

const priority = 1000;

export default {
  context: 'support',
  priority,
  routes: {
    Help      : { priority: priority + 1, relpath: `/support/help` },
    UserManual: { priority: priority + 2, relpath: `/support/manual` },
    About     : { priority: priority + 3, relpath: `/support/about` },
    Contact   : { priority: priority + 4, relpath: `/support/contact` },
    Privacy   : { priority: priority + 5, public: true, relpath: `/support/privacy` },
  }
};
