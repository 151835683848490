import { v4 as uuid } from 'uuid';

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

const CloseNotification = id => ({
  type: 'close',
  content: 'Close', 
  id
});

// Action creators

const notifyAction = (appearance, title, description='', options={}, actions=[]) => {
  options.id ??= uuid();
  if (options.timeout === null || options.timeout === undefined) {
    actions = [...actions, CloseNotification(options.id)];
  }

  return {
    ...options,
    type: NEW_NOTIFICATION,
    title,
    description,
    actions,
    appearance
  };
};

// Action dispatchers

export const dismissNotification = ({id, appearance}) => dispatch => dispatch({
  type: DISMISS_NOTIFICATION,
  id,
  appearance
});


export const notify = (...args) => dispatch => dispatch(notifyAction(...args));
