import './style.css';

import * as Pages from 'modules/diagnosis';

import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import FindCalendar from 'ui/components/Calendar';
import { Select } from 'ui/components/Select';
import Spinner from '@atlaskit/spinner';
import { Study } from 'services/server/functions/model/diagnosis/model';
import StudyStatusLegend from '../components/StudyStatusLegend';
import {Text} from 'ui/components/Text';
import moment from 'moment';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import HelpTooltip from 'ui/components/HelpTooltip';
import useWindowSize, { isSmallScreen } from 'ui/hooks/useWindowSize';
import { Reports } from "features/reports/helpers/tableReportConfig";
import { msToRangeDate } from 'features/helpers/dateHelper';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { useCounter } from 'ui/hooks/useCounter';
import { sanitizeInput } from 'features/helpers/sanitiser';

const StudiesLandingView = ({loading, studiesByStatus={}, dateOptions, browseDateRange, browsePeriod, status, patientID="", patientBirth="", setLocationState}) => {
  const [currentUser] = useCurrentUser();
  useWindowSize();

  const reportsWithAccess = Object.values(Reports).filter(report => currentUser.hasAccess(report.query) && report.key !== Reports.StudyReport.key);
  return (
    <div className="page" id="StudiesLanding">
      <Back/>
      <div className="pageHeader">
        <h1 className="title"><Text>StudyLanding.title</Text></h1>
      </div>
      <div className="pageContent">
        <div className="pageSection">
          {(currentUser.hasAccess(Pages.CreateStudy) || currentUser.hasAccess(Pages.CreateStudyBatch)) && <DetailsBlock className="reportCard" inline>
            <Details id="CreateStudyDetails" icon={<div className="newStudyIcon"/>} label="StudyLanding.create-new">
              <div className="grid-col">
                {currentUser.hasAccess(Pages.CreateStudy) && <Pages.CreateStudy.AsLinkButton text="create" buttonClass="primary" linkClass="createStudyLink" />}
                {currentUser.hasAccess(Pages.CreateStudyBatch) && <Pages.CreateStudyBatch.AsLinkButton text="create-batch" buttonClass="primary" linkClass="createStudyBatchLink" />}
              </div>
            </Details>
            <div className="newStudyImg"/>
          </DetailsBlock>}
          {currentUser.hasAccess(Study.queries.FIND_SLEEP_STUDY) && <DetailsBlock className="reportCard" inline>
            <Details id="StudyFindByPatient" icon={<div className="searchStudyIcon"></div>} label="StudyLanding.find" contentClass="fullWidth">
              <div className={`${isSmallScreen() ? 'grid-row' : 'grid-col'} vcentered`}>
                <input id="StudyPatientID" placeholder={Text.resolve("patient-id")} onChange={(e) => setLocationState({patientID: sanitizeInput((e.target.value || '')).toUpperCase()})} onKeyDown={(e) => e.keyCode === 13 ? document.getElementById("search").click() : null} value={patientID}/>
                <FindCalendar
                  handleChange={e => setLocationState({patientBirth: e.target.value})}
                  id="StudyPatientBirth"
                  placeholder={Text.resolve("StudyLanding.birth-date")}
                  maxDate={moment().toDate()}
                  openToDate={moment().subtract(40, 'years').toDate()}
                  value={patientBirth}
                />
                <Pages.FindStudy.AsLinkButton disabled={(!patientID && !patientBirth)} text="StudyLanding.search" id="search" buttonClass="primary" linkClass="searchButton" patientBirth={patientBirth} patientID={patientID}/>
              </div>
            </Details>
          </DetailsBlock>}
          {currentUser.hasAccess(Study.queries.FIND_SLEEP_STUDY) && <DetailsBlock className="reportCard" inline>
            <Details icon={<div className="listStudyIcon"></div>} contentClass="fullWidth" label="StudyLanding.browse">
              <div className="grid-row browseFilters">
                <div className="grid-col browseFilter">
                  <div className="inline browseSection"><span><Text>study-last-update</Text></span>{/*<HelpTooltip id="periodHelp" content={<PeriodHelp/>}/>*/}</div>
                  <div className="grid-col vcentered filters-box periodSelector">
                    <Select 
                      className="periodSelect" 
                      placeholder={Text.resolve("StudyLanding.select-period")} 
                      options={dateOptions.map(period => ({value: period, label: Text.resolve(`StudyLanding.select-period-${period}`, {}, { schema: "diagnosis.Study" })}))} 
                      value={browsePeriod} 
                      onChange={v => { setLocationState({browsePeriod: v.target.value}); }}
                    />
                  </div>
                  <div className="grid-col vcentered filters-box periodLink">
                    {dateOptions.map(period => <div 
                      key={`period${period}`}
                      onClick={() => setLocationState({browsePeriod: period})}
                      className={['selectable status any'].concat(browsePeriod === period ? ' selected' : []).join(" ")}
                    >
                      <Text context={{ schema: "diagnosis.Study" }}>{`StudyLanding.select-period-${period}`}</Text>
                    </div>)}
                  </div>
                </div>
                <div className="grid-col browseFilter">
                  <div className="inline browseSection"><span><Text>StudyLanding.status</Text></span><HelpTooltip id="statusHelp" content={<StudyStatusLegend filter={s => Object.keys(studiesByStatus).includes(s)} />}/></div>
                  <div className="grid-col vcentered filters-box statusSelector">
                    <Select className="statusSelect" placeholder={Text.resolve("StudyLanding.select-status")} 
                      value={status} onChange={v => setLocationState({status: v.target.value})}
                      options={
                        Object.entries(studiesByStatus).sort((s1, s2) => Study.STATUS_GROUP.compare(s1[0], s2[0]))
                              .map(([status, count]) => ({value: status, label: `${Text.resolve(status, {}, { schema: "diagnosis.Study" }).toUpperCase()}${loading || status === 'any' ? '' : ` (${count})`}`}))
                        } 
                    />
                  </div>
                  <div className="grid-col vcentered filters-box browseLink">
                    {Object.entries(studiesByStatus).filter(([s]) => s !== "any").sort((s1, s2) => Study.STATUS_GROUP.compare(s1[0], s2[0])).map(([s, count]) => <div 
                      key={s}
                      id={s}
                      title={`${Text.resolve(s, {}, { schema: "diagnosis.Study" })}${loading ? '' : ` (${count})`}`}
                      className={[`selectable status ${s}`].concat(s === status ? ' selected' : []).join(" ")}
                      onClick={_ => setLocationState({status: s})}
                    >{Text.resolve(s, {}, { schema: "diagnosis.Study" })} {loading ? <Spinner size="small"/> : `(${count})`}</div>)}
                    <span className="fitContent"><Text>or</Text></span>
                    <div className={["selectable status any"].concat('any' === status ? ' selected' : []).join(" ")} onClick={_ => setLocationState({status: 'any'})}><Text>global.all</Text></div>
                  </div>
                </div>
                <div className="grid-col fitContent"><div className="info"><InfoIcon /></div><span><Text>module.diagnosis.fe.view.findStudy.info_non_archived</Text></span></div>
                <div className="grid-col browseFilter">
                  <div className="grid-col vcentered filters-box">
                    <Pages.FindStudy.AsLinkButton id="browseStudies" text="search" linkClass="fitContent" className="primary" statuses={status === "any" ? undefined : [status]} lastUpdated={browseDateRange} reportType={Reports.StudyReport.key}  />
                    <div className="align-right"><Pages.FindStudy.AsLink id="browseStudies" className="link fitContent" text="module.diagnosis.fe.view.findStudy.archive_filter" statuses={status === "any" ? undefined : [status]} lastUpdated={browseDateRange} showArchived={true} reportType={Reports.StudyReport.key}  /></div>
                  </div>
                </div>
              </div>
            </Details>
          </DetailsBlock>}
          {(currentUser.hasAccess(Pages.FindStudyEvents) || reportsWithAccess.length > 0) && <DetailsBlock className="reportCard" inline>
            <Details icon={<div className="reportingIcon"></div>} contentClass="fullWidth" label="StudyLanding.reporting">
              <div className="grid-col vcentered filters-box">
                {reportsWithAccess.map(report => <Pages.FindStudy.AsLinkButton key={report.key} id={report.key} text={Text.resolve(report.label)} linkClass="fitContent" className="primary" reportType={report.key}  />)}
                {currentUser.hasAccess(Pages.FindStudyEvents) && <Pages.FindStudyEvents.AsLinkButton text={Text.resolve("Events log")} linkClass="fitContent" className="admin" />}
              </div>
            </Details>
          </DetailsBlock>}
        </div>
      </div>
    </div>
  );
}

const StudiesLanding = () => {
  const [currentUser] = useCurrentUser();
  
  const [{patientID: pID, patientBirth: doB, browsePeriod='lastQuarter', status='any'}, setLocationState] = useLocationParams({ patientID: undefined, patientBirth: undefined, browsePeriod: undefined, status: undefined });
  const dateOptions = {
    lastDay: {
      startDate: moment().startOf('day').add(-1, 'day'),
      endDate: moment().endOf('day')
    }, 
    lastWeek: {
      startDate: moment().startOf('day').add(-1, 'week'),
      endDate: moment().endOf('day')
    }, 
    lastMonth: {
      startDate: moment().startOf('day').add(-30, 'days'),
      endDate: moment().endOf('day')
    }, 
    lastQuarter: {
      startDate: moment().startOf('day').add(-90, 'days'),
      endDate: moment().endOf('day')
    }, 
    lastYear: {
      startDate: moment().startOf('day').add(-1, 'year'),
      endDate: moment().endOf('day')
    }, 
    /*all: {
      startDate: undefined,
      endDate: undefined
    }*/
  };
  
  const period = [dateOptions[browsePeriod].startDate?.toDate().getTime(), dateOptions[browsePeriod].endDate?.toDate().getTime()];

  // improve by adopting multiple aggregations https://firebase.google.com/docs/firestore/query-data/aggregation-queries#calculate_multiple_aggregations_in_a_query
  const [ CREATED=0,     loadingCreated ]    = useCounter(Study, { ownedBy: currentUser, filters: [['status', '==', 'CREATED']],     showArchived: false, dateRange: period, withProduct: true });
  const [ IN_PROGRESS=0, loadingInProgress ] = useCounter(Study, { ownedBy: currentUser, filters: [['status', '==', 'IN_PROGRESS']], showArchived: false, dateRange: period, withProduct: true });
  const [ FINISHED=0,    loadingFinished ]   = useCounter(Study, { ownedBy: currentUser, filters: [['status', '==', 'FINISHED']],    showArchived: false, dateRange: period, withProduct: true });
  const [ REVIEWED=0,    loadingReviewed ]   = useCounter(Study, { ownedBy: currentUser, filters: [['status', '==', 'REVIEWED']],    showArchived: false, dateRange: period, withProduct: true });
  const loading = loadingCreated || loadingInProgress || loadingFinished || loadingReviewed;
  const studiesByStatus = {
    CREATED, IN_PROGRESS, FINISHED, REVIEWED,
    any: (CREATED + IN_PROGRESS + FINISHED + REVIEWED)
  };
  
  // UGLY but those are requirements! by @emilio show everything except REVIEWED when they have 0 count
  if (studiesByStatus[Study.STATUS_GROUP.REVIEWED]      === 0)  delete studiesByStatus[Study.STATUS_GROUP.REVIEWED];  

  return StudiesLandingView({
    studiesByStatus,
    dateOptions: Object.keys(dateOptions),
    browseDateRange: msToRangeDate(dateOptions[browsePeriod].startDate?.valueOf(), dateOptions[browsePeriod].endDate?.valueOf()),
    browsePeriod,
    status,
    patientID   : pID,
    patientBirth: doB,
    loading,
    setLocationState
  });
};

StudiesLanding.displayName = "StudiesLanding";
export default StudiesLanding;
