import { useState } from "react";
import {Text} from 'ui/components/Text';
import FileInput from "../FileInput";
import './style.css';

const FileDrop = ({id, disabled, annotation, onFileDrop, accept}) => {
  const [highlight, setHighlight] = useState(false);

  const onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setHighlight(true);
  }

  const onDragLeave = () => {
    setHighlight(false);
  }

  const onDrop = (evt) => {
    evt.preventDefault();
    onFileDrop(evt.dataTransfer.files[0]);
    setHighlight(false);
  }

  return (
    <div className={['dropDestination', highlight ?  'highlight' : '', disabled ? 'disabled' : ''].join(' ')} id={id} onDragOver={disabled ? undefined : onDragOver} onDragLeave={disabled ? undefined : onDragLeave} onDrop={disabled ? undefined : onDrop}>
      <div><Text>DropFile.upload.drop-file</Text></div>
      <div><Text>DropFile.upload.or</Text></div>
      <FileInput disabled={disabled} onFileAdded={onFileDrop} accept={accept}/>
      {annotation && <div className="annotation">{annotation}</div>}
    </div>
  );
};

FileDrop.displayName = 'FileDrop';

const CSV = ({ id, disabled, annotation, onFileDrop, transformRow=_=>_, onComplete=onFileDrop, ...props }) => {
  const R = require('ramda');
  const setter     = (object, [field, value]) => value === undefined ? object : R.set(R.lensPath(field.split('.')), value, object);
  const row2data   = data => Object.entries(data).reduce(setter, {});
  const withErrors = errors => (data, row) => ({ row, data, errors: errors?.filter(e => e.row === row) });
  const csv2data   = ({ data, errors }) => data.map(row2data).map(withErrors(errors)).map(transformRow);
 
  const parse = file => require('papaparse').parse(file, {
    delimiter      : "", // auto-detect
    fastMode       : false, // allows for more accurate interpretation of the data values (ex: values with commas)
    header         : true,
    skipEmptyLines : 'greedy',
    transformHeader: val => val && String(val).trim(),
    transform      : val => val === '' ? undefined : String(val).trim(),
    complete       : csv => onComplete(csv2data(csv), csv.meta),
    ...props
  });

  return <FileDrop id={id} disabled={disabled} annotation={annotation} onFileDrop={parse} />;
}

FileDrop.CSV = ({hidden, ...props}) => hidden ? <></> : <CSV {...props} />;
FileDrop.CSV.displayName = 'FileDrop.CSV';

export default FileDrop;