import { useCallback } from "react";
import Details from "ui/components/Details";
import { FiltersType } from "../../utils";
import { sanitizeInput } from "features/helpers/sanitiser";

export const ReferenceId = ({ filters, onFilterChange, isSuperAdmin }) => {
    const reference = filters[FiltersType.Reference];
    const handlePatientId = useCallback((e) => {
        const value = sanitizeInput((e.target.value || '')).toUpperCase();
        onFilterChange({ [FiltersType.Reference]: value });
    }, [onFilterChange]);

    return (
        <Details id={FiltersType.Reference}
            label={isSuperAdmin ? "patient-id-or-reference" : "patient-id"}>
            <input id={`study_${FiltersType.Reference}`}
                className="input"
                onChange={handlePatientId} value={reference || ''}
            />
        </Details>
    )
};
