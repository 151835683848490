import { useCallback, useEffect, useMemo, useState } from "react";
import { DataBase } from "services/firebase";
import useCurrentUser from "../useCurrentUser";
import AdministrationConfig from 'services/server/functions/model/administration/config';
import { Organisation, HealthcareSite, Product } from "services/server/functions/model/administration/model";
import { ulid } from 'ulid';
import usePrevious from "../usePrevious";

export const useCounter = (model, opts = {}) => {
  const [currentUser] = useCurrentUser();

  const { owner, ownedBy = currentUser, showArchived, dateRange, filters = [], withProduct, autoStart = true } = opts;

  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState();
  // Necessary to do [...ownedBy.data.owners] because it may come from the props.snapshot property, and it throws an error when doing owners.sort() because it freezes the object
  const owners = owner ? [owner] : ownedBy?.data?.owners ? [...ownedBy.data.owners] : [];

  const periodUlids = useMemo(_ => dateRange?.map(t => Boolean(t) ? ulid((new Date(t)).valueOf()) : undefined), [dateRange?.join(';')]);

  const prevShowArchived = usePrevious(showArchived);
  const prevPeriod = usePrevious(periodUlids);
  const prevFilters = usePrevious(filters);
  const prevOwners = usePrevious(owners);
  const prevWithProduct = usePrevious(withProduct);
  const prevSelectedProduct = usePrevious(currentUser.metadata.selectedProductKey);
  const prevAutoStart = usePrevious(autoStart);

  const ownersChanged = prevOwners !== undefined && prevOwners.sort().join(';') !== owners.sort().join(';');
  const update = prevAutoStart !== autoStart || ownersChanged || prevShowArchived !== showArchived || prevPeriod?.[0] !== periodUlids?.[0] || prevPeriod?.[1] !== periodUlids?.[1] || filters?.flat()?.join(';') !== prevFilters?.flat()?.join(';') || prevWithProduct !== withProduct || prevSelectedProduct !== currentUser.metadata.selectedProductKey;

  const hasModelListAccess = model.name === "Study" ? currentUser.hasAccess(require('modules/diagnosis').FindStudy) : currentUser.hasAccess(model.queries.LIST), // ugly: how someone can have access to FindStudy and not Study.LIST ?
    canAccessOrg = currentUser.hasAccess(Organisation.queries.GET) || currentUser.hasAccess(Organisation.queries.GET_NAME),
    canAccessHcs = currentUser.hasAccess(HealthcareSite.queries.GET);
  // same fro Trials ? 
  // hasTrialGetAccess = currentUser.hasAccess(Organisation.entities.Trial.queries.GET),

  const getOwners = useCallback(async _ => {
    if (owners.length > 1) {
      const ancestors = new Set(owners.filter(oid => (canAccessHcs && oid.includes("/HealthcareSite/")) || (canAccessOrg && oid.includes("/Organisation/"))));
      const ancestorIds = [...ancestors].filter(id => id !== AdministrationConfig.organisation.root.id);
      const parents = ancestorIds.length ? await Promise.all(ancestorIds.map(oid => DataBase().snapshots(oid).get())) : [];
      parents.forEach(a => a.data.owners.forEach(oid => ancestors.has(oid) && ancestors.delete(a.aggregate.id))); // remove those owners whose parents are already included in the set of owners

      return [...ancestors];
    }
    return owners;
  }, [owners.sort().join(";")]);

  const makeRequest = useCallback(async (force) => {
    if (hasModelListAccess && !loading && model && (force || (update && autoStart))) {
      let query;
      const owners = await getOwners();
      if (owners.length) {
        query = DataBase().snapshots(model).withMetadata('allOwners', 'array-contains-any', owners);
      } else {
        query = DataBase().snapshots(model);
      }

      let runORAsMultipleRequests = filters.length;

      if (model.name === "User") { runORAsMultipleRequests = true; query = query.with('job', '!=', 'patient'); } // ugly workaround for counting only "real" users, not impersonators/patients.
      if (showArchived !== undefined) query = query.withMetadata('archived', showArchived);
      if (periodUlids?.[0] !== undefined) query = query.withMetadata('lastEvent', '>=', periodUlids[0]);
      if (periodUlids?.[1] !== undefined) query = query.withMetadata('lastEvent', '<', periodUlids[1]);
      if (withProduct) query = query.withMetadata('product', Product.newURN(currentUser.metadata.selectedProductKey));

      // Adds filters
      if (filters.length) query = query.withAny(...filters);

      setLoading(true);
      setCounter(await query.count({ runORAsMultipleRequests }));
      setLoading(false);
    }
    return undefined;
  }, [hasModelListAccess, update, model, loading, getOwners, filters?.flat()?.join(';'), showArchived, periodUlids?.[0], periodUlids?.[1], autoStart]);

  useEffect(async () => {
    makeRequest();
  }, [makeRequest]);

  const reload = () => {
    makeRequest(true);
  };

  return [counter, loading, reload];
};