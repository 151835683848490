import { memo } from "react";

import { SchemaFormList } from "features/components/SchemaForm";
import { ModelFormTypes } from "features/constants/modelActionTypes";
import { useSettings } from "features/hooks/useSettings";
import { FormConfig } from "./formConfig";

export const FeatureToggleTypes = Object.freeze({
    AllFeatures: 'AllFeatures',
});

const AllFeatures = ({ ownerId }) => {
    const { schemas, onClickAction } = useSettings({
        ownerId,
        schemasToRetrieve: Object.values(FormConfig).map(c => c.schemaToRetrieve),
    });
    const formProps = {
        onClickAction,
        formOpts: {
            [ModelFormTypes.StudySettings]: FormConfig[ModelFormTypes.StudySettings].options,
        }
    };
    const labelsPerForm = {
        [ModelFormTypes.StudySettings]: FormConfig[ModelFormTypes.StudySettings].label,
    };

    return (
        <>
            {
                schemas && <SchemaFormList schemas={schemas} formProps={formProps} labelsPerForm={labelsPerForm} />
            }
        </>
    );
};

export const FeatureToggleSection = ({ formId, ownerId }) => {
    const formConfig = FormConfig[formId];
    if (!ownerId || !formConfig) {
        return <></>;
    }

    const { schemas, onClickAction } = useSettings({
        ownerId,
        schemasToRetrieve: [
            formConfig.schemaToRetrieve,
        ],
    });

    const formProps = {
        onClickAction,
        formOpts: {},
    };

    if (formConfig.options) {
        formProps.formOpts[formId] = formConfig.options;
    }

    const labelsPerForm = {};

    if (formConfig.label) {
        labelsPerForm[formId] = formConfig.label;
    }

    return (
        <>
            {
                schemas && <SchemaFormList schemas={schemas} formProps={formProps} labelsPerForm={labelsPerForm} />
            }
        </>
    );
};
FeatureToggleSection.hasAccess = (currentUser, formId) => {
    return currentUser.hasAccess(FormConfig[formId].action);
};

const accessibleFeatures = {
    [FeatureToggleTypes.AllFeatures]: AllFeatures,
};

export const FeatureToggleSettings = memo(({
    ownerId,
    accessTo,
}) => {
    if (!ownerId || Object.values(FeatureToggleTypes).every(type => type !== accessTo)) {
        return <></>;
    }
    const FeatureComponent = accessibleFeatures[accessTo];
    return (<><FeatureComponent ownerId={ownerId} /></>);
});
