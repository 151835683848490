import {Text} from 'ui/components/Text';

export default {
  topic: Text.resolve("PrivacyPolicy.topics.what-personal.question"),
  component: () => <div className="grid-row">
    <span><Text>PrivacyPolicy.topics.what-personal.answer-1</Text></span>
    <div>
      <span><Text>PrivacyPolicy.topics.what-personal.answer-2</Text></span>
      <ul>
        <li><Text>global.name</Text></li>
        <li><Text>PrivacyPolicy.topics.what-personal.answer-2.2</Text></li>
        <li><Text>PrivacyPolicy.topics.what-personal.answer-2.3</Text></li>
        <li><Text>PrivacyPolicy.topics.what-personal.answer-2.4</Text></li>
        <li><Text>PrivacyPolicy.topics.what-personal.answer-2.5</Text></li>
      </ul>
    </div>
  </div>
}