import codegen from 'codegen.macro';

// links transformed events (if any) with source event
// produce ordered event ids relative to source event
const toDerivedEvent = referenceEvent => (e, i, a) => {
  if (e.id !== referenceEvent.id) {
    e.id                    = require('../executor/urn').nextEventURN(e.id, (a[i-1] || referenceEvent).id);
    e.seq                   = require('../executor/urn').dataFrom(e.id).id;
    e.metadata              = e.metadata || {};
    e.metadata.causationId  = referenceEvent.id;
    e.metadata.derivedEvent = true;
  } 
  return e;
}

// Returns the list of transformmations that are elegible for this source event
// Using timestamps to order and filter functions depending on source event forces us to think on the release date for production env when creating an event transform function 
// we should use versions instead, schema versions instead of app versions maybe
const transformers = codegen.require('./transformers.macro');
export const DEPRECATED = transformers.map(t => t.DEPRECATED || [])
                                      .reduce((all, d) => [...all, ...d], [])

const transformations = transformers.map(t => Object.entries(t.default))
                                    .reduce((all, txsObj) => [...all, ...txsObj], [])
                                    .sort((e1, e2) => e1[0]-e2[0])
                                    .map(([ts, fn]) => [
                                      ts, 
                                      async (event, ctx) => {
                                        if (DEPRECATED.includes(event.type)) return [];

                                        event = await fn(event, ctx);
                                        event = Array.isArray(event) ? event : [event];
                                        return event;
                                      }
                                    ]);

transformations.forEvent = event => {
  if (event.timestamp > transformations[transformations.length-1][0]) // none applicable
    return [];

  if (event.timestamp <= transformations[0][0]) // all applicable
    return transformations.map(t => t[1]);

  // some applicable. Finds first applicable transformation using binary search (given that the array is sorted by timestamp)
  let start = 0, mid, end = transformations.length-1;
  while (start < end) { 
    mid = start + ((end - start) >>> 1);
    if (event.timestamp < transformations[mid][0])
      end = mid - 1;
    else if (event.timestamp > transformations[mid][0])
      start = mid + 1;
    else 
      end = mid;
  }

  return transformations.slice(start).map(t => t[1]);
}

export const transform = async (events) => {
  const transformed = [];
  for await (const event of events) {
    let derived = [event];
    for await (const tx of transformations.forEvent(event)) {
      const txEvts = []
      for await (const ev of derived) {
        txEvts.push(...(await tx(ev, events)))
      }
      derived = txEvts;
    }

    derived = derived.map(toDerivedEvent(event))
    transformed.push(...derived)
  }
  return transformed;
}

export default transform;
