import { Study } from 'services/server/functions/model/diagnosis/model';

export const getStudyvalidationSchema = ({ currentUser, isConfirm, isEdit }) => {
  if (!isConfirm && !isEdit) {
    return Study.commands.CREATE_STUDY.schema;
  }
  if (isEdit) {
    return currentUser.hasAccess(Study.commands.UPDATE_STUDY)
      ? Study.commands.UPDATE_STUDY.schema
      : Study.commands.UPDATE_PATIENT_DETAILS.schema;
  };
  return undefined;
};

