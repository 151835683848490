import { useMemo } from 'react';
import Back from 'ui/components/Back';
import * as Administration from 'modules/administration';
import { HealthcareSite } from 'services/server/functions/model/administration/model';
import WithSnapshot from 'ui/components/WithSnapshot';
import { getLocationParams } from 'ui/hooks/useLocationParams';
import { Redirect } from 'react-router-dom';
import { TemplateSettings } from 'features/preferences/TemplateSettings';
import { StudySleepReportSettings } from 'features/preferences/StudySleepReportSettings';
import { FeatureToggleSection } from 'features/preferences/FeatureToggleSettings';
import { ViewPatientProfile } from 'features/preferences/PatientProfile';
import { ModelFormTypes } from 'features/constants/modelActionTypes';
import useCurrentUser from 'ui/hooks/useCurrentUser';

const ViewHealthcareSiteConfigurationUI = ({ healthcaresite, I18n }) => {
  const [currentUser] = useCurrentUser();
  
  const siteSettingsComponents = useMemo(() => {
    return [
      {
        key: ModelFormTypes.StudySettings,
        Component: FeatureToggleSection,
        hasAccess: (currentUser) => FeatureToggleSection.hasAccess(currentUser, ModelFormTypes.StudySettings),
        params: {
          ownerId: healthcaresite,
          formId: ModelFormTypes.StudySettings,
        },
      },
      {
        key: 'PatientProfile',
        Component: ViewPatientProfile,
        hasAccess: ViewPatientProfile.hasAccess,
        params: {
          ownerId: healthcaresite,
        },
      },
      {
        key: ModelFormTypes.ManualScoringSettings,
        Component: FeatureToggleSection,
        hasAccess: (currentUser) => FeatureToggleSection.hasAccess(currentUser, ModelFormTypes.ManualScoringSettings),
        params: {
          ownerId: healthcaresite,
          formId: ModelFormTypes.ManualScoringSettings,
        },
      },
      {
        key: ModelFormTypes.DigitalSignature,
        Component: FeatureToggleSection,
        hasAccess: (currentUser) => FeatureToggleSection.hasAccess(currentUser, ModelFormTypes.DigitalSignature),
        params: {
          ownerId: healthcaresite,
          formId: ModelFormTypes.DigitalSignature,
        },
      },
      {
        key: ModelFormTypes.ReviewedStudies,
        Component: FeatureToggleSection,
        hasAccess: (currentUser) => FeatureToggleSection.hasAccess(currentUser, ModelFormTypes.ReviewedStudies),
        params: {
          ownerId: healthcaresite,
          formId: ModelFormTypes.ReviewedStudies,
        },
      },
      {
        key: 'SleepReportSettings',
        Component: StudySleepReportSettings,
        hasAccess: StudySleepReportSettings.hasAccess,
        params: {
          ownerId: healthcaresite,
        },
      },
      {
        key: 'TemplateSettings',
        Component: TemplateSettings,
        hasAccess: TemplateSettings.hasAccess,
        params: {
          ownerId: healthcaresite,
        },
      },
    ].filter(row => row.hasAccess(currentUser));
  }, [currentUser]);

  return (
    <div id='ConfigureHealthcareSiteSetting' className='page'>
      <Back />
      <div className='pageHeader'>
        <h1 className='title'>{I18n('title')}</h1>
      </div>
      <div className="pageContent">
        {siteSettingsComponents.map(row => {
          return <row.Component key={row.key} {...row.params} />
        })}
      </div>
    </div>
  );
};

const fallbackComponent = _ => <Redirect to={Administration.FindHCS.PATH} />;
const ViewHealthcareSiteConfiguration = (props) => WithSnapshot(HealthcareSite, getLocationParams().healthcaresite, { fallbackComponent })(ViewHealthcareSiteConfigurationUI)({ ...props, ...getLocationParams() });

ViewHealthcareSiteConfiguration.displayName = "ViewHealthcareSiteConfiguration";
export default ViewHealthcareSiteConfiguration;