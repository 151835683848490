import { browserStorageTypes } from "features/constants/browserTypes";
import { browserDispatchEvent } from "./browserEvent";
import { BrowserEventType } from "features/administration/providers/useBrowserNotifcationProvider";

const witNameSpace = (key) => `@acurable/${key}`;

const MAX_LOCALSTORAGE_SIZE = 5 * 1024 * 1024; 

const bytesToMB = (size) => size / (1024 * 1024);

export const getCurrentStorageSpace = () => Object.keys(localStorage)
    .map(key => localStorage[key])
    .join('')
    .length;

export const isThereSpaceToStoreItem = (item = {}) => {
    const storageUsaged = getCurrentStorageSpace();
    const spaceAfterSaved = storageUsaged + JSON.stringify(item).length;
    const hasSpace = spaceAfterSaved <= MAX_LOCALSTORAGE_SIZE;
    const maxMbAllowed = bytesToMB(MAX_LOCALSTORAGE_SIZE);

    console.log(`[localstorage] - Current size: ${bytesToMB(storageUsaged)} / ${maxMbAllowed} MB`);

    console.log(`[localstorage] - Attempt to save : ${bytesToMB(spaceAfterSaved)} / ${maxMbAllowed} MB`);

    return hasSpace;
};

export const getBrowserStorageItem = (key) => {
    let item;
    const normalizedKey = witNameSpace(key);

    try {
        item = JSON.parse(localStorage.getItem(normalizedKey));
    } catch {
        item = undefined;
        browserDispatchEvent({
            type: BrowserEventType.StorageGetItemFails,
            payload: normalizedKey
        });
    }

    return item || undefined;
};

export const setBrowserStorageItem = (key, value) => {
    const normalizedKey = witNameSpace(key);
    const storageAlmostFullEvent = {
        type: BrowserEventType.StorageAlmostFull,
        payload: normalizedKey
    };

    try {
        const hasSpace = isThereSpaceToStoreItem(value);
        if (hasSpace) {
            localStorage.setItem(normalizedKey, JSON.stringify(value));
        } else {
            browserDispatchEvent(storageAlmostFullEvent);
        }
    } catch (error) {
        let evenData = {
            type: BrowserEventType.StorageSetItemFails,
            payload: normalizedKey
        };

        if (error.name === "QuotaExceededError") {
            evenData = storageAlmostFullEvent;
        }

        browserDispatchEvent(evenData);
    }
};

const withRegionNameSpace = (key) => {
    const region = getBrowserStorageItem(browserStorageTypes.Region);
    return `${region}/${key}`;
};

export const getBrowserStorageRegionItem =
    (key) => getBrowserStorageItem(withRegionNameSpace(key));

export const setBrowserStorageRegionItem =
    (key, value) => setBrowserStorageItem(withRegionNameSpace(key), value);

export const clearBrowserStorage = (keys = [], useRegion = false) => keys?.forEach(k => {
    const key = witNameSpace(useRegion ? withRegionNameSpace(k) : k);
    localStorage.removeItem(key)
});
