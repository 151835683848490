import * as Pages from '.';
import LabelIcon from '@atlaskit/icon/glyph/label';
import PhoneIcon from '@atlaskit/icon/glyph/hipchat/dial-out';
import QuestionsIcon from '@atlaskit/icon/glyph/questions';

import {Text} from 'ui/components/Text';

const InfoIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
    <defs>
      <mask id="infoLetter">
        <rect width="100%" height="100%" fill="white"/>
        <circle fill="black" cx="12" cy="8" r="1" />
        <rect fill="black" x="11" y="10" width="2" height="7" rx="1" />
      </mask>
    </defs>
    <path mask="url(#infoLetter)" d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12z" fill="currentColor"></path>
  </svg>;

export default () => [{
  titleGroup: Text.resolve("Menu.support-subtitle"),
  itemsGroup: [
    {
      icon: <QuestionsIcon />,
      text: Text.resolve('Menu.help'),
      link: Pages.Help.PATH,
      id  : 'help'
    },
    {
      icon: <InfoIcon />,
      text: Text.resolve('Menu.user-manual'),
      link: Pages.UserManual.PATH,
      id  : 'manual'
    },
    {
      icon: <LabelIcon size="medium"/>,
      text: Text.resolve('Menu.about'),
      link: Pages.About.PATH,
      id  : 'about'
    },
    {
      icon: <PhoneIcon size="medium"/>,
      text: Text.resolve('Menu.contact'),
      link: Pages.Contact.PATH,
      id  : 'contact'
    }
  ]
}];