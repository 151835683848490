import { BlockCommentModal } from './BlockCommentModal';
import { BlockTextAreaModal } from './BlockTextAreaModal';

import './style.css';

export const BlockCommentTypes = {
    TextAreaModal: 'text-area-modal',
    ButtonModal: 'button-modal'
};

export const componentByType = {
    [BlockCommentTypes.TextAreaModal]: BlockTextAreaModal,
    [BlockCommentTypes.ButtonModal]: BlockCommentModal
};

export const BlockComment = ({
    type = BlockCommentTypes.TextAreaModal,
    ...props
}) => {
    const Component = componentByType[type] || componentByType[BlockCommentTypes.TextAreaModal];

    return <Component {...props} >
        {props.children}
    </ Component>;
};