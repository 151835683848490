import { Joi } from 'services/server/functions/validation/rules';
import { errorMessages } from 'services/server/functions/validation/errors';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import i18n from 'services/server/functions/i18n'

export const WithFormValidation = ({/*onValidate, showError, schema, form, style, */children}) => {
  // TODO this will validate the form and will look for each field of the form object in children REACT/DOM elements with a name or id property equal to the current field and will replace it using same wrapper used in WithValidation using React RecursiveWrapper strategy: https://stackoverflow.com/questions/41523340/react-replace-child-component-in-props-children
  /*require('./style.css');
  const [message, setMessage] = useState();
  const show = showError && Boolean(message);

  // TODO: pass always model/action schemas and use schema.validate() directly
  useEffect(() => {
    console.log(field, fieldValue)
    const fieldSchema = withCustomError(schema ? ((form === undefined && schema.schemaType === "object") ? schema.extract(field) : schema) : Joi.any());
    try {
      const value        = form !== undefined ? form : fieldValue;
      const result       = Joi.validate(value === null ? undefined : value, fieldSchema, form ? {abortEarly: false, stripUnknown: true} : undefined);
      const fieldPath    = field && field.split('.');
      const fieldError   = form !== undefined ? (result.error && result.error.details.find(e => e.path.length === fieldPath.length && e.path.every((f, idx) => fieldPath[idx] === f))) : (result.error && result.error.details[0]);
      if (fieldError) {
        console.log(field, fieldError)
        setMessage(fieldError.message);
      } else setMessage(null);
    } catch (e) {
      setMessage(e.message); //FIXME: phone validation may throw exception, maybe use joi extension to fix it.
    }
  }, [fieldValue, form, field]);

  useEffect(() => { onValidate && onValidate(field, message); }, [field, message]);
  
  return (
    <React.Fragment>
      {show && <ReactTooltip className="validationTooltip" id={`${field || id}ValidationTooltip`} place="top" type="light" effect="solid" border multiline>
        <span>{message}</span>
      </ReactTooltip>}
      <div data-tip data-for={`${field || id}ValidationTooltip`} style={style} className={['validatedField'].concat(show ? "failed" : []).join(' ')}>
        { children }
      </div>
    </React.Fragment>
  );*/
  return children;
}

// Workaround for translating messages like "Please provide at least {{number}}". TODO: use i18n joi extension after upgrading joi
const txMessage = (text) => {
  const regex = /[^0-9.]*( \d+)$/;
  const match = text.match(regex);
  return match ? i18n.resolve(text.replace(match[1], '')).concat(match[1]) : i18n.resolve(text);
}

// NOTE: use "form" when you need to validate a field whose validation depends on other field values
export const WithValidation = ({onValidate, forceMessage, dependsOn, showError, schema, mandatory, field, id, value: fieldValue, form, style, children}) => {
  require('./style.css');
  const [message, setMessage] = useState();
  const show = showError && Boolean(message || forceMessage);

  // TODO: pass always model/action schemas and use schema.validate() directly
  useEffect(() => {
    let fieldSchema = schema ? ((form === undefined && schema.type === "object") ? schema.extract(field) : schema) : Joi.any();
    if (mandatory) fieldSchema = fieldSchema.required();
    try {
      const value        = form !== undefined ? form : fieldValue;
      const result       = fieldSchema.validate(value === null ? undefined : value, form ? {abortEarly: false, stripUnknown: true, messages: errorMessages()} : {messages: errorMessages()});
      const fieldPath    = field?.split('.');
      const fieldError   = form !== undefined ? result.error?.details.find(e => e.path.length === fieldPath.length && e.path.every((f, idx) => fieldPath[idx] === f)) : (result.error?.details?.[0] || result.error);
      if (fieldError) {
        setMessage(txMessage(fieldError.message));
      } else setMessage(null);
    } catch (e) {
      setMessage(e.message); //FIXME: phone validation may throw exception, maybe use joi extension to fix it.
    }
  }, [fieldValue, form, field, dependsOn, mandatory, schema]);

  useEffect(() => { onValidate?.(field, message); }, [field, message]);
  
  return (
    <>
      {show && <ReactTooltip className="validationTooltip" id={`${field || id}ValidationTooltip`} place="top" type="light" effect="solid" border multiline>
        <span>{message || forceMessage}</span>
      </ReactTooltip>}
      <div data-tip data-for={`${field || id}ValidationTooltip`} style={style} className={['validatedField'].concat(show ? "failed" : []).join(' ')}>
        { children }
      </div>
    </>
  );
}

export const ValidatedField = ({disabled, onValidate, showError, schema, field, mandatory, id=field, value, style, children}) => { // TODO: very inefficient to validate individual fields all the time ... validation should be centralised into a Form component.Deprecate this whole module
  if (schema?.type !== "object") {
    console.warn(`[ValidatedField] no object schema for field '${field}'. Intented to validate a field from a domain/model instance`)
  }

  require('./style.css');

  if (mandatory) { // mandatory could be derived from the schema itself, if the validation itself fails with '*.required' or maybe introspecting the schema itself for the 'required' flag
    schema = schema?.fork(field, s => s.required()); // this should not be here. it should come from the schema already
  }

  const [message, setMessage] = useState();
  const show = showError && Boolean(message);

  useEffect(() => {
    if (disabled) {
      setMessage(null);
    } else {
      try {
        const { error }    = schema?.validate(value || undefined, {abortEarly: false, stripUnknown: true, messages: errorMessages()}) || {};
        const fieldError   = error?.details?.find(e => e.path.join('.') === field);
        if (fieldError) {
          setMessage(txMessage(fieldError.message));
        } else setMessage(null);
      } catch (e) {
        setMessage(e.message); //FIXME: phone validation may throw exception, maybe use joi extension to fix it.
      }
    }
  }, [disabled, value, field, schema]);

  useEffect(() => { onValidate && onValidate(field, message); }, [field, message]);
  
  return (
    <>
      {show && <ReactTooltip className="validationTooltip" id={`${id}ValidationTooltip`} place="top" type="light" effect="solid" border multiline>
        <span>{message}</span>
      </ReactTooltip>}
      <div data-tip data-for={`${id}ValidationTooltip`} style={style} className={['validatedField'].concat(show ? "failed" : []).join(' ')}>
        { children }
      </div>
    </>
  );
}