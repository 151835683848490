import { Readable } from 'stream';

export const Storage = (filesystem, _options={}) => ({ 
  bucket: (bucketName) => {
    const bucket = filesystem?.[bucketName];
    return {
      async getFiles(opts) {
        return [ Object.keys(bucket||{}).filter(_path => !opts?.prefix || _path.startsWith(opts.prefix)).map(f => this.file(f)) ]
      },
      file(name) {
        const _bucket = this;
        const f = bucket?.[name];
        return {
          bucket: _bucket,
          name,
          exists: async () => [ f !== undefined ],
          createReadStream: () => {
            return Readable.from(Buffer.from((typeof f === 'string' || f instanceof String) ? f : JSON.stringify(f)))
          },
          getMetadata: async () => [({})] // { id, size, md5hash, metadata: meta } ... we don't need this implemented for the moment
        }
      }
    }
  }
});
