import DetailsBlock from 'ui/components/DetailsBlock';
import { Text } from 'ui/components/Text';
import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

const Question = () => {
    const { assetsURl } = useAssetsURL();

    return (
        <>
            {assetsURl && (
                <div className="pageSection">
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <span>
                                <Text>Help.clean-sensor.answer-1</Text>
                            </span>
                            <ul>
                                <li>
                                    <Text>Help.clean-sensor.answer-2</Text>
                                </li>
                                <li>
                                    <Text>Help.clean-sensor.answer-3</Text>
                                </li>
                                <li>
                                    <Text>Help.clean-sensor.answer-4</Text>
                                </li>
                            </ul>
                        </div>
                        <div className="instructionImg">
                            <img
                                alt=""
                                src={getImgUrl(
                                    assetsURl,
                                    'acupebble-sensor.png'
                                )}
                            />
                        </div>
                    </DetailsBlock>
                </div>
            )}
        </>
    );
};

Question.question = Text.resolve('Help.clean-sensor.question');

export default Question;
