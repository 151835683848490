import * as Pages from './ui';

import Config from './config';
import { publish } from 'modules/router';
import asyncComponent from 'ui/components/AsyncComponent';

import MODELS from 'modules/model'

const actions = {
  ...MODELS.authentication.User.UIActions,
  ...MODELS.authentication.Role.UIActions,
  editUserMail: MODELS.authentication.User.UIActions.updateUserMail,
};

const LoginComponent        = Object.assign(asyncComponent(() => import(/* webpackChunkName: "login" */  'ui/components/Login')), {displayName: "AsyncLogin"});
export const Login          = publish(Config.routes.Login)(LoginComponent);
export const ChangePassword = publish(Config.routes.ChangePassword)(LoginComponent);

export const CreateUser = publish(Config.routes.CreateUser, actions)(Pages.EditUser, { page: "EditUser"} );
export const FindUser   = publish(Config.routes.FindUser, actions)(Pages.FindUser, { page: "FindUser"} );
export const UpdateUser = publish(Config.routes.UpdateUser, actions)(Pages.EditUser, { page: "EditUser"} );
export const ViewUser   = publish(Config.routes.ViewUser, actions)(Pages.ViewUser, { page: "ViewUser"} );

export const CreateRole = publish(Config.routes.CreateRole, actions)(Pages.EditRole, { page: "EditRole"} );
export const FindRole   = publish(Config.routes.FindRole, actions)(Pages.FindRole, { page: "FindRole"} );
export const UpdateRole = publish(Config.routes.UpdateRole, actions)(Pages.EditRole, { page: "EditRole"} );
export const ViewRole   = publish(Config.routes.ViewRole, actions)(Pages.ViewRole, { page: "ViewRole"} );