import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { DataBase } from 'services/server/functions/firebase';
import useCurrentUser from '../useCurrentUser';
import useEffectOnMount from '../useEffectOnMount';
import usePrevious from '../usePrevious';
import usePageChange from '../usePageChange';
import { resolve } from 'services/server/functions/model';
import { getModelName, isURN } from 'services/server/functions/executor/urn';
import { equals } from 'ramda';
import { ulid } from 'ulid';
import { getSubscription, getSnapshot } from './globalState';
import { Product } from "services/server/functions/model/administration/model";

export const useSnapshots = (...args) => {
  const inputModels = args.filter(arg => arg?.name && arg?.context);
  const inputIds = args.slice(inputModels.length).filter(Boolean).reduce((all, arg) => all.concat(arg), []);
  const options = isURN(inputIds.slice(-1).pop()) ? {} : (inputIds.pop() || {});

  // TODO:: save in url location params to store page startWith but propagate outside of useSnapshot
  const { onReady: clientOnReady = () => { }, autoStart = true, listenToChanges = true, debug, disableCache, showArchived, withProduct, dateRange, filters = [], limit: fetchSize, sort, pageSize, visited, setVisited, onError } = options;
  const limit = pageSize !== undefined ? pageSize : fetchSize;
  const periodUlids = useMemo(_ => dateRange?.map(t => Boolean(t) ? ulid((new Date(t)).valueOf()) : undefined), [dateRange?.join(';')]);

  const [currentUser] = useCurrentUser(options),
    hasAccess = (model) => currentUser?.hasPermission(model.roles.GET.id)
      || currentUser?.hasPermission(model.roles.viewer.id) // FIXME: Using metadata all roles since at this moment we don't have the list of roles to be able to expand normal user roles
      || currentUser?.hasPermission(model.roles.LIST.id)
      || ["GET_NAME", "VIEW_DIAGNOSIS_SUMMARY", "FIND_SLEEP_STUDY"] // TODO: very ugly, need to implement query subscription
        .map(q => model.queries[q]).filter(Boolean)
        .some(q => currentUser?.hasAccess(q));

  const ids = inputIds.filter(Boolean).filter((m, idx, a) => a.indexOf(m) === idx);
  const prevIds = usePrevious(ids);

  const idModels = !ids.length ? inputModels : ids.map(resolve);
  const idHasAccess = idModels.map(hasAccess); // TODO: allow access to User model when ids contains current user id

  const models = idHasAccess.map((allowed, idx) => allowed && idModels[idx]).filter(Boolean).filter((m, idx, a) => a.indexOf(m) === idx);
  
  const subscription = useRef({});
  const pages = useRef({});
  const subscribedModels = useRef();
  const stopEventRegistration = useRef();
  const [loading, setLoading] = useState(Boolean(autoStart));
  const ready = useRef(false);

  const stop = useCallback(_ => {
    debug && console.debug("Cancelling subscription to", models.map(m => m.name));
    models.forEach(m => {
      subscription.current[m.name]?.unsubscribe?.();
    });
    subscription.current = {};
    pages.current = {}
  }, [models.map(m => m.name).join(';'), subscription.current]);
  // TODO: implement paginated queries for FindXX pages
  // TODO: implement select queries for things like showing only clinician name in ViewStudy (but rest of user snapshot can be ignored)

  const checkPredicate = (snapshot, filter) => {
    const [field, op, value] = filter;
    const isMetadataField = field.startsWith('metadata.');
    const data = field.split('.').reduce((s, attr) => s?.[attr], isMetadataField ? snapshot : snapshot?.data);
    switch (op) {
      case 'starts-with': return Boolean(data?.startsWith(value));
      case 'array-contains': return data.includes(value);
      case 'array-contains-any': return data.some(v => value.includes(v));
      case 'in': return value.includes(data);
      case '>=': return data >= value;
      case '<': return data < value;
      default: return data === value;
    };
  };

  const passFilters = (snapshot, idx) => ids.length || [ // This is because of global usage of getSnapshot (i.e. global snapshots cache) which might include data from previous snapshot queries to DB
    snapshot => showArchived === undefined || snapshot?.metadata.archived === showArchived,
    snapshot => periodUlids?.[0] === undefined || snapshot?.metadata.lastEvent >= periodUlids[0],
    snapshot => periodUlids?.[1] === undefined || snapshot?.metadata.lastEvent < periodUlids[1],
    snapshot => !filters?.length || filters.some(conjunctionOrPred => Array.isArray(conjunctionOrPred[0]) ? conjunctionOrPred.every(pred => checkPredicate(snapshot, pred)) : checkPredicate(snapshot, conjunctionOrPred)),
    snapshot => (options.skipContext && currentUser.isSuperAdmin()) || currentUser?.hasOwnership(snapshot),
    snapshot => snapshot?.aggregate?.name !== 'Study/Test/Marker' || !options?.owner || snapshot?.metadata?.parent === options?.owner,
    (_s, idx) => !limit || !idx || idx < limit
  ].every(passFilter => passFilter(snapshot, idx));

  const [error, setError] = useState();

  // Configure snapshot consumer/reducer
  const [state, setState] = useState({}),
    updateState = (refs = ids) => {
      if (refs.length) {
        debug && console.debug("updateState", refs)
        setState(prevState => ({...refs.reduce((newState, id) => {
          const model = { name: getModelName(id) };
          const cacheSnapshot = getSnapshot(id);
          const cacheHit = cacheSnapshot && passFilters(cacheSnapshot);
          debug && console.debug(" - Update state for", id, model, cacheSnapshot, cacheHit, currentUser?.hasOwnership(cacheSnapshot, debug))
          if (id in newState && !cacheHit) {
            debug && console.debug(" - Delete this id from previous state")
            delete newState[id];
          } else if (cacheHit) {
            debug && console.debug(" - Update this id from previous state with", cacheSnapshot)
            newState[id] = cacheSnapshot;
          }

          return newState;
        }, prevState)}));
      } else if (!ids.length && models.length) {
        debug && console.debug("updateState without refs")
        let size = 0;
        setState(models.reduce((snaps, m) => {
          Object.entries(getSnapshot(m.name) || {}).forEach(([k, v]) => {
            const pass = passFilters(v, size);
            if (pass) {
              ++size;
              snaps[k] = v;
            }
          }); return snaps;
        }, {}));
      } else debug && console.debug("Skip updateState")
    }

  const [lastUpdate, setLastUpdate] = useState(undefined);
  const refreshLastUpdate = useCallback(_ => {
    const lastEvent = state?.metadata?.lastEvent || Object.values(state || {}).map(s => s.metadata.lastEvent).sort().pop();
    if (ready.current && lastEvent !== lastUpdate) setLastUpdate((debug && console.debug("Updating lastUpdate", lastEvent)) || lastEvent);
  }, [state?.metadata?.lastEvent || Object.values(state).map(s => (!s.metadata && console.log("Weird snap", s, state)) || s.metadata.lastEvent).sort().pop(), ready.current]);
  useEffect(_ => {
    refreshLastUpdate();
  }, [refreshLastUpdate])

  const idsChanged = Boolean(ids && prevIds && (ids.length !== prevIds.length || ids.some((id, idx) => prevIds[idx] !== id)));
  const modelsChanged = subscribedModels.current !== undefined && (models?.length !== subscribedModels.current?.length || models?.some((m, idx) => subscribedModels.current[idx].name !== m.name));
  useEffect(_ => {
    if (idsChanged || modelsChanged) updateState();
  }, [idsChanged, modelsChanged]);

  const onNewState = useCallback((changes, type) => {
    debug && console.debug("onNewState", type, changes)
    const idsChanged = ids.length && (type === "SNAPSHOT_READY" && !changes ? ids : ids.filter(id => changes.some(c => c.aggregate.id === id)));
    if ((ids?.length && idsChanged) || (!ids?.length && models.length)) updateState(ids?.length ? idsChanged : undefined);

  }, [ids, models.map(m => m.name).join(';'), Object.keys(subscription.current).join(';')]);

  useEffectOnMount(_ => () => stopEventRegistration.current?.());

  const onReady = _ => {
    debug && console.debug("onReady called for", models.map(m => m.name), ids, "hook was ready?", ready.current)
    if (ready.current) return;
    debug && console.debug("Subscriptions ready for", models.map(m => m.name))
    setLoading(false);
    ready.current = true;

    refreshLastUpdate();

    clientOnReady();
  };

  // Configure subscription
  const start = useCallback(_ => {
    if (currentUser?.metadata?.loadingContext || !models.length || subscribedModels.current !== undefined) return;

    debug && console.debug("Starting loading snapshots for", models.map(m => m.name), ids.length && `and ids ${ids}`, "- hookReady?", ready.current, "- loading?", loading)
    setLoading(true);
    ready.current = false;

    subscribedModels.current = models;

    const onSnapshotChange = ({ aggregate, snapshots: affectedSnapshots, page, type }) => {
      if (type === "PAGE_READY" || type === "NEW_PAGE") {
        debug && console.debug("New page", aggregate, type, page, "loading?", loading)
        pages.current[aggregate] = page;
        setState(page.docs.reduce((snaps, snap) => {snaps[snap.aggregate.id] = snap; return snaps;}, {}));
      } else {
        debug && console.debug("onSnapshotChange", type, affectedSnapshots, "loading?", loading)
        onNewState(affectedSnapshots, type);
      }
      const isReady = type === "SNAPSHOT_READY" || type === "PAGE_READY";
      if (isReady && debug) console.debug("subscription", aggregate, "ready, all models", models.map(m => m.name), "ready?", models.every(m => subscription.current[m.name]?.isReady()));
      if (isReady && models.every(m => subscription.current[m.name]?.isReady())) onReady();
    };

    // for all models the user has access to
    models.forEach(m => {
      let query = DataBase().snapshots(m);
      const modelIds = ids?.filter(id => m.isReference(id));

      let runORAsMultipleRequests =  (modelIds || []).length >= 100 || filters.length;

      if (modelIds.length) {
        query = query.withAny(...modelIds.map(id => ['id', '==', id]));
      } else {
        if (showArchived !== undefined) query = query.withMetadata('archived', showArchived);
        if (withProduct) query = query.withMetadata('product', Product.newURN(currentUser.metadata.selectedProductKey));
        if (periodUlids?.[0] !== undefined) query = query.withMetadata('lastEvent', '>=', periodUlids[0]).orderBy('metadata.lastEvent', 'desc');
        if (periodUlids?.[1] !== undefined) query = query.withMetadata('lastEvent', '<', periodUlids[1]);
        if (m.name === "User") { runORAsMultipleRequests = true; query = query.withAny([['job', '!=', 'patient']]); }
        if (m.name === "Study/Test/Marker" && options?.owner) query = query.withMetadata('parent', options.owner);
        
        // Adds filters
        if (filters?.length) query = query.withAny(...filters);

        // Adds owners filters
        if (filters.every(([attr]) => attr !== 'owners' && attr !== 'metadata.allOwners')) query = query.ownedBy(currentUser);

        if (sort) query = query.orderBy(sort.field, sort.order);
        if (limit) query = query.limit(limit);
      }

      // Note: runORAsMultipleRequests is enabled in those situations in which we detect we might be exceeding one of firestore limitations (e.g. limit of 100 disjunctions, limit on the number of fields using inequality operators like starts-with)
      subscription.current[m.name] = getSubscription(m.name, modelIds, query, { usePagination: pageSize !== undefined, visited, setVisited, runORAsMultipleRequests, callback: onSnapshotChange, debug, disableCache, onError: (reason) => {
        setLoading(false);
        setError(reason);
        onError?.(reason);
      } });
      if (pageSize !== undefined) subscription.current[m.name].paginate();
      else if (listenToChanges) subscription.current[m.name].start();
      else subscription.current[m.name].fetch();

    });

  }, [Boolean(currentUser?.metadata?.loadingContext), dateRange?.join(';'), showArchived, withProduct, ready.current, loading, currentUser, currentUser?.metadata?.selectedProductKey, listenToChanges, onNewState]);

  const goTo = direction => {
    debug && console.debug(`useSnapshot: ${direction} page requested`)
    if (subscribedModels.current === undefined || pageSize === undefined) return undefined;

    setState({});
    setLoading(true);
    debug && console.debug(`Getting ${direction} page`, pages.current);
    models.filter(m => pages.current[m.name]).forEach(m => {
      debug && console.debug(`Getting ${direction} page for`, m.name);
      pages.current[m.name] = pages.current[m.name][direction]();
    });
  }

  const reset = useCallback(_ => {
    if (subscribedModels.current !== undefined) {
      debug && console.debug("Reset subscription to snapshots for", models.map(m => m.name));
      setVisited?.([]);
      stop();
      setLoading(autoStart);
      ready.current = false;
      subscribedModels.current = undefined;
      if (Object.keys(state || {}).length) setState({});

      setError(undefined);
    }
    if (models?.length && autoStart) start();
  }, [stop, start, subscribedModels.current]);

  usePageChange(_ => stop(debug && console.debug("Cancel subscription to", models.map(m => m.name), "and ids", ids, "from page change")));

  const prevOwner = usePrevious(options?.owner);
  const prevOwners = usePrevious(currentUser?.metadata?.context ? currentUser?.data?.owners : undefined); // do not load user owners until user context is ready (might load data from multiple products otherwise)
  const prevSelectedProductKey = usePrevious(currentUser?.metadata?.selectedProductKey);
  const prevShowArchived = usePrevious(showArchived);
  const prevPeriod = usePrevious(dateRange);
  const prevFilters = usePrevious(filters.map(f => f.join(' ')).join('&'));
  const prevLimit = usePrevious(limit);
  const prevSort = usePrevious(sort && JSON.stringify(sort));
  const showArchivedChanged = prevShowArchived !== showArchived;
  const ownersChanged = (prevOwners !== undefined && !equals(prevOwners, currentUser?.data?.owners)) || (prevOwner !== undefined && prevOwner !== options?.owner);
  const selectedProductKeyChanged = prevSelectedProductKey !== currentUser?.metadata?.selectedProductKey; // Necessary if the owners don't change but the selected product does change, as we have filters that apply to the product
  const periodChanged = prevPeriod !== undefined && !equals(prevPeriod, dateRange);
  const filtersChanged = (prevFilters !== undefined && prevFilters !== filters.map(f => f.join(' ')).join('&'));
  const limitChanged = (prevLimit !== undefined && prevLimit !== limit);
  const sortChanged = (prevSort !== undefined && prevSort !== (sort && JSON.stringify(sort)))

  useEffect(_ => {
    const triggerReset = filtersChanged || sortChanged || idsChanged || modelsChanged || ownersChanged || selectedProductKeyChanged || showArchivedChanged || periodChanged || limitChanged;
    const triggerStart = subscribedModels.current === undefined && models?.length && autoStart;
    const triggerReady = subscribedModels.current === undefined && models?.length === 0 && currentUser?.data;
    debug && console.debug("useSnapshot change detected for models", models.map(m => m.name), "and ids", ids, "- filtersChanged?", filtersChanged, "- sortChanged?", sortChanged, "- idsChanged?", idsChanged, "- modelsChanged?", modelsChanged, "- ownersChanged?", ownersChanged,
    "- selectedProductKeyChanged?", selectedProductKeyChanged, "- showArchivedChanged?", showArchivedChanged, "- reset?", triggerReset, "- start?", triggerStart, "- ready?", triggerReady)
    if (idsChanged && debug) console.debug(" - Ids change - prev:", prevIds, "- new:", ids)
    if (modelsChanged && debug) console.debug(" - Models change - prev:", subscribedModels.current, "- new:", models.map(m => m.name), idModels)
    if (filtersChanged && debug) console.debug(" - Filters change - prev:", prevFilters, "- new:", filters.map(f => f.join(' ')).join('&'))
    if (limitChanged && debug) console.debug(" - Limit change - prev:", prevLimit.current, "- new:", limit);
    if (sortChanged && debug) console.debug(" - Sort change - prev:", prevSort.current, "- new:", sort && JSON.stringify(sort));
    if (triggerReset) {
      reset();
    } else if (triggerStart) {
      start();
    } else if (triggerReady) {
      subscribedModels.current = models;
      onReady();
    }
  }, [start, reset, onReady, filtersChanged, sortChanged, idsChanged, modelsChanged, ownersChanged, selectedProductKeyChanged, showArchivedChanged, periodChanged, limitChanged, Boolean(autoStart), models?.map(m => `${m.context}/${m.name}`).sort().join(';')]);

  useEffectOnMount(_ => _ => stop(debug && console.debug("Cancel subscription to", models.map(m => m.name), "and ids", ids, "from component unmount")));

  debug && console.debug("useSnapshot output", {ids, state, loading, idsChanged, modelsChanged, showArchivedChanged, periodChanged})
  const data = ids.length === 1 ? state[ids[0]] : state;
  const loadingData = Boolean(loading || filtersChanged || idsChanged || modelsChanged || showArchivedChanged || periodChanged);
  const startOrNext = _ => subscribedModels.current === undefined || pageSize === undefined ? start() : goTo('next');
  const stopOrPrev = _ => pageSize === undefined ? (listenToChanges ? stop() : () => {}) : goTo('prev');
  return [data, loadingData, startOrNext, stopOrPrev, reset, lastUpdate, error];
}

export default (...args) => useSnapshots(...args);