import Progress from 'react-progress-2';

import { resolve } from 'services/server/functions/model';
import { dataFrom, getModelName, isURN } from 'services/server/functions/executor/urn';

Progress.tryShow = () => { try { Progress.show(); } catch {} }
Progress.tryHide = () => { try { Progress.hide(); } catch {} }

const wrapSnapshot = (snapshot, model=(snapshot ? resolve(snapshot) : {})) => {
  return ({
    ...snapshot,
    getEvents: () => snapshot?.metadata.events
      .map(e => ({ ...e, type: dataFrom(e.id).type }))
      .filter(e => model.events[e.type]) // filter out any "old" not currently supported event. (just in case)
      .map(e => ({ ...e, aggregate: snapshot.aggregate, label: model.events[e.type].label }))
  });
};

let persistedSnapshots = {};
let subscriptions = {};
const cancelSubscriptions = _ => {
  Object.values(subscriptions).forEach(s => s.unsubscribe?.()); 
  subscriptions = {};
  persistedSnapshots = {};
}
window.addEventListener("beforeunload", cancelSubscriptions); // Cancel subscriptions on tab closed
require('services/iam').onSignout(cancelSubscriptions);

const snapshotReducer = (action) => {
  Progress.tryShow();
  try {
    const {subscriptionId, type, snapshots, aggregate} = action;
  
    switch(type) {
      case 'SNAPSHOT_CREATED':
      case 'SNAPSHOT_UPDATED':
      case 'SNAPSHOT_READY':
        if (!persistedSnapshots[aggregate]) persistedSnapshots[aggregate] = { snapshots: {}, ready: false };
        snapshots?.length && snapshots.forEach(snap => {persistedSnapshots[aggregate].snapshots[snap.aggregate.id] = wrapSnapshot(snap);});
        if (type === "SNAPSHOT_READY") {
          persistedSnapshots[aggregate].ready = true;
          action.debug && console.debug(`state ${aggregate} ready`)
          if (!action.snapshots) action.snapshots = Object.values(persistedSnapshots[aggregate].snapshots);
          //console.dir(persistedSnapshots[aggregate])
        }
        break;
      case 'SNAPSHOT_DELETED':
        snapshots.forEach(snap => {delete persistedSnapshots[aggregate].snapshots[snap.aggregate.id]});
        break;
      default: throw new Error('should never happen');
    }

    // subscriptions[aggregate] might be undefined when we receive events from aggregates to which we are not subscribed (due to permissions or because we don't need a subscription like Role aggregate)
    if (persistedSnapshots[aggregate].ready) Object.entries(subscriptions).filter(([sId, s]) => sId === subscriptionId || s.aggregate === aggregate).forEach(([_, s]) => (s?.listeners || []).forEach(l => l(action)));
  } finally {
    Progress.tryHide();
  }
};

const eventListeners = [];
export const registerEventListener = (listener) => {
  eventListeners.push(listener);
  return () => eventListeners.splice(eventListeners.findIndex(l => l === listener), 1)
};
const notifyNewEvent = evt => eventListeners.forEach(l => l.model.name === evt.aggregate.name && l.onNewEvent(evt))

export const eventReducer = (event) => {
  notifyNewEvent(event);

  if (!Object.values(subscriptions).filter(s => s.aggregate === event.aggregate.name).length) return;
  
  // If we are not subscribed (due to read permissions or because we don't need to subscribe like Role aggragte) but we execute a command, we might receive events from aggregates not subscribed.
  // Example: trial users do not have read permissions but can execute commands and we want to be able to read info from command events (since it is information generated locally)
  persistedSnapshots[event.aggregate.name] = persistedSnapshots[event.aggregate.name] || {snapshots: {}, ready: true};

  const prevSnap = persistedSnapshots[event.aggregate.name].snapshots[event.aggregate.id];
  const change = require('services/server/functions/snapshot').updateSnapshot(prevSnap, event);
  snapshotReducer({
    aggregate: event.aggregate.name, 
    type: change[0].metadata.deleted ? 'SNAPSHOT_DELETED' : change[1] === undefined ? 'SNAPSHOT_CREATED' : 'SNAPSHOT_UPDATED', 
    snapshots: change.slice(0, 1)
  });
  
  event.triggeredEvents?.forEach(eventReducer);
};

export const getSubscription = (aggregate, ids, query, options={}) => { // TODO: agrgegate argument should not be needed, look for a proper way to remove that parameter and get it from query or snapshots instead
    const subscriptionId = query.toString();

    let _started = false;
    let _subscription = subscriptions[subscriptionId];

    const {callback, onError, debug, disableCache, ...dbOptions} = options;

    const fetchCache = _ => {
        if (!disableCache && persistedSnapshots[aggregate]?.ready) {
            debug && console.debug("Using cached snapshots for", aggregate, ids)
            callback({type: 'SNAPSHOT_READY', snapshots: Object.values(persistedSnapshots[aggregate].snapshots).filter(v => !ids?.length || ids.includes(v.aggregate.id))});
            return true;
        }

        return false;
    }

    const withCallback = page => {
      const newPageCb = newPage => {
        debug && console.debug('New page received for', subscriptionId, ':', page);
        snapshotReducer({ aggregate, type: 'SNAPSHOT_CREATED', snapshots: newPage.docs });
        callback({ aggregate, type: 'NEW_PAGE', page: withCallback(newPage) });
      };
      
      return {
        docs: page.docs,
        prev: _ => page.prev().then(newPageCb),
        next: _ => page.next().then(newPageCb)
      }
    };
    
    const _this = _ => Object.freeze({
        start: _ => {
            if (_started) return _this();
            _started = true;
            
            if (!_subscription) {
                debug && console.debug('Subscribing to snapshots in background. Query:', subscriptionId);
            
                subscriptions[subscriptionId] = query
                  .onDeleted(snapshots => (debug && console.log("onDelete:", snapshots)) || (subscriptions[subscriptionId] && snapshotReducer({ subscriptionId, aggregate, type: 'SNAPSHOT_DELETED', snapshots })))
                  .onCreated(snapshots => (debug && console.log("onCreated:", snapshots)) || (subscriptions[subscriptionId] && snapshotReducer({ subscriptionId, aggregate, type: 'SNAPSHOT_CREATED', snapshots })))
                  .onUpdated(snapshots => (debug && console.log("onUpdated:", snapshots)) || (subscriptions[subscriptionId] && snapshotReducer({ subscriptionId, aggregate, type: 'SNAPSHOT_UPDATED', snapshots })))
                  .onReady  (_         => (debug && console.log("onReady:", subscriptionId)) || (subscriptions[subscriptionId] && snapshotReducer({ subscriptionId, aggregate, type: 'SNAPSHOT_READY' })))
                  .onError  (reason    => subscriptions[subscriptionId] && (console.error("subscription error:", reason) || onError?.(reason)))
                  .subscribe({...dbOptions, debug});

                _subscription = subscriptions[subscriptionId];
                _subscription.aggregate = aggregate;
                _subscription.listeners = [];
            }

            fetchCache();

            debug && console.debug('Listening to changes of', subscriptionId);
            _subscription.listeners.push(callback);

            return _this();
        },
        paginate: _ => {
            debug && console.debug('Paginate snapshots in background. Query:', subscriptionId);

            query.paginate({...dbOptions, debug}).then(page => {
                debug && console.debug('First page received for', subscriptionId, ':', page);
                snapshotReducer({ aggregate, type: 'SNAPSHOT_READY', snapshots: page.docs });
                callback({ aggregate, type: 'PAGE_READY', page: withCallback(page) }); // must be after snapshotReducer so that persistedSnapshots gets updated
            }).catch(error => onError?.(error?.message || error));

            return _this();
        },
        fetch: _ => {
            debug && console.debug('Fetching snapshots in background. Query:', subscriptionId);

            query.get({...dbOptions, debug}).then(snapshots => {
                snapshotReducer({ aggregate, type: 'SNAPSHOT_READY', snapshots });
                callback({ aggregate, type: 'SNAPSHOT_READY', snapshots }); // must be after snapshotReducer so that persistedSnapshots gets updated
            }).catch(error => onError?.(error?.message || error));

            return _this();
        },
        unsubscribe: _ => {
            _started = false;
            if (_subscription?.listeners?.length) _subscription.listeners.splice(_subscription.listeners.findIndex(l => l === callback), 1);
            if (_subscription && !_subscription.listeners?.length) {
                _subscription.unsubscribe?.();
                delete subscriptions[subscriptionId];
            }
        },
        isReady: _ => persistedSnapshots[aggregate]?.ready
    });

    return _this();
}

export const getSnapshot = (idOrAggregate) => isURN(idOrAggregate) ? persistedSnapshots[getModelName(idOrAggregate)]?.snapshots[idOrAggregate] : persistedSnapshots[idOrAggregate]?.snapshots;