import { browserEventSubscribe } from "features/helpers/browserEvent";
import { useEffect, createContext } from "react";

export const BrowserNotificationContext = createContext();

export const BrowserEventType = Object.freeze({
    StorageGetItemFails: 'StorageGetItemFails',
    StorageSetItemFails: 'StorageSetItemFails',
    StorageAlmostFull: 'StorageAlmostFull',
});

export const BrowserNotificationProvider = ({ children }) => { //@meir we should not rely on the local storage if we don't have space we just don't store anything but the app MUST work anyway (it just may be slower, but that's fine!)
    const handleStoragetAlmostFull = () => console.log('Storage almost full');

    useEffect(() => {
        browserEventSubscribe(BrowserEventType.StorageAlmostFull, handleStoragetAlmostFull);
    }, []);

    return <>{children}</>;
};