import HelpTooltip from 'ui/components/HelpTooltip';
import './style.css';

const REQUIRED_FIELD_SYMBOL = '*';

export const TitleField = ({
    id, children, help, required, className = ""
}) => (
    <legend id={id} className={`schema-form-title ${className}`}>
        <div>
            {children}
            {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
        </div>
        { help && <HelpTooltip content={help} />}
    </legend>
);