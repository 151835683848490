import DetailsBlock from 'ui/components/DetailsBlock';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { StudyLanding } from 'modules/diagnosis';
import { Text } from 'ui/components/Text';
import { getImgUrlByLang, useAssetsURL } from 'ui/hooks/useAssetsUrl';

const Question = () => {
    const { assetsURl } = useAssetsURL();

    return (
        <>
            {assetsURl && (
                <div className="pageSection grid-row">
                    <span className="stepsIntro">
                        <Text>Help.results-where.answer-0</Text>
                    </span>
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <h3>
                                <Text>Help.results-where.answer-1</Text>
                            </h3>
                            <span>
                                <Text>
                                    component.faq.access_to_menu_instructions
                                </Text>
                                <StudyLanding.AsLink
                                    id="studiesSection"
                                    text="studies"
                                />
                                .
                            </span>
                        </div>
                        <div className="largeInstructionImg">
                            <img
                                alt=""
                                src={getImgUrlByLang(
                                    assetsURl,
                                    'study-menu-item'
                                )}
                            />
                        </div>
                    </DetailsBlock>
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <h3>
                                <Text>Help.results-where.answer-3</Text>
                            </h3>
                            <span>
                                <Text>Help.results-where.answer-4</Text>
                            </span>
                        </div>
                        <div className="largeInstructionImg">
                            <img
                                alt=""
                                src={getImgUrlByLang(assetsURl, 'search-study')}
                            />
                        </div>
                    </DetailsBlock>
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <h3>
                                <Text>Help.results-where.answer-5</Text>
                            </h3>
                            <span>
                                <Text>Help.results-where.answer-6</Text>
                            </span>
                        </div>
                        <div className="largeInstructionImg">
                            <img
                                alt=""
                                src={getImgUrlByLang(
                                    assetsURl,
                                    'view-study-summary'
                                )}
                            />
                        </div>
                    </DetailsBlock>
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <h3>
                                <Text>Help.results-where.answer-7</Text>
                            </h3>
                            <span>
                                <Text>Help.results-where.answer-8</Text>
                            </span>
                        </div>
                        <div className="largeInstructionImg">
                            <img
                                alt=""
                                src={getImgUrlByLang(
                                    assetsURl,
                                    'view-export-test'
                                )}
                            />
                        </div>
                    </DetailsBlock>
                </div>
            )}
        </>
    );
};

Question.question = Text.resolve('Help.results-where.question');
Question.roles = Study.queries.FIND_SLEEP_STUDY.roles;

export default Question;
