import { useContext } from 'react';
import { UserContext } from 'features/providers/userContextProvider';
import DetailsBlock from 'ui/components/DetailsBlock';
import ExportReport from 'ui/components/ExportReport';
import { Study } from 'services/server/functions/model/diagnosis/model';
import moment from 'moment';
import Action from 'ui/components/Action';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { CustomModal } from 'ui/acukit/ModalDialog';
import { Text as I18N } from 'ui/components/Text';
import { useSnapshots } from 'ui/hooks/useSnapshot';

import i18n from 'services/server/functions/i18n';

const Text = I18N.withContext({ component: "DownloadStudyDetails" });

const I18n = Text.resolve;

const exportLetter = (studies, product) => async () => {
  const getLetters = _ => require('services/server/functions/executor/executor.client').getReport(studies.map(s => ({ product, reportId: 'diagnosis.Study.letter', id: s.data.id})));
  return `data:data:application/pdf;base64,${await getLetters()}`; // TODO: export PDF assumes data URI. remove everywhere the use of data URIs and use URL.createObjectURL
};

const exportActivationCodes = (studies, product) => async () => {
  const language = i18n.current;
  const getActivationCodes = _ => require('services/server/functions/executor/executor.client').getReport(studies.map(s => ({ product, reportId: 'diagnosis.Study.activationCode', id: s.data.id, language, })));
  return `data:data:application/pdf;base64,${await getActivationCodes()}`; // TODO: export PDF assumes data URI. remove everywhere the use of data URIs and use URL.createObjectURL
};

const exportCSV = (data, props) => () => {
  // TODO: create a CSV report in order to generate it in the backend better
  const model = props.model;
  const schema = model.schema.tailor('csv').tailor('export'); // this will serialize object values into valid strings for CSVs
  const fields = props.fields || model.describe(schema).fields.flatten().withMeta(meta => meta?.csv?.header).map(f => f.field);
  props.append ??= {};
  Object.keys(props.append).forEach(f => fields.push(f));
  const context = { delimiter: ',', quote: '"', ...props.prefs?.context };

  const R = require('ramda');
  const get = (field, object) => R.view(R.lensPath(field.split('.')), object);
  
  const toRow = ({data: s}) => {
    const { value, error } = schema.validate(s, { ...props?.prefs, context, allowUnknown: true }); 
    if (error) { // UGLY
      try {
        console.error(s, error); // there should not be any error at this point. but we should notify of this somehow
        require('services/server/functions/errors').reportError(`Error validating data for fulfilment CSV export`, JSON.stringify(error))
      } catch (e) {
        console.error(e);
      }
    }
    return fields.map(f => f in props.append ? props.append[f] : get(f, value)).join(context.delimiter);
  }
  
  const header  = fields.join(context.delimiter);
  const content = data.map(toRow).join('\n');
  const base64  = Buffer.from(`${header}\n${content}`).toString('base64');

  return `data:data:text/csv;base64,${base64}`; // TODO: export assumes data URI. remove everywhere the use of data URIs and use URL.createObjectURL
}

const DownloadStudyDetails = ({studies:inputStudies=[], data, csv, onFinish}) => { // do not pass data and studies at the same time ... TODO: likely needs refactoring, very UGLY
  require('./style.css');
  const { selectedProduct } = useContext(UserContext);
  const [snapshots, loadingSnaps] = useSnapshots(...inputStudies.map(s =>  s.data?.id || s.id || s)); // if data is provided directly, we assume data is a full instance which may contain other unknown fields to be exported in the CSV (potentially the PDF too)
  const studies = data?.length ? data.map(d => d.data ? d : ({ data: d })) : snapshots?.data ? [ snapshots ] : inputStudies.map(s => snapshots[s.data?.id || s.id || s]).filter(Boolean); 
  //console.log("DownloadStudyDetails studies", studies)
  const loading = !data?.length && loadingSnaps;

  const [currentUser]     = useCurrentUser(),
        hasAccess         = currentUser.hasAccess,
        canGetCSV         = hasAccess(Study.queries.GET_ACTIVITY_REPORT),
        canGetLetter      = hasAccess(Study.queries.GET_STUDY_LETTER),
        canGetActivationCodes = hasAccess(Study.queries.GET_ACTIVATION_CODE);

  const studyDate = moment().format("L");

  return (
    <>
      <div className="pageSection" id="StudyInstructions">
        <DetailsBlock inline>
          <div className="grid-row"> 
            <h3>{I18n("instructions-print")}</h3>
            <span>{`${I18n("instructions-text1")}${canGetCSV ? ` ${I18n("instructions-text1-csv")}` : ""}`}</span>
            <span>{I18n("instructions-text2")}</span>
            <div className="grid-col fitContent centered">
              <ExportReport disabled={loading || !canGetLetter} label={I18n("global.download_pdf_message")} secondary downloadFile={`StudyBatch_letters_${studyDate}.pdf`} id="exportLetter" urlCallback={exportLetter(studies, selectedProduct?.key)} />
              <ExportReport disabled={loading || !canGetCSV} type="csv" label={I18n("instructions-download-csv")} secondary downloadFile={`StudyBatch_details_${studyDate}.csv`} id="exportFulfillment" urlCallback={exportCSV(studies, csv)} />
              <ExportReport disabled={loading || !canGetActivationCodes} label={I18n("instructions-download_activation_codes_pdf")} secondary downloadFile={`StudyBatch_activation_codes_${studyDate}.pdf`} id="exportActivationCodes" urlCallback={exportActivationCodes(studies, selectedProduct?.key)} />
            </div>
          </div>
          <img className="codeImage printImg" alt="" src={require('../CreateStudy/images/printer-code.png').default}/>
        </DetailsBlock>
      </div>
      <div className='pageFooter'>
        <div className='actions'>
          <Action className='button primary' handleAction={onFinish} label={'finish'} />
        </div>
      </div>
    </>
  );
};

DownloadStudyDetails.AsModalAction = ({heading='Download-CSV-and-Instructions', label='Download-CSV-and-Instructions', title='Download-CSV-and-Instructions', studies, data, csv, ...props}) => {
  
  return (
    <CustomModal id='DownloadStudyDetailsModal'
        heading={I18n(heading)}
        label={I18n(label)}
        title={I18n(title)}
        cancelLabel={I18n("close")}
        {...props}
    >
      <DownloadStudyDetails studies={studies} data={data} csv={csv} />
    </CustomModal>
  );
}

DownloadStudyDetails.displayName= "DownloadStudyDetails";
DownloadStudyDetails.AsModalAction.displayName= "DownloadStudyDetailsModal";

export default DownloadStudyDetails;