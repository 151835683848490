import {
  getWidget,
  getUiOptions,
  optionsList,
  hasWidget,
} from '@rjsf/utils';

export const StringField = (props) => {
    const {
        schema,
        name,
        uiSchema,
        idSchema,
        formData,
        required,
        disabled = false,
        readonly = false,
        autofocus = false,
        onChange,
        onBlur,
        onFocus,
        registry,
        rawErrors,
    } = props;
    const { title, format } = schema;
    const { widgets, formContext, schemaUtils, globalUiOptions } = registry;
    const enumOptions = schemaUtils.isSelect(schema) ? optionsList(schema) : undefined;
    let defaultWidget = enumOptions ? 'select' : 'text';
    if (format && hasWidget(schema, format, widgets)) {
        defaultWidget = format;
    }
    const { widget = defaultWidget, placeholder = '', title: uiTitle, ...options } = getUiOptions(uiSchema);
    const displayLabel = schemaUtils.getDisplayLabel(schema, uiSchema, globalUiOptions);
    const label = uiTitle ?? title ?? name;
    const Widget = getWidget(schema, widget, widgets);
    
    return (
        <Widget
            options={{ ...options, enumOptions }}
            schema={schema}
            uiSchema={uiSchema}
            id={idSchema.$id}
            name={name}
            label={label}
            hideLabel={!displayLabel}
            value={formData}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            required={required}
            disabled={disabled}
            readonly={readonly}
            formContext={formContext}
            autofocus={autofocus}
            registry={registry}
            placeholder={placeholder}
            rawErrors={rawErrors}
        />
    );
};
 