import { Fragment, useContext, useEffect, useState } from 'react';
import { UserContext } from 'features/providers/userContextProvider';
import { configurations, projectRegion, currentRegion, setCurrentRegion, isHostingChannel, getHostingChannelRegion } from 'services/server/functions/firebase/config/config.client';
import { getQueryParams } from 'ui/hooks/useLocationParams';

const getRedirectOrigin = (region) => {
  if (!isHostingChannel) return `${window.location.protocol}//${configurations[region].authDomain}`;
  const currentOrigin = window.location.origin;
  const hostingChannelRegion = getHostingChannelRegion(currentOrigin);
  return currentOrigin.replace(configurations[hostingChannelRegion].projectId, configurations[region].projectId);
};

const redirectToRegion = (region) => {
  console.debug("redirectToRegion", region, "isHostingChannel", isHostingChannel);
  const params = new URLSearchParams(window.location.search);
  params.set('region', region);
  params.delete('useRegionRedirect');
  const origin = getRedirectOrigin(region)
  const url = new URL(window.location.pathname, origin);
  url.search = params.toString();
  const newHref = url.toString();
  console.log("redirecting to", newHref);
  window.location.href = newHref;
};

const isInLocalhost = window.location.hostname === "localhost";
const { useRegionRedirect } = getQueryParams();
const doRegionRedirect =  isInLocalhost ? useRegionRedirect === "true" : useRegionRedirect !== "false";

const useRegion = () => {
  const contextState = useContext(UserContext);
  const [region, setRegion] = useState(currentRegion);

  useEffect(() => {
    if (region && region !== projectRegion && doRegionRedirect) redirectToRegion(region);
    else if (region) setCurrentRegion(region, contextState.selectedProduct);
  }, [region]);

  const availableRegions = Object.keys(configurations).filter(config => config !== 'localisation');

  return [region, setRegion, availableRegions];
}

// TODO maybe find a better place for these components ?
export const Region = ({ target, children }) => {
  const [currentRegion] = useRegion();
  const predicate = Array.isArray(target) ? r => target.includes(r) :
    target instanceof Function ? target :
      r => r === target;

  return predicate(currentRegion) ? children : <Fragment />;
}

Region.US = ({ children }) => <Region target='us'>{children}</Region>;
Region.EU = ({ children }) => <Region target='eu'>{children}</Region>;
Region.UK = ({ children }) => <Region target='uk'>{children}</Region>;
Region.CE = ({ children }) => <Region target={['uk', 'eu']}>{children}</Region>;
Region.FDA = ({ children }) => <Region target={['us']}>{children}</Region>;

export const Language = ({target, children}) => {
  const { selectedProduct } = useContext(require('features/providers/userContextProvider').UserContext); // update when context (product) changes, as product translations can change

  const [targetLang, targetProduct] = target.split("-x-");
  const currentLang = require('services/server/functions/i18n').default.current;
  const showLanguage = currentLang === targetLang && targetProduct === selectedProduct?.key;
  return showLanguage ? children : <Fragment />;
}

export default useRegion