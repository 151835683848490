import * as R from "ramda";

export const groupBy = (arr, key) => R.groupBy(R.path(key.split(".")))(arr);

export const getProperty = (object, attribute = "") => R.path(attribute.split("."), object);

// property can be a string separated by "." or an array indicating the path
export const setProperty = (object, property, value) => {
    const path = Array.isArray(property) ? property : property.split('.');
    const lastKey = path.pop();
    let target = object;
    for (const key of path) {
        if (target[key]?.constructor !== Object) {
            target[key] = {};
        }
        target = target[key];
    }
    target[lastKey] = value;
    return object;
};

export const flattenObject = (obj = {}, prefix = '') => {
    return Object.keys(obj).reduce((acc, key) => {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (obj[key]?.constructor === Object) {
            Object.assign(acc, flattenObject(obj[key], newKey));
        } else {
            acc[newKey] = obj[key];
        }
        return acc;
    }, {});
};

export const nestObject = (obj = {}) => {
    return Object.keys(obj).reduce((acc, key) => {
        const path = key.split(".");
        const lastKey = path.pop();
        let target = acc;
        path.forEach(k => {
            if (target[k]?.constructor !== Object) {
                target[k] = {};
            }
            target = target[k];
        });
        target[lastKey] = obj[key];
        return acc;
    }, {});
};

const _deepMerge = (target = {}, source = {}) => {
    for (const key in source) {
        if (source[key]?.constructor === Object) {
            if (!target[key]) {
                Object.assign(target, { [key]: {} });
            }
            _deepMerge(target[key], source[key]);
        } else {
            Object.assign(target, { [key]: source[key] });
        }
    }
    return target;
};

export const deepMerge = (target, ...sources) => {
    for (const source of sources) {
        target = _deepMerge(target, source);
    }
    return target;
};

export const deepClone = (obj = {}) => {
    return JSON.parse(JSON.stringify(obj));
};

export const mapToInheritanceData = (oldObj = {}, newObj = {}, { flatten = true, reset = false } = { flatten: true, reset: false }) => {
    if (reset) return {};

    const flattennedOldObj = flatten ? flattenObject(oldObj) : oldObj;
    const flattennedNewObj = flatten ? flattenObject(newObj) : newObj;

    const flattennedMappedData = Object.keys(flattennedNewObj).reduce((acc, flatKey) => {
        const newObjValue = flattennedNewObj[flatKey];
        const oldObjValue = flattennedOldObj[flatKey];
        // inheritance logic
        if (newObjValue === oldObjValue) {
            delete acc[flatKey];
        }
        return acc;
    }, deepClone(flattennedNewObj));

    Object.keys(flattennedOldObj).forEach(flatKey => {
        const oldObjValue = flattennedOldObj[flatKey];
        const newObjValue = flattennedNewObj[flatKey];
        // unset logic
        if (newObjValue === undefined && oldObjValue !== undefined) {
            flattennedMappedData[flatKey] = null;
        }
    });

    const data = flatten ? nestObject(flattennedMappedData) : flattennedMappedData;
    return data;
};

export const getResetDiff = (oldObj = {}, newObj = {}, flatten = true) => {
    const flattennedOldObj = flatten ? flattenObject(oldObj) : oldObj;
    const flattennedNewObj = flatten ? flattenObject(newObj) : newObj;
    const resetFields = [];

    const flattennedMappedData = Object.keys(flattennedOldObj).reduce((acc, flatKey) => {
        const newObjValue = flattennedNewObj[flatKey];
        const oldObjValue = flattennedOldObj[flatKey];
        if (newObjValue === undefined && oldObjValue !== undefined) {
            resetFields.push(flatKey);
            acc[flatKey] = null;
        }
        return acc;
    }, deepClone(flattennedNewObj));

    return {
        diff: flatten ? nestObject(flattennedMappedData) : flattennedMappedData,
        resetFields,
    };
};

export const deleteNestedFields = (obj, fields) => {
    for (const field of fields) {
        const path = field.split('.');
        let currentObj = obj;
        for (const key of path) {
            if (currentObj[key]?.constructor === Object) {
                currentObj = currentObj[key];
            } else {
                // Field doesn't exist or is not an object, skip to the next field
                break;
            }
        }
        delete currentObj[path.at(-1)];
    }
    return obj;
}

export const findLast = (array = [], predicate) => {
    for (let i = array.length - 1; i >= 0; --i) {
        if (predicate(array[i])) return array[i];
    }
    return undefined;
};

export const promiseAllInBatches = async (promises, batchSize = 1) => {
    const resultsArray = [];
    let startIndex = 0;
    while (startIndex < promises.length) {
        const promisesForBatch = promises.slice(startIndex, startIndex + batchSize);
        // eslint-disable-next-line no-await-in-loop
        const batchResults = await Promise.all(promisesForBatch.map((promise) => promise()));
        resultsArray.push(...batchResults);
        startIndex += batchSize;
    }
    return resultsArray;
};

// By default removes all null and undefined values
const removeNullOrUndefined = (obj, filterFn = (value) => value !== null && value !== undefined) => {
    return Object.keys(obj).reduce((acc, curr) => {
        if (filterFn(obj[curr])) {
            acc[curr] = obj[curr];
        }
        return acc;
    }, {});
};

export const removeNullOrUndefinedNested = (obj, filterFn) => {
    return nestObject(removeNullOrUndefined(flattenObject(obj), filterFn));
};