import { toPascalCase } from 'features/helpers/objectHelper';
import { Text } from "ui/components/Text";

import AdministrationConfig from "services/server/functions/model/administration/config";

const getBrandTitle = (productKey) => {
    const i18nKey = `global.brand.product.${productKey}.title`;
    let productLabel = Text.resolve(i18nKey); // @meir why the product key is not translated ???
    if (productLabel === i18nKey) productLabel = `AcuPebble ${toPascalCase(productKey)}`;
    return productLabel;
}
  
export const prepareProduct = (product) => {
    if (product?.id) {
        const { id, key } = product;
        return ({
            id,
            key,
            value: id,
            owners: product?.owners,
            label: getBrandTitle(key)
        });
    }
};

export const getProductUnits = (contextState, userContext) => {
    return (contextState.owners || []).filter(unit => unit.data.id === AdministrationConfig.organisation.root.id || !userContext || userContext.units.find(u => u.id === unit.data.id)?.products.some(p => p.key === contextState.selectedProduct.key));
};