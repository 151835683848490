export const SchemaFormVariantTypes = Object.freeze({
    Toggle: "toggle",
    Select: "select"
});

export const SchemaFormFeatureTypes = Object.freeze({
    Empty: "Empty",
    Yes: "Yes",
    No: "No"
});

export const SchemaFormButtonTypes = Object.freeze({
    Submit: "submit",
    Button: "button"
});