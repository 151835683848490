import 'ui/theme/find.css';

import Activity from 'ui/components/ActivityLog';
import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Device } from 'services/server/functions/model/devices/model';
import { dataFrom } from 'services/server/functions/executor/urn';
import useSnapshot from 'ui/hooks/useSnapshot';
import { useState } from 'react';

const ActivityLogUI = ({activity, deviceId, onDeviceIdChangedHandler, I18n}) => (
  <div id='DeviceActivityLog' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
    </div>
    <div className='pageContent'>
      <div className="pageSection">
        <div className='title'>{I18n('search-by')}</div>
        <DetailsBlock>
          <div className='filter grid-row'>
            <input id="Batch.batchIdOrSWIdOrHWId" placeholder={I18n("device-id")} onChange={(e) => onDeviceIdChangedHandler(e.target.value)} value={deviceId}/>
          </div>
        </DetailsBlock>
      </div>
      <Activity noBox hideTitle aggregate={Device.name} activity={activity} />
    </div>
  </div>
);

export default Object.assign(props => {  
  const [devSnaps] = useSnapshot(Device);
  const [deviceId, setDeviceId] = useState("");

  return ActivityLogUI({ 
    activity                 : Object.values(devSnaps).filter(d => !deviceId || dataFrom(d.data.id).id.toLowerCase().includes(deviceId?.toLowerCase())).reduce((events, snap) => events.concat(snap.getEvents()), []),
    onDeviceIdChangedHandler : setDeviceId,
    I18n                     : props.I18n
  });
}, {displayName: "Activity"});

