import { memo, useState, useEffect } from 'react';

import { FilterOption, FiltersOptionTypes } from 'features/components/FilterOption';
import { ModelFormTypes } from 'features/constants/modelActionTypes';
import { useSettingsValue } from 'features/hooks/useSettingsValue';
import { SwitchFilterModes } from 'features/components/FilterOption/SwitchFilter';

const REPORT_TYPES = {
    SIMPLIFIED: 'SIMPLIFIED', // Patient
    DETAILED: 'DETAILED', // Clinician
    CONDENSED: 'CONDENSED',
};

const FilterOptionWrapper = ({ filters = [], ...props }) => {
    const [filtersList, setFiltersList] = useState(filters);

    useEffect(() => {
        const hasChanged = filters.length !== filtersList.length;

        if (hasChanged) {
            setFiltersList(filters);
        };
    }, [filters]);

    const setTestsFilterToSingleSelection = (filtersList, testsFilter) => {
        if (testsFilter.mode === SwitchFilterModes.Single) return;

        testsFilter.mode = SwitchFilterModes.Single;
        const selectedOptions = testsFilter.options.filter(o => o.selected);
        if (selectedOptions !== 1) testsFilter.options.forEach((o, index) => {
            if (o.id === selectedOptions[0]?.id || index === 0) o.selected = true;
            else o.selected = false;
        })
        setFiltersList([...filtersList]);
    };
    
    const setTestsFilterToMultipleSelection = (filtersList, testsFilter) => {
        if (testsFilter.mode === SwitchFilterModes.Multiple) return;

        testsFilter.mode = SwitchFilterModes.Multiple;
        setFiltersList([...filtersList]);
    };

    // Sets selected nights to single selection
    useEffect(() => {
        const testsFilter = filtersList.find(f => f.fieldName === "tests");
        const modeFilter = filtersList.find(f => f.fieldName === "mode");
        if (!testsFilter || !modeFilter) return;
        
        if (modeFilter.options.find(o => o.id === REPORT_TYPES.CONDENSED)?.selected) {
            setTestsFilterToSingleSelection(filtersList, testsFilter)
        } else {
            setTestsFilterToMultipleSelection(filtersList, testsFilter);
        }
    }, [filtersList]);

    return <FilterOption
        {...props}
        filtersList={filtersList}
        setFiltersList={setFiltersList}
    />;
};

export const SleepReportSettingValue = memo(({ owner, testFilters, getCustomActions, disabled, onLoaded, ...props }) => {
    const [filters, setFilters] = useState([]);
    const [useSettingsArgs, setUseSettingsArgs] = useState({});

    const { settingValue, isLoading } = useSettingsValue(useSettingsArgs);

    const onOpen = () => {
        const id = ModelFormTypes.SleepReportSettings;
        setUseSettingsArgs({ id, owner });
    };
    
    useEffect(() => {
        if (!isLoading) onLoaded();
    }, [isLoading]);

    useEffect(() => {
        if (!settingValue) return;
        const formats = settingValue?.formats;
        const reportTypeFilters = {
            fieldName: 'mode',
            type: FiltersOptionTypes.Switch,
            title: 'module.diagnosis.fe.component.export.select_type',
            options: [
                { id: REPORT_TYPES.DETAILED, text: 'module.diagnosis.fe.component.export.clinician_option' },
                { id: REPORT_TYPES.SIMPLIFIED, text: 'module.diagnosis.fe.component.export.patient_report' },
                { id: REPORT_TYPES.CONDENSED, text: 'module.diagnosis.fe.component.export.condensed_report' },
            ],
        };

        reportTypeFilters.options = reportTypeFilters.options.reduce((acc, opt) => {
            const hasType = formats?.includes(opt.id) || !formats;
            if (hasType) {
                acc.push(opt);
            }
            return acc;
        }, []);
        if (reportTypeFilters.options.length) reportTypeFilters.options[0].selected = true;

        setFilters([reportTypeFilters, testFilters]);
    }, [settingValue]);
    
    return (
        <>
            {
                <FilterOptionWrapper
                    label="global.export"
                    disabled={disabled}
                    title="module.diagnosis.fe.component.export.title"
                    filters={filters}
                    onOpen={onOpen}
                    isLoading={isLoading}
                    getCustomActions={getCustomActions}
                    {...props}
                />
            }
        </>
    );
});



