import { useCallback } from "react";

import Details from "ui/components/Details";
import { AutoComplete } from "features/components/AutoComplete";
import { FiltersType } from "../../utils";

export const SeverityField = ({ filters, onFilterChange, items, isDisabled, help }) => {
    const handleChange = useCallback((option) => onFilterChange({ [FiltersType.Severity]: option?.value }), [onFilterChange]);
    return (
        <Details id="seveirtyDetails" label="ViewTest.diagnosis.severity" help={help}>
            <AutoComplete
                id={FiltersType.Severity}
                items={items}
                value={filters[FiltersType.Severity]}
                handleChange={handleChange}
                selectProps={{
                    isMulti: false,
                    isClearable: true,
                    isDisabled,
                }}
            />
        </Details>
    );
};