import moment from 'moment';

import { CalendarContainer } from 'react-datepicker';
import { subMonths, subQuarters, subYears} from 'date-fns';

import {
    getMonthRange, getQuarterRange, getYearRange
} from 'features/helpers/dateHelper';

import i18n from 'services/server/functions/i18n';

import './style.css';
import { getCalendarHeader } from './CalendarCustomHeader';

export const CalendarTypeOptions = Object.freeze({
    ThisMonth: "thisMonth",
    LastMonth: "lastMonth",
    ThisQuarter: "thisQuarter",
    LastQuarter: "lastQuarter",
    ThisYear: "thisYear",
    LastYear: "lastYear"
});

const changeCalendarDates = (id, action, setCalendarRange) => {
    try {
        const currentDate = new Date();
        const getDateRange = {
            [CalendarTypeOptions.ThisMonth]: getMonthRange,
            [CalendarTypeOptions.ThisQuarter]: getQuarterRange,
            [CalendarTypeOptions.ThisYear]: getYearRange,
            [CalendarTypeOptions.LastMonth]: () => getMonthRange(subMonths(currentDate, 1)),
            [CalendarTypeOptions.LastQuarter]: () => getQuarterRange(subQuarters(currentDate, 1)),
            [CalendarTypeOptions.LastYear]: () => getYearRange(subYears(currentDate, 1)),
        };
        const [startDayOfMonth, endDayOfMonth] = getDateRange[action]();
        setCalendarRange(id, { target: { value: [startDayOfMonth, endDayOfMonth] } });
    } catch (e) {
        console.error(e);
    }
};

export const getCalendarContainer = ({ id, onSelectRange }) => ({ className, children }) => {
    return (
        <CalendarContainer className={className}>
            <div className="calendar-container-root">
                <div className="calendar-container-block">
                    <span className="calendar-container-title">
                        {i18n.resolve('global.selectRange')}
                    </span>
                    {Object.values(CalendarTypeOptions).map(r => (
                        <span
                            key={r}
                            className="calendar-options"
                            onClick={() => changeCalendarDates(id, r, onSelectRange)}
                        >
                            {i18n.resolve(`global.${r}`)}
                        </span>
                    ))}
                </div>
                <div>
                    {children}
                </div>
            </div>
        </CalendarContainer>
    );
};


const minPeriodOfCalendar = moment().subtract(15, 'years').toDate();
const maxPeriod = moment().toDate();
export const calendarRangeProps = {
    selectsRange: true,
    showPreviousMonths: true,
    maxDate: maxPeriod,
    minDate: minPeriodOfCalendar,
    monthsShown: 1,
    showMonthDropdown: false,
    showYearDropdown: false,
    onYearChange: undefined,
    onMonthChange: undefined,
    renderCustomHeader: getCalendarHeader(),
};