import 'ui/theme/find.css';

import * as Pages from 'modules/administration';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { HealthcareSite } from 'services/server/functions/model/administration/model';
import QuickFilters from 'ui/components/QuickFilters';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import WithSnapshot from 'ui/components/WithSnapshot';
import history from 'history.js';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import { User } from 'services/server/functions/model/authentication/model';
import useProjection from 'ui/hooks/useProjection';

const FindSiteUI = ({ nameOrAddress, organisation, trial, healthcaresites, onNameOrAddressChanged, I18n }) => {
  const [currentUser] = useCurrentUser();
  
  return <div id='FindHealthcareSite' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
      <div className='actions'>
        {currentUser.hasAccess(Pages.CreateHCS) && <Pages.CreateHCS.AsLinkButton text="create" className="primary"/>}
      </div>
    </div>
    <div className="pageContent">
      <div className="pageSection">
        <div className='title'>{I18n('search-by')}</div>
        <DetailsBlock>
          <div className='filter grid-row'>
            <input id="HealthcareSite.nameOrAddress" placeholder={Text.resolve("name-email")} onChange={(e) => onNameOrAddressChanged(e.target.value)} value={nameOrAddress || ''}/>        
            <QuickFilters>
              { organisation && <Pages.ViewOrganisation.AsLinkIcon {...{ organisation, className: "quickfilter selected" }} options={{ skipContext: true }} /> }
              { trial && <Pages.ViewTrial.AsLinkIcon {...{ trial, className: "quickfilter selected" }} /> }
            </QuickFilters>
          </div>
        </DetailsBlock>
      </div>
      <div className="pageSection">
        <Table
          keyField='id'
          columns={{
            name   : { content: "global.name",         isSortable: true },
            address: { content: "address",      isSortable: true },
            orgName: { content: "global.organisation.label" }
          }}
          onRowClick={((hcs) => history.push(Pages.ViewHCS.PATH, {healthcaresite: hcs.id}))}
          data={healthcaresites.map(({organisation, ...site}) => ({orgName: <Pages.ViewOrganisation.AsText organisation={organisation} options={{ skipContext: true }} />, ...site}))} // TODO: maybe it is worth to use bootstrp table formatter to only render the rows in current page
          defaultSortCol={'name'}
          defaultSortDir={'asc'}
          emptyText={I18n('no-hcs-found')}
        />
      </div>
    </div>
  </div>;
};

const FindSitePresenter = (props)  => {
  const [{ organisation, trial, nameOrAddress }, setLocationState] = useLocationParams();
  const [usersSnap=[], _loading]                                   = useProjection(User.queries.LIST.newRequest(), {autoStart: trial!==undefined, refreshRate: 60000});

  const byNameOrAdress    = ({ name, address }) => nameOrAddress   ? `${name} ${address}`.toLowerCase().includes(nameOrAddress.toLowerCase()) : true;
  const byOrganisation    = ({ owners })  => organisation ? owners.includes(organisation) : true;
  const byTrial           = ({ id })  => trial ? usersSnap.some(u => u.data.owners.includes(id) && u.data.owners.includes(trial)) : true;
  const byCurrentState    = (healthcaresite)  => [byNameOrAdress, byOrganisation, byTrial].every(pred => pred(healthcaresite));
  const toTable           = ({ id, name, address, owners }) => ({ id, name, address, organisation: owners[0] });

  const healthcaresites = Object.values(props.snapshots).map(s => s.data).filter(byCurrentState).map(toTable);
  const setNameOrAddress = (nameOrAddress) => setLocationState({ nameOrAddress });
  
  return FindSiteUI({
    nameOrAddress,
    organisation,
    trial,
    healthcaresites,
    onNameOrAddressChanged: setNameOrAddress,
    I18n                  : props.I18n
  });
}

const FindSite = WithSnapshot(HealthcareSite)(FindSitePresenter);

FindSite.displayName = "FindHealthcareSite";
export default FindSite;
