import * as Administration from 'modules/administration';
import * as Authentication from 'modules/authentication';
import * as Devices from 'modules/devices';
import * as Studies from 'modules/diagnosis';

import { Device } from 'services/server/functions/model/devices/model';
import { HealthcareIcon  } from 'ui/acukit/Icon';
import AdministrationConfig from 'services/server/functions/model/administration/config';
import { HealthcareSite, Organisation, isFromAcurable } from 'services/server/functions/model/administration/model';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { User } from 'services/server/functions/model/authentication/model';

import Counter from 'ui/components/Counter';
import Details from 'ui/components/Details';
import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import DeleteEntity from 'ui/components/DeleteEntity';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import { Redirect } from 'react-router-dom';
import { hasRole } from 'services/server/functions/iam';
import i18n from 'services/server/functions/i18n';
import AggregateAsText from 'ui/components/AggregateAsText';
import useSnapshot from 'ui/hooks/useSnapshot';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import WithSnapshot from 'ui/components/WithSnapshot';
import { useCounter } from 'ui/hooks/useCounter';

import history from 'history.js';

const DeleteHealthcareSite = ({healthcaresite, ...props}) => <DeleteEntity entity={healthcaresite} linkVariable="healthcaresite" {...props}/>;
DeleteHealthcareSite.roles = Organisation.commands.UNREGISTER_HCS.roles || [];

const sortByLastName = (_1, _2, order, _3, row1, row2) => (order === 'asc' ? 1 : -1) * (row1.lastName?.toLowerCase() > row2.lastName?.toLowerCase() ? 1 : -1);
const ViewSiteUI = ({hasAccess, handleDelete, currentUser, originalUser, healthcaresite, summary: {studies, devices, trials, users}, admins, I18n}) => {
  const { id, name, department, address, postCode, country, mail, phone, owners = [] } = healthcaresite || {};
  const organisation = owners[0];
  
  const additionalInformation = [
    Administration.ViewOrganisation.AsText.hasAccess() ? () => <Details label='parent-org'><Administration.ViewOrganisation.AsLinkIcon organisation={organisation} className="button" options={{ skipContext: true }} /></Details> : undefined,
    hasAccess(Devices.Find) ? () => <Details label='devices'><Counter count={devices} findPage={Devices.Find} id='Device' params={{ healthcaresite: id }} /></Details> : undefined,
    hasAccess(Studies.FindStudy) ? () => <Details label='sleep-studies'><Counter count={studies} findPage={Studies.FindStudy} id='Study' params={{ healthcaresite: id }} /></Details> : undefined,
    originalUser.hasAccess(Authentication.FindUser) ? () => <Details label='users'><Counter createPage={Authentication.CreateUser} count={users} findPage={Authentication.FindUser} options={Authentication.FindUser.options} id='User' params={{ healthcaresite: id }} /></Details> : undefined,
    trials && hasAccess(Administration.FindTrial) ? () => <Details label='trials'><Counter count={trials} findPage={Administration.FindTrial} id='Trial' params={{ healthcaresite: id }} /></Details> : undefined
  ].filter(Boolean);
  const showSettingsButton = !owners.includes(AdministrationConfig.organisation.root.id) && (currentUser.isSuperAdmin() || [Organisation.commands.UPDATE_STUDY_CONFIG_SETTINGS, Organisation.commands.UPDATE_STUDY_SLEEP_REPORT_SETTINGS, Organisation.commands.UPDATE_STUDY_TEMPLATE_SETTINGS, Organisation.commands.UPDATE_REPORT_SETTINGS].every(q => currentUser.hasAccess(q)));

  return (
  <div id='ViewHealthcareSite' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
      <div className='actions'>
        { hasAccess(Administration.UpdateHCS) && <Administration.UpdateHCS.AsLinkButton className='primary' text="edit" healthcaresite={id} /> }
        { hasAccess(DeleteHealthcareSite) && <DeleteHealthcareSite 
            healthcaresite={{id, summary: {
              studies        : {count: studies, page: Studies.FindStudy}, 
              devices        : {count: devices, page: Devices.Find}, 
              users          : {count: users, page: Authentication.FindUser}}}} 
            onDelete={handleDelete} I18n={I18n}
          />}
        { showSettingsButton && <Administration.ViewHealthcareSiteConfiguration.AsLinkButton text="settings" healthcaresite={id} organisation={organisation} /> }
      </div>
    </div>
    <div className="pageContent">
      <div className='pageSection'>
        <div className='title'>{I18n('details')}</div>
        <DetailsBlock>
          <div className="grid-col">
            <Details label='global.name' value={name} />
            <Details label='department' value={department || "N/A"} />
          </div>
          <Details label="address" value={address || "-"} />
          <div className="grid-col">
            <Details label="postal-code" value={postCode || "-"} />
            <Details label="country" value={i18n.countries.getName(country) || country || "-"} />
          </div>
          <div className="grid-col">
            <Details label="email-address" value={mail || "-"} />
            <Details label="telephone-number" value={phone || "-"} />
          </div>
        </DetailsBlock>
      </div>
      {hasAccess(User.queries.LIST) && <div className='pageSection'>
        <DetailsBlock>
          <Details label="ViewOrganisation.key-admins" help={<div className="maxTextWidth60">{I18n('global.error.problem_usign_the_system')}</div>}>
            <Table
              keyField='id'
              columns={{
                fullName     : { content: "global.name",         isSortable: true, sortFunc: sortByLastName },
                job          : { content: "job" }
              }}
              onRowClick={((u) => hasAccess(Authentication.ViewUser) && history.push(Authentication.ViewUser.PATH, {user: u.id}))}
              loading={admins===undefined}
              data={(admins || []).filter(u => u.status !== User.STATUS.disabled).map(u => ({...u, fullName: [u.lastName, u.name].join(', ')}))}
              defaultSortCol={'fullName'}
              defaultSortDir={'asc'}
              emptyText={Text.resolve('no-admins-found')}
            />
          </Details>
        </DetailsBlock>
      </div>}
      <div className='pageSection'>
        <DetailsBlock>
          {additionalInformation.reduce((cs, Info, idx) => idx % 2 === 0 ? cs.concat([[Info]]) : cs.concat([cs.pop().concat(Info)]), [])
                                .map((cs, row) => <div key={`addInfo${row}`} className="grid-col">{cs.map((Info, col) => <Info key={`addInfo${row}${col}`}/>)}</div>)}
        </DetailsBlock>
      </div>
    </div>
  </div>);
};

const ViewSitePresenter = (props) => {
  const [originalUser] = useCurrentUser({ skipContext: true });
  
  const owner = props.snapshot?.aggregate.id;
  const userHasAccessToHCS = (!originalUser.metadata.belongsTo || originalUser.metadata.belongsTo.includes(owner));
  const useCounterAutoStart = Boolean(owner) && userHasAccessToHCS;
  const [studiesCounter, loadingStudies] = useCounter(Study, { owner, autoStart: useCounterAutoStart });
  const [devicesCounter, loadingDevices] = useCounter(Device, { owner, autoStart: useCounterAutoStart });
  const [usersCounter,   loadingUsers]   = useCounter(User, { owner, autoStart: useCounterAutoStart });
  const [trialsCounter,  loadingTrials]  = useCounter(Organisation.entities.Trial, { owner, autoStart: useCounterAutoStart });

  const [usersSnap, loadingUserSnap]     = useSnapshot(User, { autoStart: userHasAccessToHCS });

  const adminOf = expected => ({data: {owners, roles}}) => owners.includes(expected) && hasRole(HealthcareSite.roles.manager.id)(roles);

  const healthcaresite = props.snapshot?.data;
  const admins         = (props.loading || loadingUserSnap) ? undefined : Object.values(usersSnap).filter(adminOf(healthcaresite.id)).map(u => u.data);
  const summary        = {
    studies: (props.loading || loadingStudies) ? undefined : studiesCounter, // TODO: add an option to useSnapshot to filter deleted
    devices: (props.loading || loadingDevices) ? undefined : devicesCounter,
    users  : (props.loading ||   loadingUsers) ? undefined : usersCounter,
    trials : (props.loading ||  loadingTrials) ? undefined : trialsCounter,
  };

  const unregister = () => props.unregisterHCS({id: healthcaresite.id});
  return ViewSiteUI({healthcaresite, summary, admins, handleDelete: unregister, hasAccess: props.hasAccess, I18n: props.I18n, isFromAcurable: props.isFromAcurable, currentUser: props.currentUser, originalUser: props.originalUser });
};

const ViewSite = (props) => {
  // TODO: need to check the organisation owner of the currentUser HCS in case the user belongs to a HCS and not to an org directly
  const [currentUser] = useCurrentUser();
  const [originalUser] = useCurrentUser({ skipContext: true });
  const [myHealthcaresite,] = HealthcareSite.ownersFrom(currentUser?.data);
  const [{ id, healthcaresite }] = useLocationParams(); // TODO: deprecate id parameter
  
  const fallbackComponent = _ => currentUser.hasAccess(Administration.FindHCS) ? <Redirect to={Administration.FindHCS.PATH}/> : <Redirect to="/"/>;
  return WithSnapshot(HealthcareSite, id || healthcaresite || myHealthcaresite, { fallbackComponent })(ViewSitePresenter)({
    hasAccess: currentUser.hasAccess, currentUser, originalUser,
    isFromAcurable: isFromAcurable(currentUser), ...props
  }); // ID priority 1) location state 2) query param 3) default hcs ... does it make sense?
}

ViewSite.AsText = AggregateAsText(HealthcareSite, data => data?.name, require('../../config').default.routes.ViewHCS);

const AsIcon = ({ className, ...props }) => {
  const [currentUser] = useCurrentUser(props.options);
  const hasAccess = currentUser.hasAccess(require('../../config').default.routes.ViewHCS);
  const hasOwnership = currentUser.hasOwnership(props.healthcaresite || props.id);
  return <div className={`${hasAccess && hasOwnership ? className : className.replace('button', 'nonselectable')} withIcon`}><HealthcareIcon /><ViewSite.AsText {...props} /></div>;
}
ViewSite.AsIcon = AsIcon;

ViewSite.displayName = "ViewHealthcareSite";
export default ViewSite;

