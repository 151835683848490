import * as Sentry from "@sentry/browser";

import * as codes from 'services/server/functions/errors/codes';

import { Auth, onAppLoad } from 'services/firebase';
import { getReason, getResolution } from 'services/server/functions/errors/messages';

import {Roles} from 'services/server/functions/iam/roles';
import { makeCancelable } from 'services/server/functions/promises';
import { store as rxStore } from 'services/redux';
import { wrapUser } from 'services/server/functions/iam';
import { reportError } from 'services/server/functions/errors';

import { execute as exec } from 'modules/executor';
import getUserContext from 'features/providers/helpers/getUserContext';
import moment from 'moment';

// TODO: add user functionality: check Authentication reducer
const currentUser   = async () => Auth().currentUser?.uid ? (rxStore.getState()?.authentication?.login?.currentUser || require('services/server/functions/model/authentication/model').User.defaultFrom(Auth().currentUser))
                                                          : undefined;
const anonymousUser = () => require('services/server/functions/iam').anonymousUser();

export let currentUserSync;
export const userSubscribers = [
  user => rxStore.dispatch({ type: require('../redux/actions/Authentication').UPDATE_USER, user })
];

const addOnUserChangeListener = (cb) => { 
  if (cb) {
    userSubscribers.push(cb); 
    return _ => userSubscribers.splice(userSubscribers.findIndex(c => cb === c), 1) 
  }
};

const Email    = ()        => require('services/server/functions/model/notifications/model').Email;
const Role     = ()        => require('services/server/functions/model/authentication/model').Role;
const User     = ()        => require('services/server/functions/model/authentication/model').User;
const DataBase = ()        => require('services/firebase').DataBase();

const USER_CONTEXT_CACHE = { userId: undefined, lastUpdate: undefined, value: undefined };
const onUserUpdated = async (user, isUpdate = false) => {
  currentUserSync = user;
  Sentry.setUser({
    id: currentUserSync.aggregate.id,
  });

  const currentLastUpdate = currentUserSync.metadata.events.filter(e => !User().events.USER_AUTHENTICATED.isInstance(e.id) && !User().events.USER_LOGOUT.isInstance(e.id)).pop().timestamp;
  if (user.aggregate.id !== USER_CONTEXT_CACHE.userId || (USER_CONTEXT_CACHE.lastUpdate || 0) < currentLastUpdate) {
    USER_CONTEXT_CACHE.lastUpdate = currentLastUpdate;
    USER_CONTEXT_CACHE.value = getUserContext();
    USER_CONTEXT_CACHE.userId = user.aggregate.id;
  }
  currentUserSync.metadata.context = await USER_CONTEXT_CACHE.value; // @meir error handling ? probably improve by adding the context as user's Entity ?
  
  userSubscribers.forEach(onUserChanged => onUserChanged(currentUserSync, isUpdate));
};

const SESSION_TIMEOUT_MINUTES = 9;
const GET_USER_PERMISSION_REASON = "Cannot get user information";

const errorCodes                = require('services/server/functions/errors/codes');
const INTERNAL_SYSTEM_ERROR     = errorCodes.INTERNAL_SYSTEM_ERROR;

let userSubscription, rolesSubscription, unsubscribeRoleEvents;
const endSubscription = async () => new Promise(resolve => {
  try { unsubscribeRoleEvents?.();         } catch(e) { console.warn("Error while unsubscribing Role events subscription", e); }
  try { rolesSubscription?.unsubscribe?.();} catch(e) { console.warn("Error while unsubscribing Role subscription", e);        }
  try { userSubscription?.unsubscribe?.(); } catch(e) { console.warn("Error while unsubscribing User subscription", e);        }
  try { userSubscription?.cancel?.();      } catch(e) { console.warn("Error while cancelling User subscription", e);           }
  
  userSubscribers?.forEach(onUserChange => {
    try { onUserChange(wrapUser()); } catch(e) { console.warn("Error while notifying user subscribers", e); }
  });

  userSubscription = rolesSubscription = unsubscribeRoleEvents = undefined;

  resolve();
});

const signoutListeners = [];
const onSignout = (handler) => {if (handler) {signoutListeners.push(handler); return () => signoutListeners.splice(signoutListeners.findIndex(h => h === handler), 1)} return undefined;};
const signOut = () => endSubscription()
  .then(_ => rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGOUT }))
  .then(_ => signoutListeners.forEach(handler => handler()))
  .then(_ => Auth().currentUser?.uid ? exec(User().commands.SIGNOUT.newRequest({id: Auth().currentUser?.uid}), {execute: {background: true}, notifications: {disabled: true}}).catch(reportError) : Promise.resolve())
  .then(_ => Auth().signOut())
  .then(_ => Sentry.setUser(null));

const addErrorInfo = (error, code, reason, resolution) => ({
  ...error,
  code,
  reason: error.reason || reason || getReason(code),
  resolution: error.resolution || resolution || getResolution(code),
  type: (code === codes.INVALID_CREDENTIALS_ERROR) ? require('../redux/actions/Authentication').INVALID_LOGIN : require('../redux/actions/Authentication').LOGIN_ERROR,
})

// Firebase Auth errors: https://firebase.google.com/docs/reference/js/firebase.auth.Error
// and https://firebase.google.com/docs/auth/admin/errors
export const mapErrorCode = (error) => error.code === "auth/network-request-failed" ? addErrorInfo(error, codes.NETWORK_ERROR)
                                     : error.code === "auth/timeout" ? addErrorInfo(error, codes.TIMEOUT_ERROR)
                                     : error.code === "auth/session-cookie-expired" || error.code === "auth/session-cookie-revoked" ? addErrorInfo(error, codes.EXPIRED_ERROR)
                                     : error.code === "auth/too-many-requests" ? addErrorInfo(error, codes.QUOTA_ERROR)
                                     : error.code === "auth/user-disabled" ? addErrorInfo(error, codes.DISABLED_ERROR, require('services/server/functions/errors/messages').DISABLED_USER_REASON)
                                     : error.code === "auth/insufficient-permission" || error.code === "auth/unauthorized-domain" || error.code === "auth/operation-not-allowed" ? addErrorInfo(error, codes.PERMISSION_ERROR)
                                     : error.code === "auth/email-already-exists" || error.code === "auth/phone-number-already-exists" ? addErrorInfo(error, codes.DUPLICATE_ERROR)
                                     : error.code === "auth/user-not-found" || error.code === "auth/wrong-password" || error.code === "auth/invalid-email" ? addErrorInfo(error, codes.INVALID_CREDENTIALS_ERROR)
                                     : error.code === "auth/invalid-phone-number" ? addErrorInfo(error, codes.INVALID_PHONE_ERROR)
                                     : error.code === "auth/invalid-verification-code" ? addErrorInfo(error, codes.INVALID_CODE_ERROR)
                                     : error.code === "auth/invalid-email-verified" ? addErrorInfo(error, codes.NOT_VERIFIED_ACCOUNT_ERROR)
                                     : addErrorInfo(error.data?.code ? error.data : error, (error.data?.code || Object.values(codes).includes(error.code)) ? error.code : codes.INTERNAL_SYSTEM_ERROR);
const onUserError = (error, doSignOut=true) => {
  console.error("User session error details:", "error code: ", error.code, " - ", error ? (error.details || error.message || error) : "Unknown user session error") // TODO: remove once all open login/session bugs get solved
  error = mapErrorCode(error);
  
  rxStore.dispatch({ type: error.type, error: error.message || error.reason || "Unknown error" }); // TODO: instead of string use error code format?
  doSignOut && signOut();

  return Promise.reject(error);
}

const userQuery = (userId) => DataBase().snapshots(userId).asQuery();
let onAuthCheck  = undefined;
const checkSession = async us => {
  const user = wrapUser(us);
  await onAuthCheck?.(user).catch(e => onUserError({code: codes.INVALID_CREDENTIALS_ERROR, details: e}));

  // TODO: move here logic re session timeout (currently handled from AuthenticatorManager component)
  return user;
};

const sessionStartedListeners = [];
const onSessionLoaded = (handler) => {if (handler) {sessionStartedListeners.push(handler); return () => sessionStartedListeners.splice(sessionStartedListeners.findIndex(h => h === handler), 1)} return undefined;};
const startSubscription = async (userId) => {
  if (userSubscription) return Promise.resolve(userSubscription);

  const doSubscribe = async () => {
    const [roles, user] = await Promise.all([
      DataBase().snapshots(Role()).asQuery().get(),
      DataBase().snapshots(userId).get()
    ]);

    roles.forEach(r => Roles.update(r.data)); // roles must be ready for checking user permissions later on
    onUserUpdated(await checkSession(user)); // preemptively validate user session if still active
    
    // TODO: unify Authentication actions with SNAPSHOT actions so that we reuse the SNAPSHOTS reducer
    rolesSubscription = DataBase().snapshots(Role()).asQuery()
                        .onCreated(roleSnaps => roleSnaps.forEach(r => Roles.update(r.data)))
                        .onUpdated(roleSnaps => roleSnaps.forEach(r => Roles.update(r.data)))
                        .onDeleted(roleSnaps => roleSnaps.forEach(r => Roles.update(r.data, true)))
                        .onError(e => Auth().currentUser ? onUserError(e) : console.error(e))
                        .subscribe();
    unsubscribeRoleEvents = require('ui/hooks/useSnapshot/globalState').registerEventListener({model: Role(), onNewEvent: event => {
      const prevSnap = Roles.get(event.aggregate.id);
      const newSnap  = require('services/server/functions/snapshot').updateSnapshot(prevSnap, event)[0];
      Roles.update(newSnap?.data, !newSnap || newSnap?.metadata.deleted);
    }});
    userSubscription  = userQuery(user.data.id)
                        .onUpdated(([newUser]) => onUserUpdated(wrapUser(newUser), true))
                        .onDeleted(_ => signOut().catch(console.error)) // If we cannot signout -> the system will not allow the user do nothing as it does not exists, so I guess it is safe to just print the signout error
                        .onError(e => Auth().currentUser ? onUserError(e) : console.error(e))
                        .subscribe();
    sessionStartedListeners.forEach(notify => notify(currentUserSync));
    return userSubscription;
  }

  return makeCancelable(doSubscribe()).catch(e => !e.isCanceled && onUserError({code: codes.PERMISSION_ERROR, reason: GET_USER_PERMISSION_REASON, resolution: getResolution(codes.PERMISSION_ERROR), details: e.toString()})); // TODO: catch error codes from firestore to check if it is really a permission denied error (most common error case)
}

const isAuthenticated = () => Boolean(currentUserSync?.isAuthenticated());
const sessionActive =   () => require('../redux/actions/Authentication').isSessionActive();

const checkIsRegisteredUser = ({additionalUserInfo, user}) => !additionalUserInfo?.isNewUser ? Promise.resolve({user}) : Promise.reject({code: "auth/user-not-found"});

const signinListeners = [];
const onSignin = (handler) => {if (handler) {signinListeners.push(handler); return () => signinListeners.splice(signinListeners.findIndex(h => h === handler), 1)} return undefined;};
const onAuthenticated = ({ user }) => {
  if (user?.uid) {
    exec(User().commands.SIGNIN.newRequest({id: user.uid}), {execute: {background: true}, notifications: {disabled: true}}).catch(reportError);
    signinListeners.forEach(notify => notify());
    return startSubscription(user.uid).then(currentUser) 
  }
  
  return Promise.reject({code: INTERNAL_SYSTEM_ERROR, reason: getReason(INTERNAL_SYSTEM_ERROR), resolution: getResolution(INTERNAL_SYSTEM_ERROR), details: `onAuthenticated: invalid user object: ${user && JSON.stringify(user)}`});
}

const signInWithPhone = (verificationId, verificationcode) => {
  // It seems Firebase is somehow blocking main thread until onAuthState callback is called -> candidate for Web Worker??
  rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGIN });
  console.log("Sign-in with phone", verificationcode)
  return Auth().signInWithPhone(verificationId, verificationcode)
    .then(onAuthenticated)
    .catch(onUserError);
};

const sendPhoneVerifyCode = (phone, appVerifier) => Auth().verifyPhone(phone, appVerifier).catch(e => onUserError(e, false)); // TODO: ensure  E.164 format somehow

const handleResponseError = (response) => response.error.message === 'INVALID_CODE' ? Promise.reject(mapErrorCode({ code: 'auth/invalid-verification-code' })) : Promise.reject(mapErrorCode({ code: '' }))
const verifyPhoneCode = (code, sessionInfo) => Auth().verifyPhoneNumber(code, sessionInfo).then(resp => resp.ok ? resp : resp.json().then(resp => handleResponseError(resp)));

const signInWithCredentials = (email, password) => {
  // It seems Firebase is somehow blocking main thread until onAuthState callback is called -> candidate for Web Worker??
  rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGIN });
  return Auth().signInWithEmailAndPassword(email, password).then(async ({ user }) => {
    if (user.phoneNumber) { 
      const {twoFactorEnabled=true} = (await Auth().getCustomClaims(user)) || {};
      
      // TODO: check if required for this login (typical two factor auth do not ask for verification on every single login, only if IP address or somingth else changes -> new Firebase multi-factor system might already solve this issue)
      // for now we force it always if the phone number is defined and twoFactorEnabled
      if (!twoFactorEnabled) return onAuthenticated({ user });
      
      await Auth().signOut();
      rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGOUT });
      return { id: user.uid, mail: user.email, phone: user.phoneNumber, requires2Factor: true};
    }
    return onAuthenticated({ user });

  }).catch(onUserError);
};

const signInWithCode = async (code, checkCallback) => {
  onAuthCheck = checkCallback;

  if (isAuthenticated()) await signOut();

  // It seems Firebase is somehow blocking main thread until onAuthState callback is called -> candidate for Web Worker??
  rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGIN });
  return require('services/server/functions/executor/executor.client').authenticate(code)
    .then(({ token }) => Auth().signInWithCustomToken(token))
    .then(onAuthenticated)
    .catch(onUserError)
    .finally(_ => {onAuthCheck=undefined});
};

const signInWithPopup = (provider = new (require('services/firebase').firebase.auth.GoogleAuthProvider)()) => {
  rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGIN });
  return Auth().signInWithPopup(provider)
    .then(checkIsRegisteredUser)
    .then(onAuthenticated)
    .catch(onUserError);
};

const signInWithRedirect = (provider=new (require('services/firebase').firebase.auth.GoogleAuthProvider)()) => {
  rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGIN });
  // provider.setCustomParameters({state: "state"}) TODO: use state as recommended ? https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters
  return Auth().signInWithRedirect(provider).catch(onUserError);
};

const getRedirectResult = () => {
  rxStore.dispatch({ type: require('services/redux/actions/Authentication').LOGIN });
  return Auth().getRedirectResult()
    .then(checkIsRegisteredUser)
    .then(onAuthenticated)
    .catch(onUserError);
};

const verifyResetPasswordCode = (authCode) => {
  try {
    return Auth().verifyPasswordResetCode(authCode);
  } catch (e) {
    return Promise.reject(e); // Should fail in promise catch but for some reason is throwing an exception
  }
};

const changePassword = async (newPassword, authCode) => {
  const email = await verifyResetPasswordCode(authCode);
  await Auth().confirmPasswordReset(authCode, newPassword);
  await exec(Email().commands.SCHEDULE_PASSWORD_CHANGED_EMAIL.newRequest({to: email, template: {parameters: {localISOTime: moment().format() /* Default format is ISO with time offset */}}}), {notifications: {disabled: true}, execute: {background: true}})
  await signOut().catch(console.error); // Firebase allows to change the password of the current user and keep the session active, so I guess this is not a problem ATM
  return email;
}

const sendChangePasswordCode = (email, {firstChange}) => exec(Email().commands.SCHEDULE_PASSWORD_EMAIL.newRequest({to: email, template: {parameters: {firstChange: Boolean(firstChange)}}}), {notifications: {disabled: true}});

let authUnsubscribe = () => {};
onAppLoad(_ => {
  authUnsubscribe();
  authUnsubscribe = Auth().onAuthStateChanged(
    user  => {
      if (!user) signOut();
      else if ('uid' in user) startSubscription(user.uid);
      else console.error("Unexpected user object while subscribed to firebase user state changes:", user);
    },
    onUserError
  ) || (() => {});
});

export {
  SESSION_TIMEOUT_MINUTES,
  
  // Common IAM interface (backend/frontend)
  currentUser,
  addOnUserChangeListener as onUserChange,
  onSessionLoaded,
  onSignin,
  onSignout,
  anonymousUser,
  
  // frontend API
  signInWithPhone,
  sendPhoneVerifyCode,
  signInWithCredentials,
  signInWithCode,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  signOut,
  changePassword,
  verifyResetPasswordCode,
  verifyPhoneCode,
  sendChangePasswordCode,
  isAuthenticated,
  sessionActive
}