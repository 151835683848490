import { memo, useMemo } from 'react';

import { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "ui/components/Text";

import { UserPrefs } from 'features/constants/modelActionTypes';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { User } from 'services/server/functions/model/authentication/model';
import useSnapshot from 'ui/hooks/useSnapshot';

const getCustomModal = (studySignatureContent, onClickApplySignatureButton) => {
    if (studySignatureContent) return (props) => (<CustomModal
        heading={Text.resolve('module.diagnosis.fe.component.apply_signature')}
        secondaryLabel={Text.resolve("global.button.continue")}
        secondaryAction={() => onClickApplySignatureButton(studySignatureContent)}
        {...props}
    >
        <div className="confirmation-modal">
            <Text>
                {'module.diagnosis.fe.component.apply_signature_confirmation_modal'}
            </Text>
        </div>
    </CustomModal>);

    return (props) => (<CustomModal
        heading={Text.resolve('module.diagnosis.fe.component.apply_signature.missing')}
        {...props}
    >
        <div className="confirmation-modal">
            <Text>
                {'module.diagnosis.fe.component.apply_signature_confirmation_modal.missing'}
            </Text>
        </div>
    </CustomModal>);
};

export const ApplySignatureButton = memo(({ onResolve, owner }) => {
    const [currentUser] = useCurrentUser({ owner });

    const hasAccessToSignature = currentUser.hasAccess(Study.commands.APPLY_SIGNATURE);
    if (!hasAccessToSignature) return <></>;

    const preferenceId = useMemo(() => User.entities.Preferences.newURN(currentUser.aggregate.id.split("/").pop(), UserPrefs.Signature), []);
    const [studySignatureSnap] = useSnapshot(preferenceId);
    const studySignatureContent = studySignatureSnap?.data?.defaults?.signature;
    
    const onClickApplySignatureButton = (data) => onResolve(data);
    
    const ApplySignatureModal = getCustomModal(studySignatureContent, onClickApplySignatureButton);
    
    return (
        <>
            <div id="apply-signature" >
                <ApplySignatureModal
                    id={'apply-signature-confirmation-modal'}
                    btn
                    label={Text.resolve('module.diagnosis.fe.component.apply_signature')}
                    cancelLabel={Text.resolve("global.button.close")}
                    appearance={'default'}
                    size="sm"
                />
            </div>
        </>
    );
});