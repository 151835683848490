import { useState } from 'react';
import { CustomModal } from 'ui/acukit/ModalDialog';
import {Text} from 'ui/components/Text';
import {ValidatedInput} from 'ui/components/Input';
import { equalsTo } from 'services/server/functions/validation/rules';
const EnableDisableUser = (props) => {
  const [confirmed, setConfirmed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [notifyUser, setNotifyUser] = useState(false);
  const [value, setValue] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = props.onToggle || (_ => {})

  const resetModal = () => {onToggle(false); setIsOpen(false); setNotifyUser(false); setConfirmed(false); setShowError(false); setValue(undefined);};
  return(
    <CustomModal  id={props.disabled ? 'enable' : 'disable'}
                  btn
                  appearance={props.disabled ? "primary" : undefined}
                  secondaryAction={() => { setShowError(true); return confirmed && props.onConfirm({notifyUser}).then(resetModal); }}
                  message={''}
                  isOpen={isOpen || props.isOpen}
                  onToggle={o => {setIsOpen(o); if (props.onToggle) props.onToggle(o);}}
                  heading={props.I18n(props.disabled ? "enable-user" : "disable-user")}
                  label={props.disabled ? "enable" : "disable"}
    >
      <div className="grid-row">
        <p><Text variables={{confirmWord: (<span className='bold red'>'{Text.resolve(props.disabled ? 'enable' : 'disable').toLowerCase()}'</span>)}}>{props.disabled ? 'sure-to-enable-type' : 'sure-to-disable-type'}</Text></p>
        <p>{props.I18n(props.disabled ? 'enable-consecuence' : 'disable-consecuence')}</p>
        {!props.disabledNotifications && <div className="grid-col"><input style={{width: "10px"}} className="vcentered" type="checkbox" id="notifyDisable" onChange={(e) => setNotifyUser(e.target.checked)} value={notifyUser} /><span>{props.I18n('send-email-notification')}</span></div>}
        <ValidatedInput value={value} schema={equalsTo(Text.resolve(props.disabled ? 'enable' : 'disable').toLowerCase())} autoFocus={true} onKeyDown={(e) => e.keyCode === 13 ? confirmed && props.onConfirm({notifyUser}).then(resetModal) : null} showError={showError} onChange={e => { setValue(e.target.value); e.target.value && e.target.value.toLowerCase() === Text.resolve(props.disabled ? "enable" : "disable").toLowerCase() ? setConfirmed(true) : setConfirmed(false); return true; }}/>
      </div>
    </CustomModal>
  )
};

export default EnableDisableUser;