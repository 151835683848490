import * as Devices from 'modules/devices';
import { Redirect } from 'react-router-dom';
import { monotonicFactory } from 'ulid';
import { dataFrom } from 'services/server/functions/executor/urn';
import * as R from 'ramda';
import Action from 'ui/components/Action';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Back from 'ui/components/Back';
import DeviceStatusLegend from '../components/DeviceStatusLegend';
import { useState } from 'react';

import {Batch, Device} from 'services/server/functions/model/devices/model';
import WithSnapshot from 'ui/components/WithSnapshot';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';

const ulid = monotonicFactory();
const DEVICE_STATUS = {[Device.STATUS.created]: 0, [Device.STATUS.inStock]: 0, [Device.STATUS.ready]: 0, [Device.STATUS.active]: 0, [Device.STATUS.disabled]: 0, [Device.STATUS.retired]: 0};

const LogBatchIssueUI = ({batchId, batchSize, batchStatus, type, description, onTypeChangedHandler, onDescriptionChangedHandler, onConfirmHandler, onCancelHandler, I18n}) => (
  <div id='LogBatchIssue' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
    </div>
    <div className='pageContent'>
      <div className='pageSection'>
        <div className='title'>{I18n('details')}</div>
        <DetailsBlock inline>
          <Details label='batch-id' value={dataFrom(batchId || '').id || '-'} />
          <Details label='quantity' value={batchSize!==undefined ? batchSize : "-"} />
          <Details label='status'>
            <div className='items'>
              {Object.entries({...batchStatus}).map((s, i) => 
                <div className={`status ${s[0].toLowerCase()}`} key={i}>{s[1]}</div>
              )}
            </div>
          </Details>
        </DetailsBlock>
        <DeviceStatusLegend />
      </div>
      <div className='pageSection'>
        <div className='title'>{I18n('description')}</div>
        <div className="grid-row">
          <div>{I18n('global.error.issue_provide_message')}</div>
          <div>
            <div className='label'>{I18n('type-of')}<span className='mandatory'>*</span></div>
            <select id='type' className='field' value={type ? type : ''} onChange={(e) => onTypeChangedHandler(e.target.value)}>
              <option value='' disabled>{I18n("type")}</option>
              {Batch.ISSUE_TYPES.map(type => <option key={type} value={type}>{I18n(type)}</option>)}
            </select>
            <textarea id='description' className='field' placeholder={I18n("describe")} value={description ? description : ''} onChange={(e) => onDescriptionChangedHandler(e.target.value)}/>
          </div>
        </div>
      </div>
    </div>
    <div className="pageFooter">
      <div className='actions'>
        <Action className='button primary' id="report" handleAction={() => onConfirmHandler()} disabled={!description || !type} label='send' />
        <Action className='button' id="back" handleAction={() => onCancelHandler()} label='cancel' />
      </div >
    </div>
  </div>
);

const LogBatchIssuePresenter = props => {
  const [devSnaps] = useSnapshot(Device);
  const batch = props.snapshot?.data || {status: undefined, issues: {}};

  const [id, setId]                   = useState(ulid());
  const [type, setType]               = useState();
  const [description, setDescription] = useState();
  
  const withBatchId = (expected) => ({batchId: actual}) => expected === actual;
  const devices     = Object.values(devSnaps).map(s => s.data).filter(withBatchId(dataFrom(props.batchId).id));
  const batchStatus = { ...DEVICE_STATUS, ...R.countBy(R.prop('status'))(devices) };

  const reportIssue = () => props.reportBatchIssue({ id: props.batchId, issues: {[id]: { id, type, description }} })
    .then(_ => setId(ulid()))
    .then(_ => setType(undefined))
    .then(_ => setDescription(undefined))
    .then(_ => require('history.js').default.push(Devices.ViewBatch.PATH, { batch: props.batchId }))
    .catch(e => console.log("error", e));

  return LogBatchIssueUI({
    id,
    type,
    description,
    batchId: props.batchId,
    batchIssues: batch.issues,
    batchSize  : batch.size,
    batchStatus,
    onTypeChangedHandler       : setType,
    onDescriptionChangedHandler: setDescription,
    onConfirmHandler           : reportIssue,
    onCancelHandler            : require('history.js').default.goBack,
    I18n                       : props.I18n
  });
};

export default Object.assign(props => {
  const [{batch: bId, id: oldId}] = useLocationParams();
  const batchId = Batch.newURN(bId || oldId);

  return WithSnapshot(Batch, batchId, {fallbackComponent: _ => <Redirect to={Devices.Inventory.PATH} />})(LogBatchIssuePresenter)({...props, batchId});
}, {displayName: "LogBatchIssue"});