import BootstrapModal from 'react-bootstrap/Modal';
import {Text} from 'ui/components/Text';
import './style.css';

import { Fragment, useState, useEffect } from 'react';

const Modal = (props) => (
  <BootstrapModal
    id={props.id}
    show={props.show}
    onHide={props.onClose}
    onEntered={props.onOpen}
    backdrop={props.dontCloseOnClick ? 'static' : true}
    centered
    dialogClassName={props.className}
    aria-labelledby="contained-modal-title-vcenter"
    size={props?.size}
  >
    {props.heading && <BootstrapModal.Header {...props?.headerProps}>
      <BootstrapModal.Title className="modal-full-w"><Text>{props.heading}</Text></BootstrapModal.Title>
    </BootstrapModal.Header>}
    <BootstrapModal.Body>
      {props.children}
    </BootstrapModal.Body>
    {props.actions && <BootstrapModal.Footer>
      {props.actions.map(action => action?.component ?
        <Fragment key={`action-${action.id}`}>{action.component({ id: action.id, ...action.props, onClose: props.onClose })}</Fragment>
        : (
          <div key={`action-${action.id || (action.text && action.text.toLowerCase().trim().replace(' ', '-'))}`} id={action.id} onClick={action.onClick} className={`button ${action.appearance || ''}`}>{<Text>{action.text}</Text>}</div>
        ))}
    </BootstrapModal.Footer>}
  </BootstrapModal>
);
export default Modal;

export const CustomModal = ({disabled, hidden, ...props}) => {
  const { isOpen: outOpen, actions: customActions } = props;
  const [isOpen, setIsOpen] = useState(outOpen);
  const modalActions = customActions && customActions.filter(Boolean)
  useEffect(() => setIsOpen(outOpen), [outOpen, setIsOpen]);
  if (disabled) props.appearance = `disabled ${props.appearance}`;
  if (hidden)   props.appearance = `hidden ${props.appearance}`;

  const open = disabled ? () => {} : (e) => { setIsOpen(true); props.onToggle && props.onToggle(e || true); };
  const close = () => { setIsOpen(false); props.onToggle && props.onToggle(false); };
  const addClose = (fn, closeOptions) => async data => {
    if (!fn) return close();
    if (closeOptions?.await) return (await fn(data)) !== false ? close() : undefined;
    return fn(data) !== false ? close() : undefined;
  }

  const withClose = (actions) => actions.map(action => ({...action, onClick: (data) => {addClose(action.onClick, action.closeOptions)(data);}}));
  
  const defaultActions = () => [
    { text: props.cancelLabel || 'cancel', id: `${props.id ? props.id : 'modal'}-cancel`, appearance:'default', onClick: close },
    props.secondaryAction ? { text: props.secondaryLabel || 'confirm', id: `${props.id ? props.id : 'modal'}-confirm`, appearance:'primary',  onClick: addClose(props.secondaryAction, props.secondaryActionOptions) } : undefined
  ].filter(a => a !== undefined);

  return (
    <Fragment>
      {props.trigger ? props.trigger : props.btn
        ? <div id={props.id} onClick={open} title={Text.resolve(props.tooltip) || ""} className={`button ${props.appearance}`} ><Text>{props.label}</Text></div>
        : props.asLink ? <span id={props.id} className="selectable fullWidth link" onClick={open}>{props.label}</span> : undefined}
      <Modal
        id={props.id}
        size={ props?.size }
        show={isOpen}
        onClose={close}
        heading={props.heading ? <Text>{props.heading}</Text> : props.heading}
        actions={modalActions ? withClose(modalActions) : defaultActions()}
        headerProps={props?.headerProps ?? {}}
      >
        {props.children}
      </Modal>
    </Fragment>
  );
}

CustomModal.defaultProps = {
  isOpen: false,
  appearance : '',
  btn: false,
  asLink:false,
};
