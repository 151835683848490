import 'ui/theme/find.css';

import * as Pages from 'modules/devices';
import * as R from 'ramda';

import { Batch, Device } from 'services/server/functions/model/devices/model';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import DeviceStatusLegend from '../components/DeviceStatusLegend';
import Table from 'ui/components/Table';
import WithSnapshot from 'ui/components/WithSnapshot';
import { dataFrom } from 'services/server/functions/executor/urn';
import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';

const DEVICE_STATUS = {[Device.STATUS.created]: 0, [Device.STATUS.inStock]: 0, [Device.STATUS.ready]: 0, [Device.STATUS.active]: 0, [Device.STATUS.disabled]: 0, [Device.STATUS.retired]: 0};

const FindBatchUI = ({batchId, batches, onBatchIdChangedHandler, I18n}) => (
  <div id='FindBatch' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
      <div className='actions'>
        <Pages.RegisterBatch.AsLinkButton className='primary' text={I18n('create')} />
      </div>
    </div>
    <div className='pageContent'>
      <div className="pageSection">
        <div className='title'>{I18n('search-by')}</div>
        <DetailsBlock>
          <div className='filter grid-row'>
            <input id="Batch.batchId" placeholder={I18n("batch-id")} onChange={(e) => onBatchIdChangedHandler(e.target.value)} value={batchId || ''}/>
          </div>
        </DetailsBlock>
      </div>
      <div className="pageSection">
        <Table
          keyField='id'
          columns={{
            id          : { content: "id",            isSortable: true },
            serialNumber: { content: "serial-no",     isSortable: true },
            size        : { content: "quantity",      isSortable: true },
            status      : { content: "status" }
          }}
          onRowClick={((batch) => history.push(Pages.ViewBatch.PATH, {id: Batch.newURN(batch.id)}))}
          data={batches.map(b => ({
            ...b,
            id: dataFrom(b.id).id, 
            status: Object.entries({ ...DEVICE_STATUS, ...b.status }).map((s, i) => (<div  key={i} className={`status counter ${s[0].toLowerCase()}`}>{s[1]}</div>))
          }))}
          defaultSortCol={'id'}
          defaultSortDir={'asc'}
          emptyText={I18n('no-batches-found')}
        />
      </div>
    </div>
    <div className='pageFooter'>
      <DeviceStatusLegend />
    </div>
  </div>
);

export default WithSnapshot(Batch)(Object.assign((props) => {  
  const [{batchId}, setLocationState] = useLocationParams();
  const [devSnaps] = useSnapshot(Device);
  
  const withBatchId      = ({id})  => batchId ? id.toLowerCase() === dataFrom(batchId).id.toLowerCase() : true;    
  const byCurrentState   = (batch) => withBatchId(batch.data);
  const toBatchStatus    = (id)    => R.countBy(R.prop('status'))(Object.values(devSnaps).map(s => s.data).filter(dev => dev.batchId === dataFrom(id).id));
  const toBatchTable     = ({data: {id, serialNumber, size}}) => ({id, serialNumber, size, status: toBatchStatus(id)});
  
  const batches = Object.values(props.snapshots).filter(byCurrentState).map(toBatchTable);

  const setBatchId = (batchId) => setLocationState({ batchId })
  return FindBatchUI({
    batchId,
    batches, 
    onBatchIdChangedHandler : setBatchId,
    I18n                    : props.I18n
  })
}, {displayName: "FindBatch"}));