import './style.css';

import * as d3 from 'd3';

import {  useRef, useState } from 'react';

import DetailsBlock from 'ui/components/DetailsBlock';
import HelpIcon from '@atlaskit/icon/glyph/question-circle';
import HelpTooltip from 'ui/components/HelpTooltip';
import InfoIcon from '@atlaskit/icon/glyph/info';
import LockIcon from '@atlaskit/icon/glyph/lock-filled';
import ReactTooltip from 'react-tooltip';
import { Study } from 'services/server/functions/model/diagnosis/model';
import {Text} from 'ui/components/Text';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import ViewStats from '../ViewStats';
import { ViewSummary } from './ViewSummary';
import PositionChart from './components/PositionChart';
import { Report } from 'services/server/functions/model/diagnosis/model/Report';

const IndexHelp = ({showAHI3, showODI3, showAHI4, showODI4}) => <div id="IndexHelp" className="grid-row">
  {showAHI3 && <div className="inline"><span className="indexName"><Text>ViewTest.diagnosis.acu-ahi3</Text></span><span className="indexDescription"><Text>ViewTest.diagnosis.acu-ahi3.text</Text></span></div>}
  {showODI3 && <div className="inline"><span className="indexName"><Text>ViewTest.diagnosis.acu-odi3</Text></span><span className="indexDescription"><Text>ViewTest.diagnosis.acu-odi3.text</Text></span></div>}
  {showAHI4 && <div className="inline"><span className="indexName"><Text>ViewTest.diagnosis.acu-ahi4</Text></span><span className="indexDescription"><Text>ViewTest.diagnosis.acu-ahi4.text</Text></span></div>}
  {showODI4 && <div className="inline"><span className="indexName"><Text>ViewTest.diagnosis.acu-odi4</Text></span><span className="indexDescription"><Text>ViewTest.diagnosis.acu-odi4.text</Text></span></div>}
  <div className="inline"></div>
  <div className="grid-col footnote">
    <div className="icon"><InfoIcon /></div>
    <div>
      <div className="note"><Text>ViewTest.diagnosis.indices-calculated</Text></div>
      <div className="note"><Text>ViewTest.diagnosis.indices-calculated2</Text></div>
    </div>
  </div>
</div>

const IndexChart = (props) => {
  const indicatorsHeight = 30;
  const barHeight = 30;
  const viewBoxWidth  = 800;
  const viewBoxHeight = barHeight+indicatorsHeight;

  const normalPerc   = 10;
  const mildPerc     = 20;
  const moderatePerc = 30;
  const severePerc   = 40;

  return <svg id='chart-index-svg' width={props.width} height={props.height} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
    <text id="chart-metric" y="0" fill="black" fontSize="7pt">
      <tspan x="0" dy="1.2em"><Text>ViewTest.diagnosis.events-hour-1</Text></tspan>
      <tspan x="0" dy="1.2em"><Text>ViewTest.diagnosis.events-hour-2</Text></tspan>
    </text>
    <rect height={`${barHeight}`} width={`${normalPerc}%`} y={indicatorsHeight} className="normal section"/>
    <text id="mild-text" x={`0.5%`} y="45" dominantBaseline="middle" className="text" fontSize="7pt"><Text>NORMAL</Text></text>

    <text textAnchor="middle" id="mild-value" x={`${normalPerc}%`} y="10" className="value" fontSize="10pt">5</text>
    <path id="mild-value-indicator" className="value-indicator" 
          transform={`translate(${viewBoxWidth*(normalPerc/100)}, ${(indicatorsHeight/2)+2})rotate(180,0,0)`}
          d={d3.symbol().type(d3.symbolTriangle).size(40)()}></path>
    <rect height={`${barHeight}`} width={`${mildPerc}%`} x={`${normalPerc}%`} y={indicatorsHeight} className="mild section" />
    <text id="mild-text" x={`${normalPerc+0.5}%`} y="45" dominantBaseline="middle" className="text" fontSize="7pt"><Text>MILD</Text></text>

    <text textAnchor="middle" id="moderate-value" x={`${normalPerc+mildPerc}%`} y="10" className="value" fontSize="10pt">15</text>
    <path id="moderate-value-indicator" className="value-indicator" 
          transform={`translate(${viewBoxWidth*((normalPerc+mildPerc)/100)}, ${(indicatorsHeight/2)+2})rotate(180,0,0)`}
          d={d3.symbol().type(d3.symbolTriangle).size(40)()}></path>
    <rect height={`${barHeight}`} width={`${moderatePerc}%`} x={`${normalPerc+mildPerc}%`} y={indicatorsHeight} className="moderate section"/>
    <text id="mild-text" x={`${normalPerc+mildPerc+0.5}%`} y="45" dominantBaseline="middle" className="text" fontSize="7pt"><Text>MODERATE</Text></text>

    <text textAnchor="middle" id="severe-value" x={`${normalPerc+mildPerc+moderatePerc}%`} y="10" className="value" fontSize="10pt">30</text>
    <path id="severe-value-indicator" className="value-indicator"
          transform={`translate(${viewBoxWidth*((normalPerc+mildPerc+moderatePerc)/100)}, ${(indicatorsHeight/2)+2})rotate(180,0,0)`}
          d={d3.symbol().type(d3.symbolTriangle).size(40)()}></path>
    <rect height={`${barHeight}`} width={`${severePerc}%`} x={`${normalPerc+mildPerc+moderatePerc}%`} y={indicatorsHeight} className="severe section"/>
    <text id="mild-text" x={`${normalPerc+mildPerc+moderatePerc+0.5}%`} y="45" dominantBaseline="middle" className="text" fontSize="7pt"><Text>SEVERE</Text></text>
  </svg>;
}

const LockedFeature = ({id, className="indexSeverity"}) => <HelpTooltip id={id} content="commercial-feature-lock" place="top" type="lock" icon={<span className={className}><LockIcon/></span>} />

const IndexRow = ({name, label, className, canAccess, canAccessSeverity, canAccessValue, severityLabel, valueLabel, messages, onClick}) => canAccess ? (
  <div className={`grid-col centered vcentered indexRow ${className} ${onClick ? 'selectable' : ''}`} onClick={onClick}>
    <HelpTooltip className="toolptip-alignment" id={name} type={messages?.some(m => m.code?.includes?.("WARNING")) ? 'warning' : 'info'} content={<div style={{textAlign: 'left'}}>{messages?.map((m,j) => <p key={j}><Text>{m.code}</Text></p>)}</div>} show={Boolean(messages?.length)}>
      <span className="indexName">{label}</span>
    </HelpTooltip>
    <div className={`indexSeverity ${className}`}>
     {canAccessSeverity ?  <div>{severityLabel}</div> : <LockedFeature id={`${name.toLowerCase()}SevLock`} />}
    </div>
    <div className="grid-col vcentered indexQuantity">
      {canAccessValue ? <span className="indexValue">{valueLabel}</span> : <LockedFeature id={`${name.toLowerCase()}ValLock`} />}
      {onClick ? <div className="button"><Text>ViewTest.diagnosis.events.title</Text></div> : <div className="button indexEvents" style={{visibility: 'hidden'}}><span>&nbsp;</span></div> }
    </div>
  </div>
) : <></>;

const Summary = ({ test, owner, onIndexSelected=() => {}, setIsEventScreen, hasScoring, isOximetryFromNeck }) => {
  const [currentUser]       = useCurrentUser();
  const [currentUserWithOwner] = useCurrentUser({ owner });
  const hasAccess           = currentUser.hasAccess;
  const defaultTooltipPlace = 'right';
  const tooltipPlace = useRef(defaultTooltipPlace);
  
  const onChangeIndexHandler = (canViewStats, eventIndex) => { 
    if (canViewStats) {
      onIndexSelected(eventIndex);
      setIsEventScreen(true);
    }
  };

  const indexes = Object.entries(test?.report.diagnosis || [])
                        .filter(([index,]) => /^ACU-(AHI|ODI)[34]$/.test(index)) // TODO: there should not be a need to filter anything. Webapp should display whatever comes from acucore somehow
                        .reduce((all, [K,V]) => {
                          const messages = JSON.parse(JSON.stringify(currentUser.isFromAcurable() ? [...(V?.messages || [])] : [...(V.messages?.filter(m => m.code in Report.MESSAGES) || [])]));
                          if (hasScoring && K.includes("AHI") && !messages.length && currentUserWithOwner.hasAccess(Study.entities.Test.entities.Marker.queries.LIST)) {
                            const code = "AC/INFO/AHI/000";
                            messages.push({
                              code,
                              text: Report.MESSAGES[code].text,
                            });
                          } else if (hasScoring && K.includes("ODI") && messages.find(m => m.code === "AC/INFO/ODI/000") && currentUserWithOwner.hasAccess(Study.entities.Test.entities.Marker.queries.LIST)) {
                            const index = messages.findIndex(m => m.code === "AC/INFO/ODI/000");
                            messages[index].code = "AC/INFO/ODI/000.scoring";
                          } else if (hasScoring && K.includes("ODI") && isOximetryFromNeck && currentUserWithOwner.hasAccess(Study.entities.Test.entities.Marker.queries.LIST)) {
                            const code = "AC/INFO/AHI/000"; // ugly hack but... requirements dictate so...
                            messages.push({
                              code,
                              text: Report.MESSAGES[code].text,
                            });
                          }

                        return {
                          ...all, 
                          [K]: {
                            ...V,
                            name             : K.split('-').pop(),
                            label            : ((K.includes("ODI") && isOximetryFromNeck) || messages.some(m => m.code === "AC/WARNING/ODI/006" || m.code === "AC/WARNING/ODI/005")) ? Text.resolve(`ViewTest.diagnosis.${K.toLowerCase()}.neck`) : Text.resolve(`ViewTest.diagnosis.${K.toLowerCase()}`),
                            severityLabel    : !V.severity || V.severity === 'UNKNOWN' ? "-" : Text.resolve(V.severity).toUpperCase(), // TODO 'UNKNOWN' should be translated to '-' somehow and avoid harcoding it here.
                            valueLabel       : V.value !== undefined ? V.value : "-",
                            canAccessSeverity: hasAccess(Study.entities.Test.queries[`GET_${K.replace(/-/g,'')}_SEVERITY`]),
                            canAccessValue   : hasAccess(Study.entities.Test.queries[`GET_${K.replace(/-/g,'')}_VALUE`]),
                            canAccess        : hasAccess(Study.entities.Test.queries[`GET_${K.replace(/-/g,'')}_SEVERITY`]) || hasAccess(Study.entities.Test.queries[`GET_${K.replace(/-/g,'')}_VALUE`]),
                            canViewStats     : K.includes('AHI') && hasAccess(ViewStats[K.split('-').pop()]),
                            messages,

                            className: (V.severity || "").replace('/','_').toLowerCase(),
                          }
                        }
                      }, {});

  return (
    <div className="pageSection">
      <div className="title"><Text>module.diagnosis.fe.view.diagnosis.diagnostic_indexes</Text></div>
      <DetailsBlock className="reportCard">
        <div className="grid-col diagnosisHeader">
          <div className="th centered inline"><span><Text>ViewTest.diagnosis.diagnostic-index</Text></span><div data-tip data-for="indexHelp" className="help"><HelpIcon size="small"/></div></div>
          <span className="th centered"><Text>ViewTest.diagnosis.severity</Text></span>
          <span className="th centered"><Text>ViewTest.diagnosis.events-hour</Text></span>
        </div>
        <div className="horizontal-line"/>
        {(indexes['ACU-AHI3'].canAccess || indexes['ACU-ODI3'].canAccess) && 
          <>
            <span className="centered mt"><Text>ViewTest.diagnosis.3pc-desat</Text></span>
            <div className="odi3Rows">
            <IndexRow {...indexes['ACU-AHI3']} onClick={
              () => onChangeIndexHandler(indexes['ACU-AHI3'].canViewStats, 'AHI3')
            } />
              <IndexRow {...indexes['ACU-ODI3']} />
            </div>
          </>
        }
        {(indexes['ACU-AHI4'].canAccess || indexes['ACU-ODI4'].canAccess) && 
          <>
            <span className="centered"><Text>ViewTest.diagnosis.4pc-desat</Text></span>
            <div className="odi4Rows">
            <IndexRow {...indexes['ACU-AHI4']} onClick={
              () => onChangeIndexHandler(indexes['ACU-AHI4'].canViewStats, 'AHI4')
            } />
              <IndexRow {...indexes['ACU-ODI4']} />
            </div>
          </>}
        <div className="button centered fullWidth" data-tip data-for='indexHelp' data-event="click" onMouseOver={_ => tooltipPlace.current = 'top'} onMouseOut={_ => tooltipPlace.current = defaultTooltipPlace}><Text>ViewTest.diagnosis.definitions</Text></div>
        <DetailsBlock className="severityChartBlock">
          <IndexChart/>
        </DetailsBlock>
      </DetailsBlock>
      {currentUser.hasAccess(require('services/server/functions/model/diagnosis/model').Study.entities.Test.queries.VIEW_DIAGNOSIS_POSITION_REPORT) && PositionChart.hasData(test) ? <div id="BodyPosition" className="pageSection">
        <PositionChart {...test}/>
      </div> : <></>}
      <ReactTooltip className="HelpTooltip" id="indexHelp" place={tooltipPlace.current} type="light" effect="solid" border multiline>
        <IndexHelp showAHI3={indexes['ACU-AHI3'].canAccessSeverity} showODI3={indexes['ACU-ODI3'].canAccessSeverity} showAHI4={indexes['ACU-AHI4'].canAccessSeverity} showODI4={indexes['ACU-ODI4'].canAccessSeverity}/>
      </ReactTooltip>
    </div>
  );
}

const ViewDiagnosisUI = ({ test, owner, setIsEventScreen, hasScoring, isOximetryFromNeck }) => {
  const [index, setIndex] = useState('SUMMARY');
  const onBackHandler = () => { 
    setIndex('SUMMARY');
    setIsEventScreen(false);
  };

  
  return (
    <div className="pageContent" id="ViewDiagnosis">
      {index === 'SUMMARY' ? <Summary test={test} owner={owner} hasScoring={hasScoring} onIndexSelected={setIndex} setIsEventScreen={ setIsEventScreen } isOximetryFromNeck={isOximetryFromNeck} /> : <ViewStats type={index} test={test} onBack={ onBackHandler } /> }
    </div>
  );
}

const ViewDiagnosis = ({ test, notify, dismiss, editComments, study, selectedRetry, selectedTest, setTabIndex, hasScoring }) => {
  const summaryProps = { test, notify, dismiss, editComments, study, selectedRetry, selectedTest, setTabIndex };
  const [isEventScreen, setIsEventScreen] = useState(false);
  const isOximetryFromNeck = study.owners?.some(o => o.endsWith("/ox100")) && !study.patient?.instructions?.requiresOximetry;

  return <>
    {
      isEventScreen ? ViewDiagnosisUI({ test, owner: study.site, notify, setIsEventScreen, hasScoring, isOximetryFromNeck })
        : (
          <ViewSummary {...summaryProps}>
            {ViewDiagnosisUI({ test, owner: study.site, notify, setIsEventScreen, hasScoring, isOximetryFromNeck })}
          </ViewSummary>
        )
    }
  </>
};

ViewDiagnosis.displayName = "ViewDiagnosis";
export default ViewDiagnosis;