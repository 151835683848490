import {User} from '.';

export default { // ordered list of event transformers (most recent versions at the end of array)
  1618956000000: async (event) => { // 2021.04.21.-1.2.11 (bugfixes of 1.2.10)
    // Until this date there should not be any user with organisation owner unless the user is an org. manager or it has been assigned only to organisations
    if (event.aggregate.name === User.name 
      && 'owners' in (event.data || {}) 
      && require('../../administration/model').HealthcareSite.ownersFrom(event.data).length > 0
      && !require('../../../iam').hasRole(require('../../administration/model').Organisation.roles.manager.id)(event.data.roles)) { // Some old User events contained a bug that duplicates some owners
        event.data.owners = require('../../administration/model').HealthcareSite.ownersFrom(event.data);
        return event;
    }

    return event;
  },
  1593593910150: async (event) => { // 2020.07.01-0.1.152-2 
    // TODO: include schema/model version in events
    if (event.aggregate.name === User.name && 'owners' in (event.data || {})) { // Some old User events contained a bug that duplicates some owners
      event.data.owners = event.data.owners.filter((o, i) => event.data.owners.indexOf(o) === i);
      return event;
    }

    return event;
  }
}