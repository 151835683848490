import i18n from '../i18n';

// Reasons
export const INTERNAL_SYSTEM_ERROR_REASON = "Internal system error";
export const INVALID_DATA_REASON = "Invalid input data";
export const PERMISSION_REASON = "Permission denied";
export const FORBIDDEN_REASON = "Operation forbidden";
export const NOT_FOUND_REASON = "Asset or service not found";
export const DUPLICATE_REASON = "Asset already exists";
export const DISABLED_REASON = "Disabled asset or service";
export const NETWORK_REASON = "Network error: your connection might be slow or disconnected";
export const TIMEOUT_REASON = "Timeout error: your connection might be slow or disconnected";
export const DISABLED_USER_REASON = "Your account is disabled";
export const UNAVAILABLE_SERVICE_REASON = "Service temporally unavailable";
export const QUOTA_REASON = "Service quota exceeded";
export const INVALID_CREDENTIALS_REASON = "Invalid credentials";
export const INVALID_CODE_REASON = "Invalid code";
export const NOT_VERIFIED_ACCOUNT_REASON = "User account not verified";
export const EXPIRED_REASON = "Asset expired";
export const INVALID_PHONE_REASON = "Invalid phone number";
export const RECAPTCHA_VERIFICATION_REASON = "Recaptcha verification failed";

export const getReason = (code, message) => {
  const codes = require('./codes');
  
  let fullMessage;
  switch (code) {
    case codes.NETWORK_ERROR:
      fullMessage = NETWORK_REASON;
      break;
    case codes.TIMEOUT_ERROR:
      fullMessage = TIMEOUT_REASON;
      break;
    case codes.UNAVAILABLE_SERVICE_ERROR:
      fullMessage = UNAVAILABLE_SERVICE_REASON;
      break;
    case codes.QUOTA_ERROR:
      fullMessage = QUOTA_REASON;
      break;
    case codes.INVALID_CODE_ERROR:
      fullMessage = INVALID_CODE_REASON;
      break;
    case codes.INVALID_DATA_ERROR:
      fullMessage = INVALID_DATA_REASON;
      break;
    case codes.PERMISSION_ERROR:
      fullMessage = PERMISSION_REASON;
      break;
    case codes.FORBIDDEN_ERROR:
      fullMessage = FORBIDDEN_REASON;
      break;
    case codes.NOT_FOUND_ERROR:
      fullMessage = NOT_FOUND_REASON;
      break;
    case codes.DUPLICATE_ERROR:
      fullMessage = DUPLICATE_REASON;
      break;
    case codes.DISABLED_ERROR:
      fullMessage = DISABLED_REASON;
      break;
    case codes.EXPIRED_ERROR:
      fullMessage = EXPIRED_REASON;
      break;
    case codes.INVALID_CREDENTIALS_ERROR:
      fullMessage = INVALID_CREDENTIALS_REASON;
      break;
    case codes.NOT_VERIFIED_ACCOUNT_ERROR:
      fullMessage = NOT_VERIFIED_ACCOUNT_REASON;
      break;
    case codes.INVALID_PHONE_ERROR:
      fullMessage = INVALID_PHONE_REASON;
      break;
    default:
      fullMessage = INTERNAL_SYSTEM_ERROR_REASON;
  }
  if (message) return `${i18n.resolve(fullMessage)}: ${i18n.resolve(message)}`;

  return i18n.resolve(fullMessage);
}

// Resolutions
export const CONTACT_SYSTEM_ADMIN_RESOLUTION = "Please contact system administrator";
export const CONTACT_ADMIN_RESOLUTION = "Please contact your administrator";
export const TRY_AGAIN_RESOLUTION = "Please try again later. If the error persists, please contact your administrator";
export const CHECK_CONNECTION_RESOLUTION = "Please check your connection";
export const CHECK_AND_RETRY_RESOLUTION = "Please check and try again. If the error persists, please contact your administrator";
export const NOT_VERIFIED_ACCOUNT_RESOLUTION = "Please check your email inbox and follow the instructions to activate/verify your account";

export const getResolution = (code) => {
  const codes = require('./codes');
  switch (code) {
    case codes.NETWORK_ERROR:
    case codes.TIMEOUT_ERROR:
      return i18n.resolve(CHECK_CONNECTION_RESOLUTION);
    case codes.UNAVAILABLE_SERVICE_ERROR:
    case codes.QUOTA_ERROR:
      return i18n.resolve(TRY_AGAIN_RESOLUTION);
    case codes.INVALID_DATA_ERROR:
    case codes.INVALID_CREDENTIALS_ERROR:
    case codes.INVALID_CODE_ERROR:
    case codes.NOT_FOUND_ERROR:
    case codes.DUPLICATE_ERROR:
    case codes.INVALID_PHONE_ERROR:
      return i18n.resolve(CHECK_AND_RETRY_RESOLUTION);
    case codes.NOT_VERIFIED_ACCOUNT_ERROR:
      return i18n.resolve(NOT_VERIFIED_ACCOUNT_RESOLUTION);
    case codes.DISABLED_ERROR:
    case codes.PERMISSION_ERROR:
      return i18n.resolve(CONTACT_ADMIN_RESOLUTION);
    default:
      return i18n.resolve(CONTACT_SYSTEM_ADMIN_RESOLUTION);
  }
}