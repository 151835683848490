import * as Administration from 'modules/administration';
import * as Authentication from 'modules/authentication';
import * as Studies from 'modules/diagnosis';

import { Organisation } from 'services/server/functions/model/administration/model';
import TrialIcon from '@atlaskit/icon/glyph/jira/labs';
import { Redirect } from 'react-router-dom';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Role, User } from 'services/server/functions/model/authentication/model';
import Back from 'ui/components/Back';
import Counter from 'ui/components/Counter';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import DeleteEntity from 'ui/components/DeleteEntity';
import WithSnapshot from 'ui/components/WithSnapshot';
import { WithValidation } from 'ui/components/WithValidation';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';
import AggregateAsText from 'ui/components/AggregateAsText';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { prop, sortBy } from 'ramda';

import history from 'history.js';
import { HealthcareSite } from 'services/server/functions/model/administration/model';
import { Select } from 'ui/components/Select';
import { useState } from 'react';
import Modal from 'ui/acukit/ModalDialog';
import { hasRole } from 'services/server/functions/iam';

const ValidatedSelect = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><Select {...props} /></WithValidation>; 

const DeleteTrial = ({trial, ...props}) => <DeleteEntity entity={trial} linkVariable="trial" {...props}/>;
DeleteTrial.roles = Organisation.entities.Trial.commands.CANCEL_TRIAL.roles || [];

const ViewTrialUI = ({hasAccess, originalUser, handleCancel, trial, userOptions, registeredUsers, onRegister, summary: {studies, users, sites}, I18n}) => {
  const {id, name, description, principal: currentPrincipal, owners} = trial || {};
  const sponsor = Organisation.ownersFrom({owners})[0];

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [principal, setPrincipal] = useState();
  const [showUserRegister, setShowUserRegister] = useState(false);
  const [showErrors, setShowErros] = useState(false);
  const [errors, setErrors] = useState();

  const openRegister = () => {
    setShowUserRegister(true);
    setPrincipal(currentPrincipal);
    setSelectedUsers(registeredUsers);
  }

  const closeRegister = () => {
    setShowUserRegister(false);
    setShowErros(false);
  }
  
  const onUsersRegister = () => {
    setShowErros(true);
    if (Object.values(errors).some(e => !e)) {
      return onRegister((selectedUsers || []).concat(principal || []), principal).then(closeRegister);
    } else {
      return false;
    }
  }

  const additionalInformation = [
    Administration.ViewOrganisation.AsText.hasAccess() ? () => <Details id="sponsor" label="sponsor"><Administration.ViewOrganisation.AsLinkIcon organisation={sponsor} className="button" /></Details> : undefined,
    hasAccess(Administration.FindHCS) ? () => <Details label="hcss"><Counter count={sites} findPage={Administration.FindHCS} id='Site' params={{ trial: id }}  /></Details> : undefined,
    hasAccess(Studies.FindStudy) ? () => <Details label="ViewOrganisation.sleep-studies"><Counter count={studies} findPage={Studies.FindStudy} id='Study' params={{ trial: id }}  /></Details> : undefined,
    originalUser.hasAccess(Authentication.FindUser) ? () => <Details label="participants"><Counter onEdit={openRegister} count={users} findPage={Authentication.FindUser} options={Authentication.FindUser.options} id='User' params={{ trial: id }}  /></Details> : undefined
  ].filter(Boolean);
  
  return (
  <div id='ViewTrial' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
      <div className='actions'>
        { hasAccess(Administration.UpdateTrial) && <Administration.UpdateTrial.AsLinkButton className='primary' text="edit" trial={id} /> }
        { hasAccess(DeleteTrial) && <DeleteTrial 
            trial={{id, summary: {
              studies        : {count: studies, page: Studies.FindStudy}, 
              users          : {count: users, page: Authentication.FindUser}}}} 
            onDelete={handleCancel} I18n={I18n} 
          />}
      </div>
    </div>
    <div className="pageContent">
      <div className='pageSection'>
        <div className='title'>{I18n('details')}</div>
        <DetailsBlock>
          <div className="grid-col">
            <Details label='global.name' value={name} />
            {currentPrincipal && <Details id="clinicianName" label="principal-investigator"><Authentication.ViewUser.AsLinkButton {...{ user: currentPrincipal }} /></Details>}
          </div>
          {description && <Details label='description' value={description} />}
        </DetailsBlock>
      </div>
      <div className='pageSection'>
        <DetailsBlock>
          {additionalInformation.reduce((cs, Info, idx) => idx % 2 === 0 ? cs.concat([[Info]]) : cs.concat([cs.pop().concat(Info)]), [])
                                .map((cs, row) => <div key={`addInfo${row}`} className="grid-col">{cs.map((Info, col) => <Info key={`addInfo${row}${col}`}/>)}</div>)}
        </DetailsBlock>
      </div>
    </div>
    <Modal show={showUserRegister} onClose={closeRegister} heading={I18n("participants")} actions={[
      { text: 'confirm', onClick: onUsersRegister, appearance: 'primary' },
      { text: 'cancel', onClick: closeRegister },
    ]}>
      <div className="grid-row">
        <span>{I18n('register-users.content')}</span>
        <span>{I18n('principal-investigator')}:</span>
        <ValidatedSelect name='principal' isClearable onValidate={(field, error) => setErrors({...errors, [field]: error})} onChange={e => {setSelectedUsers(selectedUsers?.filter(u => u !== e.target.value).concat(principal || [])); setPrincipal(e.target.value);}} options={sortBy(prop('label'))(userOptions)} placeholder={I18n('choose-principal')} schema={Organisation.entities.Trial.commands.UPDATE_CLINICIANS.schema.extract('principal')} showError={showErrors} value={principal} />
        <span>{I18n('other-participants')}:</span>
        <ValidatedSelect isMulti name='selectedUsers' onValidate={(field, error) => setErrors({...errors, [field]: error})} onChange={e => setSelectedUsers((e.target.selectedOptions || []).map(o => o.value))} options={sortBy(prop('label'))(userOptions.filter(o => !principal || o.value !== principal))} placeholder={I18n('register-users.choose')} schema={Organisation.entities.Trial.commands.UPDATE_CLINICIANS.schema.extract('clinicians')} showError={showErrors} value={selectedUsers?.filter(u => u !== principal)} />
      </div>
  </Modal>
  </div>);
};

const ViewTrialPresenter = (props) => {
  const [studiesSnap, loadingStudies] = useSnapshot(Study);
  const [usersSnap, loadingUsers]     = useSnapshot(User);
  
  const withOwner = (expected) => ({data: {owners}}) => owners.includes(expected);

  const trial     = props.snapshot?.data;
  const registeredUsers = Object.values(usersSnap).filter(u => u.data.owners.includes(trial?.id)).map(u => u.aggregate.id);
  const summary   = {
    studies        : (props.loading || loadingStudies) ? undefined : Object.values(studiesSnap).filter(withOwner(trial.id)).length,
    users          : (props.loading || loadingUsers) ? undefined : registeredUsers.length,
    sites          : (props.loading || loadingUsers) ? undefined : Object.values(usersSnap).filter(withOwner(trial.id)).reduce((sites, u) => sites.concat(HealthcareSite.ownersFrom(u.data)).filter((site, idx, a) => a.indexOf(site) === idx), []).length,
  };

  const cancel = () => props.cancelTrial({id: trial.id}).then(() => history.replace(Administration.FindTrial.PATH));
  const onRegister = (clinicians, principal) => props.updateTrialClinicians({id: trial.id, clinicians, principal});

  return ViewTrialUI({ trial, summary, onRegister, userOptions: Object.values(usersSnap).filter(u => !u.metadata.impersonator && hasRole(Role.newURN('CTUser'))(u.data.roles)).map(u => ({label: `${u.data.lastName.toUpperCase()}, ${u.data.name}`, value: u.data.id})), registeredUsers, hasAccess: props.hasAccess, originalUser: props.originalUser, handleCancel: cancel, I18n: props.I18n });
}

const ViewTrial = (props) => {
  const [currentUser] = useCurrentUser();
  const [originalUser] = useCurrentUser({ skipContext: true });
  const [myTrial,] = Organisation.entities.Trial.ownersFrom(currentUser?.data);
  const [{id, trial}] = useLocationParams(); // TODO: deprecate id parameter

  const fallbackComponent = _ => <Redirect to={Administration.FindTrial.PATH}/>;
  return WithSnapshot(Organisation.entities.Trial, id || trial || myTrial, {fallbackComponent})(ViewTrialPresenter)({...props, hasAccess: currentUser.hasAccess, originalUser }); // ID priority 1) location state 2) query param 3) default org ... does it make sense?
}

ViewTrial.AsText = AggregateAsText(Organisation.entities.Trial, data => data?.name, Organisation.entities.Trial.queries.GET);

const AsIcon = ({ className, ...props }) => {
  const [currentUser] = useCurrentUser();
  const hasAccess = currentUser.hasAccess(require('../../../config').default.routes.ViewTrial);
  return <div className={`${hasAccess ? className : className.replace('button', 'nonselectable')} withIcon`}><TrialIcon /><ViewTrial.AsText {...props} /></div>;
}
ViewTrial.AsIcon = AsIcon;
ViewTrial.Icon   = TrialIcon;

export default ViewTrial;
