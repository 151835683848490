import Spinner from '@atlaskit/spinner';
import Blanket from '@atlaskit/blanket';
import {Text} from "../Text";

import './style.css'
import { LoaderBar } from 'features/components/LoaderBar';

export const Loading = ({ show = true, text, size, children, alternative = false }) => {
  const spinnerTemplate = show && <div id="Spinner">
    <div className="content grid-row centered">
      <Spinner size={size} />
      {text && <span className="text">{text}...</span>}
    </div>
  </div>;

  return (
    alternative ? (
      <>
        {show && (
          <div className="loading-alternative" >
            <LoaderBar text={text} />
          </div>
        )}
        {children}
      </>
    ) : (
      <>
        <Blanket canClickThrough={!show} isTinted={show} />
        {spinnerTemplate}
        {children}
      </>
    )
      
  );
};

Loading.Default = ({loading}) => <Loading text={Text.resolve('App.loading-data')} show={loading} size="xlarge"/>;

export default Loading;