// TODO: create a "generic" Legend and Legend.Tooltip components ?

import './style.css'; // TODO: same as DeviceStatusLegend

import {Study} from 'services/server/functions/model/diagnosis/model';
import {Text} from 'ui/components/Text';

//TODO: create generic "legend" compoenent?
export const StatusLegendTooltip = ({filter=(_ => true)}) => (
  <div className='tooltipLegend'>
    <div className='statusItems grid-row'>
      { Study.STATUS_GROUP.values().filter(filter).map((s, i) =>  <div key={i} className="statusItem"><div className={`status ${s}`} >{Text.resolve(s, {}, { schema: "diagnosis.Study" })}</div><span className="definition">{Text.resolve(`${s}-DESC`, {}, { schema: "diagnosis.Study" })}</span></div>) }
    </div>
  </div>
);

export default StatusLegendTooltip;
