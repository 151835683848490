import ServerConfig from 'services/server/functions/model/devices/config';
import { Device, Batch } from 'services/server/functions/model/devices/model';

const modulePriority = 400;
const basePath = 'devices';

export default {
  context: ServerConfig.context,
  priority: modulePriority,
  routes: {
    // TODO: this could be moved to the components themselves. Static props: Overview.ROUTE_RELPATH and Overview.ROUTE_PRIORITY for instance
    Assign       : { priority: modulePriority +  7, relpath: `/${basePath}/assign`       , roles: Device.commands.UPDATE_DEVICE.roles           },
    ChangeStatus : { priority: modulePriority +  9, relpath: `/${basePath}/changeStatus` , roles: Device.commands.UPDATE_DEVICE.roles           },
    RegisterBatch: { priority: modulePriority +  4, relpath: `/${basePath}/registerBatch`, roles: Batch.commands.REGISTER_BATCH.roles           },
    Inventory    : { priority: modulePriority +  2, relpath: `/${basePath}/batches`      , roles: Batch.queries.LIST.roles                      },
    Activity     : { priority: modulePriority +  3, relpath: `/${basePath}/activity`     , roles: Device.queries.GET.roles.concat(Batch.queries.GET.roles) },
    LogBatchIssue: { priority: modulePriority + 12, relpath: `/${basePath}/logBatchIssue`, roles: Batch.commands.REPORT_BATCH_ISSUE.roles       },
    LogIssue     : { priority: modulePriority + 11, relpath: `/${basePath}/logIssue`     , roles: Device.commands.REPORT_DEVICE_ISSUE.roles     },
    Find         : { priority: modulePriority +  1, relpath: `/${basePath}/find`         , roles: Device.queries.LIST.roles                     },
    Receive      : { priority: modulePriority +  6, relpath: `/${basePath}/receive`      , roles: Device.commands.UPDATE_DEVICE.roles           },
    ViewBatch    : { priority: modulePriority +  5, relpath: `/${basePath}/viewBatch`    , roles: Batch.queries.GET.roles                       },
    ViewDevice   : { priority: modulePriority +  8, relpath: `/${basePath}/viewDevice`   , roles: Device.queries.GET.roles                      },
  } 
};
