import { PureComponent } from 'react';
import { Workbox } from 'workbox-window';
import I18n from 'services/server/functions/i18n';

const WarningIcon = () => <svg width="80" height="80" viewBox="0 0 24 24" focusable="false" role="presentation"><g fillRule="evenodd"><path d="M12.938 4.967c-.518-.978-1.36-.974-1.876 0L3.938 18.425c-.518.978-.045 1.771 1.057 1.771h14.01c1.102 0 1.573-.797 1.057-1.771L12.938 4.967z" fill="currentColor"/><path d="M12 15a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2" fill="inherit"/></g></svg>;

// https://developers.google.com/web/fundamentals/primers/service-workers/
export default class ServiceWorker extends PureComponent { 
  
  state = { 
    message: undefined
  }

  componentDidMount = () => this.enabled && this.unregister().then(this.register);

  enabled = ('serviceWorker' in navigator);
  newServiceWorker = () => {
    const sw = new Workbox('/service-worker.js');
    sw.addEventListener('waiting',          _e => this.setState({ message: I18n.resolve("New version available. Close other opened browser tabs (if any) and then click 'Accept' to apply.") }));
    sw.addEventListener('installed',        _e => console.log('Service worker installed'));
    sw.addEventListener('activated',        _e => console.log('Service worker activated'));
    sw.addEventListener('controllerchange', _e => this.setState({ message: I18n.resolve("New version available. Click 'Accept' to apply.") }));
    return sw;
  }

  register = () => this.newServiceWorker().register().then(registration => {
    registration.addEventListener('updatefound', () => {
      const worker = registration.installing || registration.waiting || registration.active; // A wild service worker has appeared in reg.installing!
      worker.addEventListener('statechange', () => console.log(`[statechanged] service worker ${worker.state}`));
    });

    registration.update();
  })
  unregister = () => navigator.serviceWorker.getRegistrations().then(registrations => Promise.all(registrations.map(r => r.unregister())));

  reload = () => this.enabled && this.unregister().then(() => { window.location.reload() });
  render = () => (
    <div className="serviceworker grid-row centered" style={{display: this.state.message ? 'flex' : 'none'}}>
      <div className="notification grid-col centered"><div className="warningIcon fitContent"><WarningIcon/></div><p>{this.state.message}</p></div>
      <div className="actions">
        <div className="action button secondary" onClick={() => this.reload()}>{I18n.resolve('Accept')}</div>
      </div>
    </div>
  )
}
  
