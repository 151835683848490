import { DataBase } from 'services/server/functions/firebase';
import { applyMiddleware, createStore, compose as reduxCompose } from 'redux';
import { persistStore } from 'redux-persist';
import reducer from './reducers';
import reduxThunk from 'redux-thunk';
import storage from 'localforage';


const compose = process.env.NODE_ENV === 'development' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : reduxCompose;
const rxStore = createStore(reducer(storage), {}, compose(applyMiddleware(reduxThunk)));


rxStore.database = DataBase;

export const store = rxStore;
export const persistor = persistStore(rxStore);
