
import { useSettings } from 'features/hooks/useSettings';

import { ModelFormTypes } from 'features/constants/modelActionTypes';

import { SchemaFormList } from 'features/components/SchemaForm';
import i18n from 'services/server/functions/i18n';

import MODELS from 'modules/model';

export const StudySleepReportSettings = ({ ownerId }) => {
    const { schemas, onClickAction } = useSettings({
        ownerId,
        schemasToRetrieve: [
            {
                formId: ModelFormTypes.SleepReportSettings,
                action: MODELS.diagnosis.Study.actions.GET_STUDY_SLEEP_REPORT_SETTINGS_FORM.exec
            },
        ]
    });

    const formProps = { onClickAction };
    const labelsPerForm = {
        [ModelFormTypes.SleepReportSettings]: {
            title: i18n.resolve("SleepReportSettings.form_title")
        }
    };

    return (
        <>
            {schemas && (
                <SchemaFormList
                    schemas={schemas} formProps={formProps}
                    labelsPerForm={labelsPerForm}
                />
            )}
        </>
    );
};

StudySleepReportSettings.hasAccess = (currentUser) => {
    return currentUser.hasAccess(MODELS.diagnosis.Study.actions.GET_STUDY_SLEEP_REPORT_SETTINGS_FORM);
};