import moment from 'moment';
import {
    endOfMonth, startOfMonth, endOfQuarter, startOfQuarter,
    startOfYear, endOfYear,
} from 'date-fns';

export const DATE_HOUR_TIME_FORMAT = 'H[h] m[m]';
export const DATE_LOCALE_FORMAT = 'L';
export const DATE_DAY_FORMAT = 'day';
export const DATE_FULL_FORMAT = 'dd/MM/yyyy';
export const DATE_FULL_FORMAT_US = 'MM/dd/yyyy';

export const getTime = (milliseconds, startOf = DATE_DAY_FORMAT, format = DATE_HOUR_TIME_FORMAT) => moment().startOf(startOf).milliseconds(milliseconds).format(format);

export const getRegionFormat = () => {
    let dateFormat = '';
    try {
        const { region } = require('services/server/functions/firebase/config').config;
        dateFormat = region === "us" ? DATE_FULL_FORMAT_US : DATE_FULL_FORMAT;
    } catch (error) {
        console.log("Error getting format date by region...");
        return DATE_FULL_FORMAT;
    }
    return dateFormat;
};

export const getDate = ({ date, format }) => {
    const dateFormat = format ? format.toUpperCase() : getRegionFormat().toUpperCase();
    const newDate = moment(date).format(dateFormat);
    return newDate.includes('NaN') ? moment(date).format(dateFormat) : newDate;
};

export const getDuration = (duration, unit) => require('moment').duration(duration, unit);

export const mapDateToRequest = (date) => date ? moment(date).toISOString() : undefined;

export const getMonthRange = (currentDate = new Date()) => [startOfMonth(currentDate), endOfMonth(currentDate)];

export const getQuarterRange = (currentDate = new Date()) => [startOfQuarter(currentDate), endOfQuarter(currentDate)];

export const getLastDay = (currentDate = new Date()) => {
    return [moment(currentDate).add(-1, 'day').toDate(), moment(currentDate).toDate()];
};

export const getLastWeekRange = (currentDate = new Date()) => {
    return [moment(currentDate).add(-1, 'week').toDate(), moment(currentDate).toDate()];
};

export const getLastMonthRange = (currentDate = new Date()) => [moment(currentDate).add(-30, 'days').toDate(), moment(currentDate).toDate()];

export const getYearRange = (currentDate = new Date()) => [startOfYear(currentDate), endOfYear(currentDate)];

export const parseDate = (dates) => {
    const nullDateValues = [undefined, "null", ""];
    let [startDate, endDate] = dates;
    startDate = nullDateValues.includes(startDate) ? null : new Date(startDate);
    endDate = nullDateValues.includes(endDate) ? null : new Date(endDate);
    return [startDate, endDate];
};

export const msToRangeDate = (start, end) => { 
    if (!start || !end) return undefined;
    return `${new Date(start)}/${new Date(end)}`;
};
