import { Children, cloneElement, useEffect, useState } from 'react';
import HelpTooltip from 'ui/components/HelpTooltip';
import {Text} from 'ui/components/Text';

const QuickFilters = ({ children, header = "Quick Filters:", legend }) => Children.count(children.filter(Boolean).filter(c => !c.props.hidden )) ? ( 
  <div className='quickfilters'>
    <div className='header'><Text>{ header }</Text></div>
    { legend ? <HelpTooltip place={legend.props.place} id={legend.props.id} content={legend}/> : <></> }
    { children }
  </div>
) : <></>;

const Select = (props) => (
  <div className={['quickfilter'].concat(props.className || []).join(' ')}>
    { props.label && <span>{props.label}: </span> }
    <select onChange={props.onChange} value={props.value || ''}>{
      (props.options || []).map(({label, value}) => <option key={value || ''} value={value || ''}>{label}</option>)}
    </select>
  </div>
);

const Exclusive = ({ children, onSelected = () => undefined, onUnSelected = () => undefined }) => {
  const [selected, setSelected] = useState(children.map((c, i) => c.props.active ? i : undefined).find(i => i !== undefined));

  useEffect(_ => children.forEach((c, i) => c.props.active && setSelected(i)), [children]);

  return Children.map(children, (c, i) => cloneElement(c, { 
    active      : selected === i,
    onSelected  : (id) => { if (selected !== i) { setSelected(i);         c.props.onSelected   ? c.props.onSelected(id)   : onSelected(id);   } },
    onUnSelected: (id) => { if (selected === i) { setSelected(undefined); c.props.onUnSelected ? c.props.onUnSelected(id) : onUnSelected(id); } }
  }));
}

const Multi = ({ children, onSelected = () => undefined, onUnSelected = () => undefined }) => {
  const [selected, setSelected] = useState(children.map((c, i) => c.props.active ? i : undefined).filter(i => i !== undefined));

  useEffect(_ => setSelected(children.map((c, i) => c.props.active ? i : undefined).filter(e => e !== undefined)), [children]);

  return Children.map(children, (c, i) => cloneElement(c, { 
    active      : selected.includes(i),
    onSelected  : (id) => { if (!selected.includes(i)) { setSelected([...selected, i]);         c.props.onSelected   ? c.props.onSelected(id)   : onSelected(id);   } },
    onUnSelected: (id) => { if (selected.includes(i)) { setSelected(selected.filter(s => s !== i)); c.props.onUnSelected ? c.props.onUnSelected(id) : onUnSelected(id); } }
  }));
}

const Toggle = ({id, active, className, children, onSelected=() => undefined, key, onUnSelected= () => undefined}) => {
  const toggle = () => active ? onUnSelected(id) : onSelected(id);
  
  return <div id={id} key={key} className={`item ${className ? className : ""} ${active ? "selected" : ""}`} onClick={toggle}>{children}</div>;
}

QuickFilters.Toggle    = Toggle;
QuickFilters.Item      = Toggle;     // deprecate. Toggle should be a better name ?
QuickFilters.Exclusive = Exclusive;
QuickFilters.Multi = Multi;
QuickFilters.Select    = Select;

export default QuickFilters;
