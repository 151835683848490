import { Joi } from '../../../validation/rules';

const Study = () => require('.').Study; // To avoid cyclic dependency

const SIGNALS = [
  "Resp vis",
  "Cardiac rate",
  "Resp rate",
  "Relative desatur",
  "CardiacVisualisa",
  "Snore",
  "SpO2",
  "Acoustic airflow",
  "Movement",
  "Sleep position",
];

// We also want to allow new signals in case acu-core is updated
const SIGNALS_PATTERN = new RegExp(SIGNALS.concat(".*").join("|"));

//TODO: maybe extract a common Recording schema so it can be used by both Devices and Diagnosis context?
const Recording = {
  signalFilePattern:   '[^_]+_\\d{8}_\\d{4}(-\\d+)?(((_[^.]+)?\\.log)|((_[^.]+)\\.json))(\\.zip)?$',
  manifestFilePattern: '[^_]+_\\d{8}_\\d{4}\\.json(\\.zip)?$',
  SIGNALS,
  schema : Joi.object().keys({
    uploadTime     : Joi.string().isoDateWithOffset(),
    startTime      : Joi.string().isoDateWithOffset(),
    endTime        : Joi.string().isoDateWithOffset(),
    timezone       : Joi.string().empty('').default('GMT/Europe/London'), // TODO: if not provided, get it from the startTime... although we need to make sue all the dates have timezone information so we can get rid of this field.
    length         : Joi.number().positive().allow(0),
    diagnosableTime: Joi.number().positive().allow(0),
    signals        : Joi.object() // FIXME: @diego should we move this signals section to Report.js schema?
    .pattern(SIGNALS_PATTERN, Joi.object().keys({ // Expected signals keys are "Cardiac rate" and "Resp rate"
      histogram: Joi.array().items(Joi.object().keys({
        x: Joi.string(), // Rounded rate values in Cardiac and Resp rate for instance
        value: Joi.number().integer()
      })),
      params: Joi.object().keys({
        average: Joi.alternatives().try(Joi.object().keys({ value: Joi.number(), units: Joi.string().default(Joi.ref('...units')) }), Joi.number()), // SpO2 format and backwards compatible with other signals
        stdDev : Joi.alternatives().try(Joi.object().keys({ value: Joi.number(), units: Joi.string().default(Joi.ref('...units')) }), Joi.number()),
        min    : Joi.alternatives().try(Joi.object().keys({ value: Joi.number(), units: Joi.string().default(Joi.ref('...units')) }), Joi.number()),
        lt90   : Joi.alternatives().try(Joi.object().keys({ value: Joi.number(), units: Joi.string().default(Joi.ref('...units')) }), Joi.number()), // SpO2 specific
        lt90pc : Joi.alternatives().try(Joi.object().keys({ value: Joi.number(), units: Joi.string().default(Joi.ref('...units')) }), Joi.number()), // SpO2 specific .. we should probably generalise this ??
        units  : Joi.string(), // bpm, breaths/min
        snorer : Joi.boolean(), // TODO validate this field can only exists for Resp rate signal
      }),
      quartiles: Joi.array().items(Joi.object().keys({
        x  : Joi.array().length(2).items(Joi.string()), // hour range e.g.: ["01:00", "01:59"]
        max: Joi.number().precision(1),
        min: Joi.number().precision(1),
        p25: Joi.number().precision(1),
        p50: Joi.number().precision(1),
        p75: Joi.number().precision(1),
        outliers: Joi.array().items(Joi.number())
      }))
    })).meta({ preferences: { id: "Signals" } }), 
    sensor         : Joi.string(),
    receiver       : Joi.string(),
    file           : Joi.string(), // TODO should be a relative URI ? TO BE DEPRECATED
    files          : Joi.array().items(Joi.string()),
    metadataFile   : Joi.string(), // TODO should be a relative URI ?
    neckPhotoFile  : Joi.string(), // TODO should be a relative URI ?
    ppgFile        : Joi.string(), // TODO should be a relative URI ? TO BE DEPRECATED
  }).prefs({allowUnknown: true, stripUnknown: false}), // we wshould leave whatever acucore deems necessary. // TODO needs testing
  events: {
    NECK_PHOTO_FILE_UPDATED     : {},
    RECORDING_FILE_UPDATED      : { snapshot: (ev, prev) => {
      const testPayload = Object.entries(ev.data.tests || {[prev.data.sequence || 0]: ev.data}).reduce((ts, [seq, testEvent]) => {
        const currentFiles = (prev.data.tests?.[seq] || prev.data).recording?.files || [];
        const files   = currentFiles.concat(testEvent.recording?.files || [])
                                    .concat(testEvent.recording?.file)
                                    .concat(testEvent.recording?.ppgFile)
                                    .filter((f, idx, all) => f && all.findIndex(f2 => f2 === f) === idx);
        return {...ts, [seq]: {recording: { file: files.find(f => f.endsWith('.log') || f.endsWith('.log.zip')), files }}};
      } ,{});

      return { data: ev.aggregate.name === Study().name ? { tests: testPayload } : testPayload[prev.data.sequence || 0] };
    }},
    RECORDING_COMPLETED         : { snapshot: (ev, prev) => {
      const testPayload = Object.entries(ev.data.tests || {[prev.data.sequence || 0]: ev.data}).reduce((ts, [seq, _testEvent]) => {
        return {...ts, [seq]: {recording: { 
          uploadTime: require('moment')(ev.timestamp).utcOffset(0).toISOString()
        }}};
      } ,{});

      return { data: ev.aggregate.name === Study().name ? { tests: testPayload } : testPayload[prev.data.sequence || 0] };
    }},
    RECORDING_METADATA_UPDATED  : {},
  },
  queries: {
    VIEW_SIGNALS_REPORT: {
      // TODO: Implement read model
    },
    VIEW_SIGNALS_VISUALISATION_TOOL: {
      // TODO: Implement read model
    }
  }
}

export { Recording };