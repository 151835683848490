import { useCallback, useState } from "react";
import Details from "ui/components/Details";
import { AutoComplete } from "features/components/AutoComplete";
import { FiltersType } from "../../utils";
import { sanitizeInput } from "features/helpers/sanitiser";

export const BatchIdField = ({ filters, onFilterChange, items, isDisabled }) => {
    const batchId = filters[FiltersType.BatchId];
    const [newItem, setNewItem] = useState();
    const handleBatchId = useCallback((option) => {
        const value = sanitizeInput(option?.value || '');
        onFilterChange({ [FiltersType.BatchId]: value });
    }, [onFilterChange]);
    const allItems = [...items];
    if (newItem) allItems.push({ value: newItem, label: newItem });
    return (
        <Details id={FiltersType.BatchId} label="batch-id">
            <AutoComplete
                id={`study_${FiltersType.BatchId}`}
                items={allItems}
                value={batchId}
                handleChange={handleBatchId}
                forceOneItem={false}
                forceDefault={false}
                selectProps={{
                    isMulti: false,
                    isClearable: true,
                    isDisabled,
                    onInputChange: (value) => {
                        if (!items.some(i => i.value === value)) setNewItem(value);
                        else setNewItem(undefined);
                    },
                }}
            />
        </Details>
    );
};