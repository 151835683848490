import { dataFrom } from '../urn';
import { snapshotFrom } from '../../snapshot';
import { getResetDiff } from '../../helpers/utils';
import { OPERATIONS } from '../../patch';

export const asyncResetMerge = async (data, _prevSnap) => {
    const prevSnap = _prevSnap || await snapshotFrom(dataFrom(data.id).model);
    const result = resetMerge({ data }, prevSnap);
    return result;
};

export const resetMerge = (event, prevSnap) => {
    if (!prevSnap) return { data: event.data };

    const { diff, resetFields } = getResetDiff(prevSnap.data, event.data);
    const isDeleted = Boolean(prevSnap.metadata?.deleted);

    const patch = resetFields.map(fieldPath => ({ op: OPERATIONS.REMOVE, path: `data.${fieldPath}` }));

    return {
        data: diff,
        metadata: {
            patch: patch.length ? patch : undefined,
            deleted: isDeleted ? false : undefined,
        },
    };
};