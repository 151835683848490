import { ACTION_COMPLETED, ACTION_FAILED, ACTION_REQUESTED } from './actions';
import {INTERNAL_SYSTEM_ERROR, INVALID_CREDENTIALS_ERROR} from '../errors/codes';
import { aggregateURN, commandURN, dataFrom, eventURN, isCommand, isEvent, queryURN } from './urn';
import {getReason, getResolution} from '../errors/messages';
import { validate as modelValidation, resolve } from '../model';

import { DataBase } from '../firebase';
import { config } from '../firebase/config';
import { isAuthorised } from '../iam';
import { isBrowser } from '../env';
import { updateSnapshot } from '../snapshot';

const AUDIT = { context: 'audit', name: 'Log' };
const audit = (type, request, result) => event(type, AUDIT, {...request.action, data: result instanceof Error ? result.message : (result || request.action.data)}, { user: request.user ? request.user.aggregate.id : "anonymous" });

const hasReason = (error) => (error?.data?.reason || error?.reason || error?.details?.data?.reason) ? true : false;

const requested      = (request)         => audit(ACTION_REQUESTED, request);
const completed      = (request, result) => console.debug('[execute] Completed request', request.action.id, result) || audit(ACTION_COMPLETED, request, result?.id || result?.length || result);
const failed         = (request, error)  => audit(ACTION_FAILED, request, hasReason(error) ? error : { code: INTERNAL_SYSTEM_ERROR, reason: getReason(INTERNAL_SYSTEM_ERROR), resolution: getResolution(INTERNAL_SYSTEM_ERROR), details: error.message, stacktrace: error.stack || JSON.stringify(error) })
const policyViolated = (request, error)  => audit('POLICY_VIOLATED', request, error);
const unauthorised   = (request)         => audit('USER_NOT_AUTHORISED', request, { code: INVALID_CREDENTIALS_ERROR, reason: getReason(INVALID_CREDENTIALS_ERROR), resolution: getResolution(INVALID_CREDENTIALS_ERROR) });

const EXECUTOR_MODULE = () => isBrowser() ? require('./executor.client') : require('./executor.server');
const queryExecutor   = () => EXECUTOR_MODULE().queryExecutor({ withPriviledge: true });
const validate        = (data, schema, context) => Promise.resolve(modelValidation(data, schema, context));

const awaitSnapshotForEventId = async (eventId, aggregateId, isDeletion) => {
  return new Promise((resolv) => {
    let hasExisted; // some commands as CANCEL_STUDY rely on event handlers, therefore we will asume that if the snapshot dissappears, is that the action meant to delete it
    const unsubscribe = DataBase().snapshots(aggregateId).subscribe(({ exists, snapshot }) => {
      if (isDeletion && !exists) return resolv(unsubscribe());
      if (snapshot?.metadata?.events?.some(e => e.id === eventId)) return resolv(unsubscribe());
      if (hasExisted === undefined && exists) hasExisted = true;
      if (hasExisted && !exists) return resolv(unsubscribe());
      return undefined;
    }, (error) => {
      console.error(`failed awaiting snapshot for eventId:${eventId} on aggregateId:${aggregateId}, error:${error}`, { eventId, aggregateId, error });
    });
    setTimeout(() => { // automatic resolve after a timeout of 3s to avoid long waits in the request
      resolv(unsubscribe());
    }, 3000);
  });
};

const command = (type, model, instance, metadata = {}) => ({
  context: {
    name: model.context
  },
  aggregate: {
    name: model.name,
    id  : aggregateURN(model, instance.id || model.parent?.ownersFrom(instance)[0])
  },
  type: type,
  data: instance,
  id: commandURN(model, type),
  metadata
});

const event = (type, model, action, metadata = {}) => {
  const timestamp = metadata.timestamp || Date.now();
  const eventID = eventURN(model, type, undefined, timestamp);
  const id      = aggregateURN(model, model.context === AUDIT.context ? eventID : action.aggregate.id);

  return {
    seq: dataFrom(eventID).id,
    id: eventID,
    type: type,
    context: {
      name: model.context
    },
    aggregate: {
      name: model.name,
      id
    },
    data: model.context === AUDIT.context ? action.data : modelValidation({ // Sanitize payload according to model schema
      ...action.data,
      id
    }, model.schema, {schemaVersion: action.metadata?.schemaVersion || metadata?.schemaVersion || action.metadata?.apiVersion || metadata?.apiVersion || model.version || config.appVersion}),
    metadata: {
      schemaVersion: action.metadata?.apiVersion || metadata?.apiVersion || model.version || config.appVersion,
      appVersion : config.appVersion,
      causationId: action.id,
      ...metadata
    },
    timestamp,
  };
};

const query = (type, model, instance, metadata={}) => ({
  context: {
    name: model.context
  },
  aggregate: {
    name: model.name,
    id  : instance?.id && aggregateURN(model, instance.id)
  },
  id: queryURN(model, type),
  type,
  data: instance,
  metadata
});

const checkAndComplete = async (request) => {  // TODO needs refactoring
  console.debug('[execute::checkAndComplete] Init');

  const model  = resolve(request.action);
  const action = model.actions[request.action.type];
  
  try {
    request.action.data = await validate(request.action.data, action.schema, {...request.action.metadata, user: request.user.data}); // validates and completes action data with defaults and such
    if (isBrowser()) { 
      console.debug('[execute::checkAndComplete] Ignored (client side)'); // TODO: System policies vs client policies implementation (client side don't have access to all resources)
      return undefined;
    }
    
    if (isCommand(request.action.id))  {
      const currSnap = await queryExecutor().loadSnapshot(request.action.aggregate.id);
      await action.checkPolicies(request.action.data, currSnap, queryExecutor(), { user: request.user, apiVersion: request.action.metadata.apiVersion });
      
      console.debug('[execute::checkAndComplete] End (command)');
      return currSnap;

    } else {
      await action.checkPolicies(request.action.data, undefined, queryExecutor(), { user: request.user, apiVersion: request.action.metadata.apiVersion });
      console.debug('[execute::checkAndComplete] End (query)');
      return undefined;

    }

  } catch (error) {
    console.error('[execute::checkAndComplete] Error', error);
    throw policyViolated(request, error);

  }
}

const checkResponse = async (request, currSnap, response) => {  
  try {
    if (!isBrowser() && isCommand(request.action.id))  {
      console.debug('[execute::checkResponse] Init');
      const model  = resolve(request.action);
      const action = model.actions[request.action.type];
      
      let newEvent, triggeredEvents;
      if (typeof action.event === 'function')  {
        triggeredEvents = await action.event(request.action, currSnap, response);
        if (Array.isArray(triggeredEvents)) {
          newEvent = triggeredEvents.shift();
        } else {
          newEvent = triggeredEvents;
          triggeredEvents = undefined;
        }
      } else {
        newEvent = action.event.new(request.action); // TODO: we should probably have a more specific method 'event.from()' and use `event.new(data)' to pass directly the data ?
        newEvent.data = {...newEvent.data, ...response }; // default behaviour is to add to the event everything that comes from the command handlers
      }

      const eventModel = resolve(newEvent); // Some actions might generate events for other aggregates (e.g. Organisation.commands.REGISTER_HCS)
      newEvent.data  = await validate(newEvent.data, eventModel.schema, {...request.action.metadata, user: request.user.data}); // Validate event generated by the event function
      newEvent.metadata.user = request.user?.aggregate.id;
      
      const newSnap = updateSnapshot(currSnap, newEvent)[0];
      newSnap.data  = await validate(newSnap.data, model.schema, {...request.action.metadata, user: request.user.data}); // Validate new snapshot data
      
      await model.checkPolicies(newSnap, currSnap, queryExecutor());

      if (triggeredEvents?.length) newEvent.triggeredEvents = triggeredEvents;

      console.debug('[execute::checkResponse] End (command)');
      return newEvent;

    }

    return response;

  } catch (error) {
    console.error('[execute::checkResponse] Error', error);
    throw policyViolated(request, error);

  }
}

const execute = async (request, dispatch=DataBase().dispatch) => {
  console.debug('[execute] Executing request', request.action);
  try {
    const onBrowser = isBrowser();
    await dispatch(requested(request));
    if (!isAuthorised(request.user, request.action)) throw unauthorised(request);
    
    const currSnap = await checkAndComplete(request); // validates and completes request with defaults
    
    let response = await checkResponse(request, currSnap, await EXECUTOR_MODULE().execute(request, {currSnap})); // TODO: maybe check for previous results
    let triggeredEvents;
    if (!onBrowser && response?.triggeredEvents) {
      triggeredEvents = response.triggeredEvents;
      delete response.triggeredEvents;
    }
    if (isEvent(response?.id)) {
      await dispatch(response); // Note: queries may return null result in case the resource requested does not exist
      if (request.action.metadata?.awaitSnapshot) await awaitSnapshotForEventId(response.id, response.aggregate.id, response.metadata?.deleted);
    }
    await dispatch(completed(request));


    const transform = EXECUTOR_MODULE()?.transform || (r => r);
    const apiVersion = request.action.metadata?.apiVersion || config.appVersion;
    if (triggeredEvents) response.triggeredEvents = triggeredEvents;
    return (response && apiVersion !== config.appVersion) ? (await transform(response, request)) : response;

  } catch (error) {
    console.error("[execute] Error while executing", request.action, error);
    
    if (isEvent(error?.id)) await dispatch(error);
    const failedEvent = failed(request, error); 
    await dispatch(failedEvent);
    
    return Promise.reject(isEvent(error?.id) ? error : failedEvent);
  }
}

export { 
  command, event, query, 
  execute, failed
}