const bind = (func, ...args) => (form) => func(...args.map(arg => form.find(f => f.field?.id === arg)?.value));

const providers = {
    bmi: bind((height, weight) => {
      height = Number(height); weight = Number(weight);
      if (height > 0 && Number.isFinite(weight)) {
        return (weight / (height ** 2)).toFixed(2);
      }
      return undefined;
    }, 'height', 'weight')
}

export default (id, form) => {
    const field = form.find(f => f.field?.id === id);
    return providers[id]?.(form) ?? (field?.value ?? field?.default);
}