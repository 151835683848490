import { useState } from "react";
import Back from 'ui/components/Back';
import Action from "ui/components/Action";
import { Text } from "ui/components/Text";
import useCurrentUser from "ui/hooks/useCurrentUser";
import I18n from 'services/server/functions/i18n';
import { FindProduct } from "../FindProduct";
import { ProductForm } from "../ProductForm";
const pageTitle = "module.administration.fe.manageProducts.page_title";

export const ManageProducts = ({ header }) => {
    const [state, setState] = useState({
        showForm: false,
        product: undefined,
    });
    
    // const onClickCreate = () => setState({
    //     ...state,
    //     product: undefined,
    //     showForm: true
    // });

    const onClickProductItem = product => {
        setState({
            product,
            showForm: true
        });
    };
    const onClose = () => setState({
        ...state,
        product: undefined,
        showForm: false
    });


    return !state?.showForm ? (
        <>
            {header}
            <div id="manage-products">
                <div className='pageHeader'>
                    <h1 className='title'>{I18n.resolve(pageTitle)}</h1>
                    {/* <div className='actions'>
                        <Action
                            handleAction={onClickCreate}
                            label={Text.resolve('create')} className={`button primary`} />
                    </div> */}
                </div>
                <FindProduct onClickProductItem={onClickProductItem} />
            </div>
        </>
    )
        : (
            <>
                <Back
                    handleHistory={false}
                    onClick={onClose} />
                <div id="manage-products">
                    <div className='pageHeader'>
                        <h1 className='title'>{I18n.resolve(state?.product ? 'module.administration.fe.manageProducts.edit_title' : 'module.administration.fe.manageProducts.create_title')}</h1>
                    </div>
                    <ProductForm
                        defaultProduct={state?.product}
                        onClose={onClose}
                    />
                </div>
            </>
        )
};


export const ManageProductsAction = ({ onAction }) => {
    const [currentUser] = useCurrentUser();
    const handleAction = () => onAction();
    
    return currentUser?.isSuperAdmin() ?
        <div>
            <Action
                handleAction={handleAction}
                label={Text.resolve(pageTitle)} className={`button alternative`} />
        </div>
        : <></>;
};