import 'ui/theme/find.css';

import * as Pages from 'modules/authentication';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import { User } from 'services/server/functions/model/authentication/model';
import WithSnapshot from 'ui/components/WithSnapshot';
import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import useRoles from 'ui/hooks/useRoles';
import { useRef } from 'react';

const Find = ({ roles, labelOrDescription, permission, onLabelOrDescriptionChanged, onPermissionChanged, I18n }) => (
  <div id='FindRole' className='page'>
    <Back />
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
      <div className='actions'>
        <Pages.CreateRole.AsLinkButton text="create" className="primary" />
      </div>
    </div>
    <div className="pageContent">
      <div className="pageSection">
        <div className='title'>{I18n('search-by')}</div>
        <DetailsBlock>
          <div className="filter grid-col">
            <input id="Role.labelOrDescription" placeholder={I18n("label-desc")} onChange={(e) => onLabelOrDescriptionChanged(e.target.value)} value={labelOrDescription || ""}/>
            <input id="Role.permission"         placeholder={I18n("permission")} onChange={(e) => onPermissionChanged(e.target.value)}         value={permission || ""}/>
          </div>
        </DetailsBlock>
      </div>
      <div className="pageSection content">
        <Table
          columns={{
            label      : { content: "global.name",        isSortable: true },
            description: { content: "description", isSortable: true },
            users      : { content: "users",       isSortable: true },
          }}
          data={roles}
          defaultSortCol={'type'}
          defaultSortDir={'asc'}
          emptyText={I18n('no-roles-found')}
          keyField='id'
          onRowClick={(r => history.push(Pages.ViewRole.PATH, { role: r.id }))}
        />
      </div>
    </div>
  </div>
);

export default WithSnapshot(User)(Object.assign((props) => {
  const [{ labelOrDescription, permission }, setLocationState] = useLocationParams(),
        setLabelOrDescription                                  = (labelOrDescription) => setLocationState({ labelOrDescription }),
        setPermission                                          = (permission) => setLocationState({ permission });
  
  const ancestors   = useRef({});
  const customRoles = useRef([]);
  const [Roles]     = useRoles(newRoles => {
    ancestors.current = {};
    customRoles.current = newRoles.customRoles();
  });

  const users = props.snapshots;

  const byLabelOrDesc = ({ label, description }) => !labelOrDescription || `${label} ${description}`.toLowerCase().includes(labelOrDescription.toLowerCase());
  const byPermission  = ({ includes=[] }) => !permission || includes.map(id => Roles.get(id)).filter(Boolean).filter(({ label, description }) => `${label} ${description}`.toLowerCase().includes(permission)).length;
  const withState     = (role) => [byLabelOrDesc, byPermission].reduce((result, filter) => result && filter(role), true);

  const ancestorsOf   = (role) => ancestors.current[role] || (ancestors.current[role] = Roles.ancestorsOf(role).map(r => r.id));
  const withRole      = (role) => ({ roles=[] }) => !role || roles.find(r => r === role.id || ancestorsOf(role).includes(r)) ;
  const usersWithRole = (role) => Object.values(users).map(u => u.data).filter(withRole(role)).length;
      
  return <Find {...{
    roles: customRoles.current.filter(withState).map(r => ({ ...r, label: Text.resolve(r.label, {}, { schema: "authentication.Role" }), description: Text.resolve(r.description, {}, { schema: "authentication.Role" }), users: usersWithRole(r) })),
    labelOrDescription, 
    permission, 
    onLabelOrDescriptionChanged: setLabelOrDescription,
    onPermissionChanged        : setPermission,
    I18n                       : props.I18n
  }} />;
}, {displayName: 'FindRole'}))
  