import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import moment from 'moment';
import { ValidatedInput, ValidatedCalendar } from 'features/helpers/validators';
import { Text } from 'ui/components/Text';
import { sanitizeInput } from 'features/helpers/sanitiser';

export const PatientBaseForm = ({ isConfirm, formSaved, onValidate, onChange, patientID, patientIDConfirmed, patientBirth, patientBirthConfirmed, validationSchema }) => {
    return (
        <div className="pageSection">
            <div className="title">{isConfirm ? <Text>CreateStudy.reenter-id</Text> : <Text>CreateStudy.patient-id</Text>}</div>
            <DetailsBlock>
                <div className="grid-col">
                    <Details id="patientIDField" label="patient-id" mandatory>
                        <ValidatedInput
                            name={isConfirm ? "patientIDConfirmed" : "patientID"}
                            autoComplete="off"
                            schema={validationSchema}
                            field="patient.id"
                            showError={!isConfirm && formSaved}
                            onValidate={(_, message) => onValidate(isConfirm ? "patientIDConfirmed" : "patientID", message)}
                            onChange={(e) => { if (e.target.value) e.target.value = sanitizeInput(e.target.value); onChange(e); }}
                            value={isConfirm ? patientIDConfirmed : patientID}
                        />
                    </Details>
                    <Details id="patientBirthField" label="global.date_of_birth" mandatory>
                        <ValidatedCalendar
                            id="patientBirthCal"
                            clearable={false}
                            name={isConfirm ? "patientBirthConfirmed" : "patientBirth"}
                            schema={validationSchema}
                            field="patient.birthDate"
                            showError={!isConfirm && formSaved}
                            onValidate={(_, message) => onValidate(isConfirm ? "patientBirthConfirmed" : "patientBirth", message)}
                            handleChange={onChange}
                            openToDate={moment().subtract(40, "years").toDate()}
                            maxDate={new Date()}
                            valueFormat="DD/MM/YYYY"
                            value={isConfirm ? patientBirthConfirmed : patientBirth}
                        />
                    </Details>
                </div>
            </DetailsBlock>
        </div>
    );
};
