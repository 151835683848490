import './style.css';

import { Text } from 'ui/components/Text';
import history from 'history.js';

require('services/iam').onSignin(_ => history.resetLength());

export default ({ asButton, className, text, onClick, handleHistory = true }) => (onClick || (handleHistory && history.lengthSinceSignin > 0)) ?
  <div id='back' className={(asButton ? 'button' : 'backLink link selectable') + (className ? ' ' + className : '')} onClick={(e) => { if (onClick) onClick(e); if (handleHistory) history.goBack() }}>{text || <>&laquo; <Text>back</Text></>}</div>
  : <></>;
