import { isEmpty } from 'ramda';

export const removeUnsetProps = obj => Object.entries(obj || {}).filter(([, v]) => v !== undefined && v !== null).reduce((o, [k, v]) => ({ ...o, [k]: v }), {});

export const diff = (o1 = {}, o2 = {}) => Object.keys({ ...o1, ...o2 }).filter(key => o1[key] !== o2[key]);

export const sortObj = (value = {}) =>
    Object.keys(value).sort().reduce((acc, key) => {
        acc[key] = value[key];
        return acc;
    }, {});
     
export const clearObjValues = (value = {}) =>
    Object.keys(value).reduce((acc, key) => {
        acc[key] = undefined;
        return acc;
    }, {});

export const objHasAllDefinedValues = (obj = {}) => Object.values(obj).every(value => value !== undefined && value !== null);

export const setObjProperty = ({ obj = {}, ref, data }) => {
    try {
        const objCopy = { ...obj };
        const destinationPath = ref?.target.split('.');
        return destinationPath?.reduce((acc, key, index) => {
            const isLast = index === destinationPath.length - 1;
            if (!isLast) {
                return acc.hasOwnProperty(key) ? acc[key] : {};
            }
            acc[key] = data[ref?.key];
            return acc;
        }, { ...objCopy });
    } catch {
        return obj;
    }
};

export const converStringPropsToObj = (data, delimiter = ".") => {
    const result = {};
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            const keys = key.split(delimiter);
            let obj = result;
            for (let i = 0; i < keys.length - 1; i++) {
                const k = keys[i];
                obj[k] = obj[k] || {};
                obj = obj[k];
            }
            obj[keys[keys.length - 1]] = value;
        }
    }
    return result;
};

export const flattenObj = (data, delimiter) => {
    if (isEmpty(data)) {
        return data;
    }

    return Object.keys(data).reduce((acc, key) => {
        const delimitedKey = delimiter ? `${delimiter}.${key}` : key;
        if (typeof data[key] === 'object') {
            const flattened = flattenObj(data[key], delimitedKey);
            return { ...acc, ...flattened };
        }
        return { ...acc, [delimitedKey]: data[key] };
    }, {});
};

export const removeNotDefinedProps = (obj = {}) => {
    if (Array.isArray(obj)) {
        return obj.map((item) => removeNotDefinedProps(item));
    }
    if (typeof obj === "object" && obj !== null) {
        return Object.keys(obj).reduce((acc, key) => {
            const prop = obj[key];
            if (prop !== undefined && prop !== null) {
                acc[key] = removeNotDefinedProps(prop);
            }
            return acc;
        }, {});
    }
    return obj;
};

export const toPascalCase = (str) => str.replace(/\w+/g, (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase());