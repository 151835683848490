import { Fragment } from "react";

import { Button } from "features/components/Button";
import { ColorsType } from "features/constants/colors";

import './style.css';
import { SchemaFormButtonTypes } from "../../constants/types";
import { getSchemaFormResolver } from "../../utils/uiUtils";

const generateSchemaAction = ({ formRef, formId, schema, formData, resolver, buttonProps, onClickAction }) => {
    const isSubmiteable = `${schema.id}`.toLowerCase() === SchemaFormButtonTypes.Submit;
    const color = isSubmiteable ? ColorsType.Primary : ColorsType.Secondary;
    const label = resolver ? resolver(schema?.i18n) : schema?.id;
    
    const handleClick = () => { 
        const isValidForm = formRef?.current?.validateForm();
        if (isSubmiteable && !isValidForm) {
            console.debug("Skipping onClickAction: cannot submit invalid form");
            return;
        }
        onClickAction({ formId, schema, formData });
    };

    return (
        <Button
            {...buttonProps}
            disabled={ isSubmiteable ? buttonProps?.disabled : false }
            className="schema-form-actions-button--margin"
            type="button"
            color={color}
            onClick={handleClick}
        >
            {label}
        </Button>
    );
};

export const ActionsTemplate = (props) => {
    const {
        formRef, formId, cleanable, actions = [], disabled, onClickAction, registry,
        handleClear, formData } = props;
    const resolver = getSchemaFormResolver(registry);
    const hasResolver = typeof resolver === "function";
    const CLEAR_ACTION = "clear";
    const allActions = [...actions.slice(0, 1), { id: CLEAR_ACTION, },
        ...actions.slice(1)];
    const buttonProps = { disabled };
    const clearLabel = hasResolver ? resolver("global.button.clear") : CLEAR_ACTION;

    return (
        <>
            {
                allActions?.map((schema) => {
                    return (
                        <Fragment key={`${schema?.id}`}>
                            {schema?.id === CLEAR_ACTION ? (
                                <>
                                    {!disabled && cleanable && (
                                        <Button
                                            onClick={handleClear}
                                            className="schema-form-actions-button--margin"
                                            color={ColorsType.Alternative}
                                        >
                                            {clearLabel}
                                        </Button>
                                    )}
                                </>
                            ) : generateSchemaAction({
                                formRef, 
                                formId,
                                schema,
                                formData,
                                buttonProps,
                                onClickAction,
                                resolver
                            })}
                        </Fragment>
                    );
                })
            }
        </>
    );
};
