
import i18n from 'services/server/functions/i18n';

import './style.css';

const FrameDoc = ({ url , data, formId="formScript564016000000503116" }) => `
    <html>
      <head>
        <style>
          .zcwf_title{
              visibility: hidden !important;
            }
            .zcwf_col_fld {
                font-size: 15px !important;
            }

            .zcwf_col_fld > textarea {
                background: #f5f8fa;
                width: 100% !important;
                padding: 8px;
            }

            .zcwf_col_fld > input {
                background: #f5f8fa;
                width: 100% !important;
                height: 40px !important;
                padding: 8px;
            }
            .zcwf_col_lab > label {
                color: #2f2f2f;
                font-weight: 500;
            }
            .zcwf_lblTopBottom {
                width: 100%;
                padding: 0 !important;
                margin: 0 !important;
                box-sizing: border-box;
                top: -36px;
                position: absolute;
            }
            #formsubmit, input[type="reset"]{
                background: #f3ba00;
                border-color: #f3ba00;
                color: #2f2f2f;
                font-size: 14px;
                font-weight: 500;
                width: 82px !important;
                height: 36px !important;
                border-radius: 4px;
            }

            input[type="reset"] {
                background: #eaebec;
                border-color: #eaebec;
            }
        </style>
      </head>
      <body>
         <script>
            function handleScriptLoad() {
              const emailElement = document.querySelector('input[id="Email"]');
              emailElement.value = '${data?.email ?? ""}';
              emailElement.dispatchEvent(new Event('input', { bubbles: true }));
              const formElement = document.querySelector('form');
              if (formElement) {
                formElement.addEventListener('submit', ()=>{
                     event.preventDefault();
                     event.target.submit();
                     setTimeout(()=> window.location.reload(), 250);
                });
              }
            }
            const script = document.createElement('script');
            script.id="${formId}"
            script.src = "${url}&script=$sYG"
            script.onload = handleScriptLoad;
            document.body.appendChild(script);
        </script>
      </body>
    </html>
`;

const SupportForm = (data) => (
    <iframe  frameBorder="0" scrolling="no" srcDoc={FrameDoc({
      url: i18n.resolve('modules.fe.support.zoho_crm_link'),
      data
    })} />
);
  

export default SupportForm;