import { parseDate } from "features/helpers/dateHelper";
import { FiltersType, severityList, statusList } from ".";
import { DateConductedField } from "../Fields/DateConducted";
import { DateCreatedField } from "../Fields/DateCreated";
import { HealthcareSiteField } from "../Fields/HealthcareSite";
import { LastUpdatedField } from "../Fields/LastUpdated";
import { NumberOfTestsField } from "../Fields/NumberOfTests";
import { OrganisationField } from "../Fields/Organisation";
import { TestStatusField } from "../Fields/TestStatus";
import { Reports } from "features/reports/helpers/tableReportConfig";
import { ReferenceId } from "../Fields/ReferenceId";
import { PatientBirthField } from "../Fields/PatientBirth";
import { ClinicianField } from "../Fields/Clinician";
import { SeverityField } from "../Fields/Severity";
import { BatchIdField } from "../Fields/BatchId";

const filtersByReport = {
    [Reports.StudyReport.key]: (currentUser) => ({
        filters: [FiltersType.Reference, FiltersType.PatientBirth, FiltersType.Clinician],
        advance: [FiltersType.Organisation, FiltersType.HealthcareSite,
        FiltersType.Status, FiltersType.DateCreated, FiltersType.DateConducted, FiltersType.LastUpdated, FiltersType.NumberOfTests, FiltersType.Severity, ...(currentUser.isFromAcurable() ? [FiltersType.BatchId] : [])],
        config: { filters: { columns: 3 }, advance: { columns: 3 } }
    }),
    [Reports.ActivityLogReport.key]: (_currentUser) => ({
        filters: [FiltersType.HealthcareSite, FiltersType.Clinician, FiltersType.DateCreated, FiltersType.DateConducted],
        advance: [FiltersType.Reference, FiltersType.Status, FiltersType.NumberOfTests],
        config: { filters: { columns: 2 }, advance: { columns: 3 } }
    }),
    [Reports.ActivitySummary.key]: (_currentUser) => ({
        filters: [FiltersType.Organisation, FiltersType.HealthcareSite, FiltersType.Clinician, FiltersType.DateCreated, FiltersType.DateConducted, FiltersType.NumberOfTests],
        advance: [],
        config: { filters: { columns: 3 } }
    }),
};

const getUiFilterValueField = (filterType) => {
    const fieldCases = {
        [FiltersType.Reference]: ({ baseFilters }) => baseFilters[FiltersType.Reference],
        [FiltersType.PatientBirth]: ({ baseFilters }) => baseFilters[FiltersType.PatientBirth],
        [FiltersType.Clinician]: ({ selectedClinician }) => selectedClinician,
        // Organisation block
        [FiltersType.Organisation]: ({ selectedOrg }) => selectedOrg,
        // HCS block
        [FiltersType.HealthcareSite]: ({ baseFilters, hcs }) => baseFilters[FiltersType.HealthcareSite] ? hcs?.find(o => o.value === baseFilters[FiltersType.HealthcareSite]) : undefined,
        // Test Status block
        [FiltersType.Status]: ({ baseFilters }) => baseFilters[FiltersType.Status] ? statusList.find(s => s.value === baseFilters[FiltersType.Status]) : undefined,
        // SeverityBlock
        [FiltersType.Severity]: ({ baseFilters }) => baseFilters[FiltersType.Severity] ? severityList.find(s => s.value === baseFilters[FiltersType.Severity]) : undefined,
        // Number Of Tests block
        [FiltersType.NumberOfTests]: ({ baseFilters, listOfTests }) => baseFilters[FiltersType.NumberOfTests] ? listOfTests.find(s => s.value === baseFilters[FiltersType.NumberOfTests]) : undefined,
        // Date Created block
        [FiltersType.DateCreated]: ({ baseFilters }) => baseFilters[FiltersType.DateCreated] ? parseDate(baseFilters[FiltersType.DateCreated].split("/")) : undefined,
        // Date Conducted block
        [FiltersType.DateConducted]: ({ baseFilters }) => baseFilters[FiltersType.DateConducted] ? parseDate(baseFilters[FiltersType.DateConducted].split("/")) : undefined,
        // LastUpdated block
        [FiltersType.LastUpdated]: ({ baseFilters }) => baseFilters[FiltersType.LastUpdated] ? parseDate(baseFilters[FiltersType.LastUpdated].split("/")) : undefined,
        [FiltersType.BatchId]: ({ baseFilters }) => baseFilters[FiltersType.BatchId] ? ({ value: baseFilters[FiltersType.BatchId], label: baseFilters[FiltersType.BatchId] }) : undefined,
    };

    return fieldCases[filterType];
};

export const generateStudyFiltersValue = (
    { filters = [], advance = [] },
    props = {}
) => [...filters].concat(advance)?.reduce((acc, filterType) => {
    acc[filterType] = getUiFilterValueField(filterType)(props);
    return acc;
}, {});

export const uiFilterFields = {
    [FiltersType.Reference]: ReferenceId,
    [FiltersType.DateConducted]: DateConductedField,
    [FiltersType.DateCreated]: DateCreatedField,
    [FiltersType.LastUpdated]: LastUpdatedField,
    [FiltersType.Organisation]: OrganisationField,
    [FiltersType.HealthcareSite]: HealthcareSiteField,
    [FiltersType.Status]: TestStatusField,
    [FiltersType.NumberOfTests]: NumberOfTestsField,
    [FiltersType.PatientBirth]: PatientBirthField,
    [FiltersType.Clinician]: ClinicianField,
    [FiltersType.Severity]: SeverityField,
    [FiltersType.BatchId]: BatchIdField,
};


export const getUiFilters = (currentUser, reportType) => filtersByReport[reportType](currentUser);

