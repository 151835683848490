import { firebase } from '.';
import { config } from 'services/server/functions/firebase/config';
import 'firebase/compat/auth';

export default (app) => { 
  if (!app) return undefined;
  const auth =  app.auth();
  if (process.env.REACT_APP_USE_EMULATOR === "1") auth.useEmulator(`http://localhost:${process.env.REACT_APP_AUTH_EMULATOR_PORT}/`)

  //TODO: set persistence properly?? https://firebase.google.com/docs/auth/web/auth-state-persistence
  // auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

  //auth.languageCode = 'en';
  auth.useDeviceLanguage();
  // TODO: Turn off phone auth app verification for automated testing
  // https://firebase.google.com/docs/auth/web/phone-auth#test-with-whitelisted-phone-numbers
  // auth.settings.appVerificationDisabledForTesting = true;

  const PhoneAuthProvider = firebase.auth.PhoneAuthProvider;
  auth.verifyPhone = (phone, appVerifier) => (new PhoneAuthProvider(auth)).verifyPhoneNumber(phone, appVerifier);
  auth.signInWithPhone = (verificationId, verificationCode) => auth.signInWithCredential(firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode));
  auth.getCustomClaims = (user) => user.getIdTokenResult().then(idToken => idToken.claims);

  // Firebase API does not allow phone number verification without user authentication - see https://medium.com/google-developer-experts/verifying-phone-numbers-with-firebase-phone-authentication-on-your-backend-for-free-7a9bef326d02
  auth.verifyPhoneNumber = (code, sessionInfo) => fetch(`https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPhoneNumber?key=${config.apiKey}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ sessionInfo, code })
  })

  return auth;
};
