import 'firebase/compat/storage';
import memoize from 'fast-memoize';
import {config} from 'services/server/functions/firebase/config';

export default (app) => {
  if (!app) return undefined;
  const storage = app.storage();

  const getDownloadURL = (path) => (path.startsWith('gs://') ? storage.refFromURL(path) : storage.ref(path)).getDownloadURL()
    .catch(error => { console.log(`Error while retrieving '${path}' download URL`, error); throw error; });

  const upload = (targetPath, file, metadata) => storage.ref(targetPath).put(file, metadata)
      .catch(error => { console.error(`Error while uploading file to '${targetPath}'`, error); throw error; });

  const downloadFile = path => getDownloadURL(path)
    .then(url => new Promise((resolve, reject) => {
      console.debug('downloading file', path);
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onerror = () => reject(new Error(`Download request failed with status: ${xhr.status}, statusText: ${xhr.statusText}`));
      xhr.onload  = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          console.debug('file downloaded', path);
          resolve(new File([xhr.response], url, { lastModified: new Date().getTime(), type: xhr.response.type }));

        } else {
          xhr.onerror();
        }
      }
      xhr.open('GET', url);
      xhr.send();
    }))
    .catch(e => { console.error('file download failed', path, e); throw e; });

  //TODO: properly sanitize error messages
  // switch (error.code) {
  //   case 'storage/object-not-found':
  //     break;c
  //   case 'storage/unauthorized':
  //     break;
  //   case 'storage/canceled':
  //     break;
  //   case 'storage/unknown':
  //     break;
  //   default: // A full list of error codes is available at https://firebase.google.com/docs/storage/web/handle-errors
  //     error.message = "Unexpected error while processing PDF report URL";
  // }
  
  const getBucketAssets = async ({
    path, defaultPath, callback
  }) => {
    const rootPath = `gs://${config.assetsBucket}`;
    let bucketFileUrl;

    try {
      bucketFileUrl = await getDownloadURL(`${rootPath}/${path}`);
    } catch {
      bucketFileUrl = await getDownloadURL(`${rootPath}/${defaultPath}`);
    }

    callback(bucketFileUrl);
  };

  storage.getDownloadURL = getDownloadURL;
  storage.upload         = upload;
  storage.downloadFile   = memoize(downloadFile);
  storage.getBucketAssets = getBucketAssets;

  return storage;
}
