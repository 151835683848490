import { useContext } from 'react';

import { UserContext } from 'features/providers/userContextProvider';
import OptionsBlock, { SelectorTitle } from "../ContextOptionsBlock";

export default ({value, label, onChange, name='product'}) => {
    const {
        products, loading
    } = useContext(UserContext);

    const onProductChange = (newProduct) => onChange({target: {name, value: newProduct}});

    return <OptionsBlock
        id={name}
        name={name}
        label={<SelectorTitle label={label || "module.administration.fe.manageProducts.product"} />}
        items={loading ? [] : products}
        value={value}
        handleChange={onProductChange}
        isMulti={false}
    />;
}