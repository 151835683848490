import { DataBase as db } from 'services/firebase';
import { execute as exec } from 'modules/executor';
import { Product } from 'services/server/functions/model/administration/model';
import { Roles } from 'services/server/functions/iam/roles';

const DataBase = db();

const getKey = (snap) => snap.split("/").pop();

const getUserContext = async () => { // @meir we need to simplify this "a lot"
    const [{ organisationalUnits: units }, productsSnaps] = await Promise.all([
        exec(
            Product.queries.GET_CONTEXT.newRequest({}, { populate: true }), {
            execute: { background: true },
            notifications: { disabled: true }
        }),
        DataBase.snapshots(Product).asQuery().get(),
    ]);

    const availableProductKeys = new Set();
    units.forEach(o => {
        o.products?.forEach(p => {
            availableProductKeys.add(getKey(p.id))
        });
    });

    const products = productsSnaps.filter(snap => availableProductKeys.has(getKey(snap.data.id))).map(p => p.data);
    products.forEach(p => { p.accessRights = Roles.expand(p.accessRights); });

    // optimise this object so that checking permissions is as fast as possible ... ugly ATM
    const rights = units.reduce((all, o) => ({
        ...all,
        [o.id]: o.products?.reduce((ps, p) => ({
            ...ps,
            [p.key]: {
                accessRights: p.accessRights,
                attributeRights: p.attributeRights
            }
        }), {})
    }), {});

    return { units, products, rights };
};

export default getUserContext;