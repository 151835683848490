import { useMemo, useState, useEffect, useRef } from 'react';
import DetailsBlock from 'ui/components/DetailsBlock';
import Details from 'ui/components/Details';
import Action from 'ui/components/Action';
import FileDrop from 'ui/components/FileDrop';
import { Text } from 'ui/components/Text';
import useSnapshot from 'ui/hooks/useSnapshot';
import { User } from 'services/server/functions/model/authentication/model';
import Spinner from '@atlaskit/spinner';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { UploadUserSignatureButton } from './UploadUserSignatureButton';
import { DeleteUserSignatureButton } from './DeleteUserSignatureButton';

const MAX_IMAGE_WIDTH = 300;
const MAX_IMAGE_HEIGHT = 150;
const MAX_IMAGE_SIZE = 100;
const MAX_IMAGE_SIZE_STRING = `${MAX_IMAGE_SIZE}KB`;
const MAX_IMAGE_SIZE_KB = MAX_IMAGE_SIZE * 1024; // 100KB

const isImage = (file) => file.type.match('image');
const hasValidSize = (file) => file.size <= MAX_IMAGE_SIZE_KB;
const hasValidDimensions = (img) => img.width <= MAX_IMAGE_WIDTH && img.height <= MAX_IMAGE_HEIGHT;

const SignatureManagerUI = ({ isLoadingSignaturePref, isLoadingImage, handleUpload, handleClear, handleDelete, onFileSelected, hasUploadAccess, hasDeleteAccess, I18n, signatureFile, signaturePrefFileContent }) => <div className="pageContent">
    <div className='pageSection'>
        <DetailsBlock>
            {isLoadingSignaturePref ? <Spinner size="large" /> : <>
                {<Details id="userSignature" label="signature" help={<div className="maxTextWidth60">{I18n('global.signature-tooltip')}</div>} >
                    <div className="grid-col">
                        {hasUploadAccess && <FileDrop id="userSignatureDrop" annotation={Text.resolve('DropFile.upload.image-size', { MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT })} onFileDrop={onFileSelected} accept="image/*" />}
                        {isLoadingImage && <Spinner size="large" />}
                        {(signatureFile || signaturePrefFileContent) && <div className="centered"><img src={signatureFile?.src || signaturePrefFileContent} alt={Text.resolve("logo")} /></div>}
                    </div>
                </Details>}
                <div className="grid-col" style={{ width: 'max-content' }} >
                    {signatureFile && <UploadUserSignatureButton onResolve={handleUpload} />}
                    {signatureFile && <Action handleAction={handleClear} className="primary button" label="global.button.clear" />}
                    {hasDeleteAccess && signaturePrefFileContent && !signatureFile && <DeleteUserSignatureButton onResolve={handleDelete} />}
                </div>
            </>
            }
        </DetailsBlock>
    </div>
</div>;

const SignatureManager = ({ userId, onUpload, onDelete, notify }) => {
    if (!userId) throw new Error("missing userId prop");

    const [currentUser] = useCurrentUser();

    const isOwnerOfSignature = User.isOwnerOfSignature(currentUser, userId);
    const hasReadAccess = currentUser.hasAccess(User.entities.Preferences.queries.GET);
    const hasUploadAccess = UploadUserSignatureButton.hasAccess(currentUser) && isOwnerOfSignature;
    const hasDeleteAccess = DeleteUserSignatureButton.hasAccess(currentUser) && isOwnerOfSignature;

    const preferenceId = useMemo(() => User.entities.Preferences.newURN(userId.split("/").pop(), "Signature"), [userId]);
    const [signaturePrefSnap, isLoadingSignaturePref] = useSnapshot(preferenceId, { autoStart: hasReadAccess });
    const processingAction = useRef({
        action: null, // 'update', 'delete'
        isLoading: false,
    });
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [signatureFile, setSignatureFile] = useState(undefined);
    const signaturePrefFileContent = signaturePrefSnap?.data?.defaults?.signature;

    useEffect(() => {
        if (!processingAction.current.action) return;

        if (processingAction.current.action === "update" && signaturePrefFileContent) {
            setSignatureFile(undefined);
        }

        processingAction.current = {
            action: null,
            isLoading: false
        };
    }, [signaturePrefFileContent]);

    const hasViewAccess = hasReadAccess && isLoadingSignaturePref === false && signaturePrefFileContent;
    const hasAccessToSignatureManager = hasUploadAccess || hasDeleteAccess || hasViewAccess;
    if (!hasReadAccess || !hasAccessToSignatureManager) return <></>;

    const onFileSelected = (file) => {
        if (!isImage(file)) {
            return notify('error', Text.resolve("Notification.wrong-file"), Text.resolve("Notification.wrong-file.not-image"));
        }
        if (!hasValidSize(file)) {
            return notify('error', Text.resolve("Notification.file-too-big"), Text.resolve("Notification.file-too-big.max-limit", { size: MAX_IMAGE_SIZE_STRING }));
        }

        const reader = new FileReader();
        reader.onload = (evt) => {
            const signatureFile = new Image();
            signatureFile.onload = () => {
                if (!hasValidDimensions(signatureFile)) {
                    setIsLoadingImage(false);
                    return notify('error', Text.resolve("Notification.wrong-file"), `${Text.resolve("Image file is of size %{width}x%{height}.", { width: signatureFile.width, height: signatureFile.height })} ${Text.resolve("Please select an image of a maximum size of %{width}x%{height}.", { width: MAX_IMAGE_WIDTH, height: MAX_IMAGE_HEIGHT })}`);
                }
                if (signatureFile.src !== signaturePrefFileContent) {
                    setSignatureFile(signatureFile);
                }
                setIsLoadingImage(false);
            };
            signatureFile.src = evt.target.result;
        };

        reader.readAsDataURL(file);
        setIsLoadingImage(true);
    };

    const handleUpload = async () => {
        processingAction.current = {
            action: 'update',
            isLoading: true
        };
        await onUpload({ id: userId, signature: signatureFile.src }).catch(e => {
            processingAction.current = {
                action: null,
                isLoading: false
            };
            console.error(e);
        });
    };

    const handleClear = () => {
        setSignatureFile(undefined);
    };

    const handleDelete = async () => {
        processingAction.current = {
            action: 'delete',
            isLoading: true
        };
        await onDelete({ id: userId }).catch(e => {
            processingAction.current = {
                action: null,
                isLoading: false
            };
            console.error(e);
        });
    };

    return SignatureManagerUI({ isLoadingSignaturePref, isLoadingImage: isLoadingImage || processingAction.current.isLoading, handleUpload, handleClear, handleDelete, onFileSelected, hasUploadAccess, hasDeleteAccess, I18n: Text.resolve, signatureFile, signaturePrefFileContent });
};

export default SignatureManager;