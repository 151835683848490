import { getTemplate } from "@rjsf/utils";
import { ClearButton } from "../../components/ClearButton";
import { DoesSchemaFormAcceptsUnndefined, getFormOpts } from "../../utils/formUtils";

export const BaseInputWidgetTypes = Object.freeze({
    Integer: 'integer',
    Number: 'number'
});

const BaseComponent = (props) => {
    const { value, options, registry, onChange, schema } = props;
    const BaseInputTemplate = getTemplate('BaseInputTemplate', registry, options);
    const formOpts = getFormOpts(registry);
    const showClearButton = DoesSchemaFormAcceptsUnndefined(props) && formOpts?.showClearButton;
    const handleReset = () => onChange(undefined);
    const handleChange = (value) => {
        onChange(value);
    };

    const disabled = props.disabled || schema.isReadOnly;
    
    return (
        <>
            <div className="schema-form-align-end"> <BaseInputTemplate {...props} onChange={handleChange} disabled={disabled} /> </div>
            {disabled ? <></> : <div>{showClearButton && <ClearButton value={value} handleClick={handleReset} />}</div>}
        </>
    );
};  

const BaseInputTypesComponent = {
    [BaseInputWidgetTypes.Integer]: BaseComponent,
};

export const BaseInputWidget = (props) => {
    const { schema } = props;
    const type = schema?.type;
    const Component = BaseInputTypesComponent[type] || BaseComponent;
    return <Component {...props} />
};
