import './style.css';

export const LoaderBar = ({ text }) => (
    <div className="loader-bar-container">
        <div className="loader-bar-info">
            <img className="loader-bar-icon" src="web/sensor.png" width="120" height="67" />
            <span className="loader-bar-text">{text}...</span>
        </div>
        <div className="loader-bar-progress loader-bar-progress-moved">
            <div className="loader-progress-bar" />
        </div>
    </div>
);
