import { SchemaExtractor } from '../../../validation/schemaExtractor';
import { Joi } from '../../../validation/rules';
import { getAllowedFieldsForAttributeRights } from './attributeRights';
import { Roles } from '../../../iam/roles';

const ProductTest = {
    get Study() {
        return require('../../diagnosis/model').Study;
    },
    get Test() {
        return ProductTest.Study.entities.Test;
    },
    schemas: {
        SignalSettings: {
            formId: 'SignalSettings',
            get signalsFieldPaths() {
                delete this.signalsFieldPaths;
                const signalsField = SchemaExtractor.JoiSchemaDescriptor.find(ProductTest.Test.schema, (field) => field.metadata?.preferences?.id === 'Signals');
                const signalsRelativePath = signalsField.path.join(".");
                this.signalsFieldPaths = SchemaExtractor.JoiSchemaDescriptor.getKeys(signalsField.schema).reduce((acc, signalField) => {
                    if (signalField.id === '.*') return acc;
                    acc.push(signalsRelativePath + "." + signalField.id);
                    return acc;
                }, []);
                return this.signalsFieldPaths;
            },
            get schema() {
                delete this.schema;
                this.schema = Joi.object().keys(ProductTest.schemas.SignalSettings.signalsFieldPaths.reduce((acc, signalFieldPath) => {
                    acc[signalFieldPath] = Joi.boolean().default(false).meta({ preferences: { id: "SignalSettings" } });
                    return acc;
                }, {}));
                return this.schema;
            },
            getAllowedSignals(userAttributeRights) {
                const allowedSignalsPaths = getAllowedFieldsForAttributeRights(userAttributeRights, ProductTest.schemas.SignalSettings.signalsFieldPaths);
                return allowedSignalsPaths.map(path => path.split(".").pop());
            },
        },
        ReportSettings: {
            formId: 'ReportSettings',
            get queries() {
                delete this.queries;
                this.queries = [
                    "VIEW_DIAGNOSIS_REPORT",
                    "VIEW_RESPIRATORY_REPORT",
                    "VIEW_SPO2_REPORT",
                    "VIEW_CARDIAC_REPORT",
                    "VIEW_SNORING_REPORT",
                    "VIEW_SIGNALS_REPORT",
                    "VIEW_DIAGNOSIS_SUMMARY",
                    "VIEW_DIAGNOSIS_POSITION_REPORT",
                    "VIEW_POSITION_PER_HOUR_REPORT",
                ].reduce((acc, name) => {
                    acc[name] = ProductTest.Test.queries[name];
                    return acc;
                }, {});
                return this.queries;
            },
            get schema() {
                delete this.schema;
                this.schema = Joi.object().keys(Object.values(ProductTest.schemas.ReportSettings.queries).reduce((acc, query) => {
                    acc[query.type] = Joi.boolean().default(false).meta({ preferences: { id: "ReportSettings" } });
                    return acc;
                }, {}));
                return this.schema;
            },
            getSupportedReports: (product) => {
                const productAccessRights = new Set([...(Roles.expand(product.accessRights) || [])]);
                const supportedReports = Object.entries(ProductTest.schemas.ReportSettings.queries).reduce((acc, [queryName, query]) => {
                    if (productAccessRights.has(query.roles[0])) acc.push(queryName);
                    return acc;
                }, []);
                return supportedReports;
            },
        },
        SleepReportSettings: {
            formId: "SleepReportSettings",
            get schema() {
                delete this.schema;
                this.schema = Joi.object().keys({
                    formats: Joi.array().items(...ProductTest.Study.SLEEP_REPORT_TYPES).meta({ preferences: { id: ProductTest.schemas.SleepReportSettings.formId } }),
                });
                return this.schema;
            },
            mapToRightsAndData: (data = {}) => {
                const involvedRoles = ProductTest.Study.queries.EXPORT_SLEEP_STUDY.roles;

                return {
                    data,
                    rights: {
                        add: data.length === 0 ? [] : involvedRoles,
                        remove: data.length === 0 ? involvedRoles : [],
                    },
                };
            },
        },
    },
};

export default ProductTest;