import Navigation from 'ui/acukit/Navigation';
import navigation, { createItems } from 'modules/navigation';
import {Text} from 'ui/components/Text';
import useCurrentUser from "ui/hooks/useCurrentUser";

import './style.css';

const SideNavBar = (props) => {
  const [currentUser] = useCurrentUser();
  const navItems = navigation({currentUser, ...props});

  return <Navigation
      {...props}
      navCreate={[{
        titleGroup: Text.resolve('create'),
        itemsGroup: createItems({currentUser, ...props})
      }]}
      navItems={[
        { titleGroup: Text.resolve('Menu.main-menu-subtitle'), itemsGroup: navItems.filter(item => !item.itemsGroup) }        
      ].concat(navItems.filter(item => item.itemsGroup !== undefined))}
      //navSearch={Search}
    />;
};

export default SideNavBar;
