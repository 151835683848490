import { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "ui/components/Text";

export const SaveClinicianAnalysisStudyActionButton = ({ currentUser, signatureInfo, userSignature, onSave, submitText = "global.button.save" }) => (props) => {
    const onSubmit = () => {
        props.onSave?.(props.newValue);
        onSave(props.newValue);
        props.onClose();
    };

    if (props.id === "save-clinician-analysis-study-action-button" && signatureInfo && !userSignature.action) {
        const text = {};
        if (signatureInfo.userId !== currentUser.aggregate.id) {
            text.id = 'signature-will-be-deleted';
            text.heading = 'module.diagnosis.fe.component.save_clinician_analysis.signature_will_be_deleted';
            text.content = 'module.diagnosis.fe.component.save_clinician_analysis.signature_will_be_deleted_confirmation_modal';
        } else {
            text.id = 'reaply-signature';
            text.heading = 'module.diagnosis.fe.component.save_clinician_analysis.reaply_signature';
            text.content = 'module.diagnosis.fe.component.save_clinician_analysis.reaply_signature_confirmation_modal';
        }
        return <SignatureWillBeDeletedButton onResolve={onSubmit} text={text} />;
    }

    return <div key={`action-test`} id={props.id} onClick={onSubmit} className={`button primary`}>{<Text>{submitText}</Text>}</div>;
};

const SignatureWillBeDeletedButton = ({ onResolve, text }) => {
    return <CustomModal
        id={`${text.id}-confirmation-modal`}
        btn
        heading={Text.resolve(text.heading)}
        label={Text.resolve('global.button.save')}
        cancelLabel={Text.resolve("global.button.close")}
        secondaryLabel={Text.resolve("global.button.continue")}
        secondaryAction={onResolve}
        appearance={'primary'}
        size="sm"
    >
        <div className="confirmation-modal">
            <Text>
                {text.content}
            </Text>
        </div>
    </CustomModal>;
};