import './style.css';

import * as Administration from 'modules/administration';
import * as Devices from 'modules/devices';

import QuickFilters from 'ui/components/QuickFilters';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Batch, Device } from 'services/server/functions/model/devices/model';
import ExportReport from 'ui/components/ExportReport';
import { HealthcareSite } from 'services/server/functions/model/administration/model';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import WithSnapshot from 'ui/components/WithSnapshot';
import { dataFrom } from 'services/server/functions/executor/urn';
import history from 'history.js';
import { messageFrom } from 'modules/executor';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';

const inventoryReport = () => require('services/server/functions/executor/executor.client').getReport('devices.Device.inventory').then(encFile => `data:text/csv;charset=utf-8;base64,${encFile}`);
const FindDeviceUI = ({ devices, idOrSNOrBatch, organisation, healthcaresite, batch, status, onStatusChangedHandler, onIdOrSNOrBatchChangedHandler, notify, I18n }) => {
  //const StatusCounter = ({status}) => <div className="button" onClick={() => onStatusChangedHandler(status)}>{statusOverview[status]}</div>;
  const [currentUser] = useCurrentUser();

  return (
    <div id='Overview' className="page detailed">
      <Back/>
      <div className='pageHeader'>
        <h1 className='title'>{I18n('devices')}</h1>
        <div className="actions">
          {currentUser.hasAccess(Devices.Inventory) && <Devices.Inventory.AsLinkButton className='primary' text={I18n("manage-batches")} />}
          <Devices.Activity.AsLinkButton text={I18n('activity-log')} />
          {currentUser.hasAccess(Device.queries.GET_INVENTORY_REPORT) && <ExportReport type="csv" normal label={I18n("actions.inventoryReport")} id="exportInventory" urlCallback={() => inventoryReport()} onError={e => notify('error', I18n("error.inventoryReport"), messageFrom(e))}/>}
        </div>
      </div>
      <div className='pageContent'>
        {/*<div className='pageSection'>
          <div className='title'>Overview</div>
          <div className='grid-col'>
            {detailed ? <DetailsBlock title='Inventory' inline>
              <Details centered valueClass="counter" label='global.created' size='medium'>{statusOverview[Device.STATUS.created] ? <StatusCounter status={Device.STATUS.created} /> : 0}</Details>
              <Details centered valueClass="counter" label='IN-STOCK' size='medium'>{statusOverview[Device.STATUS.inStock] ? <StatusCounter status={Device.STATUS.inStock} /> : 0}</Details> 
              <Details centered valueClass="counter" label='RETIRED' size='medium'>{statusOverview[Device.STATUS.retired] ? <StatusCounter status={Device.STATUS.retired} /> : 0}</Details> 
            </DetailsBlock> : undefined}
            <DetailsBlock title={detailed ? 'Assigned' : undefined} inline>
              <Details centered valueClass="counter" label='READY' size='medium'>{statusOverview[Device.STATUS.ready] ? <StatusCounter status={Device.STATUS.ready} /> : 0}</Details>
              <Details centered valueClass="counter" label='ACTIVE' size='medium'>{statusOverview[Device.STATUS.active] ? <StatusCounter status={Device.STATUS.active} />: 0}</Details>
              <Details centered valueClass="counter" label='DISABLED' size='medium'>{statusOverview[Device.STATUS.disabled] ? <StatusCounter status={Device.STATUS.disabled} /> : 0}</Details>
            </DetailsBlock>
          </div>
        </div>*/}
        <div className='pageSection'>
          <div className='title'>{I18n('search-by')}</div>
          <DetailsBlock>
            <div className='filter grid-row'>
              <input id="Device.idOrSNOrBatch" placeholder={I18n("id-serial-batch")} onChange={(e) => onIdOrSNOrBatchChangedHandler(e.target.value)} value={idOrSNOrBatch || ''} />
              <QuickFilters header="">
                <div className="inline vcentered"><span>{Text.resolve("status").concat(':')}</span><QuickFilters.Select label='' className="selected" value={status} onChange={(e) => onStatusChangedHandler(e.target.value)} options={Object.values(Device.STATUS).filter(s => currentUser.hasAccess(Devices.Inventory) ? true : ![Device.STATUS.created, Device.STATUS.inStock, Device.STATUS.retired].includes(s)).map(s => ({label: Text.resolve(Device.STATUS_LABELS[s], {}, { schema: "devices.Device" }).toLowerCase(), value: s})).concat({label: Text.resolve('global.all')})}/></div>
                { organisation ? <Administration.ViewOrganisation.AsLinkIcon organisation={organisation} className="quickfilter selected" /> : undefined }
                { healthcaresite ? <Administration.ViewHCS.AsLinkIcon healthcaresite={healthcaresite} className="quickfilter selected" /> : undefined }
                { batch ? <Devices.ViewBatch.AsLinkButton batch={batch} className="quickfilter selected" /> : undefined }
              </QuickFilters>
            </div>
          </DetailsBlock>
        </div>
        <div className="pageSection">
          <Table
            keyField='id'
            columns={currentUser.hasAccess(Devices.Inventory) ? {
              idLabel     : { content: "id",            isSortable: true },
              batchId     : { content: "batch-id",      isSortable: true },
              serialNumber: { content: "serial-no",     isSortable: true },
              status      : { content: "status" }
            } : {
              idLabel     : { content: "id",            isSortable: true },
              status      : { content: "status" }
            }}
            onRowClick={((dev) => history.push(Devices.ViewDevice.PATH, {device: dev.id}))}
            data={devices.map(d => { d.batchId = Batch.id(d.batchId); d.idLabel = Device.id(d.id); d.status = (<div className={`status ${d.status.toLowerCase()}`}>{Text.resolve(Device.STATUS_LABELS[d.status], {}, { schema: "devices.Device" })}</div>); return d; })}
            defaultSortCol={'idLabel'}
            defaultSortDir={'asc'}
            emptyText={I18n("no-found")}
          />
        </div>
      </div>
    </div>
  );
};

//const DEVICE_STATUS = {[Device.STATUS.created]: 0, [Device.STATUS.inStock]: 0, [Device.STATUS.ready]: 0, [Device.STATUS.active]: 0, [Device.STATUS.disabled]: 0, [Device.STATUS.retired]: 0};

export default WithSnapshot(Device)(Object.assign((props) => {  
  const [{idOrSNOrBatch, organisation: org, healthcaresite: hcs, status: locStatus, batch: locBatchId}, setLocationState] = useLocationParams();
  
  const [hcsSnaps] = useSnapshot(HealthcareSite);
  
  const owner = hcs || org;
  const filteredStatus     = Object.values(Device.STATUS).find(s => locStatus && s.toLowerCase().startsWith(locStatus.toLowerCase())); // TODO: dodgy?? should be a better way

  const withBatchId        = ({batchId}) => locBatchId ? Batch.id(locBatchId) === Batch.id(batchId) : true;
  const withOwner          = ({owners}) => owner === undefined || owners.includes(owner) || HealthcareSite.ownersFrom({owners}).map(id => hcsSnaps[id]?.data.owners[0]).includes(owner);
  
  //const toOverview         = ({data: {id, status}}) => ({id, status});
  //const byOverviewState    = (device) => withBatchId(device.data) && withOwner(device.data);
  //const statusOverview     = {...DEVICE_STATUS, ...R.countBy(R.prop('status'))(Object.values(props.snapshots).filter(byOverviewState).map(toOverview))};

  const withIdOrSNOrBatch  = ({id, serialNumber, batchId}) => idOrSNOrBatch ? `${dataFrom(id).id} ${serialNumber} ${batchId}`.toLowerCase().includes(idOrSNOrBatch.toLowerCase()) : true;
  const withStatus         = ({status}) => filteredStatus ? status.includes(filteredStatus) : true;
  const byCurrentState     = (device) => withIdOrSNOrBatch(device.data) && withBatchId(device.data) && withStatus(device.data) && withOwner(device.data);
  const toTable            = ({data: {id, serialNumber, batchId, status}}) => ({id, serialNumber: serialNumber || "-", batchId: batchId || "-", status});
  const devices            = Object.values(props.snapshots).filter(byCurrentState).map(toTable);

  const setStatus          = (status) => setLocationState({ status })
  const setIdOrSNOrBatch   = (idOrSNOrBatch) => setLocationState({ idOrSNOrBatch })

  return FindDeviceUI({ 
    idOrSNOrBatch,
    organisation  : org,
    healthcaresite: hcs,
    batch         : locBatchId,
    status        : filteredStatus,
    //statusOverview,
    devices,
    onStatusChangedHandler       : setStatus,
    onIdOrSNOrBatchChangedHandler: setIdOrSNOrBatch,
    notify                       : props.notify,
    I18n                         : props.I18n                      
  });
}, {displayName: "FindDevice"}));
