import DetailsBlock from 'ui/components/DetailsBlock';
import Table from 'ui/components/Table';

const Issues = ({issues = {}, I18n}) => (
  <div className='pageSection'>
    <div className='title'>{I18n('issues')}</div>
    <DetailsBlock>
      <Table
        keyField='id'
        columns={{
          id         : { content: I18n("issue-id"),   isSortable: true },
          type       : { content: I18n("type"),       isSortable: true },
          description: { content: I18n("description") }
        }}
        withViewAction={false}
        data={Object.values(issues).map(issue => ({...issue, type: I18n(issue.type)}))}
        emptyText={I18n('no-issues-logged')}
        defaultSortCol={'id'}
        defaultSortDir={'desc'}
      />
    </DetailsBlock>
  </div>
);

export default Issues;