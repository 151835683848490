import { useCallback } from 'react';

import { Select } from 'ui/components/Select';
import { ClearButton } from '../../components/ClearButton';
import { SchemaFormVariantTypes } from '../../constants/types';
import { DoesSchemaFormAcceptsUnndefined, getFormOpts } from '../../utils/formUtils';
import { getUISchemaFormLabel } from '../../utils/localizationUtils';
import { booleanToEnum, enumToBoolean } from '../../utils/transformUtils';
import { CheckboxWidget } from '../CheckboxWidget';

import './style.css';


const getValue = (event, multiple) => {
    if (multiple) {
        return event?.target?.selectedOptions?.map(o => o?.value);
   }

    return event?.target?.value;
};


/*
    Available widget props by json schema lib
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple = false,
    autofocus = false,
    onChange,
    onBlur,
    onFocus,
    placeholder,
*/
export const Widget = (props) => {
    const {
        label,
        id,
        options,
        value,
        disabled,
        readonly,
        onChange,
        multiple = false,
        autofocus = false,
        registry,
        schema,
    } = props;
    const formContext = registry?.formContext || {};
    const { select: placeholder, enumTranslations } = getUISchemaFormLabel({ formContext, label });
    const formOpts = getFormOpts(registry);
    const showClearButton = DoesSchemaFormAcceptsUnndefined(props) && formOpts?.showClearButton;
    const selectOptions = options?.enumOptions?.map(o => ({
        ...o,
        label: enumTranslations[o?.label] ?? o?.label,
    }));
    const handleChange = useCallback(
        (event) => {
            onChange(getValue(event, multiple));
        }, [onChange]
    );
    const handleReset = () => onChange(undefined);
    return (
        <>
            <div className="schema-form-select-widget-comp">
                <Select
                    id={id}
                    name={id}
                    isMulti={multiple}
                    value={value}
                    options={selectOptions}
                    onChange={handleChange}
                    disabled={disabled || readonly || schema.isReadOnly}
                    autofocus={autofocus}
                    placeholder={placeholder}
                />
            </div>
            {<div>{showClearButton && <ClearButton id={id} value={value} handleClick={handleReset} />}</div>}
        </>
    );
};


const selectWidgetComponent = {
    [SchemaFormVariantTypes.Toggle]: (props) => {
        const handleChange = (value) => props?.onChange(booleanToEnum(value));
        return <CheckboxWidget {...props} value={enumToBoolean(props?.value)} onChange={handleChange} />;
    },
    [SchemaFormVariantTypes.Select]: Widget
};


export const SelectWidget = (props) => {
    const Component = selectWidgetComponent[SchemaFormVariantTypes.Select];
    return <Component {...props} />;
};