import { useCallback, useEffect, useRef, useState } from "react";
import useCancellablePromise from "./useCancellablePromise";
import { execute } from "modules/executor";
import usePageChange from "./usePageChange";
import useEffectOnMount from "./useEffectOnMount";
import usePrevious from "./usePrevious";

const exec = req => execute(req, { execute: { background: true }, notifications: { disabled: true } });

/**
 * Returns the requested projection (i.e. result of requested query)
 *
 * queryRequests = { id, request } || request
 * @param {Array<Object>|Object} queryRequests query request object (e.g. model.queries.QUERY_TO_BE_EXECUTED.newRequest(...args))
 * @param {number} options object which might contain the following options
 *  - autoStart  : Automatically start the query execution (default true): if false, the query execution must be triggered manually using returned start method
 *  - refreshRate: Automatically refresh projection at given rate in milliseconds (default disabled, i.e. 0)
 *  - onNewResult: Callback to be used when new results are available with new computed projection as argument of the callback
 * @return {Array} Array with the following elements:
 *  - projection: Query result
 *  - loading   : Execution loading status (if refresRate is used will be only true while executing the query for the first time)
 *  - start     : Method to start query execution manually
 *  - stop      : Method to stop current query execution manually
 *  - reset     : Method to reset current query execution manually
 */
export default (queryRequests, options = {}) => {
    const isArray = Array.isArray(queryRequests);
    if (!isArray) queryRequests = [queryRequests];

    const { autoStart = true, refreshRate = 0, onNewResults, debug, update, onError } = options;
    const { cancellablePromise, cancelAll } = useCancellablePromise();
    const queryPromises = useRef([]);
    const queryIntervals = useRef([]);

    const prevUpdate = usePrevious(update);

    const [projections, setProjections] = useState({});
    const [loading, setLoading] = useState(autoStart);
    const [errors, setErrors] = useState([]);

    const start = useCallback(hideLoading => {
        setErrors(new Array(queryRequests.length));
        const promises = queryRequests.map((req, index) => {
            const { id = index, queryRequest = req } = req;
            if (queryPromises.current[index] && !queryPromises.current[index].isCanceled()) {
                return queryPromises.current[index];
            }
            debug && console.debug(`Projection ${index} started`);

            if (!loading && !hideLoading) {
                setLoading(true);
            }
            const promise = cancellablePromise(exec(queryRequest).then(async results => {
                debug && console.debug("queryRequests", queryRequests, results);
                setProjections(projections => ({
                    ...projections,
                    [id]: results,
                }));
                onNewResults?.(results);
            })).catch(({ isCanceled, ...error }) => {
                debug && console.debug("error", error, isCanceled);
                setErrors(errors => {
                    errors[index] = error;
                    return errors;
                });
                return !isCanceled && Promise.reject(error);
            });

            return promise;
        });
        queryPromises.current = promises;

        if (refreshRate) {
            queryRequests.forEach((_, index) => {
                if (!queryIntervals.current[index]) {
                    queryIntervals.current[index] = setInterval(reset, refreshRate, true); // eslint-disable-line no-use-before-define
                }
            });
        }

        Promise.all(promises).finally(() => {
            setLoading(false);
            if (errors.length) onError?.(errors);
        });
    });

    const stop = useCallback(_ => {
        debug && console.debug("Projection stop");
        queryIntervals.current.forEach(interval => clearInterval(interval));
        queryIntervals.current = [];
        cancelAll();
        queryPromises.current = [];
    });

    const reset = useCallback((hideLoading) => {
        debug && console.debug(`Projection reset`);
        stop();
        setErrors([]);
        start(hideLoading);
    });

    useEffect(_ => {
        if (autoStart && queryPromises.current.length === 0 && queryRequests.length > 0) start();
    }, [queryPromises.current, autoStart]);

    useEffect(_ => {
        if (autoStart && prevUpdate !== undefined && prevUpdate !== update) reset();
    }, [autoStart, update]);

    usePageChange(stop);
    useEffectOnMount(_ => stop);

    const providedId = queryRequests[0]?.id !== undefined;
    const results = providedId ? projections : Object.keys(projections).map(k => projections[k]);
    return [isArray ? results : results[0], loading, isArray ? errors : errors[0], start, stop, reset];
};
