import { useEffect, useState } from 'react';

import {Text} from 'ui/components/Text';
import Action from 'ui/components/Action';
import DownloadIcon from './DownloadIcon';
import Modal from 'ui/acukit/ModalDialog';
import Spinner from '@atlaskit/spinner';

// TODO: these are reusable thunks, so they should be moved outside the UI components
const resolveFirebasePathThunk = (path) => (urlCallback, onError) => (path.match(/^gs:\/\/.*/) || !path.match(/[^:]+:\/\/.*/)) ? // Is firebase storage path
  import('services/firebase')
    .then(firebase => firebase.Storage().getDownloadURL(path))
    .then(urlCallback)
    .catch(onError) // TODO dispatch error notification ?
  : urlCallback(path)

export default ({url, urlCallback, downloadFile, type='pdf', disabled, label, id, onError, secondary, normal, tooltip, showProgModal: showProgModalOut, appearance='button', style = {}, onClosed=_=>_}) => {
  const responseToBlob = async res => await (await fetch(res)).blob(); // TODO: check if datauri or other possible format. Potentially move this to the executor.client
  const [reportObjectURL, setReportObjectURL]     = useState(undefined), responseToObjectUrl = async res => setReportObjectURL(URL.createObjectURL(await responseToBlob(res)));
  const [showProgModal, setShowProgModal]         = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => setShowProgModal(showProgModalOut), [showProgModalOut]);

  const onGenerateError = (e) => {
    console.log('Error generating report: ', e)
    onError && onError(e)
    setShowProgModal(false)
  }

  const generateReport = () => (urlCallback && Promise.resolve(urlCallback()).then(responseToObjectUrl).catch(onGenerateError)) || (url && resolveFirebasePathThunk(url)(responseToObjectUrl, onGenerateError))

  const download = (objectURL) => {
    const a = document.createElement("a");
    a.setAttribute('target', '_blank');
    a.setAttribute('href', objectURL);
    a.setAttribute("download", downloadFile || `report.${type}`);
    a.click();
  }

  const progressActions = [
    { text: 'close', onClick: () => { setShowProgModal(false); setShowDownloadModal(false); onClosed(); }},
  ];
  const downloadActions = [
    { text: 'close', onClick: () => { setShowDownloadModal(false); setReportObjectURL(undefined); onClosed(); }},
    { text: 'download', appearance: 'primary', onClick: () => { download(reportObjectURL) } }
  ];

  useEffect(() => {
    if (reportObjectURL && showProgModal) {
      setShowProgModal(false)
      setShowDownloadModal(true)
    }
  }, [reportObjectURL, showProgModal, showDownloadModal])

  useEffect(() => { return () => reportObjectURL && URL.revokeObjectURL(reportObjectURL); }, []);

  return (
    <>
      <Action handleAction={() => setShowProgModal(true)} id={id} tooltip={Text.resolve(tooltip || `global.download_${type}_message`)} label={Text.resolve(label)} disabled={disabled || (!url && urlCallback === undefined)} className={`${appearance} ${secondary ? 'secondary' : normal ? '' : 'primary'}`} style={style} />
      <Modal show={showProgModal} onOpen={generateReport} onClose={() => setShowProgModal(false)} actions={progressActions} dontCloseOnClick>
        <div className="centered grid-row">
          <DownloadIcon type={type} />
          <span><Text>{`export.${type}.preparing`}</Text></span>
          <span><Text>export.please-wait</Text></span>
          <div><Spinner size="large"/></div>
        </div>
      </Modal>
      <Modal show={showDownloadModal} onOpen={() => setShowProgModal(false)} onClose={() => setShowDownloadModal(false)} actions={downloadActions} dontCloseOnClick>
        <div className="centered grid-row">
          <DownloadIcon type={type} />
          <span><Text>{`export.${type}.you-can-download`}</Text></span>
        </div>
      </Modal>
    </>
  );
}