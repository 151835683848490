import Modal from 'ui/acukit/ModalDialog';
import { Text } from 'ui/components/Text';
import { WarningIcon } from 'ui/components/WarningIcon';

export const UnmatchStudyDetailsModal = ({ show, onClose }) => (
    <Modal id="UnmatchDetails" show={show} onClose={onClose} actions={[{ text: 'retry', onClick: onClose, appearance: 'primary modal-centered' }]}>
        <div className="validationError centered grid-row">
            <div className="errorIcon"><WarningIcon /></div>
            <span><Text>CreateStudy.error.details-dont-match</Text><br /><Text>CreateStudy.error.make-sure</Text></span>
        </div>
    </Modal>
);

export const ConfirmLeaveStudyModal = ({ show, onConfirm, onRegret }) => (
    <Modal
        show={show}
        onClose={onRegret}
        heading="unsaved-changes"
        actions={[
            { text: 'leave', onClick: onConfirm, appearance: 'primary' },
            { text: 'stay', onClick: onRegret },
        ]}
    >
        <span>
            <Text>sure-to-leave</Text>
        </span>
    </Modal>
);

export const ConfirmDuplicityStudyModal = ({ show, onClose, onConfirm }) => (
    <Modal
        show={show}
        onClose={onClose}
        heading="please-confirm"
        actions={[
            { text: 'confirm', onClick: onConfirm, appearance: 'primary' },
            { text: 'cancel', onClick: onClose },
        ]}
    >
        <span>
            <Text>CreateStudy.error.already-created</Text>
        </span>
    </Modal>
);