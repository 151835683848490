
import codegen from 'codegen.macro';

const languages = codegen.require('./index.macro');

export const locals = languages
  .map(path => ({ code: require('path').basename(path, '.json').toLowerCase(), path}))
  .reduce((all, {code, path}) => ({...all, [code]: _ => require(`${path}`)}), {});


const remotes = {};

const modules = new Proxy({}, {
  get(target, key, receiver) {
    if (!/^\w{2}(-\w{2})?(-x-.*)?$/.test(key)) {
      return Reflect.get(target, key, receiver);
    }

    if (remotes[key]) {
      return remotes[key];
    }
  
    //resolution order: 1. <lang>-x-<productKey>, 2. en-x-<productKey>, 3. <lang>, 4. en
    const [ lang , product ] = key.split('-x-'); // <lang>-x-<productKey>
    const next = lang === 'en' ? 
                      async _ => (await modules.en) || locals.en : 
                      async _ => (await (product && modules[`en-x-${product}`])) || (await modules[lang])
    
    return fetchAsset(`locales/admin-console/${key}.json`)
            .catch(next) // there are failures that we will need to retry somehow .. 404 specifically
            .then(_ => (remotes[key] = _));
  }
})


const ASSETS = {}
const fetchAsset = async (path, defaultValue) => {
  const ASSETS_DOMAIN = require('../../firebase/config').config.assetsDomain;
  const url           = `https://${ASSETS_DOMAIN}/${path}`;  

  ASSETS[url] || console.info('[locale] retrieving asset ', url);
  ASSETS[url] ??= require('axios').get(url).catch(e => defaultValue ? Promise.resolve({data: defaultValue}) : Promise.reject(e));

  try {
    return (await ASSETS[url])?.data;
  } catch (e) {
    console.error(`unable to fetch asset file '${url}'. Error:`, JSON.parse(e.toJSON()))
    throw e;
  }
};


export default modules;