import { createContext , useState, useMemo} from "react";
import Loading from "ui/components/Loading";
import { Text } from "ui/components/Text";

export const LoaderContext = createContext(false);

export const LoaderProvider = ({ children }) => {
    const [{
        showLoader,
        alternative,
        text
    }, setState] = useState({
        showLoader: false,
        alternative: false,
        text: 'App.loading-data'
    });
    const setShowLoader = (loader) => setState((prevState) => ({
        ...prevState,
        showLoader: loader
    }));

    const contextValue = useMemo(() => ({
        showLoader,
        setShowLoader,
        setState
    })); 

    return (
        <LoaderContext.Provider value={contextValue}>
            <Loading text={Text.resolve(text)} show={showLoader} size='xlarge' alternative={alternative} >
                {children}
            </Loading>
        </LoaderContext.Provider>
    );
};