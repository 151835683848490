import i18n from "services/server/functions/i18n";

const generateGroupName = ({ id, property }) => property?.group ? `${id}.${property?.group}.group_name` : `${id}.group_name`;

const generateOptsLocalization = (property) => ({
    enumTranslations: (property?.enum || property?.items?.enum)?.reduce((acc, opt) => ({
        ...acc,
        [opt]: i18n.resolve(opt)
    }),{})
});
const getPropsLocalization = ({ id, properties }) => properties?.reduce((acc, property) => {
    acc[property.prop] = {
        title: i18n.resolve(`${property?.i18n}.title`),
        help: property.help ? i18n.resolve(`${property?.i18n}.help`) : undefined,
        select: i18n.resolve('global.placeholder.select'),
        errors: {
            required: i18n.resolve("global.fields.required")
        },
        groupName: i18n.resolve(generateGroupName({ id, property })),
        ...generateOptsLocalization(property)
    };
    return acc;
}, {});

export const getUISchemaFormLabel = ({
    uiSchema, formContext, label
}) => {
    const defaultLabel = uiSchema?.title || label;
    const localization = formContext?.localization;
    const localizedLabel = localization?.[label];
    return {
        ...localizedLabel,
        title: localizedLabel?.title || defaultLabel
    };
};

export const prepareSchemaFormLocalization = ({ id, properties }) => ({
    ...getPropsLocalization({ id, properties }),
    status: {
        status: i18n.resolve("status"),
        yes: i18n.resolve("Yes"),
        no: i18n.resolve("No"),
        undefined: i18n.resolve("global.status.not_defined"),
        enabled: i18n.resolve("global.status.enabled"),
        disabled: i18n.resolve("global.status.disabled")
    },
    resolver: i18n.resolve
});
