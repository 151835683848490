import { forwardRef, useState, useRef, useEffect } from 'react';


import "react-datepicker/dist/react-datepicker.css";

import Calendar, {registerLocale} from 'react-datepicker';
import useWindowSize, { isSmallScreen } from 'ui/hooks/useWindowSize';

import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import ClearIcon from '@atlaskit/icon/glyph/editor/close';
import moment from 'moment';

import './style.css';
import { DATE_FULL_FORMAT_US } from 'features/helpers/dateHelper';

const CustomInput = forwardRef(({calRef, editable, handleChange, clearable, onBlur, onFocus, disabled, ...props}, _) => {
  const [parentClass, setParentClass] = useState("dateTimePickerInput");
  const {width, height} = useWindowSize();

  return <div className={`${parentClass}
   ${disabled ? 'calendarInput-disabled' : ''}`}>
    <input {...props} readOnly={!editable || width < 800 || height < 500} onKeyDown={e => e.keyCode === 9 && calRef?.current.setOpen(false)} onBlur={e => {setParentClass("dateTimePickerInput"); onBlur(e);}} onFocus={ e => { props.onClick(); setParentClass("dateTimePickerInput selected"); onFocus(e); } }/>
    {clearable && props.value && <div className="icon selectable" onClick={() => handleChange(undefined)}><ClearIcon size='medium'/></div>}
    <div className="icon selectable" onClick={props.onClick}><CalendarIcon size='medium'/></div>
  </div>
});

const calLocale = () => {
  const i18n     = require('services/server/functions/i18n').default;
  const {region} = require('services/server/functions/firebase/config').config;
  const locales  = require('date-fns/locale');

  const currentLocale = region === 'us' ? 'en-us' : region ? 'en-gb' : i18n.currentLocale?.toLowerCase() || i18n.current
  const config = locales[currentLocale.split('-').map((l, idx) => idx > 0 ? l.toUpperCase() : l).join('')] || locales[currentLocale.split('-').shift()] || locales['enGB'];
  registerLocale(currentLocale, config);
  return [currentLocale, config, region];
}
const [calendarLocale, config, region] = calLocale();

const dateFormat = region === "us" ? DATE_FULL_FORMAT_US : config.formatLong.date({ width: 'short' });

export default ({ valueFormat, disabled, editable = true, selectsRange, startDate, endDate, ...props }) => {
  const calRef = useRef();

  useWindowSize();
  const propagateChange = props.handleChange;
  const { clearable = true } = props;

  const toCalValue = (v) => valueFormat && v ? moment(v, valueFormat).toDate() : v;
 
  const handleChange = (v) => propagateChange?.({ target: { name: props.name, value: v } });
  
  useEffect(() => {
    if (selectsRange && endDate) {
      calRef?.current?.setOpen(false);
    }
  }, [endDate]);
  
  const onCalendarClose = () => {
    const hasRange = !!(startDate && endDate);
    if (!hasRange && selectsRange) {
      calRef?.current?.clear();
    }
  };

  return <div className={`calendarInput ${disabled ? "disabled" : ""}`} id={props.id}>
    <Calendar
      className="calendar"
      disabled={disabled}
      selectsRange={selectsRange}
      startDate={startDate}
      endDate={endDate}
      ref={calRef}
      autoComplete="off"
      showMonthDropdown showYearDropdown disabledKeyboardNavigation
      locale={calendarLocale}
      dateFormat={dateFormat}
      id={props.id}
      dropdownMode="select"
      maxDate={props.maxDate}
      minDate={props.minDate}
      onChange={handleChange}
      onCalendarClose={onCalendarClose}
      onYearChange={handleChange}
      onMonthChange={handleChange}
      placeholderText={props.placeholder || dateFormat?.toLowerCase()}
      selected={toCalValue(props.value)}
      openToDate={toCalValue(props.value) || props.openToDate}
      withPortal={isSmallScreen()}
      customInput={<CustomInput calRef={calRef} editable={editable} clearable={clearable && !disabled} handleChange={handleChange} disabled={disabled} />}
      {...props}
    />
  </div>;
};

