import { DataBase } from "../firebase"; 
import _transform from "./transformers"; // Applies backwards compatibility transformations

export const transform = (reference, options) => async events => {
  events = await _transform(events);
  
  if (options?.skipOtherInstances && reference.aggregate?.id) {
    // note: transform might generate events for other instances or even other aggregates as backwards compatibility result
    events = events.filter(evt => evt.aggregate.id === reference.aggregate.id)
  }

  return events;
}

export const eventsFrom = async (reference, options={}) => {
  const query = DataBase().events().from(reference).startAfter(options.startAfter).endAt(options.endAt);
  // TODO: Use stream of events instead of list of events to have a more scalable solution (not critical since it is not expected an aggregate instance to have a very large amount of events)
  
  return await transform(reference, options)(await query.list()); // Applies backwards compatibility transformations
}