import { ModelFormTypes } from "features/constants/modelActionTypes";
import i18n from "services/server/functions/i18n";

import MODELS from 'modules/model';

export const FeatureToggleTypes = Object.freeze({
    AllFeatures: 'AllFeatures',
});

class FeatureToggleConfig {
    #formId = null;
    #action = null;
    #schemaToRetrieve = null;
    #options = null;
    #label = null;

    constructor({ formId, action, schemaToRetrieve, options, label }) {
        this.#formId = formId;
        this.#action = action;
        this.#schemaToRetrieve = schemaToRetrieve;
        this.#options = options;
        this.#label = label;
    }

    get action() {
        return this.#action;
    }

    get schemaToRetrieve() {
        return {
            formId: this.#formId,
            action: this.#action.exec,
            ...this.#schemaToRetrieve,
        };
    }

    get options() {
        return this.#options;
    }

    get label() {
        return this.#label;
    }
}

export const FormConfig = {
    [ModelFormTypes.StudySettings]: new FeatureToggleConfig({
        formId: ModelFormTypes.StudySettings,
        action: MODELS.diagnosis.Study.actions.GET_STUDY_CONFIG_SETTINGS_FORM,
        schemaToRetrieve: {},
        options: {
            showClearButton: true,
        },
        label: {
            title: i18n.resolve("StudySettings.form_title"),
        }
    }),
    [ModelFormTypes.SignalSettings]: new FeatureToggleConfig({
        formId: ModelFormTypes.SignalSettings,
        action: MODELS.administration.Organisation.actions.GET_SIGNAL_SETTINGS_FORM,
        schemaToRetrieve: {},
    }),
    [ModelFormTypes.SignalsReportSettings]: new FeatureToggleConfig({
        formId: ModelFormTypes.SignalsReportSettings,
        action: MODELS.administration.Organisation.actions.GET_REPORT_SETTINGS_FORM,
        schemaToRetrieve: {},
    }),
    [ModelFormTypes.ManualScoringSettings]: new FeatureToggleConfig({
        formId: ModelFormTypes.ManualScoringSettings,
        action: MODELS.administration.Organisation.actions.GET_FEATURE_ACTIONS_FORM,
        schemaToRetrieve: {
            dataOverrides: {
                featureId: ModelFormTypes.ManualScoringSettings,
            },
        },
    }),
    [ModelFormTypes.DigitalSignature]: new FeatureToggleConfig({
        formId: ModelFormTypes.DigitalSignature,
        action: MODELS.administration.Organisation.actions.GET_FEATURE_ACTIONS_FORM,
        schemaToRetrieve: {
            dataOverrides: {
                featureId: ModelFormTypes.DigitalSignature,
            },
        },
    }),
    [ModelFormTypes.ReviewedStudies]: new FeatureToggleConfig({
        formId: ModelFormTypes.ReviewedStudies,
        action: MODELS.administration.Organisation.actions.GET_FEATURE_ACTIONS_FORM,
        schemaToRetrieve: {
            dataOverrides: {
                featureId: ModelFormTypes.ReviewedStudies,
            },
        },
    }),
};