import * as R from "ramda";
import { SchemaExtractor } from "../validation/schemaExtractor";

const I18N = {
    "Submit": "global.button.save",
    "Reset": "global.button.reset",
};

const Form = {
    create: () => ({
        type: "object",
        required: [],
        properties: {},
        allOf: [],
    }),
    createCondition: (operators) => {
        const condition = {
            if: {
                properties: {},
            },
        };
        for (const operator of operators) {
            condition[operator] = {
                required: [],
                properties: {},
            };
        }
        return condition;
    }
};

const BUILD_FIELD_PROPERTY_DEFAULT_OPTIONS = { supportedTypes: ["integer", "string", "boolean", "array"], supportedProps: ['type', 'minimum', 'maximum', 'enum', 'default', 'isReadOnly', 'order', 'group', 'help', 'items', 'maxItems', 'uniqueItems'] };
const buildFieldProperty = (field, options = BUILD_FIELD_PROPERTY_DEFAULT_OPTIONS) => {
    if (!options?.supportedTypes?.includes(field.type)) {
        throw new Error(`[buildFieldProperty] Non supported type '${field.type}'`);
    }
    const property = (options?.supportedProps || []).reduce((acc, curr) => {
        if (field[curr] === undefined) return acc;
        acc[curr] = field[curr];
        return acc;
    }, {});
    Object.assign(property, field.meta?.extension);
    return property;
}

const getFieldKey = (field) => field.field;
// TODO: for now we just always return true
// eslint-disable-next-line no-unused-vars
const oppositeConditions = (conditions1, conditions2) => true;

// TODO: first version with repeated conditions per field
// Try to group them on the next iteration
const buildHideCondition = (field, fieldI18n) => {
    const operator = field.hideConditions.operator === "then" ? "else" : "then";
    const condition = Form.createCondition([operator]);

    // TODO: for now just taking into account the first condition as we only use one
    const conditionData = field.hideConditions.conditions[0].data;
    const condOp = conditionData.comparator === '>' ? 'minimum' : 'const';
    const condVal = conditionData.comparator === '>' ? conditionData.value + 1 // this is  implemented as >= and assuming integers
                                                     : conditionData.value;
    condition.if.properties[conditionData.id] = {
        [condOp]: condVal
    };
    if (operator === "then") condition.if.required = [conditionData.id]; // This is so that when the value is undefined it remains hidden
    const fieldKey = getFieldKey(field);
    const fieldProperty = buildFieldProperty(field);
    fieldProperty.i18n = fieldI18n;
    condition[operator].properties[fieldKey] = fieldProperty;

    // We should only mark it as required if the required condition is opposite to the hide condition
    if (field.requiredConditions && oppositeConditions(field.hideConditions, field.requiredConditions)) {
        condition[operator].required.push(fieldKey);
    }

    return condition;
}

const groupBy = (arr, key) => R.groupBy(R.path(key.split(".")))(arr);

const setBuildFormDefaultOptions = (options) => {
    if (!options) options = {};
    if (!options.i18n) options.i18n = {};
    if (!options.i18n.base) options.i18n.base = "";
    if (!options.i18n.addKey) options.i18n.addKey = (curr, key) => `${curr}.${key}`;
    return options;
}

const buildForm = (fields, options) => {
    options = setBuildFormDefaultOptions(options);
    const fieldsByGroup = groupBy(fields, "group");
    const sortedGroupKeys = Object.keys(fieldsByGroup).sort((a, b) => fieldsByGroup[a][0].order - fieldsByGroup[b][0].order);

    const form = Form.create();
    for (const groupKey of sortedGroupKeys) {
        const groupFields = fieldsByGroup[groupKey];
        const groupI18n = options.i18n.addKey(options.i18n.base, groupKey);
        for (const field of groupFields.sort((a, b) => a.order - b.order)) {
            const fieldKey = getFieldKey(field);
            const fieldProperty = buildFieldProperty(field);
            const fieldI18n = options.i18n.addKey(groupI18n, fieldKey);
            if (field.hideConditions) {
                const hideCondition = buildHideCondition(field, fieldI18n);
                form.allOf.push(hideCondition);
            } else {
                form.properties[fieldKey] = fieldProperty;
                form.properties[fieldKey].i18n = fieldI18n;
                if (field.required) {
                    form.required.push(fieldKey);
                }
            }
        }
    }
    return form;
}

const setGetFormBuilderDefaultOptions = (options) => {
    if (!options) options = {};
    if (!options.tailors) options.tailors = [];
    return options;
}

const getFormBuilder = (rootSchema, options) => {
    options = setGetFormBuilderDefaultOptions(options);
    return {
        generate: (data) => {
            const schema = options.tailors.reduce((a, t) => a.tailor(t), rootSchema);
            const fields = SchemaExtractor.getFieldsPopulated(schema, data, options);
            const form = buildForm(fields, options);
            return form;
        }
    }
}

const camelToSnake = (camel) => {
    const result = camel.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    return result.charAt(0) === '_' ? result.slice(1) : result;
}

const setFormBuilderFactoryDefaultOptions = (options, formName) => {
    if (!options) options = {};
    if (!options.tailors) options.tailors = [];
    if (!options.filter) options.filter = (meta) => meta?.preferences?.id === formName;
    return options;
}

export const FormBuilderFactory = {
    // The model can be a model, a command or a query
    get: (model, formName, options) => {
        options = setFormBuilderFactoryDefaultOptions(options, formName);
        const { context, name } = model.parent || model; // if a command or query, then model.parent is the aggregate model
        const formBuilderOptions = {
            i18n: {
                base: `${context}.${name}.forms.${camelToSnake(formName)}`.toLowerCase(),
            },
            ...options,
            tailors: ["form", ...options.tailors],
        };
        const formBuilder = getFormBuilder(model.schema, formBuilderOptions);
        return formBuilder;
    },
    makeAllRequired: (form) => {
        form.required = Object.keys(form.properties);
        form.allOf.forEach(e => {
            const subForm = e.then || e.else;
            subForm.required = Object.keys(subForm.properties);
        });
    },
}

export const FormParser = {
    // Each iteration returns [property, data]
    getPropertiesIterator: function* (form) {
        yield* [
            form.properties,
            (form.allOf || []).reduce((acc, curr) => Object.assign(acc, curr.if.properties, curr.then?.properties, curr.else?.properties), {}),
        ].filter(Boolean).flatMap(props => Object.entries(props));
    },
    getProperties: (form) => {
        return [...FormParser.getPropertiesIterator(form)];
    },
}

export const populateActions = (actions) => {
    return actions.map((action) => {
        action.i18n = I18N[action.id];
        return action;
    })
};