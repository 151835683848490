
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Organisation } from 'services/server/functions/model/administration/model';
import Spinner from '@atlaskit/spinner';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Text } from 'ui/components/Text';
import { ValidatedSelect } from 'features/helpers/validators';


const renderTrialSelect = ({
    trialOptions, loadingTrials, isEditPage, hasAccess, trial, formSaved, onChange, onValidate
}) => {
    if (loadingTrials) {
        return <Spinner size="small" />;
    }
    
    if (isEditPage && !hasAccess(Study.commands.UPDATE_STUDY)) {
        return <span>{trialOptions.find((o) => o.value === trial)?.label || "-"}</span>;
    }

    return (
        <ValidatedSelect
            options={trialOptions || []}
            name="trial"
            placeholder={Text.resolve("CreateStudy.select-trial.placeholder")}
            isClearable={true}
            schema={Organisation.entities.Trial.schema.extract("id")}
            showError={formSaved}
            onChange={onChange}
            onValidate={onValidate}
            value={trial}
        />
    );
};

export const PatientTrialForm = ({
    trialOptions,
    loadingTrials,
    hasAccess,
    isEditPage,
    trial,
    formSaved,
    onChange,
    onValidate
}) => {
    return (
        <div className={`pageSection ${trialOptions.length > 0 && hasAccess(Organisation.entities.Trial.queries.LIST) ? '' : 'hidden'}`}>
            <div className='title'>
                <Text>CreateStudy.trial-section</Text>
            </div>
            <DetailsBlock>
                <Details id="trialField" label={isEditPage ? undefined : "CreateStudy.select-trial.question"}>
                    {renderTrialSelect({
                        trialOptions, loadingTrials, isEditPage, hasAccess, Study, trial, formSaved, onChange, onValidate
                    })}

                </Details>
            </DetailsBlock>
        </div>
    );
};
