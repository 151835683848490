import { Fragment, useState } from 'react';
import { sendPhoneVerifyCode, verifyPhoneCode } from 'services/iam';
import { recaptchaVerifier } from 'services/security';
import { Text } from 'ui/components/Text';
import Modal from 'ui/acukit/ModalDialog';
import Spinner from '@atlaskit/spinner';

const PhoneVerificationModal = ({show, phone, onSuccess, onError, close}) => {

  const [sessionInfo, setSessionInfo] = useState(undefined)
  const [sendingCode, setSendingCode] = useState(true)

  const onOpen = () => phone && sendPhoneVerifyCode(phone, recaptchaVerifier('2-factor-recaptcha-container'))
                        .then((sessionInfo) => { setSendingCode(false); setSessionInfo(sessionInfo); })
                        .catch(e => { setSendingCode(false); onError(e); });
  
  return (
    <Modal show={show} onOpen={onOpen} onSuccess={onSuccess} heading={Text.resolve("title", {}, {component: "PhoneVerificationModal"})}
                  actions={[{ text: 'cancel', id: 'modal-cancel', appearance: 'default', onClick: close }]} onClose={close}
    >
      {sendingCode ?
        <Fragment>
          <div className="grid-row centered">
            <Text context={{component: "PhoneVerificationModal"}}>sending-code</Text><br/>
            <Spinner size="large" />
          </div>
        </Fragment>
        :
        <Fragment>
          <div className="grid-row centered">
            <Text>global.phone.verification_code_message</Text><br/>
            <input name="verificationCode" autoComplete="one-time-code" type="number" onChange={(e) => {
              if (/[0-9]{6}/.test(e.target.value)) {
                verifyPhoneCode(e.target.value, sessionInfo).then(_ => onSuccess()).catch(e => onError(e))
              }
            }}/>
          </div>
        </Fragment>
      }
    </Modal>
  );
}

export default PhoneVerificationModal;