const errorMessages = (type, ...args) => {
  let messages = {
    'any.only': 'Invalid option, select one of {{#valids}}',
    'any.empty': 'Please complete this field',
    'any.required': (type === 'required' && args.length > 0) ? `${args[0]}` : 'Please complete this field',
    'array.min': 'Please provide at least {{#limit}}',
    'number.positive': 'Must be a positive number',
    'number.max': 'Value must be lower or equal to {{#limit}}',
    'string.empty': 'Please complete this field',
    'string.phonenumber': 'Please enter the number preceded by + and the country code, e.g. +44 208 1917590',
    'string.email': 'Please enter a valid email address',
    'string.alphanum': 'Please use only letters and numbers (i.e. no symbols or spaces)',
  }

  if (type === 'regex') messages['string.pattern.base'] = getRegexMessage(args[0]);       // TODO: change to string().pattern() ?
  if (type === 'equalsTo') messages['any.only'] = `${args[0]}`;

  return messages;
}

function withCustomError(rule, type, ...args) {
  return rule.messages(errorMessages(type, ...args));
}

function getRegexMessage(name) {
  var message = undefined;
  if (name === "postCode") {
    message = 'Please enter a valid postal/ZIP code, e.g. CR2 6XH';
  } else if (name === "actCode") {
    message = 'Please enter a valid 6-digit activation code';
  }
  
  if (message) {
    return message;
  } else {
    return 'This field does not match the expected pattern';
  }
}

export { withCustomError, errorMessages }
