import DetailsBlock from 'ui/components/DetailsBlock';
import Details from 'ui/components/Details';
import MailIcon from '@atlaskit/icon/glyph/mention';
import Table from 'ui/components/Table';
import WithSnapshot from 'ui/components/WithSnapshot';
import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import { User } from 'services/server/functions/model/authentication/model';
import { hasRole } from 'services/server/functions/iam';
import useSnapshot from 'ui/hooks/useSnapshot';
import useCurrentUser from 'ui/hooks/useCurrentUser';

import * as Authentication from 'modules/authentication';
import * as Administration from 'modules/administration';

import SupportForm from './SupportForm';

import {Text} from 'ui/components/Text';

import history from 'history.js';
import { isSmallScreen } from 'ui/hooks/useWindowSize';
import { currentRegion } from 'services/server/functions/firebase/config/config.client';

const ContactForm = ({ admins, user, org }) => {
  const acurableEmail = currentRegion === "us" ? "support-us@acurable.com" : "support@acurable.com";
  if (currentRegion === "us") {
    return (<>
      <span>{!admins ? Text.resolve("Contact.contact-acurable") : Text.resolve("Contact.further-contact-acurable")}</span>
      <Details className='indented' value={acurableEmail} iconSize="dynamic" vcentered icon={<MailIcon primaryColor={'black'} />} />
    </>);
  }

  return (<>
    <span>{!admins ? Text.resolve("Contact.contact-acurable") : Text.resolve("Contact.further-contact-acurable")}</span>
    <DetailsBlock>
      <div className={isSmallScreen() ? 'grid-row' : 'grid-col'}>
        <div className='grid-row'>
          <div><Text>Fill in this form and we will get back to you as soon as possible.</Text></div>
          <div><Text>Or email us at:</Text></div>
          <Details className='indented' value={acurableEmail} iconSize="dynamic" vcentered icon={<MailIcon primaryColor={'black'} />} />
        </div>
        <SupportForm email={user.mail} organisation={org.name} firstname={user?.name || ''} lastname={user?.lastName || ''}></SupportForm>
      </div>
    </DetailsBlock>
  </>);
};

export default WithSnapshot(User)((props) => {
  const [currentUser] = useCurrentUser();
  
  let contactOrg       = currentUser.hasAccess(Administration.ViewOrganisation) && Organisation.ownersFrom(currentUser?.data);
  const isOrganisation = contactOrg && contactOrg.length > 0;

  if (!isOrganisation) contactOrg = currentUser.hasAccess(Administration.ViewHCS) && HealthcareSite.ownersFrom(currentUser?.data);
  contactOrg = contactOrg && contactOrg[0];

  const model      = isOrganisation ? Organisation : HealthcareSite;
  const orgLink    = isOrganisation ? <Administration.ViewOrganisation.AsLink text='organisation' id={contactOrg}/> : <Administration.ViewHCS.AsLink text='healthcare site' id={contactOrg}/>;
  const adminOf    = (model, expected) => ({data: {owners, roles}}) => owners.includes(expected) && hasRole(model.roles.manager.id)(roles);
  const admins     = Object.values(props.snapshots).filter(adminOf(model, contactOrg)).map(s => s.data);
  const [orgSnap]  = useSnapshot(model, contactOrg);
  const org        = orgSnap?.data || {};
  const user       = currentUser?.data || {};

  const sortByLastName = (_1, _2, order, _3, row1, row2) => (order === 'asc' ? 1 : -1) * (row1.lastName?.toLowerCase() > row2.lastName?.toLowerCase() ? 1 : -1);
  return (
    <div className='page'>
      <div className='pageHeader'>
        <h1 className='title wraptext'><Text>Contact.title</Text></h1>
      </div>
      <div id='Contact' className="pageContent">
        <div className="pageSection grid-row">
          <DetailsBlock>
            <span><Text variables={{orgLink}}>Contact.contact-admins</Text></span>
            {admins && currentUser.hasAccess(User.queries.LIST) && <DetailsBlock>
                <Table
                  keyField='id'
                  columns={{
                    fullName     : { content: "name",         isSortable: true, sortFunc: sortByLastName },
                    mail         : { content: "email-address" },
                    job          : { content: "job" }
                  }}
                  onRowClick={((u) => currentUser.hasAccess(Authentication.ViewUser) && history.push(Authentication.ViewUser.PATH, {user: u.id}))}
                  data={admins.filter(u => u.status !== User.STATUS.disabled).map(u => ({...u, fullName: [u.lastName, u.name].join(', ')}))}
                  defaultSortCol={'fullName'}
                  defaultSortDir={'asc'}
                  emptyText="Contact.no-admins-found"
                />
            </DetailsBlock>}
            <ContactForm admins={admins} user={user} org={org} />
          </DetailsBlock>
        </div>
      </div>
    </div>
  );
});
