import { useCallback } from "react";

import Details from "ui/components/Details";
import { AutoComplete } from "features/components/AutoComplete";
import { FiltersType } from "../../utils";

export const HealthcareSiteField = ({ filters, onFilterChange, items, isDisabled }) => {
    const handleChange = useCallback((option) => onFilterChange({ [FiltersType.HealthcareSite]: option?.value }), [onFilterChange]);
    return (
        <Details id="hcsDetails" label="hcs">
            <AutoComplete
                id={FiltersType.HealthcareSite}
                items={items}
                value={filters[FiltersType.HealthcareSite]}
                handleChange={handleChange}
                selectProps={{
                    isMulti: false,
                    isClearable: true,
                    isDisabled: !items?.length || isDisabled,
                }}
                forceDefault={true}
                forceOneItem={true}
            />
        </Details>
    );
};