import { VALID_DATA_POLICY, FORBIDDEN_POLICY, when } from '../../policies';
import { DUPLICATE_ERROR, INVALID_DATA_ERROR } from '../../../errors/codes';
import { getResolution } from '../../../errors/messages';
import { User } from '.';
import { Roles } from '../../../iam/roles';
import { hasAllRoles } from '../../../iam';

const Product = () => require('../../administration/model').Product;

export const PHONE_EXISTS_POLICY = (details) => ({ code: DUPLICATE_ERROR, reason: 'A user with the provided phone number already exists', resolution: getResolution(DUPLICATE_ERROR), details });
export const PHONE_REQUIRED_POLICY = (details) => ({ code: INVALID_DATA_ERROR, reason: 'Mobile phone required when 2-factor authentication is enabled', resolution: getResolution(INVALID_DATA_ERROR), details });
export const UNIQUE_EMAIL_POLICY = (details) => ({ code: DUPLICATE_ERROR, reason: 'Email already in use', resolution: getResolution(DUPLICATE_ERROR), details });

export const checkMailDoesNotExist = (user, executor) => executor.execute(User.queries.MAIL_EXISTS.newRequest({ ...user, where: [{ field: 'id', operator: '!=', value: user.id }] })).then(exist => when(exist).rejectWith(UNIQUE_EMAIL_POLICY(`User email ${user.mail} already in use.`)));

export const checkPhoneDoesNotExist = (user, executor) => executor.execute(User.queries.PHONE_EXISTS.newRequest({ ...user, where: [{ field: 'id', operator: '!=', value: user.id }] })).then(exist => when(exist).rejectWith(PHONE_EXISTS_POLICY(`User phone ${user.phone} already in use.`)));

export const hasAssignedStudies = async (user, executor) => {
  const studies = await executor.execute(require('../../diagnosis/model').Study.queries.LIST.newRequest({ where: { field: 'data.clinician', operator: '==', value: user.id } }));

  return studies.length !== 0;
}

export const checkNewUserRoles = async (currentUser, newUserData) => {
  if (newUserData.roles.some((role) => !Roles.get(role))) {
    throw VALID_DATA_POLICY("Can not assign invalid roles");
  }

  const currentUserHasEnoughAccessRights = hasAllRoles(newUserData.roles)(currentUser.data.roles);
  if (!currentUserHasEnoughAccessRights) {
    throw FORBIDDEN_POLICY("Can not assign roles out of your scope of access rights");
  }
};

export const checkRoleRelationships = async (roleId, executor) => {
  const productsUsingRole = await executor.execute(Product().queries.LIST.newRequest({ where: { field: "data.accessRights", operator: "array-contains", value: roleId } }));

  if (productsUsingRole.length > 0) {
    throw FORBIDDEN_POLICY("Can not delete role as it's being used by some products");
  }
};

export const userEmailAlreadyExists = async (executor, email) => {
  const usersWithEmail = await executor.execute(User.queries.LIST.newRequest({ where: { field: "data.mail", operator: "=", value: email }}));
  if (usersWithEmail.length) throw UNIQUE_EMAIL_POLICY(`User email ${email} already in use.`);
};