
import { Text } from "ui/components/Text";

import { useSettings } from 'features/hooks/useSettings';
import { ModelFormTypes } from 'features/constants/modelActionTypes';
import { SchemaFormList } from 'features/components/SchemaForm';

import MODELS from 'modules/model';

export const TemplateSettings = ({ ownerId }) => {
    const { schemas, onClickAction } = useSettings({
        ownerId,
        schemasToRetrieve: [
            {
                formId: ModelFormTypes.StudyTemplateSettings,
                action: MODELS.diagnosis.Study.queries.GET_STUDY_TEMPLATE_SETTINGS_FORM.exec
            },
        ]
    });
    const formProps = { onClickAction };

    return (
        <>
            {schemas && (
                <div className="pageSection" id="templateSettings">
                    <div className="sectionHeader">
                        <div className="title">
                            <Text>module.diagnosis.fe.view.healthcareSite.templates</Text>
                        </div>
                    </div>
                    <SchemaFormList schemas={schemas} formProps={formProps} />
                </div>
            )}
        </>
    );
};

TemplateSettings.hasAccess = (currentUser) => {
    return currentUser.hasAccess(MODELS.diagnosis.Study.queries.GET_STUDY_TEMPLATE_SETTINGS_FORM);
}