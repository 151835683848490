import {Text} from 'ui/components/Text';

export default {
  topic: Text.resolve("PrivacyPolicy.topics.cookies.question"),
  component: () => <div className="grid-row">
    <span><Text 
            variables={{
              aboutCookiesLink: (<a href="http://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">www.aboutcookies.org</a>),
              aboutAllCookiesLink: (<a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>)
            }}>
            PrivacyPolicy.topics.cookies.answer-1
          </Text>
    </span>
    <span><Text>PrivacyPolicy.topics.cookies.answer-2</Text></span>
  </div>
};