import './style.css';
import * as R from 'ramda';
import { Device } from 'services/server/functions/model/devices/model';
import * as Devices from 'modules/devices';
import Action from 'ui/components/Action';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import FileDrop from 'ui/components/FileDrop';
import Back from 'ui/components/Back';
import Papa from 'papaparse';
import { Fragment, useState } from 'react';
import useSnapshot from 'ui/hooks/useSnapshot';

const RegisterBatchUI = ({onUploadCSV, I18n}) => (
  <div id='RegisterBatch' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
    </div>
    <div className='pageContent'>
      <div className='instructions'>{I18n('instructions')}</div>
      <div className='upload-form'>
        <FileDrop id="csv" onFileDrop={onUploadCSV} />
      </div>
    </div>
  </div>
);

const ReviewBatchesUI = ({batches, errors, total, progress, onConfirm, onCancel, I18n}) => (
  <div id='ReviewBatch' className='page'>
    <div className='pageHeader'>
      <div className='title'>{I18n('title')}</div>
      <div className='actions'>
        <Back />
      </div>
    </div>
    <div className='pageContent'>
      <div className='pageSection'>
        <div className='title'>{I18n('review-details')}</div>
        { Object.keys(batches).map((id,i) => ( 
          <DetailsBlock key={i}>
            <div className="grid-col">
              <Details label='batch-id'>{id}</Details> 
              <Details label='serial-no'>{batches[id].serialNumber}</Details>
            </div>
            <Details label='number-of-devices'>{batches[id].size}</Details>
          </DetailsBlock>
        ))}
        {!errors || errors.length <= 0 ? <Fragment/> : (
          <div className='errors'>
            <div className='section'>{I18n('errors')}</div>
            <div className='sub-title'>{I18n('global.type')}</div>
            <div className='sub-title'>{I18n('code')}</div>
            <div className='sub-title'>{I18n('message')}</div>
            <div className='sub-title'>{I18n('row')}</div>
            <div className='line'/>
            {errors.map((e, i) => (
              <Fragment key={i}>
                <div className='type'>{e.type}</div>
                <div className='code'>{e.code}</div> 
                <div className='message'>{e.message}</div>
                <div className='grid-row'>{e.row}</div>
              </Fragment>
            ))}
            <div className='line' />
          </div>
        )}
      </div>
      <div className='pageFooter'>
        <div className='actions'>
        {
          (progress >= 100) ? Object.keys(batches).map((id) => <Devices.ViewBatch.AsLinkButto key={id} text="view" batch={id} className='button primary' />) : 
          (progress  >   0) ? Object.keys(batches).map((id) => <Devices.ViewBatch.AsLinkButton key={id} text={`${progress}%`} batch={id} className='button primary' />) :
          <Fragment >
            <Action className='button' handleAction={onCancel} id="import-cancel" label='cancel' />
            <Action className='button primary' disabled={!total || errors.length || progress} handleAction={onConfirm} id="import-confirm" label='import' />
          </Fragment>
        }
        </div>
      </div>
    </div>
  </div>
);

const RegisterBatch = (props) => {
  const [devSnaps] = useSnapshot(Device);
  const [{batches, total, errors}, setBatches] = useState({batches: undefined, total: undefined, errors: undefined});
  
  const batchIds     = Object.keys(batches || {});
  const withBatchIds = (ids) => ({data: {batchId}}) => ids.includes(batchId);
  
  // const withIds      = (ids) => ({data: {id}}) => ids.includes(id);
  // const [batchSnaps] = useSnapshot(Batch);
  // const registeredBatches = Object.values(batchSnaps).filter(withIds(batchIds)).length;
  
  const registeredDevices = Object.values(devSnaps).filter(withBatchIds(batchIds)).length;
  const progress = Math.round(100*registeredDevices/total);
  
  const loadBatches = (csv) => {
    // CSV: BATCH_ID, SERIAL_NUMBER, SOFTWARE_ID, HARDWARE_ID, DEVICE_ID, DEVICE_TYPE
    const byBatchId = R.groupBy(R.path(['BATCH_ID']));
    const toDevice  = ({ id, serialNumber }) => (d) => ({ batchId: id, serialNumber, id: d.DEVICE_ID, type: d.DEVICE_TYPE });
    const toBatch   = (content, id) => { 
      const { SERIAL_NUMBER: serialNumber } = content[0]; 
      const devices =  R.mapObjIndexed(([d]) => d)(R.groupBy(R.path(['id']))(content.map(toDevice({ id, serialNumber }))));
      return { id, serialNumber, size: Object.keys(devices).length, devices }; 
    };
    const errors = csv.errors;
    const batches = R.mapObjIndexed(toBatch, byBatchId(csv.data));
    const total = Object.values(batches).map(b => b.size).reduce((ctr, size) => ctr + size, 0);
    setBatches({ batches, total, errors });
  }

  const uploadCSV = (file) => Papa.parse(file, {
    delimiter     : ",",
    fastMode      : true,
    header        : true,
    skipEmptyLines: true,
    trimHeaders   : true,
    complete      : loadBatches,
    transform     : (val, _col) => String(val).trim(),
  });

  
  const importBatch = () => Promise.all(Object.values(batches).map(props.registerBatch));
  
  const cancel = () => setBatches({
    batches : undefined,
    errors  : undefined,
    total   : undefined,
  });

  return batches ? ReviewBatchesUI({
    batches,
    errors,
    progress,
    total, 
    onConfirm           : importBatch,
    onCancel            : cancel,
    I18n                : props.I18n
  }) : RegisterBatchUI({ onUploadCSV: uploadCSV, I18n: props.I18n })
}

export default RegisterBatch;