
import { Accordion } from 'features/components/Accordion';

import { FiltersType,  severityList,  statusList } from './utils';
import { useStudyFiltersManagement } from './hooks/useStudyFiltersManagement';

import './styles.css';
import { Fragment } from 'react';
import { uiFilterFields } from './utils/uiFields';


const getRenderizedFilters = ({
    items = [], filters, currentUser,
    onFilterChange, fieldsProp
}) => items.map(filterType => {
    const Component = uiFilterFields[filterType];
    const props = fieldsProp[filterType];
    if (!Component) return <></>;
    return (
        <Fragment key={filterType}>
            <Component
                {...props}
                filters={filters}
                onFilterChange={onFilterChange}
                isAdmin={currentUser?.isAdmin()}
            />
        </Fragment>
    )
});

export const StudyFilters = ({
    currentUser, snapshots, children, filters: baseFilters = {}, batchIds,
    onFilterChange, maxNumberOfTests, showAdvanceFilters = false, reportType
}) => {
    const {
        listOfTests,
        uiFilters,
        filters,
        hcs,
        organisations,
        listOfClinicians
    } = useStudyFiltersManagement({ currentUser, snapshots, reportType, baseFilters, maxNumberOfTests });

    const removePatientIDHelp = filters[FiltersType.Reference] ? 'module.diagnosis.fe.view.findStudy.remove_patientID_help' : undefined;
    const applyOwnerHelp = currentUser.data.owners.length > 3 && !(filters[FiltersType.Organisation] || filters[FiltersType.HealthcareSite]) ? 'module.diagnosis.fe.view.findStudy.apply_owner_help' : undefined;

    const organisation = filters[FiltersType.Organisation]?.value;

    // Only Acurable users have access to the REPEATED Test status
    const statusItems = statusList.filter(s => currentUser.isFromAcurable() || s.value !== 'REPEATED');

    const fieldsProp = {
        [FiltersType.Clinician]: {
            items: listOfClinicians,
        },
        [FiltersType.Organisation]: {
            items: organisations,
        },
        [FiltersType.HealthcareSite]: {
            items: hcs?.filter(h => h?.owners?.includes(organisation) || !organisation),
        },
        [FiltersType.Status]: { items: statusItems, help: applyOwnerHelp, isDisabled: Boolean(applyOwnerHelp), disableClear: Boolean(filters[FiltersType.Severity]?.value) }, // disableClear => ui reequirement, explained in FindStudy
        [FiltersType.Severity]: { items: severityList, help: applyOwnerHelp || 'module.diagnosis.fe.view.findStudy.severity_help', isDisabled: Boolean(applyOwnerHelp) },
        [FiltersType.NumberOfTests]: { items: listOfTests },
        [FiltersType.DateConducted]: { help: removePatientIDHelp, disabled: Boolean(removePatientIDHelp) },
        [FiltersType.DateCreated]: { help: removePatientIDHelp, disabled: Boolean(removePatientIDHelp) },
        [FiltersType.LastUpdated]: { help: removePatientIDHelp, disabled: Boolean(removePatientIDHelp) },
        [FiltersType.BatchId]: { items: batchIds.map(id => ({ value: id, label: id })) },
    };
    const filtersSectionConfig = uiFilters?.config?.filters;
    const advanceSectionConfig = uiFilters?.config?.advance;
    const commonFieldProps = {
        filters,
        onFilterChange,
        fieldsProp,
        currentUser,
    };
    const hasAdvanceFilters = uiFilters?.advance?.length > 0;

    return (
        <div className="study-filters-root">
            <div className={`study-filters-row-${filtersSectionConfig?.columns}`}>
                {getRenderizedFilters({
                    items: uiFilters?.filters,
                    ...commonFieldProps
                })}
            </div>
            <div className="study-filters-children study-filters-margin">   {children}
            </div>
            {hasAdvanceFilters && (
                <Accordion isOpen={showAdvanceFilters}>
                    <div className={`study-filters-row-${advanceSectionConfig?.columns} study-filters-margin `}>
                        {getRenderizedFilters({
                            items: uiFilters.advance,
                            ...commonFieldProps
                        })}
                    </div>
                </Accordion>
            )}
        </div>
    );
};
