import * as Pages from 'modules/diagnosis';
import * as path from "path";

import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import FileDrop from 'ui/components/FileDrop';
import Loading from 'ui/components/Loading';
import { Redirect } from 'react-router-dom';
import { Test } from 'services/server/functions/model/diagnosis/model/Test';
import TestSelector from '../../components/TestSelector';
import {Text} from 'ui/components/Text';
import {ViewTest} from 'modules/diagnosis';
import history from 'history.js'
import useLocationParams from 'ui/hooks/useLocationParams';
import { useState } from 'react';
import { WithStudy } from '../../components';

const UploadTestFile = (props) => {
  const study = props.study?.data || {tests: {}};
  const test  = (props.retry ? study?.tests[props.testId]?.repeats?.[props.retry] : study?.tests[props.testId]) || {id: undefined};
  const [studyFile, setStudyFile] = useState();
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {   
    setUploading(true);
    require('services/firebase').Storage().upload(path.join(test?.filesPath, studyFile?.name), studyFile) // TODO: avoid using firebase directly in our UI code (imprt instead a custom facade/interface for storage like services/storage)
         .then(_ => setUploading(false))
         .then(_ => props.notify("success", Text.resolve("file-uploaded")))
         .then(_ => history.push(ViewTest.PATH, {study: study.id, test: props.testId, retry: props.retry || 0}))
         .catch(e => {setUploading(false); console.error(e); props.notify("error", Text.resolve("file-not-uploaded"), e.message, { timeout: 3000 });});
  }

  return test && Test.STATUS.compare(test.status, Test.STATUS.analysed) >= 0 ? <Redirect to={{pathname: Pages.ViewTest.PATH, state: {study: study.id, test: props.testId, retry: props.retry}}}/>
  : (
    <div id='UploadStudyFile' className='page'>
      <Back/>
      <div className='pageHeader'>
        <h1 className='title'><Text>UploadStudy.title</Text></h1>
        <TestSelector study={study.id} test={props.testId} retry={props.retry} tests={study.tests}/>
      </div>
      <div className="pageContent">
        <div className='pageSection'>
          <Loading text={Text.resolve("processing")} show={uploading} size="xlarge" >
            <DetailsBlock>
              <Details label="UploadStudy.study-file">
                <div className="grid-row">
                  <FileDrop id="studyDrop" onFileDrop={setStudyFile} />
                  { studyFile && <div className="centered"><span>File: {studyFile.name} (size: {(studyFile.size / (1024*1024)).toFixed(2)}MB)</span></div> }
                </div>
              </Details>
              { studyFile && <Action handleAction={handleUpload} className="primary button" label="upload"></Action> }
            </DetailsBlock>
          </Loading>
        </div>
      </div>
    </div>
  )
};

export default Object.assign((props) => {
  const [locState] = useLocationParams({test: undefined, retry: 0})
  const {study: studyId, test: testId, retry} = locState;

  const [notfound, setNotFound] = useState(undefined);

  return notfound ? <Redirect to={Pages.FindStudy.PATH}/> 
  : <WithStudy id={studyId} onNotFound={() => setNotFound(true)} showLoading={true}>
    <UploadTestFile {...props} testId={testId} retry={retry} />
  </WithStudy>
}, {displayName: 'UploadStudy'});