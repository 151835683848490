import * as Pages from 'modules/authentication';

import { isFromAcurable } from 'services/server/functions/model/administration/model';
import { Joi, list, min, phone, unique } from 'services/server/functions/validation/rules';
import { User } from 'services/server/functions/model/authentication/model';
import { WithFormValidation, WithValidation } from 'ui/components/WithValidation';

import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import HelpTooltip from 'ui/components/HelpTooltip'
import Modal from 'ui/acukit/ModalDialog';
import PhoneVerificationModal from './PhoneVerificationModal';
import { Roles } from 'services/server/functions/iam/roles';
import { Select } from 'ui/components/Select';
import {Text} from 'ui/components/Text';
import Toggle from 'react-toggle';
import { ValidatedPhone } from 'ui/components/PhoneInput';
import WithSnapshot from 'ui/components/WithSnapshot';
import history from 'history.js';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import { useState } from 'react';
import OwnersSelect from 'features/components/OwnersSelector';


const ValidatedInput = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><input {...props} value={props.value || ""}/></WithValidation>;
const ValidatedSelect = ({onValidate, showError, dependsOn, field, value=[], ...props}) => <WithValidation dependsOn={dependsOn} field={field || props.name} onValidate={onValidate} showError={showError} value={value} {...props}><Select {...props} value={value} /></WithValidation>;
const ValidatedElement = (WrappedComponent) => ({onValidate, showError, dependsOn, field, ...props}) => <WithValidation dependsOn={dependsOn} field={field || props.name} onValidate={onValidate} showError={showError} value={props.value} {...props}><WrappedComponent {...props} /></WithValidation>;

const EmptyPhone2FAModal = ({show, close, I18n}) => {
  return <Modal show={show} heading={I18n("settings.2-factor-empty-phone.modal.title")} actions={[{ text: 'Accept', id: 'modal-help', appearance: 'default', onClick: close }]} onClose={close} >
    {I18n("settings.2-factor-empty-phone.modal.content")}
  </Modal>
}

const PhoneChanged2FAModal = ({show, close, I18n}) => {
  return <Modal show={show} heading={I18n("settings.2-factor-phone-changed.modal.title")} actions={[{ text: 'Accept', id: 'modal-help', appearance: 'default', onClick: close }]} onClose={close} >
    {I18n("settings.2-factor-phone-changed.modal.content")}
  </Modal>
}

const OwnersSelector = ValidatedElement(OwnersSelect);

const phoneSchema = Joi.object().keys({
  phone,
});

const EditUserUI = ({ user, defaultOwners, onCancelHandler, onSave, onValueChange, roleOptions, update, formSaved, onValidate, privateEditable, isFromAcurable, I18n, notify}) => {
  const [edited, setEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false);
  const [showEmptyPhone2FAModal, setShowEmptyPhone2FAModal] = useState(false);
  const [showPhoneChanged2FAModal, setShowPhoneChanged2FAModal] = useState(false);
  const actions = [
    { text: 'leave',  onClick: onCancelHandler, appearance: 'primary' },
    { text: 'stay', onClick: () => setShowModal(false) },
  ];

  const onChange = ({ target }) => {
    setEdited(true);
    return onValueChange({ [target.name]: target.type === 'select-multiple' ? (target.selectedOptions || []).map(o => o.value) : target.value });
  };
  const onToggleChange = e => onChange({target: {name: e.target.name, value: e.target.checked}});

  const onTwoFactorEnabled = () => onValueChange({ twoFactorEnabled: true, phoneVerified: true });

  const onTelephoneChange = (e) => {
    if (user.twoFactorEnabled) setShowPhoneChanged2FAModal(true);
    return onValueChange({ twoFactorEnabled: false, phoneVerified: false, phone: e.target.value });
  }
  
  const ValidationSchema = update ? User.commands.UPDATE_USER.schema : User.commands.REGISTER_USER.schema;
  
  require('./style.css')
  return (
  <div className="page" id="EditUser">
    <Back/>
    <div className="pageHeader">
      <h1 className="title">{update ? I18n('EditUser.title') : I18n('global.user.create')}</h1>
    </div>
    <div className="pageContent">
      <div className="pageSection">
        <div className="title">{I18n('details')}</div>
        <DetailsBlock>
          <div className="grid-col">
            <Details id="userName" label="first-name" mandatory><ValidatedInput name="name" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={user.name} /></Details>
            <Details id="userLastName" label="last-name" mandatory><ValidatedInput name="lastName" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={user.lastName} /></Details>
          </div>
          <div className="grid-col">
            <Details id="userMail" label="email-address" help={!privateEditable && I18n('edit-email')} mandatory={privateEditable}>{privateEditable ? <ValidatedInput name="mail" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={user.mail} /> : user.mail}</Details>
            <Details id="userJob" label="job-title" mandatory><ValidatedInput name="job" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={user.job} /></Details>
          </div>
          <div className="grid-col">
            <Details id="userPhone" label="mobile-phone" mandatory={user.twoFactorEnabled}><ValidatedPhone country='' schema={phoneSchema} mandatory={user.twoFactorEnabled} showError={formSaved} onValidate={onValidate} name="phone" onChange={onTelephoneChange} value={user.phone} /></Details>
            <div/>
          </div>
        </DetailsBlock>
      </div>
      {(isFromAcurable || update) && <div className="pageSection">
        <DetailsBlock>
          {update && <div className="grid-col settings">
            <li>{I18n('settings.2-factor')}<HelpTooltip content={I18n("settings.2-factor-help")} id={'help2FactorAuth'}/></li>
            <div className="toggleSelect settingValue" title={user.phone === undefined ? I18n('settings.2fa-provide-phone-tooltip') : undefined}>
              <Toggle disabled={user.phone === undefined} name="twoFactorEnabled" onChange={(e) => user.twoFactorEnabled ? onToggleChange(e) : user.phone ? setShowPhoneVerificationModal(true) : setShowEmptyPhone2FAModal(true) } value="" checked={user.twoFactorEnabled} />
            </div>
          </div>}
          {isFromAcurable && <div className="grid-col settings">
            <li>{I18n('settings.notifications')}<HelpTooltip content={I18n("settings.notifications-help")} id={'helpNotifications'}/></li>
            <Toggle className="toggleSelect settingValue" name="notifications" onChange={onToggleChange} value="" checked={user.notifications} />
          </div>}
        </DetailsBlock>
      </div>}
      <div className="pageSection">
        <DetailsBlock inline>
          <OwnersSelector label={`${I18n('orgs')} / ${I18n('hcss')}`} defaults={defaultOwners} schema={unique(min(list(Joi.string().uri()), 1))} showError={formSaved} onValidate={onValidate} value={user.owners || []} onChange={onChange}/>
        </DetailsBlock>
      </div>
      <div className="pageSection">
        <DetailsBlock>
          <Details id="userRole" label="roles" mandatory>
            <ValidatedSelect isMulti name="roles" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} value={user.roles} options={roleOptions.map(r => { return {isDisabled: r.isDisabled, value: r.value, label: Text.resolve(r.label, {}, { schema: "authentication.Role" })}})} placeholder={I18n("choose-roles")} onChange={onChange} />
          </Details>
        </DetailsBlock>
        <span><sup className="mandatory">*</sup><span className="small">{I18n('compulsory-field')}</span></span>
      </div>
    </div>
    <div className="pageFooter">
      <div className='actions'>
        <Action className='button' id="back" handleAction={() => edited ? setShowModal(true) : onCancelHandler()} label='cancel' />
        <Action className='button primary' id="report" handleAction={() => onSave()} label='global.button.save' />
      </div >
    </div> 
    <Modal show={showModal} onClose={() => setShowModal(false)} heading="unsaved-changes" actions={actions}>
      <span>{I18n('sure-to-leave')}</span>
    </Modal>
    <PhoneVerificationModal
      show={showPhoneVerificationModal} notify={notify} phone={user.phone}
      onSuccess={() => { setShowPhoneVerificationModal(false); onTwoFactorEnabled(); notify('success', I18n('settings.2fa-verify-success')); }}
      onError={(e) => { setShowPhoneVerificationModal(false); notify('error', Text.resolve(e.reason), Text.resolve(e.resolution)); }}
      close={() => setShowPhoneVerificationModal(false)}
    />
    <div id='2-factor-recaptcha-container'></div>
    <EmptyPhone2FAModal show={showEmptyPhone2FAModal} close={() => setShowEmptyPhone2FAModal(false)} I18n={I18n}/>
    <PhoneChanged2FAModal show={showPhoneChanged2FAModal} close={() => setShowPhoneChanged2FAModal(false)} I18n={I18n}/>
  </div>
  );
};

const EditUserPresenter = (props) => {
  const [ currentUser ] = useCurrentUser({ skipContext: true }); // We want to show all roles the User has access too independent of the Product

  const { usr, id, defaultOwners, setLocationState, isUpdate } = props;
  
  const [saved, setSaved]   = useState(false);
  const [errors, setErrors] = useState({});

  const origUser            = props.snapshot?.data;
  
  const origNotifications   = 'emailEnabled' in (origUser?.preferences?.notifications || {}) ? origUser.preferences.notifications.emailEnabled : true;
  const origAuthSettins     = origUser?.preferences?.authentication || {twoFactorEnabled: Boolean(origUser?.phone)}; // if no authentication preferences exists in this user > using old way of activationg 2fa (depends on whether phone was flled)
  const user                = usr || {notifications: origNotifications, twoFactorEnabled: Boolean(origAuthSettins.twoFactorEnabled), ...origUser};

  const hasPermission       = currentUser.hasPermission;
  const hasAllRoles         = currentUser.hasAllPermissions; // TODO it needs to expand custom roles into system roles and filter out those custom roles which have at least one role that current user does not have.

  // Block those roles current user does not have, then the use to be edited cannot change that permission
  const blockedRoles        = (origUser?.roles || []).filter(r => !hasPermission(r) || r === Roles.superAdmin.id);
  const roleOptions         = Roles.customRoles()
                                   .concat(Roles.systemRoles()) // Note system roles should not be shown keeping only to be able to show Administrator and Acurable Admin roles in the UI, or old users assigned to a sytem role from previous web app version
                                   .filter(r => (origUser?.roles || []).includes(r.id) || (Roles.isCustomRole(r.id) && (hasPermission(r.id) || hasAllRoles(r.includes))) )
                                   .map(({ id, label }) => ({ label, value: id, isDisabled: blockedRoles.includes(id) }));

  const selectedRoles       = user.roles?.concat(blockedRoles.filter(r => /* avoid repeating elements */!user.roles.includes(r)));  
  if (selectedRoles) user.roles           = selectedRoles.filter(sr => roleOptions.some(r => r.value === sr));
  
  const hasErrors = () => errors && Object.values(errors).filter(Boolean).length > 0;
  
  const handleValidate = (field, error) => {
    return setErrors(prevErrors => ({ ...prevErrors, [field]: error }));
  };
  const handleRegister = (data) => props.registerUser(data).then(_ => history.replace(Pages.ViewUser.PATH, { user: data.id }));
  const filter         = (data) => {
    if (!isUpdate || currentUser?.data.id === id) return data;
    // TODO: we should only have here those fields that have really changed
    if ('mail'  in data) delete data.mail;
    return data;
  }
  const withSettings = ({notifications, twoFactorEnabled, ...user}) => ({...user, preferences: {notifications: {emailEnabled: notifications}, authentication: {twoFactorEnabled}}});

  const handleUpdate   = (data) => props.updateUser(filter(data)).then(_ => history.replace(Pages.ViewUser.PATH, { user: data.id }));
  const handleSave     = () => { 
    setSaved(true); 
    if (hasErrors()) return;

    const savedUser = withSettings(user);
    return isUpdate ? handleUpdate(savedUser) : handleRegister(User.new(savedUser).data); 
  }

  const onValueChange = (change) => {
    setLocationState({ usr: { ...user, ...change } });
  }

  //const ValidationSchema = isUpdate ? User.commands.UPDATE_USER.schema : User.commands.REGISTER_USER.schema;
  return <WithFormValidation /*schema={ValidationSchema} onValidate={onValidate} showError={showError} form={user}*/>
    <EditUserUI
      user={user}
      defaultOwners={defaultOwners}
      roleOptions={roleOptions}
      update={isUpdate}
      onCancelHandler={_ => isUpdate ? history.goBack() : history.replace(Pages.FindUser.PATH)}
      onValidate={handleValidate}
      onSave={handleSave}
      onValueChange={onValueChange}
      formSaved={Boolean(saved)}
      privateEditable={!isUpdate || currentUser?.data.id === id}
      isFromAcurable={isFromAcurable(currentUser)}
      I18n={props.I18n}
      notify={props.notify}
    />
  </WithFormValidation>;
};

const EditUser = props => {
  const [{ organisation, healthcaresite, user: id, usr }, setLocationState] = useLocationParams();
  const isUpdate = id !== undefined;

  const defaultOrgs = (organisation && [organisation]) || [];
  const defaultSites = (healthcaresite && [healthcaresite]) || [];
  const defaultOwners = defaultOrgs.concat(defaultSites);

  return WithSnapshot(User, id, {autostart: isUpdate})(EditUserPresenter)({...props, usr, defaultOwners, isUpdate, setLocationState});
}

export default EditUser;