export const INTERNAL_SYSTEM_ERROR = "INTERNAL";
export const PERMISSION_ERROR = "PERMISSION-DENIED";
export const FORBIDDEN_ERROR = "FORBIDDEN";
export const NOT_FOUND_ERROR = "NOT-FOUND";
export const NO_ERROR = "SUCCESS";
export const INVALID_DATA_ERROR = "INVALID-DATA";
export const DUPLICATE_ERROR = "DUPLICATED";
export const DISABLED_ERROR = "DISABLED";
export const INVALID_CREDENTIALS_ERROR = "INVALID-CREDENTIALS";
export const INVALID_CODE_ERROR = "INVALID-CODE";
export const NOT_VERIFIED_ACCOUNT_ERROR = "ACCOUNT-NOT-VERIFIED";
export const EXPIRED_ERROR = "EXPIRED";
export const INVALID_PHONE_ERROR = "INVALID-PHONE";

// Retriable errors:
// TODO: maybe define different retry delay depending on error code?
export const NETWORK_ERROR = "NETWORK";
export const TIMEOUT_ERROR = "TIMEOUT";
export const UNAVAILABLE_SERVICE_ERROR = "UNAVAILABLE";
export const QUOTA_ERROR = "QUOTA";