import { Study } from '../model';
import { Test } from '../model/Test';
import i18n from '../../../i18n';
import { getDate, getTime, getDuration } from '../../../../../../features/helpers/dateHelper';

const getv = (params, metric) => params?.[metric]?.value === undefined ? params?.[metric] : params[metric].value;
const getu = (params, metric) => params?.[metric]?.units === undefined ? params?.units : params[metric].units; 
  
const computeTestTemplateVariables = (testNumber, test) => {
    if (test.status === Test.STATUS.cancelled) {
        if (test.recording) delete test.recording;
        if (test.report) delete test.report;
    }
    const { recording = {}, report = {} } = test;
    const fullConductedDate = Study.conductedDate({ [testNumber]: test });
    const conductedDate = fullConductedDate ? getDate({
        date: fullConductedDate }) : "-";
    const sleepTime = recording.diagnosableTime ? getTime(recording.diagnosableTime * 1000) : "-";
    const indexes = report?.diagnosis;
    const snoreParams = recording?.signals?.Snore?.params;
    let snore;

    if (snoreParams) {
        const snoreSleepTime = getDuration(getv(snoreParams, 'sleeptime'), getu(snoreParams, 'sleeptime'));
        const sleeptimePct = getv(snoreParams, 'sleeptime_pct');
        const sleeptimePctUnit = getu(snoreParams, 'sleeptime_pct');
   
        snore = {
            ...snoreParams,
            sleepTime: `${snoreSleepTime ? (snoreSleepTime.hours() > 0 ? `${snoreSleepTime.hours()}h ${snoreSleepTime.minutes()}m` : `${snoreSleepTime.minutes()} min`) : "-"} `,
            sleeptimePct: `${!sleeptimePct ? "-" : sleeptimePct} ${i18n.resolve(sleeptimePctUnit)}`
        };
    }

    return {
        ...test,
        night: parseInt(testNumber) + 1,
        repeat: test.retry,
        conductedDate,
        sleepTime,
        indexes,
        snore
    }
}

export const applyTestFormat = (data) => data && Object.entries(data).reduce((tests, [testNumber, test]) => {
    return [
        ...tests,
        {
            ...tests[testNumber],
            ...computeTestTemplateVariables(testNumber, test)
        },
        ...Object.values(test.repeats || {}).map(repeat => computeTestTemplateVariables(testNumber, repeat))
    ];
}, []);