import { CustomModal } from 'ui/acukit/ModalDialog';
import { WithValidation } from 'ui/components/WithValidation';
import { mail, required } from 'services/server/functions/validation/rules';
import { recaptchaVerifier } from 'services/security';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notify } from 'services/redux/actions/Notifications';
import { messageFrom } from 'modules/executor';
import {Text} from 'ui/components/Text';
import { NOT_FOUND_ERROR } from 'services/server/functions/errors/codes';
import { getErrorData } from 'services/server/functions/errors';

const ValidatedInput = ({onValidate, showError, ...props}) => <WithValidation field={props.name} onValidate={onValidate} showError={showError} {...props}><input {...props} value={props.value || ''}/></WithValidation>;
const ResetPasswordDialog = (props) => {
  
  const { email: defaultMail, firstChange, onError: triggerError, onSuccess: triggerSuccess, btn, onSubmit, onSent, onVerified, onError: reportError, isReadOnly, asLink, label, heading, message1, message2, isOpen: outOpen, onToggle: outOnToggle, ...otherProps } = props;
  const [email, setEmail]     = useState(defaultMail);
  const [isValid, setIsValid] = useState(false);
  const [edited, setEdited]   = useState(false);
  const [isOpen, setIsOpen]   = useState(outOpen);

  useEffect(() => setEmail(defaultMail), [defaultMail]);
  useEffect(() => setIsOpen(outOpen), [outOpen]);
  
  const onValidate      = (_, error) => setIsValid(error === null);
  const onChange        = (event) => { setEdited(true); setEmail(event.target.value); };
  const onSuccess       = mail => {
    if (onSent) onSent(mail);
    props.notify('success', isReadOnly ? Text.resolve("ResetPasswordDialog.registered-email-sent") : Text.resolve("ResetPasswordDialog.email-sent"));
    if (triggerSuccess) triggerSuccess(mail);
  };
  const onError         = (error) => {
    if (reportError) reportError(error);
    if (error && !isReadOnly && getErrorData(error, 'code') === NOT_FOUND_ERROR) {
      props.notify('success', Text.resolve("ResetPasswordDialog.email-sent"));
    } else {
      props.notify('error', Text.resolve("ResetPasswordDialog.error.email-not-sent"), messageFrom(error));
    }
    if (triggerError) triggerError(error);
  };
  const onConfirm       = () => isValid ? Promise.resolve(onSubmit && onSubmit()).then(_ => (isReadOnly ? Promise.resolve() : recaptchaVerifier('pass-recaptcha-container').doVerify()))
                                            .then(_ => onVerified && onVerified(true))
                                            .then(_ => import('services/iam'))
                                            .then(iam => iam.sendChangePasswordCode(email, {firstChange}))
                                            .then(onSuccess)
                                            .catch(onError)
                                        : Promise.resolve();
    
  return (<div>
      <CustomModal
        btn={Boolean(btn)}
        secondaryAction={onConfirm}
        heading={heading}
        label={label}
        asLink={Boolean(asLink)}
        id={props.id}
        onToggle={open => {setIsOpen(open); outOnToggle && outOnToggle(open);}}
        isOpen={isOpen}
        {...otherProps}
      >
        <div className="grid-row">
          <span>{message1}</span>
          <ValidatedInput
            name="email"
            placeholder={Text.resolve("email")}
            showError={edited || (email !== undefined && email.length > 0)}
            schema={required(mail)}
            onValidate={onValidate}
            onKeyDown={(e) => e.keyCode === 13 && (setIsOpen(false) || onConfirm())}
            readOnly={isReadOnly}
            onChange={onChange}
            value={email}
          />
          <span>{message2}</span>
        </div>
      </CustomModal>
      <div id='pass-recaptcha-container'/>
  </div>);
}

export default connect(undefined, { notify })(ResetPasswordDialog);