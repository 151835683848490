import * as Pages from 'modules/diagnosis';

import history from 'history.js';

import { Study } from "services/server/functions/model/diagnosis/model";

import StudyStatusLegend from "modules/diagnosis/ui/components/StudyStatusLegend";

import TestStatusLegend from "modules/diagnosis/ui/components/TestStatusLegend";

import i18n from "services/server/functions/i18n";
import { studyReportDataFormatter, activityReportDataFormatter, activitySummaryDataFormatter } from "services/server/functions/model/diagnosis/connector/study-report.connector";
import { getActivityLogReportTableRow, getStudyReportTableRow, getActivitySummaryReportTableRow } from "./tableReportRows";
import useCurrentUser from 'ui/hooks/useCurrentUser';
import MenuButton from 'ui/components/MenuButton';
import Action from 'ui/components/Action';
import BatchStudyCancel from 'features/diagnosis/Study/FindStudyActions/BatchStudyCancel';

export const Reports = Object.freeze({
    StudyReport: {
        key: "activity",
        query: Study.queries.GET_ACTIVITY_REPORT,
        label: "sleep-studies",
    },
    ActivityLogReport: {
        key: "activityLog",
        query: Study.queries.GET_ACTIVITY_LOG,
        label: "modules.fe.report.activity_log_report.label",
    },
    ActivitySummary: {
        key: "activitySummary",
        query: Study.queries.GET_ACTIVITY_SUMMARY,
        label: "modules.fe.report.activity_summary.label",
    }
})

export const ReportConfigTypes = Object.freeze(Object.entries(Reports).reduce((types, [type, conf]) => {
    types[type] = conf.key;
    return types;
}, {}));

const TABLE_DEFAULT_PROPS = {
    defaultSortCol: "lastUpdate",
    defaultSortDir: "desc",
    emptyText: "Table.no-data",
};

const isCreatedStudyAndHasBeenDeleted = (row, selectedActionResolution) => row.status === Study.STATUS.created && row.id in selectedActionResolution;

const getStudyReportConfig = ({ sortFunc, data, ...overrides }) => {
    const [currentUser] = useCurrentUser();
    const hasViewStudyAccess = currentUser.hasAccess(Pages.ViewStudy);

    const result = {
        id: ReportConfigTypes.StudyReport,
        keyField: "key",
        columns: {
            patientID: { content: "patient-id", isSortable: false },
            clinicianName: { content: "global.clinician.label", canBeHidden: true },
            lastUpdate: { content: "study-last-update", isSortable: true, type: "date" },
            statusView: { content: "tests", help: i18n.resolve("FindStudy.tests_header_tooltip"), isSortable: false, sortFunc }
        },
        ...TABLE_DEFAULT_PROPS,
        ...overrides,
        data: getStudyReportTableRow(data, overrides.selectedActionResolution),
        withViewAction: hasViewStudyAccess ? {
            text: overrides.selectedIds.length ? (<MenuButton title="Actions" primary >
                <BatchStudyCancel studiesIds={overrides.selectedIds} studies={data.filter(d => overrides.selectedIds.includes(d.id))} cancelStudyBatch={overrides.cancelStudyBatch} setSelectedActionResolution={overrides.setSelectedActionResolution} />
                <Action handleAction={() => {
                    overrides.setSelectedIds([]);
                    overrides.setSelectedActionResolution({});
                    overrides.reloadData();
                }} id="clearAction" className="button" label='clear' />
            </MenuButton>) : '',
        } : false,
    };
    
    if (hasViewStudyAccess) {
        result.onRowClick = (row) => { overrides.setHighlightedRow(row.id); if (!isCreatedStudyAndHasBeenDeleted(row, overrides.selectedActionResolution)) history.push(Pages.ViewStudy.PATH, { study: row.id }); };
        result.onRowHover = () => { if (overrides.highlightedRow) overrides.setHighlightedRow(undefined); };
        result.rowClasses = (row) => { if (overrides.highlightedRow === row.id) return "highlight"; }
    }
    
    if (currentUser.isFromAcurable()) {
        result.selectRow = {
            mode: 'checkbox',
            selected: overrides.selectedIds.map(id => `study-report-row-${id}`),
            onSelect: (row, isSelected) => {
                overrides.setSelectedId(row.id, isSelected);
            },
            onSelectAll: (isSelected, rows) => {
                overrides.setSelectedIds(isSelected ? rows.map(r => r.id) : []);
            },
        };
        const prevRowClasses = result.rowClasses;
        result.rowClasses = (row) => prevRowClasses?.(row) || overrides.selectedActionResolution[row.id]?.status;
    }


    return result;
};

const getActivityLogReportConfig = ({ data = [], ...overrides }) => {
    const [currentUser] = useCurrentUser();
    const hasViewStudyAccess = currentUser.hasAccess(Pages.ViewStudy);

    const result = {
        id: ReportConfigTypes.ActivityLogReport,
        keyField: "key",
        columns: {
            studyRef: { content: "study-reference", isSortable: true },
            studyCreated: { content: "global.created", isSortable: true, type: "date" },
            completed: { content: "conducted-date", isSortable: true, type: "date" },
            numberOfTests: { content: "number-of-tests", isSortable: true },
            testStatus: { content: "test-status", help: <TestStatusLegend />, isSortable: true },
            studyStatus: { content: "study-status", help: <StudyStatusLegend />, isSortable: true }
        },
        ...TABLE_DEFAULT_PROPS,
        ...overrides,
        data: getActivityLogReportTableRow(data),
        withViewAction: hasViewStudyAccess,
    }

    if (hasViewStudyAccess) result.onRowClick = (row) => history.push(Pages.ViewStudy.PATH, { study: row.studyId });

    return result;
};

const getActivitySummaryReport = ({ data = [], filters, ...overrides }) => {
    const [currentUser] = useCurrentUser();
    const hasActivityLogReportAccess = currentUser.hasAccess(Reports.ActivityLogReport.query);

    const result = {
        id: ReportConfigTypes.ActivitySummary,
        keyField: "key",
        columns: {
            hcsName: { content: "hcs", isSortable: true },
            numberOfNights: { content: "number-of-nights", isSortable: true },
            studiesCreated: { content: "studies-created", isSortable: true },
            studiesCompleted: { content: "studies-completed", isSortable: true },
            testsCompleted: { content: "Tests completed", help: <TestStatusLegend />, isSortable: true }
        },
        ...TABLE_DEFAULT_PROPS,
        ...overrides,
        data: getActivitySummaryReportTableRow(data),
        withViewAction: hasActivityLogReportAccess,
    };

    if (hasActivityLogReportAccess) {
        result.onRowClick = (row) => history.push(Pages.FindStudy.PATH, { reportType: ReportConfigTypes.ActivityLogReport, healthcaresite: row.hcsId, requestedTests: row.numberOfNights, filters });
    }

    return result;
};

export const getTableReportConfig = (type) => {
    const reportConfigs = {
        [ReportConfigTypes.ActivityLogReport]: getActivityLogReportConfig,
        [ReportConfigTypes.ActivitySummary]  : getActivitySummaryReport,
        [ReportConfigTypes.StudyReport]      : getStudyReportConfig
    };
    return reportConfigs[type] ?? reportConfigs[ReportConfigTypes.StudyReport];
};

export const getTableReportData = (type) => {
    const reportConfigs = {
        [ReportConfigTypes.StudyReport]      : (expandedStudies) => studyReportDataFormatter(expandedStudies),
        [ReportConfigTypes.ActivityLogReport]: (expandedStudies) => activityReportDataFormatter(expandedStudies),
        [ReportConfigTypes.ActivitySummary]  : (expandedStudies) => activitySummaryDataFormatter(activityReportDataFormatter(expandedStudies)),
    };
    return reportConfigs[type] ?? reportConfigs[ReportConfigTypes.StudyReport];
};