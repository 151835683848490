import './style.css';
import * as Admin from 'modules/administration';
import { Batch, Device } from 'services/server/functions/model/devices/model';
import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import { dataFrom } from 'services/server/functions/executor/urn';
import * as R from 'ramda';
import Action from 'ui/components/Action';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Back from 'ui/components/Back';
import {StatusLegendTooltip} from '../components/DeviceStatusLegend';
import Modal from 'ui/acukit/ModalDialog';
import { Fragment, useCallback, useState } from 'react';
import {Text} from 'ui/components/Text';
import useSnapshot from 'ui/hooks/useSnapshot';
import useLocationParams from 'ui/hooks/useLocationParams';
import WithSnapshot from 'ui/components/WithSnapshot';

const DEVICE_STATUS = Object.values(Device.STATUS).reduce((allStatus, status) => ({...allStatus, [status]: 0}), {});

const assign = ({organisations, organisation, healthcaresites, healthcaresite, onOrganisationChangedHandler, onHealthcaresiteChangedHandler, I18n}) => (
  <Fragment>
    <Details label='global.organisation.label'>
      <select id='organisation' className='field' value={organisation || ""} onChange={e => onOrganisationChangedHandler(e.target.value)}>
        <option value="">{I18n('select')}</option>
        { organisations.map(({id, name}, i) => <option key={i} value={id}>{name}</option>) }
      </select>
    </Details>
    <Details label='hcs'>
      <select id='healthcaresite' className='field' value={healthcaresite || ""} onChange={e => onHealthcaresiteChangedHandler(e.target.value)}>
        <option value="">{I18n('select')}</option>
        { healthcaresites && healthcaresites.map(({id, name}, i) => <option key={i} value={id}>{name}</option>) }
      </select>
    </Details>
  </Fragment>
);

const change = ({nextStatus, transitions, organisations, organisation, healthcaresites, healthcaresite, onOrganisationChangedHandler, onHealthcaresiteChangedHandler, onNextStatusChangedHandler, I18n}) => (
  <Fragment>
    <Details label={I18n('new-status')} help={<StatusLegendTooltip isChange/>}>
      <select id='nextStatus' className='field' value={nextStatus ? nextStatus : ""} onChange={(e) => onNextStatusChangedHandler(e.target.value || undefined)}>
        <option key="" value="">{I18n("select")}</option>
        { transitions.map((s, i) => <option key={i} value={s}>{Text.resolve(s, {}, { schema: "devices.Device" })}</option>) }
      </select>
    </Details>
    {nextStatus === Device.STATUS.ready && assign({organisations, organisation, healthcaresites, healthcaresite, onOrganisationChangedHandler, onHealthcaresiteChangedHandler, I18n}) }
  </Fragment> 
);

const ChangeStatusView = (props) => {
  const [show, setShow] = useState(false), showModal = () => setShow(true), hideModal = () => setShow(false);
  const {quantity, batchId, organisation, organisations, healthcaresite, healthcaresites, deviceId, devices, status, currentStatus, nextStatus, transitions, onNextStatusChangedHandler, onConfirmHandler, onCancelHandler, onQuantityChangedHandler, onOrganisationChangedHandler, onHealthcaresiteChangedHandler, I18n} = props;
  const actions = [
    { text: 'confirm', appearance: 'primary', onClick: onConfirmHandler },
    { text: 'cancel',  onClick: hideModal },
  ];

  return (
    <div id='ChangeStatus' className='page'>
      <Back/>
      <div className='pageHeader'>
        <h1 className='title'>{I18n('title')}</h1>
      </div>
      <div className='pageContent'>
        <div className='pageSection'>
          <div className='title'>{I18n('details')}</div>
          <DetailsBlock inline>
            { !batchId && !deviceId && !healthcaresite && organisation && <Details label='organisation'><Admin.ViewOrganisation.AsLinkIcon organisation={organisation} className="button" /></Details> }
            { !batchId && !deviceId && healthcaresite && <Details label='hcs'><Admin.ViewHCS.AsLinkIcon healthcaresite={healthcaresite} className="button" /></Details> }
            { batchId && <Details label='batch-id' value={dataFrom(batchId).id} /> }
            { deviceId ? <Details label='device-id' value={dataFrom(deviceId).id} /> : <Details label={I18n('number-of-devices')} value={devices.length} /> }
            { currentStatus ? <Details label='status'><div className={`status ${currentStatus.toLowerCase()}`} >{Text.resolve(currentStatus, {}, { schema: "devices.Device" })}</div></Details> :
              <Details label={I18n('devices-status')}>
                <div className='items'>{ Object.entries(status).map((s, i) => <div className={`status ${s[0].toLowerCase()}`} key={i}>{s[1]}</div>) }</div>
              </Details>
            }
          </DetailsBlock>
        </div>
        <div className='pageSection'>
          <div className='title'>{I18n('input')}</div>
          <DetailsBlock inline>
            { change({nextStatus, transitions, organisations, organisation, healthcaresites, healthcaresite, onOrganisationChangedHandler, onHealthcaresiteChangedHandler, onNextStatusChangedHandler, I18n}) }
            { (!nextStatus || deviceId || devices.length < 2) ? <Fragment/> : <Details label='quantity'><input name='quantity' value={quantity} onChange={e => onQuantityChangedHandler(e.target.value)}/></Details> }
          </DetailsBlock>
        </div>
      </div>
      <div className='pageFooter'>
        <div className='actions'>
          <Action className='button primary' disabled={nextStatus === undefined} handleAction={showModal} label={I18n('continue')} />
          <Action className='button' handleAction={_ => onCancelHandler()} label='cancel' />
        </div>
      </div>
      <Modal show={show} onClose={hideModal} heading={I18n("title")} actions={actions}>
        <div className='pageSection'>
        <div className='title'>{I18n('please-confirm')}</div>
          <DetailsBlock>
            { (organisation || healthcaresite) && <div className="grid-col">
                { organisation && <Details label='organisation'><Admin.ViewOrganisation.AsIcon organisation={organisation} /></Details> }
                { healthcaresite && <Details label='hcs'><Admin.ViewHCS.AsIcon healthcaresite={healthcaresite} /></Details> }
              </div> 
            }
            <div className="grid-col">
              { batchId && <Details label='batch-id' value={dataFrom(batchId).id} /> }
              { deviceId ? <Details label='device-id' value={dataFrom(deviceId).id} /> : <Details label='quantity'>{quantity || devices.length}</Details> }
              { currentStatus && <Details label='status'><div className={`status ${currentStatus.toLowerCase()}`} >{Text.resolve(currentStatus, {}, { schema: "devices.Device" })}</div></Details> }
            </div>
            <Details label={I18n('new-status')}><div className={`status ${nextStatus && nextStatus.toLowerCase()}`} >{nextStatus ? Text.resolve(nextStatus, {}, { schema: "devices.Device" }) : nextStatus}</div></Details>
        </DetailsBlock>
        { status[Device.STATUS.retired] ? <div className='warning'>{status[Device.STATUS.retired]} {I18n('retired-devices')}</div> : <Fragment/> }
        </div>
      </Modal>
    </div>
  );
}

const ChangeStatusPresenter = (props) => {
  const [locst]    = useLocationParams();
  const [orgSnaps] = useSnapshot(Organisation);
  const [hcsSnaps] = useSnapshot(HealthcareSite);
  const [batchSnap]= useSnapshot(Batch, locst.batch);

  const withBatch          = ({ batchId: expected }) => ({ batchId: actual }) => expected === undefined || dataFrom(expected).id === dataFrom(actual).id;
  const withDevice         = ({ deviceId: expected }) => ( { id: actual }) => expected === undefined || dataFrom(expected).id === dataFrom(actual).id;
  const withStatus         = ({ currentStatus }) => ({ status }) => currentStatus === undefined || currentStatus === status;
  const withOwner          = (expected) => ({ owners }) => expected === undefined || owners.includes(expected) || HealthcareSite.ownersFrom({owners}).map(id => hcsSnaps[id]?.owners[0]).includes(expected);
  const withOrg            = ({organisation}) => withOwner(organisation.id);
  const withHCS            = ({healthcaresite}) => withOwner(healthcaresite.id);
  const canTransitionTo    = ({ nextStatus }) => ({ status }) => nextStatus === undefined || Device.STATUS_TRANSITIONS[nextStatus][status];

  const matching = (filters) => {
    const predicates = [withDevice, withBatch, withStatus, withOrg, withHCS, canTransitionTo].map(f => f(filters));
    return (device) => predicates.every(p => p(device));
  };

  const device       = props.snapshots[locst.device]?.data   || { id: undefined, status: undefined };
  const batch        = batchSnap?.data || { id: undefined, size: undefined   };
  const hcs          = hcsSnaps[locst.healthcaresite]?.data || { id: undefined, name: undefined, owners: [] };
  const org          = orgSnaps[hcs.owners[0] || locst.organisation]?.data || { id: undefined, name: undefined, owners: [] };
  const currentStatus= device.status || locst.status;
  const [nextStatus, setNextStatus] = useState(currentStatus === Device.STATUS.created ? Device.STATUS.inStock : currentStatus === Device.STATUS.inStock ? Device.STATUS.ready : undefined);

  const filters     = { deviceId: device.id, batchId: batch.id, organisation: org, healthcaresite: hcs, currentStatus, nextStatus };
  const devices     = Object.values(props.snapshots).map(s => s.data).filter(matching(filters)).map(({ id, status }) => ({ id, status }));

  const status      = { ...DEVICE_STATUS, ...R.countBy(R.prop('status'))(devices) };
  const transitions = Object.keys(status).filter(s => devices.filter(canTransitionTo({nextStatus: s})).length && status[s] !== batch.size);

  const [ownership, setOwnership] = useState({organisation: undefined, healthcaresite: undefined});
  const organisations   = nextStatus === Device.STATUS.ready ? Object.values(orgSnaps).map(s => s.data).filter(o => org.id === undefined || org.id === o.data.id).map(({id, name}) => ({id, name})) : undefined;
  const healthcaresites = nextStatus === Device.STATUS.ready && ownership.organisation ? Object.values(hcsSnaps).map(s => s.data).filter(withOwner(ownership.organisation)).map(({id, name}) => ({id, name})) : undefined;

  const [quantity, setQuantity] = useState();
  const onQuantityChanged = (value) => {
    const newQuantity = (value === '') ? 0 : parseInt(value);
    if (Number.isInteger(newQuantity)) {
      const available = currentStatus ? status[currentStatus] : Object.values(status).reduce((total, val) => val + total, 0);
      setQuantity(Math.min(newQuantity, available));
      return true;
    }
    return false;
  };

  const updateDevice = props.updateDevice;
  const changeStatus = useCallback(() => {
    const ids = (quantity ? devices.slice(0, quantity) : devices).map(({id}) => id);
    const attrs = { status: nextStatus };

    //TODO: define ASSIGN_DEVICE / TRANFER_OWNERSHIP command 
    if (nextStatus === Device.STATUS.ready)
      attrs.owners = [ownership.healthcaresite]; // TODO: use assign_device command

    if (nextStatus === Device.STATUS.inStock || nextStatus === Device.STATUS.created || nextStatus === Device.STATUS.retired) {
      const {default: Config} = require('services/server/functions/model/administration/config');
      attrs.owners = [Config.organisation.root.id];
    }

    Promise.all(ids.map(id => updateDevice({id, ...attrs})))
      .then(_ => void(setNextStatus(undefined), setQuantity(undefined), setOwnership({organisation: undefined, healthcaresite: undefined})))
      .then(_ => require('history.js').default.goBack());
  }, [nextStatus, setNextStatus, devices, quantity, setQuantity, ownership.healthcaresite, setOwnership, updateDevice]);
  
  return <ChangeStatusView
      {...ownership}
      organisations                  = {organisations}
      healthcaresites                = {healthcaresites}
      status                         = {status} 
      nextStatus                     = {nextStatus}
      transitions                    = {transitions} 
      quantity                       = {quantity}
      deviceId                       = {device.id}
      batchId                        = {batch.id}
      devices                        = {devices}
      currentStatus                  = {currentStatus}
      onCancelHandler                = {require('history.js').default.goBack}
      onConfirmHandler               = {changeStatus}
      onNextStatusChangedHandler     = {setNextStatus}
      onQuantityChangedHandler       = {onQuantityChanged}
      onOrganisationChangedHandler   = {(id) => setOwnership({healthcaresite: undefined, organisation: id})}
      onHealthcaresiteChangedHandler = {(id) => setOwnership({organisation: ownership.organisation, healthcaresite: id})}
      I18n                           = {props.I18n}
  />;
}

const ChangeStatus = WithSnapshot(Device)(ChangeStatusPresenter);

export default ChangeStatus;