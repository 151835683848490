import './style.css';
import '../components/TestReportHeader/style.css';

import * as Administration from 'modules/administration';
import * as Pages from 'modules/diagnosis';
import * as Users from 'modules/authentication';

import { Fragment, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import useWindowSize, { isMediumScreen } from 'ui/hooks/useWindowSize';

import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import ExportPDF from 'ui/components/ExportReport';
import MenuButton from 'ui/components/MenuButton';
import { Redirect } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { Study } from 'services/server/functions/model/diagnosis/model';
import StudyStatusLegend from '../components/StudyStatusLegend';
import { Test } from 'services/server/functions/model/diagnosis/model/Test';
import TestSelector from '../components/TestSelector';
import { Text } from 'ui/components/Text';
import { WithStudy } from '../components';
import { aggregateURN } from 'services/server/functions/executor/urn';
import history from 'history.js';
import { messageFrom } from 'modules/executor';
import moment from 'moment';
import useCurrentUser, { IfHasAccess } from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import { CustomField } from 'ui/components/CustomField';
import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import { Patient } from 'services/server/functions/model/diagnosis/model/Patient';
import { getConductedDate, getSleepStudyBlock, getTestSeverity } from './viewStudyHelper';
import i18n from 'services/server/functions/i18n';
import { BlockCommentTypes } from 'features/components/BlockComment';
import { applyTestFormat } from 'services/server/functions/model/diagnosis/connector/test.connector';
import { StudyActions, StudyActionsNames } from 'features/diagnosis/components/StudyActions';
import { reportFileName } from 'features/helpers/reportHelper';
import useSnapshot from 'ui/hooks/useSnapshot';
import StudyEvents from '../components/StudyEvents';
import { getDate } from 'features/helpers/dateHelper';
import { getStudyClinicianAnalysisLastUpdatedTimestamp } from 'services/server/functions/model/diagnosis/connector/study-report.connector';
import { UserContext } from 'features/providers/userContextProvider';
import { StudyPrefs } from 'features/constants/modelActionTypes';
import { CancelStudyModal } from './CancelStudyModal';

const WarningIcon = (props) => <svg className="warningIcon" width={props.width} height={props.height} viewBox="0 0 24 24" focusable="false" role="presentation"><g fillRule="evenodd"><path d="M12.938 4.967c-.518-.978-1.36-.974-1.876 0L3.938 18.425c-.518.978-.045 1.771 1.057 1.771h14.01c1.102 0 1.573-.797 1.057-1.771L12.938 4.967z" fill="currentColor"></path><path d="M12 15a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2" fill="inherit"></path></g></svg>;
const ExclamationIcon = (props) => <svg className="exclamationIcon" width={props.width} height={props.height} viewBox="0 0 24 24" focusable="false" role="presentation">
  <defs>
    <mask id="exclamationMask">
      <rect width="100%" height="100%" fill="white" />
      <rect fill="black" x="11" y="5" width="2" height="10" rx="1" />
      <circle fill="black" cx="12" cy="18" r="1" />
    </mask>
  </defs>
  <circle cx="12" cy="12" r="10" fill="var(--circle-color, currentColor)" mask="url(#exclamationMask)"></circle>
  <rect x="11" y="5" width="2" height="10" rx="1" fill="var(--exclamation-color, currentColor)"></rect>
  <circle cx="12" cy="18" r="1" fill="var(--exclamation-color, currentColor)"></circle>
</svg>;

const PhoneSetup = (props) => {
  const ActivationCode = require('modules/diagnosis/ui/CreateStudy/PrepareEquipment/ActivationCode').default;
  return (
    <div id="PhoneSetup" className="page">
      <Back />
      <div className="pageHeader">
        <h1 className="title"><Text>ViewStudy.title</Text></h1>
      </div>
      <div className="pageContent">
        <ActivationCode {...props} />
      </div>
    </div>
  );
};

const exportReport = (report, id, product) => () => require('services/server/functions/executor/executor.client')
  .getReport(`diagnosis.Study.${report}`, { id, product })
  .then(encFile => `data:application/pdf;base64,${encFile}`);

const exportActivationCode = (studyId, product) => () => require('services/server/functions/executor/executor.client')
  .getReport("diagnosis.Study.activationCode", { product, id: studyId, language: i18n.current, })
  .then(encFile => `data:application/pdf;base64,${encFile}`);

const HighlightText = ({ text, highlight }) => {
  const parts = text.split(highlight);
  return (
    <span>
      {parts.map((part, index) => (
        <span key={index}>
          {part}
          {index < parts.length - 1 && <strong>{highlight}</strong>}
        </span>
      ))}
    </span>
  );
}

const DownloadQRCode = ({ study, product, currentUser }) => {
  const hasValidStatus = [Study.STATUS.created, Study.STATUS.in_progress].includes(study.status);
  const hasAccess = currentUser.isFromAcurable() && currentUser.hasAccess(Study.queries.GET_ACTIVATION_CODE);
  if (!hasValidStatus || !hasAccess) return <></>;

  return <ExportPDF id="exportActivationCode" normal label={"module.diagnosis.fe.view.study.qr_code"} downloadFile={`activation_code_${study.id.split("/").pop()}.pdf`} urlCallback={exportActivationCode(study.id, product)} />
};

const SecondaryActions = ({ children }) => isMediumScreen() ? <MenuButton>{children}</MenuButton> : <Fragment>{children}</Fragment>

const renderPatientProfileFields = (patientProfile, key) => patientProfile.reduce((rows, item, idx) => (idx % 4 === 0 ? rows.push([item]) : rows[rows.length - 1].push(item)) && rows, [])
  .map((row, rowIdx) => (
    <div key={`${key}Row${rowIdx}`} className="grid-col">
      {
        row.concat(Array.from({ length: 4 - row.length }, _ => null)).map((item, colIdx) => item === null ?
          <div key={`patientProfileCol${rowIdx}${colIdx}`}></div> :
          <div key={`patientProfileCol${rowIdx}${colIdx}${item.value}`} className="profile-fields">
            <CustomField {...item.field} value={item.value} comments={item.comments} />
          </div>)}
    </div>)
  );

const ViewStudy = ({
  id,
  reference,
  clinicianAnalysisLastUpdated,
  patient = { profile: [] },
  status, hasConflict, date, activationCode = { required: false },
  requestedTests,
  tests = {},
  archived,
  healthcaresite, trial, clinician,
  handleCancel, handleDelete, handleRestore, handleRefreshCode, notify,
  archive, unarchive,
  generateStudyPdfReport,
  setLocationState,
  clinicianAnalysis,
  updateClinicianAnalysis,
  onClinicianAnalysisUpdated,
  applySignature,
  deleteSignature,
  markAsReviewed,
  markAsFinished,
  signatureInfo,
}) => {
  useWindowSize();
  const { selectedProduct } = useContext(UserContext);
  const [hcsSnap] = useSnapshot(HealthcareSite, [healthcaresite]);
  const [currentUser] = useCurrentUser({ owner: hcsSnap?.aggregate?.id });
  const [showEvents, setShowEvents] = useState(false), toggleEvents = _ => setShowEvents(!showEvents);
  const hasAccess = currentUser.hasAccess;

  const patientID = patient.id || "-";
  const patientBirth = patient.birthDate ? moment(patient.birthDate, "DD/MM/YYYY").format("L") : "-";
  const patientAge = patient.birthDate ? moment().diff(moment(patient.birthDate, "DD/MM/YYYY"), 'years') : "-";
  const questionnaireScore = patient.questionnaireScore >= 0 ? patient.questionnaireScore : (Patient.shouldFillQuestionnaire(patient) ? Text.resolve("PENDING") : Text.resolve("N/A"));
  const studyDate = date ? moment(date).format("L") : "-";

  const codeExpired = Study.STATUS.compare(status, Study.STATUS.finished) < 0 && activationCode?.until !== undefined && moment().isAfter(moment(activationCode.until));

  const [waitingForCode, setWaitingForCode] = useState(false);
  useEffect(() => {
    !codeExpired && setWaitingForCode(false);
  }, [codeExpired]);

  const patientProfile = [
    { field: { type: 'NUMBER', name: { default: Text.resolve("ViewStudy.age") } }, value: patientAge },
    { field: { type: 'NUMBER', name: { default: Text.resolve("ViewStudy.score") }, description: { default: Text.resolve("ViewStudy.score-help") } }, value: questionnaireScore },
    ...(patient.profile || []).filter(e => !e.field.studyDetail),
  ];
  const patientProfileStudyDetails = (patient.profile || []).filter(e => e.field.studyDetail).reduce((acc, curr) => {
    acc.push(curr);
    if (curr.field.id === "referring-clinician" && signatureInfo?.userFullName) {
      acc.push({ field: { id: 'interpreting-clinician', name: 'Interpreting Clinician', type: "TEXT", studyDetail: true }, value: signatureInfo.userFullName });
    }
    return acc;
  }, []);

  const showDelete = currentUser?.isFromAcurable() && status === Study.STATUS.created;
  const showCancel = Study.STATUS.compare(status, Study.STATUS.finished) < 0 && Object.values(tests).map(t => [t, ...Object.values(t.repeats || {})]).flat().some(test => test.status === Test.STATUS.pending || test.status === Test.STATUS.started);

  const onSaveClinicianAnalysis = (clinicianAnalysis) => {
    return updateClinicianAnalysis({
      id,
      clinicianAnalysis
    }).then(ev => {
      onClinicianAnalysisUpdated(ev.data.clinicianAnalysis);
    });
  };

  const modelData = {
    study: {
      id,
      patient,
      status,
      date: studyDate,
      healthcaresite,
      tests: applyTestFormat(tests),
      clinicianAnalysisLastUpdated,
    },
  };

  const [hideDeleteButton, setHideDeleteButton] = useState(true);

  const showPatientProfile = (patientProfile?.length === 2 && !isNaN(patientProfile[1]?.value)) || patientProfile?.length > 2;
  const clinicianAnalysisBlock = (
    <StudyActions
      id="clinician-comments"
      site={healthcaresite}
      {...modelData}
      clinicianAnalysis={clinicianAnalysis}
      onSaveClinicianAnalysis={onSaveClinicianAnalysis}
      applySignature={applySignature}
      deleteSignature={deleteSignature}
      markAsReviewed={markAsReviewed}
      signatureInfo={signatureInfo}
      hideDeleteButton={hideDeleteButton}
      onToggle={(e) => setHideDeleteButton(!e)}
      updateActions={(actions) => Object.entries(actions).reduce((acc, [actionKey, action]) => {
        if (actionKey === StudyActionsNames.Analysis) {
          acc[actionKey] = (props) => action({
            ...props,
            title: Text.resolve('module.diagnosis.fe.view.study.comments_title'),
            titleActions: [
              Study.STATUS.compare(props.study.status, Study.STATUS.finished) === 0 ? <Action.Guarded resource={Study.commands.MARK_AS_REVIEWED} handleAction={markAsReviewed} id="markStudyAsReviewed" key="markAsReviewed" className="button" label='module.diagnosis.fe.view.findStudy.markStudyAsReviewed' /> : undefined,
              Study.STATUS.compare(props.study.status, Study.STATUS.reviewed) === 0 ? <Action.Guarded resource={Study.commands.MARK_AS_FINISHED} handleAction={markAsFinished} id="markStudyAsFinished" key="markAsFinished" className="button" label='module.diagnosis.fe.view.findStudy.markStudyAsFinished' /> : undefined
            ].filter(Boolean),
            addLabel: Text.resolve('module.diagnosis.fe.view.study.add_analysis'),
            type: BlockCommentTypes.TextAreaModal
          });
        }
        return acc;
      }, {})}
    />
  );

  const showActCode = activationCode?.required && !activationCode?.disabled;

  return (
    <div id="ViewStudy" className="page" size="A4" layout="portrait">
      <Back />
      <div className="pageHeader">
        <h1 className="title"><Text>ViewStudy.title</Text></h1>
        <StudyActions
          id="study-actions-header"
          site={healthcaresite}
          {...modelData}
          layout
          clinicianAnalysis={clinicianAnalysis}
          onSaveClinicianAnalysis={onSaveClinicianAnalysis}
          applySignature={applySignature}
          deleteSignature={deleteSignature}
          signatureInfo={signatureInfo}
          notify={notify}
          urlCallback={generateStudyPdfReport}
        >
          <SecondaryActions>
            {Study.STATUS.compare(status, Study.STATUS.finished) >= 0 && !archived && <Action.Guarded resource={Study.commands.ARCHIVE_STUDY} handleAction={archive} id="archiveStudy" className="button" label='module.diagnosis.fe.view.findStudy.archive' tooltip={Text.resolve("ViewStudy.archive-btn-hover")} />}
            {Study.STATUS.compare(status, Study.STATUS.finished) >= 0 && archived && <Action.Guarded resource={Study.commands.UNARCHIVE_STUDY} handleAction={unarchive} id="archiveStudy" className="button" label='module.diagnosis.fe.view.findStudy.unarchive' tooltip={Text.resolve("ViewStudy.unarchive-btn-hover")} />}
            <Action.Guarded resource={Pages.EditStudy} handleAction={() => history.push(Pages.EditStudy.PATH, { study: id })} id="editStudy" className="button" label='edit' tooltip={Text.resolve("ViewStudy.edit-btn-hover")} />
            <CancelStudyModal currentUser={currentUser} disabled={!showCancel} type={'cancel'} tooltip={Text.resolve("ViewStudy.cancel-btn-hover")} onConfirm={handleCancel} />
            <CancelStudyModal currentUser={currentUser} disabled={!showDelete} type={'delete'} tooltip={Text.resolve("ViewStudy.delete-btn-hover")} onConfirm={handleDelete} />
            <Action.Guarded resource={Pages.CreateStudy} handleAction={() => history.push(Pages.CreateStudy.PATH, { clone: id })} tooltip={Text.resolve("ViewStudy.repeat-btn-hover")} id="repeatStudy" className={"button"} label='repeat' />
            <DownloadQRCode study={{ id, status }} product={selectedProduct?.key} currentUser={currentUser} />
          </SecondaryActions>

        </StudyActions>
        {!hasConflict && <TestSelector study={id} tests={tests} />}
      </div>
      <div className="pageContent">
        <div className="pageSection">
          <div className="title"><Text>ViewStudy.summary</Text></div>
          <DetailsBlock className="studyDetailsHeader studySummaryHeader">
            <Details id="patientDetails" label="patient-details">
              <div className="grid-col">
                <DetailsBlock className="profileBlock">
                  <div className="grid-col">
                    <Details id="studyPatientID" label="patient-id" value={patientID.toUpperCase()} />
                    <Details id="studyPatientBirth" label="global.date_of_birth" value={patientBirth} className="canBeHiddenAt410" />
                    <Details id="patientReference" label="patient-reference" value={patient.reference} />
                  </div>
                </DetailsBlock>
              </div>
            </Details>
            {showPatientProfile && (
              <Details id="patientProfile" label="patient-profile">
                <div className="grid-col">
                  <DetailsBlock className="profileBlock">
                    {renderPatientProfileFields(patientProfile, "patientProfile")}
                  </DetailsBlock>
                </div>
              </Details>
            )}
            <Details id="patientProfile" label="ViewStudy.study-details">
              <div className="grid-col">
                <DetailsBlock className="feedbackBlock">
                  <div className="grid-row">
                    <div className="grid-col">
                      <Details id="studyReference" label="study-reference" value={reference || "-"} />
                      <Details id="studyDate" label="date-created" value={studyDate} />
                      <Details id="numTests" label="ViewStudy.num-tests" help="ViewStudy.num-tests-help" helpPosition="top">
                        <span>{`${Object.values(tests).filter(t => Test.STATUS.compare(t.repeats ? Object.values(t.repeats).pop().status : t.status, Test.STATUS.analysed) >= 0).length} / ${requestedTests}`}</span>
                      </Details>
                      <Details id="status" label="ViewStudy.status" help={<StudyStatusLegend helpPosition="top"/>} >
                        <span className={`status ${Study.getStatusGroup(status)}`}><Text context={{ schema: "diagnosis.Study" }}>{Study.getStatusGroup(status)}</Text></span>
                      </Details>
                    </div>
                  </div>
                  {renderPatientProfileFields(patientProfileStudyDetails, "patientProfileStudyDetails")}
                </DetailsBlock>
              </div>
            </Details>
            <div className="grid-col">
              <Details id="healthcaresiteName" label="hcs"><Administration.ViewHCS.AsLinkButton healthcaresite={healthcaresite} /></Details>
              <Details id="clinicianName" label="global.clinician.label"><Users.ViewUser.AsLinkButton user={clinician} blockOnError /></Details>
            </div>
            {hasAccess(Administration.ViewTrial) && trial && <Details id="trialName" label="trial"><Administration.ViewTrial.AsLinkButton trial={trial} /></Details>}
            {showActCode && !codeExpired && <DetailsBlock className="feedbackBlock">
              <div className="grid-col">
                <ExclamationIcon width={30} height={30} />
                <div className="grid-row">
                  <HighlightText
                    text={Text.resolve('ViewStudy.instruction-sheet.message', { activationCode: activationCode?.value || "-" })}
                    highlight={activationCode?.value || "-"}
                  />
                  {<ExportPDF
                    secondary
                    disabled={!hasAccess(Study.queries.GET_STUDY_LETTER) || codeExpired}
                    tooltip={Text.resolve("ViewStudy.activation-code-hover")}
                    label="ViewStudy.instruction-sheet"
                    downloadFile={reportFileName('letter', patientID, studyDate)}
                    id="ViewLetter"
                    urlCallback={exportReport('letter', id, selectedProduct?.key)}
                    onError={e => notify('error', Text.resolve("ViewStudy.error.letter"), messageFrom(e))}
                  />}
                </div>
              </div>
            </DetailsBlock>}
            {codeExpired && <DetailsBlock className="feedbackBlock">
              <div className="grid-col">
                <WarningIcon width={50} height={50} />
                <div className="grid-row">
                  <span>{patient.instructions?.providedPhone ? <Text>ViewStudy.status.expired.hospital.desc</Text> : <Text>ViewStudy.status.expired.patient.desc</Text>}</span>
                  {waitingForCode ? <div className="grid-row centered italic"><Text>component.create_study.loading_message</Text><Spinner size="small" /></div> : <Action disabled={!hasAccess(Study.commands.REFRESH_ACTIVATION_CODE)} handleAction={() => { handleRefreshCode().then(() => setLocationState({ showActivationCode: true }, { type: 'push' })).catch(e => { setWaitingForCode(false); return e; }); setWaitingForCode(true); }} id="createNewActivationCode" label="ViewStudy.status.expired.button" className="button secondary" />}
                </div>
              </div>
            </DetailsBlock>}
          </DetailsBlock>
        </div>

        {hasConflict && <div className="pageSection">
          <DetailsBlock inline className="error" contentClass="content">
            <div className="errorIcon"><ErrorIcon size="xlarge" /></div>
            <div className="grid-row">
              <span className="title"><Text>ViewStudy.study-deleted</Text></span>
              <span>{status === Study.STATUS.in_progress ? <Text>ViewStudy.deleted-before-uploaded</Text> : <Text>ViewStudy.deleted-before-results</Text>}</span>
              <div className="left actions">
                <Action handleAction={() => handleRestore()} id="restoreStudy" label="restore" className="button secondary" />
                <Action handleAction={() => handleCancel()} id="deleteStudy" label="delete" className="button primary" />
              </div>
            </div>
          </DetailsBlock>
        </div>}

        {hasAccess(Pages.ViewTest) && <div className="pageSection">
          <div className="title large"><Text>ViewStudy.sleep-tests</Text></div>
          <div className="grid-row">
            {Object.values(tests).map((test, idx) => {
              const conductedDate = getConductedDate(idx, test);
              const testSeverity = getTestSeverity(hasAccess, test);

              return getSleepStudyBlock({
                id,
                idx,
                test,
                conductedDate,
                testSeverity,
                hasAccess
              })
            })}
          </div>
        </div>}
        {clinicianAnalysisBlock}
        <IfHasAccess resource={Pages.FindStudyEvents}>
          <div className="pageSection">
            <div className={"title"}><div className="grid-col fitContent"><div><Text>Events log</Text></div><div className="button admin" onClick={toggleEvents}>{showEvents ? 'Close' : 'Open'}</div></div></div>
            {showEvents && <StudyEvents id={id} hideTitle />}
          </div>
        </IfHasAccess>
      </div>
    </div>
  );
};

const ViewStudyPresenter = (props) => {
  let { showActivationCode, study: studySnap } = props;
  const study = studySnap?.data || { id: undefined, site: undefined, date: undefined, clinician: undefined, tests: {}, patient: { profile: [], instructions: {} }, status: undefined, activationCode: undefined, hasConflict: false, owners: [] };
  study.archived = studySnap?.metadata?.archived;
  const clinicianAnalysisLastUpdatedTimestamp = getStudyClinicianAnalysisLastUpdatedTimestamp(studySnap);

  const handleDelete = useCallback((data) => props.deleteStudy({ id: study.id, ...data, metadata: { awaitSnapshot: true } }, { notifications: { successText: "ViewStudy.study-deleted" } }).then(() => history.push(Pages.FindStudy.PATH)), [study.id]);
  const handleCancel = useCallback((data) => props.cancelStudy({ id: study.id, ...data, metadata: { awaitSnapshot: true } }, { notifications: { successText: "schema.Study.cancelled" } }), [study.id]);
  const handleRestore = useCallback(() => props.restoreStudy({ id: study.id }), [study.id]);
  const handleRefreshCode = useCallback(() => props.refreshActivationCode({ id: study.id, activationCode: study.activationCode }), [study.id, study.activationCode]);

  // Just to force a re-render
  const [, setClinicianAnalysis] = useState(study.clinicianAnalysis);
  const onClinicianAnalysisUpdated = (clinicianAnalysis) => {
    study.clinicianAnalysis = clinicianAnalysis;
    setClinicianAnalysis(clinicianAnalysis);
  };

  const applySignature = async () => {
    await props.applySignature({ id: study.id });
  };
  const deleteSignature = async () => {
    await props.deleteSignature({ id: study.id });
  };
  const markAsReviewed = async () => {
    await props.markAsReviewed({ id: study.id });
  };
  const markAsFinished = async () => {
    await props.markAsFinished({ id: study.id });
  };

  const ViewComponent = study?.site ? (
    <ViewStudy {...{
      ...study,
      clinicianAnalysisLastUpdated: clinicianAnalysisLastUpdatedTimestamp ? getDate({ date: clinicianAnalysisLastUpdatedTimestamp }) : undefined,
      onClinicianAnalysisUpdated,
      reference: Study.getReference(study),
      clinician: study.clinician,
      healthcaresite: study.site,
      trial: Organisation.entities.Trial.ownersFrom(study)[0],
      handleCancel,
      handleDelete,
      handleRestore,
      handleRefreshCode,
      archive: _ => props.archive({ id: study.id, metadata: { awaitSnapshot: true } }),
      unarchive: _ => props.unarchive({ id: study.id, metadata: { awaitSnapshot: true } }),
      generateStudyPdfReport: ({ mode, tests } = {}) => props.generateStudyPdfReport({
        id: study.id, report: {
          language: i18n.current, options: {
            mode: mode || 'DETAILED', tests: tests || []
          }
        }
      }),
      notify: props.notify,
      setLocationState: props.setLocationState,
      updateClinicianAnalysis: props.updateClinicianAnalysis,
      applySignature,
      deleteSignature,
      markAsReviewed,
      markAsFinished,
      signatureInfo: props.signatureInfo,
    }} />
  ) : (<></>);
  return showActivationCode ? <PhoneSetup {...props} /> : ViewComponent;
}

const ViewStudyWithSnap = (props) => {
  const [locState, setLocationState] = useLocationParams({ showActivationCode: false, test: undefined, retry: undefined, study: undefined });
  let { study: studyId, test: testId, retry, ...stateParams } = locState;
  studyId = studyId && aggregateURN(Study, studyId);

  const [notfound, setNotFound] = useState(undefined);

  const signaturePreferenceId = useMemo(() => studyId && Study.entities.Preferences.newURN(studyId.split("/").pop(), StudyPrefs.StudySignature), [studyId]);
  const [studySignatureSnap] = useSnapshot(signaturePreferenceId, { autoStart: Boolean(studyId) });
  const signatureInfo = studySignatureSnap?.data?.defaults?.signatureInfo;

  return testId !== undefined ? <Redirect to={Pages.ViewTest.PATH} state={{ study: studyId, test: testId, retry }} /> : notfound === true ? <Redirect to={Pages.FindStudy.PATH} /> : (
    <WithStudy id={studyId} onNotFound={() => setNotFound(true)} showLoading={true} >
      <ViewStudyPresenter {...{ ...props, ...stateParams, setLocationState, signatureInfo }} />
    </WithStudy>
  )
}

ViewStudyWithSnap.displayName = 'ViewStudy';
export default ViewStudyWithSnap;