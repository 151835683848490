
export const ModelFormTypes = Object.freeze({ //@meir I believe this is pointless. the model is the one that know what settings are defined by the system
    StudySettings: 'StudyConfigSettings',
    SignalSettings: 'SignalSettings',
    SignalsReportSettings: 'SignalsReportSettings',
    StudyTemplateSettings: 'StudyTemplateSettings',
    SleepReportSettings: 'SleepReportSettings',
    DigitalSignature: 'DigitalSignature',
    ManualScoringSettings: 'ManualScoring',
    ReviewedStudies: 'ReviewedStudies',
});

export const UserPrefs = Object.freeze({
    Signature: 'Signature',
});

export const StudyPrefs = Object.freeze({
    StudySignature: 'StudySignature',
});