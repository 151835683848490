import Styled from 'styled-components';
import history from 'history.js';

const BrandNav = Styled.p`
  width:100%;
  color: #2f2f2f;
  padding-top:10px;
  padding-left:2px;
  font-size: 22px;
  text-align: left;
  cursor: pointer;
  margin: ${props=>props.margin || 0};
  display: ${props=>props.show ? 'unset' : 'none'};
`;

const NavigationTitle = (props) => (
  <BrandNav onClick={() => history.push("/")} show={!('show' in props) || props.show} margin={props.margin}>{props.brand}</BrandNav>
);

export default NavigationTitle;
