import { useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import * as R from 'ramda';

import { Text } from 'ui/components/Text';
import { Select } from 'ui/components/Select';
import Details from 'ui/components/Details';

import { User } from 'services/server/functions/model/authentication/model';
import { Study } from 'services/server/functions/model/diagnosis/model';

import { ModelFormTypes } from "features/constants/modelActionTypes";
import MODELS from 'modules/model';
import { useSettings } from "features/hooks/useSettings";
import { SchemaForm } from "features/components/SchemaForm";
import { getSchemaFormConfig } from 'features/components/SchemaForm/utils/formUtils';
import ProductStudy from 'services/server/functions/model/administration/model/ProductStudy';
import { flattenObject } from 'services/server/functions/helpers/utils';

const formId = ModelFormTypes.StudySettings;

export const EditCSVRow = ({ row, loadingSites, loadingClinicians, siteOptions, usersSnaps, onSiteChange, onClinicianChange, onStudySettingsChange, settings = {}, }) => {
    const { hasRole } = require('services/server/functions/iam');

    const { schemas, loadingSchemas, retrieveSchemas } = useSettings({
        ownerId: row.instance.site,
        schemasToRetrieve: [{
            formId,
            action: MODELS.diagnosis.Study.actions.GET_CREATE_STUDY_FORM.exec
        }],
        auto: false,
    });

    useEffect(() => {
        retrieveSchemas();
    }, [row.instance.site]);

    const studySchema = schemas?.[formId];
    const mergedFlattennedSettings = flattenObject(R.mergeDeepRight(settings, ProductStudy.schemas.StudyConfigSettings.extractFromStudy(row.instance)));
    const formProps = {
        onChange: ({ formData }) => {
            Object.keys(formData).forEach(key => {
                const value = formData[key];
                if (value !== mergedFlattennedSettings[key]) {
                    onStudySettingsChange({ target: { field: key, value } });
                }
            });
        },
    };
    const formOpts = { showClearButton: true };

    const clinicians = Object.values(usersSnaps || {})
        .filter(u => u.data.status !== User.STATUS.disabled && !u.metadata.impersonator && u.data.owners.includes(row.instance.site))
        .filter(u => hasRole(Study.roles.CREATE_STUDY.id)(u.data.roles));
    const clinicianOptions = clinicians.map(c => ({ label: [c.data.lastName, c.data.name].join(', '), value: c.data.id }));

    return <>
        <div className='grid-row vcentered' style={{ width: '800px' }}>
            <div className='grid-col vcentered'>
                <Details id="siteField" label="hcs" help={siteOptions.length === 0 ? <span><Text>CreateStudy.only-users-hcs</Text><br /><Text>CreateStudy.if-an-expected-hcs</Text></span> : undefined} >
                    {loadingSites ? <Spinner size="small" />
                        : <Select
                            name="site"
                            options={siteOptions}
                            placeholder={Text.resolve("CreateStudy.select-hcs")}
                            isSearchable={true}
                            isSortable={true}
                            onChange={({ target }) => onSiteChange(target.value)}
                            value={row.instance.site} />}
                </Details>
                <Details id="clinicianField" label="global.clinician.label" help={clinicianOptions.length === 0 ? <span><Text>CreateStudy.only-users-study</Text><br /><Text>CreateStudy.if-an-expected-clinician</Text></span> : undefined} >
                    {loadingClinicians ? <Spinner size="small" />
                        : <Select
                            disabled={!row.instance.site}
                            name="clinician"
                            options={clinicianOptions}
                            placeholder={Text.resolve("CreateStudy.select-clinician")}
                            isSearchable={true}
                            onChange={({ target }) => onClinicianChange(target.value)}
                            value={row.instance.clinician} />}
                </Details>
            </div>
        </div>
        {loadingSchemas ? <Spinner size="small" /> : row.instance.site && studySchema ? <SchemaForm
            {...getSchemaFormConfig({ schema: studySchema.form, id: formId })}
            id={formId}
            schema={studySchema.form}
            data={mergedFlattennedSettings}
            formOpts={formOpts}
            actions={studySchema.actions}
            {...formProps} /> : ''}
    </>;
};