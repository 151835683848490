import { useRef, useState } from 'react';

import { Roles } from 'services/server/functions/iam/roles';
import useEffectOnMount from './useEffectOnMount';
import usePageChange from './usePageChange';

export default (onUpdate=()=>{}) => {
  const unsubscribe = useRef();
  const cancelSubscription = _ => {if (unsubscribe.current) unsubscribe.current(); unsubscribe.current = undefined;};
  const [roles, setRoles] = useState(Roles);
  useEffectOnMount(_ => {unsubscribe.current = Roles.onUpdate(_ => { setRoles({...Roles}); onUpdate(Roles); }); return cancelSubscription;}); // Copy Roles object to force update
  usePageChange(cancelSubscription);

  return [ roles ];
}