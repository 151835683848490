import ArrowLeft from '@atlaskit/icon/glyph/arrow-left';
import { AkCreateDrawer } from '@atlaskit/navigation';
import NavigationTitle from './../NavigationTitle';
import ImgFluid from "../../../ImageFluid";
import BlackIsotype from "../../../images/Isotipo-black.png";

const CreateDrawer = props => {
  const { drawerIsOpen, closeDrawer, brand="Acurable", iconOffset=70, width='narrow', children } = props;

  return(
    <AkCreateDrawer
      backIcon     = {<ArrowLeft label="Back" />}
      primaryIcon  = {<ImgFluid src={BlackIsotype} />}
      header       = {<NavigationTitle margin={'0 0 0 -8px'} brand={brand} />}
      isOpen       = {drawerIsOpen}
      iconOffset   = {iconOffset || 0}
      width        = {width}
      onBackButton = {closeDrawer}
    >
      {children}
    </AkCreateDrawer>
  )
}

export default CreateDrawer;


