import { useCallback } from "react";
import moment from 'moment';

import Details from "ui/components/Details";
import FindCalendar from 'ui/components/Calendar';
import { FiltersType } from "../../utils";

const openToDate = moment().subtract(40, 'years').toDate();
export const PatientBirthField = ({ filters, onFilterChange }) => {
    const date = filters[FiltersType.PatientBirth];
    const patientBirth = date ? new Date(date) : null;
    const handleChange = useCallback(e => onFilterChange(
        { [FiltersType.PatientBirth]: e.target.value ? e.target.value.toISOString() : null }), [onFilterChange]);
    return (
        <Details id="StudyPatientBirthDetails" label="FindStudies.birth-date"
            maxDate={moment().toDate()}>
            <FindCalendar
                handleChange={handleChange}
                id={FiltersType.PatientBirth}
                openToDate={patientBirth ?? openToDate}
                value={patientBirth}
            />
        </Details>
    );
};
