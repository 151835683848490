import Config from '../config';

//import { /*UNIQUE_FIELD_POLICY,*/ VALID_DATA_POLICY, when } from '../../policies';

import { Joi } from '../../../validation/rules';
import { withDefaults } from '../..';
//import { chain } from '../../../promises';

const TYPES = ["flow-event", "3%-desaturation-event", "4%-desaturation-event"];
export const Marker = {
  context : Config.context,
  name    : 'Marker',
  TYPES, // TODO: get somehow from Organisation settings
  schema : Joi.object().keys({
    // IDEA: maybe define a transient ID property which is a hash of the key/required properties ? may be useful at some point
    signal    : Joi.string(),
    type      : Joi.string().valid(...TYPES),
    onset     : Joi.number().positive().allow(0),
    duration  : Joi.number().positive(),
    origin    : Joi.string().valid('automatic', 'user-defined'),
    userChange: Joi.when('origin', {is: 'user-defined', then: Joi.forbidden(), otherwise: {
      type     : Joi.string(),
      onset    : Joi.number().positive().allow(0),
      duration : Joi.number().positive(),
      deleted  : Joi.boolean(),
    }})
  }),
  isUserDefined: m => m?.userChange || m?.origin === 'user-defined',
  equals: (m1, m2) => m1?.signal === m2?.signal && m1?.type === m2?.type && Math.abs(m1.duration - m2.duration) < 0.1 && Math.abs(m1.onset - m2.onset) < 0.1,
  get uniq() { return require('ramda').uniqWith(this.equals) },
  get diff() { return require('ramda').differenceWith(this.equals) },
  toManualScore: (markers, diagnosableTimeInSecs) => {
    markers = markers.filter(Marker.isUserDefined);
    if (!markers?.length || diagnosableTimeInSecs <= 0) 
      return undefined;

    const score = markers.reduce((a, m) => ({
      ...a,
      [m.type]: m.userChange?.deleted ? a[m.type] - 1 : a[m.type] + 1
    }), Marker.TYPES.reduce((a, t) => ({...a, [t]: 0 }) , {}));

    score["3%-desaturation-event"] += score["4%-desaturation-event"];
    
    Object.keys(score).forEach(k => {
      score[k] = Math.trunc(score[k]*3600/diagnosableTimeInSecs)
    })

    return score;
  }
};

export const asEntity = (parent) => withDefaults()({
  ...Marker,
  events: {
    MARKER_ADDED  : {
      snapshot: (e) => {
        e.data.origin ??= 'automatic';
        return e;
      }
    },
    MARKER_EDITED : {},
    MARKER_REMOVED: { 
      snapshot: (_ev, prevMarker) => prevMarker.data.origin === 'user-defined' ? {metadata: {deleted: true}} : {data: {userChange: {deleted: true}}}
    }
  },
  commands: {
    ADD_MARKER: {
      /*TODO checkPolicies: (payload, _, ) => {
        //const duplicates = (studySnap.data.report?.markers || []).filter(oldMarker => Marker.equals(oldMarker, payload.marker));
        //const test = GET(testID);
        return chain(
          () => when(payload.onset + payload.duration >= test.data.recording.length).rejectWith(VALID_DATA_POLICY(`Invalid marker parameters '${JSON.stringify(payload)}', recording length for study ${payload.id} and test ${test.data.id} is ${test.data.recording.length}.`)),
          //() => when(duplicates.length > 0).rejectWith(UNIQUE_FIELD_POLICY(`Unable to add markers. Markers '${JSON.stringify(duplicates)}' already exists.`))
        )
        // TODO: Should we check the new markers parameters are valid ()??
      },*/
      get schema() { return Joi.object().keys({
        id      : parent.entities.Marker.schema.extract('id'),
        signal  : Marker.schema.extract('signal'),
        type    : Marker.schema.extract('type').required(),
        onset   : Marker.schema.extract('onset').required(),
        duration: Marker.schema.extract('duration').required()
      }); },
      event: (action) => { return parent.entities.Marker.events.MARKER_ADDED.new({...action.data, origin: 'user-defined'}); }
    },
    EDIT_MARKER: {
      /*TODO checkPolicies: (payload, _, ) => {
        //const duplicates = (studySnap.data.report?.markers || []).filter(oldMarker => Marker.equals(oldMarker, payload.marker));
        //const test = GET(testID);
        return chain(
          () => when(payload.onset + payload.duration >= test.data.recording.length).rejectWith(VALID_DATA_POLICY(`Invalid marker parameters '${JSON.stringify(payload)}', recording length for study ${payload.id} and test ${test.data.id} is ${test.data.recording.length}.`)),
          //() => when(duplicates.length > 0).rejectWith(UNIQUE_FIELD_POLICY(`Unable to add markers. Markers '${JSON.stringify(duplicates)}' already exists.`))
        )
        // TODO: Should we check the new markers parameters are valid ()??
      },*/
      get schema() { return Joi.object().keys({
        id      : parent.entities.Marker.schema.extract('id').required(),
        type    : Marker.schema.extract('type'),
        onset   : Marker.schema.extract('onset'),
        duration: Marker.schema.extract('duration')
      }).or('type', 'onset', 'duration'); },
      event: (action, prevMarker) => {
        const {id, ...newChange} = action.data;
        const userChange = Object.keys(prevMarker.userChange || {}).reduce((c, f) => {
          if (!(f in c)) c[f] = prevMarker[f]; // restore original field value if the new change does not modify the original field value
          return c;
        }, newChange);
        return parent.entities.Marker.events.MARKER_EDITED.new(prevMarker.data.origin === 'user-defined' ? action.data : {id, userChange}); 
      }
    },
    REMOVE_MARKER: {
      get schema() { return Joi.object().keys({
        'id': parent.entities.Marker.schema.extract('id').required()
      }); },
      get event() { return parent.entities.Marker.events.MARKER_REMOVED; }
    }
  },
  queries: {
    VIEW_MARKERS: {
      // TODO: Iplement query
    }
  }
}, parent);