import { memo } from 'react';
import moment from 'moment';

import { useSettingsValue } from 'features/hooks/useSettingsValue';

import { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "ui/components/Text";

import { ModelFormTypes } from 'features/constants/modelActionTypes';

const addReportFields = (data) => {
    data.study?.tests?.forEach(test => {
        if (test.recording?.startTime) test.startTime = moment(test.recording.startTime).utcOffset(test.recording.startTime).format("hh:mma");
        if (test.recording?.endTime) test.endTime = moment(test.recording.endTime).utcOffset(test.recording.endTime).format("hh:mma");
        if (test.recording?.length) test.length = moment().startOf('day').milliseconds(test.recording.length * 1000).format('H[h] m[m]');
    });
};

export const TemplateSettingValue = memo(({
    owner, data, className, onResolve }) => {
    const id = ModelFormTypes.StudyTemplateSettings;
    const { settingValue } = useSettingsValue({ id, owner });
    const clinicianAnalysis = settingValue?.clinicianAnalysis;
    const onClickTemplateImport = () => onResolve(Text.render(clinicianAnalysis, data));
    addReportFields(data);
    return (
        <>
            {clinicianAnalysis && (
                <div id="template-import" className={`${className}`} >
                    {
                        <CustomModal
                            id={'template-confirmation-modal'}
                            btn
                            heading={Text.resolve('module.diagnosis.fe.component.template_import_title')}
                            label={Text.resolve('module.diagnosis.fe.component.template_import_title')}
                            cancelLabel={Text.resolve("global.button.close")}
                            secondaryLabel={Text.resolve("global.button.continue")}
                            secondaryAction={onClickTemplateImport}
                            appearance={'default'}
                            disabled={!data}
                            size="sm"
                        >
                            <div className="confirmation-modal">
                                <Text>
                                    module.diagnosis.fe.component.template_import_confirmation_modal
                                </Text>
                                <span>
                                    <Text>
                                        global.wish_to_continue
                                    </Text>
                                </span>
                            </div>
                        </CustomModal>
                    }
                </div>
            )}
        </>
    );
}
);