const extractPatternsFromRegex = (regex) => {
  const pattern = regex.toString().slice(1, -1);
  const patternArray = pattern.split('|').map(p => p.trim());
  return patternArray;
}

const JoiSchemaDescriptor = {
  getPatternKeys: schema => {
    const pattern = schema.$_terms.patterns?.[0];
    if (!pattern?.regex) return [];
    const keys = extractPatternsFromRegex(pattern.regex);
    return keys.map(key => ({ schema: pattern.rule, id: key }));
  },
  getKeys: schema => [...(schema._ids?._byKey?.values() || []), ...JoiSchemaDescriptor.getPatternKeys(schema)],
  getMetas: schema => schema.$_terms?.metas?.reduce((a, m) => ({ ...a, ...m }), {}), // not sure if this makes sense? merge metas?
  getWhens: schema => schema.$_terms?.whens || [],
  getFlags: schema => schema?._flags,
  getRules: schema => schema._rules || [],
  getValidValues: schema => schema._valids?._values,
  wrap: schema => {
    return {
      ...schema,
      get metadata() { return JoiSchemaDescriptor.getMetas(schema) },
      get whens() { return JoiSchemaDescriptor.getWhens(schema) },
      get flags() { return JoiSchemaDescriptor.getFlags(schema) },
      get rules() { return JoiSchemaDescriptor.getRules(schema) },
      get validValues() { return JoiSchemaDescriptor.getValidValues(schema) },
    }
  },
  // lightweigth version of Joi schema describe (which happens to be disabled in joi-browser)
  describe: (schema, ...path) => {
    if (schema.type !== 'object') {
      return { field: path.join('.'), path: [...path], key: path.pop(), schema: JoiSchemaDescriptor.wrap(schema) };
    }
    const fields = Fields.wrap(JoiSchemaDescriptor.getKeys(schema).map(k => JoiSchemaDescriptor.describe(k.schema, ...path, k.id)).reduce((all, f) => [...all, ...(Array.isArray(f) ? f : [f])], []));
    return ({ field: path.join('.'), path: [...path], key: path.pop(), schema: JoiSchemaDescriptor.wrap(schema), fields });
  },
  find: (schema, filter, ...path) => {
    const wrappedSchema = JoiSchemaDescriptor.wrap(schema);
    if (filter(wrappedSchema)) return { schema, path };
    if (schema.type === 'object') {
      for (const field of JoiSchemaDescriptor.getKeys(schema)) {
        const search = JoiSchemaDescriptor.find(field.schema, filter, ...path, field.id);
        if (search.schema) return search;
      }
    }
    return {};
  },
};

// Assumes the field schema is wrapped
const Field = {
  isAllowed: f => f.schema.flags?.presence !== 'forbidden',
  isRequired: f => f.schema.flags?.presence === 'required',
  withMeta: (f, comp) => comp(f.schema.metadata, f),
  getType: f => Rules.find(f, (r => r.name === "integer")) ? "integer" : f.schema.type,
  getPreferences: f => f.schema.metadata.preferences,
  // it's not entirely true as there might also be a presence flag present on the else statement or in a nested when
  // .slice().reverse() used as a workaround to get the latest when applied, as we cannot remove previous whens (really ugly)
  getRequiredWhen: f => Whens.wrap(f).slice().reverse().find(w => JoiSchemaDescriptor.getFlags(w.then)?.presence === "required"),
  // it's not entirely true as there might also be a result flag present on a nested when
  // .slice().reverse() used as a workaround to get the latest when applied, as we cannot remove previous whens (really ugly)
  getHideWhen: f => Whens.wrap(f).slice().reverse().find(w => JoiSchemaDescriptor.getFlags(w.then)?.result === "strip" || JoiSchemaDescriptor.getFlags(w.otherwise)?.result === "strip"),
  getPath: f => [...f.path],
  wrap: field => {
    return {
      ...field,
      get allowed() { return Field.isAllowed(field) },
      get required() { return Field.isRequired(field) },
      get type() { return Field.getType(field) },
      get enum() {
        const validValues = JoiSchemaDescriptor.getValidValues(field.schema);
        const rules = Rules.wrap(this);
        if (validValues?.size > 0 && !(rules.minimum === 0 && validValues.has(0))) return Array.from(validValues);
        return undefined;
      },
      get items() {
        // TODO: For now we will only support enums in array, not input values
        const self = this;
        return {
          enum: Array.from(self.schema.$_terms.items.reduce((acc, curr) => {
            Array.from(JoiSchemaDescriptor.getValidValues(curr)).forEach(e => acc.add(e));
            return acc;
          }, new Set())),
        };
      },
      get preferences() { return Field.getPreferences(field) },
      get requiredWhen() { return Field.getRequiredWhen(field) },
      get hideWhen() { return Field.getHideWhen(field) },
      get path() { return Field.getPath(field) },
      get conditions() { return buildConditions(this) },
      get meta() { return JoiSchemaDescriptor.getMetas(field.schema) },
      export() {
        const exportedRules = Rules.wrap(this).export();
        const res = {
          field: this.field,
          path: this.path,
          type: this.type,
          group: this.preferences?.group,
          order: this.preferences?.order,
          help: this.preferences?.help,
          required: this.required,
          ...this.conditions,
          ...exportedRules,
          meta: this.meta,
        };
        if (this.type === "array") {
          res.items = this.items
          res.uniqueItems = true; // This it just has to do with it being displayed as a multi-select component
          if (exportedRules.maximum) {
            delete res.maximum;
            res.maxItems = exportedRules.maximum;
          }
        }
        else if (this.enum && this.enum.length !== 1 && this.enum[0] !== "") res.enum = this.enum;
        return res;
      }
    }
  },
};

const Fields = {
  flatten: (fields) => Fields.wrap(fields.reduce((a, f) => [...a, ...(f.fields ? Fields.flatten(f.fields) : [f])], [])),
  filter: (fields = [], predicate) => Fields.wrap([...fields].filter(predicate).map(f => f.fields ? ({ ...f, fields: Fields.filter(f.fields, predicate) }) : f)),
  sortBy: (fields = [], sorter) => Fields.wrap([...fields].sort(sorter)),
  // fields are described
  wrap: (fields) => {
    fields.flatten = _ => Fields.flatten(fields);
    fields.filter = _ => Fields.filter(fields, _);
    fields.allowed = _ => fields.filter(Field.isAllowed);
    fields.required = _ => fields.filter(Field.isRequired);
    fields.withMeta = _ => fields.filter(f => Field.withMeta(f, _));
    fields.sortByMeta = _ => Fields.sortBy(fields, (f1, f2, i) => _(JoiSchemaDescriptor.getMetas(f1.schema), JoiSchemaDescriptor.getMetas(f2.schema), i));
    return fields;
  }
};

const Whens = {
  get: f => f.schema.whens || [],
  wrap: field => {
    const whens = Whens.get(field);
    whens.forEach(when => {
      if (when.ref?.path) {
        when.ref.path.absolute = fromWhenRelativePathToAbsolutePath(field.path, when.ref);
      }
    });
    return whens;
  }
};

const Rules = {
  find: (field, predicate) => field.schema.rules.find(predicate),
  getMinimum: field => {
    const minRule = Rules.find(field, r => r.name === "min");
    if (minRule) return minRule.args.limit;

    const positiveRule = Rules.find(field, r => r.name === "sign" && r.args.sign === "positive");
    if (positiveRule) {
      if (field.schema.validValues?.has(0)) return 0;
      return 1;
    }
    return undefined;
  },
  getMaximum: field => {
    const maxRule = Rules.find(field, r => r.name === "max");
    if (maxRule) return maxRule.args.limit;
    return undefined;
  },
  wrap: field => {
    return {
      ...field.schema.rules,
      get minimum() { return Rules.getMinimum(field) },
      get maximum() { return Rules.getMaximum(field) },
      export() {
        return {
          minimum: this.minimum,
          maximum: this.maximum,
        }
      }
    }
  }
};

const CARDINALITIES = Object.freeze({
  ONE: "one",
  MANY: "many"
});

const fromWhenRelativePathToAbsolutePath = (absolutePath, ref) => {
  const levelsUp = ref.ancestor;
  const until = absolutePath.length - levelsUp;
  if (until < 0) throw new Error("Invalid relative path");
  return absolutePath.slice(0, absolutePath.length - levelsUp).concat(ref.path).join('.');
}

const comparatorFrom = ({flags, rules}, cardinality) => {
  if (flags.presence === "forbidden") return cardinality === CARDINALITIES.ONE ? "!=" : "!in";
  if (cardinality === CARDINALITIES.ONE && rules.some(r => r.method === "compare")) return rules.find(r => r.method === "compare")?.operator;
  return cardinality === CARDINALITIES.ONE ? "=" : "in";
}

const getConditionDataFromWhen = (when) => {
  const data = {
    id: when.ref.path.absolute,
  };
  const cardinality = when.is.allow.length > 1 ? CARDINALITIES.MANY : CARDINALITIES.ONE;

  data.comparator = comparatorFrom({flags: JoiSchemaDescriptor.getFlags(when.is), rules: JoiSchemaDescriptor.getRules(when.is)}, cardinality);
  const validValues = data.comparator.includes('>') ? [JoiSchemaDescriptor.getRules(when.is).find(r => r.method === "compare")?.args?.limit].filter(v => v !== undefined)
                                                    : Array.from(JoiSchemaDescriptor.getValidValues(when.is)) ;
  data.value = cardinality === CARDINALITIES.ONE ? validValues[0] : validValues;
  return data;
}

const buildCondition = (whenCondition, operator) => {
  const data = getConditionDataFromWhen(whenCondition);
  return {
    type: "condition",
    operator,
    conditions: [{
      operator: "and",
      data,
    }],
  };
}

const getOperatorForWhen = (when, matches) => {
  for (const operator of ["then", "otherwise"]) {
    if (matches(when[operator])) return operator === "otherwise" ? "else" : operator;
  }
  throw new Error("Could not find when operator");
}

const buildConditions = (field) => {
  const conditions = {};
  const requiredWhen = field.requiredWhen;
  const hideWhen = field.hideWhen;

  if (requiredWhen) {
    const operator = getOperatorForWhen(requiredWhen, (whenOperator) => JoiSchemaDescriptor.getFlags(whenOperator)?.presence === "required");
    const condition = buildCondition(requiredWhen, operator);
    conditions.requiredConditions = condition;
  }
  if (hideWhen) {
    const operator = getOperatorForWhen(hideWhen, (whenOperator) => JoiSchemaDescriptor.getFlags(whenOperator)?.result === "strip");
    const condition = buildCondition(hideWhen, operator);
    conditions.hideConditions = condition;
  }

  return conditions;
}

const allowDefaultsRecursive = (schema) => {
  return JoiSchemaDescriptor.getKeys(schema).map(f => f.id).reduce((s, key) => {
    // [[key]] necessary, because the key could contain dots ".", and we don't want to fork down the hierarchy
    return s.fork([[key]], e => e.type === 'object' ? allowDefaultsRecursive(e) : e);
  }, schema).prefs({ noDefaults: false });
}

const getDataWithDefaults = (schema = {}, data) => {
  const result = allowDefaultsRecursive(schema).validate(data, { abortEarly: false });
  return result.value;
}

const setDefaultsAndForcedValues = (fields, data = {}, initialValues = {}, readOnlyFields = []) => {
  for (const field of fields) {
    let values = initialValues;
    let defaultsObj = data;
    const path = Field.getPath(field);
    const key = path.pop();
    for (const elem of path) {
      values = values[elem] || {};
      defaultsObj = defaultsObj[elem] || {};
    }
    if (readOnlyFields.includes(field.field)) {
      field.isReadOnly = true;
    }
    if (defaultsObj[key] !== undefined || values[key] !== undefined) {
      if (values[key] !== undefined) {
        field.default = values[key];
      } else {
        field.default = defaultsObj[key];
      }
    }
  }
}

const setFieldsInitialValues = (schema, fields, initialValues, readOnlyFields, ignoreSchemaDefaults) => {
  const dataWithDefaults = ignoreSchemaDefaults ? initialValues : getDataWithDefaults(schema, initialValues);
  setDefaultsAndForcedValues(fields, dataWithDefaults, initialValues, readOnlyFields);
  return fields;
}

const setFieldsPopulatedDefaultOptions = (options) => {
  if (!options) options = {};
  if (!options.filter) options.filter = () => true;
  if (!options.readOnlyFields) options.readOnlyFields = [];
  return options;
}

const getWrappedFields = (schema, filter) => {
  const jsonSchema = JoiSchemaDescriptor.describe(schema);
  const fields = jsonSchema.fields.flatten().withMeta(filter).map(f => Field.wrap(f).export());
  return fields;
};

const getFieldsPopulated = (schema, initialValues, options) => {
  options = setFieldsPopulatedDefaultOptions(options);
  const fields = getWrappedFields(schema, options.filter);
  setFieldsInitialValues(schema, fields, initialValues, options.readOnlyFields, options.ignoreSchemaDefaults);
  return fields;
}

export const SchemaExtractor = {
  JoiSchemaDescriptor,
  getWrappedFields,
  getFieldsPopulated,
};

const resetWhens = (schema) => {
  const clone = schema.clone(); // In order to not modify the original schema
  if (clone.$_terms?.whens?.length) {
    clone.$_terms.whens = []
  }
  return clone;
};

export const SchemaTransformer = {
  resetWhens,
};