import './style.css';

export const Hint = ({ color = "", children }) => {

    return (
        <span
            className={`schema-form-hint ${color}`} >
            {children}
        </span>
    )
};
