const withWindow = (callback) => {
    if (!window) {
        return;
    }

    return callback;
};

export const browserDispatchEvent = withWindow(({ type, payload }) => window.dispatchEvent(new CustomEvent(type, {
    detail: payload,
})));

export const browserEventSubscribe = withWindow((type, callback) => window.addEventListener(type, ({ detail }) => callback(detail)));

export const browserOnStorageChange = withWindow((callback) => window.addEventListener('storage', ({ detail }) => callback(detail)));