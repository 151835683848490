import './style.css';

import { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { Text } from 'ui/components/Text';
import { IfHasAccess } from 'ui/hooks/useCurrentUser';
import { CustomModal } from 'ui/acukit/ModalDialog';
import { NetworkContext } from 'features/providers/NewtworkProvider';
import Spinner from '@atlaskit/spinner';

const mainClass = "action";
const ActionInternal = forwardRef(
  ({className, id, modal, children, tooltip, label, disabled, handleAction, asMenuItem, style, loading}, ref) => {
    const inputRef = useRef();
    const labelRef = useRef();
    const { offline } = useContext(NetworkContext);
    
    useImperativeHandle(ref, () => ({
      disable         : v => { inputRef.current.onclick = undefined;    v === undefined || v ? inputRef.current.classList.add('disabled')   : inputRef.current.classList.remove('disabled'); },
      enable          : v => { inputRef.current.onclick = handleAction; v === undefined || v ? inputRef.current.classList.remove('disabled'): inputRef.current.classList.add('disabled');    },
      set             : v => { labelRef.current.textContent = v },
    }));

    label = Text.resolve(label);
    const hasDisabledButton = disabled || (offline && className.includes('primary')); // define a specific class name or 'data-network=true' attribute or similar .. there may be primary actions that do not require network, so we should not disable those.

    return handleAction || modal ? (
      <div ref={inputRef} title={tooltip || undefined} id={id} onClick={hasDisabledButton ? undefined: handleAction} className={mainClass + ' ' + className + (asMenuItem ? ' menuButtonItem' : '') + (hasDisabledButton ? ' disabled' : '')} style={style}>{modal ? modal : <span ref={labelRef} className="actionContent">{loading ? <Spinner size="small" /> : children ? children : label}</span>}</div>
    ) : <></>;
  }
);

const Action = forwardRef(
  ({hidden, ...props}, ref) => {
    return hidden ? <></> : <ActionInternal ref={ref} {...props} />
  }
);

Action.Guarded = ({resource, resources=[resource], ...props}) => (
  <IfHasAccess resources={resources}>
    <Action {...props} />
  </IfHasAccess>
);

Action.AsModal = ({children, ...props}) => (
  <CustomModal btn {...props}>
    {children}
  </CustomModal>
);

export default Action;
