import {Text} from 'ui/components/Text';
import DetailsBlock from 'ui/components/DetailsBlock';

export default ({data}) => <DetailsBlock id="PositionPerHour">
    <div className="grid-col">
    <div className="title position-header"><Text>ViewTest.snore.position.title</Text></div>
    </div>
    <div className="hline fullWidth"></div>
    <div className="grid-row">
    <div className="position-row grid-col">
        <div className="position-type"><Text>module.diagnosis.be.patient_report.hour</Text></div>
        {data.map(({x: hour}, idx) => <div key={`positionHour-${idx}`} className="hour-value">{hour}</div>)}
    </div>
    <div className="position-row grid-col">
        <div className="position-type"><Text>ViewTest.position.supine</Text></div>
        {data.map(({supine}, idx) => <div key={`supineVal-${idx}`} className="hour-value">{supine?.value}{supine?.unit}</div>)}
    </div>
    <div className="position-row grid-col">
        <div className="position-type"><Text>ViewTest.position.non_supine</Text></div>
        {data.map(({non_supine}, idx) => <div key={`nonSupineVal-${idx}`} className="hour-value">{non_supine?.value}{non_supine?.unit}</div>)} {/* eslint-disable-line camelcase */}
    </div>
    </div>
</DetailsBlock>