import { Study } from "services/server/functions/model/diagnosis/model";
import { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "ui/components/Text";

const BatchStudyCancel = ({ studiesIds, studies, cancelStudyBatch, setSelectedActionResolution }) => {
    const submit = () => {
        const onProgress = (result, row) => {
            setSelectedActionResolution(prev => ({
                ...prev,
                [result.value?.aggregate?.id || studiesIds[row]]: { status: result.status === 'fulfilled' ? 'success' : 'error' },
            }));
        };

        setSelectedActionResolution({});
        return cancelStudyBatch(studiesIds, onProgress);
    };

    const amountOfCompletedStudies = studies.filter(s => Study.STATUS.compare(s.status, Study.STATUS.finished) >= 0).length;

    return <CustomModal
        id={'batch-study-cancel-confirmation-modal'}
        btn
        label={'module.diagnosis.fe.view.findStudy.actions.batch-study-cancel'}
        cancelLabel={"global.button.close"}
        appearance={'default'}
        size="sm"
        heading={'module.diagnosis.fe.view.findStudy.actions.batch-study-cancel'}
        secondaryLabel={"global.button.continue"}
        secondaryAction={amountOfCompletedStudies ? undefined : submit}
        secondaryActionOptions={{ await: true }}
    >
        <div className="confirmation-modal">
            {amountOfCompletedStudies ? Text.resolve("module.diagnosis.fe.view.findStudy.actions.batch-study-cancel.unselect-completed-studies", { amountToUnselect: amountOfCompletedStudies, plural: studiesIds.length }) : Text.resolve("module.diagnosis.fe.view.findStudy.actions.batch-study-cancel.description", { amountOfStudies: studiesIds.length, plural: studiesIds.length })}
        </div>
    </CustomModal>;
};

export default BatchStudyCancel;