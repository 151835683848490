import * as Pages from 'modules/diagnosis';

import { Study } from 'services/server/functions/model/diagnosis/model';
import { Test } from 'services/server/functions/model/diagnosis/model/Test';
import {Text} from 'ui/components/Text';
import history from 'history.js';
import moment from 'moment';

const conductedDate = (testId, test) => Study.conductedDate({[testId]: test});
const goTo = (path, state={}) => history.push(path, state);
const goToViewStudy = study => goTo(Pages.ViewStudy.PATH, {study});
const goToViewTest = (study, test, tests) => {
  const repeats = tests[test]?.repeats;
  let retry = undefined;

  if (repeats) {
    const testRepeatKeys = Object.keys(repeats);
    retry = testRepeatKeys.pop();
  }

  return goTo(Pages.ViewTest.PATH, { study, test, retry });
};const isNotCancelled = ([_, test]) => test.status !== Test.STATUS.cancelled
export default ({study, tests, test='all', onChange=id => goToViewTest(study, id, tests)}) => {
  require('./style.css');

  const options = Object.entries(tests).map(([idx, test]) => [idx, test.repeats ? Object.values(test.repeats).pop() : test]).filter(isNotCancelled)
    .map(([idx, test]) => ({ idx, date: Test.STATUS.compare(test.status, Test.STATUS.analysed) >= 0 ? conductedDate(idx, test) : undefined, status: Test.getStatusGroup(test.status) }))
    .map(test => ({ ...test, date: test.date ? moment(test.date).utcOffset(test.date).format("L") : " ", status: Text.resolve(test.status, {}, {schema: "diagnosis.Study.Test"}).toUpperCase() }))
    .map(test => ({
      label: `[${parseInt(test.idx)+1}] ${test.status} ${test.date}`, 
      value: test.idx
    }));
  
  return options.length ? 
    <div className={`testSelector hideIfSmallScreen`}>
      <select onChange={e => e.target.value === "all" ? goToViewStudy(study) : onChange(e.target.value)} value={test?.toString()}>
        {[
          {label: Text.resolve("module.diagnosis.fe.component.testSelector.sleep_study_summary"), value: 'all'}, 
          ...options
        ].map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
      </select>
    </div> : <></>;
};