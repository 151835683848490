import { GroupForm } from '../components/GroupForm';
import { generateUISchemaFormGroup, getSchemaFormProperty, getUISchemaFormId } from '../utils/formUtils';

/*
  Available props by json schema lib
    description,
    disabled,
    formData,
    idSchema,
    onAddClick,
    properties,
    readonly,
    registry,
    required,
    schema,
    title,
    uiSchema,
*/
export const ObjectFieldTemplate = (props) => {
  const {
    formData,
    properties
  } = props;
  const groups = generateUISchemaFormGroup({ properties });
  return (
    <>
      {
        groups?.map(group => {
          const groupField = getSchemaFormProperty({ properties, group });
          const key = getUISchemaFormId(groupField);
          return (
            <div key={key}>
              <GroupForm
                formData={formData}
                schemaProperties={properties}
                name={group}
              />
            </div>
          );
        })
      }
    </>
  );
};
