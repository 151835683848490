import i18n from 'services/server/functions/i18n';

import { useEffect, useState } from 'react';
import { getBaseDomainUrl } from 'services/server/functions/firebase/config/utils';

export const useAssetsURL = () => {
    const [assetsURl, setAssetsUrl] = useState();

    useEffect(() => {
        require('services/server/functions/firebase/config/config.client').onRegionChanged(
            (region, clientConfig) => {
                setAssetsUrl(
                    getBaseDomainUrl(clientConfig.assetsDomain, region)
                );
            }
        );
    });

    return {
        assetsURl,
    };
};

export const getImgUrl = (assetsURl, imgName) =>
    `${assetsURl}/images/${imgName}`;

export const getImgUrlByLang = (assetsURl, imgName) =>
    `${assetsURl}/images/${imgName}.${i18n.current}.png`;
