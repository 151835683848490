import moment from "moment";
import ArchiveIcon from '@atlaskit/icon/glyph/archive';
import PlayIcon from '@atlaskit/icon/glyph/vid-play';

import * as Authenticaton from 'modules/authentication';
import * as Administration from 'modules/administration';
import { Study } from "services/server/functions/model/diagnosis/model";
import { Test } from "services/server/functions/model/diagnosis/model/Test";
import HelpTooltip from "ui/components/HelpTooltip";
import i18n from "services/server/functions/i18n";

import '../styles.css';

const isCreatedStudyAndHasBeenDeleted = (row, selectedActionResolution) => row.status === Study.STATUS.created && row.id in selectedActionResolution;

const DATE_FORMAT = 'DD/MM/YYYY';
export const getStudyReportTableRow = (data = [], selectedActionResolution) => (data.map((s, idx) => {
    const statusGroup = Study.getStatusGroup(s.status);
    s.patientBirth = moment(s.patientBirth).format(DATE_FORMAT);
    const isDeletedStudy = isCreatedStudyAndHasBeenDeleted(s, selectedActionResolution); // really ugly hack to not allow Users to click on deleted study
    return {
        key: `study-report-row-${s.id}`,
        id: s.id,
        patientID: s.patient.id.toUpperCase(),
        statusGroup,
        lastUpdate: s.lastUpdate,
        skipAction: isDeletedStudy,
        clinicianName: <Authenticaton.ViewUser.AsText id={s.clinician?.id || s.clinician} blockOnError />, // this is necessary because there is a first query that returns multiple clinicians and if that one failts then s.clinician is not an object, but just the id
        statusView: <div className="grid-col">
        <div className={`status numTestsValue fitContent ${statusGroup}`}>
            {isDeletedStudy ? 0 : Object.values(s.tests).filter(t => Test.STATUS.compare(t.repeats ? Object.values(t.repeats).pop().status : t.status, Test.STATUS.analysed) >= 0).length} / {s.requestedTests}
        </div>
        <div className="actions-layout">
            {!isDeletedStudy && Object.values(s.tests).some(t => t.status === Test.STATUS.invalid) && <HelpTooltip type="warning" className="vcentered" width={25} height={25} id={`${idx}InvalidWarn`} content={"FindStudy.invalid_tests_tooltip"} place="left" />}
            {!isDeletedStudy && (Object.values(s.tests).some(t => t.status === Test.STATUS.cancelled || Object.values(t.repeats || {}).some(t => t.status === Test.STATUS.cancelled))) && <HelpTooltip type="cancelled" className="vcentered" width={25} height={25} id={`${idx}CancelledWarn`} content={"FindStudy.cancelled_tests_tooltip"} place="left" />}
            {s.archived && <HelpTooltip
                place="right"
                content="module.diagnosis.fe.view.findStudy.archived_legend"
                id={`${idx}Archived`}
                icon={<div className="archived-icon" ><ArchiveIcon size="medium" /></div>}
            />}
        </div>
    </div>,
    ...s
    };
}));

const activityLogTestStatusesGroups = [
    [Test.STATUS_GROUP.PENDING, Test.STATUS_GROUP.STARTED],
    [{
        status: Test.STATUS_GROUP.CANCELLED, // Actually both Invalid and Cancelled
        count: (data) => [Test.STATUS_GROUP.INVALID, Test.STATUS_GROUP.CANCELLED].reduce((acc, curr) => acc + data[curr], 0),
    }, Test.STATUS_GROUP.COMPLETED],
];

const paintTestStatuses = (testStatuses, testStatusesGroups) => {
    const result = testStatusesGroups.reduce((acc, statusGroup, index) => {
        const isLastIndex = index === testStatusesGroups.length - 1;
        statusGroup.forEach(status => {
            const key = `${status?.status || status}_${index}`;
            acc.push(
                <div key={key} className={`status ${status?.status || status} report-row-square test-status-margin`}>
                    {status?.count ? status.count(testStatuses) : testStatuses[status]}
                </div>
            );
        });
        if (!isLastIndex) acc.push(<PlayIcon key={`icon_${index}`} size="small" />);
        return acc;
    }, []);
    return result;
};

export const getActivityLogReportTableRow = (data = []) => data.map(
    (row, idx) => {
        const testStatuses = row.study.tests.status;
        return ({
            id: `${idx}-${row.study.id}`,
            key: `study-activity-log-row-${idx}`,
            studyId: row.study.id,
            studyRef: row.study.reference,
            studyCreated: moment(row.study.date).format(DATE_FORMAT),
            numberOfTests: row.study.tests.total,
            completed: row.study.analysed ? moment(row.study.analysed).format(DATE_FORMAT) : '-',
            testStatus: paintTestStatuses(testStatuses, activityLogTestStatusesGroups),
            studyStatus: <div className={`status ${row.study.status}`} >{i18n.resolve(row.study.status, {}, { schema: "diagnosis.Study.Test" })}</div>
        });
    });

const activitySummaryTestStatusesGroups = [
    [{
        status: Test.STATUS_GROUP.CANCELLED, // Actually both Invalid and Cancelled
        count: (data) => [Test.STATUS_GROUP.INVALID, Test.STATUS_GROUP.CANCELLED].reduce((acc, curr) => acc + data[curr], 0),
    }, Test.STATUS_GROUP.COMPLETED],
];

export const getActivitySummaryReportTableRow = (data = []) => data.map(
    (row, idx) => {
        const testStatuses = row.study.tests.status;
        return ({
            key: `study-summary-report-row-${idx}`,
            id: `${row.healthcaresite.id}-${row.study.requestedTests}`,
            hcsId: row.healthcaresite.id,
            hcsName: row.healthcaresite.id !== 'undefined' ? <Administration.ViewHCS.AsText id={row.healthcaresite.id} /> : <></>,
            studiesCreated: row.study.status.CREATED,
            studiesCompleted: row.study.status.FINISHED + row.study.status.REVIEWED,
            numberOfNights: row.study.requestedTests,
            testsCompleted: paintTestStatuses(testStatuses, activitySummaryTestStatusesGroups),
        });
    });