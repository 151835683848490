import * as fs from 'fs-extra';
import * as mime from 'mime-types';
import * as os from 'os';
import * as path from 'path';

import { read as readPromise, retry } from '../promises';
import {Readable} from 'stream';
import { isBrowser } from '../env';

//Conventions for storage files //TODO document
// 1.- Test files follows pattern:
const TESTFILE_REGEXP = /^\/?(?<org>[^/]+)\/(?<hcs>[^/]+)\/(?<study>[^/]+)\/((?<sequence>\d+)\/)?((?<sensorId>[^_]+)_(?<datetime>\d{8}_\d{4}))?(?<extension>.+)$/;
// OBS: 'sequence' may be undefined for very old studies
// OBS: 'sensorId' and 'datetime' may be undefined for acucore generated files for "old" studies too

const StudyOwnersFromFile = async (file) => {
  const { org, hcs, study, sequence=0, sensorId, datetime } = TESTFILE_REGEXP.exec(file.name).groups;

  let test = sequence;
  if (sensorId !== undefined) { // all acucore generated files will also contain the same prefix as the mobile uploaded files. If sensorId is undefined, it means and old study which follows a different format
    // this is a workaround to figure out whether the file belongs to a repeated test and which repetition actually is.
    const retryIndex = (await file.bucket.getFiles({prefix: path.dirname(file.name) }).then(data => data[0]))
                      .map(f => TESTFILE_REGEXP.exec(f.name)?.groups).filter(Boolean)
                      .filter((f, idx, all) => f.datetime && (idx === all.findIndex(rec => rec.datetime === f.datetime && rec.sensorId === f.sensorId)))
                      .map(f => ({ ...f, timeCreated: parseInt(f.datetime.replace('_', ''))}))
                      .sort((f1, f2) => f1.timeCreated - f2.timeCreated)
                      .findIndex(f => f.sensorId === sensorId && f.datetime === datetime);
    
    test = `${sequence}-${retryIndex}`;
  }

  return [
    `urn:com:acurable:apsa:aggregate:diagnosis/Study/${study}/Test/${test}`,
    `urn:com:acurable:apsa:aggregate:diagnosis/Study/${study}`,
    `urn:com:acurable:apsa:aggregate:administration/HealthcareSite/${hcs}`,
    `urn:com:acurable:apsa:aggregate:administration/Organisation/${org}`
  ]
};

// 1.- devices files follow pattern '/devices/batch/YYY-MM-DD/{file}'
const BatchOwnersFromFile = (filepath) => {
  const [ batch ] = filepath.match(/^\/?devices\/batch\/([^/]+)\/.*$/).slice(1)
  return [
    `urn:com:acurable:apsa:aggregate:devices/Batch/${batch}`
  ]
};

const makeStorage = (storage, config) => {
  config ??= require('./config').config;

  const OwnersFromFilePath = (bucketName) => {
    let resolver = _ => [];
    if      (bucketName === config.storageBucket) resolver = StudyOwnersFromFile;
    else if (bucketName === config.assetsBucket)  resolver = BatchOwnersFromFile;
    
    return resolver;
  }

  return Object.freeze({
    bucket,
    backups,
    assets
  });

  function bucket(name=config.storageBucket, basePath="/") {
    if (arguments.length === 1 && path.isAbsolute(name)) {
      basePath = name;
      name     = config.storageBucket;
    }
    return makeBucket(storage.bucket(name), name, basePath);
  }  
  
  function backups(basePath) {
    const backupBucket = bucket(config.backupBucket, basePath);

    return {
      backup,
      ...backupBucket
    }

    function backup(resource, id=require('ulid').monotonicFactory()(), version=config.appVersion, { contentType='application/json', ...metadata }={}) {  
      if (!resource || !id) {
        throw Error('missing required argument "resource" or "id"');
      }
      
      return { read, write }
      
      async function write(readable) {
        if (readable instanceof Readable) {  
          const pipeline = require('util').promisify(require('stream').pipeline);
          const file = await backupBucket.file(`${version}/${id}/${resource}/${version}_${id}.gz`).get();
  
          console.info(`Writing backup '${resource}' with id '${id}' and version '${version}' to: '${file.path}'`);
          return pipeline(readable, require('zlib').createGzip(), file.stream.forWrite({ contentEncoding: 'gzip', contentType, metadata }));
  
        } else if (readable instanceof Function) {
          const file = backupBucket.file(`${version}/${id}/${resource}/na.x`); // VERY UGLY:
          console.info(`Writing backup '${resource}' with id '${id}' and version '${version}' to '${file.bucket.uri}'`);
          return readable(file.bucket); // UGLY: easy hack to get a bucket reference to a subfolder
  
        }
  
        return undefined; // Should never reach this point
      }
  
      async function read(restore) {
        const file = backupBucket.file(`${version}/${id}/${resource}/${version}_${id}.gz`);
  
        if (await file.exists()) {
          console.info(`Restoring backup '${resource}' with id '${id}' and version '${version}' from: '${file.uri}'`);
          const pipeline = require('stream').pipeline;
          const readable = pipeline((await file.get()).stream.forRead(), require('zlib').createGunzip(), (e) => { e && require('../errors').reportError(e); });
  
          return (restore instanceof Function) ? restore(readable) : readable;
        } 
  
        // restore is mandatory in this case and it must be a function
        console.info(`Restoring backup '${resource}' with id '${id}'  and version '${version}' from '${file.bucket.uri}'`);
        return restore(file.bucket);
      }
    }
  }

  function assets(basePath) {
    const assetsBucket = bucket(config.assetsBucket, basePath);
    return { 
      config: configBucket,
      ...assetsBucket
    }

    function configBucket() {
      return {
        ...assetsBucket.file('/config/web.json').bucket,
        web(options) { return this.file('web.json').json(options); } // probably file name should not be hardcoded here ? and it should not default to "empty"
      }
    }
  }

  function makeBucket(fireBucket, bucketName, basePath) { 
    return Object.freeze({
      getFiles,
      clone,
      file,
      exists,
      name: bucketName, // TODO: there should be a way to get bucketName from fireBucket: metadata?
      upload,
      path: basePath,
      uri: `gs://${path.join(bucketName, basePath)}`,
      deleteFiles
    });
  
    async function getFiles(query) {
      return fireBucket.getFiles({prefix: basePath.replace(/^\//, ''), ...query}).then(result => result[0].map(f => file(f.name)));
    }

    async function deleteFiles(query) {
      return new Promise((resolve, reject) => fireBucket.deleteFiles({...query, prefix: basePath.replace(/^\//, '')}, err => !err ? resolve() : reject(err)));
    }
  
    async function clone(localFolderName=path.basename(basePath), localBasePath=fs.mkdtempSync(`${os.tmpdir()}${path.sep}`)) {
      const _localPath = path.join(localBasePath, localFolderName); fs.mkdirSync(_localPath);
      const files  = await Promise.all((await getFiles()).map(p => p.get().then(f => f.clone(path.join(_localPath, f.name)))));
      const cloned = files.reduce((d, f) => ({...d, [f.name]: f }), {});
      const updated = {};
      
      return Object.freeze({
        path: _localPath,
        get,
        create,
        ls,
        push,
        clean
      });
  
      function ls() {
        return fs.readdirSync(_localPath).map(fname => cloned[fname] || file(fname).create());
      }
  
      function clean() {
        return Promise.all(ls().map(f => f.unlink().catch(() => {})));
      }
  
      async function push(options={}) {
        const results = await Promise.all(ls().filter(f => !options.exclude || options.exclude.every(p => !f.name.match(p))).filter(f => updated[f.name] || !cloned[f.name]).map(f => f.push().catch(e => e))); // only makes sense to push new/modified files.
        const errors  = results.filter(e => e instanceof Error);
        if (errors.length) {
          throw new Error(`Unable to push some files from ${_localPath} to ${bucketName}/${basePath}. Errors: ${JSON.stringify(errors)}`)
        }
        return results;
      }
  
      async function create(fileObj, truncate=true) {
        if (truncate && cloned[fileObj.name]) {
          console.warn(`[create] file ${fileObj.name} exists. It will be truncated ...`)
          await cloned[fileObj.name].remove().catch(() => {});
        }
  
        cloned[fileObj.name] = await fileObj.create(path.join(_localPath, fileObj.name));
        updated[fileObj.name] = cloned[fileObj.name];
        return cloned[fileObj.name];
      }
  
      function get(fileObj) {
        return cloned[fileObj.name];
      }
    }
  
    function file(name) {
      const filepath = (path.isAbsolute(name) || name.replace(/^\//, '').startsWith(basePath.replace(/^\//, ''))) ? name : path.join(basePath, name);
      return makeFile(fireBucket, bucketName, fireBucket.file(filepath.replace(/^\//, '')));
    }
  
    function exists(files) {
      if (Array.isArray(files)) // TODO: check .every(instanceof File) ??
        return Promise.all(files.map(f => f.exists())).then(all => all.every(Boolean));
      
      return exists([files]);
    }
  
    async function upload(localPath, destPath, metadata={}) {
      if (!await fs.exists(localPath)) return Promise.reject(new Error(`[storage] cannot upload '${localPath}' not file/directory found`));
      
      const localPathStat = await fs.lstat(localPath);
      return (await localPathStat.isFile()) ? 
        fireBucket.upload(localPath, { destination: path.join(destPath ? destPath : '/', path.basename(localPath)), metadata: { metadata } }) // Upload single file
        : new Promise((resolve, reject) => require('klaw').walk(localPath)
            .on('data', async item => {
              const itemStat = await fs.lstat(item.path);
              if (await itemStat.isFile()) {
                const destination = path.join(destPath ? destPath : '/', path.relative(await fs.realpath(localPath), item.path));
                await fireBucket.upload(item.path, { destination, metadata: { metadata } });
              }
            })
            .on('error', reject)
            .on('end',resolve)
          );
    }
  }
  
  function makeFile(fireBucket, bucketName, fireFile) { 
    const uri      = `gs://${path.join(bucketName, fireFile.name)}`;
    let _localPath = undefined;
    let _islocal   = false;
    let _metadata  = undefined;
    let _content   = undefined;

    return Object.freeze({
      bucket: makeBucket(fireBucket, bucketName, path.dirname(fireFile.name)),
      get owners() { return OwnersFromFilePath(bucketName)(fireFile); },
      create,
      remove,
      move,
      exists,
      get,
      json: async (options) => await (await get()).asJson(options),
      text: async (options) => await (await get()).asText(options),
      name: path.basename(fireFile.name),
      path: fireFile.name,
      uri,
      signedUrl: _ => fireFile.getSignedUrl({ action: 'read', expires: Date.now() + 3600000 }).then(signedUrls => signedUrls[0]),
      waitFor,
      waitForExists,
      write
    });
  
    async function get() {
      return Object.freeze({
        ...(await getMetadata()),
        name: path.basename(fireFile.name),
        path: fireFile.name,
        clone,
        asJson,
        asText,
        asBuffer,
        stream: {
          forRead: createReadStream, //TODO: only if file exists we should allow it
          forWrite: createWriteStream,
        },
        updateMetadata,
        getMetadata: () => _metadata
      });
    }
  
    async function write(payload) {
      if (_islocal) {
        console.debug(`[write] file '${_localPath}'.`)
        await fs.writeFile(_localPath, payload);
      } else {
        console.debug(`[write] remote file '${fireFile.name}'.`)
        await fireFile.save(payload);
      }

    }
  
    function create(localPath) {
      _islocal   = true;
      _localPath = localPath || _localPath || path.join(fs.mkdtempSync(`${os.tmpdir()}${path.sep}`), path.basename(fireFile.name))
      return Object.freeze({
        name: path.basename(fireFile.name),
        path: _localPath,
        exists,
        push,
        stream: {
          forWrite: createWriteStream,
        },
        unlink,
      });
    }
  
    async function move(dest) {
      if (_islocal) {
        console.debug(`[move] file '${_localPath}' moved locally to ${dest}.`)
        await fs.move(_localPath, dest);
      } else {
        console.debug(`[move] file '${fireFile.name}' moved remotelly to ${dest}.`)
        await fireFile.move(dest);
      }
    }
  
    async function remove() {
      if (_islocal) {
        console.debug(`[remove] file '${_localPath}' removed locally.`)
        fs.unlinkSync(_localPath)
      } else {
        console.debug(`[remove] file '${fireFile.name}' removed remotelly.`)
        await fireFile.delete();
      }
    }
  
    async function clone(localPath) {
      create(localPath);
      console.debug(`[clone] downloading '${fireFile.name}' to ${_localPath}).`); 
      await fireFile.download({destination: _localPath});  
      return Object.freeze({
        ...(await getMetadata()),
        path: _localPath,
        name: path.basename(_localPath),
        remove,
        push,
        asJson,
        asText,
        asBuffer,
        stream: {
          forRead: createReadStream,
          forWrite: createWriteStream,
        },
        updateMetadata: updateMetadata,
        unlink,
      });
    }
  
    async function push(options={}) {
      const { metadata: newmeta } = options;
      const contentType = options.contentType || mime.lookup(fireFile.name) || 'application/octet-stream';
      const contentDisposition = options.contentDisposition || 'inline';
      const toValidFileMetadata = (metadata) => metadata && Object.entries(metadata).reduce((newMeta, [key, value]) => { newMeta[key] = Array.isArray(value) ? value.join(',') : value instanceof Object ? JSON.stringify(value) : value; return newMeta; }, {});
      
      console.debug(`[push] uploading '${_localPath}' to ${fireFile.name}.`); 
      await fireBucket.upload(_localPath, { destination: fireFile.name, metadata: {contentType, contentDisposition, metadata: toValidFileMetadata(newmeta)}} );
      console.debug(`[push] uploaded file ${fireFile.name}.`); 
      await waitForExists();
      _metadata = _content = undefined; // force refresh ...
      return get();
    }
  
    async function exists() {
      return _islocal ? fs.existsSync(_localPath) : (await fireFile.exists().then(b => b[0])); // TODO: it could return directly get() when b=true, get() will evaluate to true anyway, ?? 
    }
  
    async function asJson(options={}) {
      options.encoding ??= 'utf8';
      if (typeof options.defaultValue === "object" && !(typeof options.defaultValue === "string" || options.defaultValue instanceof String))
        options.defaultValue = JSON.stringify(options.defaultValue);

      return JSON.parse(await asText(options), options.replacer, options.space);
    }
  
    async function asText(options={}) {
      options.encoding ??= 'utf8';
      return await getContent(buffer => buffer.toString(options.encoding), options)
    }
  
    async function asBuffer() {
      const filePath = (_islocal) ? _localPath : fireFile.name;
      console.debug(`[load] loading content of file ${filePath} (local: ${_islocal}).`);
      return await readPromise(createReadStream());
    }
    
    async function getContent(parser, options={}) {
      if (await exists())
        return _content || (_content = parser(await asBuffer()));

      else if (options.defaultValue)
        return options.defaultValue
      
      throw new Error(`File not found '${uri}'`);
    }
  
    function createReadStream(options) {
      console.debug(`[createReadStream] opening read stream for '${(_islocal) ? _localPath : fireFile.name}'.`); 
      return (_islocal) ? fs.createReadStream(_localPath, options) : fireFile.createReadStream();
    }
  
    function createWriteStream({contentType, contentDisposition, contentEncoding, metadata, ...options}) {
      const opts = JSON.parse(JSON.stringify({contentType, contentDisposition, contentEncoding, metadata, ...options})); // without undefined
      console.debug(`[createWriteStream] opening write stream for '${(_islocal) ? _localPath : fireFile.name}'.`, opts);
      return (_islocal) ? fs.createWriteStream(_localPath, {...options}) : fireFile.createWriteStream(opts);
    }
  
    async function getMetadata() {
      if (_metadata) {
        return _metadata;
      }
  
      _metadata = {};
      if (await exists()) {
        console.debug(`[getMetadata] retrieving metadata for '${fireFile.name}'.`); 
        const { id, size, md5hash, metadata: meta } = (await fireFile.getMetadata())[0];
        _metadata = { id, size, md5hash, metadata: meta };
      }
      return _metadata;
    }
  
    async function waitFor(condition) {
      return retry(() => condition(fireFile), Boolean);
    }
  
    async function waitForExists() {
      return waitFor(exists);
    }
  
    async function updateMetadata(metadata, cleanCurrentMetadata=false) {
      const newmeta = cleanCurrentMetadata ? metadata : {...(await getMetadata())?.metadata, ...metadata};
      console.debug(`Updating file '${fireFile.name}' metadata.`, newmeta);    
      await waitForExists(); // it's supposed to exists, but it may not be immediately available
      await fireFile.setMetadata({ metadata: newmeta });
      return newmeta;
    }
  
    async function unlink() {
      return new Promise((resolve) => {
        if (fs.existsSync(_localPath)) {
          fs.unlinkSync(_localPath);
          fs.removeSync(path.dirname(_localPath));
          console.debug(`[rmtemp] deleted temp file '${_localPath}'.`); 
        }
        _islocal = false;
        resolve();
      });
    }
    
  }
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
const Storage = (() => {
  let _this;

  return new Proxy({
    create(storage, options) { // real service
      if (isBrowser() && !storage) {
        require('firebase/compat/storage');
        storage ??= { bucket: (name) => require('../../../firebase').app.storage(name) };
      }
      
//////////////////////
/////////////////////////////////////
////////////////////////////////////////////////////////////
///////
////////////////

      _this = makeStorage(storage, options?.config)
      return _this;
    },
    createNull(filesystem, options={}) { // mocked service
      const storage = require('./storage.test.nullable').Storage(filesystem, options);
      _this = makeStorage(storage, options.config);
      return _this;
    }
  }, {
    get(target, prop) { // proxy to delegate to any other Storage function
      _this ??= target.create();
      return prop.startsWith('create') ? target[prop] : _this[prop];
    }
  });

})();

export { Storage }