import administration, { createItems as adminCreateItems } from './administration/navigation';
import authentication, { createItems as authCreateItems } from './authentication/navigation';
import devices, { createItems as devicesCreateItems } from './devices/navigation';
import diagnosis, { createItems as diagnosisCreateItems } from './diagnosis/navigation';
import support from './support/navigation';

export const createItems = (props) => [
  ...adminCreateItems(props),
  ...authCreateItems(props),
  ...devicesCreateItems(props),
  ...diagnosisCreateItems(props),
];

export default (props) => [
  ...administration(props),
  ...authentication(props),
  ...devices(props),
  ...diagnosis(props),
  ...support(props),
];
