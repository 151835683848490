import { FORBIDDEN_POLICY, UNIQUE_FIELD_POLICY, when } from '../../policies';
import { Joi } from '../../../validation/rules';

import Config from '../config';

import { Roles } from '../../../iam/roles';

import { withDefaults } from '../..';
import { checkRoleRelationships } from './policies';

let Role = withDefaults()({
  context: Config.context,
  name   : 'Role',
  schema : Joi.object().keys({
    label      : Joi.string(),
    description: Joi.string(),
    includes   : Joi.array().items(Joi.string().uri()).min(1).unique()
  }),
  events: {
    ROLE_CREATED : { },
    ROLE_UPDATED : { snapshot: (e, s) => ({ data: { includes: (e.data.includes || []).length ? e.data.includes : s.data.includes }}) },
    ROLE_DELETED: { snapshot: () => ({ metadata: { deleted: true }}) }
  }
});

Role = withDefaults()({
  ...Role,
  commands: {
    CREATE_ROLE: {
      checkPolicies: (role, prevRole) => when(prevRole !== undefined || !Roles.isCustomRole(role.id)).rejectWith(UNIQUE_FIELD_POLICY(`Role already exists with the same unique identifier.`)),
      roles : [Roles.superAdmin.id],
      schema: Role.schema.fork(['id', 'label', 'includes'], schema => schema.required()),
      event : Role.events.ROLE_CREATED
    },
    UPDATE_ROLE: {
      checkPolicies: (role) => Promise.all([
        when(role.includes.includes(role.id)).rejectWith(FORBIDDEN_POLICY('Cannot include the own role to the list of role permissions')),
        when(!Roles.isCustomRole(role.id)).rejectWith(FORBIDDEN_POLICY('Permissions and system roles can not be edited'))
      ]),
      roles : [Roles.superAdmin.id],
      schema: Role.schema.fork(['id'], schema => schema.required()).or('label', 'description', 'includes'),
      event : Role.events.ROLE_UPDATED
    },
    DELETE_ROLE: {
      checkPolicies: (role, _, executor) => Promise.all([when(!Roles.isCustomRole(role.id)).rejectWith(FORBIDDEN_POLICY('Permissions and system roles can not be deleted')), checkRoleRelationships(role.id, executor)]),
      roles : [Roles.superAdmin.id],
      schema: Role.schema.fork(['id'], schema => schema.required()),
      event : Role.events.ROLE_DELETED
    },
  },
  queries: {
    EXISTS: {
      schema: Joi.object().keys({ id: Joi.string().uri(), label: Joi.string() }).or('id', 'label'),
      newRequest: (role, metadata) => ({
        action   : Role.queries.EXISTS.new(role, metadata),
        depends  : Role.queries.LIST.newRequest({where: {field: 'data.label', operator: '==', value: role.label}}), // before was using r.data.label.localeCompare(role.label, undefined, { sensitivity: 'base' }) === 0
        // Should we include system roles and special custom role: administrator?
        transform: ([roles]) => roles.filter(r => r.data.id !== role.id).length !== 0
      })
    },
    GET_ROLE_PARENTS: {
      schema: Joi.object().keys({ id: Joi.string().uri() }),
      newRequest: (role, metadata) => ({
        action   : Role.queries.GET_ROLE_PARENTS.new(role, metadata),
        depends  : Role.queries.LIST.newRequest({where: {field: 'data.includes', operator: 'array-contains', value: role.id}}),
        transform: ([roles]) => roles.filter(r => r.data.id !== role.id)
      })
    },
    GET_ROLE_ANCESTORS: {
      schema: Joi.object().keys({ id: Joi.string().uri() }),
      newRequest: ({ id }, metadata) => ({
        action   : Role.queries.GET_ROLE_ANCESTORS.new({ id }, metadata),
        depends  : Role.queries.LIST.newRequest(),
        transform: ([roles]) => {
          const parentsOf   = (role) => roles.filter(r => r.data.id !== role.data.id && r.data.includes.includes(role.data.id));
          const ancestorsOf = (role) => parentsOf(role).reduce((ancestors, r) => [...ancestors, r, ...ancestorsOf(r)], []);

          return ancestorsOf(roles.find(r => r.data.id === id));
        }
      })
    }
  }
});

export default Role;