import { getTemplate, getUiOptions } from "@rjsf/utils";
import { TitleField } from "../components/TitleField";
import { getUISchemaFormLabel } from "../utils/localizationUtils";


export const FieldTemplate = (props) => {
    const { id, label, children, errors, help, description, hidden, required, displayLabel, registry, uiSchema, schema } = props;

    if (hidden) {
        return <div className='hidden'>{children}</div>;
    }

    const uiOptions = getUiOptions(uiSchema);
    const formContext = registry?.formContext || {};
    const { title } = getUISchemaFormLabel({ uiSchema, formContext, label });
    const WrapIfAdditionalTemplate = getTemplate(
        'WrapIfAdditionalTemplate',
        registry,
        uiOptions
    );
    const hasTitle = title && displayLabel && !schema?.format;
    const classNames = hasTitle ? props?.classNames : `${props?.classNames}`.replaceAll("string", " ");
    return (
        <WrapIfAdditionalTemplate {...props} classNames={classNames}>
            {hasTitle &&
                <TitleField id={id} required={required}>
                    {title}
                </TitleField>
            }
            {displayLabel && description ? description : null}
            {children}
            {errors}
            {help}
        </WrapIfAdditionalTemplate>
    );
};
