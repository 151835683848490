import { config } from "../../../firebase";
import { Organisation, HealthcareSite, Product } from "../../administration/model";
import { VALID_DATA_POLICY, FORBIDDEN_POLICY, NOT_EXIST_POLICY } from "../../policies";
import AdministrationConfig from "../../administration/config";
import { validate } from "../../administration/model/attributeRights";
import { User } from "../../authentication/model";
import { Study } from "../../diagnosis/model";

export const checkClinicianOwnsSite = async (exec, clinicianId, siteId) => {
    const clinicianSnap = await exec(User.queries.GET.newRequest({ id: clinicianId }));
    if (!clinicianSnap) throw NOT_EXIST_POLICY("The given clinician does not exist");
    if (!clinicianSnap.data.owners.includes(siteId)) throw FORBIDDEN_POLICY("The clinician provided is missing ownership of the given Healthcare Site");
};

export const checkClinicianOwnsSiteUpdate = async (exec, reqClinicianId, currentClinicianId, siteId) => {
    if (!reqClinicianId || reqClinicianId === currentClinicianId) return true;
    return checkClinicianOwnsSite(exec, reqClinicianId, siteId);
};

const getSiteCompatibleProductIds = async (exec, siteId) => {
    const hcsSnap = await exec(HealthcareSite.queries.GET.newRequest({ id: siteId }));
    if (hcsSnap.data.products?.length) return hcsSnap.data.products;
    const orgaId = Organisation.ownersFrom(hcsSnap.data).filter(id => id !== AdministrationConfig.organisation.root.id)[0];
    const orgaSnap = await exec(Organisation.queries.GET.newRequest({ id: orgaId }));
    return orgaSnap.data.products || [];
};

/* IMPORTANT: sets default value of productId in request!!
- For requests with versions <2.0.0 it's always optional and defaults to sa100 or the first product found for the site provided
- For requests with versions >= 2.0.0 it's only optional if the site of the request can only be assigned to one product, otherwise it's required
*/
const checkAndSetStudyProductValue = async (exec, study, apiVersion) => {
    const { compare } = require("../../../executor/version");
    const compatibleProductIds = await getSiteCompatibleProductIds(exec, study.site);
    const requireProductId = !compatibleProductIds.length || (compare(apiVersion, '2.0.0') >= 0 && compatibleProductIds.length > 1);
    if (requireProductId && !study.productId) throw VALID_DATA_POLICY("A productId must be provided");
    if (!study.productId) { // Sets default
        if (compatibleProductIds.includes(AdministrationConfig.products.sa100.id)) study.productId = AdministrationConfig.products.sa100.id;
        else study.productId = compatibleProductIds[0];
    }
    return [study.productId, study.site];
};

export const checkStudyProduct = async (exec, study, apiVersion) => {
    const [productId, siteId] = await checkAndSetStudyProductValue(exec, study, apiVersion);
    const productExists = await exec(Product.queries.ID_EXISTS.newRequest({ id: productId }));
    if (!productExists) throw NOT_EXIST_POLICY("The given product does not exist");
    const site = await exec(HealthcareSite.queries.GET.newRequest({ id: siteId }));
    if (!site) throw NOT_EXIST_POLICY("The given healthcareSite does not exist");

    const organisationIds = Organisation.ownersFrom({ owners: site.data.owners });
    // root orga has access to all products (even though we don't store the products in its document)
    if (organisationIds.includes(AdministrationConfig.organisation.root.id)) return;

    const organisations = await exec(Organisation.queries.LIST.newRequest({ where: { field: "data.id", operator: "in", value: organisationIds } }));
    const userHasAccessToProduct = organisations.some(o => (o.data.products || []).includes(productId));
    if (!userHasAccessToProduct) {
        console.error("The given healthcaresite organisation is missing access to the given product", siteId)
        throw FORBIDDEN_POLICY("The given healthcaresite organisation is missing access to the given product");
    }
}

export const checkOwnerIdHasProduct = async (exec, ownerId, productId) => {
    if (HealthcareSite.isReference(ownerId)) {
        const site = await exec(HealthcareSite.queries.GET.newRequest({ id: ownerId }));
        if (!site) throw NOT_EXIST_POLICY("The given healthcareSite does not exist");
        const orgaId = Organisation.ownersFrom(site.data)[0];
        const orga = await exec(Organisation.queries.GET.newRequest({ id: orgaId }));
        if (!(orga.data.products || []).includes(productId)) throw VALID_DATA_POLICY('The given product is not associated to the given ownerId');
    }
};

// on create study there is no study only studyRequestData
export const checkAttributeRights = async (study, studyRequestData, userId) => {
////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////
///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////
};

export const checkApplySignaturePolicies = async (reqData, existingStudy, userSnap, executor) => {
    if (!existingStudy) throw NOT_EXIST_POLICY(`Study ${reqData.id} does not exists`);
    if (Study.STATUS.compare(existingStudy.data.status, Study.STATUS.finished) < 0) throw VALID_DATA_POLICY(`Only studies with status '${Study.STATUS.finished}' can be signed`);
    if (!userSnap.data.owners.some(o => existingStudy.metadata.allOwners.includes(o))) throw VALID_DATA_POLICY(`Cannot sign a study that does not belong to you`);
    
    const preferenceId = User.entities.Preferences.newURN(userSnap.aggregate.id.split("/").pop(), "Signature");
    const userSignature = await executor.execute(User.entities.Preferences.queries.GET.newRequest({ id: preferenceId }));
    if (!userSignature?.data?.defaults?.signature) throw NOT_EXIST_POLICY(`You don't have a signature. Please upload a signature before signing a Study`);
};

export const checkDeleteSignaturePolicies = async (reqData, existingStudy, userSnap) => {
    if (!existingStudy) throw NOT_EXIST_POLICY(`Study ${reqData.id} does not exists`);
    if (!userSnap.data.owners.some(o => existingStudy.metadata.allOwners.includes(o))) throw VALID_DATA_POLICY(`Cannot delete the signature of a study that does not belong to you`);
};

//! should be required if we are going to cancel the study, but... we don't really know at this point as it will be done by the event handler
export const checkCancelReason = (reqData, userSnap) => {
    if (reqData.cancel?.reason === Study.CANCEL_REASON.REPEATED_STUDY && !userSnap.isFromAcurable()) {
        throw FORBIDDEN_POLICY(`Your user does not have permission to assign the cancel reason '${Study.CANCEL_REASON.REPEATED_STUDY}'`);
    }
};