import { useCallback } from "react";

import Details from "ui/components/Details";
import { AutoComplete } from "features/components/AutoComplete";
import { FiltersType } from "../../utils";

export const NumberOfTestsField = ({ filters, onFilterChange, items }) => {
    const handleChange = useCallback((option) => onFilterChange({ [FiltersType.NumberOfTests]: option?.value }), [onFilterChange]);

    return (
        <Details id="testDetails" label="global.number_of_tests">
            <AutoComplete
                id={FiltersType.NumberOfTests}
                items={items}
                value={filters[FiltersType.NumberOfTests]}
                handleChange={handleChange}
                withDefault={false}
                selectProps={{
                    isMulti: false,
                    isClearable: true,
                }}
            />
        </Details>
    );
};