const applyAnd = (filters = []) => (andFilter) => {
    if (!andFilter.length) return filters;
    return (filters.length ? filters : [[]]).map(f => [...f, andFilter]);
};

export const applyAnds = (filters = [[]]) => (andFilters) => {
    if (!andFilters.length) return filters.filter(disjunc => disjunc?.length);
    return andFilters.reduce((acc, curr) => applyAnd(acc)(curr), filters);
};

export const applyOrs = (filters = [[]]) => (orFilters) => {
    if (!orFilters.length) return filters;

    return (orFilters.length ? orFilters : [[]]).reduce((acc, curr) => {
        (filters.length ? filters : [[]]).forEach(f => {
            const newQuery = [...f, ...curr];
            if (newQuery.length) acc.push(newQuery);
        })
        return acc;
    }, []);
};