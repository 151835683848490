import { Contact } from 'modules/support';

import DetailsBlock from 'ui/components/DetailsBlock';
import { Text } from 'ui/components/Text';
import { getImgUrlByLang, useAssetsURL } from 'ui/hooks/useAssetsUrl';

const Question = () => {
    const { assetsURl } = useAssetsURL();

    return (
        <>
            {assetsURl && (
                <div className="pageSection">
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <span>
                                <Text
                                    variables={{
                                        contactLink: (
                                            <Contact.AsLink
                                                id="contactLink"
                                                text="Help.report-system-problem.contact-link"
                                            />
                                        ),
                                    }}>
                                    Help.report-system-problem.answer-1
                                </Text>
                            </span>
                            <span>
                                <Text
                                    variables={{
                                        contactLink: (
                                            <Contact.AsLink
                                                id="contactLink"
                                                text="Help.report-system-problem.contact-link"
                                            />
                                        ),
                                    }}>
                                    Help.report-system-problem.answer-2
                                </Text>
                            </span>
                        </div>
                        <div className="largeInstructionImg">
                            <img
                                alt=""
                                src={getImgUrlByLang(
                                    assetsURl,
                                    'contact-menu-item'
                                )}
                            />
                        </div>
                    </DetailsBlock>
                </div>
            )}
        </>
    );
};

Question.question = Text.resolve('component.faq.how_report_problem_message');

export default Question;
