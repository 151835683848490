import * as Pages from '.';
import { HealthcareSite, Organisation, isFromRoot } from 'services/server/functions/model/administration/model';
import HealthcareIcon from '@atlaskit/icon/glyph/department';
import OrganisationIcon from '@atlaskit/icon/glyph/office-building';

import {Text} from 'ui/components/Text';

export const createItems = ({ currentUser }) => {
  var items = [];
  
  if (currentUser.hasAccess(Pages.CreateOrganisation)) {
    items.push({
      icon: <OrganisationIcon />,
      text: Text.resolve('global.organisation.label'),
      link: Pages.CreateOrganisation.PATH,
      id: 'createOrganisation'
    });
  }

  if (currentUser.hasAccess(Pages.CreateHCS)) {
    items.push({
      icon: <HealthcareIcon />,
      text: Text.resolve('hcs'),
      link: Pages.CreateHCS.PATH,
      id: 'createHCS'
    });
  }

  if (currentUser.hasAccess(Pages.CreateTrial)) {
    items.push({
      icon: <Pages.ViewTrial.Icon />,
      text: Text.resolve('trial'),
      link: Pages.CreateTrial.PATH,
      id: 'createTrial'
    });
  }

  return items;
};

export default ({ currentUser }) => {
  var items = [];

  const userOrganisations    = Organisation.ownersFrom(currentUser?.data);
  const canListOrganisations = (isFromRoot(currentUser) || userOrganisations.length > 1) && currentUser.hasAccess(Pages.FindOrganisation);

  const orgPage              = canListOrganisations ? Pages.FindOrganisation : userOrganisations.length === 1 ? Pages.MyOrganisation : undefined;
  if (orgPage && currentUser.hasAccess(orgPage)) {
    items.push({
      icon: <OrganisationIcon />,
      text: canListOrganisations ? Text.resolve('global.organisation.label-plural') : Text.resolve('my-organisation'),
      link: orgPage.PATH,
      id  : canListOrganisations ? 'findOrganisations' : 'myOrganisation',
      root: Organisation.name.toLowerCase()
    });
  }

  const userSites    = HealthcareSite.ownersFrom(currentUser?.data);
  const canListSites = currentUser.hasAccess(Pages.CreateHCS) || (userSites.length > 1 && currentUser.hasAccess(Pages.FindHCS));
  const hcsPage      = canListSites ? Pages.FindHCS : userSites.length === 1 ? Pages.ViewHCS : undefined;
  if (hcsPage && currentUser.hasAccess(hcsPage)) {
    items.push({
      icon: <HealthcareIcon />,
      text: canListSites ? Text.resolve('hcss') : Text.resolve('my-hcs'),
      link: hcsPage.PATH,
      id  : `${canListSites ? 'find' : 'view'}HCS`,
      root: HealthcareSite.name.toLowerCase()
    });
  }

  const userTrials    = Organisation.entities.Trial.ownersFrom(currentUser?.data);
  const canListTrials = currentUser.hasAccess(Pages.CreateTrial) || (userTrials.length > 1 && currentUser.hasAccess(Pages.FindTrial));
  const trialPage     = canListTrials ? Pages.FindTrial : Pages.ViewTrial;
  if (currentUser.hasAccess(trialPage) && (currentUser.hasAccess(Pages.CreateTrial) || userTrials.length > 0)) {
    items.push({
      icon: <Pages.ViewTrial.Icon />,
      text: canListTrials ? Text.resolve('trials') : Text.resolve('my-trial'),
      link: trialPage.PATH,
      id  : `${canListTrials ? 'find' : 'view'}Trial`,
      root: Organisation.entities.Trial.name.split('/').pop().toLowerCase()
    });
  }
  
  return items;
};
