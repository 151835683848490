import { useState } from 'react';
import { CustomModal } from 'ui/acukit/ModalDialog';
import {Text} from 'ui/components/Text';
import {ValidatedInput} from 'ui/components/Input';
import { equalsTo } from 'services/server/functions/validation/rules';
import Spinner from '@atlaskit/spinner';
import useCurrentUser from 'ui/hooks/useCurrentUser';

const ForbiddenDeleteModal = (props) => {
  const [currentUser] = useCurrentUser();
  const resourceItem = (key, counter, page) => counter > 0 && <li key={`resourceItem${key}`} onClick={() => currentUser.hasAccess(page) && require('history.js').default.push(page.PATH, {[props.linkVariable || "id"]: props.entity.id})} className={currentUser.hasAccess(page) ? "link" : undefined}>{Text.resolve(key, {plural: counter, counter})}</li>

  return(
    <CustomModal  id={`forbidden-delete${props.entity.linkVariable ? `-${props.entity.linkVariable}` : ''}`}
                  btn
                  actions={[
                    {text: 'close', id: `delete-${props.entity.linkVariable || 'entity'}-cancel`}
                  ]}
                  message={''}
                  heading={props.I18n("delete.title")}
                  label={"delete"}
    >
      <p>{props.I18n('forbidden-delete.resources-assigned')}</p>
      <ul>
        {Object.keys(props.entity?.summary || {}).map(k => resourceItem(`assigned-${k}`, props.entity.summary[k].count, props.entity.summary[k].page))}
      </ul>
    </CustomModal>
  )
};

const DeleteModal = (props) => {
  const [confirmed, setConfirmed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [value, setValue]         = useState(undefined);
  
  return(
    <CustomModal  id={'delete'}
                  btn
                  secondaryAction={() => { setShowError(true); return confirmed && props.onDelete(); }}
                  message={''}
                  heading={props.I18n("delete.title")}
                  label={"delete"}
    >
      <p><Text variables={{deleteWord: (<span className='bold red'>'{Text.resolve('delete').toLowerCase()}'</span>)}}>sure-to-delete-type</Text></p>
      <ValidatedInput value={value} schema={equalsTo(Text.resolve('delete').toLowerCase())} autoFocus={true} onKeyDown={(e) => e.keyCode === 13 ? confirmed && props.onDelete() : null} showError={showError} onChange={e => { setValue(e.target.value); e.target.value && e.target.value.toLowerCase() === Text.resolve("delete").toLowerCase() ? setConfirmed(true) : setConfirmed(false); return true; }}/>
    </CustomModal>
  )
};

const hasResourcesAssigned = (entity) => Object.keys(entity?.summary || {}).reduce((sum, key) => sum += entity?.summary?.[key].count, 0);
const Delete = (props) => Object.values(props.entity?.summary).some(e => e.count === undefined) ? <div className="button disabled inline"><span><Text>delete</Text></span><Spinner size="small"/></div> : hasResourcesAssigned(props.entity) ? <ForbiddenDeleteModal {...props}/> : <DeleteModal {...props}/>;

export default Delete;