import {Text} from 'ui/components/Text';

export default {
  topic: Text.resolve("PrivacyPolicy.topics.what-patients.question"),
  component: () => <div className="grid-row">
    <span><Text>PrivacyPolicy.topics.what-patients.answer-1</Text></span>
    <div>
      <span><Text>PrivacyPolicy.topics.what-patients.answer-2</Text></span>
      <ul>
        <li><Text>PrivacyPolicy.topics.what-patients.answer-3</Text></li>
        <li><Text>PrivacyPolicy.topics.what-patients.answer-4</Text></li>
        <li><Text>PrivacyPolicy.topics.what-patients.answer-5</Text></li>
        <li><Text>PrivacyPolicy.topics.what-patients.answer-6</Text></li>
      </ul>
    </div>
    <div>
      <span><Text>PrivacyPolicy.topics.what-patients.answer-7</Text></span>
      <ul>
        <li><Text>PrivacyPolicy.topics.what-patients.answer-8</Text></li>
      </ul>
    </div>
  </div>
};