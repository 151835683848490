import { memo } from 'react';
import moment from 'moment';

import { Text } from "ui/components/Text";

import './style.css';
import { DeleteSignatureButton } from '../../preferences/DeleteSignatureButton';

export const ClinicianAnalysisSignature = memo(({ signatureInfo, deleteSignature, owner, hideButton }) => {
    if (!signatureInfo) return <></>;

    const date = signatureInfo.datetime ? moment(signatureInfo.datetime) : undefined;

    const signatureTextInfo = {
        fullName: signatureInfo.userFullName,
        time: date?.format('HH:mm'),
        date: date?.format('L'),
    };

    return (
        <>
            <div id="study-signature">
                <img src={signatureInfo.fileContent}></img>
                {date && <div>{Text.resolve("module.diagnosis.fe.component.signature.text", signatureTextInfo)}</div>}
                {!hideButton && <DeleteSignatureButton onResolve={deleteSignature} owner={owner} />}
            </div>
        </>
    );
});