export const getSchemaFormOrder = ({ properties }) => {
    if (!properties || !Array.isArray(properties)) {
        return undefined;
    }

    return properties
        .sort((a = 0, b = 1) => a.order - b.order)
        .map(p => p?.prop);
};

export const getSchemaFormResolver = (registry) => registry?.formContext?.localization?.resolver;

export const getFormOptionsFromSchema = (id, formProps) => {
    const formOpts = formProps?.formOpts ?? {};
    return formOpts[id];
};