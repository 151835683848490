let crypto;
////////////////
///////////////////////////
//////////
crypto ??= require("rusha");

const hash = (text, length) => {
    let hashedValue = crypto.createHash("sha1").update(text).digest("hex");
    if (length) hashedValue = hashedValue.substring(0, length);
    return hashedValue;
};

export {
    hash,
};