import { ReactComponent as EnterFullScreen } from './enter-fullscreen.svg'; // TODO: move static images out base code to reduce bundle size
// import { ReactComponent as ExitFullScreen } from './exit-fullscreen.svg';
import Modal from 'ui/acukit/ModalDialog';
import { useState, Fragment } from 'react';
import {Text} from 'ui/components/Text';

// TODO: implemt properly  standarize properties and styles
export default ({ id, className, show, isLoading, heading, showExpand=true, onClose, closeLabel=Text.resolve("close"), onConfirm, fullScreen=true, confirmLabel="confirm", onOpen, children, whenOpenedRender: OpenedView, whenClosedRender: ClosedView, whenLoadingRender: LoadingView }) => {
  const [isOpen, setOpen] = useState(show), open = () => { setOpen(true); onOpen && onOpen(); }, close = () => { setOpen(false); onClose && onClose(); };

  return isLoading ? <LoadingView /> :  
    <div id={id} className={className}>
      {showExpand ? <EnterFullScreen className="button" style={{ width: '20px', height: '20px', padding: '1px', position: 'absolute', top: '5px', right: '5px', backgroundColor: 'white' }} onClick={open} /> : <Fragment/> }
      <ClosedView  open={open} />
      <Modal id={id} heading={heading} className={`${className||''} ${fullScreen ? 'modal-fullscreen' : ''}`} show={isOpen || show} onClose={close} scrollBehavior='inside' actions={(onConfirm ? [{ text: confirmLabel,  onClick: _ => { onConfirm(); setOpen(false); } }] : []).concat({text: Text.resolve(closeLabel), onClick: close})}>
        {/* <ExitFullScreen className="button" style={{width: '20px', height: '20px', padding: '1px', position: 'absolute', top: '1%', right: '1%' }} onClick={close} /> */}
        { OpenedView ? <OpenedView close={close} /> : children }
      </Modal>
    </div>;
    
};
