import { ACTION_COMPLETED, ACTION_FAILED, ACTION_REQUESTED, EXEC_BACKGROUND } from '../../../actions/modules/Executor';
import { isEvent }                                           from 'services/server/functions/executor/urn';
import produce from 'immer'

const initialState = {
  actions: {
    completed: { },
    running: { },
    failed: { },
    background: { }
  },
  events: {
  }
};

const executor = (globalState, event) => {
  globalState.executor = globalState.executor || initialState;
  const { actions, events } = globalState.executor;
  const { running, completed, failed, background } = actions;

  switch (event.type) {
    case EXEC_BACKGROUND:
      background[event.id] = true;
      return globalState;

    case ACTION_REQUESTED:
      running[event.metadata.causationId] = event.data;
      events[event.id] = event;
      events[event.id].timestamp = event.timestamp || Date.now();
      return globalState;

    case ACTION_COMPLETED:
      delete running[event.metadata.causationId];
      delete background[event.metadata.causationId];
      completed[event.metadata.causationId] = event.data;
      events[event.id] = event
      events[event.id].timestamp = event.timestamp || Date.now();
      return globalState;

    case ACTION_FAILED:
      delete running[event.metadata.causationId];  
      delete background[event.metadata.causationId];
      failed[event.metadata.causationId] = event; // TODO: why different? should be event.data as the others ???
      return globalState;

    default:
      // TODO: not required if components always reaload from server
      // return Object.assign({}, state, {
      //   events: [...events, [event.id]: event]
      // });
      if (event.id && isEvent(event.id)) {
        events[event.id] = event
        events[event.id].timestamp = event.timestamp || Date.now();
      }
      return globalState;
  }
};

export default (state = initialState, event) => produce(state, draft => executor(draft, event));
