import { errorId } from "@rjsf/utils";

import { ColorsType } from "features/constants/colors";
import { Hint } from "../components/Hint";
import { getSchemaFormId } from "../utils/formUtils";

const getErrorTranslation = ({ error, errorsMessages }) => {
    const errorKey = Object.keys(errorsMessages)?.find(errorKey => error.includes(errorKey));
    return errorKey ? errorsMessages[errorKey] : error;
};

export const FieldErrorTemplate = (props) => {
    const { errors = [], registry, idSchema } = props;

    if (errors.length === 0) {
        return null;
    }

    const formContext = registry?.formContext || {};
    const idOfError = errorId(idSchema);
    const propId = getSchemaFormId(idSchema);
    const hasLocalization = formContext?.localization;
    const localization = hasLocalization && (
        formContext.localization[propId] || {}
    );
    const errorsMessages = localization?.errors;

    return (
        <div id={idOfError} name={idOfError} >
            {errors
                .filter((e) => !!e)
                .map((error) => (
                    <div key={error}>
                        <Hint color={ColorsType.Danger}>
                            {getErrorTranslation({ errorsMessages, error })}
                        </Hint>
                    </div>
                ))}
        </div>
    );
};
