import 'react-phone-input-2/lib/style.css';
import * as Pages from 'modules/administration';
import * as Authentication from 'modules/authentication';
import { Organisation } from 'services/server/functions/model/administration/model';
import { Select } from 'ui/components/Select';
import { WithValidation } from 'ui/components/WithValidation';
import { prop, sortBy } from 'ramda';
import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Modal from 'ui/acukit/ModalDialog';
import { useState } from 'react';
import { Role, User } from 'services/server/functions/model/authentication/model';
import Spinner from '@atlaskit/spinner';

import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import WithSnapshot from 'ui/components/WithSnapshot';
import useSnapshot from 'ui/hooks/useSnapshot';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { hasRole } from 'services/server/functions/iam';

const ValidatedInput  = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name}  onValidate={onValidate} showError={showError} {...props}><input {...props} value={props.value || ''} /></WithValidation>
const ValidatedSelect = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><Select {...props} /></WithValidation>; 

const EditTrialUI = ({formSaved, clinicians, organisations, onValidate, onSave, onValueChange, isUpdate, trial, askConfirmation, onConfirm, onRegret, I18n}) => {
  const [edited, setEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const actions = [
    { text: 'leave',  onClick: () => history.replace(Pages.ViewTrial.PATH, {trial: trial.id}), appearance: 'primary' },
    { text: 'stay', onClick: () => setShowModal(false) },
  ];
  const onChange = (...args) => {
    setEdited(true);
    return onValueChange(...args);
  };

  const ValidationSchema = isUpdate ? Organisation.entities.Trial.commands.EDIT_TRIAL.schema : Organisation.entities.Trial.commands.REGISTER_TRIAL.schema;
  return (
    <div className="page" id="EditTrial">
      <Back/>
      <div className="pageHeader">
        <h1 className="title">{I18n('title')}</h1>
      </div>
      <div className="pageContent">
        <div className="pageSection">
          <div className="title">{I18n('details')}</div>
          <DetailsBlock>
            <div className="grid-col">
              <Details id="trialName" label="global.name" mandatory><ValidatedInput name="name" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={trial.name} /></Details>
              {!isUpdate ? <Details id="trialPrincipal" label="principal-investigator">
                {isUpdate ? <Authentication.ViewUser.AsText user={trial.principal} />
                  : clinicians === undefined ? <Spinner size="small"/>
                  : <ValidatedSelect name='principal' isClearable onValidate={onValidate} onChange={onChange} options={sortBy(prop('label'))(clinicians)} placeholder={I18n('choose-principal')} schema={ValidationSchema} showError={formSaved} value={trial.principal} />}
              </Details> : <div/>}
            </div>
            <Details id="trialDescription" label="description"><ValidatedInput autoComplete="off" name="description" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={trial.description} /></Details>
          </DetailsBlock>
        </div>
        {!isUpdate && <div className="pageSection">
          <DetailsBlock>
            <Details id="trialSponsor" label="sponsor" mandatory={organisations?.length !== 1}>
              {organisations === undefined ? <Spinner size="small"/>
                : organisations.length === 1 ? <Pages.ViewOrganisation.AsText organisation={trial.sponsor || organisations[0].value} />
                : <ValidatedSelect name='sponsor' onValidate={onValidate} onChange={onChange} options={sortBy(prop('label'))(organisations)} placeholder={I18n('choose-org')} schema={Organisation.schema.extract('id').required()} showError={formSaved} value={trial.sponsor}/>}
            </Details>
          </DetailsBlock>
        </div>}
        <span><sup className="mandatory">*</sup><span className="small">{I18n('compulsory-field')}</span></span>
      </div>
      <div className="pageFooter">
        <div className='actions'>
          <Action className='button' id="back" handleAction={() => edited ? setShowModal(true) : history.replace(Pages.ViewTrial.PATH, {trial: trial.id})} label='cancel' />
          <Action className='button primary' id="report" handleAction={() => onSave()} label='global.button.save' />
        </div >
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} heading="unsaved-changes" actions={actions}>
        <span>{I18n('sure-to-leave')}</span>
      </Modal>
      <Modal show={askConfirmation} onClose={onRegret} heading="please-confirm" actions={[
        { text: 'confirm', onClick: onConfirm, appearance: 'primary' },
        { text: 'cancel', onClick: onRegret },
      ]}>
        <span>{I18n('EditTrial.already-exists')}</span>
      </Modal>
    </div>
  );
};

const EditTrialPresenter = (props) => {
  const [currentUser] = useCurrentUser();
  
  const {organisation, form} = props;
  const id            = props.trial;
  const isUpdate      = id !== undefined;
  
  const [userSnaps, loadingUsers] = useSnapshot(User, undefined, {autoStart: !isUpdate});
  const [orgSnaps, loadingOrgs] = useSnapshot(Organisation, undefined, {autoStart: !isUpdate});
  let organisations = loadingOrgs ? undefined : Object.values(orgSnaps || {}).map(({data: { id, name }}) => ({ label: name, value: id })).filter(o => currentUser.isFromAcurable() || currentUser?.data.owners.includes(o.value));
  let clinicians    = loadingUsers ? undefined : Object.values(userSnaps || {}).filter(u => !u.metadata.impersonator && hasRole(Role.newURN('CTUser'))(u.data.roles)).map(({data: { id, name, lastName }}) => ({ label: `${lastName.toUpperCase()}, ${name}`, value: id }));

  const [saved, setSaved]   = useState(false);
  const [errors, setErrors] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);

  const trial   = form || (isUpdate && props.snapshots[id]?.data) || {};
  trial.sponsor = trial.sponsor || (Organisation.ownersFrom(trial)?.[0]) || organisation || Organisation.ownersFrom(currentUser?.data)[0] || organisations[0].value;

  const hasErrors = () => errors && Object.values(errors).length > 0;
  
  const onValidate = (field, error) => setErrors(prevErrors => {
    if (error) return { ...prevErrors, [field]: error };
    delete prevErrors[field];
    return prevErrors;
  });

  const handleRegister = (data) => {
    const newTrial = Organisation.entities.Trial.new(data);
    props.registerTrial(newTrial.data)
      .then(() => history.replace(`${Pages.ViewTrial.PATH}`, { trial: newTrial.aggregate.id }));
  };

  const handleUpdate = (data) =>  isUpdate && props.editTrial({ id, ...data }).then(_ => history.replace(`${Pages.ViewTrial.PATH}`, { trial: id }));

  const withOwners = ({ sponsor, ...trial }) => ({ ...trial, owners: [sponsor] });

  const submit = () => {
    setAskConfirmation(false);
    (isUpdate ? handleUpdate : handleRegister)(withOwners(trial))
  };
  
  const handleSave = () => {
    setSaved(true);
    if (!hasErrors()) {
      if (Object.values(props.snapshots).find(t => t.aggregate.id !== id && t.data.name === trial.name) !== undefined) return setAskConfirmation(true);
      submit();
    };
  }

  const onValueChange = (event) => {
    const value = event.target.type === 'select-multiple' ? [] : event.target.value;
    if (event.target.type === 'select-multiple') for (let i = 0; i < (event.target.selectedOptions || []).length; i++) value.push(event.target.selectedOptions[i].value);
    props.setLocationState({form: {...trial, [event.target.name]: value}});
  }

  return <EditTrialUI
    trial={trial}
    clinicians={clinicians}
    organisations={organisations}
    onValidate={onValidate}
    onSave={handleSave}
    onValueChange={onValueChange}
    formSaved={Boolean(saved)}
    isUpdate={isUpdate}
    askConfirmation={askConfirmation}
    onConfirm={submit}
    onRegret={() => setAskConfirmation(false)}
    I18n={props.I18n}
  />;
};

const EditTrial = (props) => {
  const [locState, setLocationState] = useLocationParams();
  // Note: loading all organisations because of popup warning saying there is another organisation with same name (re-using query to load current snapshot on Update UC)
  return WithSnapshot(Organisation.entities.Trial, undefined, {showLoading: locState.trial !== undefined})(EditTrialPresenter)({...props, setLocationState, ...locState});
}

EditTrial.displayName = "EditTrial";
export default EditTrial;
