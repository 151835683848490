import 'ui/theme/find.css';

import * as Administration from 'modules/administration';
import * as Pages from 'modules/authentication';

import AdministrationConfig from 'services/server/functions/model/administration/config';
import { HealthcareSite, Organisation, isAcurable, isFromAcurable } from 'services/server/functions/model/administration/model';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import HelpTooltip from 'ui/components/HelpTooltip';
import QuickFilters from 'ui/components/QuickFilters';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import { User } from 'services/server/functions/model/authentication/model';
import WithSnapshot from 'ui/components/WithSnapshot';
import history from 'history.js';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import useRoles from 'ui/hooks/useRoles';
import useSnapshot from 'ui/hooks/useSnapshot';

const ROOT_ORGA_ID = AdministrationConfig.organisation.root.id;

const sortByLastName = (_1, _2, order, _3, row1, row2) => (order === 'asc' ? 1 : -1) * (row1.lastName?.toLowerCase() > row2.lastName?.toLowerCase() ? 1 : -1);

const FindUserView = ({ nameOrEmail, showDisabled, role, organisation, healthcaresite, trial, users: usersData, onNameOrEmailChanged, onShowDisabledChanged, I18n }) => {
  require('./style.css');
  
  const [currentUser] = useCurrentUser();
  const userBelongsToOrg = (u, o) => u?.data?.owners?.includes(ROOT_ORGA_ID) || u?.metadata?.allOwners?.includes(o);
  const isOrgAndHasAccessTo = (o) => Organisation.isReference(o) && (isFromAcurable(currentUser)  || userBelongsToOrg(currentUser, o));
  // This is necessary because the User might have more than 1 orga, and the first one might not be associated to the context (mostly applies for Users from Acurable)
  const allOwnersFirst = (a) => (currentUser?.metadata.allOwners || []).includes(a) ? -1 : 1;
  const users = usersData.filter(u => u.owners.concat(u.ancestors).some(o => userBelongsToOrg(currentUser, o))) // Must filter the currentUser if its from acurable and doesn't belong to that org, as the content always returns it as a user
                .map(u => ({...u, organisation: u.owners.concat(u.ancestors).filter(isOrgAndHasAccessTo).sort(allOwnersFirst)[0]}));
  
  return <div id='FindUser' className='page'>
    <Back />
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
      <div className='actions'>
        {currentUser.hasAccess(Pages.CreateUser) && <Pages.CreateUser.AsLinkButton text="create" className="primary" />}
        {currentUser.hasAccess(Pages.FindRole) && <Pages.FindRole.AsLinkButton text={I18n("manage-roles")}/> }
      </div>
    </div>
    <div className="pageContent">
      <div className="pageSection">
        <div className='title'>{I18n('search-by')}</div>
        <DetailsBlock>
          <div className="filter grid-row">
            <input id="User.nameOrEmail" placeholder={Text.resolve("name-email")} onChange={(e) => onNameOrEmailChanged(e.target.value)} value={nameOrEmail || ""}/>
            <QuickFilters>
              { role ? <Pages.ViewRole.AsLinkIcon role={role} className="quickfilter selected" /> : undefined }
              { organisation ? <Administration.ViewOrganisation.AsLinkIcon organisation={organisation} className="quickfilter selected" /> : undefined }
              { healthcaresite ? <Administration.ViewHCS.AsLinkIcon healthcaresite={healthcaresite} className="quickfilter selected" /> : undefined }
              { trial ? <Administration.ViewTrial.AsLinkIcon trial={trial} className="quickfilter selected" /> : undefined }
            </QuickFilters>
            <div className="inline">
              <input style={{width: "10px"}} className="vcentered" type="checkbox" id="showDisabled" onChange={(e) => onShowDisabledChanged(e.target.checked)} checked={showDisabled} />
              <span className="disabledAccounts">{I18n('show-disabled-users')}</span>
            </div>
          </div>
        </DetailsBlock>
      </div>
      <div className="pageSection content">
        <Table
          keyField='id'
          columns={{
            fullName     : { content: "global.name",         isSortable: true, sortFunc: sortByLastName },
            organisation : { content: "global.organisation.label" },
            job          : { content: "job" }
          }}
          onRowClick={((u) => history.push(Pages.ViewUser.PATH, { user: u.id }))}
          data={users.map(u => ({
            ...u,
            fullName: <div className="inline"><span>{u.name && u.lastName ? `${u.name} ${u.lastName.toUpperCase()}`: 'N/A'}</span>{u.status === User.STATUS.disabled && <HelpTooltip type="warning" className="vcentered" id={`${u.id}DisabledWarn`} content={I18n('disabled-user')} place="right" />}</div>,
            organisation: <Administration.ViewOrganisation.AsText id={u.organisation} options={{ skipContext: true }}/>
          }))}
          defaultSortCol={'fullName'}
          defaultSortDir={'asc'}
          emptyText={I18n('no-users-found')}
        />
      </div>
    </div>
  </div>;
}

const FindUserPresenter = props => {
  const [Roles] = useRoles();
  
  const { organisation, healthcaresite, trial, nameOrEmail, role: roleId, showDisabled=false, hcss, setLocationState} = props,
        setNameOrEmail                                                                                         = (nameOrEmail)  => setLocationState({ nameOrEmail }),
        setShowdisabled                                                                                        = (showDisabled) => setLocationState({ showDisabled });

  const role      = roleId && Roles.get(roleId); // TODO: assumes Roles is up to date. Create a global subscription to keep roles up to date ??
  const ancestors = role && Roles.ancestorsOf(role).map(r => r.id);

  const byDisabled    = ({data: { status } }) => status !== User.STATUS.disabled || showDisabled; // TODO: expand roles? use metadata?? muist be a better way
  const byRole        = ({ data: { roles=[] } }) => !role || roles.find(r => r === role.id || ancestors.includes(r)) ; // TODO: expand roles? use metadata?? muist be a better way
  const byNameOrEmail = ({ data: { name, lastName, mail } }) => !nameOrEmail || `${name} ${lastName} ${mail}`.toLowerCase().includes(nameOrEmail.toLowerCase());
  const byOwners      = (user) => !(healthcaresite || organisation || trial) || user.data.owners.some(o => (trial && o === trial) || (healthcaresite && o === healthcaresite) || (organisation && o === organisation) || (organisation && (hcss[o]?.data?.owners || []).includes(organisation)));
  const byNotImpersonator = (user) => !user.metadata.impersonator;
  const withState     = (user) => byOwners(user) && byNameOrEmail(user) && byRole(user) && byDisabled(user) && byNotImpersonator(user);

  // Note: filter root organisation from list of ancestors to get HCSs organisations in case the user do not belong to an organisation directly
  const users = Object.values(props.snapshots || {}).filter(withState).map(u => ({...u.data, ancestors: u.metadata.allOwners.filter(o => !isAcurable(o))}));
  
  return <FindUserView {...{
        users,
        role: roleId,
        nameOrEmail,
        showDisabled,
        organisation,
        healthcaresite,
        trial,
        onNameOrEmailChanged : setNameOrEmail,
        onShowDisabledChanged: setShowdisabled,
        I18n                 : props.I18n
      }} />;
};

export default Object.assign(props => {
  const [locState, setLocationState] = useLocationParams();

  const [ hcss, loadingHCSs ] = useSnapshot(HealthcareSite);

  return WithSnapshot(User)(FindUserPresenter)({...props, hcss, loading: loadingHCSs, ...locState, setLocationState});
}, {displayName: 'FindUser'});