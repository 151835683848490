import merge from 'lodash.merge';
import moment from 'moment';

import { converStringPropsToObj, removeNotDefinedProps } from "features/helpers/objectHelper";

export const toStudyRef = ({ study, product, site }, isEditPage) => {
    let studyRef = {
        id: study.id,
        date: study.date,
        clinician: study.clinician,
        site,
        productId: product?.id,
        patient: {
            id: study.patientID?.toUpperCase(),
            birthDate: study.patientBirth ? moment(study.patientBirth).format("DD/MM/YYYY") : study.patientBirth,
            profile: study.profile,
            instructions: Object.entries({
                requiresOximetry: study.requiresOximetry,
                providedPhone: study.providedPhone,
                fillQuestionnaire: study.fillQuestionnaire,
                alarmDisabled: study.alarmDisabled,
                maxTestRepeats: study.maxTestRepeats
            }).filter(([, val]) => val !== undefined).reduce((all, [k, v]) => ({ ...all, [k]: v }), {})
        },
        requestedTests: study.requestedTests
    };

    if (!isEditPage) {
        studyRef.owners = [site, study.trial].filter(Boolean);
    }

    return studyRef;
};

export const prepareCreateStudyFormData = ({ study, product, isEditPage, persistedState = {} }) => {
    const persistedFormData = persistedState?.dataCausedByUser;
    const data = toStudyRef({ study, product, site: persistedState?.site }, isEditPage);
    const convertedData = converStringPropsToObj(persistedFormData) || {};
    return removeNotDefinedProps(merge(data, convertedData));
};
