import { useState } from 'react';

import { reportFileName } from 'features/helpers/reportHelper';
import { messageFrom } from 'modules/executor';
import ExportPDF from 'ui/components/ExportReport';
import { SleepReportSettingValue } from 'features/preferences/StudySleepReportSettings/SleepReportSettingValue';
import { FiltersOptionTypes } from 'features/components/FilterOption';
import { SwitchFilterModes } from 'features/components/FilterOption/SwitchFilter';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Text } from 'ui/components/Text';

const getTestFilters = (tests, test) => ({
    fieldName: 'tests',
    type: FiltersOptionTypes.Switch,
    title: 'module.diagnosis.fe.component.export.night_to_export',
    mode: SwitchFilterModes.Multiple,
    options: tests?.map(({ id, retry, night }) => {
        return {
            id, text: retry ? `${night}R` : `${night}`,
            selected: test ? test?.id === id : true,
        };
    }
    ) || [],
});

const hasValidTests = (tests = {}) => {
    try {
        return Object.values(tests).some(
            (t) => t?.status === "ANALYSED" ||
                Object.values(t?.repeats || {}).some(r => r?.status === "ANALYSED")
        );
    } catch {
        return false;
    }
};

export const getExportStudyAction = (hasAccess) => ({ study, test, notify, urlCallback, ...extraProps }) => {
    const { patient, date, tests } = study;
    const showExportButton = hasValidTests(tests);
    const [showProgModal, setShowProgModal] = useState(false);
    const [fields, setFields] = useState();
    const [loading, setLoading] = useState(true);

    const disabledExport = !hasAccess(Study.queries.EXPORT_SLEEP_STUDY) || loading;
    const getCustomActions = ({ getFields }) => disabledExport ? [] : [{
        text: Text.resolve("global.export"), id: 'export-action-filter', appearance: 'primary', onClick: () => {
            setFields(getFields());
            setShowProgModal(true);
        }
    }];

    const testsWithRepeats = Object.values(study.tests || {})
        .reduce((result, test) => {
            result.push(test);
            return result;
        }, []);

    const testFilters = getTestFilters(testsWithRepeats, test);

    return (
        <>
            {
                showExportButton &&
                <SleepReportSettingValue
                    owner={study.healthcaresite}
                    disabled={disabledExport}
                    testFilters={testFilters}
                    onLoaded={() => setLoading(false)}
                    getCustomActions={getCustomActions}
                />
            }
            {
                showExportButton && showProgModal &&
                <ExportPDF
                    id="ExportStudy"
                    disabled={disabledExport}
                    showProgModal={showProgModal}
                    appearance="hidden"
                    label="global.exportx"
                    downloadFile={reportFileName('analysis', patient?.id, date)}
                    onClosed={() => setShowProgModal(false)}
                    onError={e => notify('error', Text.resolve("ViewStudy.error.export"), messageFrom(e))}
                    urlCallback={() => urlCallback({
                        mode: fields?.mode[0],
                        tests: fields?.tests
                    })}
                    {...extraProps} />
            }
        </>
    );
};
