import { dismissNotification, notify } from 'services/redux/actions/Notifications';

import { Component } from 'react';
import { Text } from 'ui/components/Text';
// @ts-check
import { compose } from 'redux';
import { connect } from 'react-redux';
import history from 'history.js';
import hoistNonReactStatics from 'hoist-non-react-statics';
import i18n from 'services/server/functions/i18n';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { withPermission } from 'modules/iam';

const isDigit =  (d) => !isNaN(parseInt(d));
const html5Id = (id) => (isDigit(`${id}`.charAt(0)) ? `ID_${id}` : `${id}`).trim().toLowerCase().replace(' ', '-');  // HTML5 IDs cannot start by number

export const withRoutePaths = ({relpath, pathparams, ...otherProps}) => {
  const routePath = pathparams ? `${relpath}/${pathparams}` : relpath;
  
  return {
    ...otherProps,
    relpath,
    routePath, //TODO: redesign. we probably don't need query parameters as we can always pass attrs using the route or link state
    linkPath: (args) => Object.keys(args).reduce((path, a) => args[a] ? path.match(new RegExp(`:${a}\\??`)) ? path.replace(new RegExp(`:${a}\\??`), args[a]) : path + `${path.includes('?') ? '&' : '?'}${a}=${args[a]}` : path, routePath)
  };
};

/**
 * Augments the `WrappedComponent` with static `asRoute` and `asLink` methods for generating `Route` or `Link` components for the `WrappedComponent`
 * @param {*} route route object to render
 */
const withRenderRoute = (route) => (WrappedComponent) => {
  const { routePath, linkPath, relpath: path } = withRoutePaths(route);
  if (!WrappedComponent.AsText) WrappedComponent.AsText = ({text, title}) => <span title={title}><Text>{text === undefined ? "Unknown" : text}</Text></span>;
  
  const AsButton = ({ id, text, component, title, className, children, disabled, ...props }) => {
    const [currentUser] = useCurrentUser();
    return <div title={!currentUser.hasAccess(WrappedComponent) ? i18n.resolve("no-access-tooltip") : title} className={`button ${className || ''} ${(disabled || !currentUser.hasAccess(WrappedComponent)) ? "disabled" : ""}`} id={html5Id(id || text)} >{children !== undefined ? children : component ? component : WrappedComponent.AsText({text, ...props})}</div>;
  }
  
  const AsLink = ({ key, id, text, title, target, disabled, className='link', children, ...state }) => {
    const [currentUser] = useCurrentUser(state.options);

    disabled  = disabled || !currentUser.hasAccess(route);

    const TextComponent = () => children !== undefined ? children : <WrappedComponent.AsText {...{ id, text, title, ...state }} />;
    const LinkComponent = ({children, ...props}) => disabled ? <span {...props}>{children}</span> 
                                                                      : <a href={target === "_blank" ? linkPath({id, ...state}) : undefined} target={target} onClick={_ => target !== '_blank' && history.push({ pathname: linkPath({}), state: {id, ...state} })} {...props}>{children}</a>;
    return <LinkComponent key={key || html5Id(id || text)} id={id || html5Id(text)} className={disabled ? className?.replace(/(^|[ ]?)link([ ]?|$)/, '') : className}> 
      <TextComponent/>
    </LinkComponent>;
  }

  WrappedComponent.AsButton = AsButton;
  WrappedComponent.AsLink = AsLink;
  WrappedComponent.AsLinkButton = ({hidden, buttonClass, linkClass, ...props})  => hidden ? <></> : <WrappedComponent.AsLink {...props} className={linkClass || ''}><WrappedComponent.AsButton className={buttonClass} {...props} /></WrappedComponent.AsLink>;
  WrappedComponent.AsLinkIcon = ({hidden, className, linkClass, ...otherProps}) => hidden ? <></> : <WrappedComponent.AsLink {...otherProps} className={linkClass || ''}><WrappedComponent.AsIcon className={className} {...otherProps} /></WrappedComponent.AsLink>;
  WrappedComponent.PATH=path;
  WrappedComponent.ROUTE_PATH=routePath;
  WrappedComponent.ROUTE_PRIORITY=route.priority;
  WrappedComponent.ROUTE_PUBLIC=Boolean(route.public);
  WrappedComponent.ROUTE_HIDENAV=Boolean(route.hideNav);
  WrappedComponent.ROUTE_HIDEONAUTH=Boolean(route.hideOnAuth);
  WrappedComponent.displayName = `WithRenderRoute(${WrappedComponent?.displayName})`;

  return WrappedComponent;
};

const withGroupedLogs = (WrappedComponent) => {
  if (!WrappedComponent) return WrappedComponent;
  
  class WithGroupedLogs extends Component {
    constructor(props) { 
      super(props); 
      console.group(WrappedComponent?.displayName || WrappedComponent?.name); 
    }    
    componentWillUnmount() { console.groupEnd(); }
    render = () => <WrappedComponent {...this.props} />;
  }

  WithGroupedLogs.displayName = `WithGroupedLogs(${WrappedComponent?.displayName})`;

  hoistNonReactStatics(WithGroupedLogs, WrappedComponent);

  return WithGroupedLogs
}


const withI18n = context => ({ I18n: (text, variables, ctx) => Text.i18n.resolve(text, variables, {...context, ...ctx}) });

// TODO: Instead of receiving actions, load them automatically from associated route model
const publish = (route, actions) => {
  const composed = (page, context) => compose(
    withPermission(route), // 1. minimum access rights required to access this component. Each component should be further protected if required.
    withRenderRoute(route), // 2. route/link order and path to the component
    connect(
      () => ({}), 
      { notify, dismissNotification, ...actions },
      (_stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...dispatchProps,
        ...withI18n(context)
      })
    ),
    withGroupedLogs
  )(page);

  return (page, context) => composed(page, context);
};

export {
  publish
};
