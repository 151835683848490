import * as Sentry from "@sentry/browser";

import 'react-app-polyfill/stable'; // Required to get stable polyfills? -> not sure if this is comming automatically imported when compiling for production since react-scripts 3.0.0
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ui/theme/index.css';
import 'ui/theme/use-normalize.css'; // required by pos-css to normalize app CSS for different browsers
import 'ui/theme/serviceworker.css';

import ErrorBoundary from 'ui/components/ErrorBoundary';
import Loading from 'ui/components/Loading';
import ReactDOM from 'react-dom';
import {Text} from 'ui/components/Text';
import asyncComponent from 'ui/components/AsyncComponent';
import cssVars from 'css-vars-ponyfill';
import {onAppLoad} from 'services/firebase';
import useEffectOnMount from 'ui/hooks/useEffectOnMount';
import { useState } from 'react';
import ServiceWorker from './ServiceWorker';

Sentry.init({
  dsn: "https://db1d2511c846863cde0670e4bb4f6504@o4507186216239104.ingest.de.sentry.io/4507695970844752",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  
  // The environment we are running in
  environment: window.location.hostname || 'unknown',
  // Our release
  release: process.env.REACT_APP_VERSION || 'unknown',
  // Enabled?
  enabled: window.location.hostname !== 'localhost',
});

cssVars({
  onComplete: () => console.log('CSS Variables updated')
})

const LoadComponent = () => <Loading text={Text.resolve('App.loading-regions')} size="xlarge" />;
const EntryPoint = asyncComponent(() => import(/* webpackChunkName: "EntryPoint" */  './entrypoint'), LoadComponent);

const App = () => {
  const [appReady, setAppReady] = useState(false);
  useEffectOnMount(_ => onAppLoad(app => setAppReady(Boolean(app))));
  return appReady ? <EntryPoint/> : <LoadComponent/>;
};

const AppWithBoundary = () => <ErrorBoundary>
  <ServiceWorker />
  <App/>
</ErrorBoundary>;
ReactDOM.render(<AppWithBoundary/>, document.getElementById('root'));