import authentication from './Authentication';
import { combineReducers } from 'redux';
import modules from './modules';
import notifications from './Notifications';

export default (storage) => combineReducers({
  authentication: authentication(storage),
  notifications,
  modules
});
