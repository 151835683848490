import { useState, useEffect } from 'react';

const checkIntegrity = (a, b) => JSON.stringify(a) !== JSON.stringify(b);

export default (defaultForm) => { 
  const [form, setForm] = useState(defaultForm || {});
  const [dirty, setDirty] = useState(checkIntegrity(defaultForm || {}, form));

  const onFormChange = ({ target: { name, value } }) => setForm({ ...form, [name]: value.trim() });

  const onOptionsChange = ({ target: { name, selectedOptions = [] } }) => {
    const options = selectedOptions || [];
    setForm({ ...form, [name]: options?.map(o => o.value) });
  };

  const [errors, setErrors] = useState();
  const onError = (field, error) => setErrors((prevErr)=> ({ ...prevErr, [field]: error }));

  const hasErrors = errors !== undefined && Object.keys(errors).some(key => errors[key] !== undefined && errors[key] !== null);

  const [submitted, setSubmitted] = useState(false);
  const onSubmit = (callback) => { setSubmitted(true); if (!hasErrors) callback(form) };

  useEffect(() => setDirty(checkIntegrity(defaultForm || {}, form)), [form]);

  return {
    form,
    onFormChange,
    onOptionsChange,
    onSubmit,
    onError,
    setForm,
    hasErrors: (submitted && hasErrors ) || hasErrors,
    errors,
    dirty
  };
}