import { AutoComplete } from "../AutoComplete";
import { Text } from "ui/components/Text";

export const SelectorTitle = ({ label, children }) => (
    <span className="context-selector-component-title">
        <Text>
            {label}
        </Text>
        {children}
    </span>);

export default ({
    id, label, items, name, value,
    handleChange, isMulti = true, placeholder, disabled
}) => {
    return (
        <div className="context-selector-opts-block">
            <span className="context-selector-opts-block-label">{label}</span>
            <AutoComplete
                id={id}
                name={name}
                items={items}
                value={value}
                isMulti={isMulti}
                handleChange={handleChange}
                placeholder={placeholder}
                withDefault={false}
                selectProps={{
                    isDisabled: disabled,
                    closeMenuOnSelect: !isMulti
                }}
            />
        </div>
    );
};