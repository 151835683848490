import { WithValidation, ValidatedField } from 'ui/components/WithValidation';

const Input = ({disabled, className, ...props}) => <input {...props} className={`${className} ${disabled ? 'disabled': ''}`}readOnly={disabled} value={(props.value !== undefined && props.value !== null) ? props.value : ''}/>;
export const ValidatedInput = ({onValidate, schema, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} schema={schema} {...props}><Input {...props}/></WithValidation>;

const get = (obj, [first, ...rest], defaultValue) => rest.length ? get(obj[first], rest, defaultValue) : obj[first] === undefined ? defaultValue : obj[first];
export const ValidatedInputField = ({onValidate, schema, showError, field, defaultValue, ...props}) => (
  <ValidatedField field={field || props.name} onValidate={onValidate} showError={showError} schema={schema} {...props}>  
    <Input {...props} mandatory={props.mandatory?.toString()} value={get(props.value, field.split('.'), defaultValue)}/>
  </ValidatedField>
)

export default Input;