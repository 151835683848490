import { createContext , useState, useEffect, useCallback} from "react";
import { useDispatch } from "react-redux";

import I18n from 'services/server/functions/i18n';
import { dismissNotification, notify } from 'services/redux/actions/Notifications'; 

export const NetworkContext = createContext(false);

export const NetworkProvider = ({ children }) => {
    const id = 'networkProvider';
    const notificationSelector = '.notification';
    const dispatch = useDispatch();
    const [network, setNetwork] = useState({
        online: true,
        offline: false
    });
    const setNetworkStatus = useCallback((isOnline) => {
        const recoveringFromDisconnection = network.offline && isOnline;
        if (recoveringFromDisconnection) {
            document.querySelectorAll(`${notificationSelector}`).forEach(n => n.remove());
        }
        setNetwork(() => ({
            online: isOnline,
            offline: !isOnline
        }));
    });
    
    window.addEventListener('online', () => setNetworkStatus(true));
    window.addEventListener('offline', () => setNetworkStatus(false));

    useEffect(() => {
        console.log('Network Status:', network.offline ? 'offline' : 'online');
        if (network.offline) {
            dispatch(notify('warning', I18n.resolve("global.notifications.not_network"), I18n.resolve("global.notifications.with_out_connection"), { id, timeout: 0, dismiss: { showIcon: false, click: false, touch: false } }));
        } else {
            dispatch(dismissNotification({ id }));
        }
    }, [ network.offline ]);

    return (
      <NetworkContext.Provider value={network}>
          {children}
      </NetworkContext.Provider>
    );
}; 