import * as Pages from 'modules/authentication';

import { Option, Select } from 'ui/components/Select';
import { Role, User } from 'services/server/functions/model/authentication/model';

import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Loading from 'ui/components/Loading';
import Modal from 'ui/acukit/ModalDialog';
import {Text} from 'ui/components/Text';
import { WithValidation } from 'ui/components/WithValidation';
import { hasAllRoles } from 'services/server/functions/iam';
import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import useRoles from 'ui/hooks/useRoles';
import useSnapshot from 'ui/hooks/useSnapshot';
import { useState } from 'react';

const ValidatedInput  = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><input {...props} value={props.value || ''}/></WithValidation>;
const ValidatedSelect = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><Select {...props} /></WithValidation>;

const RoleOption = props => <div title={props.data.description || "no description"}><Option {...props} /></div>;

const Edit = ({ role, userCount, onCancelHandler, onSave, onValueChange, permissions, update, I18n }) => {
  const [edited, setEdited]                           = useState(false);
  const [showModal, setShowModal]                     = useState(false);
  const [showConfirmOnUpdate, setShowConfirmOnUpdate] = useState(false);
  const [showErrors, setShowErrors]                   = useState(false);
  const [errors, setErrors]                           = useState({}), hasErrors = () => Object.values(errors).filter(Boolean).length > 0;

  const onValidate = (field, error) => setErrors(prevErrors => ({ ...prevErrors, [field]: error }));

  const onChange = ({ target }) => {
    setEdited(true);
    return onValueChange({ [target.name]: target.type === 'select-multiple' ? (target.selectedOptions || []).map(o => o.value) : target.value });
  };
  
  const onSubmit = () => {
    if ( hasErrors() ) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
      if (userCount) {
        setShowConfirmOnUpdate(true);
      } else {
        setShowConfirmOnUpdate(false);
        onSave();
      }
    }
  };

  const txPermissions = permissions.map(p => ({ ...p, label: Text.resolve(p.label, {}, { schema: "authentication.Role" })}));

  const ValidationSchema = update ? Role.commands.UPDATE_ROLE.schema : Role.commands.CREATE_ROLE.schema;
  return (
    <div className="page" id="EditRole">
      <Back />
      <div className="pageHeader">
        <h1 className="title">{update ? I18n('EditRole.title') : I18n('CreateRole.title')}</h1>
      </div>
      <div className="pageContent">
        <div className="pageSection">
          <div className="title">{I18n('details')}</div>
          <DetailsBlock>
            <div className="grid-col">
              <Details id="Name" label="global.name" mandatory>
                <ValidatedInput name="label" schema={ValidationSchema} showError={showErrors} onValidate={onValidate} onChange={onChange} value={Role.i18n(role.label)} />
              </Details>
              <Details id="Description" label="description">
                <ValidatedInput name="description" schema={ValidationSchema} showError={showErrors} onValidate={onValidate} onChange={onChange} value={Role.i18n(role.description)} />
              </Details>
            </div>
            <div className="grid-col">
              <Details id="permissions" label="access-rights" mandatory>
                <ValidatedSelect isMulti name="includes" schema={ValidationSchema} showError={showErrors} onValidate={onValidate} value={role.includes || []} options={txPermissions} placeholder={I18n("CreateRole.choose-access-rights")} onChange={onChange} 
                  components={{ Option: RoleOption }}
                />
              </Details>              
            </div>
          </DetailsBlock>
        </div>
      </div>
      <div className="pageFooter">
        <div className='actions'>
          <Action className='button' id="Cancel" handleAction={() => edited ? setShowModal(true) : onCancelHandler()} label='cancel' />
          <Action className='button primary' id="Save" handleAction={onSubmit} label='save' disabled={!edited} />
        </div >
      </div> 
      <Modal show={showModal} onClose={() => setShowModal(false)} heading="unsaved-changes" actions={[
          { text: 'leave', onClick: onCancelHandler, appearance: 'primary' },
          { text: 'stay',  onClick: () => setShowModal(false) },
      ]}>
        <span>{I18n('sure-to-leave')}</span>
      </Modal>
      <Modal show={showConfirmOnUpdate} onClose={() => setShowConfirmOnUpdate(false)} heading={I18n('edit-user-role')} actions={[
          { text: 'confirm', onClick: () => { setShowConfirmOnUpdate(false); onSave(); }, appearance: 'primary' },
          { text: 'cancel',  onClick: () => setShowConfirmOnUpdate(false) },
      ]}>
        <p>Making changes to this user role will affect {<Pages.FindUser.AsLink target={'_blank'} text={`${userCount} users`} role={role.id} />}.</p>
        <p>{I18n('sure-to-continue')}</p>
      </Modal>
    </div>
  );
};

export default (props) => {
  const [Roles] = useRoles();
  
  const [{ role: r }, setLocationState] = useLocationParams();
  const [users, usersLoading]           = useSnapshot(User);

  const id          = (typeof r === 'object' && r !== null) ? r.id : r;
  const role        = (typeof r === 'object' && r !== null) ? r : Roles.get(id || "");
  const ancestors   = role ? Roles.ancestorsOf(role).map(r => r.id) : [];
  const userCount   = role ? Object.values(users).filter(({data: { roles=[] }}) => roles.find( r => r === role.id || ancestors.includes(r))).length : 0;
  const permissions = Roles.all().filter(r => r.id !== role?.id && r.id !== Roles.anonymous.id && r.id !== Roles.admin.id && r.id !== Roles.superAdmin.id)
                                 .filter(r => (role?.includes || []).includes(r.id) /* current list of included permissions must be included so that the selector shows them */ 
                                           || !hasAllRoles([r.id])(role?.includes || []))
                                 .map(({ id, label, description }) => ({ label, description, value: id }));

  const handleRegister = (data) => props.createRole(data).then(_ => history.replace(Pages.ViewRole.PATH, { role: data.id }));
  const handleUpdate   = (data) => props.updateRole(data).then(_ => history.replace(Pages.ViewRole.PATH, { role: data.id }));
  const handleSave     = () => role.id ? handleUpdate(role) : handleRegister(Role.new(role).data); 

  return (
    <Loading text={Text.resolve("App.loading-data")} show={usersLoading} size='xlarge'>
      <Edit
        role={role || {}}
        userCount={userCount}
        permissions={permissions}
        update={role?.id}
        onSave={handleSave}
        onCancelHandler={_ => history.replace(Pages.FindRole.PATH)} // TODO: this "should" be the default behaviour of any View ?
        onValueChange={r => setLocationState({ role: { ...role, ...r } })} // TODO: this "should" be the default behaviour of any View ?
        I18n={props.I18n}
      />
    </Loading>
  );
};
