import * as colorUtils from 'services/server/functions/report/colors';

import { uniq } from 'ramda';

const colors = ['#ffe384', '#ffd460', '#ffbb32', '#ffa228', '#ffeebc', '#ffd8a2'];
export const getStatsConfig = (eventClasses) => {
  const classes = uniq(eventClasses || []);
  return classes.reduce((all, c, idx) => {
    all[c] = {
      value: c,
      style: { backgroundColor: colorUtils.increaseLightness(colors[idx % colors.length], -0.2), fill: colors[idx % colors.length] }
    }; 
    return all;
  }, {});
};
