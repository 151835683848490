import { isBrowser } from '../../env';

let config, secrets;

const listeners = [];
const notify = _ => { listeners.forEach(l => { l(config); }); }

const addOnConfigurationChanged = listener => {
  const idx = listeners.length;
  listeners.push(listener);
  config && listener(config);
  return () => listeners.splice(idx, 1)[0];
}

////////////////
///////////////////
///////////////////////////////////////////////
///////////////////////////////////////////////

////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 
//////////

if (isBrowser()) {
  require('./config.client').onRegionChanged((_, c) => {
    config = c;
    notify();
  });
}

export { config, secrets, addOnConfigurationChanged };
