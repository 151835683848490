import { memo } from 'react';

import { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "ui/components/Text";

import useCurrentUser from 'ui/hooks/useCurrentUser';
import { User } from 'services/server/functions/model/authentication/model';

export const UploadUserSignatureButton = memo(({ onResolve }) => {
    const [currentUser] = useCurrentUser();

    const hasAccessToSignature = UploadUserSignatureButton.hasAccess(currentUser);
    if (!hasAccessToSignature) return <></>;

    const onClickUploadSignatureButton = () => onResolve();

    return (
        <>
            <div id="upload-user-signature" >
                {
                    <CustomModal
                        id={'upload-user-signature-confirmation-modal'}
                        btn
                        heading={Text.resolve('module.diagnosis.fe.component.upload_user_signature')}
                        label={Text.resolve('module.diagnosis.fe.component.upload_user_signature')}
                        cancelLabel={Text.resolve("global.button.close")}
                        secondaryLabel={Text.resolve("global.button.continue")}
                        secondaryAction={onClickUploadSignatureButton}
                        appearance={'default'}
                        size="sm"
                    >
                        <div className="confirmation-modal">
                            <Text>
                                module.diagnosis.fe.component.upload_user_signature_confirmation_modal
                            </Text>
                        </div>
                    </CustomModal>
                }
            </div>
        </>
    );
});

UploadUserSignatureButton.hasAccess = (currentUser) => {
    return currentUser.hasAccess(User.commands.SET_SIGNATURE);
};