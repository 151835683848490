import { getBrowserStorageItem, setBrowserStorageItem } from '../../../../../features/helpers/browserStorage';

export const cacheConfiguration = (configuration) => {
    setBrowserStorageItem(createConfigurationKey(), configuration)
}

export const retrieveCachedConfiguration = () => {
    return getBrowserStorageItem(createConfigurationKey());
}

const createConfigurationKey = () => {
    return `configuration`;
}
