import { Fragment } from "react";
import Spinner from '@atlaskit/spinner';
import valueProvider from "services/server/functions/forms/valueProvider";

const getValue = (id, name, form, prvValue, onChange) => {
  const newValue = id !== undefined ? valueProvider(id, form) : prvValue;
  if (onChange && newValue !== prvValue) onChange({target: {name, value: newValue}});

  return newValue;
}

export const CustomField = ({asConfig, asForm, type, form, ...fieldProps}) => {
  let CustomFieldComponent = undefined;
  try {
    CustomFieldComponent = require(`./${type.split('_').map(s => s.replace(/(\w)(\w*)/g,
      (_g0,g1,g2) => {return g1.toUpperCase() + g2.toLowerCase();})).join('')}Field`);
  } catch (error) {
    console.error("Could not load custom field type", type, error)
    // Ignore: use Fragment component by default
  }

  if (asForm) fieldProps.value = getValue(fieldProps.id, fieldProps.name, form, fieldProps.value, fieldProps.onChange);

  return CustomFieldComponent === undefined ? <Fragment/> 
       : asConfig ? <CustomFieldComponent.AsConfig {...fieldProps}/>
       : asForm ? <CustomFieldComponent.AsForm {...fieldProps}/>
       : <CustomFieldComponent.AsView {...fieldProps}/>;
}

const COMPUTED_FIELDS_ACTIONS = {
  'bmi-comp': (newForm) => { // (height / (weight^2)) * 703
    const height = parseFloat(newForm.find(entry => entry.field.id === "height-in")?.value);
    const weight = parseFloat(newForm.find(entry => entry.field.id === "weight-p")?.value);
    if (!height || !weight) return undefined;
    return ((weight / (height**2)) * 703).toFixed(2);
  },
};

const recalculateComputedFields = (newForm, onChange) => {
  newForm.filter(entry => entry.field.computed).forEach(entry => {
    const newValue = COMPUTED_FIELDS_ACTIONS[entry.field.id]?.(newForm);
    if (newValue !== entry.value) {
      if (entry.value === undefined) delete entry.value;
      entry.value = newValue;
      onChange({ target: { name: entry.field.name, value: newForm }});
    }
  });
};

export const Form = (props) => props.form === undefined ? <div className="centered"><Spinner size="large"/></div>
: props.form.map((entry, idx) => entry.field.computed ? <></> : <CustomField
  key={`${props.name}_${idx}`}
  {...entry.field}
  value={entry.value}
  comments={entry.comments}
  name={`${props.name}_${idx}`}
  label={entry.field.name}
  form={props.form}
  onChange={event => {
    const value = event.target.type === 'select-multiple' ? [] : event.target.value;
    if (event.target.type === 'select-multiple') for (let i = 0; i < (event.target.selectedOptions || []).length; i++) value.push(event.target.selectedOptions[i].value);
    const newForm = [...props.form];
    const idx = parseInt(event.target.name.split('_').pop().split('.')[0]);
    newForm[idx] = {...newForm[idx], value, comments: event.target.comments};
    if (newForm[idx].value === undefined) delete newForm[idx].value;
    if (newForm[idx].comments === undefined) delete newForm[idx].comments;
    if (props.onChange) props.onChange({target: {name: props.name, value: newForm}});
    recalculateComputedFields(newForm, props.onChange);
  }}
  onValidate={props.onValidate}
  showErrors={props.showErrors}
  asForm={true}
/>);