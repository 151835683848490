import { useRef, useState } from 'react';
import useEffectOnMount from './useEffectOnMount';
import history from 'history.js';

export default () => {
  const hashTimer    = useRef();
  const locHash      = useRef();
  const locationHash = () => `${history.location.pathname}${history.location.search}${JSON.stringify(history.location.state || {})}`;
  const [_, refresh] = useState();
  const listenLocationHash = () => {
    hashTimer.current = setTimeout(_ => {
      const currentHash = locationHash(); 
      if (locHash.current !== currentHash) refresh(locHash.current = currentHash); 
      listenLocationHash();
    }, 500); 
    
    return (() => hashTimer && clearTimeout(hashTimer.current));
  };
  useEffectOnMount(listenLocationHash);

  return [locHash.current]
}