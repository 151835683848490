import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';

import './style.css';

export const ClearButton = ({ id, value, handleClick }) => {
    const hiddeClearButton = typeof value === "undefined";
    return (
        <div id={id} name={id} className="schema-form-clear-component">
            {!hiddeClearButton && (
                <div onClick={handleClick}>
                    <SelectClearIcon size="small" />
                </div>
            )}
        </div>
    )
};
