import { isAuthenticated, SESSION_TIMEOUT_MINUTES as DEFAULT_TIMEOUT } from 'services/iam';

//TODO: implements these actions as commands/events of the User model
export const UPDATE_USER     = 'UPDATE_USER';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const INVALID_LOGIN   = 'INVALID_LOGIN';
export const LOGIN_ERROR     = 'LOGIN_ERROR';
export const LOGIN           = 'LOGIN';
export const LOGOUT          = 'LOGOUT';

export const SESSION_TIMEOUT_MINUTES = +process.env.REACT_APP_SESSION_TIMEOUT_MINUTES || DEFAULT_TIMEOUT;

export const updateActivity = (timestamp) => dispatch => dispatch({type: UPDATE_ACTIVITY, timestamp});

export const isSessionExpired   = () => {
  const lastActivity = require('../..').store?.getState().authentication?.login?.lastActivity;
  return lastActivity !== undefined ? (Math.floor(Math.abs(new Date() - new Date(lastActivity)) / 1000 / 60) > SESSION_TIMEOUT_MINUTES) : !isAuthenticated();
}
export const isSessionActive  = () => Boolean(require('../..').store?.getState().authentication?.login?.lastActivity);
export const keepSessionAlive = () => !isSessionExpired() && updateActivity(Date.now())(require('../..').store.dispatch);