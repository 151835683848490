import Config from '../config';
import { Joi, mail } from '../../../validation/rules';
import { when, UNAUTHORISED_POLICY } from '../../policies';
import { extensions, withDefaults } from '../..';
import { Roles } from '../../../iam/roles';

const preferencesModels = {};

let Preferences = (parent) => {
  let currentModel = preferencesModels[parent?.name];
  if (!currentModel) {
    currentModel = withDefaults()({
      context: Config.context,
      name: 'Preferences',
      label: `${parent?.label} preferences`,
      schema: Joi.object().unknown(true),
      events: {
        PREFERENCE_SET: {},
        ...extensions.getEvents(() => Preferences(parent), extensions.events),
      },
      commands: {
        SET_PREFERENCE: {
          label: `Set preference for ${parent?.label.toLowerCase() || '<undefined parent>'}`,
          get schema() { return Joi.object().unknown(true); },
          get event() { return Preferences(parent).events.PREFERENCE_SET },
        },
        ...extensions.getCommands(() => Preferences(parent), extensions.commands),
      },
      queries: {
        GET_INHERITED: {
          get schema() {
            return Joi.object().keys({
              ownerId: Joi.string().uri().required(),
              preferenceId: Joi.string().required(),
            });
          },
          newRequest: (args, metadata) => ({
            action : Preferences(parent).queries.GET_INHERITED.new(args, metadata),
            depends: [],
          }),
        },
      },
    }, parent);
    preferencesModels[parent?.name] = currentModel;
  }
  return currentModel;
};

let System = {
  context: Config.context,
  name: 'System',
  schema: Joi.object(),
  events: {
    CUSTOM_CLAIMS_REFRESHED: {},
    SNAPSHOTS_REFRESHED: {},
    ALL_USERS_DELETED: {},
  },
  commands: {
    DELETE_ALL_USERS: {
      roles: [Roles.superAdmin.id],
      checkPolicies: (_req, _1, _2, { user: currentUser }) => when(currentUser?.data.id !== require('../../../config/users.server').config.admin.aggregate.id).rejectWith(UNAUTHORISED_POLICY),
      get schema() { return Joi.object().keys({ id: System.schema.extract('id').default(System.newURN('CLI')) }); },
      get event() { return System.events.ALL_USERS_DELETED; }
    },
    REFRESH_CUSTOM_CLAIMS: {
      roles: [Roles.superAdmin.id],
      checkPolicies: (_req, _1, _2, { user: currentUser }) => when(currentUser?.data.id !== require('../../../config/users.server').config.admin.aggregate.id).rejectWith(UNAUTHORISED_POLICY),
      get schema() { return Joi.object().keys({ id: System.schema.extract('id').default(System.newURN('CLI')), mail }); },
      get event() { return System.events.CUSTOM_CLAIMS_REFRESHED; }
    },
    REFRESH_SNAPSHOTS: {
      roles: [Roles.superAdmin.id],
      checkPolicies: (_req, _1, _2, { user: currentUser }) => when(currentUser?.data.id !== require('../../../config/users.server').config.admin.aggregate.id).rejectWith(UNAUTHORISED_POLICY),
      get schema() {
        return Joi.object().keys({
          id: System.schema.extract('id').default(System.newURN('CLI')),
          filter: Joi.object().keys({
            id: System.schema.extract('id'),
            context: Joi.object().keys({ name: Joi.string() }),
            aggregate: Joi.object().keys({ name: Joi.string(), id: Joi.string() }),
          })
        });
      },
      get event() { return System.events.SNAPSHOTS_REFRESHED; }
    }
  }
};
System = withDefaults()(System);

export { System, Preferences }