import React from "react";

import Details from "ui/components/Details";
import { getUISchemaFormContentLabels, getUISchemaFormId, getUISchemaFormSchema } from "../../utils/formUtils";
import { TitleField } from "../TitleField";
import DetailsBlock from "ui/components/DetailsBlock";

import './style.css';

const GroupFormChild = ({ items }) => (
    <>
        {items?.map(groupChild => (
            <div key={getUISchemaFormId(groupChild)} className="schema-form-group-childs">
                {groupChild?.content}
            </div>
        ))}
    </>
);

const getGroupNameTitle = ({ owner, groupDependencies }) => {
    let firstChild;
    try {
        const defualtName = owner?.name ? getUISchemaFormContentLabels(owner)?.groupName : "";
        firstChild = groupDependencies?.length > 0 && groupDependencies[0];
        const { groupName } = getUISchemaFormContentLabels(firstChild);
        return groupName || defualtName;
    } catch {
        return owner?.name || firstChild?.name || "";
    }
};

export const GroupForm = ({
    name,
    formData,
    children,
    schemaProperties
}) => {
    const { owner, groupDependencies } = schemaProperties.reduce(
        (acc, property) => {
            const schema = getUISchemaFormSchema(property);
            const isSameGroup = schema?.group === name;
            if (isSameGroup) {
                if (schema?.isGroupOwner) {
                    acc.owner = property;
                    return acc;
                }
                acc.groupDependencies.push(property);
            }
            return acc;
        }, { owner: {}, groupDependencies: [] }
    );
    const hasOwnerData = !!formData[owner?.name];
    const label = getGroupNameTitle({ owner, groupDependencies });
    return (
        <div className="schema-form-group">
            <Details id={name} label={label}>
                {children && <TitleField>{children}</TitleField>}
                {owner?.name ? (
                    <DetailsBlock className={`${hasOwnerData ? " " : "schema-form-group-blocks"}`}>
                        {owner?.content}
                        {hasOwnerData &&
                            <GroupFormChild items={groupDependencies} />
                        }
                    </DetailsBlock>
                )
                    : (
                        <DetailsBlock className="">
                            <GroupFormChild items={groupDependencies} />
                        </DetailsBlock>
                    )
                }

            </Details>
        </div>
    );
};