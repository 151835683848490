import React, { Children, useState } from 'react';
import HelpIcon from '@atlaskit/icon/glyph/question-circle';
import InfoIcon from '@atlaskit/icon/glyph/info';
import ReactTooltip from 'react-tooltip';
import {Text} from 'ui/components/Text';
import { ReactComponent as TimeIcon } from './images/icon-study-timezone.svg';

import './style.css';

const WarningIcon = (props) => <svg className="warningIcon" width={props.width || "80"} height={props.height || "80"} viewBox="0 0 24 24" focusable="false" role="presentation"><g fillRule="evenodd"><path d="M12.938 4.967c-.518-.978-1.36-.974-1.876 0L3.938 18.425c-.518.978-.045 1.771 1.057 1.771h14.01c1.102 0 1.573-.797 1.057-1.771L12.938 4.967z" fill="currentColor"/><path d="M12 15a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2" fill="inherit"/></g></svg>;
const CancelledIcon = (props) => <svg className="cancelledIcon" width={props.width || "80"} height={props.height || "80"} viewBox="0 0 24 24" focusable="false" role="presentation"><g fillRule="evenodd"><circle fill="currentColor" cx="12" cy="12" r="10"></circle><path d="M13.477 9.113l-4.36 4.386a1 1 0 101.418 1.41l4.36-4.386a1 1 0 00-1.418-1.41z" fill="inherit"></path><path d="M9.084 10.501l4.358 4.377a1 1 0 101.418-1.411L10.5 9.09a1 1 0 00-1.417 1.411z" fill="inherit"></path></g></svg>;

const Icons = {
  help   : props => <HelpIcon size      = "small" {...props} />,
  time   : props => <TimeIcon className = "help"  {...props} />,
  info   : props => <InfoIcon size      = "small" {...props} />,
  warning: props => <WarningIcon                  {...props} />,
  cancelled: props => <CancelledIcon {...props} />,
}

const HelpTooltip = ({id, type="help", place="right", width=20, height=20, icon, className="", show=true, content, variables, children}) => {
  icon = icon || Icons[type]?.({ width, height }) || Icons.help;
  const [left, setLeft] = useState(0),
        replace = e => show && e && setLeft(e.offsetWidth - width);

  return (
    <div className={['grid-col', 'vcentered', 'centered'].join(" ")}>
      { Children.map(children, child => React.cloneElement(child, { style: {textAlign: 'right'} })) }
      <div ref={replace} data-tip data-for={id} className={[type, ...className.split(/\s+/)].join(" ")} style={{visibility: show ? 'visible' : 'hidden', textAlign: 'left'}}>{icon}</div>
      <ReactTooltip className={["HelpTooltip", ...className.split(/\s+/)].join(" ")} id={id} place={window.innerWidth > 670 ? place : "bottom"} offset={{ left }} type="light" effect="solid" border multiline>
        { typeof content === 'function' ? content : 
          <Text variables={variables}>{content}</Text> // TODO: we should delegate to the client code to decide when and what to translate. Left here for backwards compatibility
        }
      </ReactTooltip>
    </div>
  );
}

export default HelpTooltip
