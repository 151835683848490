import {ascend, groupBy, path, prop, sortWith} from 'ramda';

import {Text} from 'ui/components/Text';
import asyncComponent from '../AsyncComponent';

export const Option = asyncComponent(() => import('@atlaskit/select').then(m => ({default: m.components.Option})));
export const Select = props => {
  const { default: AKSelect }  = require('@atlaskit/select');
  
  const canSelectAll = props.isMulti && !props.hideAllOption && (!props.value || props.value.length < props.options.length);
  
  const handleSelect = (selected) => props.onChange({ target: { type: props.isMulti ? 'select-multiple' : 'select', selectedOptions: props.isMulti && selected, value: !props.isMulti && selected && selected.value, name: props.name } }); // Adapt to standard HTML event interface
  const handleSelectAll = () => handleSelect(props.options);
  
  const { value: _1, onChange: _2, options, placeholder, disabled, ...otherProps } = props;
  const selectOptions = sortWith([ascend(prop('label'))])(Object.entries(groupBy(path(['group']))(props.options)).map(([group, opts]) => ({label: group && group !== "undefined" ? group : "", options: opts})))

  return (
    <>
      <AKSelect 
        isSearchable
        classNamePrefix="akselect" 
        menuIsOpen={props.open}
        isMulti={props.isMulti}
        inputProps={{ autoComplete: "off" }}
        placeholder={placeholder ? Text.resolve(placeholder) : `${Text.resolve("select")}...`}
        onChange={handleSelect} 
        options={selectOptions} 
        defaultMenuIsOpen={props.defaultOpen}
        onInputChange={(_1, {action}) => { if (action === "menu-close" && props.onClose) props.onClose(); }}
        value={options.filter(o => props.value !== undefined && props.value !== null && (Array.isArray(props.value) ? props.value.includes(o.value) : props.value === o.value))}
        className={'akSelect'}
        isDisabled={disabled}
        {...otherProps} 
      />
      { props.isMulti && canSelectAll && <div className="indented link" onClick={handleSelectAll}><Text>select-all</Text></div> }
    </>
  );
};

export const CreatableSelect = asyncComponent(() => import('@atlaskit/select').then(m => ({default: m.CreatableSelect})));