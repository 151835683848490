import { DISMISS_NOTIFICATION, NEW_NOTIFICATION } from "../../actions/Notifications";

const initialState = [];

export default function notification(state = initialState, action) {
  switch (action.type) {
    case NEW_NOTIFICATION: {
      const notifications = state.slice(); // Copy previous content
      notifications.unshift(action);
      return notifications;
    }
    case DISMISS_NOTIFICATION: {
      if (action.id)              return state.filter(notification => notification.id !== action.id);
      else if (action.appearance) return state.filter(notification => notification.appearance !== action.appearance);

      return state.slice(1);
    }
    default:
      return state;
  }
}
