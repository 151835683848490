import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import {
  AkNavigationItemGroup,
  AkNavigationItem,
} from '@atlaskit/navigation';
import Tooltip from '@atlaskit/tooltip';

import history from 'history.js';

import './style.css';

export const itemSelected = (item) => {
  const matchPath = (item, location) => location.pathname.startsWith(item.root ? `/${item.root}` : item.link);
  const matchState = (item, location) => !item.linkState || Object.entries(item.linkState).every(([key, val]) => key in (location.state || {}) && (val === undefined || location.state[key] === val));
  return item.itemsGroup ? item.itemsGroup.some(itemSelected) : (matchPath(item, history.location) && matchState(item, history.location));
}

const OptionalLink = (item) => (!('show' in item) || item.show) ? <div className={`${["navLink"].concat(item.isOpen ? "open" : []).concat(itemSelected(item) ? "active" : []).join(" ")}`} id={item.id ? item.id : item.text.trim().toLowerCase().replace(' ', '-')} onClick={() => (item.link || item.linkState) && history.push({pathname: item.link ? item.link : history.location.pathname, state: item.linkState ? Object.entries(item.linkState).reduce((locState, [key, value]) => {locState[key] = value || history.location.state[key]; return locState;},{}) : {}})}>
  {item.children}
</div> : null;

const OptionalTooltip = ({children, enabled, ...props}) => enabled ? <Tooltip position="right" {...props}>{children}</Tooltip> : children;

const getNavItemText = (item) => item?.info ? item.info : item.text;
const NavItem = ({item, isOpen, onItemSelected}) => <OptionalLink 
  {...item}
  // TODO: Maybe we can somehow reuse NavLink location property??
  isOpen={isOpen}
>
  <OptionalTooltip content={item.text} enabled={!isOpen}>
    <AkNavigationItem
      onClick={(e) => { if (onItemSelected) onItemSelected(e, item); }}
      icon={item.icon ? item.icon : null}
      linkComponent={item.linkComponent}
      text={getNavItemText(item)}
      autoFocus={false}
      isDropdownTrigger
      dropIcon={item.nested ? item.dropIcon ? item.dropIcon : <ChevronRightIcon label="add" size="medium"/> : null}
    />
  </OptionalTooltip>
</OptionalLink>;

const NavOptionsGroup = (props) =>
  <AkNavigationItemGroup hasSeparator={props.hasSeparator} title={props.titleGroup}>{
    props.data.map((item, idx) => item.modal ? <item.modal location={history.location} trigger={({onClick}) => <NavItem item={item} isOpen={props.isOpen} onItemSelected={(...args) => { onClick(); props.onItemSelected(...args); }}/>} key={idx}/> 
                                             : <NavItem key={idx} item={item} isOpen={props.isOpen} onItemSelected={props.onItemSelected}/>)}
  </AkNavigationItemGroup>;

export default NavOptionsGroup;

