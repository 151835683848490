import { useContext } from 'react';
import {UserContext} from "features/providers/userContextProvider";

const useCurrentUser = (options={}) => {
  const { getUser } = useContext(UserContext);
  
  return [getUser(options)];
}

export const useHasAccess = (...resources) => {
  const [currentUser] = useCurrentUser();
  return [resources.every(r => currentUser.hasAccess(r))];
}
export const IfHasAccess = ({resource, resources=[resource], children}) => {
  const [ canAccess ] = useHasAccess(...resources);

  return canAccess ? children : <></>;
}

export default useCurrentUser;

