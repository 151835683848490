import { Fragment } from "react";
import Details from "../Details";
import { Joi } from "services/server/functions/validation/rules";
import { Text } from "../Text";
import { WithValidation } from "../WithValidation";

const ValidatedInput = ({onValidate, showError, field, schema, ...props}) => <WithValidation field={field || props.name} schema={schema} onValidate={onValidate} showError={showError} value={props.value}><input type="number" name={props.name || field} {...props} value={props.value || ''} /></WithValidation>

export const AsForm = ({name, label, question, required, placeholder, showErrors, onChange, ...otherProps}) => console.log("Whow errors", showErrors) || <div className="grid-row fullWidth">
  <Text values={question || label}/>{required && <sup className="mandatory">*</sup>}
  <ValidatedInput
      field={name || question}
      showError={showErrors} 
      schema={required ? Joi.number().positive().required() : Joi.number().positive()}
      className="field" 
      placeholder={Text.resolveValue(placeholder)}
      onChange={(event) => {
        if (event.target?.value === '') return onChange({ ...event, target: { ...event.target, name: event.target.name, value: undefined }});
        onChange(event);
      }}
      {...otherProps}
    />
</div>;

export const AsView = ({value, default: defaultValue="-", name, question, id, description}) => <Details 
  id={id} 
  label={Text.resolveValue(name || question)} 
  help={Text.resolveValue(description)} 
  value={value ?? defaultValue} 
/>;

export const AsConfig = Fragment; // TODO