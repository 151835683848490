import LockCircleIcon from "@atlaskit/icon/glyph/lock-circle";
import i18n from 'services/server/functions/i18n';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useSnapshot from 'ui/hooks/useSnapshot';
import {getSnapshot} from 'ui/hooks/useSnapshot/globalState';

const BlockedIcon = () => <div title={i18n.resolve("no-access-tooltip")}><LockCircleIcon/></div>;

const AggregateAsText = (model, dataToText, view) => { // TODO: dataToText should not be needed, the view should be the query that returns the text to be shown
  const hasAccess = (currentUser) => !view?.roles || currentUser?.hasAccess(view);

  const Component = ({ id: snapId, text, [model.name.split('/').pop().toLowerCase()]: id = snapId, title, ...props }) => {
    const Spinner = require('@atlaskit/spinner').default;
    const [currentUser] = useCurrentUser(props.options);
    const snapProvided = (id && getSnapshot(id)) || 'data' in props;
    const [ snap, loadingSnap, ...rest ] = useSnapshot(model, id, {autoStart: !text && !snapProvided && id !== undefined, listenToChanges: false });
    const hasError = rest[4];
    const snapshot = snapProvided ? (id && getSnapshot(id)) || (props.data && {data: (props.data)}) : snap;
    const dataText = dataToText(snapshot?.data);
    const loading = snapProvided ? dataText === undefined : loadingSnap;
    
    const isAuthorised = (hasAccess(currentUser) && currentUser?.hasOwnership(snapshot || id)) || !(props.blockOnError && hasError);

    const tooltip = (!loading && !snapshot && !isAuthorised) ? i18n.resolve("no-access-tooltip") : title;
    // FIXME: snapshot can only be hidden in cases where for instance an orgasanition/HCS has been removed anbd hasn't been unassigned from a user, but we should implement data invariants check 
    return text ? <span title={tooltip}>{text}</span> : loading ? <Spinner size="small"/> : isAuthorised ? <span className={!snapshot?.data ? "hidden" : ""} title={tooltip}>{dataText || "Unknown"}</span> : <span title={tooltip}><BlockedIcon/></span>;
  };
  Component.hasAccess = (_id) => hasAccess(require("services/iam").currentUserSync);

  return Component;
}

export default AggregateAsText;