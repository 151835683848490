import { useRef, useCallback } from 'react';
import { makeCancelable } from 'services/server/functions/promises';
import useEffectOnMount from './useEffectOnMount';

// TODO: refactor according to our needs
// Copied from: https://github.com/rajeshnaroth/react-cancelable-promise-hook/blob/master/index.js

export default function useCancellablePromise() {
  const promises  = useRef();
  const cancelAll = useCallback(_ => {
    (promises.current || []).forEach(p => p.cancel());
    promises.current = [];
  }, [promises.current]);

  useEffectOnMount(_ => cancelAll);

  function cancellablePromise(p, id=(promises.current || []).length) {
    const cPromise = makeCancelable(p, id);
    promises.current = promises.current || [];
    promises.current.push(cPromise);
    return cPromise;
  };

  return { cancellablePromise, cancelAll };
}