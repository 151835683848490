import moment from "moment";
import { getMonth, getYear } from "date-fns";
import { range } from "ramda";
import ArrowLeft from '@atlaskit/icon/glyph/chevron-left-large';
import ArrowRight from '@atlaskit/icon/glyph/chevron-right-large';

import i18n from "services/server/functions/i18n";
import { toPascalCase } from "features/helpers/objectHelper";

import './style.css';

const months = moment.localeData(i18n.current).months()?.map(m => toPascalCase(m));

export const getCalendarHeader = ({ initialYear = 2005 } = {}) => {
    const years = range(initialYear, getYear(new Date()) + 1, 1);
    return ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div className="calendar-custom-header-root">
            <div className={`${prevMonthButtonDisabled ? "calendar-custom-header-disabled" : "calendar-custom-header-cursor"}`} onClick={() => !prevMonthButtonDisabled && decreaseMonth()}>
                <ArrowLeft size="medium" />
            </div>
            <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)} >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }>
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <div className={`${nextMonthButtonDisabled ? "calendar-custom-header-disabled" : "calendar-custom-header-cursor"}`} onClick={() => !nextMonthButtonDisabled && increaseMonth()}>
                <ArrowRight size="medium" />
            </div>
        </div>
    );
};