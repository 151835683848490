import { useState, useCallback } from 'react';
import { ariaDescribedByIds } from '@rjsf/utils';

import './style.css';

export const TextAreaWidget = ({
    id,
    options = {},
    placeholder,
    value,
    required,
    disabled,
    readonly,
    autofocus = false,
    onChange,
    onBlur,
    onFocus,
    schema,
}) => {
    const [state, setState] = useState({
        content: value ? value : ''
    });
    const { content } = state;
    const handleChange = ({ target: { value } }) => setState({
        ...state,
        content: value
    });

    const notifyChanges = useCallback(
        (value) => onChange(value),
        [onBlur, id]
    );

    const handleBlur = useCallback(
        () => onBlur(id, content),
        [onBlur, id]
    );

    const handleFocus = useCallback(
        () => onFocus(id, content),
        [id, onFocus]
    );

    const handleMouseLeave = () => notifyChanges(content);

    return (
        <textarea
            id={id}
            name={id}
            className="schema-form-textarea-widget"
            value={content}
            placeholder={placeholder}
            required={required}
            disabled={disabled || schema.isReadOnly}
            readOnly={readonly}
            autoFocus={autofocus}
            rows={options.rows}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            onMouseLeave={handleMouseLeave}
            aria-describedby={ariaDescribedByIds(id)}
        />
    )
};
