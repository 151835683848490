// This Patch implementation is inspired by https://jsonpatch.com/

import { deleteNestedFields, flattenObject } from "../helpers/utils";

export const OPERATIONS = {
    REMOVE: 'remove',
}

export const applyPatch = (obj, patch = []) => {
    for (const change of patch) {
        if (change.op === OPERATIONS.REMOVE) removeOp(obj, change);
    }
    return obj;
};

export const getPatchForNullValues = (obj, basePath = '') => {
    const resetFields = Object.keys(flattenObject(obj)).filter(k => obj[k] === null);
    deleteNestedFields(obj, resetFields);
    const patch = resetFields.map(fieldPath => ({ op: OPERATIONS.REMOVE, path: `${basePath}${fieldPath}` }));
    return patch;
};

const removeOp = (obj, change) => {
    deleteNestedFields(obj, [change.path]);
    return obj;
};