import * as models from 'services/server/functions/model';
import { rxExecute } from './executor';

const snakeToCamel = str => // probably defined somewhere already
  str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

const withActionableActions = m => {
  if (m.UIActions) return m; // recursive model. Already seen model.

  // actionable model: provides to the model the ability to directly "execute" UI actions
  m.UIActions = {};
  m.actions ??= {...m.commands, ...m.queries}; // somehow there are models without actions field ??
  Object.keys(m.actions)
        .forEach(k => {
    m.actions[k].exec               = (args, options) => rxExecute(m.actions[k].newRequest(args), options)();
    m.actions[k].asAction           = (args, options) => async (dispatch) => rxExecute(m.actions[k].newRequest(args), options)(dispatch);
    m.actions[k].asBackgroundAction = (args, options) => async (dispatch) => rxExecute(m.actions[k].newRequest(args), {...options, execute: { ...options?.execute, background: true }})(dispatch);
    m.UIActions[snakeToCamel(k)]    = m.actions[k].asAction;

    Object.values(m.entities || {}).forEach(withActionableActions);
  });

  return m;
}

const MODELS = models.all();

Object.values(MODELS).forEach(module => {
        Object.values(module)
              .filter(model => Boolean(model.context))
              .forEach(withActionableActions)
      });


export default MODELS;
export const resolve = models.resolve;
