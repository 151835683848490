import { useState } from 'react';
import DropList , {Group, Item, SecondaryText} from '@atlaskit/droplist';
import MoreIcon from "@atlaskit/icon/glyph/more";
import './style.css';
import Styled from 'styled-components';

const CenteredIcon = Styled.span`
  display:flex;
  flex-direction: column;
  align-items: center;
`;

// const HTMLSwitch = Styled.div`
//   display: ${props=>props.hidden ? 'none' : 'block'};
// `;


const DefaultTrigger = ({id, appearance}) => 
  <div id="grandparent">
    <div id={id || 'dropmenu'} className={`${appearance} button`}>
      <CenteredIcon>
        <MoreIcon />
      </CenteredIcon>
    </div>
  </div>;

const CustomDropList = props => { // FIXME: needs further refactoring or complete replacements
  // const [isActive, setIsActive] = useState(props.isActive);
  // const [isHidden, setIsHidden] = useState(props.isHidden);
  const [isOpen, setIsOpen]     = useState(props.isOpen);
  
  const onOpenChange = () =>{
    setIsOpen(!isOpen);
    // setIsActive(false);
    // setIsHidden(true);
    // !isActive && props.returnOpenState(isActive);
  };

  const onClick = _ => {
    setIsOpen(!isOpen);
    // setIsActive(!isActive);
    // setIsHidden(!isHidden);
    // props.returnOpenState(isActive);
    // props.onClick();
  };

  return (
    <DropList 
      isOpen={isOpen} 
      trigger={props.trigger || <DefaultTrigger {...props} />} 
      onClick={onClick}
      appearance={props.appearance}
      position={props.position}
      isTriggerNotTabbable={false}
      onOpenChange={onOpenChange}
      isMenuFixed={false}
      isLoading={false}
      >
      {/* <HTMLSwitch hidden={isHidden}> */}
        {props.children}
      {/* </HTMLSwitch> */}
    </DropList>
  )
}

CustomDropList.defaultProps ={
  isOpen         : false,
  returnOpenState: ()=>{},
  appearance     : "secondary",
  position       : "right top",
  isActive       : false,
  isHidden       : false,
  onClick        : ()=>{},
};



export default CustomDropList;
export  {DropList as DropListNative ,Group, Item, SecondaryText};
