import { useState } from 'react';
import { WithValidation } from 'ui/components/WithValidation';

export default ({onValidate, schema, showError, type, ...props}) => {
  const [ className, setClassName ] = useState(type === "password" ? "protected" : "clear");
  const toogleClass = () => setClassName(className === "protected" ? "clear" : "protected");
  
  return (
    <WithValidation field={props.name} onValidate={onValidate} showError={showError} schema={schema} {...props}>
      <input {...props} type={className === "protected" ? "password" : "text"} value={props.value || ''} className={`${type} ${className}`} />
      { type === "password" && <div onClick={toogleClass} className={`${type} ${className}`} /> }
    </WithValidation>
  );
}