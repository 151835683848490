
import { useState } from 'react';

import './style.css';

const Component = ({ selected , onChange , ...props}) => {
    const [checked, setChecked] = useState(selected || false);

    const changeHandler = (e) => {
        const status = e.target.checked;
        setChecked(status);
        onChange(status);
    }

    return <input type="checkbox" {...props} onChange={changeHandler} checked={checked} />;
};

export const Checkbox = ({ label, ...props }) => label ?
    <div className="checkbox-component">
        <span>{label}</span>
        <Component {...props} />
    </div> : <Component {...props} />;