import { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "ui/components/Text";

export default (props) => {
  return(
    <CustomModal  id={props.id || 'info'}
                  asLink
                  message={''}
                  heading={Text.resolve(props.title || '')}
                  cancelLabel={Text.resolve("close")}
                  secondaryLabel={Text.resolve("save")}
                  label={Text.resolve(props.label || 'show')}
    >
      <div className="grid-row">
        {props.info && props.info.split ? props.info.split('\n').map((l, i) => <p key={`info${i}`}>{l}</p>) : props.info}
      </div>
    </CustomModal>
  )
};