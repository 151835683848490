import { checkDuplicatedID, userHasOwnership } from '../../policies';
import { Joi } from '../../../validation/rules';

import Config from '../config';

import { Roles } from '../../../iam/roles';

import { extensions, withDefaults } from '../..';
import { checkAccessRights, checkDuplicatedKey } from './policies';
import ProductStudy from './ProductStudy';
import ProductTest from './ProductTest';
import { User } from '../../authentication/model';
import ProductFeatures from './ProductFeatures';

const productModels = {};

const generateBaseProduct = (parent) => {
  let currentModel = productModels[parent?.name];
  if (!currentModel) {
    currentModel = withDefaults()({
      context: Config.context,
      name: Config.aggregatesNames.Product,
      schema: Joi.object().keys({
        // Used for internationalization
        key: Joi.string(),
        accessRights: Joi.array().items(Joi.string().uri()),
        attributeRights: Joi.array().items(Joi.string().uri()),
      }),
      entities: {
        Preferences: (self) => {
          return require('../../system/model').Preferences(self);
        },
      },
      events: {
        PRODUCT_CREATED: {},
        STUDY_CONFIG_SETTINGS_SET: {},
        STUDY_TEMPLATE_SETTINGS_SET: {},
        STUDY_SLEEP_REPORT_SETTINGS_SET: {},
        SIGNAL_SETTINGS_SET: {},
        REPORT_SETTINGS_SET: {},
        FEATURE_ACTIONS_SET: {},
        PRODUCT_ACCESS_RIGHTS_UPDATED: {},
        PRODUCT_ATTRIBUTE_RIGHTS_UPDATED: {},
        ...extensions.getEvents(() => generateBaseProduct(parent), extensions.events),
      },
      commands: {
        CREATE_PRODUCT: {
          checkPolicies: (product, _, executor) => Promise.all([
            checkDuplicatedID({ id: Product.newURN(product.key), ...product }, Product, executor),
            checkDuplicatedKey(product.key, executor),
            checkAccessRights(product),
            // checkAttributeRights(product),
          ]),
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              key: Product.schema.extract('key').required(),
              accessRights: Product.schema.extract('accessRights'),
            });
          },
          event: async (action, _, product) => {
            const event = generateBaseProduct(parent).events.PRODUCT_CREATED.new(product);
            return event;
          },
        },
        SET_STUDY_CONFIG_SETTINGS: {
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              id: Product.schema.extract('id').required(),
              userId: Joi.referenceOf(User),
              settings: ProductStudy.schemas.StudyConfigSettings.schemas.Configure.getSchema(ProductStudy.schemas.StudyConfigSettings.fields.map(f => f.field)),
              reset: Joi.boolean().default(false),
            });
          },
          event: (action, productSnap, { attributeRights }) => {
            return generateBaseProduct(parent).events.STUDY_CONFIG_SETTINGS_SET.new({
              id: productSnap.aggregate.id,
              attributeRights,
            });
          },
        },
        SET_STUDY_TEMPLATE_SETTINGS: {
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              id: Product.schema.extract('id').required(),
              userId: Joi.referenceOf(User),
              settings: ProductStudy.schemas.StudyTemplateSettings.getSchema(ProductStudy.schemas.StudyTemplateSettings.fields.map(f => f.field)),
            });
          },
          event: (action, productSnap, { attributeRights, accessRights }) => {
            return generateBaseProduct(parent).events.STUDY_TEMPLATE_SETTINGS_SET.new({
              id: productSnap.aggregate.id,
              attributeRights,
              accessRights,
            });
          },
        },
        SET_STUDY_SLEEP_REPORT_SETTINGS: {
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              id: Product.schema.extract('id').required(),
              settings: ProductTest.schemas.SleepReportSettings.schema,
              reset: Joi.boolean().default(false),
            });
          },
          event: (action, productSnap, { accessRights }) => {
            return generateBaseProduct(parent).events.STUDY_SLEEP_REPORT_SETTINGS_SET.new({
              id: productSnap.aggregate.id,
              accessRights,
            });
          },
        },
        SET_SIGNAL_SETTINGS: {
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              id: Product.schema.extract('id').required(),
              settings: ProductTest.schemas.SignalSettings.schema,
              reset: Joi.boolean().default(false),
            });
          },
          event: (action, productSnap, { attributeRights }) => {
            return generateBaseProduct(parent).events.SIGNAL_SETTINGS_SET.new({
              id: productSnap.aggregate.id,
              attributeRights,
            });
          },
        },
        SET_REPORT_SETTINGS: {
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              id: Product.schema.extract('id').required(),
              settings: ProductTest.schemas.ReportSettings.schema,
              reset: Joi.boolean().default(false),
            });
          },
          event: (action, productSnap, { accessRights }) => {
            return generateBaseProduct(parent).events.REPORT_SETTINGS_SET.new({
              id: productSnap.aggregate.id,
              accessRights,
            });
          },
        },
        SET_FEATURE_ACTIONS: {
          roles: [Roles.superAdmin.id],
          get schema() {
            const possibleSchemas = Object.values(ProductFeatures.schemas);
            return Joi.object({
              id: Product.schema.extract('id').required(),
              featureId: Joi.string().valid(...possibleSchemas.map(s => s.formId)).required(),
              actions: possibleSchemas.reduce((actionsSchema, s, index) => actionsSchema.when('featureId', {
                is: possibleSchemas[index].formId,
                then: possibleSchemas[index].schema,
              }), Joi),
              reset: Joi.boolean().default(false),
            });
          },
          event: (action, productSnap, { accessRights }) => {
            return generateBaseProduct(parent).events.FEATURE_ACTIONS_SET.new({
              id: productSnap.aggregate.id,
              accessRights,
            });
          },
        },
        UPDATE_PRODUCT_ACCESS_RIGHTS: {
          checkPolicies: (product) => Promise.all([
            checkAccessRights(product)
          ]),
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              id: Product.schema.extract(['id'], schema => schema.required()),
              accessRights: Product.schema.extract(['accessRights'], schema => schema.required()),
            });
          },
          get event() {
            return generateBaseProduct(parent).events.PRODUCT_ACCESS_RIGHTS_UPDATED
          }
        },
        UPDATE_PRODUCT_ATTRIBUTE_RIGHTS: {
          /*checkPolicies: (product) => Promise.all([
            checkAttributeRights(product)
          ]),*/
          roles: [Roles.superAdmin.id],
          get schema() {
            return Joi.object({
              id: Product.schema.extract(['id'], schema => schema.required()),
              attributeRights: Product.schema.extract(['attributeRights'], schema => schema.required()),
            });
          },
          get event() {
            return generateBaseProduct(parent).events.PRODUCT_ATTRIBUTE_RIGHTS_UPDATED
          }
        },
        ...extensions.getCommands(() => generateBaseProduct(parent), extensions.commands),
      },
      queries: {
        GET_CONTEXT: {
          roles: [],
          get schema() {
            return Joi.object().keys({});
          },
        },
        GET_INHERITED_PREFERENCES: {
          checkPolicies: (data, _, executor, { user }) => Promise.all([
            userHasOwnership(user, data.ownerId.split("/").length === 3 ? data.ownerId : data.ownerId.split("/").slice(0, -2).join("/"), executor),
          ]),
          roles: [],
          get schema() {
            return Joi.object().keys({
              ownerId: Joi.string().uri().required(), // Product URN
              preferenceId: Joi.string().required(),
            });
          },
          newRequest: (args, metadata) => ({
            action : generateBaseProduct(parent).queries.GET_INHERITED_PREFERENCES.new(args, metadata),
            depends: [],
          }),
        },
      },
      roles: {
        manager: [Roles.superAdmin],
        executor: [Roles.superAdmin],
      },
    }, parent);
    productModels[parent?.name] = currentModel;
  }
  return currentModel;
};

export const ProductEntity = (parent) => generateBaseProduct(parent);

const Product = generateBaseProduct();
export default Product;