import { ColorsType } from 'features/constants/colors';

import './style.css';

export const Component = ({
    children,
    type = "button",
    color = ColorsType.Primary,
    disabled = false,
    className = "",
    ...props
}) => {
    return (
        <button
            id="button-component"
            {...props}
            type={type}
            className={
                `button-component ${color} ${className} ${disabled ? ' disabled' : ''}`
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export const Button = Component;