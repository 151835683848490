import { SELECT_ROLES } from 'services/redux/actions/Authentication/impersonation';

export default function impersonation(state = {roles: undefined}, action) {
  switch (action.type) {
    case SELECT_ROLES:
      return Object.assign({}, state, {
        roles: action.roles
      });
    default:
      return state;
  }
}