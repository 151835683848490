import { resolve } from '../../../model';
import AdministrationConfig from '../../../model/administration/config';
import { HealthcareSite, Organisation, Product } from '../../../model/administration/model';
import { getSnapshotFromEventsById } from '../../../snapshot';

export const getOwnerProduct = async (ownerId, productId) => {
  const ownerModel = resolve(ownerId);
  const productEntityId = ownerModel.name === "Product" ? ownerId : ownerModel.entities.Product.newURN(ownerModel.id(ownerId), Product.id(productId));
  // If the owner is a Product or an Organisation it must exist
  const productSnap = await getSnapshotFromEventsById(productEntityId);

  // If the owner is a HCS we might need to inherit the Product from the Organisation
  if (!productSnap) {
    const ownerSnap = await getSnapshotFromEventsById(ownerId);
    const hcsOwnerId = Organisation.ownersFrom(ownerSnap.data).filter(id => id !== AdministrationConfig.organisation.root.id)[0];
    const result = await getOwnerProduct(hcsOwnerId, productId);
    if (result) return result;
    throw new Error(`Could not resolve Product for ownerId: ${ownerId}`);
  }

  return productSnap;
};

export const getAllOwnersProducts = async (id, productId) => {
  if (HealthcareSite.isReference(id)) {
    const [ownerProductSnap, snap] = await Promise.all([getOwnerProduct(id, productId), getSnapshotFromEventsById(id)]);
    const ownerId = Organisation.ownersFrom(snap.data).filter(i => i !== AdministrationConfig.organisation.root.id)[0];
    const allProducts = await getAllOwnersProducts(ownerId, productId);
    allProducts.push(ownerProductSnap);
    return allProducts;
  }
  if (Organisation.isReference(id)) {
    const [ownerProductSnap, allProducts] = await Promise.all([getOwnerProduct(id, productId), getAllOwnersProducts(productId, productId)]);
    allProducts.push(ownerProductSnap);
    return allProducts;
  }

  // If it's a Product
  const products = [];
  const originalProductSnap = await getSnapshotFromEventsById(productId);
  products.push(originalProductSnap);
  return products;
};