import { useCallback } from "react";
import { AutoComplete } from "features/components/AutoComplete";
import Details from "ui/components/Details";
import { FiltersType } from "../../utils";

export const ClinicianField = ({ filters, onFilterChange, items }) => {
    const handleChange = useCallback((option) => onFilterChange({ [FiltersType.Clinician]: option?.value }), [onFilterChange]);
    return (
        <Details id="clinician" label="clinician.Requesting clinician">
            <AutoComplete
                id={FiltersType.Clinician}
                items={items}
                value={filters[FiltersType.Clinician]}
                handleChange={handleChange}
                selectProps={{
                    isMulti: false,
                    isClearable: true,
                    isDisabled: !items?.length,
                }}
                forceDefault={false}
                forceOneItem={false}
            />
        </Details>
    );
};