import Table from 'ui/components/Table';
import moment from 'moment';
import { Text } from 'ui/components/Text';
import DetailsBlock from 'ui/components/DetailsBlock';

const SUB_CATEGORIES = ['prone', 'left', 'right', 'up']; // For backwards compatibility with AcuCore v1.3.0 output format which does not include 'group' field on sub-categories

const getSignalPositionData = (recording) => recording?.signals?.["Sleep position"]?.params;
const getPositions = (recording) => Object.keys(getSignalPositionData(recording) || {}).filter(p => p.endsWith('_pct')).map(p => p.split('_pct')[0]);
const positionStats = (position, positionData) => ({
    type      : position,
    group     : positionData?.[position]?.group ? positionData?.[position]?.group : SUB_CATEGORIES.includes(position) ? 'non_supine' : undefined,
    value     : positionData?.[`${position}_pct`]?.value || 0,
    percentage: positionData?.[`${position}_pct`]?.value !== undefined ? positionData?.[`${position}_pct`]?.value : '-', 
    sleepTime : positionData?.[position]?.value !== undefined ? positionData[position] : '-'
});
const getPositionSignalData  = (recording) => getPositions(recording).reduce((positions, position) => {
    const sourceData = getSignalPositionData(recording);
    positions.push(positionStats(position, sourceData));
    return positions;
}, []).sort((a, b) => a.value > b.value ? -1 : a.value === b.value ? 0 : 1).reduce((all, pos, _idx, src) => {
    if (!pos.group) {
        all.push(pos);
        src.filter(p => p.group === pos.type).forEach(p => all.push(p))
    }
    return all;
}, []);

const PositionChart = ({recording}) => {
    require('./style.css');

    /// Move to QUERY and use useProjection
    const data = getPositionSignalData(recording).map(({type: position, ...stats}, idx) => ({
        idx, 
        position,
        group        : stats.group,
        label        : Text.resolve(`ViewTest.position.${position}`),
        percentage   : `${stats.percentage}%`,
        sleepTime    : moment().startOf('day').milliseconds(stats.sleepTime.value * (stats.sleepTime.unit === "minutes" ? 60 : 1 /* assuming seconds */) * 1000).format(stats.sleepTime.unit === "minutes" ? 'H[h] m[m]' : 'H[h] m[m] s[s]')
    }));
    ////////// END

    return <>
        <div className="title"><Text>module.diagnosis.fe.view.diagnosis.body_position</Text></div>
        <DetailsBlock id="PositionChart" className="reportCard">
            <Table 
                id="PositionChartTable"
                keyField={'idx'}
                withViewAction={false}
                rowClasses={(e) => e.group !== undefined ? 'sub-category' : `category-${e.position}`}
                columns={{
                    label     : { content: "ViewTest.diagnosis.position", formatter: (l, {group}) => group === undefined ? <span className="position-label">{l}</span> : <span className="position-label">{`— ${l}`}</span> },
                    percentage: { content: "ViewTest.diagnosis.position.pc", style: {textAlign: "center"}  },
                    sleepTime : { content: "global.text.time", style: {textAlign: "center"} },
                }}
                data={data}
            />
        </DetailsBlock>
    </>;
}

PositionChart.hasData = ({recording}) => Boolean(getPositions(recording).length);

export default PositionChart;