import i18n from '../i18n';
import { isCommand } from './urn';
import moment from 'moment';
import {getErrorData} from '../errors';
import * as codes from '../errors/codes';

export const mapCloudFunctionErrorCode = (funcErrorCode) => funcErrorCode === "permission-denied" || funcErrorCode === "unauthenticated" ? codes.PERMISSION_ERROR
                                                           : funcErrorCode === "invalid-argument" ? codes.INVALID_DATA_ERROR
                                                           : funcErrorCode === "unavailable" || funcErrorCode === "data-loss" ? codes.NETWORK_ERROR    
                                                           : funcErrorCode === "deadline-exceeded" || funcErrorCode === "aborted" ? codes.TIMEOUT_ERROR
                                                           : funcErrorCode === "not-found" ? codes.NOT_FOUND_ERROR
                                                           : funcErrorCode === "resource-exhausted" ? codes.QUOTA_ERROR
                                                           : funcErrorCode === "unimplemented" ? codes.UNAVAILABLE_SERVICE_ERROR
                                                           : funcErrorCode === "already-exists" ? codes.DUPLICATE_ERROR
                                                           : funcErrorCode === "out-of-range" ? codes.EXPIRED_ERROR
                                                           : codes.INTERNAL_SYSTEM_ERROR;

const functions = async () => {
  const {app}  = await import('../../../firebase');
  const {config}  = await import('../firebase/config');
  const region = config.functionsRegion;

  if (process.env.REACT_APP_USE_EMULATOR === "1") {
    app.functions().useEmulator('localhost', process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT);
  }
  
  return ({
    options: app.options,
    call   : (endpoint) => (request) => app.functions(region).httpsCallable(`${endpoint}?key=${app.options.apiKey}`, { timeout: 530 * 1000 }/* TODO: maybe this config should be im sync with cloud functions server config?? */)(request).catch(e => {
      const errData = getErrorData(e?.message) || getErrorData(e?.details);
      const err = errData || e;
      return Promise.reject((err?.code && err.code === 'cancelled') ? {} : (!errData && err?.code) ? require('.').failed(request, require('../errors').newError(mapCloudFunctionErrorCode(err.code), err)) : err);
    }),
  });
}

export const authenticate = (code) => functions().then(({call}) => call('authenticate')({value: code})).then(({data}) => data);

export const getReport = (reportId, reportData) => {
  if (Array.isArray(reportId)) {
    reportData = reportId.map(({reportId: rId, report, ...data}) => ({reportId: rId, report: { date: moment().format("DD MMM YYYY HH:mm z"), language: i18n.current, ...report }, ...data }));

  } else if (Array.isArray(reportData)) { // TODO: workaround to run multiple requests of the same report, that should be aggregated somehow in the resunting file
    reportData = reportData.map(data => ({reportId, ...({report: { date: moment().format("DD MMM YYYY HH:mm z"), language: i18n.current }, ...data}) }));
    
  } else {
    reportData = {reportId, ...({report: { date: moment().format("DD MMM YYYY HH:mm z"), language: i18n.current }, ...reportData}) }
  }
  
  return functions().then(({call}) => call('generateReport')(reportData)).then(({data: encodedFile}) => encodedFile) // TODO export API for any query using HTTP
}

export const commandExecutor = () => ({
   execute: async (command) => functions().then(({call}) => call('execute')(command)).then(({data}) => data)
});

export const queryExecutor = () => ({ // TODO export API for any query using HTTP too
  //execute     : (query) => iamProvider.currentUser().then(user => require('./query').executeQuery({...query, user})), // Query dependencies are executed as administrastor, so cannot be executed in client side
  execute     : async (query) => functions().then(({call}) => call('execute')(query)).then(({data}) => data),
  loadSnapshot: (id, model) => import('../../../redux').then(({store}) => store.snapshots(model, id).get()) // TODO: model parameter might be deprecated: at this point we expect a full reference, so model no needed as URN contains all info required
});

export const execute = (request) => {
  // eslint-disable-next-line no-unused-vars
  const { user, ...requestWithoutUser } = request;
  return (isCommand(requestWithoutUser.action.id) ? commandExecutor : queryExecutor)().execute(requestWithoutUser);
}