import { Fragment, useCallback, useContext, useEffect, useState } from 'react';

import { UserContext } from 'features/providers/userContextProvider';
import DetailsBlock from 'ui/components/DetailsBlock';
import ExportPDF from 'ui/components/ExportReport';
import QRCode from 'qrcode';
import Spinner from '@atlaskit/spinner';
import { Study } from 'services/server/functions/model/diagnosis/model';
import {Text} from 'ui/components/Text';
import i18n from 'services/server/functions/i18n';
import moment from 'moment';
import useCancellablePromise from 'ui/hooks/useCancellablePromise';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { getImgUrl, getImgUrlByLang, useAssetsURL } from 'ui/hooks/useAssetsUrl';

const exportLetter = (id, product) => () => require('services/server/functions/executor/executor.client')
                                        .getReport('diagnosis.Study.letter', { id, product })
                                        .then(encFile => `data:application/pdf;base64,${encFile}`);
const ActivationCode = (props) => {
  const { selectedProduct } = useContext(UserContext);
  const [currentUser] = useCurrentUser();
  const [qrCode, setQrCode] = useState("");
  const promises = useCancellablePromise();
  const { assetsURl } = useAssetsURL();

  const study          = props.study?.data || { activationCode: undefined, patient: {instructions: {providedPhone: props.providedPhone}}, date: undefined };
  const patientID      = study.patient && (study.patient.id || "-");
  const studyDate      = study.date ? moment(study.date).format("L") : "-";
  const providedPhone = study.patient?.instructions.providedPhone;
  const fileName = i18n.resolve('report.Instructions.file_name');
  const letterFilename = `${fileName}_${patientID}_${studyDate}.pdf`; //FIXME: duplicated code. This is used in other places too
  
  const notifyQRError = useCallback(_ => props.notify('error', 'CreateStudy.activation-code.loading-failed', "CreateStudy.activation-code.loading-failed-details"), []);
  useEffect(() => { (study.activationCode || {}).value && 
                     promises.cancellablePromise(QRCode.toDataURL(study.activationCode.value), 'QR Code')
                      .then(setQrCode)
                      .catch(reason => !reason.isCanceled && notifyQRError())
                  }, [study.activationCode, promises, notifyQRError])
  
  const daysToExpire = moment(study.activationCode?.until).diff(moment(), 'days');

  return (
    <>
      {
        assetsURl && (
          providedPhone === true ?
            <div id="StudyInstructions" className="pageSection grid-row">
              <h2><Text>CreateStudy.instructions.mobile-app.setup</Text></h2>
              <span className="stepsIntro"><Text>CreateStudy.instructions.mobile-app.success</Text></span>
              <Fragment>
                <DetailsBlock inline>
                  <div className="grid-row">
                    <h3><Text>component.create_study.instructions_message</Text></h3>
                    <span><Text>CreateStudy.instructions.mobile-app.login</Text></span>
                    <span><Text>CreateStudy.instructions.mobile-app.load-with-code</Text></span>
                  </div>
                  <div className="vcentered instructionImg">
                    <img style={{ maxWidth: "50%" }} alt="" src={getImgUrlByLang(assetsURl, 'load-study-app-screen')} />
                  </div>
                </DetailsBlock>
                <DetailsBlock inline>
                  <div className="grid-row">
                    <h3><Text>CreateStudy.instructions.mobile-app.enter-code</Text></h3>
                    {(study.activationCode || {}).value && <Fragment><span>{Text.resolve('CreateStudy.instructions.mobile-app.enter-code.subtitle', { plural: daysToExpire, days: daysToExpire })}</span>
                      <span className="activationCode">{study.activationCode && study.activationCode.value}</span></Fragment>}
                  </div>
                  {!(study.activationCode || {}).value ? <div className="centered grid-row">
                    <span className="italic"><Text>component.create_study.loading_message</Text></span>
                    <div><Spinner size="large" /></div>
                  </div>
                    : <div className="instructionImg">
                      <img style={{ maxWidth: "100%" }} alt="" src={qrCode} />
                    </div>}
                </DetailsBlock>
                <DetailsBlock inline>
                  <div className="grid-row">
                    <h3><Text>CreateStudy.instructions.mobile-app.finish</Text></h3>
                    <span><Text>CreateStudy.instructions.mobile-app.finish.subtitle</Text></span>
                  </div>
                  <div className="vcentered instructionImg">
                    <img style={{ maxWidth: "50%" }} alt="" src={getImgUrl(assetsURl, 'study-created-app-screen.png')} />
                  </div>
                </DetailsBlock>
                <DetailsBlock inline>
                  <div className="grid-row">
                    <h3><Text>CreateStudy.instructions.mobile-app.print-instructions</Text></h3>
                    <span><Text>CreateStudy.instructions.mobile-app.print-instructions.text1</Text></span>
                    <span><Text>CreateStudy.instructions.mobile-app.print-instructions.text2</Text></span>
                    <ExportPDF disabled={!currentUser.hasAccess(Study.queries.GET_STUDY_LETTER)} label="global.download_pdf_message" secondary downloadFile={letterFilename} id="exportLetter" urlCallback={exportLetter(study.id, selectedProduct?.key)} />
                  </div>
                  <div className="vcentered instructionImg">
                    <img style={{ maxWidth: "50%" }} alt="" src={getImgUrl(assetsURl, 'printer-code.png')} />
                  </div>
                </DetailsBlock>
              </Fragment>
            </div>
            : providedPhone === false ?
              <DetailsBlock inline>
                {<div className="grid-row">
                  <h3><Text>CreateStudy.activation-code.print</Text></h3>
                  <span><Text>CreateStudy.activation-code.text1</Text></span>
                  <span><Text>CreateStudy.activation-code.text2</Text></span>
                  <span><Text>CreateStudy.activation-code.text3</Text></span>
                  {!(study.activationCode || {}).value ? <div className="centered grid-row">
                    <span className="italic"><Text>component.create_study.loading_message</Text></span>
                    <div><Spinner size="large" /></div>
                  </div>
                    : <ExportPDF disabled={!currentUser.hasAccess(Study.queries.GET_STUDY_LETTER)} label="global.download_pdf_message" secondary downloadFile={letterFilename} id="exportLetter" urlCallback={exportLetter(study.id, selectedProduct?.key)} />}
                </div>}
                <img className="codeImage printImg" alt="" src={getImgUrl(assetsURl, 'printer-code.png')} />
              </DetailsBlock>
              : null)
      }
    </>
  );
}

export default ActivationCode;