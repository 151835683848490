import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import Details from 'ui/components/Details';
import {Text} from 'ui/components/Text';
import * as Administration from 'modules/administration';
import { Organisation } from 'services/server/functions/model/administration/model';
import WithSnapshot from 'ui/components/WithSnapshot';
import {getLocationParams} from 'ui/hooks/useLocationParams';
import { Redirect } from 'react-router-dom';
import { FeatureToggleSettings, FeatureToggleTypes } from 'features/preferences/FeatureToggleSettings';
import { StudySleepReportSettings } from 'features/preferences/StudySleepReportSettings';

const ViewOrganisationSettingsUI = ({ snapshot, organisation, I18n }) => {
  const profile = snapshot?.data?.profile || {};

  return (
    <div id='ConfigureOrganisationProfile' className='page'>
      <Back />
      <div className='pageHeader'>
        <h1 className='title'>{I18n('title')}</h1>
      </div>
      <div className="pageContent">
        <div className='pageSection'>
          <div className='sectionHeader'>
            <div className='title'>{I18n('subtitle')}</div>
            <div className='actions'>
              <Administration.ConfigureOrganisationProfile.AsLinkButton className='primary' text="edit" organisation={organisation} />
            </div>
          </div>
          <DetailsBlock>
            <Details label={I18n("logo")}>
              <div className="grid-row centered">
                {!profile.logo ? <span>{I18n('page.organisation.empty_logo_message')}</span> : <img src={profile.logo} alt={Text.resolve("logo")} />}
              </div>
            </Details>
          </DetailsBlock>
        </div>
        <FeatureToggleSettings
          ownerId={organisation} accessTo={FeatureToggleTypes.AllFeatures}
        />
        <StudySleepReportSettings ownerId={organisation} />
      </div>
    </div>
  );
};

const fallbackComponent = _ => <Redirect to={Administration.FindOrganisation.PATH}/>;
const ViewOrganisationSettings = (props) => WithSnapshot(Organisation, getLocationParams().organisation, {fallbackComponent})(ViewOrganisationSettingsUI)({...props, organisation: getLocationParams().organisation});

ViewOrganisationSettings.displayName = "ViewOrganisationSettings";
export default ViewOrganisationSettings;