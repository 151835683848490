import { Fragment } from "react";
import Spinner from '@atlaskit/spinner';

import { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "ui/components/Text";
import { SwitchFilter } from "./SwitchFilter";

import './style.css';

export const FilterOptionLayout = {
    Modal: 'Modal'
};

export const FiltersOptionTypes = {
    Switch: 'Switch',
};

const FilterOptionComponent = {
    [FiltersOptionTypes.Switch]: SwitchFilter
};

const getFiltersBlock = (filtersList, onChangeOptions) => (
    <>
        {filtersList.map(({ type, mode, fieldName, title, options }, index) => {
            const component = FilterOptionComponent[type] || FilterOptionComponent[FiltersOptionTypes.Switch];
            return (
                <Fragment key={`${type}-${index}`}>
                    <div className="title"><Text>{title}</Text></div>
                    {component({ mode, fieldName, options, onChangeOptions })}
                </Fragment>
            )
        }
        )}
    </>
);

export const FilterOption = ({ label, disabled, title, appearance = 'primary', layout = FilterOptionLayout.Modal, filtersList, setFiltersList, getCustomActions = () => [], isLoading, onOpen, }) => {
    const getFields = () => filtersList.reduce((fields, { fieldName, options = [] }) => {
        fields[fieldName] = options?.reduce((selectedOpts, opt) => {
            if (opt?.selected) {
                selectedOpts.push(opt?.id);
            }
            return selectedOpts;
        }, []);

        return fields;
    }, {});
    const filterActions = [{ text: Text.resolve("global.button.close"), id: 'close-action-filter' }, ...getCustomActions({ getFields })];

    const onChangeOptions = (fieldName, options) => {
        const updatedFilters = [...filtersList]?.map((filter) => {
            let updatedFilter = filter;

            const isChangedFilter = filter?.fieldName === fieldName;
            if (isChangedFilter) {
                updatedFilter = {
                    ...filter,
                    options
                };
            }
            return updatedFilter;
        });

        setFiltersList(updatedFilters);
    };

    const filtersBlock = getFiltersBlock(filtersList, onChangeOptions);

    return layout === FilterOptionLayout.Modal ? (
        <CustomModal btn
            label={label}
            heading={title}
            disabled={disabled}
            appearance={appearance}
            showError={false}
            actions={filterActions}
            onToggle={(e) => e && onOpen?.()}
            size="lg"
        >
            {isLoading ? <div className="spinner"><Spinner size='xlarge' /></div> : <div id="filter-option" className="root">{filtersBlock}</div>}
        </CustomModal>
    ) : (
        <div id="filter-option" className="root">
            {isLoading ? <div className="spinner"><Spinner size='xlarge' /></div> : <>
                {filtersBlock}
                {filterActions}
            </>
            }
        </div>
    );
};
