import { useEffect, useState } from 'react';

import DetailsBlock from 'ui/components/DetailsBlock';
import Player from '../components/VideoViewer';
import {Text} from 'ui/components/Text';
import i18n from 'services/server/functions/i18n';
import { getImgUrl, getImgUrlByLang, useAssetsURL } from 'ui/hooks/useAssetsUrl';

export default (props) => {
  const { study, isCreate }     = props;
  const [videoURL, setVideoURL] = useState();
  const hasProvidedPhone        = study?.data?.patient?.instructions?.providedPhone;
  const requiresOximetry        = study?.data?.patient?.instructions?.requiresOximetry;
  const adhesives = 2 + (Object.keys(study?.data.tests || {}).length || 1);
  const { assetsURl } = useAssetsURL();

  useEffect(() => {
    
    const getVideoUrl = async () => {
      const firebase = await import('services/firebase');
      firebase.Storage().getBucketAssets({
        path: i18n.resolve('module.diagnosis.fe.createStudy.adhesiveReplace', { lang: i18n.current}),
        defaultPath: i18n.resolve('module.diagnosis.fe.createStudy.adhesiveReplace', { lang: 'en'}),
        callback: setVideoURL
      });
    };

    getVideoUrl();

    document.querySelector('.pageContainer').scrollTop = 0; document.querySelector('.pageContainer').scrollLeft = 0;

    if (props.onBack) { // VERY UGLY ... we need a hook or something for registering actions in the page
      const GoBack = document.createElement("div");
      GoBack.setAttribute('class', 'button secondary');
      GoBack.innerHTML = `<span class="actionContent">${Text.resolve('back')}</span>`
      GoBack.addEventListener('click', props.onBack);

      const actions = document.querySelector('.pageFooter > .actions');
      actions.insertBefore(GoBack, actions.firstChild);
      return () => actions.removeChild(GoBack);
    }

  }, []);

  return <>
    {assetsURl && <div id="StudyInstructions" className="pageSection grid-row">
      <span className="stepsIntro"><Text>instructions.follow</Text></span>
      <div className="steps">
        <DetailsBlock inline>
          <div className="grid-row">
            <h3><Text>{requiresOximetry ? 'instructions.charge-sensors' : 'instructions.charge'}</Text></h3>
            <span><Text>{requiresOximetry ? 'instructions.connect-sensors' : 'instructions.connect'}</Text></span>
          </div>
          <div className="vcentered instructionImg" >
            <img alt="" src={getImgUrl(assetsURl,'sensor-charging-light.png')} />
            {requiresOximetry && <img alt="" style={{ transform: "scale(0.7)" }} src={getImgUrl(assetsURl,'finger-sensor-charging.svg')} />}
          </div>
        </DetailsBlock>
        {hasProvidedPhone && <DetailsBlock inline>
          <div className="grid-row">
            <h3><Text>instructions.mobile-app.charge</Text></h3>
            <span><Text>instructions.mobile-app.charge.subtitle</Text></span>
          </div>
          <div className="vcentered instructionImg">
            <img alt="" src={getImgUrl(assetsURl,'charge-mobile.png')} />
          </div>
        </DetailsBlock>}
        <DetailsBlock inline>
          <div className="grid-row">
            <h3><Text>{requiresOximetry ? 'instructions.clean-sensors' : 'instructions.clean'}</Text></h3>
            <span><Text>{requiresOximetry ? 'instructions.wipe-sensors' : 'instructions.wipe'}</Text></span>
          </div>
          <div className="vcentered instructionImg">
            <img alt="" src={getImgUrl(assetsURl,'acupebble-sensor.png')} />
            {requiresOximetry && <img alt="" style={{ transform: "scale(0.7)" }} src={getImgUrl(assetsURl,'finger-sensor-01.svg')} />}
          </div>
        </DetailsBlock>
        <DetailsBlock inline>
          <div className="grid-row">
            <h3><Text>module.diagnosis.fe.createStudy.patient_instructions.replace_adhesive_text</Text></h3>
            <span><Text>instructions.adhesive-used</Text></span>
          </div>
          <div className="vcentered instructionImg">
            <Player height={window.innerHeight * 0.75} width={(9 / 16) * (window.innerHeight * 0.75)} url={videoURL} />
          </div>
        </DetailsBlock>
        {hasProvidedPhone && !isCreate && <DetailsBlock inline>
          <div className="grid-row">
            <h3><Text>instructions.mobile-app.setup</Text></h3>
            <span><Text>component.create_study.instructions_message</Text></span>
            <span><Text>component.create_study.patient_details_instructions_message</Text></span>
          </div>
          <div className="vcentered instructionImg">
            <img style={{ maxWidth: "70%", maxHeight: "200px" }} alt="" src={getImgUrlByLang(assetsURl, 'load-study-app-screen')} />
          </div>
        </DetailsBlock>}
        {!isCreate && <DetailsBlock inline>
          <div className="grid-row">
            <h3><Text>instructions.prepare-everything</Text></h3>
            <span><Text>instructions.collect</Text></span>
            <ul>
              <li><Text>instructions.collect-item-1</Text></li>
              <li>
                <Text variables={{ adhesives }}>{ props?.labels?.item2 || 'instructions.collect-item-2' }</Text>
              </li>
              <li><Text>{ props?.labels?.item3 || 'instructions.collect-item-3' }</Text></li>
              {hasProvidedPhone &&
                <>
                  <li><Text>instructions.collect-item-4</Text></li>
                  <li><Text>instructions.collect-item-4-b</Text></li>
                  <li><Text>instructions.collect-item-5</Text></li>
                </>
              }
              {!hasProvidedPhone && <li><Text>instructions.collect-item-6</Text></li>}
            </ul>
          </div>
          <div className="vcentered instructionImg grid-col">
            <img style={{ width: '70%' }} alt="" src={getImgUrl(assetsURl, 'doctor-patient.png')} />
            <img style={{ width: '30%' }} alt="" src={getImgUrl(assetsURl, 'shipping-box.png')} />
          </div>
        </DetailsBlock>}
      </div>
    </div>}
  </>;
}