import { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import { equals } from 'ramda';
import { onUserChange, currentUserSync, onSignin, onSignout } from "services/iam";
import i18n from 'services/server/functions/i18n';

import useEffectOnMount from 'ui/hooks/useEffectOnMount';

import { Organisation, HealthcareSite } from "services/server/functions/model/administration/model";
import useSessionStorage from 'ui/hooks/useSessionStorage';
import useSnapshot from 'ui/hooks/useSnapshot';
import { registerEventListener } from 'ui/hooks/useSnapshot/globalState';

import { userFilteredByContext } from './helpers/user';
import { getProductUnits, prepareProduct } from './helpers/product';
import getUserContext from './helpers/getUserContext';
import { currentRegion } from 'services/server/functions/firebase/config/config.client';

const deleteElementFromArray = (arr, elementToDelete) => {
  const indexToDelete = arr.indexOf(elementToDelete);
  if (indexToDelete !== -1) arr.splice(indexToDelete, 1);
  return arr;
}

const newOwners = [];

const CONTEXT_INITIAL_STATE = {
  selectedOwners: [],
  selectedProduct: undefined,
  owners: [],
  products: [],
  loading: true,
  setSelectedOwners: _ => _,
  getUser: _ => currentUserSync,
  i18nProductKey: undefined,
};

const getDefaultProduct = (userContextProducts, selectedProduct) => {
  if (!userContextProducts?.length) return undefined;
  let defaultProduct = userContextProducts.find(p => p.id === selectedProduct?.id);
  if (!defaultProduct && currentRegion === "us") defaultProduct = userContextProducts.find(p => p.key === "ox100");
  if (!defaultProduct) defaultProduct = userContextProducts[0];
  return prepareProduct(defaultProduct);
};

export const UserContext = createContext(CONTEXT_INITIAL_STATE);

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(currentUserSync);
  const userContext = user?.metadata?.context;

  const [sessionCtx, setSessionCtx] = useSessionStorage('UserContext', { selectedOwners: CONTEXT_INITIAL_STATE.selectedOwners, selectedProduct: CONTEXT_INITIAL_STATE.selectedProduct });
  const [contextState, dispatch] = useState({ ...CONTEXT_INITIAL_STATE, selectedOwners: sessionCtx?.selectedOwners || CONTEXT_INITIAL_STATE.selectedOwners, selectedProduct: sessionCtx?.selectedProduct });

  const resetContext = useCallback(() => {
    setSessionCtx(undefined);
    dispatch(CONTEXT_INITIAL_STATE);
  });

  useEffectOnMount(_ => {
    onSignin(_ => { resetContext(); });
    onSignout(_ => { resetContext(); });

    onUserChange(newUser => {
      setUser({ ...newUser });
    })
  }); // Important to create a new object, otherwise it doesn't detect the changes

  // Refresh user context in session storage on context change
  useEffect(_ => {
    if (contextState.selectedOwners?.length && contextState.selectedProduct?.id && (!equals(sessionCtx?.selectedOwners, contextState.selectedOwners) || sessionCtx?.selectedProduct?.id !== contextState.selectedProduct?.id)) setSessionCtx({
      selectedOwners: contextState.selectedOwners,
      selectedProduct: contextState.selectedProduct,
    });
  }, [contextState.selectedProduct?.key, contextState.selectedOwners?.join(';')]);

  // Updates i18n
  useEffect(_ => {
    const selectedProduct = contextState.selectedProduct;
    if (selectedProduct?.key && selectedProduct.key !== i18n.product) {
      console.debug("Updating locales with product", selectedProduct.key);
      i18n.loadProduct(selectedProduct.key).then(() => {
        // Necessary so that the Text component listens to re-renders
        dispatch(s => ({ ...s, i18nProductKey: i18n.product }));
      });
    }
  }, [contextState.selectedProduct?.key]);

  const setSelectedOwners = useCallback((newSelectedOwners) => {
    const selectedOwners = newSelectedOwners?.sort();
    if (!equals(selectedOwners, contextState.selectedOwners)) {
      dispatch(s => ({ ...s, selectedOwners }));
    }
  }, [contextState.selectedOwners?.join(';')]);

  const setProduct = useCallback(async (productOrId) => {
    const selectedProduct = productOrId?.id ? productOrId : contextState.products?.find(p => p.id === productOrId);

    if (selectedProduct && selectedProduct.key !== contextState.selectedProduct?.key) {
      const selectedOwners = user?.data?.owners.filter(o => getProductUnits({ ...contextState, selectedProduct }, userContext).find(own => own.data.id === o)).sort();

      dispatch(s => ({ ...s, selectedOwners, selectedProduct }));
    }
  }, [contextState.selectedProduct?.key, Boolean(user?.data), contextState.owners.map(o => `${o.data.name}:${o.data.id}`).sort().join(';')]);

  // Listen to changes on organisational units data or user owners to initialise and reset the current user context
  useSnapshot(Organisation, { skipContext: true });
  useSnapshot(HealthcareSite, { skipContext: true });
  useEffect(async _ => {
    const userProducts = userContext?.products?.map(prepareProduct) || [];
    const selectedProduct = getDefaultProduct(userContext?.products, contextState.selectedProduct);

    const userOrgUnits = (userContext?.units || []).map(u => ({ data: { ...u, owners: [u.parent || u.id] } }));
    const productUnits = getProductUnits({ ...contextState, selectedProduct, owners: userOrgUnits }, userContext);
    const selectedOwners = (contextState.selectedOwners?.length ? contextState.selectedOwners : (user?.data?.owners || [])).filter(oid => productUnits.some(unit => unit.data.id === oid)).sort();
    if (!selectedOwners.length) selectedOwners.push(...productUnits.map(u => u.data.id).sort()); // If the User is a super admin it might have access to more products than the ones assigned to its owners (ex: user with only ox100 owners) => instead of finding and grouping its owners by product, we will just apply sa100 as the default
    const isReady = Boolean(userContext && userOrgUnits.length && userProducts.length && userOrgUnits.length && userProducts.length && selectedOwners.length && selectedProduct);
    const changed = userOrgUnits.length !== contextState.owners.length || userProducts.length !== contextState.products.length || selectedOwners.length !== contextState.selectedOwners.length || selectedProduct?.key !== contextState.selectedProduct?.key
      || !equals(userOrgUnits.map(u => u.data.id).sort(), contextState.owners.map(o => o.data.id).sort())
      || !equals(userProducts.map(p => p.id).sort(), contextState.products.map(p => p.id).sort())
      || !equals(selectedOwners.sort(), contextState.selectedOwners.sort())
    const userContextReadyAndChanged = isReady && changed;

    if (userContextReadyAndChanged) {
      if (selectedProduct?.id !== contextState.selectedProduct?.id) i18n.loadProduct(selectedProduct.key);
      dispatch(s => ({ ...s, loading: false, owners: userOrgUnits, products: userProducts, selectedOwners, selectedProduct }));
    }
  }, [user?.data?.owners?.sort().join(';'), userContext?.products?.length, userContext?.units?.map(u => u.id)?.sort().join(';')]);

  const updateUserContext = useCallback(async (newOwnerId) => {
    if (!user) {
      console.error("updateUserContext => could not find user");
      return;
    }
    newOwners.push(newOwnerId);
    setUser({ ...user });
    const updatedUserContext = await getUserContext().catch(error => console.error(`updateUserContext => failed to getUserContext: ${error}`));
    setTimeout(() => deleteElementFromArray(newOwners, newOwnerId), 2000); // this seems to fix the issue of the orga dissappearing after creation so... don't remove
    if (!updatedUserContext) return;
    user.metadata.context = updatedUserContext;
    if (currentUserSync) currentUserSync.metadata.context = updatedUserContext;
    setUser({ ...user });
  }, [user, setUser]);

  // Listen to local changes (updates on organisational units or products)
  const onNewEvent = useCallback(async e => {
    if ([Organisation.events.ORGANISATION_REGISTERED.type, HealthcareSite.events.HCS_REGISTERED.type].includes(e.type)) {
      await updateUserContext(e.aggregate.id); // It's important to await, otherwise the selectedOwners won't update properly because the new unit is not available as an option yet
      console.log("userContextProvider onNewEvent", contextState.selectedOwners.concat(e.aggregate.id));
      setSelectedOwners(contextState.selectedOwners.concat(e.aggregate.id));
    } else if ([Organisation.events.ORGANISATION_UNREGISTERED.type, HealthcareSite.events.HCS_UNREGISTERED.type].includes(e.type)) {
      const newOwners = contextState.selectedOwners.filter(o => o !== e.aggregate.id);
      setSelectedOwners(newOwners.length ? newOwners : user?.data?.owners.filter(oid => getProductUnits(contextState, userContext).some(unit => unit.data.id === oid)));
    } // @meir I guess we should listen for new products here??
  }, [contextState.selectedOwners?.join(';'), user?.data?.owners.sort().join(';'), contextState.selectedProduct?.id, contextState.owners.map(o => `${o.data.name}:${o.data.id}`).sort().join(';'), updateUserContext]);
  // Note: it's important it returns a function so that the listeners are cleaned on each useEffect call
  useEffect(_ => registerEventListener({ model: Organisation, onNewEvent }), [onNewEvent]);
  useEffect(_ => registerEventListener({ model: HealthcareSite, onNewEvent }), [onNewEvent]);

  const contextValue = useMemo(_ => ({
    ...contextState,
    setSelectedOwners,
    setProduct,
    getProductUnits: (selectedProduct = contextState.selectedProduct) => getProductUnits({ ...contextState, selectedProduct }, userContext),
    getUser: userFilteredByContext(user, contextState, newOwners),
    withContextSelector: !contextState.loading && (user?.isFromAcurable() || contextState.products.length > 1),
  }), [contextState.selectedOwners?.join(';'), contextState.products?.map(p => p.id)?.join(';'), contextState.selectedProduct?.id, Boolean(user?.data), contextState.owners?.map(o => o.data.id).join(';'), userContext, newOwners.join(';'), contextState?.i18nProductKey]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  )
};
