import { useEffect, useState } from 'react';

import DetailsBlock from 'ui/components/DetailsBlock';
import Player from 'modules/diagnosis/ui/CreateStudy/components/VideoViewer';
import { Text } from 'ui/components/Text';
import i18n from 'services/server/functions/i18n';
import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

const VideoPlayer = ({ url }) => {
    const [videoURL, setVideoURL] = useState();
    
    useEffect(() => {
         const getVideoUrl = async () => {
            const firebase = await import('services/firebase');
            firebase.Storage().getBucketAssets({
                path: url.replace('{{lang}}', i18n.current),
                defaultPath: url.replace('{{lang}}', 'en'),
                callback: setVideoURL
            });
        };

        getVideoUrl();
    }, [url]);

    return (
        <Player
            height={window.innerHeight * 0.75}
            width={(9 / 16) * (window.innerHeight * 0.75)}
            url={videoURL}
        />
    );
};

const Question = () => {
    const { assetsURl } = useAssetsURL();

    return (
        <>
            {assetsURl && (
                <div className="pageSection">
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <span>
                                <Text>Help.replace-adhesive.answer-1</Text>
                            </span>
                            <ul>
                                <li>
                                    <Text>Help.replace-adhesive.answer-2</Text>
                                </li>
                                <li>
                                    <Text>Help.replace-adhesive.answer-3</Text>
                                </li>
                                <li>
                                    <Text>Help.replace-adhesive.answer-4</Text>
                                </li>
                            </ul>
                            <span>
                                <Text>Help.replace-adhesive.answer-5</Text>
                            </span>
                        </div>
                        <div className="instructionImg grid-row">
                            <img
                                style={{
                                    maxWidth: '50%',
                                    marginBottom: '15px',
                                }}
                                alt=""
                                src={getImgUrl(
                                    assetsURl,
                                    'new-adhesive-sensor.png'
                                )}
                            />
                            <VideoPlayer url={i18n.resolve('module.diagnosis.fe.createStudy.adhesiveReplace', { lang: i18n.current})} />
                        </div>
                    </DetailsBlock>
                </div>
            )}
        </>
    );
};

Question.question = Text.resolve('Help.replace-adhesive.question');
export default Question;
