import { useEffect, useState, useMemo, useRef } from "react";
import Modal, { CustomModal } from "ui/acukit/ModalDialog";
import { Text } from "../../../ui/components/Text";

export const BlockCommentModal = ({ id, label, header, defaultValue, onSave, appearance, placeholder, hasAcces, value: overwrittenValue, onOverrideValue = () => { }, size = "xl", children, customActions: overrideActions, onToggle }) => {
    const [value, setValue] = useState();
    const saved = useRef(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        if (overwrittenValue) {
            setValue(overwrittenValue);
            onOverrideValue();
        }
    }, [overwrittenValue]);

    const closeAction = useMemo(() => ({ text: Text.resolve("global.button.close"), id: 'close-action-comment' }), []);

    const customActions = useMemo(() => {
        if (!overrideActions?.length) {
            if (!hasAcces) return [closeAction];
            return [closeAction, {
                text: Text.resolve("global.button.save"), id: 'block-comment-save-action', appearance: 'primary', onClick: () => {
                    onSave(value);
                    saved.current = true;
                    setValue(undefined);
                }
            }];
        }

        let closeIndex = overrideActions.findIndex(a => a.id === 'close-action-comment');
        if (closeIndex >= 0) overrideActions[closeIndex] = closeAction;
        overrideActions.forEach(action => {
            if (action.id !== 'close-action-comment' && action.onClick) action.onClick = e => {saved.current = true; action.onClick(e);}
            action.props = { ...action.props, onSave: _ => {saved.current = true;}, newValue: value };
        });
        return overrideActions;
    }, [hasAcces, value, overrideActions]);

    return <>
        <CustomModal id={id}
            btn
            heading={header}
            label={label}
            showError={false}
            cancelLabel={Text.resolve("global.button.close")}
            actions={customActions}
            appearance={appearance}
            size={size}
            onToggle={e => { 
                const newValue = value || "";
                const prevValue = defaultValue || "";
                if (!e && !saved.current && newValue !== prevValue) setShowConfirmModal(true); 
                else if (!e) setValue(defaultValue)
                
                saved.current = false;
                onToggle?.(e); 
            }}
        >
            <textarea disabled={!hasAcces} className={`field text-area-${size}`} name="comments" placeholder={placeholder} value={value === undefined ? defaultValue : value} onChange={(e) => setValue(e.target.value)} />
            {children}
        </CustomModal>
        <Modal show={showConfirmModal} dontCloseOnClick onClose={() => {setShowConfirmModal(false);}} heading="unsaved-changes-title" actions={[
            { text: 'unsaved-changes-discard',  onClick: () => {setValue(defaultValue); setShowConfirmModal(false);} },
            { text: 'unsaved-changes-save',  onClick: () => {onSave(value); setValue(defaultValue); setShowConfirmModal(false);}, appearance: 'primary' },
        ]}>
            <span>{Text.resolve('unsaved-changes-content')}</span>
        </Modal>
    </>;
};