import { useContext } from 'react';
import { UserContext } from 'features/providers/userContextProvider';
import MODELS from 'modules/model';

const createProduct          = async (data) => await MODELS.administration.Product.commands.CREATE_PRODUCT.exec(data);
const addAccessRightsToProd  = async (data) => await MODELS.administration.Product.commands.UPDATE_PRODUCT_ACCESS_RIGHTS.exec(data);
const assignProductsToOrg    = async (data) => await MODELS.administration.Organisation.commands.SET_PRODUCTS.exec(data);
const getValidationSchema    = isEdit       => isEdit ? MODELS.administration.Product.commands.UPDATE_PRODUCT_ACCESS_RIGHTS.schema : MODELS.administration.Product.commands.CREATE_PRODUCT.schema;

const getProducts = (allProducts) => (productIds) => allProducts?.filter(p => productIds?.includes(p?.id));

export const useProducts = () => {
  const contextState = useContext(UserContext);
  const products = contextState.products;

  return {
      products,
      createProduct,
      getProducts: getProducts(products),
      getValidationSchema,
      assignProductsToOrg,
      addAccessRightsToProd,
  };
};