import moment from "moment";
import { Study } from "../model";
import * as URN from "../../../executor/urn";
import { hasRole } from "../../../iam";
import { HealthcareSite } from "../../administration/model";
import { User } from "../../authentication/model";
import { Report } from "../model/Report";
import { findLast, getProperty } from "../../../helpers/utils";

// utils for manipulating objects (ideally they should be reusable across all models, maybe find a decent library that does this?)
const get = (obj, [first, ...rest], value) => {
    if (first.startsWith('[')) { first = Number(first.slice(1, -1)); }
    if (obj?.[first] === undefined) return value;
    return rest.length ? get(obj[first], rest, value) : obj[first];
}

const set = (obj, [first, ...rest], value) => {
    if (value === undefined) return obj;

    if (first.startsWith('[')) {
        first = Number(first.slice(1, -1));
        obj = [...obj];
    } else {
        obj = { ...obj };
    }

    obj[first] = rest.length ? set(obj[first] || (rest[0].startsWith('[') ? [] : {}), rest, value) : value;
    return obj;
}
/** creates a copy of the given object o1 with only the specificed paths
  * cp(o1, ['b.*.z', 'c.[].y','c.[1].x'], o2)
  */
const cp = (o1, p1, o2, p2 = p1) => {
    if (!o1) return o1;

    o2 ??= Array.isArray(o1) ? [] : {};
    if (Array.isArray(p1)) {
        return p1.reduce((_o2, path) => cp(o1, path, _o2), o2);
    }

    // when p1 has wildchars p2 is ignored, as probably it has no sense for this use case ?? TODO: add checks
    if (p1.includes('*')) { // wildchar for elements in an object
        if (p1.startsWith('*')) {
            p1 = p1.slice(1);
            const _paths = Object.keys(o1).map(k => `${k}${p1}`);
            return cp(o1, _paths, o2);
        }
        const [prefix, ...rest] = p1.split('.*');
        const o = get(o1, prefix.split('.'));
        const _paths = o === undefined ? prefix : Object.keys(o).map(k => `${prefix}.${k}${rest.join('.*')}`);
        return cp(o1, _paths, o2);
    }

    if (p1.includes('[]')) { // wildchar for elements in an array
        if (p1.startsWith('[]')) {
            p1 = p1.slice(2);
            const _paths = Object.keys(o1).map(k => `[${k}]${p1}`);
            return cp(o1, _paths, o2);
        }

        const [prefix, ...rest] = p1.split('.[]');
        const o = get(o1, prefix.split('.'));
        const _paths = o === undefined ? prefix : Object.keys(o).map(k => `${prefix}.[${k}]${rest.join('.[]')}`);
        return cp(o1, _paths, o2);
    }

    return set(o2, p2.split('.'), get(o1, p1.split('.')));
}

export const copy = (aObject) => {
    if (aObject === undefined) return aObject;

    const bObject = Array.isArray(aObject) ? [] : {};
    for (const key in aObject) {
        const value = aObject[key];
        bObject[key] = (typeof value === "object") ? copy(value) : value;
    }

    return bObject;
};

export const getStudyClinicianAnalysisLastUpdatedTimestamp = (studySnap) => findLast(studySnap?.metadata?.events, e => Study.events.CLINICIAN_ANALYSIS_EDITED.isInstance(e.id))?.timestamp;

const expand = (obj, fieldPath, db) => {
    const paths = fieldPath.split(".");
    const key = paths.pop();
    const parentPath = paths.join(".");
    const parentObject = getProperty(obj, parentPath);

    if (typeof parentObject[key] === "string") {
        parentObject[key] = db[parentObject[key]] || parentObject[key];
    } else if (Array.isArray(parentObject[key])) {
        parentObject[key] = parentObject[key].map(i => db[i] || i);
    }

    return obj;
};

export const expandStudies = (studies, { organisations, healthcareSites, clinicians }, fields = ['data.site', 'data.clinician', 'metadata.allOwners']) => {
    const db = {};
    studies.forEach(s => db[s.data.id] = s.data); // in allOwners it references itself
    [organisations, healthcareSites, clinicians].forEach(arr => arr.forEach(s => db[s.id] = s));

    const expandedStudies = studies.reduce((acc, studySnap) => {
        const clone = copy(studySnap);
        fields.forEach(field => expand(clone, field, db));
        acc.push(clone);
        return acc;
    }, []);

    return expandedStudies;
};

export const studyReportDataFormatter = (expandedStudies) => {
    const lastUpdate = (events) => Study.lastEventUpdate(events);
    return expandedStudies.map((expandedStudy) => {
        expandedStudy.data.archived = expandedStudy.metadata.archived;
        expandedStudy.data.lastUpdate = moment(lastUpdate(expandedStudy.metadata.events)).utcOffset(Study.conductedDate(expandedStudy.data.tests) || '+0:00').toISOString();
        return expandedStudy.data;
    });
};

const deleteNestedFields = (obj, fields) => {
    for (const field of fields) {
        const path = field.split('.');
        let currentObj = obj;
        for (let i = 0; i < path.length; i++) {
            const key = path[i];
            if (key === "*") Object.values(currentObj || {}).forEach(obj2 => deleteNestedFields(obj2, [path.slice(i + 1, path.length).join(".")]))
            else if (currentObj[key]?.constructor === Object) {
                currentObj = currentObj[key];
            } else {
                // Field doesn't exist or is not an object, skip to the next field
                break;
            }
        }
        delete currentObj[path[path.length - 1]];
    }
    return obj;
}

const flattenObj = (ob, filter = _ => true) => {
    const result = {};

    const flatten = (obj, prefix = '') => {
        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                obj[key].forEach((item, index) => {
                    if (typeof item === 'object') {
                        flatten(item, `${prefix}${key}.[${index}].`);
                    } else if (filter(`${prefix}${key}.[${index}]`, item)) {
                        result[`${prefix}${key}.[${index}]`] = item;
                    }
                });
            } else if (typeof obj[key] === 'object') {
                flatten(obj[key], `${prefix}${key}.`);
            } else if (filter(prefix + key, obj[key])) {
                result[prefix + key] = obj[key];
            }
        }
    };

    flatten(ob);

    return result;
}

const getDeleteUnnecessaryFieldsFn = (unnecessaryFields, withoutDelete) => withoutDelete ? (e) => e : (e) => deleteNestedFields(e, unnecessaryFields);
export const buildReportFormatter = (reportType, filters, options = {}) => {
    switch (reportType) {
        case Study.queries.GET_ACTIVITY_REPORT.type:
            return {
                fields: (user) => {
                    const hasPermission = role => {
                        if (role === 'isFromAcurable') return user.isFromAcurable();
                        return hasRole(role)(user.data.roles);
                    }
                    return [
                        'data.id',
                        [
                            [
                                'data.site.id',
                                'data.site.name',
                            ],
                            [HealthcareSite.queries.GET]
                        ],
                        [
                            [
                                'data.clinician.id',
                                'data.clinician.name',
                                'data.clinician.lastName',
                                'data.clinician.mail'
                            ],
                            [User.queries.GET]
                        ],
                        'data.date',
                        'data.status',
                        'data.cancel.reason',
                        'data.cancel.customText',
                        'data.requestedTests',
                        'data.patient.id',
                        'data.patient.birthDate',
                        'data.patient.reference',
                        'data.patient.instructions',
                        'data.patient.questionnaireScore',
                        'data.reference',
                        'data.activationCode.value',
                        'data.activationCode.until',
                        'data.tests.*.status',
                        'data.tests.*.retry',
                        'data.tests.*.sequence',
                        'data.tests.*.repeated',
                        'data.tests.*.recording.uploadTime',
                        'data.tests.*.recording.endTime',
                        'data.tests.*.recording.startTime',
                        'data.tests.*.recording.length',
                        'data.tests.*.recording.diagnosableTime',
                        'data.tests.*.recording.sensor',
                        'data.tests.*.recording.receiver',
                        [
                            // adds acu-core warnings: first for tests with diagnosis, second for invalid tests
                            ['data.tests.*.report.diagnosis.*.messages.[].text', 'data.tests.*.report.messages.[].text'],
                            [{ roles: 'isFromAcurable' }],
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-ODI3.value',
                                'data.tests.*.report.diagnosis.ACU-ODI3.events.*.average',
                                'data.tests.*.report.diagnosis.ACU-ODI3.events.*.percentage',
                            ], [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUODI3_VALUE]
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-ODI3.severity'],
                            [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUODI3_SEVERITY]
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-AHI3.value',
                                'data.tests.*.report.diagnosis.ACU-AHI3.events.*.average',
                                'data.tests.*.report.diagnosis.ACU-AHI3.events.*.percentage',
                            ],
                            [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUAHI3_VALUE]
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-AHI3.severity'],
                            [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUAHI3_SEVERITY]
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-ODI4.value',
                                'data.tests.*.report.diagnosis.ACU-ODI4.events.*.average',
                                'data.tests.*.report.diagnosis.ACU-ODI4.events.*.percentage',
                            ], [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUODI4_VALUE]
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-ODI4.severity'],
                            [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUODI4_SEVERITY]
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-AHI4.value',
                                'data.tests.*.report.diagnosis.ACU-AHI4.events.*.average',
                                'data.tests.*.report.diagnosis.ACU-AHI4.events.*.percentage',
                            ], [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUAHI4_VALUE]
                        ],
                        [
                            ['data.tests.*.report.diagnosis.ACU-AHI4.severity'],
                            [Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT, Study.entities.Test.queries.GET_ACUAHI4_SEVERITY]
                        ],
                        [
                            ['data.tests.*.recording.signals.Cardiac rate.params'],
                            [Study.entities.Test.queries.VIEW_CARDIAC_REPORT]],
                        [
                            ['data.tests.*.recording.signals.Resp rate.params'],
                            [Study.entities.Test.queries.VIEW_RESPIRATORY_REPORT]],
                        [
                            ['data.tests.*.recording.signals.SpO2.params'],
                            [Study.entities.Test.queries.VIEW_SPO2_REPORT]],
                        [
                            ['data.tests.*.recording.signals.Snore.params'],
                            [Study.entities.Test.queries.VIEW_SNORING_REPORT]
                        ],
                        'data.owners',
                        'metadata.events',
                        'metadata.allOwners.[].id',
                        'metadata.allOwners.[].name',
                        'metadata.archived',
                        'metadata.batchId',
                    ].filter(fieldDescriptor => !Array.isArray(fieldDescriptor) || fieldDescriptor[1]
                        .map(p => p?.roles).every(p => p && hasPermission(p))) // some queries may not exists in the model as VIEW_RESPIRATORY_REPORT in US region 
                        .map(field => Array.isArray(field) ? field[0] : field)
                        .reduce((all, field) => Array.isArray(field) ? [...all, ...field] : [...all, field], []);
                },
                filter: (study) => Object.values(Filters(filters)).reduce((result, filter) => result && filter(study), true),
                addMissingFields: (study) => {
                    const activated = study.metadata.events?.find(e => URN.dataFrom(e.id).type === Study.events.STUDY_INITIATED.type)?.timestamp;
                    const clinicianAnalysisLastUpdatedTimestamp = getStudyClinicianAnalysisLastUpdatedTimestamp(study);
                    const clinicianAnalysisLastUpdated = clinicianAnalysisLastUpdatedTimestamp ? moment(clinicianAnalysisLastUpdatedTimestamp).toISOString() : undefined;
                    Object.entries(study.data.tests).forEach(([testId, t]) => {
                        t.number = Number(testId.split('-')[0]) + 1;
                        t.conducted = Study.conductedDate({ 0: t }); // THIS SHOULD COME FROM THE MODEL
                        if (t.recording?.length) {
                            t.recording.length = { value: t.recording.length, units: 'seconds' } // this should come from acucore somehow
                        }
                        if (t.recording?.diagnosableTime) {
                            t.recording.diagnosableTime = { value: t.recording.diagnosableTime, units: 'seconds' } // this should come from acucore somehow
                        }
                        if (t.status === Study.entities.Test.STATUS.invalid) {
                            const reportMessages = t.report?.messages;
                            delete t.report;
                            if (reportMessages?.length) t.report = { messages: reportMessages };
                            delete t.recording.signals;
                        }

                        return [testId, t];
                    });

                    study.data.status = Study.getStatusGroup(study.data.status);
                    study.data = {
                        reference: Study.getReference(study.data),
                        mode: study.data.patient.instructions.providedPhone ? 'Hospital' : 'Patient', //THIS SHOULD COME FROM THE MODEL
                        activated: activated && moment(activated).utcOffset(0).toISOString(), // THIS SHOULD COME FROM THE MODEL
                        organisation: { name: study.metadata.allOwners.find(o => o.id?.includes("Organisation"))?.name },
                        ...study.data,
                        cancelReason: study.data.cancel?.reason === Study.CANCEL_REASON.OTHER ? study.data.cancel.customText : study.data.cancel?.reason ? options.translate('module.diagnosis.cancel.reason.' + study.data.cancel?.reason) : undefined,
                        clinicianAnalysisLastUpdated,
                    };
                    delete study.data.cancel;

                    return study;
                },
                deleteUnnecessaryFields: getDeleteUnnecessaryFieldsFn(['data.clinician.id', 'data.requestedTests', 'data.owners'], options.withoutDelete),
                transformAfter: (data) => {
                    return data.reduce((all, {data: study}) => {
                        const tests = study.tests;
                        study.tests = Object.keys(study.tests).length;
                        all.push(...Object.values(tests).map(test => ({ test, study })));
                        return all;
                    }, []);
                },
            };
        case Study.queries.GET_ACTIVITY_LOG.type:
            return {
                fields: () => [
                    'data.id',
                    'data.reference',
                    'data.activationCode.value',
                    'data.site.id',
                    'data.site.name',
                    'data.clinician.id',
                    'data.clinician.name',
                    'data.clinician.lastName',
                    'data.date',
                    'data.patient.id',
                    'data.patient.reference',
                    'data.patient.birthDate',
                    'data.status',
                    'data.requestedTests',
                    'data.tests.*.status',
                    'data.tests.*.recording.startTime',
                    'data.tests.*.recording.endTime',
                    'data.tests.*.recording.sensor',
                    'data.tests.*.recording.receiver',
                    'data.tests.*.repeated',
                    'data.owners',
                    'metadata.allOwners.[].id',
                    'metadata.allOwners.[].name',
                    'metadata.events',
                    'metadata.archived',
                ],
                transformBefore: (data) => activityReportDataFormatter(data),
                filter: (study) => Object.values(Filters({
                    ...filters,
                    showArchived: options?.showArchived
                })).reduce((result, filter) => result && filter(study), true),
                deleteUnnecessaryFields: getDeleteUnnecessaryFieldsFn(['organisation.id', 'site.id', 'study.id', 'study.clinician.id', 'study.patient.id', 'study.patient.birthDate', 'study.owners'], options.withoutDelete),
            };
        case Study.queries.GET_ACTIVITY_SUMMARY.type:
            return {
                transformBefore: (data) => activitySummaryDataFormatter(getReportData(Study.queries.GET_ACTIVITY_LOG.type, data, filters, undefined, { withoutDelete: true, showArchived: true })),
                deleteUnnecessaryFields: getDeleteUnnecessaryFieldsFn(['organisation.id', 'healthcaresite.id', 'study.id', 'study.clinician.id', 'study.owners'], options.withoutDelete),
            };
        default:
            throw new Error("Report formatter not implemented");
    }
};

export const getReportData = (reportType, studies, filters, user, options = {}) => {
    const formatter = buildReportFormatter(reportType, filters, options);
    
    // Important to take this transformation in mind when setting the formatter.fields!
    const data = studies.map(s => {
        s.data.tests = Object.entries(s.data.tests).reduce((all, [testSeq, test]) => {
            all[`${testSeq}-0`] = test;
            Object.entries(test.repeats || {}).forEach(([retry, testRepeat]) => {all[`${testSeq}-${retry}`] = testRepeat;});
            delete all[`${testSeq}-0`].repeats;
            return all;
        },{});

        return s;
    });

    let result = formatter.fields ? data.map(d => cp(d, formatter.fields(user))) : copy(data);
    if (formatter.filter) result = result.filter(formatter.filter);
    if (formatter.transformBefore) result = formatter.transformBefore(result);
    if (formatter.addMissingFields) result = result.map(formatter.addMissingFields);
    if (formatter.deleteUnnecessaryFields) result = result.map(formatter.deleteUnnecessaryFields);
    if (formatter.transformAfter) result = formatter.transformAfter(result);
    if (options.flatten) result = result.map(s => flattenObj(s, (_, v) => v !== undefined));
    return result;
}

const TEST_STATUS_GROUP_VALUES = Study.entities.Test.STATUS_GROUP.values();
const STUDY_STATUS_GROUP_VALUES = Study.STATUS_GROUP.values();
const TEST_STATUS_COUNTERS = () => TEST_STATUS_GROUP_VALUES.reduce((a, s) => ({ ...a, [s]: 0 }), {});
const STUDY_STATUS_COUNTERS = () => STUDY_STATUS_GROUP_VALUES.reduce((a, s) => ({ ...a, [s]: 0 }), {});

// expandedStudies = expandStudies(studies)
export const activityReportDataFormatter = (expandedStudies) => {
    const addMissingFields = study => {
        const countByStatus = (tests = []) => tests.reduce((a, t) => {
            const status = Study.entities.Test.getStatusGroup(t.status);
            return { ...a, [status]: a[status] + 1 };
        }, TEST_STATUS_COUNTERS());

        const tests = Object.values(study.data.tests || {});
        const repeated = tests.filter(t => t.repeated).map(t => Object.values(t.repeats || {})).reduce((a, t) => [...a, t], []);

        const organisation = study.metadata.allOwners.find(o => o?.id?.includes("Organisation"));
        study.data.organisation = { id: organisation?.id, name: organisation?.name };
        study.data.reference = Study.getReference(study.data); delete study.data.activationCode;
        study.data.tests = {
            total: tests.length + repeated.length,
            status: countByStatus([...tests, ...repeated]),
            repeats: {
                total: repeated.length,
                status: countByStatus(repeated)
            }
        };
        study.data.updated = moment(Study.lastEventUpdate(study.metadata.events)).format();
        const analysedDate = Study.lastEventUpdate(study.metadata.events, [Study.events.ANALYSIS_REPORT_UPDATED]);
        if (analysedDate) study.data.analysed = moment(analysedDate).format();
        const reviewedDate = Study.lastEventUpdate(study.metadata.events, [Study.events.CLINICIAN_ANALYSIS_EDITED]);
        if (reviewedDate) study.data.reviewed = moment(reviewedDate).format();

        return study;
    }

    const formattedStudies = expandedStudies
        .map(addMissingFields)
        .map(study => study.data)
        .map(({ organisation, site, ...study }) => ({ organisation, site, study }));

    return formattedStudies;
};

// expandedFormattedStudies = activityReportDataFormatter(expandStudies(studies))
export const activitySummaryDataFormatter = (expandedFormattedStudies) => {
    const cache = {};
    const aggregatedData = expandedFormattedStudies.reduce((a, s) => {
        cache[s.organisation.id] = s.organisation;
        cache[s.site.id] = s.site;
        a[s.organisation.id] ??= {};
        a[s.organisation.id][s.site.id] ??= {};
        a[s.organisation.id][s.site.id][s.study.requestedTests] ??= { status: STUDY_STATUS_COUNTERS(), tests: { total: 0, status: TEST_STATUS_COUNTERS(), repeats: { total: 0, status: TEST_STATUS_COUNTERS() } } };
        a[s.organisation.id][s.site.id][s.study.requestedTests].status[s.study.status] += 1;
        a[s.organisation.id][s.site.id][s.study.requestedTests].tests.total += s.study.tests.total;
        a[s.organisation.id][s.site.id][s.study.requestedTests].tests.repeats.total += s.study.tests.repeats.total;
        // We only want the test count to be done for finished studies
        Study.STATUS_GROUP.compare(s.study.status, Study.STATUS_GROUP.FINISHED) >= 0 && TEST_STATUS_GROUP_VALUES.forEach(state => {
            a[s.organisation.id][s.site.id][s.study.requestedTests].tests.status[state] += s.study.tests.status[state];
            a[s.organisation.id][s.site.id][s.study.requestedTests].tests.repeats.status[state] += s.study.tests.repeats.status[state];
        })

        return a;
    }, {});

    const result = Object.entries(aggregatedData).reduce((rows, [orgId, hcssData]) => {
        Object.entries(hcssData).forEach(([hcsId, requestedTestsData]) => {
            Object.entries(requestedTestsData).forEach(([requestedTests, testsData]) => {
                rows.push({
                    organisation: { id: orgId, name: cache[orgId].name },
                    healthcaresite: { id: hcsId, name: cache[hcsId].name },
                    study: {
                        requestedTests,
                        ...testsData
                    }
                });
            });
        })
        return rows;
    }, []).sort((a, b) => {
        const nameA = a.healthcaresite.name;
        const nameB = b.healthcaresite.name;
        const requestedTestsA = Number(a.study.requestedTests);
        const requestedTestsB = Number(b.study.requestedTests);

        if (nameA < nameB) {
            return -1;
        } else if (nameA > nameB) {
            return 1;
        }

        if (requestedTestsA < requestedTestsB) {
            return -1;
        } else if (requestedTestsA > requestedTestsB) {
            return 1;
        }
        return 0;
    });

    return result;
};

export const getStudyTestIds = (study) => Object.keys(study?.data?.tests || {})
    .map(sequence => ({ sequence, retries: new Array((study.data.tests[sequence].retry || 0) + 1).fill(0).map((_, i) => i) }))
    .map(({ sequence, retries }) => retries.map(r => `${sequence}-${r}`))
    .reduce((a, ids) => [...a, ...ids], [])
    .map(testId => `${study.data.id}/Test/${testId}`);

export const prepareTests = ({ study, testIds, testEntities }) => testIds.reduce((all, testId) => {
    const [sequence, retry] = testId.split('/').pop().split('-').map(strNum => parseInt(strNum));
    const lastRetriedTest = study.data.tests[sequence];
    const test = { id: testId, ...(retry === (lastRetriedTest.retry || 0) ? lastRetriedTest : {}), ...testEntities[testId]?.data, sequence, retry };

    all[sequence] ??= {};
    if (retry) {
        all[sequence].repeated = true;
        all[sequence].repeats ??= {};
        all[sequence].repeats[retry] = test;
        all[sequence].repeats[retry].repeated = retry < (lastRetriedTest.retry || 0);
    } else all[sequence] = test;

    return all;
}, {});

const DATE_GRANULARITY = 'day';
const DATE_COMPARISION_SCOPE = '[]';

export const Filters = ({ createdDateStart, createdDateEnd, conductedDateStart, conductedDateEnd, lastUpdatedDateStart, lastUpdatedDateEnd, statuses = [], reference, patientBirthDate, testStatuses = [], testSeverities = [], requestedTests, clinicians = [], devices = [], showArchived, batchId }) => {
    const ref = reference?.toLowerCase();
    const testSeveritiesSet = testSeverities.reduce((acc, curr) => {
        Report.SEVERITIES_GROUPS[curr].forEach(sev => acc.add(sev));
        return acc;
    }, new Set());
    return {
        byCreatedDatePeriod: study => createdDateStart ? moment(study.data.date).isBetween(moment(createdDateStart), moment(createdDateEnd), DATE_GRANULARITY, DATE_COMPARISION_SCOPE) : true,
        byConductedDatePeriod: study => {
            const conductedDate = Study.conductedDate(study.data.tests || {});
            return conductedDateStart ? conductedDate ? moment(conductedDate).isBetween(moment(conductedDateStart), moment(conductedDateEnd), DATE_GRANULARITY, DATE_COMPARISION_SCOPE) : false : true
        },
        byLastUpdatedDatePeriod: study => {
            const lastUpdatedDate = Study.lastEventUpdate(study.metadata.events);
            return lastUpdatedDateStart ? lastUpdatedDate ? moment(lastUpdatedDate).isBetween(moment(lastUpdatedDateStart), moment(lastUpdatedDateEnd), DATE_GRANULARITY, DATE_COMPARISION_SCOPE) : false : true
        },
        byStatuses: study => statuses.length ? statuses.some(st => st === Study.getStatusGroup(study.data.status)) : true,
        byReference: study => ref ? [Study.getReference(study.data), study.data.patient.reference, study.data.patient.id].some(r => r.toLowerCase().includes(ref)) : true,
        byPatientBirthDate: study => patientBirthDate ? moment(study.data.patient.birthDate, 'DD/MM/YYYY').isSame(moment(patientBirthDate, 'DD/MM/YYYY'), 'day') : true,
        byTestStatuses: study => testStatuses.length ? Object.values(study.data.tests || {}).some(test => testStatuses.includes(Study.entities.Test.getStatusGroup(test.status))) : true,
        byTestSeverities: study => testSeveritiesSet.size ? Object.values(study.data.tests || {}).some(test => Object.values(test?.report?.diagnosis || {}).some(s => testSeveritiesSet.has(s.severity))) : true,
        byRequestedTests: study => requestedTests ? study.data.requestedTests === requestedTests : true,
        byClinicians: study => clinicians.length ? clinicians.includes(study.data.clinician.id) : true,
        byDevices: study => devices.length ? Object.values(study.data.tests || {}).some(t => devices.some(dev => t.recording?.sensor?.endsWith(dev) || t.recording?.receiver?.endsWith(dev))) : true,
        byShowArchived: (study) => showArchived ? true : !study.metadata.archived,
        byBatchId: study => batchId?.length ? study.metadata.batchId?.startsWith(batchId) : true,
    }
};