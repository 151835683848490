import { Device } from 'services/server/functions/model/devices/model';
import { dataFrom } from 'services/server/functions/executor/urn';
import * as Devices from 'modules/devices';
import { monotonicFactory } from 'ulid';
import Action from 'ui/components/Action';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Back from 'ui/components/Back';
import { useState } from 'react';

import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import WithSnapshot from 'ui/components/WithSnapshot';
import { Redirect } from 'react-router-dom';

const ulid = monotonicFactory();

const LogDeviceIssueUI = ({deviceId, deviceStatus, type, description, onTypeChangedHandler, onDescriptionChangedHandler, onConfirmHandler, onCancelHandler, I18n}) => (
  <div id='LogDeviceIssue' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
    </div>
    <div className='pageContent'>
      <div className='pageSection'>
        <div className='title'>{I18n('details')}</div>
        <DetailsBlock inline>
          <Details label='device-id' value={dataFrom(deviceId).id} />
          <Details label='status'>
            <div className={`status ${deviceStatus && deviceStatus.toLowerCase()}`}>{deviceStatus}</div>
          </Details>
        </DetailsBlock>
      </div>
      <div className='pageSection'>
        <div className='title'>{I18n('description')}</div>
        <div className="grid-row">
          <div>{I18n('global.error.issue_provide_message')}</div>
          <div>
            <div className='label'>{I18n('type-of')}<span className='mandatory'>*</span></div>
            <select id='type' className='field' value={type ? type : ''} onChange={(e) => onTypeChangedHandler(e.target.value)}>
              <option value='' disabled>{I18n("type")}</option>
              {Device.ISSUE_TYPES.map(type => <option key={type} value={type}>{I18n(type)}</option>)}
            </select>
            <textarea id='description' className='field' placeholder={I18n("describe")} value={description ? description : ''} onChange={(e) => onDescriptionChangedHandler(e.target.value)}/>
          </div>
        </div>
      </div>
      <div className="pageFooter">
        <div className='actions'>
          <Action className='button primary' id="report" handleAction={() => onConfirmHandler()} disabled={!description || !type} label='send' />
          <Action className='button' id="back" handleAction={() => onCancelHandler()} label='cancel' />
        </div >
      </div>
    </div>
  </div>
);

const LogDeviceIssuePresenter = (props) => {  
  const device = props.snapshot?.data || {status: undefined, issues: {}};

  const [id, setId]                   = useState(ulid());
  const [type, setType]               = useState();
  const [description, setDescription] = useState();

  const reportIssue = () => props.reportDeviceIssue({ id: props.deviceId, issues: {[id]: { id, type, description }} })
      .then(_ => setId(ulid()))
      .then(_ => setType(undefined))
      .then(_ => setDescription(undefined))
      .then(_ => history.push(Devices.ViewDevice.PATH, { device: props.deviceId }))
      .catch(e => console.log("error", e));

  return LogDeviceIssueUI({
    id,
    type,
    description,
    deviceId: props.deviceId,
    deviceIssues: device.issues,
    deviceStatus: device.status,
    onTypeChangedHandler       : setType,
    onDescriptionChangedHandler: setDescription,
    onConfirmHandler           : reportIssue,
    onCancelHandler            : require('history.js').default.goBack,
    I18n                       : props.I18n
  });
};

export default Object.assign(props => {
  const [{device: devId, id: oldId}] = useLocationParams();
  const deviceId = Device.newURN(devId || oldId);

  return WithSnapshot(Device, deviceId, {fallbackComponent: _ => <Redirect to={Devices.Find.PATH} />})(LogDeviceIssuePresenter)({...props, deviceId});
}, {displayName: "LogDeviceIssue"});
