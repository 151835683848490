import { useState, useEffect, useContext } from 'react';

import { setObjProperty } from 'features/helpers/objectHelper';
import { UserContext } from 'features/providers/userContextProvider';
import { Product } from 'services/server/functions/model/administration/model';

const prepareSettingsData = (schema, formData, productId) => {
  const { data, refs } = schema;
  const hasRefs = Array.isArray(refs) && refs.length > 0;

  if (!hasRefs) {
      return { productId, ...data };
  }
  
  return refs.reduce(
    (acc, ref) => ({ ...acc, ...setObjProperty({ obj: schema, ref, data: { formData } }) })
    ,{ productId }
  );
};

const getSchemas = async ({ ownerId, schemasToRetrieve = [], productId }) => {
  if (schemasToRetrieve?.length === 0) {
      return;
  }

  const response = await Promise.all(
    schemasToRetrieve.map(({ formId, action, dataOverrides }) => action({ ownerId, formId, productId, ...dataOverrides }, { notifications: { successDisabled: true }}))
  );

  return response?.reduce((acc, requestResult, index) => ({ ...acc, [schemasToRetrieve[index]?.formId]: requestResult }), {});
};

export const useSettings = ({ ownerId, schemasToRetrieve, auto = true }) => { //@meir this is overcomplicated, it can be done simpler I believe
    const [schemas, setSchemas] = useState(), updateSchemas = state => state && setSchemas(s => ({...s, ...state}));
    const [loadingSchemas, setLoadingSchemas] = useState(false);
    const contextState = useContext(UserContext);
    const productId = Product.isReference(ownerId) ? ownerId : Product.newURN(contextState.selectedProduct.key);
    
    const fetchSchemas = async (formId) => {
        !loadingSchemas && setLoadingSchemas(true);
        const schemasToList = formId ? schemasToRetrieve?.filter(s => s?.formId === formId) : schemasToRetrieve
        const newSchemas = await getSchemas({ ownerId, schemasToRetrieve: schemasToList, productId });
        updateSchemas(newSchemas);
        setLoadingSchemas(false);
    };

    const onClickAction = async ({ schema, formId, formData }) => { // 
        if (!schema) { return; }

        const { aggregate, context, command } = schema.action;
        const action = (await import('modules/model')).resolve({context, aggregate})?.actions[command]?.exec;
        const data = prepareSettingsData(schema, formData, productId);
        await action(data); // @meir this assumes the execution of the command associated to the update of the FORM ?? how do we control whether this works or not ?
        await fetchSchemas(formId); // @meir why do we need to fetch it again ??
    };

    const retrieveSchemas = () => {
        fetchSchemas();
    };

    useEffect(() => {
        if (auto && ownerId && !schemas && !loadingSchemas) {
          retrieveSchemas();
        }
    }, [schemasToRetrieve?.length, loadingSchemas]);

    return {
        schemas,
        onClickAction,
        retrieveSchemas,
        loadingSchemas,
    };
};