
let breakPoints = {
  xs:576,
  sm:768,
  md:992,
  lg:1200,
  xl:9999,
};

breakPoints.breakNav = breakPoints.md;

export default breakPoints;
