import Config from './config';
import { publish } from 'modules/router';
import * as Pages from './ui';
import MODELS from 'modules/model'

const actions = {
  ...MODELS.administration.Organisation.UIActions,
  ...MODELS.administration.HealthcareSite.UIActions,
  ...MODELS.administration.Organisation.entities.Trial.UIActions,
  // aliases .. to be deprecated
  registerHCS                 : MODELS.administration.Organisation.UIActions.registerHcs,
  unregisterHCS               : MODELS.administration.Organisation.UIActions.unregisterHcs,
  updateHCS                   : MODELS.administration.HealthcareSite.UIActions.updateHcs,
  updateTrialClinicians       : MODELS.administration.Organisation.entities.Trial.UIActions.updateClinicians,
  setOrganisationPreference   : MODELS.administration.Organisation.entities.Preferences.UIActions.setPreference,
  setHealthcareSitePreference : MODELS.administration.HealthcareSite.entities.Preferences.UIActions.setPreference,
};

export const CreateOrganisation   = publish(Config.routes.CreateOrganisation, actions)(Pages.EditOrganisation, { page: "EditOrganisation" });
export const FindOrganisation     = publish(Config.routes.FindOrganisation, actions)(Pages.FindOrganisation, { page: "FindOrganisation" });
export const UpdateOrganisation   = publish(Config.routes.UpdateOrganisation, actions)(Pages.EditOrganisation, { page: "EditOrganisation" });
export const ViewOrganisation     = publish(Config.routes.ViewOrganisation, actions)(Pages.ViewOrganisation, { page: "ViewOrganisation" });
export const ViewOrganisationConfiguration = publish(Config.routes.ViewOrganisationConfiguration, actions)(Pages.ViewOrganisationConfiguration, { page: "ViewOrganisationConfiguration"});
export const ConfigureOrganisationProfile = publish(Config.routes.ConfigureOrganisationProfile, actions)(Pages.ConfigureOrganisationProfile, { page: "ConfigureOrganisationProfile" });
export const MyOrganisation       = publish(Config.routes.MyOrganisation, actions)(Pages.ViewOrganisation, { page: "ViewOrganisation" });
export const ViewHealthcareSiteConfiguration = publish(Config.routes.ViewHealthcareSiteConfiguration, actions)(Pages.ViewHealthcareSiteConfiguration, { page: "ViewHealthcareSiteConfiguration"});
export const CreateHCS            = publish(Config.routes.CreateHCS, actions)(Pages.EditHCS, { page: "EditHealthcareSite" });
export const FindHCS              = publish(Config.routes.FindHCS, actions)(Pages.FindHCS, { page: "FindHealthcareSite" });
export const UpdateHCS            = publish(Config.routes.UpdateHCS, actions)(Pages.EditHCS, { page: "EditHealthcareSite" });
export const ViewHCS              = publish(Config.routes.ViewHCS, actions)(Pages.ViewHCS, { page: "ViewHealthcareSite" });
export const FindTrial            = publish(Config.routes.FindTrial, actions)(Pages.FindTrial, { page: "FindTrial" });
export const CreateTrial          = publish(Config.routes.CreateTrial, actions)(Pages.EditTrial, { page: "CreateTrial" });
export const UpdateTrial          = publish(Config.routes.UpdateTrial, actions)(Pages.EditTrial, { page: "UpdateTrial" });
export const ViewTrial            = publish(Config.routes.ViewTrial, actions)(Pages.ViewTrial, { page: "ViewTrial" });
