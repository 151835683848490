import './style.css';

import LockCircleIcon from "@atlaskit/icon/glyph/lock-circle";
import Spinner from '@atlaskit/spinner';
import EditIcon from '@atlaskit/icon/glyph/edit';
import i18n from 'services/server/functions/i18n';
import useCurrentUser from 'ui/hooks/useCurrentUser';

const BlockedIcon = () => <div title={i18n.resolve("no-access-tooltip")}><LockCircleIcon/></div>;
// TODO: currentUser should come from context and here we should just use hasRole from iam module
const Counter = ({ id, count, params, findPage: Find, onCreate, onEdit, createPage: Create, options }) => {
  const [currentUser] = useCurrentUser(options);
  const showAdd = (Create && currentUser.hasAccess(Create)) || onCreate !== undefined || onEdit !== undefined;
  const counterClass = showAdd ? "left" : "";
  return (
    <div className="items counter">
      {currentUser.hasAccess(Find) ? <Find.AsLinkButton id={`${id}Counter`} component={count === undefined ? <Spinner size="small"/> : undefined} text={count} className={`action ${counterClass}`} options={options} {...params }/> : <BlockedIcon/>}
      {showAdd && (Create ? <Create.AsLinkButton id={`create${id}`} text='+' className="action right add" options={options} {...params } /> : <div id={`${onEdit ? 'edit' : 'create'}${id}`} className="button action right add" onClick={onEdit || onCreate}>{onEdit !== undefined ? <EditIcon/> : '+'}</div>)}
    </div>
  );
}

export default Counter;
