import { useState } from "react";
import { makeCancelable } from "services/server/functions/promises";
import useEffectOnMount from "ui/hooks/useEffectOnMount";

export default function asyncComponent(importComponent, LoadComponent) { // TODO: Check how to use React.lazy and Suspense instead
  return (props) => {
    const [Component, setComponent] = useState();

    useEffectOnMount(() => {
      const importPromise = makeCancelable(importComponent())
      importPromise.then(({ default: component }) => setComponent(() => component)).catch(e => !e?.isCanceled && Promise.reject(e));
      return importPromise.cancel;
    });

    return Component ? <Component {...props} /> : LoadComponent ? <LoadComponent /> : null;
  };
}