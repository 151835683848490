import { animated as Animated, useSpring } from "@react-spring/web";
import "./styles.css"


const AnimatedDiv = Animated.div;

const onCloseAnimation = {
    scY: 1,
    opacity: 0,
    visibility: "hidden",
    maxHeight: "0px",
    config: { duration: 500 }
};

export const Accordion = ({ children, contentMaxHeight = "500px",
    isOpen = false }) => {
    const onOpenAnimation = {
        scY: -1,
        opacity: 1,
        visibility: "visible",
        maxHeight: contentMaxHeight,
        config: { duration: 800 }
    };
    const animation =useSpring(isOpen ? onOpenAnimation: onCloseAnimation);
    return (
        <div className="accordion">
            <AnimatedDiv style={animation}>
                {children}
            </AnimatedDiv>
        </div>
    );
};

export const AccordionLinkButton = ({ label, children, handleClick }) => (
    <div className="accordion-link-button" onClick={handleClick}>
        <span>{label}</span>
        <div className="accordion-icon">{children}</div>
    </div>
); 