import {Role} from '.';

export default { // ordered list of event transformers (most recent versions at the end of array)
  1593475200000: async (event) => { // 2020.06.08-0.1.152-2 
    // TODO: include schema/model version in events
    if (event.aggregate.name === Role.name && 'includes' in event.data) {
      event.data.includes = event.data.includes.map(acRight => acRight.endsWith('/study_get_diagnosis_report') ? acRight.replace(/\/study_get_diagnosis_report$/, '/study_export_sleep_study') : acRight);
      return event;
    }

    return event;
  }
}