import { useCallback, useContext, useEffect, useState } from 'react';

import { UserContext } from 'features/providers/userContextProvider';
import DetailsBlock from 'ui/components/DetailsBlock';
import ExportPDF from 'ui/components/ExportReport';
import QRCode from 'qrcode';
import Spinner from '@atlaskit/spinner';
import { Study } from 'services/server/functions/model/diagnosis/model';
import {Text} from 'ui/components/Text';
import moment from 'moment';
import useCancellablePromise from 'ui/hooks/useCancellablePromise';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import FullInstructions from './Instructions';
import i18n from 'services/server/functions/i18n';

const exportLetter = (id, product) => () => require('services/server/functions/executor/executor.client')
                                        .getReport('diagnosis.Study.letter', { id, product })
                                        .then(encFile => `data:application/pdf;base64,${encFile}`);
const PrepareEquipment = (props) => {
  const { selectedProduct } = useContext(UserContext);
  const [currentUser]                           = useCurrentUser();
  const [qrCode, setQrCode]                     = useState("");
  const [fullInstructions, setFullInstructions] = useState(false), showFullInstructions = _ => setFullInstructions(true), goBack = _ => setFullInstructions(false);
  const promises                                = useCancellablePromise();
  
  const study            = props.study?.data || { tests: {}, activationCode: undefined, patient: {instructions: {providedPhone: props.providedPhone}}, date: undefined };
  const adhesives        = 2 + (Object.keys(study.tests || {}).length || 1);
  const patientID        = study.patient && (study.patient.id || "-");
  const studyDate        = study.date ? moment(study.date).format("L") : "-";
  const providedPhone    = study.patient?.instructions.providedPhone;
  const requiresOximetry = study.patient?.instructions.requiresOximetry;
  const fileName         = i18n.resolve('report.Instructions.file_name');
  const letterFilename   = `${fileName}_${patientID}_${studyDate}.pdf`;                                                                                                    //FIXME: duplicated code. This is used in other places too
  
  const notifyQRError = useCallback(_ => props.notify('error', 'CreateStudy.activation-code.loading-failed', "CreateStudy.activation-code.loading-failed-details"), []);
  useEffect(() => { (study.activationCode || {}).value && 
                     promises.cancellablePromise(QRCode.toDataURL(study.activationCode.value), 'QR Code')
                      .then(setQrCode)
                      .catch(reason => !reason.isCanceled && notifyQRError())
                  }, [study.activationCode, promises, notifyQRError])
  
  const daysToExpire = moment(study.activationCode?.until).diff(moment(), 'days');
  const oximetryQuantity = requiresOximetry ? 2 : 1;


  const HospitalMode = _ => (
    <div id="StudyInstructions" className="pageSection grid-row">
      <h2 className="title-page"><Text>CreateStudy.prepare-equipment</Text></h2>
      <span className="stepsIntro"><Text>CreateStudy.instructions.mobile-app.success</Text></span>
      <DetailsBlock inline>
        <div className="grid-row">
          <h3 className="title-section"><Text>CreateStudy.instructions.mobile-app.enter-code</Text></h3>
          <span>
            {Text.resolve('page.CreateStudy.instructions.mobile_app_section_title')}
          </span>
          {(study.activationCode || {}).value ?
              <span>{Text.resolve('CreateStudy.instructions.mobile-app.enter-code.subtitle', {plural: daysToExpire, days: daysToExpire})}</span> :
              <></>
          }
        </div>
        {(study.activationCode || {}).value ? 
          <div className="vcentered instructionImg grid-row">
              <div className="centered">
                <span className="activationCode">{study.activationCode && study.activationCode.value}</span>
              </div>
              <img style={{maxWidth: "100%"}} alt="" src={qrCode}/>
          </div> :
          <div className="centered grid-row">
            <span className="italic"><Text>component.create_study.loading_message</Text></span>
            <div><Spinner size="large"/></div>
          </div>
        }
      </DetailsBlock>
      <DetailsBlock inline>
        <div className="grid-row">
          <h3 className="title-section"><Text>CreateStudy.instructions.mobile-app.print-instructions</Text></h3>
          <span><Text>CreateStudy.instructions.mobile-app.print-instructions.text1</Text></span>
          <span><Text>CreateStudy.instructions.mobile-app.print-instructions.text2</Text></span>
          <ExportPDF disabled={!currentUser.hasAccess(Study.queries.GET_STUDY_LETTER)} label="global.download_pdf_message" secondary downloadFile={letterFilename} id="exportLetter" urlCallback={exportLetter(study.id, selectedProduct?.key)} />
        </div>
        <div className="vcentered instructionImg">
          <img style={{maxWidth: "50%"}} alt="" src={require('../images/printer-code.png').default}/>
        </div>
      </DetailsBlock>
      <DetailsBlock inline>
        <div className="grid-row">
          <h3 className="title-section"><Text>instructions.prepare-everything</Text></h3>
          <span>
            <Text>page.CreateStudy.instrucctions.sensor_message_attached</Text>
          </span>
          <span><Text>instructions.collect</Text></span>
          <ul>
            <li><Text>instructions.collect-item-1</Text></li>
            <li><Text variables={{ adhesives }}>instructions.collect-item-2</Text></li>
            { requiresOximetry && <li><Text>instructions.1-finger-sensor</Text></li> }
            <li>
              {Text.resolve('instructions.collect-item-3', {
                n: oximetryQuantity,
                plural: oximetryQuantity
              })}
            </li>
            <li><Text>instructions.collect-item-4</Text></li>
            <li><Text>instructions.collect-item-4-b</Text></li>
            <li><Text>instructions.collect-item-5</Text></li>
          </ul>
          <span>
            <Text>page.CreateStudy.instrucctions.more_detailed_message</Text>
            &nbsp;
            <a onClick={showFullInstructions} className='link'><Text>global.link.click_here</Text></a>.
          </span>
        </div>
        <div className="vcentered instructionImg grid-col">
          <img alt="" src={require('../images/doctor-patient.png').default}/>
        </div>
      </DetailsBlock>
    </div>
  )

  const PatientMode = _ => (
    <>
      <h2 className="title-page"><Text>CreateStudy.prepare-equipment</Text></h2>
      <span className="stepsIntro"><Text>page.CreateStudy.patient_instructions_sub_title</Text></span>
      <DetailsBlock inline>
        <div className="grid-row"> 
          <h3 className="title-section"><Text>CreateStudy.activation-code.print</Text></h3>
          <span><Text>CreateStudy.activation-code.text1</Text></span>
          <span><Text>CreateStudy.activation-code.text2</Text></span>
          <span><Text>CreateStudy.activation-code.text3</Text></span>
          {!(study.activationCode || {}).value ? 
              <div className="centered grid-row">
                <span className="italic"><Text>component.create_study.loading_message</Text></span>
                <div><Spinner size="large"/></div>
              </div> : 
              <ExportPDF disabled={!currentUser.hasAccess(Study.queries.GET_STUDY_LETTER)} label="global.download_pdf_message" secondary downloadFile={letterFilename} id="exportLetter" urlCallback={exportLetter(study.id, selectedProduct?.key)} />
          }
        </div>
        <div className="vcentered instructionImg">
          <img style={{maxWidth: "50%"}} alt="" src={require('../images/printer-code.png').default}/>
        </div>
      </DetailsBlock>
      <DetailsBlock inline>
        <div className="grid-row">
          <h3 className="title-section"><Text>instructions.prepare-everything</Text></h3>
          <span>
            <Text>module.diagnosis.fe.createStudy.instructions.patient_message</Text>
          </span>
          <span><Text>instructions.collect</Text></span>
          <ul>
            <li><Text>module.diagnosis.fe.createStudy.patient_instructions.collect-item-1</Text></li>
            <li><Text variables={{ adhesives }}>instructions.collect-item-2</Text></li>
            { requiresOximetry && <li><Text>instructions.1-finger-sensor</Text></li> }
            <li>
              {Text.resolve('instructions.collect-item-3', {
                n: oximetryQuantity,
                plural: oximetryQuantity
              })}
            </li>
            <li><Text>instructions.collect-item-6</Text></li>
          </ul>
          <span>
            <Text>page.CreateStudy.instrucctions.more_detailed_message</Text>
            &nbsp;
            <a onClick={showFullInstructions} className='link'><Text>global.link.click_here</Text></a>.
          </span>
        </div>
        <div className="vcentered instructionImg grid-col">
          <img alt="" src={require('../images/doctor-patient.png').default}/>
        </div>
      </DetailsBlock>
    </>
  )

  return fullInstructions ? <FullInstructions {...props} onBack={goBack} /> : (providedPhone === true ? <HospitalMode /> : ( !providedPhone ? <PatientMode /> : <></> ));
}

export default PrepareEquipment;