import './style.css';

import { Fragment } from 'react';

import { ReactComponent as CE } from './images/CE.svg';
import { ReactComponent as ECREP } from './images/ECREP.svg';
import { ReactComponent as RxOnly } from './images/RxOnly.svg';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Doctor from './images/doctor-sensor-tablet.png';
import { ReactComponent as GTIN } from './images/GTIN.svg';
import { ReactComponent as Instructions } from './images/Instructions.svg';
import { ReactComponent as Manufacturer } from './images/Manufacturer.svg';
import { UserManual } from 'modules/support';
import { Region, Language } from 'ui/hooks/useRegion';
import { useLocalisation } from 'ui/hooks/useLocalisation';
import { Text } from 'ui/components/Text';

const About = () => {
    const { localisation } = useLocalisation();
    const supportedLangBlock = localisation?.supportedLangs.map(
        (supportedLang) => (
            <Fragment key={supportedLang.lang}>
                <Language target={supportedLang.lang}>
                    {supportedLang.gtin}
                </Language>
            </Fragment>
        )
    );

    return (
        <div id="About" className="pageContent">
            <div className="pageSection grid-row">
                <Region.FDA>
                    <span className="aboutText">
                      <Text>US-intro-1</Text>
                    </span>
                    <span className="aboutText">
                      <Text>US-intro-2</Text>
                    </span>
                </Region.FDA>
                <Region.CE>
                    <span className="aboutText">
                      <Text>intro</Text>
                    </span>
                </Region.CE>
                <DetailsBlock inline contentClass="aboutText">
                    <Region.FDA>
                        <div className="grid-row">
                            <div>
                                <h3>
                                  <Text>intended-use.title</Text> /{' '}
                                  <Text>indications-for-use.title</Text>
                                </h3>
                                <span><Text>US-intended-use-1</Text></span>
                            </div>
                            {Text.resolve("US-intended-use-2", undefined, { page: "About" }) !== "" && <div>
                                <span><Text>US-intended-use-2</Text></span>
                            </div>}
                        </div>
                    </Region.FDA>
                    <Region.CE>
                        <div className="grid-row">
                            <div>
                                <h3><Text>intended-use.title</Text></h3>
                                <span><Text>intended-use</Text></span>
                            </div>
                            <div>
                                <h3>
                                  <Text>indications-for-use.title</Text>
                                </h3>
                                <span><Text>indications-for-use</Text></span>
                            </div>
                        </div>
                    </Region.CE>
                    <img className="aboutImage" alt="" src={Doctor} />
                </DetailsBlock>
                <DetailsBlock>
                    {Text.resolve("ce-certification", undefined, { page: "About" }) !== "" && <Details vcentered icon={<CE />}>
                        <span><Text>ce-certification</Text></span>
                    </Details>}
                    <Details vcentered icon={<Instructions />}>
                        <span>
                          <Text>read-the-instructions</Text>
                          <UserManual.AsLink id="manual" text="user-manual" />
                        </span>
                    </Details>
                    <Region.FDA>
                        <Details vcentered icon={<RxOnly />}>
                            <span>
                              <Text>Federal Law (USA) restricts this device to sale by or on the order of a licensed health practitioner.</Text>
                            </span>
                        </Details>
                    </Region.FDA>
                    <Details vcentered icon={<Manufacturer />}>
                        <span>{Text.resolve("address", undefined, { page: "About", region: undefined }) /* for some unkown reason it only works if the region is "uk" or undefined */ }</span>
                    </Details>
                    <Region.CE>
                        <Details vcentered icon={<ECREP />}>
                            <span><Text>eu-ec-rep-address</Text></span>
                        </Details>
                    </Region.CE>
                    <div className="separator" />
                    <div className="grid-col">
                        <Details vcentered icon={<GTIN />}>
                          <span>{supportedLangBlock}</span>
                        </Details>
                        <Details vcentered className="version">
                          <span>
                            <Text>version</Text>: (8012){' '}{require('services/server/functions/firebase/config').config.appVersion}
                          </span>
                        </Details>
                    </div>
                </DetailsBlock>
            </div>
        </div>
    );
};

const AboutPage = (props) => (    
    <div className="page">
        <div className="pageHeader">
            <h1 className="title wraptext">
              <Text>welcome</Text>
            </h1>
        </div>
        <About {...props} />
    </div>
);
  
export default AboutPage;
