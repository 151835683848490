import { capitalize } from "features/helpers/stringHelper";

import { SchemaFormFeatureTypes } from "../constants/types";

export const enumToBoolean = (value) => {
    if (typeof value === "boolean") {
        return value;
    }

    const enumMapping = {
        [SchemaFormFeatureTypes.Empty]: undefined,
        [SchemaFormFeatureTypes.Yes]: true,
        [SchemaFormFeatureTypes.No]: false,
    };
    return enumMapping[capitalize(value)];
};

export const booleanToEnum = (value) => {
    if (typeof value === "string") {
        return value;
    }

    const booleanMapping = {
        undefined: SchemaFormFeatureTypes.Empty,
        true: SchemaFormFeatureTypes.Yes,
        false: SchemaFormFeatureTypes.No
    };
    return capitalize(booleanMapping[value]);
};