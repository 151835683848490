
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Spinner from '@atlaskit/spinner';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Text } from 'ui/components/Text';
import { ValidatedSelect } from 'features/helpers/validators';

const renderSiteOptions = ({
    loadingSites,
    isEditPage,
    siteOptions,
    validationSchema,
    formSaved,
    onChange,
    onValidate,
    site,
}) => {
    if (loadingSites) {
        return <Spinner size="small" />;
    }
  
    if (isEditPage) {
        const siteLabel = (siteOptions.find((o) => o.value === site) || { label: "-" }).label;
        return <span>{siteLabel}</span>;
    }
  
    if (siteOptions && siteOptions.length === 1) {
        return <span>{siteOptions[0].label}</span>;
    }
  
    return (
        <ValidatedSelect
            options={siteOptions || []}
            name="site"
            placeholder={Text.resolve("CreateStudy.select-hcs")}
            schema={validationSchema}
            mandatory={true}
            showError={formSaved}
            onChange={onChange}
            onValidate={onValidate}
            value={site}
        />
    );
};

const renderClinicianSelect = ({ loadingClinicians, isEditPage, hasAccess, clinicianOptions, clinician, validationSchema, formSaved, onChange, onValidate }) => {
  if (loadingClinicians) {
    return <Spinner size="small" />;
  }

  if (isEditPage && !hasAccess(Study.commands.UPDATE_STUDY)) {
    return <span>{clinicianOptions.find((o) => o.value === clinician)?.label || "-"}</span>;
  }

  if (clinicianOptions?.length === 1) {
    return <span>{clinicianOptions[0].label}</span>;
  }

  return (
    <ValidatedSelect
      options={clinicianOptions || []}
      name="clinician"
      placeholder={Text.resolve("CreateStudy.select-clinician")}
      schema={validationSchema}
      mandatory={true}
      showError={formSaved}
      onChange={onChange}
      onValidate={onValidate}
      value={clinician}
    />
  );
};


export const PatientHcsForm = ({ siteOptions = [], loadingSites, isEditPage, site, onChange, onValidate, formSaved, validationSchema, clinicianOptions, loadingClinicians, hasAccess, clinician }) => {
    return (
        <div className="pageSection">
            <div className="title">
                <Text>CreateStudy.answer.hcs-details</Text>
            </div>
            <DetailsBlock>
                <Details id="siteField" label="hcs" help={siteOptions.length === 0 ? <span><Text>CreateStudy.only-users-hcs</Text><br /><Text>CreateStudy.if-an-expected-hcs</Text></span> : undefined} mandatory={siteOptions.length !== 1}>
                    {renderSiteOptions({
                        loadingSites,
                        isEditPage,
                        siteOptions,
                        validationSchema,
                        formSaved,
                        onChange,
                        onValidate,
                        site,
                    })}
                </Details>
                {site && (
                    <Details id="clinicianField" label="global.clinician.label" help={clinicianOptions.length === 0 ? <span><Text>CreateStudy.only-users-study</Text><br /><Text>CreateStudy.if-an-expected-clinician</Text></span> : undefined} mandatory={clinicianOptions.length !== 1}>
                        {renderClinicianSelect({
                            loadingClinicians,
                            isEditPage,
                            hasAccess,
                            clinicianOptions,
                            clinician,
                            validationSchema,
                            formSaved,
                            onChange,
                            onValidate,
                        })}
                    </Details>
                )}
            </DetailsBlock>
        </div>
    );
};
