import './style.css';

import AcurableIsoTipo from '../images/Isotipo-yellow.svg';
import AkNavigation, {AkContainerNavigationNested} from '@atlaskit/navigation'
import AvatarMenu from './Components/BottomMenus/AvatarMenu';
import Breakpoint from '../Grid/breakPoints';
import CreateDrawer from './Components/CreateDrawer';
import CreateIcon from '@atlaskit/icon/glyph/add';
import { CustomModal } from 'ui/acukit/ModalDialog';
import NavOptionsGroup from './Components/ItemsGroup';
import NavigationTitle from './Components/NavigationTitle';
import RegionMenu from './Components/BottomMenus/RegionMenu';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import Styled from 'styled-components';
import {Text} from 'ui/components/Text';
import Tooltip from '@atlaskit/tooltip';
import { connect } from "react-redux";
import history from 'history.js';
import { notify } from 'services/redux/actions/Notifications';
import { signOut } from 'services/iam';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { useState, useContext } from 'react';
import useLocationListener from 'ui/hooks/useLocationListener';
import { BrowserDBContext, BrowserDBName } from 'features/administration/providers/useBrowserDataBase';

const ShowMenu = (width, openMenu) => openMenu ? width : 0;

const ResponsiveNav = Styled.div.attrs(props => ({width: props.width || 250}))`
transition: all 0.2s;

@media (max-width: ${Breakpoint.breakNav}px){
  transform:translateX(-${props => ShowMenu(props.width,props.isOpen)}px);
  margin-right:-${props => ShowMenu(props.width,props.isOpen)}px;
  width:${props => props.isOpen ? '100%': '100vw'};
  position:fixed;
}
`;

const LogoutModal = connect(null, {notify})((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getDB } = useContext(BrowserDBContext);

  const handleSecondaryAction = async() => { 
    const imagesDB = getDB(BrowserDBName.Images);
    await imagesDB.content.clear();
    signOut().then(_ => history.replace('/')).catch(_ => notify('error', Text.resolve("Login.error.cant-close"), Text.resolve("Login.error.cant-close.desc"), { timeout: 6000 }));
  };
  
  return <CustomModal id='logout' 
    secondaryAction={handleSecondaryAction}
    heading='log-out'
    label='Log Out'
    isOpen={isOpen}
    onToggle={(isOpen) => setIsOpen(isOpen)}
    trigger={<div id='logout' className="navSideLink topNavItem" onClick={() => { setIsOpen(true); if (props.onClick) props.onClick(); }}><Tooltip content={Text.resolve('log-out')} position="right"><SignOutIcon size='medium'/></Tooltip></div>}>
    <Text>Login.logout-warning</Text>
  </CustomModal>
});

const MenuItems = ({ navItems, onItemSelected, isOpen }) => navItems.filter(group => !('show' in group) || group.show).map((group, index) => <NavOptionsGroup isOpen={isOpen} key={index} data={group.itemsGroup} onItemSelected={onItemSelected} titleGroup={group.titleGroup} hasSeparator={group.hasSeparator} />);

const getHeaderComponent = ({ isOpen, brand, headerContent }) => (
  <div className="header-nav-component">
    <NavigationTitle show={isOpen} brand={brand} />
    {isOpen && headerContent }
  </div>
);


const Navigation = (props) => {
  const {
    isOpen,
    brand,
    navCreate,
  } = props;
  
  const [currentUser] = useCurrentUser();

  const showCreateDrawer = props.navCreate && Array.isArray(props.navCreate) && props.navCreate.some(create => create.itemsGroup && create.itemsGroup.length > 0);
  const [drawer, setDrawer] = useState(), closeDrawer = () => setDrawer();
  
  const [width, setWidth] = useState(), handleResize = ({width}) => { setWidth(width); if (isOpen && width < 70 && props.onClose) props.onClose(); if (!isOpen && width >= 70 && props.onOpen) props.onOpen(); };

  useLocationListener();

  const onItemSelected = (e, _item) => { 
    closeDrawer(); 
    if (props.onItemSelected) props.onItemSelected(e);
  };

  return (
      <div className={'NavigationFather'}>
        <ResponsiveNav width={width}>
          <AkNavigation
          isOpen={isOpen}
          onResize={handleResize}
          globalPrimaryIcon={<img
            src={AcurableIsoTipo}
            alt=""
            style={{ width: '40px', height: 'auto' }}
            onClick={() => history.push("/")}
          />}

          drawers={[
            <CreateDrawer key={1} drawerIsOpen={drawer === 'CREATE'} closeDrawer={closeDrawer} brand={brand}>
              <MenuItems id={'CreateItems'} navItems={navCreate} onItemSelected={onItemSelected} />
            </CreateDrawer>
          ]}
          globalPrimaryActions={[
            <Tooltip key="2" position="right" content={Text.resolve("Menu.create-new")} >
              <div className={['navSideLink topNavItem'].concat(!showCreateDrawer && "hidden").join(" ")} onClick={_ => setDrawer('CREATE')}><CreateIcon label="create" /></div>
            </Tooltip>,
            <AvatarMenu key='currentUser' currentUser={currentUser} onItemSelected={onItemSelected} />,
            <LogoutModal key='logout' />
          ]}
          globalSecondaryActions={[
            <RegionMenu key="regionSelector" onItemSelected={onItemSelected} />
          ]}
          containerHeaderComponent={()=> getHeaderComponent(props)}>
            <AkContainerNavigationNested stack={[
              [ <MenuItems key={'mainMenu'} id={'MainItems'} navItems={props.navItems} onItemSelected={onItemSelected} isOpen={isOpen} /> ],
            ]}/>
          </AkNavigation>
        </ResponsiveNav>
      </div>
  );
}

export default Navigation;
