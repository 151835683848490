import { Redirect } from 'react-router-dom'
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';

export default () => {
  const [{action}] = useLocationParams();
  const [currentUser] = useCurrentUser();

  const hasAccessToLanding = currentUser?.metadata?.loadingContext || currentUser?.hasAccess(require('modules/diagnosis').StudyLanding);
  return <Redirect to={{pathname: (action === "welcome" || !hasAccessToLanding) ? require('modules/support').About.PATH : require('modules/diagnosis').StudyLanding.PATH}} />;
};
