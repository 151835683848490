import { useState, useContext, useCallback, useEffect } from 'react';
import PreferencesIcon from '@atlaskit/icon/glyph/preferences';
import { equals } from 'ramda';
import history from 'history.js';

import './style.css';
import { UserContext } from 'features/providers/userContextProvider';
import Modal from 'ui/acukit/ModalDialog';
import { Text } from "ui/components/Text";

import OwnersSelector from '../OwnersSelector';
import ProductSelector from '../ProductSelector';

const ContextSelector = ({
    contextState={},
    show,
    onClose
}) => {
    const handleClose = useCallback(onClose, [onClose]);
    
    const [selectedOwners, setSelectedOwners] = useState(contextState.selectedOwners);
    const onOwnersSelectChange = useCallback(e => setSelectedOwners(e.target.selectedOptions.map(s => s.value)), []);

    const [selectedProduct, setSelectedProduct] = useState(contextState.selectedProduct);
    const onProductChange = useCallback(e => setSelectedProduct(e.target.value), []);
    
    const onSave = useCallback(_ => {contextState.setSelectedOwners(selectedOwners); contextState.setProduct(selectedProduct); handleClose();}, [selectedOwners?.join(';'), selectedProduct]);
    const onCancel = useCallback(_ => {setSelectedOwners(contextState.selectedOwners); setSelectedProduct(contextState.selectedProduct); handleClose();}, [contextState.selectedOwners?.join(';'), contextState.selectedProduct.id]);
    const onSaveEnabled = selectedOwners?.length > 0 && (!equals(selectedOwners?.sort(), contextState.selectedOwners) || selectedProduct !== contextState.selectedProduct.id);

    useEffect(_ => {
        if (!equals(selectedOwners?.sort(), contextState.selectedOwners)) setSelectedOwners(contextState.selectedOwners);
        if (selectedProduct.id !== contextState.selectedProduct?.id) setSelectedProduct(contextState.selectedProduct)
    }, [contextState.selectedOwners?.join(';'), contextState.selectedProduct?.id])

    return (
        <>
            {show && (
                <Modal
                    id="selector-context"
                    show={show}
                    onClose={onCancel}
                    dontCloseOnClick={true}
                    size="lg"
                    actions={[
                        { text: 'confirm',  onClick: onSaveEnabled ? onSave : undefined, appearance: `primary${onSaveEnabled ? '' : ' disabled'}` },
                        { text: 'close', onClick: onCancel },
                    ]}
                >
                    <div className="context-selector-header">
                        <Text>
                            module.administration.fe.contextSelector.title
                        </Text>
                    </div>
                    <div className="context-selector-modal-content">   
                        <ProductSelector value={selectedProduct} onChange={onProductChange}/>
                        <OwnersSelector selectedProduct={selectedProduct} value={selectedOwners} onChange={onOwnersSelectChange}/>
                    </div>
                </Modal>
            )}
        </>
    );
};

const BrandTitle = ({ product, ...props }) => (
    <div className="context-selector-component-block">
        <SelectorInfo label={product} {...props} />
    </div>
);

const SelectorInfo = ({ label, IconComponent, handleClick }) => (
    <div className={`context-selector-info ${handleClick ? 'context-selector-cursor' : ''}`} onClick={handleClick}>
        {label}
        {IconComponent && (
            <IconComponent size="medium" />
        )}
    </div>
);

export default () => {
    const contextState = useContext(UserContext);
    const {
        selectedProduct,
        withContextSelector,
    } = contextState;

    const [showConfigModal, setShowConfigModal] = useState(false),
          handleShowModal  = _ => setShowConfigModal(true),
          handleCloseModal = _ => setShowConfigModal(false);

    return (
        <>
            {
                withContextSelector ? (
                    <>
                        <ContextSelector
                            contextState={contextState}
                            show={showConfigModal}
                            onClose={handleCloseModal}
                        />
                        <div className="context-selector-component-block">
                            <BrandTitle
                              label={selectedProduct?.label} 
                              IconComponent={PreferencesIcon} 
                              handleClick={handleShowModal} 
                            />
                        </div>
                    </>
                ) : (<BrandTitle label={selectedProduct?.label} handleClick={() => { if (window.location.pathname !== "/" && window.location.pathname !== "/study") history.push("/") }} /> )
            }
        </>
    );
};
