import { onRegionChanged } from 'services/server/functions/firebase/config/config.client';
import firebase from 'firebase/compat/app';

let app = undefined;
const appListeners = [];
const notifyApp = (newApp) => appListeners.forEach(cb => cb(newApp));
const loadApp    = (region, config) => {
  if (!app) {
    console.debug("Loading application on region", region);
    require('firebase/compat/firestore');
    require('firebase/compat/functions');
    app = firebase.initializeApp(config);

    notifyApp(app);
  } else {
    // TODO: look for a better way to wipe current modules state data (it seems there is somehting weird with redux persistence, the reason we force a logout)
    require('services/iam').signOut().then(_ => { window.location.reload(); });
    notifyApp(undefined);
  }
}

const onAppLoad  = (callback, id=appListeners.length) => { if (callback) { appListeners.push(callback); if (app) callback(app); return () => appListeners.splice(id, 1)[0]; } };
onRegionChanged(loadApp);

const Auth     = () => require('./authentication').default(app);

const DataBase = () => require('services/server/functions/firebase').DataBase();

const Storage  = () => require('./storage').default(app);

const Analytics = () => require('./analytics').default(app);

export { firebase, app, onAppLoad, Auth, DataBase, Storage, Analytics }; // TODO: config should? not be exposed. it can be accessed via "app.options"
