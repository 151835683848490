import ResetPassword from 'ui/components/ResetPasswordDialog';

export default (props) => (
  <ResetPassword 
    btn  
    label={props.I18n('ResetPassword.title')}
    message1={props.I18n('ResetPassword.confirm')}
    message2={props.I18n('ResetPassword.email-instructions')}
    id='resetpass'
    isReadOnly={true}
    {...props}
/>);