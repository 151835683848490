import * as R from 'ramda';
import * as errors from '../errors/codes'

import { getReason, getResolution } from '../errors/messages';

import { resolve } from '../model';
import { isSuperAdmin } from '../iam';

export const VALID_DATA_POLICY = (details) => ({ code: errors.INVALID_DATA_ERROR, reason: getReason(errors.INVALID_DATA_ERROR), resolution: getResolution(errors.INVALID_DATA_ERROR), details: JSON.stringify(details) });
export const UNIQUE_FIELD_POLICY = (details) => ({ code: errors.DUPLICATE_ERROR, reason: getReason(errors.DUPLICATE_ERROR), resolution: getResolution(errors.DUPLICATE_ERROR), details });
export const FORBIDDEN_POLICY = (details) => ({ code: errors.FORBIDDEN_ERROR, reason: getReason(errors.FORBIDDEN_ERROR), resolution: getResolution(errors.FORBIDDEN_ERROR), details });
export const FORBIDDEN_USER_EDIT_POLICY = (details) => ({ code: errors.FORBIDDEN_ERROR, reason: getReason(errors.FORBIDDEN_ERROR), resolution: "Please contact user to edit his email or phone. User email and phone can only be edited by the own user.", details });
export const NOT_EXIST_POLICY    = (details) => ({ code: errors.NOT_FOUND_ERROR, reason: getReason(errors.NOT_FOUND_ERROR), resolution: getResolution(errors.NOT_FOUND_ERROR), details });
export const UNAUTHORISED_POLICY = { code: errors.PERMISSION_ERROR, reason: getReason(errors.PERMISSION_ERROR), resolution: getResolution(errors.PERMISSION_ERROR) };
export const HAS_NO_RESOURCES_POLICY = (details) => ({ code: errors.FORBIDDEN_ERROR, reason: 'Cannot change organisation: the given healthcare site has resources', resolution: getResolution(errors.FORBIDDEN_ERROR), details });

const check = (condition) => (condition?.then ? condition : Promise.resolve(condition));
export const when = (condition) => ({ rejectWith: (error) => check(condition).then(condMet => condMet ? Promise.reject(error) : Promise.resolve(true)), and: (condition2) => check(condition).then(cond1 => when(cond1 && condition2)) });
// TODO: deprecate
export const validate = (snapshot) => resolve(snapshot).schema.validate(snapshot.data, {allowUnknown: true}) // TODO: stripUnknown: true
                                    .then(data => { snapshot.data = data; return data; })  // joi completes with defaults
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    .catch(error => Promise.reject(VALID_DATA_POLICY(error.details || error.message)));

export const checkDuplicatedID = (aggr, model, executor) => executor.execute(model.queries.ID_EXISTS.newRequest(aggr)).then(exist => when(exist).rejectWith(UNIQUE_FIELD_POLICY(`${model.label} unique identifier already in use.`)));

export const hasResources = (aggr, executor, models) => Promise.all(
  models
   .map(m => m.queries.LIST.newRequest({where: {field: 'data.owners', operator: 'array-contains', value: aggr.id}}))
   .map(req => executor.execute(req))  
).then(rs => rs.some(r => r.length));

export const userHasOwnership = async (user, urn, executor) => {
  const model = resolve(urn);
  const snap = await executor.execute(model.queries.GET.newRequest({ id: urn }));
  if (isSuperAdmin(user) || snap.metadata.allOwners.some(oid => user.data.owners.includes(oid))) return;
  throw FORBIDDEN_POLICY("User does not have ownership of the requested resource");
}

const ownerHasChanged = async (aggr, executor) => {
  if (!aggr.owners) return false;
  
  const aggrBefore = await executor.execute(require('./administration/model').HealthcareSite.queries.GET.newRequest({id: aggr.id}));
  return R.symmetricDifference(aggrBefore.data.owners, aggr.owners).length !== 0
}

export const checkHasNoResources = async (aggr, executor, models) => {
  const cond = (await ownerHasChanged(aggr, executor)) && (await hasResources(aggr, executor, models));
  return when(cond).rejectWith(HAS_NO_RESOURCES_POLICY(`This healthcare site's organisation cannot be edited.`));
}

export const documentExists = async(documentId, exec) => {
  const model = resolve(documentId);
  if (!model) throw VALID_DATA_POLICY(`URN does not correspond to any model: ${documentId}`);
  const exists = await exec(model.queries.ID_EXISTS.newRequest({ id: documentId }));
  if (!exists) throw NOT_EXIST_POLICY(`Document does not exist: ${documentId}`);
};

export const runAlreadyExistsPolicy = (requestData, existingSnap) => {
  return when(existingSnap !== undefined).rejectWith(UNIQUE_FIELD_POLICY(`Unique identifier ${requestData.id} already in use.`));
};

export const runNotExistsPolicy = (requestData, existingSnap) => {
  return when(existingSnap === undefined).rejectWith(NOT_EXIST_POLICY(`Unable to find unique identifier ${requestData.id}.`));
};

export const isFeatureEnabled = (featureFlag, context={}) => {
  let enabled = true;

  const appConfig = require('../firebase/config').config;
  const compareVersion = require('../executor/version').compare;
  const {version=appConfig.appVersion, client} = context;
  
  if (featureFlag.minVersion !== undefined) {
    enabled = compareVersion(featureFlag.minVersion, version) <= 0;
  }
  
  if (enabled && featureFlag.supportedClients?.length) {
    enabled = featureFlag.supportedClients.some(supportedClient => {
      let isSupported = true;
      if (supportedClient.type !== undefined) {
        isSupported = supportedClient.type === client.type;
      }
      if (isSupported && supportedClient.minVersion !== undefined) {
        isSupported = compareVersion(supportedClient.minVersion, client.version) <= 0;
      }
      if (isSupported && supportedClient.maxVersion !== undefined) {
        isSupported = compareVersion(supportedClient.maxVersion, client.version) > 0;
      }

      return isSupported;
    });
  }

  return enabled;
}