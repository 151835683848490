import { FORBIDDEN_POLICY, checkDuplicatedID, checkHasNoResources, hasResources, when, NOT_EXIST_POLICY, UNIQUE_FIELD_POLICY, VALID_DATA_POLICY, documentExists, userHasOwnership } from '../../policies';
import { Joi, mail, phone } from '../../../validation/rules';

import Config from '../config';
import { Roles } from '../../../iam/roles';
import { withDefaults } from '../../';
import { Preferences } from '../../system/model';
import Product, { ProductEntity } from './Product';
import { checkProducts, checkProductsSubset,  checkProductsStudyConfigSettings, checkIncludesProduct, checkHCSReset } from './policies';
import ProductStudy from './ProductStudy';
import ProductTest from './ProductTest';
import { getPatchForNullValues } from '../../../patch';

const OrganisationalUnit = (parent) => ({
  events: {
    PRODUCTS_SET: {},
    STUDY_CONFIG_SETTINGS_UPDATED: {},
    STUDY_TEMPLATE_SETTINGS_UPDATED: {},
    STUDY_SLEEP_REPORT_SETTINGS_UPDATED: {},
    SIGNAL_SETTINGS_UPDATED: {},
    REPORT_SETTINGS_UPDATED: {},
    FEATURE_ACTIONS_UPDATED: {},
  },
  commands: {
    SET_PRODUCTS: {
      checkPolicies: (data, existingParent, executor) => Promise.all([checkProducts(data, executor), checkProductsSubset(data, existingParent, executor), checkProductsStudyConfigSettings(data, existingParent, executor)]),
      roles: [Roles.superAdmin.id],
      get schema() { return Joi.object({ id: parent.schema.extract("id").required(), products: parent.schema.extract("products").required() }) },
      get event() { return parent.events.PRODUCTS_SET },
    },
    UPDATE_STUDY_CONFIG_SETTINGS: {
      checkPolicies: (data, existingParent, executor, { user }) => Promise.all([
        documentExists(data.productId, executor.execute),
        checkIncludesProduct(existingParent, data.productId, executor),
        userHasOwnership(user, data.id, executor),
      ]),
      get schema() {
        return Joi.object().keys({
          id: parent.schema.extract('id').required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
          settings: ProductStudy.schemas.StudyConfigSettings.schemas.Configure.getSchema(ProductStudy.schemas.StudyConfigSettings.fields.map(f => f.field)),
          reset: Joi.boolean().default(false),
        });
      },
      event: (action) => {
        return parent.events.STUDY_CONFIG_SETTINGS_UPDATED.new({
          id: action.data.id,
        });
      },
    },
    UPDATE_STUDY_TEMPLATE_SETTINGS: {
      checkPolicies: (data, existingParent, executor, { user }) => Promise.all([
        documentExists(data.productId, executor.execute),
        checkIncludesProduct(existingParent, data.productId, executor),
        userHasOwnership(user, data.id, executor),
      ]),
      get schema() {
        return Joi.object().keys({
          id: parent.schema.extract('id').required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
          settings: ProductStudy.schemas.StudyTemplateSettings.getSchema(ProductStudy.schemas.StudyTemplateSettings.fields.map(f => f.field)),
        });
      },
      event: (action) => {
        return parent.events.STUDY_TEMPLATE_SETTINGS_UPDATED.new({
          id: action.data.id,
        });
      },
    },
    UPDATE_STUDY_SLEEP_REPORT_SETTINGS: {
      checkPolicies: (data, existingParent, executor, { user }) => Promise.all([
        documentExists(data.productId, executor.execute),
        checkIncludesProduct(existingParent, data.productId, executor),
        userHasOwnership(user, data.id, executor),
      ]),
      get schema() {
        return Joi.object().keys({
          id: parent.schema.extract('id').required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
          settings: ProductTest.schemas.SleepReportSettings.schema,
          reset: Joi.boolean().default(false),
        });
      },
      event: (action) => {
        return parent.events.STUDY_SLEEP_REPORT_SETTINGS_UPDATED.new({
          id: action.data.id,
        });
      },
    },
    UPDATE_SIGNAL_SETTINGS: {
      checkPolicies: (data, _1, executor, { user }) => Promise.all([
        checkHCSReset(data),
        userHasOwnership(user, data.id, executor),
      ]),
      get schema() {
        return Joi.object().keys({
          id: parent.schema.extract('id').required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
          settings: ProductTest.schemas.SignalSettings.schema,
          reset: Joi.boolean().default(false),
        });
      },
      event: (action) => {
        return parent.events.SIGNAL_SETTINGS_UPDATED.new({
          id: action.data.id,
        });
      },
    },
    UPDATE_REPORT_SETTINGS: {
      checkPolicies: (data, _1, executor, { user }) => Promise.all([
        checkHCSReset(data),
        userHasOwnership(user, data.id, executor),
      ]),
      get schema() {
        return Joi.object().keys({
          id: parent.schema.extract('id').required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
          settings: ProductTest.schemas.ReportSettings.schema,
          reset: Joi.boolean().default(false),
        });
      },
      event: (action) => {
        return parent.events.REPORT_SETTINGS_UPDATED.new({
          id: action.data.id,
        });
      },
    },
    UPDATE_FEATURE_ACTIONS: {
      checkPolicies: (data, _1, executor, { user }) => Promise.all([
        userHasOwnership(user, data.id, executor),
      ]),
      get schema() {
        const prodSchema = Product.commands.SET_FEATURE_ACTIONS.schema;
        return Joi.object().keys({
          id: parent.schema.extract('id').required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
          featureId: prodSchema.extract('featureId'),
          actions: prodSchema.extract('actions'),
          reset: prodSchema.extract('reset'),
        });
      },
      event: (action) => {
        return parent.events.FEATURE_ACTIONS_UPDATED.new({
          id: action.data.id,
        });
      },
    },
  },
  queries: {
    GET_SIGNAL_SETTINGS_FORM: {
      checkPolicies: (data) => Promise.all([when(data.ownerId?.includes("/HealthcareSite")).rejectWith(VALID_DATA_POLICY('Query not available for HealthcareSite'))]),
      roles: [Roles.superAdmin.id],
      get schema() {
        return Joi.object().keys({
          ownerId: Joi.alternatives().try(Joi.referenceOf(Product), Joi.referenceOf(parent)).required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
        });
      },
      newRequest: (args, metadata) => ({
        action : parent.queries.GET_SIGNAL_SETTINGS_FORM.new(args, metadata),
        depends: []
      })
    },
    GET_REPORT_SETTINGS_FORM: {
      checkPolicies: (data) => Promise.all([when(data.ownerId?.includes("/HealthcareSite")).rejectWith(VALID_DATA_POLICY('Query not available for HealthcareSite'))]),
      roles: [Roles.superAdmin.id],
      get schema() {
        return Joi.object().keys({
          ownerId: Joi.alternatives().try(Joi.referenceOf(Product), Joi.referenceOf(parent)).required(),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
        });
      },
      newRequest: (args, metadata) => ({
        action : parent.queries.GET_REPORT_SETTINGS_FORM.new(args, metadata),
        depends: []
      })
    },
    GET_FEATURE_ACTIONS_FORM: {
      roles: [Roles.superAdmin.id],
      get schema() {
        const prodSchema = Product.commands.SET_FEATURE_ACTIONS.schema;
        return Joi.object().keys({
          ownerId: Joi.alternatives().try(Joi.referenceOf(Product), Joi.referenceOf(parent)).required(),
          featureId: prodSchema.extract('featureId'),
          productId: Joi.referenceOf(Product).default(Config.products.sa100.id),
        });
      },
      newRequest: (args, metadata) => ({
        action : parent.queries.GET_FEATURE_ACTIONS_FORM.new(args, metadata),
        depends: []
      })
    },
  },
});

let orgaOrganisationalUnit, hcsOrganisationalUnit;
const OrgaOrganisationalUnit = () => {
  if (!orgaOrganisationalUnit) orgaOrganisationalUnit = OrganisationalUnit(Organisation);
  return orgaOrganisationalUnit;
}
const HCSOrganisationalUnit = () => {
  if (!hcsOrganisationalUnit) hcsOrganisationalUnit = OrganisationalUnit(HealthcareSite);
  return hcsOrganisationalUnit;
}

let HealthcareSite = {
  context: Config.context,
  name   : Config.aggregatesNames.HealthcareSite,
  label  : 'Healthcare site',
  schema : Joi.object().keys({
    name        : Joi.string(),
    department  : Joi.string().allow(''),
    address     : Joi.string(),
    country     : Joi.string(), // TODO: use Joi.string().isoCountryCode()
    postCode    : Joi.string().postalCode(Joi.ref('country')),
    mail        : mail,
    phone: phone,    // TODO: use country to infer prefix and save as URI RFC3966 format (joi-phone-number may help) -> do not show URI in UI!
    products : Joi.array().items(Joi.referenceOf(Product)),
  }),
  entities: {
    get Product()     { delete this.Product; this.Product = ProductEntity(HealthcareSite); return this.Product; },
    get Preferences() { delete this.Preferences; this.Preferences = Preferences(HealthcareSite); return this.Preferences; },
  },
  get events() {
    delete this.events;
    this.events = {
      HCS_REGISTERED  : { label: 'Healthcare site created' },
      HCS_UPDATED     : { label: 'Healthcare site edited' },
      HCS_UNREGISTERED: {
        label: 'Healthcare site deleted',
        snapshot: () => ({ metadata: { deleted: true }})
      },
      ...HCSOrganisationalUnit().events,
    };
    return this.events;
  },
  get commands() {
    delete this.commands;
    this.commands = {
      UPDATE_HCS: {
        checkPolicies: (hcs, _, executor) => checkHasNoResources(hcs, executor, [require('../../diagnosis/model').Study, require('../../devices/model').Device, require('../../authentication/model').User]),
        label: "Edit healthcare site",
        get schema() { return HealthcareSite.schema.fork('id', schema => schema.required()).keys({
          country: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('country')),
          postCode: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('postCode')),
          address: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('address')),
          mail: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('mail')),
          phone: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('phone')),
        }); },
        event: (action) => {
          const patch = getPatchForNullValues(action.data, 'data.');
          if (patch.length) action.metadata.patch = patch;
          return HealthcareSite.events.HCS_UPDATED.new(action.data, action.metadata);
        },
      },
      ...HCSOrganisationalUnit().commands,
    };
    return this.commands;
  },
  get queries() {
    delete this.queries;
    this.queries = {
      ...HCSOrganisationalUnit().queries,
    }
    return this.queries;
  },
};
HealthcareSite = withDefaults()(HealthcareSite);

const Trial = (parent) => {
  let TrialEntity = {
    context: Config.context,
    name   : 'Trial',
    label  : 'Clinical trial',
    schema : Joi.object().keys({
      name: Joi.string(),
      description: Joi.string().allow(''),
      principal: Joi.string().uri().allow(null), // User reference to principal investigator (PI)
    }),
    entities: { get Preferences() { delete this.Preferences; this.Preferences = Preferences(TrialEntity); return this.Preferences; } },
    events: {
      TRIAL_REGISTERED: {},
      CLINICIANS_UPDATED: {},
      TRIAL_EDITED: {},
      TRIAL_CANCELED: { snapshot: () => ({ metadata: { deleted: true }}) },
    },
    commands: {
      REGISTER_TRIAL: {
        checkPolicies: (req, trial) => when(trial).rejectWith(UNIQUE_FIELD_POLICY(`Clinical trial unique identifier ${req.id} already in use.`)),
        get schema() { return TrialEntity.schema.fork(['id', 'name'], schema => schema.required()); },
        get event()  { return TrialEntity.events.TRIAL_REGISTERED; }
      },
      UPDATE_CLINICIANS: {
        checkPolicies: (req, trial) => when(!trial).rejectWith(NOT_EXIST_POLICY(`Clinical trial ${req.id} does not exists`)),
        get schema() { return Joi.object().keys({
          id: TrialEntity.schema.extract('id').required(),
          principal: TrialEntity.schema.extract('principal'),
          clinicians: Joi.array().items(Joi.string().uri())
        }); },
        get event()  { return TrialEntity.events.CLINICIANS_UPDATED; }
      },
      EDIT_TRIAL: {
        checkPolicies: (req, trial) => when(!trial).rejectWith(NOT_EXIST_POLICY(`Clinical trial ${req.id} does not exists`)),
        get schema() { return TrialEntity.schema.fork(['id'], schema => schema.required()).or('name', 'description'); },
        get event()  { return TrialEntity.events.TRIAL_EDITED; }
      },
      CANCEL_TRIAL: {
        checkPolicies: (req, trial) => when(!trial).rejectWith(NOT_EXIST_POLICY(`Clinical trial ${req.id} does not exists`)),
        get schema() { return Joi.object().keys({id : TrialEntity.schema.extract('id').required()}); },
        get event()  { return TrialEntity.events.TRIAL_CANCELED; }
      }
    }
  };
  TrialEntity = withDefaults()(TrialEntity, parent);
  
  return TrialEntity;
}

let Organisation = {
  context: Config.context,
  name   : Config.aggregatesNames.Organisation,
  schema : Joi.object({
    address  : Joi.string(),
    postCode : Joi.string().postalCode(Joi.ref('country')),
    country  : Joi.string(),
    mail     : mail,
    name     : Joi.string(),
    phone    : phone, // TODO: use country to infer prefix and save as URI RFC3966 format (joi-phone-number may help) -> do not show URI in UI!
    uid      : Joi.string().allow(''),
    profile  : Joi.object().keys({
      logo: Joi.string()
    }),
    products : Joi.array().items(Joi.referenceOf(Product)),
  }),
  entities: {
    get Product()     { delete this.Product;     this.Product = ProductEntity(Organisation);   return this.Product; },
    get Preferences() { delete this.Preferences; this.Preferences = Preferences(Organisation); return this.Preferences; },
    get Trial()       { delete this.Trial;       this.Trial = Trial(Organisation);             return this.Trial; }
  },
  get events() {
    delete this.events;
    this.events = {
      ORGANISATION_REGISTERED: { label: 'Organisation created' },
      ORGANISATION_UPDATED: { label: 'Organisation edited' },
      ORGANISATION_PROFILE_CONFIGURED: {},
      ORGANISATION_UNREGISTERED: {
        label: 'Organisation deleted',
        snapshot: () => ({ metadata: { deleted: true } })
      },
      STUDY_CONFIG_SETTINGS_UPDATED: {},
      ...OrgaOrganisationalUnit().events,
    };
    return this.events;
  },
  get commands() {
    delete this.commands;
    this.commands = {
      REGISTER_ORGANISATION: {
        checkPolicies: (org, _, executor) => Promise.all([checkDuplicatedID(org, Organisation, executor), checkProducts(org, executor)]),
        label: "Create organisation",
        roles: [Roles.superAdmin.id],
        get schema() { return Organisation.schema.fork(['id', 'name'], schema => schema.required()).keys({
          country: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('country')),
          postCode: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('postCode')),
          address: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('address')),
          mail: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('mail')),
          phone: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('phone')),
        }).fork('products', schema => schema.default((parent) => {
          if (parent.id === Config.organisation.root.id) return [];
          return [Product.newURN(Config.products.sa100.id)];
        }).custom((value, options) => {
          if (options.state.ancestors[0].id === Config.organisation.root.id) return value;
          if (!value?.length) throw new Error("An Organisation must be created with at least 1 product");
          return value;
        })); },
        event: (action) => {
          const patch = getPatchForNullValues(action.data, 'data.');
          if (patch.length) action.metadata.patch = patch;
          return Organisation.events.ORGANISATION_REGISTERED.new(action.data, action.metadata);
        },
      },
      UNREGISTER_ORGANISATION: {
        checkPolicies: (org, _, executor) => Promise.all([
          when(org.id === Config.organisation.root.id).rejectWith(FORBIDDEN_POLICY('Root organisation can not be deleted')),
          when(hasResources(org, executor, [HealthcareSite, require('../../authentication/model').User])).rejectWith(FORBIDDEN_POLICY('Organisation has resources assigned, please transfer or delete them, and try again'))
        ]),
        label: "Delete organisation",
        roles: [Roles.superAdmin.id],
        get schema() { return Joi.object({ id: Organisation.schema.extract('id').required() }) },
        get event() { return Organisation.events.ORGANISATION_UNREGISTERED; },
      },
      UPDATE_ORGANISATION: {
        label: "Edit organisation",
        get schema() { return Organisation.schema.fork('id', schema => schema.required()).keys({
          country: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('country')),
          postCode: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('postCode')),
          address: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('address')),
          mail: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('mail')),
          phone: Joi.alternatives().try(Joi.string().valid(null), Organisation.schema.extract('phone')),
        }).fork(['products'], schema => schema.strip()); },
        event: (action) => {
          const patch = getPatchForNullValues(action.data, 'data.');
          if (patch.length) action.metadata.patch = patch;
          return Organisation.events.ORGANISATION_UPDATED.new(action.data, action.metadata);
        },
      },
      CONFIGURE_ORGANISATION_PROFILE: {
        roles: [Roles.superAdmin.id],
        get schema() { return Joi.object({ id: Organisation.schema.extract("id").required(), profile: Organisation.schema.extract("profile").required() }) },
        get event() { return Organisation.events.ORGANISATION_PROFILE_CONFIGURED; },
      },
      REGISTER_HCS: {
        checkPolicies: (hcs, _, executor) => checkDuplicatedID(hcs, HealthcareSite, executor),
        label: "Create healthcare site",
        get schema() { return HealthcareSite.schema.fork(['id', 'name'], schema => schema.required()).keys({
          country: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('country')),
          postCode: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('postCode')),
          address: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('address')),
          mail: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('mail')),
          phone: Joi.alternatives().try(Joi.string().valid(null), HealthcareSite.schema.extract('phone')),
        }); },
        event: (action) => {
          const patch = getPatchForNullValues(action.data, 'data.');
          if (patch.length) action.metadata.patch = patch;
          return HealthcareSite.events.HCS_REGISTERED.new(action.data, action.metadata);
        },
      },
      UNREGISTER_HCS: {
        label: "Delete healthcare site",
        checkPolicies: (hcs, _, executor) => when(hasResources(hcs, executor, [require('../../diagnosis/model').Study, require('../../devices/model').Device, require('../../authentication/model').User])).rejectWith(FORBIDDEN_POLICY('Healthcare site has resources assigned, please transfer or delete them, and try again')),
        get schema() { return HealthcareSite.schema.fork('id', schema => schema.required()); },
        get event() { return HealthcareSite.events.HCS_UNREGISTERED; },
      },
      ...OrgaOrganisationalUnit().commands,
    };
    return this.commands;
  },
  get queries() {
    delete this.queries;
    this.queries = {
      GET_NAME: {
        label: "Get organisation name",
      },
      ...OrgaOrganisationalUnit().queries,
    }
    return this.queries;
  },
};

Organisation = withDefaults(HealthcareSite)(Organisation);

const isAcurable      = (organisation) => (organisation?.aggregate?.id || organisation) === Config.organisation.root.id;
const isFromAcurable  = (user) => user?.metadata?.isFromAcurable || user?.data?.mail?.endsWith('@acurable.com') || (user?.data?.owners || []).some(isAcurable);
const isFromRoot      = (user) => (user?.data?.owners || []).some(isAcurable);

export {
  isAcurable,
  isFromAcurable,
  isFromRoot,
  Organisation,
  HealthcareSite,
  Product
};