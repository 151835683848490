import { useContext, Fragment } from 'react';
import i18n from 'services/server/functions/i18n';
import Mustache from "mustache";

const Text = ({variables={}, context, values, children}) => {
  useContext(require('features/providers/userContextProvider').UserContext);

  const { page } = useContext(require('PageSelector').PageSelectorContext) || {};
  
  const stringVars = Object.entries(variables).reduce((all, [vkey, value]) => vkey.type === 'string' ? {...all, [vkey]: value} : all, {});
  const componentVars = Object.entries(variables).reduce((all, [vkey, value]) => !(vkey in stringVars) ? {...all, [vkey]: value} : all, {});
  
  if (values) {
    return i18n.resolveValue(values);
  }
  else if (typeof children !== 'string') { // TODO: define pattern for keys
    return children === undefined ? <Fragment/> : children;
  }

  // TODO: localisation: currencies, number formats, dates
  // Integers: nothing to do really
  // Float ... es_SP: 1.00,10, en_GB: 1,000.00
  // currency: ... Eu, GBP, ..
  const key = children.replace(/%\(([^)]+)\)/g, '%{$1}');
  // context && context.page && console.log(context, key, variables)
  // console.debug('Text:', data, context,  children, key)
  // <Text>CreateStudy.title</Text>
  // <Text n={} data={user} context={""}>User '%n' created.</Text>

  //TODO: validate children acceptet format
  return i18n.resolve(key, stringVars, {page, ...context}).split('%{').reduce((components, str, idx) => {
    if (!str.includes('}')) return components.concat(<Fragment key={`${key}_${idx}`}>{str}</Fragment>);
    const transvar = str.split('}').shift();
    return components.concat([
      <Fragment key={`${key}_${idx}_1`}>{componentVars[transvar] || `%{${transvar}}`}</Fragment>, 
      <Fragment key={`${key}_${idx}_2`}>{str.split('}')[1]}</Fragment>
    ]);
  }, []);
}

Text.i18n = i18n;
Text.resolve = (text, variables, context, defaultValue) => typeof text === 'string' ? i18n.resolve(text.replace(/%\(([^)]+)\)/g, '%{$1}'), variables, context, defaultValue) : text;
Text.resolveValue = i18n.resolveValue;

Text.withTranslations = translations => {
  const txs = (translations[i18n.current] || translations.en)();
  (Array.isArray(txs) ? txs : [ txs ]).forEach(t => i18n.translator.add(t));
  return Text;
}

Text.withContext = context => {
  const Contextualised            = ({ context: ctx, ...props }) => <Text {...props} context={{...context, ...ctx}} />;
  Contextualised.withContext      = ctx => Text.withContext({...context, ...ctx});
  Contextualised.withTranslations = translations => Text.withTranslations(translations).withContext(context);
  Contextualised.resolve          = (text, variables, ctx) => Text.resolve(text, variables, {...context, ...ctx});
  Contextualised.resolveValue     = Text.resolveValue; // TODO: does this use context ?
  
  return Contextualised;
}

Text.render = (template, data) => {
  if (!template || !data) {
    return '-';
  }

  let templateRender = '';

  try {
    let text = template;

    template.match(/{{{(.*?)}}}/mg).forEach((match) => {
      const variable = match.replaceAll('{{{', '').replaceAll('}}}', '');
      text = text.replaceAll(match, `${match} {{^${variable}}}-{{/${variable}}}`);
    });

    templateRender = Mustache.render(text, data);
  } catch {
    templateRender = template;
  }

  return templateRender;
};

export { Text }