import * as R from 'ramda';

import { dataFrom, isEvent } from '../executor/urn';

import Config from '../model/administration/config';
import { DataBase } from '../firebase';
import { resolve } from '../model';
import { applyPatch } from '../patch';

const { organisation: {root: rootOrganisation} } = Config;

// Timestamp is firestore's date and time field type
const isDateType = (value) => value instanceof Date || value?.constructor?.name === 'Timestamp';

Object.isObject = item => item !== null && typeof item === 'object' && !Array.isArray(item) && !isDateType(item); // typeof null === 'object'
/**
 * This method performs a deep merge and deletes fields with value undefined
 * @param  {...any} objects List of objects to be merged
 * @returns 
 */
export const mergeDeep = (...objects) => objects.reduce((target, source={}) => 
  Object.keys(source).reduce( (merge, key) => {
    if (source[key] !== undefined) merge[key] = Object.isObject(source[key]) ? mergeDeep(merge[key] || {}, source[key]) : source[key];
    else if (key in merge)         delete merge[key]; // source[key] === undefined is a patch pattern that means remove that field (DB techs like Firestore do not allow to store events with undefined fields, then we allow to use null as alternative)
    
    return merge;
  }, target)
, {});

const buildSnapDiff = (eventOrSnap) => ({ data: eventOrSnap?.data || {}, metadata: eventOrSnap?.metadata || {} });

const buildObjectsToMerge = (event, prevSnap, baseSnap, event2snapFns) => {
  return [
    ...baseSnap,
    // Important that prev goes before event
    ...[prevSnap, event].filter(s => s !== undefined).map(buildSnapDiff),
    ...event2snapFns.map(event2snap => event2snap(event, prevSnap)),
  ];
};

const getEventParentIdForEntities = (event) => {
  if (event.aggregate.name.split("/").length > 1) {
    return event.aggregate.id.split("/").slice(0, -2).join("/");
  }
  return undefined;
};

const addProductToSnapshot = snapshot => {
  if (!snapshot) return undefined;
  const product = snapshot.metadata.allOwners.find(o => o.includes("/Product/"));
  if (product) snapshot.metadata.product = product;
  return snapshot;
};

// NOTE: updateSnapshot cannot be asynchronous since it is being used as Redux reducer
export const updateSnapshot = (snapOrChange, event) => {
  const 
    { events: { [event.type]: eventModel }, snapshot: model2snap = _ => undefined } = resolve(event),
    { snapshot: event2snap = _ => undefined } = eventModel || {};
  // TODO: not very efficient and needs fixing ...
  // 1. Events should be expressed in terms of differences, maybe using something like https://github.com/Starcounter-Jack/JSON-Patch -> model2Snap and event2Snap are now expressed in term of differences (needs more improvements)
  // 2. priority: 1) event2snap, 2) model2snap 3) default / simple PATCH ?
  const prevSnap  = (Array.isArray(snapOrChange) ? snapOrChange[0] : snapOrChange);
  const parent = getEventParentIdForEntities(event);
  const baseSnapshot = [ // should not contain anything related to prev
    { context  : event.context, aggregate: event.aggregate, data: { owners: [event.aggregate.name.includes('/') ? event.aggregate.id.split(`/${event.aggregate.name.split('/').pop()}`)[0] : rootOrganisation.id] }, metadata: { archived: false, parent } },
  ];
  const event2snapFns = [
    model2snap,
    event2snap,
  ];
  const curr = applyPatch(mergeDeep(...buildObjectsToMerge(event, prevSnap, baseSnapshot, event2snapFns)), event.metadata?.patch);
  delete curr.metadata?.patch;

  curr.metadata.lastEvent = event.seq;
  curr.metadata.events    = [...(curr.metadata.events || []), {id: event.id, ...(event.metadata.derivedEvent ? {derivedFrom: event.metadata.causationId} : undefined), timestamp: event.timestamp}];  // TODO: some libraries allow extract timestamp from ULID, can we with our library? add user i
  if (curr.metadata.updatedBySnapshotHandler) curr.metadata.updatedBySnapshotHandler = false;
  return [curr, prevSnap];
}

export const unsetOldFields = (oldData, newData) => !oldData ? newData : !newData ? oldData : R.uniq(Object.keys(oldData).concat(Object.keys(newData))).reduce( (merge, key) => {
  if (!(key in newData)) merge[key] = undefined; // Mark as unset
  else if (!Object.isObject(newData[key])) merge[key] = newData[key];
  else merge[key] = Object.isObject(oldData[key]) ? unsetOldFields(oldData[key], newData[key]) : newData[key];
  
  return merge;
}, {});

export const eventsToSnapshots = (events, metadata) => Object.values(R.reduceBy(updateSnapshot, [undefined, undefined], R.path(['aggregate', 'id']), events)).map(([currSnap, prevSnap]) => {
  currSnap.metadata = {...currSnap.metadata, ...metadata};
  if (prevSnap !== undefined) prevSnap.metadata = {...prevSnap.metadata, ...metadata};

  return [currSnap, prevSnap];
});

const ownerEventsToSnap = (owner) => (events, metadata) => {
  if (!events || events.length === 0) {
    console.error("ERROR [withAllOwners::ownerEventsToSnap] Owner", owner, "does not exists, this URN has no events associated, skipping it")
    return undefined
  }

  const snapChanges = eventsToSnapshots(events, metadata);
  const [snap] = snapChanges[0] || [];
  if (!snap) {
    console.error("ERROR [withAllOwners::ownerEventsToSnap] Owner", owner, "has events associated but there are no snapshots associated to it (probably because of events transformations), skipping snapshot")
    return undefined
  }

  return snap;
}

export const withAllOwners = async (snapOrChange, lastEvent, skip=[]) => {
  const useChange = Array.isArray(snapOrChange);
  const snap      = useChange ? snapOrChange[0] : snapOrChange;
  let   prevSnap  = useChange ? snapOrChange[1] : undefined;
  
  if (!lastEvent && !useChange) lastEvent=snap?.metadata.lastEvent;

  if (snap) {
    const baseOwners = [snap.aggregate.id, ...snap.data.owners];
    const allOwners = await Promise.all(snap.data.owners
      .filter(o => o !== snap.aggregate.id && o !== rootOrganisation.id && !skip.includes(o))
      .map(owner => require('../event').eventsFrom(require('../executor/urn').dataFrom(owner).model, { skipOtherInstances: true, endAt: lastEvent })
        .then(ownerEventsToSnap(owner))))
      .then(ownersSnap => ownersSnap.filter(s => s && !s.metadata.deleted))
      .then(ownersSnap => Promise.all(ownersSnap.map(o => withAllOwners(o, lastEvent, baseOwners))))
      .then(ownersSnap => R.uniq(ownersSnap.reduce((all, o) => all.concat(o.metadata.allOwners), baseOwners)));
    
    snap.metadata           = snap.metadata || {};
    snap.metadata.allOwners = allOwners;
  }

  if (useChange) {
    const prevOwners = ((prevSnap || {}).data || {}).owners || [];
    const currOwners = ((snap || {}).data || {}).owners || [];
    if (prevSnap !== undefined && (prevOwners.length !== currOwners.length || prevOwners.some(o => currOwners.indexOf(o) < 0))) { // Optimisation to avoid computing all owners if owners have not changed
      prevSnap = await withAllOwners(prevSnap);
    } else if (prevSnap !== undefined) {
      prevSnap.metadata.allOwners = snap.metadata.allOwners;
    }

    addProductToSnapshot(snap);
    return [snap, prevSnap];
  }

  addProductToSnapshot(snap);
  return snap;
}

// Field ONLY necessary to make specific queries that pass firestore rules when a User is assigned an Organisation and wants to query data specific to a HCS not directly assigned
const withBelongsTo = async (snapOrChange) => {
  const isArray = Array.isArray(snapOrChange);
  const snap = isArray ? snapOrChange[0] : snapOrChange;
  if (snap?.aggregate?.name !== "User") return snapOrChange;
  if (snap.data.owners.includes(rootOrganisation.id) || snap.data.roles.includes("urn:com:acurable:apsa:role:superadmin")) return snapOrChange;
  const userOrganisationOwners = snap.data.owners.filter(o => o.includes("/Organisation/"));
  if (!userOrganisationOwners.length) return snapOrChange;

  const hcsEventsWithOwners = await DataBase().events().forAggregate({ name: "HealthcareSite" }).asQuery().and("data.owners", "array-contains-any", userOrganisationOwners).get();
  const hcsWithOwnersIds = [...new Set(hcsEventsWithOwners.map(e => e.aggregate.id))];

  const hcsSnapshots = await Promise.all(hcsWithOwnersIds.map(id => snapshotFrom(dataFrom(id).model)));

  snap.metadata.belongsTo = [...hcsSnapshots.reduce((acc, hcsSnap) => {
    // We must check the hcs is not deleted and the owners haven't been changed
    if (!hcsSnap.metadata.deleted && hcsSnap.data.owners.some(o => userOrganisationOwners.includes(o))) acc.add(hcsSnap.aggregate.id);
    return acc;
  }, new Set(snap.data.owners))];

  return isArray ? [snap, snapOrChange[1]] : snap;
};

export const changeFrom   = (reference, options={}) => require('../event').eventsFrom(reference, {endAt: isEvent(reference.id || reference) ? dataFrom(reference.id || reference).id : options.lastEvent}).then(evts => eventsToSnapshots(evts, options.metadata)[0] || []).then(withAllOwners).then(withBelongsTo);
export const snapshotFrom = (reference, options={}) => changeFrom(reference, options).then(change => (change || [])[0]);
export const getSnapshotFromEventsById = (snapId) => snapshotFrom(dataFrom(snapId).model);

export const changesFrom   = (model, options={}) => require('../event').eventsFrom(model, {endAt: options.lastEvent}).then(evts => eventsToSnapshots(evts.filter(e => e.aggregate.name === model.name), options.metadata)).then(changes => options.withAllOwners ? Promise.all(changes.map(withAllOwners)) : changes).then(changes  => options.withAllOwners ? Promise.all(changes.map(withBelongsTo)) : changes);
export const snapshotsFrom = (model, options={}) => changesFrom(model, options).then(changes => changes.map(change => (change || [])[0]));

////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////