import './style.css';
import { Device } from 'services/server/functions/model/devices/model';
import {Text} from 'ui/components/Text';

export const StatusLegendTooltip = ({isChange}) => (
  <div className='tooltipLegend'>
    <div className='title'>{isChange ? <Text>StatusLegendTooltip.title</Text> : Text.resolve('current-status', {}, { page: "ViewDevice" })}</div>
    <div className='statusItems grid-row'>
      { Object.values(Device.STATUS).map((s, i) =>  <div key={i} className="statusItem"><div className={`status ${s.toLowerCase()}`} >{Text.resolve(Device.STATUS_LABELS[s], {}, { schema: "devices.Device" })}</div><span className="definition">{Text.resolve(Device.STATUS_DESCRIPTIONS[s], {}, { schema: "devices.Device" })}</span></div>) }
    </div>
  </div>
);

const DeviceStatusLegend = _ => (
  <div className='legend'>
    <div className='title'><Text>DeviceStatusLegend.title</Text></div>
    <div className='items'>
      { Object.values(Device.STATUS).map((s, i) =>  <div key={i} className={`status ${s.toLowerCase()}`} >{Text.resolve(s, {}, { schema: "devices.Device" })}</div>) }
    </div>
  </div>
);

export default DeviceStatusLegend;