import { ModelFormTypes } from "features/constants/modelActionTypes";

export const StudySettingsReducerTypes = Object.freeze({
    SchemaUpdated: "SchemaUpdated",
    PerformUserDataInteraction: "PerformUserDataInteraction",
    OwnerIdHasBeenDefined: "OwnerIdHasBeenDefined",
    currentOwnerHasBeenUpdated: "currentOwnerHasBeenUpdated"
});

export const getStudySettingsReducerInitialState = (overrides) => ({
    schema: {},
    dataCausedByUser: {},
    studySettingsOwner: undefined,
    ...overrides,
    formId: ModelFormTypes.StudySettings,
    needsPersist: false,
    needsRetrieveSchemas: false,
});

export const studySettingsReducer = (state, action) => {
    switch (action?.type) {
        case StudySettingsReducerTypes.SchemaUpdated:
            const schema = action?.payload;
            return {
                ...state,
                schema,
                // quick fix for when going back on edit study it doesn't reset, even though ideally should only make the query once
                dataCausedByUser: state.dataCausedByUser || schema?.data || {},
                needsPersist: true,
                needsRetrieveSchemas: false,
            };
        case StudySettingsReducerTypes.PerformUserDataInteraction:
            return {
                ...state,
                dataCausedByUser: action?.payload,
                needsPersist: true,
                needsRetrieveSchemas: false,
            };
        case StudySettingsReducerTypes.OwnerIdHasBeenDefined:
            return {
                ...state,
                studySettingsOwner: action?.payload,
                needsPersist: true,
                needsRetrieveSchemas: false,
            };
        case StudySettingsReducerTypes.currentOwnerHasBeenUpdated:
            return {
                ...state,
                studySettingsOwner: action?.payload,
                schema: {},
                dataCausedByUser: {},
                needsPersist: true,
                needsRetrieveSchemas: true, 
            };
        default:
            return state;
    }
};