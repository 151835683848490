// import { Joi as _Joi } from 'joi';

import i18n from '../i18n';
import moment from 'moment';
import { withCustomError } from './errors';

const _Joi = require('joi');

// joi.string().isoDate() performs a transformation of the date into UTC
const isoDateWithOffset = (joi) => ({
  type    : 'string',
  base    : joi.string(),
  messages: {
    'isoDate.WrongISO8061Format': 'Needs to be a valid ISO8601 date string.'
  },
  rules: {
    isoDateWithOffset: {
      validate(value, helpers) {
        if (!value) 
          return value;

        const m = moment(value).utcOffset(value); // tries to parse any valid date (unix timestamps, ISO_8601, ...) https://momentjs.com/docs/#/parsing/string/
        if (m.isValid()) {
          return m.toISOString(true); // if valid, ensure ISO_8601 format
        }
        return helpers.error('isoDate.WrongISO8061Format')
      }
    }
  }
});

const base32 = (joi) => ({
  type    : 'base32',
  base    : joi.string(),
  messages: {
    'base32.Base32': 'needs to be a valid Base32 string.'
  },
  coerce(value, helpers) {
    if (!helpers.schema.$_getFlag('base32')) {
      return value;
    }
    value = helpers.schema.$_getFlag('random') ? require('otplib').authenticator.generateSecret() : value;
    if (!value || value.match(/^[A-Z2-7]+=*$/)) {
      return value;
    }
    return helpers.error('base32.Base32')
  },
  rules: {
    base32: {
      method() {
        this.$_setFlag('base32', true);
      }
    },
    random: {
      method(params) {
        this.$_setFlag('random', true)
        this.$_setFlag('base32Length', params.length)
      }
    }
  }
});

const isoCountryCode = (joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'isoCountryCode.UnknownCountry': 'needs to be a valid ISO 3166-1 alpha-2 country code'
  },
  rules: {
    isoCountryCode: {
      validate(value, helpers) {
        if (i18n.countries.getCodes().includes(value.toUpperCase())) { 
          return value 
        }
        return helpers.error('isoCountryCode.UnknownCountry')
      }  
    }
  }
});

const password = (joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'password.WrongPasswordStrength': 'Password must have at least 8 characters without spaces which contains at least one numeric digit, one uppercase, one lowercase letter and one symbol (e.g ?, &, ...)'
  },
  rules: {
    password: {
      validate(value, helpers) {
        let valid = Boolean(value) && value.length >= 8;
        valid     = valid && value.match(/[0-9]/) !== null;
        valid     = valid && value.match(/[a-z]/) !== null;
        valid     = valid && value.match(/[A-Z]/) !== null;
        valid     = valid && value.match(/[^ a-zA-Z0-9]/) !== null;
        return valid ? value : helpers.error('password.WrongPasswordStrength');
      }
    }
  }
});

const postalCode = (joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'postalCode.InvalidPostalCode': 'Needs to be a valid postal code for {{#country}}',
    'postalCode.CountryNotFilled' : 'Requires a valid country to be selected',
    'postalCode.InvalidCountry'   : 'Invalid country: "{{#country}}"'
  },
  rules: {
    postalCode: {
      args: [{
        name: 'country',
        ref: true,
        assert: () => joi.alternatives([joi.string().isoCountryCode(), joi.function().ref()]).required(),
        message: 'must be a country'
      }],
      method(country) {
        return this.$_addRule({ name: 'postalCode', args: { country } });
      },
      validate(value, helpers, args) {
        const country = args.country?.toUpperCase();    // Joi automatically resolves if it's a ref (see args above, ref = true)
        if (!country) { return helpers.error('postalCode.CountryNotFilled') } 

        const spec = require('./postalCodes').default[country];
        if (!spec) { return helpers.error('postalCode.InvalidCountry', { country }) } 

        if (spec.some(sp => sp.Regex.test(value))) { return value }
        return helpers.error('postalCode.InvalidPostalCode', { value, country: i18n.countries.getName(country), regex: spec.map(sp => sp.Regex.toString()).join(" or") })
      }
    }
  }
});

const birthDate = (joi) => ({ // TODO: use @hapi/joi-date extension instead -> requires upgrading from current joi version (without @hapi) to latest (with @hapi)
  type: 'string',
  base: joi.string(),
  messages: {
    'string.InvalidBirthDate': 'Needs to be a valid birth date: DD/MM/YYYY'
  },
  rules: {
    birthDate: {
      validate(value, helpers) {
        if (moment(value, 'DD/MM/YYYY', true).isValid()) { return value }
        return helpers.error('string.InvalidBirthDate');
      }
    }
  }
});

const maxLines = (joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'maxLines.HasTooManyLines': 'Has too many lines, please input less than {{#maxLines}}',
    'maxLines.LineTooLong'    : 'One or more lines are too long, please keep it under {{#lineLength}}'
  },
  rules: {
    maxLines: {
      method(maxL) {
        return this.$_addRule({ name: 'maxLines', args: { maxL } });
      },
      validate(value, helpers, args) {
        const lines = value.split(/\n/);
        if (lines.length > args.maxLines) return helpers.error('string.HasTooManyLines')
        if (lines.length > 1 && lines.some(line => line.length > 2000)) return helpers.error('string.LineTooLong', { lineLength: 2000 })
        return value;
      }
    }
  }
});

const mailRule = _Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } });
const namedMail = (joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'namedMail.InvalidNamedEmail': 'Needs to be a valid RFC 2822 name-addr email address'
  },
  rules: {
    namedMail: {
      validate(value, helpers) {
        if (typeof value === 'string') {
          let [name, addr, ...others] = value.split(" <");
          
          if (others.length === 0 && addr !== undefined && name) {
            addr = addr.replace('>', '');
            const result = joi.validate(addr, mailRule);
            if (!result.error) return value;
          }
        }
        return helpers.error('string.InvalidNamedEmail');
      }
    }
  }
});

const referenceOf = (joi) => ({
  type: 'referenceOf',
  base: joi.string().uri(),
  messages: {
    'string.uri.referenceOf.InvalidURIType': 'Needs to be a URI of a specific type'
  },
  rules: {
    referenceOf: {
      args: [{
        name: 'referenceOf',
        assert: joi.function().arity(1).required(),
        message: 'Must be a function'
      }],
      method(model) {
        return this.$_addRule({ name: 'referenceOf', args: { referenceOf: model.isReference } });
      },
      validate(value, helpers, args) {
        try {
          const isInstance = args.referenceOf(value);
          if (isInstance) {
            return value;
          }
        } catch(error) {
          console.error(error);
        }
        return helpers.error('string.uri.referenceOf.InvalidURIType');
      }
    }
  }
});

const objectTransform = (joi) => ({ 
  type: 'object',
  base: joi.object(),
  messages: { 
    'transform.schema': 'Request does not conform to schema. {{#message}}',
    'transform.exception': 'Request transform failed. {{#message}}'  
  },
  rules: {
    transform: {
      args: [{
        name: 'transform',
        assert: joi.function().minArity(1).maxArity(2).required(),
        message: 'Must be a function'
      }],
      method(transform) {
        return this.$_addRule({ name: 'transform', args:  { transform } });
      },
      validate(value, helpers, args) {
        try {
          return args.transform(value, { state: helpers.state, options: helpers.prefs, error: helpers.error });
        } catch (err) {
          return helpers.error('transform.exception', { message: err });
        }
      }
    }
  }
});

const request = (joi) => ({ 
  type: 'request',
  base: joi.object(),
  messages: { 
    'request.schema': 'Request does not conform to schema. {{#message}}',
    'request.transform': 'Request transform failed. {{#message}}'  
  },
  rules: {
    transform: {
      args: [{
        name: 'transform',
        assert: joi.function().minArity(1).maxArity(2).required(),
        message: 'Must be a function'
      }],
      method(transform) {
        return this.$_addRule({ name: 'transform', args: { transform } });
      },
      validate: objectTransform(joi).rules.transform.validate
    }
  }
});

const alternativeVersions = (joi) => ({
  type: /.*/,
  messages: {
    'alternativeVersions': 'Alternative version validation failed: {{#error}}'
  },
  rules: {
    alternativeVersions: {
      args: [{
        name: 'versions',
        assert: joi.object().pattern(joi.string().pattern(/\d+\.\d+\.\d+/), joi.object().schema().required()).min(1).required()
      }],
      method(versions) {
        return this.$_addRule({ name: 'alternativeVersions', args: { versions } });
      },
      validate(value, helpers, args) {
        const valueVersion = helpers.prefs.context?.schemaVersion;

        const versions   = Object.keys(args.versions).sort(require('../executor/version').compare);
        const versionIdx = versions.findIndex((v, idx) => valueVersion && require('../executor/version').compare(valueVersion, v) < 0 && require('../executor/version').compare(valueVersion, (idx === 0) ? '0.0.0' : versions[idx-1]) >= 0);
        
        if (versionIdx < 0) return value; // Using current version (i.e. current schema)
        try {
          return versions.filter((_, idx) => idx >= versionIdx).reduce((newVal, v) => require('../model').validate(newVal, args.versions[v], helpers.prefs.context), helpers.original);
        } catch (e) {
          console.error("Alternative version", valueVersion, "validation error", e)
          const error = (e?.details && JSON.parse(e.details)?.error) || e;
          return helpers.error('alternativeVersions', {error});
        }
      }
    }
  }
});

const currentUserId = (joi) => ({
  type  : 'currentUserId',
  base  : joi.string(),
  coerce: (_value, helpers) => helpers.prefs.user?.id
})

// TODO: add "generic" model validation
// Joi.model().ref(Study) <-- would check the field is a Study urn

// const model = (joi) => {
//   return {
//     base    : joi.string().uri(),
//     name    : 'model',
//     ...
//   }
// }

export const Joi            = _Joi.extend(require('joi-phone-number')).extend(base32).extend(isoCountryCode).extend(postalCode).extend(birthDate).extend(password).extend(namedMail).extend(isoDateWithOffset).extend(maxLines).extend(currentUserId).extend(request).extend(objectTransform).extend(alternativeVersions).extend(referenceOf);
export const mail           = withCustomError(mailRule, 'email');
export const phone          = withCustomError(Joi.string().phoneNumber({ defaultCountry: 'GB' }), 'phonenumber');
export const actCode        = withCustomError(Joi.string().replace(/[^\d]/g, '').regex(/^\d{6}(?:\d{2})?$/), 'regex', 'actCode'); //FIXME: duplicated code. Same as Study.activationCode schema

export const list           = (innerSchema=Joi.object()) => Joi.array().items(innerSchema);
export const min            = (schema, quantity) => withCustomError(schema.min(quantity), 'min');
export const unique         = (schema) => schema.unique();
export const required       = (schema) => withCustomError(schema.required().empty(''), 'required');
export const equalsTo       = (value, insensitive=true) => { const schema = Joi.string().valid(value); return withCustomError(withCustomError((insensitive ? schema.insensitive() : schema).required(), 'equalsTo', `You need to type the word ‘${value}’, please review the spelling and try again`), 'required'); }