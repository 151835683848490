import { createBrowserHistory } from 'history';

// FIXME: ugly way of refreshing user activity. This update activity is required to be implmented somehow in such a way it is persisted between web app browser refreshs: after a browser refresh we check session last actitivity to decide whether the session has timed out or not
const history       = createBrowserHistory();
history.lengthSinceSignin = 0;
history.resetLength = _ => {history.lengthSinceSignin = 0};
const push          = history.push;
history.push        = (...args) => {
  history.lengthSinceSignin++;
  return push(...args); 
}
const goBack        = history.goBack;
history.goBack      = (...args) => { 
  history.lengthSinceSignin--;   
  return goBack(...args); 
}

export default history;