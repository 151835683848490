import { useMemo } from 'react';
import { Patient } from 'services/server/functions/model/diagnosis/model/Patient';
import useProjection from 'ui/hooks/useProjection';
import Table from 'ui/components/Table';
import { Text } from 'ui/components/Text';
import i18n from 'services/server/functions/i18n';

const PATIENT_PROFILE_TYPES = {
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    PARAGRAPH_TEXT: 'PARAGRAPH_TEXT',
    NUMBER: 'NUMBER',
    TEXT: 'TEXT',
};

class PatientProfileField {
    #field = null;
    #locale = null;

    constructor(field, locale) {
        this.#field = field;
        this.#locale = locale;
    };

    get id() {
        return this.#field.id;
    }

    get category() {
        if (this.#field.computed) return 'view-study';
        return 'create-study';
    }

    get type() {
        if (this.#field.computed) {
            return 'auto-populated';
        }

        switch (this.#field.type) {
            case PATIENT_PROFILE_TYPES.MULTIPLE_CHOICE:
                return 'multiple-choice';
            default:
                return 'manual-input';
        }
    }

    get name() {
        return this.getName(this.#locale);
    }

    get values() {
        return this.getValues(this.#locale);
    }

    getName(locale) {
        const lang = locale.split('-')[0];
        if (typeof this.#field.name === 'string') return this.#field.name;
        return this.#field.name[lang] || this.#field.name[Object.keys(this.#field.name)[0]];
    }

    getValues(locale) {
        const lang = locale.split('-')[0];
        return (this.#field.options || []).map(op => {
            if (typeof op.label === 'string') return op.label;
            return op.label[lang] || op.label[Object.keys(op.label)[0]];
        });
    }
}

export const ViewPatientProfile = ({ ownerId }) => {
    const [patientProfileForm, loadingProfilePrefs] = useProjection(Patient.queries.GET_PATIENT_PROFILE_FORM.newRequest({ site: ownerId }), { update: ownerId });

    const fields = useMemo(() => {
        if (loadingProfilePrefs || !patientProfileForm?.length) return [];

        return patientProfileForm.map((row) => {
            const field = new PatientProfileField(row.field, i18n.currentLocale);
            return {
                id: field.id,
                name: field.name,
                category: Text.resolve('module.administration.fe.view.patientProfile.category.' + field.category),
                type: Text.resolve('module.administration.fe.view.patientProfile.type.' + field.type),
                values: field.values.join(', '),
            };
        });
    }, [loadingProfilePrefs, patientProfileForm, i18n.currentLocale]);

    if (!fields.length) {
        return <></>;
    }

    return (
        <div className="pageSection" id="view-patient-profile">
            <div className="details">
                <div className="detailsHeader name">
                    <Text>module.administration.fe.view.patientProfile.customFields</Text>
                </div>
            </div>
            <div className='detailsBlock'>
                <Table
                    keyField='id'
                    columns={{
                        name: { content: "field" },
                        category: { content: "category" },
                        type: { content: "type" },
                        values: { content: "values" },
                    }}
                    data={fields}
                    defaultSortCol={'field'}
                    defaultSortDir={'asc'}
                    withViewAction={false}
                />
            </div>
        </div>)
        ;
};

ViewPatientProfile.hasAccess = (currentUser) => {
    return currentUser.hasAccess(Patient.queries.GET_PATIENT_PROFILE_FORM);
};