import * as Pages from 'modules/devices';
import * as Administration from 'modules/administration';
import * as Studies from 'modules/diagnosis';

import { Batch, Device } from 'services/server/functions/model/devices/model';
import { IconWrapper, DeviceIcon  } from 'ui/acukit/Icon';
import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import {StatusLegendTooltip} from '../components/DeviceStatusLegend';
import Activity from 'ui/components/ActivityLog';
import Counter from 'ui/components/Counter';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Issues from '../components/Issues';
import Back from 'ui/components/Back';
import { Redirect } from 'react-router-dom';
import {Text} from 'ui/components/Text';
import WithSnapshot from 'ui/components/WithSnapshot';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';
import moment from 'moment';

const ViewDeviceUI = ({ id, serialNumber='N/A', batchId='N/A', issues=[], status, organisationId, healthcaresiteId, studiesCount=0, lastStudy, activity=[], hasAccess, isManager, I18n }) => {
  return <div id='ViewDevice' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1> 
      <div className='actions'>
        { hasAccess(Pages.ChangeStatus) && <Pages.ChangeStatus.AsLinkButton text={I18n("change-status")} className="primary" device={id} /> }
        <Pages.LogIssue.AsLinkButton text={I18n("report-issue")} device={id} />
      </div>
    </div>
    <div className='pageContent'>
      <div className='pageSection'>
        <div className='title'>{I18n('details')}</div>
        <DetailsBlock inline={!isManager}>
          <div className="grid-col">
            <Details label='ActivityLog.device-id' value={Device.id(id) || 'N/A'} />
            {isManager && <Details label={I18n('serial-no')} value={serialNumber} />}
            {isManager && <Details label={I18n('batch-id')} value={Batch.id(batchId)} />}
          </div>
          <div className="grid-col">
            <Details label={I18n('status')} help={<StatusLegendTooltip/>}>
              <div className={status ? `status ${status.toLowerCase()}` : ''}>{Text.resolve(Device.STATUS_LABELS[status], {}, { schema: "devices.Device" }) || 'N/A'}</div>
            </Details>
          </div>
        </DetailsBlock>
      </div>
      <div className='pageSection'>
        <div className='title'>{I18n('ownership')}</div>
        <DetailsBlock inline>
          <Details label={I18n('global.organisation.label')}>
            {organisationId ? <Administration.ViewOrganisation.AsLinkIcon organisation={organisationId} className="button" /> : I18n('not-available')}
          </Details>
          <Details label={I18n('hcs')}>
            {healthcaresiteId ? <Administration.ViewHCS.AsLinkIcon healthcaresite={healthcaresiteId} className="button" /> : I18n('not-available')}
          </Details>
        </DetailsBlock>
      </div>
      <div className='pageSection'>
        <div className='title'>{I18n('usage')}</div>
        <DetailsBlock inline>
          <Details label={I18n('studies-recorded')}>
            <Counter count={studiesCount} findPage={Studies.FindStudy} id='Study' params={{ device: id }} />
          </Details>
          <Details label={I18n('last-study')}>
            {lastStudy.id ? <Studies.ViewTest.AsLinkButton study={lastStudy.id} test={lastStudy.test} text="view" /> : "N/A"}
          </Details>
        </DetailsBlock>
      </div>
      
      <Issues issues={issues} I18n={(text, variables) => Text.resolve(text, variables, {page: 'ReportDeviceIssue'})}/>
      <Activity activity={activity} hideAggregate />
    </div>
  </div>;
};

const ViewDevicePresenter = (props) => {
  const [currentUser] = useCurrentUser();
  
  const isManager = currentUser.hasPermission(Device.roles.manager.id);
  
  const id = props.device;
  
  const device = props.snapshot?.data || {id: undefined, serialNumber: undefined, batchId: undefined, issues: undefined, status: undefined, recordings: {}, owners: undefined};  //TODO: model events similar to other Models

  const healthcaresiteId = HealthcareSite.ownersFrom(device)[0];
  const [hcsSnap]        = useSnapshot(HealthcareSite, healthcaresiteId, {autoStart: healthcaresiteId !== undefined});
  const organisationId   = healthcaresiteId ? (hcsSnap?.data || {owners: []}).owners[0] : Organisation.ownersFrom(device)[0];
  
  const time            = (rec) => rec.endTime || rec.startTime;
  const recordings      = Object.values(device.recordings || {}).sort((r1, r2) => time(r1) === time(r2) ? 0 : (time(r1) === undefined && time(r2) !== undefined ? -1 : time(r1) !== undefined && time(r2) === undefined ? 1 : moment(time(r1)).diff(moment(time(r2)))));
  const studiesCount    = recordings.filter((rec, idx) => idx === recordings.findIndex(r => r.study === rec.study)).length;
  const lastRecording   = recordings.pop() || {};
  const lastStudy       = {id: lastRecording.study || undefined, test: lastRecording.test?.split('Test/').pop()};

  return ViewDeviceUI({ 
    isManager,
    activity: props.snapshot?.getEvents(Device, id) || [],//TODO add events of studies recorded with this sensor
    studiesCount,
    lastStudy,
    organisationId,
    healthcaresiteId,
    ...device, hasAccess: currentUser.hasAccess, I18n: props.I18n });
}

const ViewDevice = (props) => {
  const [{device: devId, id: oldId}] = useLocationParams();
  const id = Device.newURN(devId || oldId);

  return WithSnapshot(Device, id, {fallbackComponent: _ => <Redirect to={Pages.Find.PATH} />})(ViewDevicePresenter)({...props, device: id});
}

ViewDevice.AsText = ({text, id, device=id}) => text || Device.id(device);
const AsIcon = ({ className, text, id, device=id }) => {
  const [currentUser] = useCurrentUser();
  const hasAccess = currentUser.hasAccess(require('../../config').default.routes.ViewDevice);
  return <div title={!hasAccess ? Text.resolve('no-access-tooltip') : undefined} className={`${className} withIcon ${hasAccess ? '' : 'noselectable'}`}>
    <IconWrapper padding={'0 7px 0 0'} text={text ? text : Device.id(device)} icon={<DeviceIcon />}/>
  </div>;
}
ViewDevice.AsIcon = AsIcon;

export default ViewDevice;
