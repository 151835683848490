import { isBrowser } from '../env';
import * as R from 'ramda';
import { Roles } from './roles';
import { getModelName, isURN } from '../executor/urn';

const exec = (request) => { // TODO: find a better place for this method which might be useful in other non-iam modules
//////////////////
//////////////////////////////////////////////////////////////////////////////////////////
////////////
  if (isBrowser()) return require('../../../../modules/executor').execute(request, {notifications: {disabled: true}, execute: {background: true}});
  return () => {}; // Should never reach this point
}

export const anonymousUser = () => wrapUser(require('../model/authentication/model').User.defaultFrom({uid: 'anonymous', email: 'anonymous@curable.com'}));

let iam = {};
if (isBrowser()) iam = require('../../../iam');

////////////////
//////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
//////////

// FIXME: options.isSuperAdmin is an ugly way of applying user context in UI but at the same time being able to give access to commands/queries that are exclusive to super admins
export const allOwners       = (urn)   => exec(require('../model').resolve(urn)?.queries.GET.newRequest({id: urn})).then(snap => (snap?.metadata?.allOwners || []));
export const hasAnyRole      = (roles) => (userRoles, userOptions={}) => isAdmin({data: {roles: userRoles}}) || (userOptions.isSuperAdmin && roles.includes(Roles.superAdmin.id)) || R.intersection(Roles.expand(roles), Roles.expand(userRoles)).length !== 0;
export const hasRole         = (roles) => hasAllRoles(Array.isArray(roles) ? roles : [roles]);
export const hasAllRoles     = (roles) => (userRoles, userOptions={}) => isAdmin({data: {roles: userRoles}}) || (userOptions.isSuperAdmin && roles.includes(Roles.superAdmin.id)) || R.difference(Roles.expand(roles), Roles.expand(userRoles)).length === 0;
export const isSuperAdmin    = (user)  => user?.metadata?.isSuperAdmin || (user?.data?.roles || []).includes(Roles.superAdmin.id);
export const isFromAcurable  = (user)  => require('../model/administration/model').isFromAcurable(user);
export const isAdmin         = (user)  => isSuperAdmin(user) || (user?.data?.roles || []).includes(Roles.admin.id);
export const isAuthorised    = (user, action) => {
  const actionModel = require('../model').resolve(action).actions[action.type];
  return actionModel.roles.includes(Roles.anonymous.id) || hasAllRoles(actionModel.roles)(user.data.roles, {isSuperAdmin: user?.metadata?.isSuperAdmin});
}

export const hasAccess = (roles) => (userRoles, userOptions) => {
  const ORRoles = roles ? roles.some(Array.isArray) ? roles : [roles] : [];
  return ORRoles.length === 0 || ORRoles.some(rs => hasRole(rs)(userRoles, userOptions));
}

const PUBLIC_COLLECTIONS = ['Role', 'Batch'];

const hasOwnership = (user, snapOrId, productUnits, newOwners, debug) => {
  const isId = isURN(snapOrId);
  const snap = isId ? undefined : snapOrId;
  const id = isId ? snapOrId : snapOrId?.data?.id;

  const isFromRoot = require('../model/administration/model').isFromRoot(user);
  const snapAggregate  = getModelName(id);
  if ((!isId && !snap?.data) || PUBLIC_COLLECTIONS.some(c => c === snapAggregate.split('/').pop())) return true;
  if (isId) return productUnits?.length ? productUnits.includes(id) : (id.includes('/Organisation/') || id.includes('/HealthcareSite/')) && (isFromRoot || user?.metadata?.allOwners.includes(id));

  const snapParentPath = snapAggregate.split('/').slice(0, -1);
  
  const isOwnerCollection = oid => oid.includes('/Organisation/') || oid.includes('/HealthcareSite/') || oid.includes('/User/') || oid.includes(`/${snapAggregate}/`) || snapParentPath.some(parent => oid.includes(`/${parent}/`));
  const directOwners = user.data.owners.filter(isOwnerCollection);
  const parents      = user.metadata.allOwners.filter(o => !directOwners.includes(o) && isOwnerCollection(o))
  
  const rootOrg = require('../model/administration/config').default.organisation.root.id;
  // If it doesn't have metadata.allOwners it's because its a new snapshot
  let isChildSnapshot = isFromRoot && productUnits?.length ? id === rootOrg || (snap.metadata.allOwners ? snap.metadata.allOwners.filter(own => own !== rootOrg && isOwnerCollection(own)).some(o => productUnits.includes(o)) : true)
                        : directOwners.length > 0 && (snap.metadata.allOwners ? directOwners.some(o => snap.metadata.allOwners.includes(o)) : true);

  const snapProductIds = snap.data.owners.filter(o => o.includes('/Product/')); // <Aggregate>.ownersFrom performs badly .. needs research and refactoring likely
  const userSelectedProducts = user.metadata.allOwners.filter(o => o.includes('/Product/'));
  isChildSnapshot = newOwners.includes(id) || (isChildSnapshot && (!snapProductIds.length || !userSelectedProducts.length || userSelectedProducts.find(o => snapProductIds.includes(o)) !== undefined)); // user allOwners includes the selected product because it is introduced by front-end in context provider (i.e. it is not part of the DB user snapshot)

  debug && console.debug("hasOwnership", {snap, directOwners, productUnits, isChildSnapshot, newOwners})
  return isChildSnapshot || (snapAggregate === 'Preferences' && parents.some(o => snap.data.owners.includes(o)));
};

// TODO: add similar helpers for each aggregate type to any instance returned from that aggregate??
export const wrapUser = (user, productUnits, newOwners = []) => ({
  ...user,
  isAuthenticated  : ()              => iam.sessionActive() && user?.data?.id && user.data.id !== 'anonymous',
  hasAccess        : (resource)      => resource && user?.data && (resource.onlySuperAdmin ? isSuperAdmin(user) : resource.onlyAcurableUsers ? (isFromAcurable(user) || isSuperAdmin(user)) : hasAccess(resource.roles)(user.data.roles, {isSuperAdmin: user?.metadata?.isSuperAdmin})),
  hasOwnership     : (snap, debug)   => user?.data && hasOwnership(user, snap, productUnits, newOwners, debug),
  hasPermission    : (permissionURN) => user?.data && hasRole(permissionURN)(user.data.roles, {isSuperAdmin: user?.metadata?.isSuperAdmin}),
  hasAllPermissions: (permissions)   => user?.data && hasAllRoles(permissions)(user.data.roles, {isSuperAdmin: user?.metadata?.isSuperAdmin}),
  isSuperAdmin     : _               => isSuperAdmin(user),
  isAdmin          : _               => isAdmin(user),
  isFromAcurable   : _               => isFromAcurable(user),
  isFromRoot       : _               => require('../model/administration/model').isFromRoot(user),
});

export default iam;
