import { getUISchemaFormLabel, prepareSchemaFormLocalization } from "./localizationUtils";
import { getSchemaFormOrder } from "./uiUtils";

const extractSchemaProps = (schema, result = []) => {
    if (schema && typeof schema === 'object') {
        if (schema?.properties) {
            for (const [prop, value] of Object.entries(schema.properties)) {
                if (value.type) {
                    result.push({ ...value, prop });
                }
            }
        }
        Object.values(schema).forEach(val => extractSchemaProps(val, result))
    }
    return result;
};

export const getUISchemaFormId = (property) => property?.content?.props?.idSchema?.$id || '';
export const getUISchemaFormSchema = (property) => property?.content?.props?.schema || {};
export const getUISchemaFormContentProps = (root) => root?.content?.props || {};
export const getUISchemaFormContentLabels = (root) => {
    const contentProps = getUISchemaFormContentProps(root);
    const formContext = contentProps?.registry?.formContext;
    const uiSchema = contentProps?.uiSchema;
    return getUISchemaFormLabel({ uiSchema, formContext, label: root?.name });
};

export const getSchemaFormConfig = ({ schema, id, overrides = {} }) => {
    try {
        const properties = extractSchemaProps(schema);
        return {
            uiSchema: {
                "ui:order": getSchemaFormOrder({ properties }),
                ...overrides
            },
            formContext: {
                id,
                localization: prepareSchemaFormLocalization({ id, properties }),
            },
            properties
        };
    } catch {
        return {};
    }
};

export const generateUISchemaFormGroup = ({
    properties = []
}) => properties?.reduce((acc, property) => {
    const item = getUISchemaFormSchema(property);
    const hasGroup = item && acc.includes(item?.group);

    if (!hasGroup) {
        acc.push(item?.group);
    }
    return acc;
}, []);

export const getSchemaFormProperty = ({ properties, group }) =>
    properties?.find(p => getUISchemaFormSchema(p)?.group === group);
    
export const getSchemaFormId =
    (idSchema = {}) => idSchema?.$id?.replace('root_', '');

export const getFormOpts = (registry) => registry?.formContext?.formOpts || {};

export const DoesSchemaFormAcceptsUnndefined = ({ required, readonly }) => !required && !readonly;

export const combineSchemaFormDefaultsAndData = (properties, data = {}) => {
    const defaultValues = properties?.reduce((acc, property) => {
        const isDefault = property?.readOnly;
        if (isDefault) {
            acc[property?.prop] = property?.default || property?.value;
        }
        return acc;
    }, {}) || {};
    return {
        ...Object.keys(data).reduce((acc, prop) => {
            if (data[prop] !== undefined && data[prop] !== null) {
                acc[prop] = data[prop];
            }
            return acc;
        }, {}),
        ...defaultValues
    };
};