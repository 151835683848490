import UploadStudyBatch from './UploadStudyBatch';
import Back from 'ui/components/Back';

//TODO: refactor 
export default Object.assign((props) => {
 
  return (
    <div id='CreateStudyBatch' className='page'>
      <Back />
      <div className='pageHeader'>
        <h1 className='title'>{props.I18n('title')}</h1>
      </div>
      <div className='pageContent'>
        <UploadStudyBatch createStudyBatch={props.createStudyBatch}  I18n={props.I18n} />
      </div>
    </div>
  )
}, { displayName: 'CreateStudyBatch' });
