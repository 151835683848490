import { Text } from "../../../ui/components/Text";
import { BlockCommentModal } from "./BlockCommentModal";

export const BlockTextAreaModal = ({
    title,
    titleActions=[],
    id,
    defaultValue,
    hasAcces,
    emptyLabel = Text.resolve('ViewTest.clinician-comments.empty'),
    editLabel = 'edit',
    addLabel = 'add',
    onSave = () => { },
    customActions,
    onToggle,
    ...props
}) => {
    const editsCommentTemplate = hasAcces &&
        <>
            <BlockCommentModal
                id={id}
                defaultValue={defaultValue}
                appearance={'primary'}
                hasAcces={hasAcces}
                onSave={onSave}
                customActions={customActions}
                onToggle={onToggle}
                {...props}
                label={Text.resolve(defaultValue ? editLabel : addLabel)}
            />
        </>;

    return (
        <div id='comment-block'>
            <div className='header'>
                <div className="title">
                    <Text>{title}</Text>
                </div>
                {titleActions
                    .concat(defaultValue ? editsCommentTemplate : [])
                    .map((action, idx) => <div key={`titleAction${idx}`} className="ml">{action}</div>)}

            </div>
            <div className='box'>
                {defaultValue ? (
                    <textarea disabled
                        className={`viewer textarea`} name="viewer-value" value={defaultValue} />
                ) : editsCommentTemplate || (
                    <div className="grid-col commentsText preserve-line-breaks">
                        {emptyLabel}
                    </div>
                )
                }
               {props.children}
            </div>
        </div>
    );
};