import { useState, useEffect, useContext } from "react";

import { useProducts } from "features/hooks/useProducts";
import Details from "ui/components/Details";
import DetailsBlock from "ui/components/DetailsBlock";
import { Select } from "ui/components/Select";
import { Text } from "ui/components/Text";
import { WithValidation } from "ui/components/WithValidation";
import { Organisation } from 'services/server/functions/model/administration/model';
import useForm from "ui/hooks/useForm";

import './style.css';
import useCurrentUser from "ui/hooks/useCurrentUser";
import Action from "ui/components/Action";
import { UserContext } from "features/providers/userContextProvider";

const ValidatedSelect = (props) => <WithValidation field={props.field || props.name} {...props}><Select {...props} /></WithValidation>; 

export const EditOrganisationProducts = ({
    organisationData,
    onChange = ()=> {},
    onSubmit = () => {},
    isUpdate,
    showError,
 }) => {
    const { selectedProduct } = useContext(UserContext);
    const [currentUser] = useCurrentUser();
    const {
        products,
        assignProductsToOrg
    } = useProducts();
    const prepareOptions = () => products?.map(({ key, id }) => ({
        label: key,
        value: id
    }));
    const [options, setOptions] = useState(prepareOptions);
    const {
        form,
        onOptionsChange,
        onError,
        setForm,
        onSubmit: onSubmitForm,
        dirty
    } = useForm({ products: isUpdate ? organisationData?.products : [] });
    const validationSchema = Organisation.commands.SET_PRODUCTS.schema.fork('products', s => s.min(1));

    const submitHandler = () => onSubmitForm(async (submiteableForm) => {
        await assignProductsToOrg({
            ...submiteableForm,
            products: submiteableForm?.products || [],
            id: organisationData?.id
        });
        onSubmit();
    });
    useEffect(() => setOptions(prepareOptions), [products]);
    useEffect(() => isUpdate && setForm({
        ...form,
        products: organisationData?.products,
    }), [organisationData]);
    const changeHandler = (event) => { 
        onOptionsChange(event);
        onChange(event);
    };
    
    // Triggers changeHandler for default products
    useEffect(() => {
        if (isUpdate || !options.length) return;
        const defaultProducts = [selectedProduct.id];
        changeHandler({ target: { name: "products", selectedOptions: defaultProducts.map(p => options.find(op => op.value === p)), type: 'select-multiple' } });
        setForm({ ...form, products: defaultProducts });
    }, [isUpdate, options]); // on refresh options takes an extra re-render to have values

    const formLayout = (
        <>
            <Details
                id="productList"
                mandatory={true}
                label="module.administration.fe.manageProducts.multiselect_label">
                <ValidatedSelect
                    isMulti
                    name="products"
                    schema={validationSchema}
                    showError={showError}
                    onValidate={onError}
                    value={form?.products}
                    options={options}
                    placeholder={Text.resolve("module.administration.fe.manageProducts.choose")}
                    onChange={changeHandler} />
            </Details>
            {
                isUpdate && <div className="footer">
                    <div className="actions">
                        <Action
                            disabled={!dirty}
                            handleAction={submitHandler}
                            label={Text.resolve('module.administration.fe.manageProducts.assign')}
                            className={`button primary`} />
                    </div>
                </div>
            }
        </>
    );

    return currentUser?.isSuperAdmin() ? (
        <div className="products">
            {isUpdate ? (
                <DetailsBlock>
                    {formLayout}
                </DetailsBlock>
            ) : formLayout}
        </div>
    ) : <></>;
};
