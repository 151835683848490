import '../CreateStudy/style.css';

import * as Pages from 'modules/diagnosis';

import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import PatientDetails from '../CreateStudy/PatientDetails';
import { Redirect } from 'react-router-dom';
import {Text} from 'ui/components/Text';
import { WithStudy } from '../components';
import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import { useState } from 'react';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { Study } from 'services/server/functions/model/diagnosis/model';

const EditStudyUI = (props) => {
  const [currentUser] = useCurrentUser();
  const {step='form'} = props;

  let onNext = undefined;
  let onBack = undefined;
  const setOnNext = (callback) => onNext = callback;
  const setOnBack = (callback) => onBack = callback;

  const isConfirm = step !== 'form';
  const goNext = _ => Promise.resolve(onNext ? onNext() : true).then(validated => { validated && setOnNext(undefined); return validated && (isConfirm ? history.replace(Pages.ViewStudy.PATH, {study: history.location.state.id}) : props.setStep('validate')); })
  const goBack = _ => Promise.resolve(onBack ? onBack() : true).then(validated => { validated && setOnBack(undefined); return validated && (isConfirm ? props.setStep('form') : history.goBack()); })

  return <div className='page'>
    {step === 'form' && <Back handleHistory={false} onClick={goBack}/>}
    <div className='pageHeader'>
      <div className='title wraptext'><Text>EditStudy.title</Text></div>
    </div>
    <div id='EditStudy' className="pageContent grid-row">
      <PatientDetails goBack={goBack} goNext={goNext} setOnBack={setOnBack} setOnNext={setOnNext} study={props.study} loading={props.loading} onSubmit={currentUser.hasAccess(Study.commands.UPDATE_STUDY) ? props.updateStudy : props.updatePatientDetails} notify={props.notify}/>
    </div>
    <div className='pageFooter'>
      <div className='actions'>
        <Action className='button' handleAction={goBack} label={isConfirm ? 'back': 'cancel'} />
        <Action className='button primary' handleAction={goNext} label={isConfirm ? 'submit' : 'next'} /> 
      </div>
    </div>
  </div>;
};

const EditStudy = (props) => {
  const [locState, setLocationState] = useLocationParams({ step: undefined, study: undefined }), setStep  = step => setLocationState({ step }), pushStep = step => setLocationState({ step }, {type: 'push'});
  const {study: studyId, ...stateProps} = locState;
  const additionalProps = {setStep, pushStep, step: locState.step};
  const [notfound, setNotFound] = useState(undefined);

  return notfound ? <Redirect to={Pages.FindStudy.PATH}/>
  : <WithStudy id={studyId} onNotFound={() => setNotFound(true)}>
    <EditStudyUI {...{...additionalProps, ...props, ...stateProps}} />
  </WithStudy>;
};

export default EditStudy;