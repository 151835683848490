import { browserStorageTypes } from 'features/constants/browserTypes'; // eslint-disable-line import/no-unresolved
import { getBrowserStorageItem, setBrowserStorageItem } from 'features/helpers/browserStorage'; // eslint-disable-line import/no-unresolved
import { fetchFirebaseConfigurations, fetchLocalisations } from './configRepository';
import { cacheConfiguration, retrieveCachedConfiguration } from './configStorage';

const {
    appVersion,
    region: projectRegion,
} = require('./config.default').default; // import from form does not work well with macros, don't know why ??

export { projectRegion }

const configListeners = [
    region      => setBrowserStorageItem(browserStorageTypes.Region, region),
    (_, config) => window.dispatchEvent(new CustomEvent('onConfigurationChanged', { detail: config }))
];

export let currentRegion;
export let configurations = {};

export const isHostingChannel = window.location.hostname.includes("--");

export const getHostingChannelRegion = (hostingChannelOrigin = window.location.origin) => {
    const region = Object.keys(configurations).find(key => {
        const regionConfig = configurations[key];
        return hostingChannelOrigin.includes(regionConfig.projectId + "--");
    });

    return region;
}


const prepareConfig = (configs, region, product) => {
    const regionConfig = configs[region];
    const localisation = configs.localisation[region];
    localisation.product = product;

    configurations[region].localisation = localisation;

    return {
        ...regionConfig,
        localisation,
    };
};

export const setCurrentRegion = (region, product) => {
    if (region === currentRegion) return;

    console.debug('Using region', region);
    currentRegion = region;
    
    const config = prepareConfig(configurations, currentRegion, product);

    notifyConfig(region, config);
};

export const onRegionChanged = ( onRegionChange, idx = configListeners.length ) => {
    if (!onRegionChange) return () => {};
    configListeners.push(onRegionChange);
    if (currentRegion && configurations[currentRegion]) onRegionChange(currentRegion, configurations[currentRegion]);
    return () => configListeners.splice(idx, 1)[0];
};
const notifyConfig = (region, config) => configListeners.forEach((cb) => cb(region, config));
const getLocationRegion = () => new URLSearchParams(window.location.search).get("region");
const getHostnameRegion = () => {
    if (!isHostingChannel) return Object.keys(configurations).find(key => configurations[key].authDomain === window.location.hostname);
    return getHostingChannelRegion();
};

/**
 * Maps the API localisations into the form expected by the web app
 * @param {*} localisations 
 */
const mapLocalisations = (localisations) => {
    const gtins = localisations.attributes.gtins;
    const mappedLocalisations = gtins.reduce((acc, obj) => {
        const region = obj.region;
        acc[region] = {
            supportedLangs: obj.supportedLangs
        }
        return acc;
    }, {})
    return mappedLocalisations;
}

/**
 * Converts the API response into a form used by the webapp
 * @param {*} regionConfigs 
 * @param {*} localisations 
 */
const assembleApplicationConfig = (regionConfigs, localisations) => {

    // Transform configuration response into that expected by the webapp
    const applicationConfig = regionConfigs.reduce((acc, obj) => {
        const region = obj.attributes.region;
        acc[region] = {
            ...obj.attributes.firebaseConfiguration,
            functionsRegion : obj.attributes.functionsRegion,
            assetsBucket    : obj.attributes.assetsBucket,
            assetsDomain    : obj.attributes.assetsDomain,
            appVersion,
        }
        return acc;
    }, {})
    applicationConfig.localisation = localisations
    return applicationConfig;
}

const assembleRemoteConfiguration = async() => {
    const firebaseConfigs = await fetchFirebaseConfigurations()
    const localisations = await fetchLocalisations();
    const mappedLocalisations = mapLocalisations(localisations)
    const assembledConfig = assembleApplicationConfig(firebaseConfigs, mappedLocalisations)
    return assembledConfig;
}

export const fetchConfigurations = () => {
    console.debug('Fetching app configurations');
    const cachedConfigs = retrieveCachedConfiguration();

    const remoteConfig = assembleRemoteConfiguration()
        .then((configs) => {
            cacheConfiguration(configs);
            return configs;
        })
        .catch(err => {
            console.error(err)
            return null
        }); // Store fetched configurations for next time

    // TODO: when configs are cached and hence returned, we should detect somehow if the fetched configurations are different from cached ones and suggest user to refresh the app (note: setBrowserStorageItem has already been called and now cached configurations would be updated)
    if (cachedConfigs)
        console.debug('Using cached configurations while fetching server ones. Refresh the app to use server ones, once server ones have been fetched');

    return cachedConfigs
        ? Promise.resolve(cachedConfigs)
        : remoteConfig.catch((response) => console.error('Regions config fetch error: ', response) ); // TODO: maybe at this point we should show some error notification saying we were not able to retrieve the updated list of region configurations (internet connection, or other reason) and default or last cached/used app configuration will be used
};

// TODO: Reduce initial load time (specially for slow connections) by:
//  1: by default use last configurations stored in local storage, if undefined then fetchConfigurations
//  2: create component similar to service worker that fetches configuration in background anc checks if something changed, then notifies users when region configuration has changed to ask them to refresh the webapp and force fetch
export default fetchConfigurations()
    .then((configs) => {
        if (Object.keys(configs || {}).length === 0) {
            console.warn('No configurations found, using local ones');
            configs = {
                [projectRegion]: require('./firebase.default').default,
                ...retrieveCachedConfiguration(),
            };
        }
        return configs || {};
    })
    .then((configs) => {
        configurations = configs;

        const regionsPriority = [
            getLocationRegion(), // When using links (e.g. dynamic links) with a given region, then use that region by default
            getHostnameRegion(),
            getBrowserStorageItem(browserStorageTypes.Region), // Remember last selected region and use it by default
            projectRegion,
        ];
        console.debug('Regions priority queue', regionsPriority, '- Available regions', Object.keys(configurations) );
        // product will be undefined on setCurrentRegion and prepareConfig the first time
        // the default product is being set on the userContextProvider
        setCurrentRegion(
            regionsPriority
                .filter(Boolean)
                .find((region) => region in configurations)
        );
        const config = prepareConfig(configurations, currentRegion);

        return config;
    });
