import { Text } from "ui/components/Text";

export const SwitchFilterModes = {
    Single: 'Single',
    Multiple: 'Multiple'
};

export const SwitchFilter = ({ fieldName, mode = SwitchFilterModes.Single, options , onChangeOptions = () => {}}) => {
    const handleSingleMode = ({ id }) => {
        const updatedOptions = options.map(opt => {
            
            return {
                ...opt,
                selected: id === opt.id
            }
        });
        
        onChangeOptions(fieldName, updatedOptions);

        return updatedOptions;
    };
    
    const handleMultipleMode = ({ id }) => { 
        const updatedOptions = [...options];
        const optToUpdate = updatedOptions.find(opt => opt.id === id);
        optToUpdate.selected = !optToUpdate.selected;

        onChangeOptions(fieldName, updatedOptions);

        return updatedOptions;
    };
    
    const clickHandler = (opt) => { 
        const modes = {
            [SwitchFilterModes.Single]: handleSingleMode,
            [SwitchFilterModes.Multiple]: handleMultipleMode,
        };
        const updatedMode =  modes[mode] || modes[SwitchFilterModes.Single]; 
       
        const updatedOptions = updatedMode(opt);        
        opt?.action && opt?.action(fieldName, updatedOptions.find(({id}) => opt.id === id));
    };


    return (
        <div className="switch-filter">
            {options?.map(({ id, text, selected, action }, index) => {
                const isSmall = text?.length < 4;
                
                return (
                    <div key={`${text}-${index}`}
                        className={`option 
                        ${selected ? 'selected' : ''} ${isSmall ? 'sm' : 'lg'}`}
                        onClick={() => clickHandler({ id, text, selected, action })}>
                        <Text>{text}</Text>
                    </div>
                );
            })}
        </div>
    );
}