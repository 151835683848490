import { ObjectFieldTemplate } from "../templates/ObjectFieldTemplate";

import { BaseInputWidget } from "../widgets/BaseInputWidget";
import { CheckboxWidget } from "../widgets/CheckboxWidget";
import { SelectWidget } from "../widgets/SelectWidget";
import { TextAreaWidget } from "../widgets/TextAreaWidget";

import { capitalize } from "features/helpers/stringHelper";

import { FieldErrorTemplate } from "../templates/FieldErrorTemplate";
import { FieldTemplate } from "../templates/FieldTemplate";
import { SubmitButtonTemplate } from "../templates/ButtonTemplates/SubmitButtonTemplate";
import { StringField } from "../fields/StringField";

import './theme.css';
import { BaseInputTemplate } from "../templates/BaseInputTemplate";

const propertiesClass = Object.freeze({
    Integer: "schema-form-theme integer",
    String: "schema-form-theme string"
});

const generatePropertiesStyles = ({ properties }) =>
    properties?.reduce((acc, property) => {
        acc[property?.prop] = {
            "ui:classNames": propertiesClass[capitalize(property?.type)] || ""
        };
        return acc;
    }, {});

export const baseSchemaFormTheme = ({ properties }) => {
    const templates = {
        ObjectFieldTemplate,
        FieldErrorTemplate,
        FieldTemplate,
        BaseInputTemplate,
        ButtonTemplates: {
            SubmitButton: SubmitButtonTemplate
        },
    };
    const widgets = {
        CheckboxWidget,
        TextWidget: BaseInputWidget,
        SelectWidget,
        TextareaWidget: TextAreaWidget
    };
    const theme = {
        templates,
        widgets,
        fields: {
            StringField: StringField
        },
        showErrorList: false
    };
    const uiSchema = {
        ...generatePropertiesStyles({ properties }),
    };

    return [theme, uiSchema];
};