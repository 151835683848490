import * as Authentication from 'modules/authentication';

import { Role, User } from 'services/server/functions/model/authentication/model';

import Back from 'ui/components/Back';
import Counter from 'ui/components/Counter';
import DeleteEntity from 'ui/components/DeleteEntity';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import HelpTooltip from 'ui/components/HelpTooltip'
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle';
import { Redirect } from 'react-router-dom';
import WithSnapshot from 'ui/components/WithSnapshot';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import useRoles from 'ui/hooks/useRoles';

const DeleteRole = ({role, ...props}) => <DeleteEntity entity={role} linkVariable="role" {...props}/>;
DeleteRole.roles = Role.commands.DELETE_ROLE.roles || [];

const View = ({ role, usersWithRole, handleDelete, isEditable, isRemovable, isPermission, I18n }) => <div className="page" id="ViewRole">
  <Back/>
  <div className="pageHeader">
    <h1 className="title">{I18n('title')}</h1>
    <div className="actions">
      { isEditable && <Authentication.UpdateRole.AsLinkButton className='primary' text="edit" role={role.id} /> }
      { isRemovable && <DeleteRole 
          role={{id: role.id, summary: {users: {count: usersWithRole, page: Authentication.FindUser}}}} 
          onDelete={handleDelete} I18n={I18n}
        /> }
    </div>
  </div>
  <div className="pageContent">
    <div className="pageSection">
      <div className="title">{I18n('details')}</div>
      <DetailsBlock>
        <Details id="label" label="global.name" value={Role.i18n(role.label)} />
        <Details id="description" label="description" value={Role.i18n(role.description)} />
        <Details id="permissions" label="access-rights" 
          help={<div className="maxTextWidth60">{I18n('page.role.assigned_permission_message')}</div>} 
          value={
            <ul>{role.includes.map((r, i) =>
              <li key={i}>
                <div className="inline">
                  <Authentication.ViewRole.AsLink role={r?.id} text={r?.label} disabled={isPermission(r)}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                  <HelpTooltip id={`${r.id}Help`} content={r.description} place="right" type="light" effect="solid" border multiline />
                </div>
              </li>)
            }</ul>
        }/>
      </DetailsBlock>
    </div>
    <div className="pageSection">
      <DetailsBlock>         
        <Details label='users'><Counter count={usersWithRole} findPage={Authentication.FindUser} options={Authentication.FindUser.options} id='Role' params={{ role: role.id }} /></Details>
      </DetailsBlock>
    </div>
  </div>
</div>;

const ViewRole = WithSnapshot(User)((props) => {
  const [Roles] = useRoles();
  
  const [currentUser] = useCurrentUser();
  const [{ id: roleId, role: id = roleId }] = useLocationParams({id: undefined, role: undefined});
  
  const role      = Roles.get(id) ? {...Roles.get(id)} : { id: undefined, label: undefined, description: undefined, includes: [] };
  const ancestors = Roles.ancestorsOf(role).map(r => r.id);
  role.includes = role.includes.map(r => Roles.get(r)).filter(r => r !== undefined);

  const users  = props.snapshots;
  const byRole = ({ roles=[] }) => roles.find(r => r === role.id || ancestors.includes(r));
  
  const unregister       = () => props.deleteRole({ id: role.id });

  return !role.id ? <Redirect to={Authentication.FindRole.PATH} /> :
        <View
          usersWithRole  = {Object.values(users).map(u => u.data).filter(byRole).length}
          handleDelete   = {unregister}
          isEditable     = {currentUser.hasAccess(Authentication.UpdateRole) && Roles.isCustomRole(role.id)}
          isRemovable    = {currentUser.hasAccess(Role.commands.DELETE_ROLE) && Roles.isCustomRole(role.id)}
          isPermission   = {Roles.isPermission}
          role           = {role}
          I18n           = {props.I18n}
        />;
});

const AsText = ({ id, role = id, text }) => {
  const [Roles] = useRoles();

  return <span>{ Role.i18n(text || Roles.get(role)?.label || "Unknown") }</span>;
};
ViewRole.AsText = AsText;

const AsIcon = ({ className, ...props }) => {
  const [currentUser] = useCurrentUser();
  const hasAccess = currentUser.hasAccess(require('../../../config').default.routes.ViewRole);
  return <div title={!hasAccess ? Text.resolve('no-access-tooltip') : undefined} className={`${className} withIcon ${hasAccess ? '' : 'noselectable'}`}>
    <PersonCircleIcon />
    <ViewRole.AsText {...props} />
  </div>;
}
ViewRole.AsIcon = AsIcon;

ViewRole.displayName = "ViewRole";

export default ViewRole ;
