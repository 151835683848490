export const getMaskValue = (options, selectedOptions, optionsMap) => {
    optionsMap ??= getMaskValue.getOptionsMap(options);
    let mask = 0;
    selectedOptions.forEach(option => {
        const powerOfTwo = Math.pow(2, optionsMap[option]);
        mask |= powerOfTwo;
    });
    return mask;
};
getMaskValue.getOptionsMap = (options) => options.reduce((acc, curr, index) => { acc[curr] = index; return acc; }, {});

const combinations = (options) => {
    const result = [];
    const f = function (prefix, array) {
        for (let i = 0; i < array.length; i++) {
            result.push(prefix.concat(array[i]));
            f(prefix.concat(array[i]), array.slice(i + 1));
        }
    }
    f([], options);
    return result;
};

export const getMaskCombinations = (options, selectedOptions) => {
    const optionsMap = getMaskValue.getOptionsMap(options);
    const allCombinations = combinations(options);
    const combinationsWithSelectedOptions = allCombinations.filter(r => selectedOptions.some(o => r.includes(o)));
    return combinationsWithSelectedOptions.map(c => getMaskValue(options, c, optionsMap));
};
