export function makeCancelable(promise/*, id*/) {
  let isCanceled = false;
  let isResolved = false;
  let onCancel   = undefined;

  const wrappedPromise = new Promise((resolve, reject) => {
    //console.debug("Registered cancellable promise", id)
    promise
      .then(val => (isResolved = true) && (isCanceled ? reject({...(new Error("Cancellable promise resolved after being cancelled")), isCanceled}) : resolve(val))) // eslint-disable-line prefer-promise-reject-errors
      .catch(error => reject({...error, isCanceled})); // eslint-disable-line prefer-promise-reject-errors
  });

  wrappedPromise.cancel = () => {
    if (!isResolved) {
      //console.debug("Cancelled promise", id)
      onCancel?.();
      isCanceled = true;
    }
  };

  wrappedPromise.onCancel = (cb) => onCancel = cb;

  wrappedPromise.isCanceled = _ => isCanceled;

  return wrappedPromise;
}

const retry = (promise_fn, until, options) => {
  const doRetry = (error) => {
    options = { interval: 500, retries: 120, verbose: false, ...options };
    if (options.retries === 1) {
      error.message = `${options.label ? options.label + " - " : ""}retry: maximum retries exceeded`; // FIXME: get stack trace of th original call to retry
      throw error;
    }
    if (options.verbose) console.warn(`[retry] ${options.label ? options.label + " - " : ""}Retrying after error ...`, error);
    
    return new Promise((resolve, reject) => setTimeout(async _ => {try {await retry(promise_fn, until, { ...options, retries: options.retries - 1 }); resolve(); } catch (e) {reject(e);}}, options.interval));
  };

  return promise_fn()
    .then(async result => {
      if (!until || (await Promise.resolve(until(result)))) return result;
      const error = new Error(`${options?.label ? options.label + " - " : ""} Promise finished with unexpected result: ${JSON.stringify(result)}`);
      return doRetry(error);
    
    }).catch(doRetry);
};


const read = (readable) => new Promise((resolve, reject) => {
  const chunks = [];
  readable
    .on('error', reject)
    .on('data', chunk => chunks.push(chunk))
    .on('end', () => resolve(Buffer.concat(chunks)));
});

const chain = (...promises) => promises.reduce((result, p) => result.then(p), Promise.resolve());

// Polyfill for Promise.allSettled available from node v12 >=
const allSettled = (promises, onProgress) => Promise.all(promises.map((promise, idx) => promise.then(value => {
                                                                                          const result = { status: "fulfilled", value };
                                                                                          onProgress?.(result, idx);
                                                                                          return result;
                                                                                        })
                                                                                        .catch(reason => {
                                                                                          const result = { status: "rejected", reason, isRejected: true };
                                                                                          onProgress?.(result, idx);
                                                                                          return result;
                                                                                        })))
                                                    .then(results => {results.hasErrors = results.some(r => r.status === "rejected"); return results;});

export { retry, read, chain, allSettled };