import 'react-tabs/style/react-tabs.css';

import { useEffect } from 'react';
import { CustomModal } from 'ui/acukit/ModalDialog';
import { Report } from 'services/server/functions/model/diagnosis/model/Report';
import { Test } from 'services/server/functions/model/diagnosis/model/Test';
import {Text} from 'ui/components/Text';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import HelpTooltip from 'ui/components/HelpTooltip';
import { groupBy } from 'ramda';

const WarningIcon      = (props) => <svg className={`${props.type || 'warning'}Icon`} width={props.width || "80"} height={props.height || "80"} viewBox="0 0 24 24" focusable="false" role="presentation"><g fillRule="evenodd"><path d="M12.938 4.967c-.518-.978-1.36-.974-1.876 0L3.938 18.425c-.518.978-.045 1.771 1.057 1.771h14.01c1.102 0 1.573-.797 1.057-1.771L12.938 4.967z" fill="currentColor"></path><path d="M12 15a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2" fill="inherit"></path></g></svg>;
const MessageIcon      = WarningIcon; // TODO: generalize / standardize generic icons

const ListMessages = ({ messages, id, compact, hideCode, tooltip }) => {
  const getType = m => m.type || m.code.split('/')[1].toLowerCase();
  const byType = groupBy(getType);

  return compact ? Object.entries(byType(messages || [])).map(([type, ms], idx) => {
    const WrapComponent = ({children}) => ms.length > 1 ? <ul>{children}</ul> : <>{children}</>;
    const ItemComponent = ({children}) => ms.length > 1 ? <li>{children}</li> : <>{children}</>;
    return <HelpTooltip key={idx} id={`${id}-${type}`} type={type} content={
      <WrapComponent>{ms.map((m, tidx) => <ItemComponent key={`${type}${tidx}`}><span className='warnText vcentered'>{Text.resolve(m.message || m.text)}</span></ItemComponent>)}</WrapComponent>
    } />;
  })
  : (messages || []).map((m, idx) => tooltip ? 
  <HelpTooltip key={idx} id={`${id}-${m.code}`} type={m.type || m.code.split('/')[1].toLowerCase()} content={<span className='warnText vcentered'>{Text.resolve(m.message || m.text)}</span>} /> :
    <div key={idx} className="studyWarn inline vcentered">
      <MessageIcon type={m.type || m.code.split('/')[1].toLowerCase()} />
      <span className={`warnText vcentered${hideCode ? ' hidden' : ''}`}>{Text.resolve(m.subject || m.code)}</span>
      <span className={`warnText vcentered${tooltip ? ' hidden' : ''}`}>{Text.resolve(m.message || m.text)}</span>
    </div>
  );
}

const ReportMessages = ({ status, report, sequence, retry, notify, dismiss , exclude = undefined }) => {
  const [currentUser] = useCurrentUser();

  const clinicianMessages = ReportMessages.clinicianMessages({report});
  const adminMessages     = currentUser.isFromAcurable() || currentUser.isSuperAdmin() ? ReportMessages.adminMessages({status, report}) : [];

  useEffect(() => {
    clinicianMessages?.forEach(({code, subject, message}) => notify(code.split('/')[1].toLowerCase(), Text.resolve(subject), Text.resolve(message), { id: code, timeout: 0 }));    
    return () => clinicianMessages?.forEach(({code}) => dismiss(code));
  }, [report.messages]);

  const messagesList = ReportMessages.clinicianMessages({report}, exclude);

  return (
    <>
      <ReportMessages.List id={`${sequence}-${retry}`} messages={messagesList} hideCode />
      <CustomModal  id='errors' appearance={adminMessages.length ? '' : 'hidden'}
          btn
          heading={Text.resolve('Additional report message(s):')}
          label={Text.resolve('Additional message(s)')}
          title={Text.resolve('Additional report warnings and errors')}
          cancelLabel={Text.resolve("close")}
      >
        <ReportMessages.List messages={adminMessages.concat()} />
      </CustomModal>
    </>
  )
}

ReportMessages.clinicianMessages = (test, exclude, include) => {
  const messages = (test?.report?.messages || []).concat(include || []);
  const clinicianMessages = messages.filter(({code}) => code !== "AC/INFO/000" && Report.MESSAGES[code]).map(m => ({...m, ...Report.MESSAGES[m.code] }));
  return !exclude ? clinicianMessages : clinicianMessages?.filter(m => !exclude.includes(m?.code));
}
ReportMessages.adminMessages = (test) => {
  const isInvalid         = test?.status === Test.STATUS.invalid;
  return test?.report?.messages?.filter(({code}) => code !== "AC/INFO/000" && (isInvalid || !Report.MESSAGES[code])).map(m => ({...m, ...Report.MESSAGES[m.code] }));
};
ReportMessages.displayName = 'ReportMessages';
ReportMessages.List = ({report, ...props}) => <ListMessages messages={report?.messages} {...props} />
ReportMessages.List.displayName = 'ReportMessages.List';

export default ReportMessages;