import './style.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { Fragment, useEffect, useRef } from 'react';
import useWindowSize, { isSmallScreen } from 'ui/hooks/useWindowSize';

import Action from '../Action';
import BootstrapTable from 'react-bootstrap-table-next';
import HelpTooltip from 'ui/components/HelpTooltip'
import QuickFilters from 'ui/components/QuickFilters';
import Spinner from '@atlaskit/spinner';
import {Text} from 'ui/components/Text';
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import useLocationParams from 'ui/hooks/useLocationParams';

const sortByDate = (d1, d2, order) => {
  const date1 = moment(d1).isValid() ? moment(d1) : 0; //TODO: do not assume a especific date format. standarize all internal dates to moment and assume all dates instances are moment. Define an additional col param to format/displays dates
  const date2 = moment(d2).isValid() ? moment(d2) : 0;
  return order === 'desc' ? date2 - date1 : date1 - date2;
}

const PageButton = ({ page, active, disabled, className, onPageChange }) => {
  const classes = require('classnames')({ active, disabled, 'page-item': true}, className);
  return <li className={ classes }><span onClick={ _ => onPageChange(page) } className="page-link">{ page }</span></li>;
}

const PageList = ({pages, remotePageLoader, currentPage, pageSize, totalSize, onPageChange}) => (
  <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
    <ul className="pagination react-bootstrap-table-page-btns-ul">
      { pages.map((pageProps) => {
        const isLastPage = currentPage * pageSize >= totalSize;
        const isNavigationBtn = ['<<', '<', '>'].some(p => p === pageProps.page) && ((currentPage > 2 && pageProps.page === '<<') || (!isLastPage && pageProps.page === '>') || (currentPage > 1 && pageProps.page === '<'));
        const remoteProps = remotePageLoader ? {active: false, disabled: !isNavigationBtn, className: isNavigationBtn ? undefined : 'hidden'} : {};
        return <PageButton key={ pageProps.page } {...{ ...pageProps, ...remoteProps, onPageChange }} />
      }) }
    </ul>
  </div>
);

const Table = ({ id='Table', loading, columns, data, defaultSortCol, rowClasses, rowStyle, defaultSortDir='desc', onRowClick, withViewAction=true, onViewClick, expandRow, selectRow=undefined, emptyText="No data to show", keyField, i18n=Text.resolve, sizePerPage: _sizePerPage, hideSizePerPage: _hideSizePerPage, onSizePerPageChange: _onSizePerPageChange, showTotal: _showTotal, onPageChange: _onPageChange, onSortChange: _onSortChange, totalSize, pageNumber, onRowHover }) => {
  useWindowSize();

  const [{ [`${id}.currentPage`]: currentPage=1, [`${id}.currentDataSize`]: currentDataSize, [`${id}.currentSort`]: currentSort}, setLocationState] = useLocationParams({ [`${id}.currentPage`]: 1, [`${id}.currentDataSize`]: undefined, [`${id}.currentSort`]: undefined }, { coerce: { [`${id}.currentPage`]: Number }});
  const setCurrentPage = (page) => currentPage     !== page && setLocationState({[`${id}.currentPage`]    : page});
  const setCurrentSort = (sort) => currentSort     !== sort && setLocationState({[`${id}.currentSort`]    : sort});
  const setDataSize    = (size) => currentDataSize !== size && setLocationState({[`${id}.currentDataSize`]: size});

  // UGLY: workarround that should work most of the time ... reset page everytime the filters change, but keeps the page from a history back. TODO: find a better way.
  useEffect(() => { !loading && data.length     && setDataSize(data.length); }, [loading, data.length]); 
  useEffect(() => { totalSize !== undefined && (!loading || totalSize > 0) && setCurrentPage(1) }, [totalSize]); 
  useEffect(() => { !loading && currentDataSize && setCurrentPage(1)         }, [currentDataSize]); 
  // END UGLY

  const totalPages = useRef(0);
  const sizePerPage = useRef(_sizePerPage || 10);
  keyField = !keyField || !columns || columns.length === 0 ? 'idx' : keyField;

  const numCols = Object.entries(columns).length + [withViewAction, selectRow].filter(Boolean).length;
  const cols = Object.entries(columns).map(([key, col], colIdx) => ({
    ...col,
    classes        : (...args) => [typeof col.classes === "function" ? col.classes(...args) : col.classes || '', col.canBeHidden ? "text-truncate canBeHidden" : "text-truncate"].filter(Boolean).join(" "),
    headerClasses  : (...args) => [typeof col.headerClasses === "function" ? col.headerClasses(...args) : col.headerClasses || '', col.canBeHidden ? "canBeHidden" : ""].filter(Boolean).join(" "),
    headerFormatter: col.headerFormatter || (col.help ? (colProps, _, {sortElement}) => <div title={col.tooltip} className="headerWithHelp fitContent">{colProps.text}<HelpTooltip content={i18n(col.help)} place={col.helpPlace || (colIdx >= Math.floor(0.75*numCols) ? 'left' : colIdx <= Math.ceil(0.25*numCols) ? 'right' : 'bottom')} className="headerHelp" id={`help${key}`}/>{sortElement}</div> : undefined),
    text           : col.text || (col.content ? i18n(col.content) : ""),
    formatter      : col.formatter || ( cell => col.type === 'date' && cell && moment(cell).isValid() ? moment(cell).format('L') : cell ),
    dataField      : key,
    sort           : col.isSortable,
    sortFunc       : col.type === 'date' && !col.sortFunc ? sortByDate : col.sortFunc,
    onSort         : (field, order) => { setCurrentSort({field, order}); },
    footerClasses  : col.footerClass,
    headerTitle    : Boolean(col.tooltip) && (() => col.tooltip)
  }));

  if(!columns[keyField]) {
    cols.push({dataField: keyField, hidden: true})
  }

  if (withViewAction) {
    cols.push({
      headerClasses: "action",
      text       : withViewAction.text || "",
      formatter : (_, r, idx) => r.skipAction ? <></> : <Action id='open' label="open" className="button secondary" handleAction={_ => !selectRow?.clickToSelect ? (onViewClick || onRowClick)(r, idx) : true} />,
      dataField  : "action",
      sort       : false,
    });
  }


  const calculateNextPage = (pages, page) => {
    switch (page) {
      case "<" : return currentPage - 1;
      case ">" : return (currentPage || 1) + 1;
      case "<<": return 1;
      case ">>": return pages;
      default  : return page;
    }
  };

  const useRemotePageLoader = _onPageChange !== undefined;
  const hideSizePerPage = typeof _hideSizePerPage === "boolean" ? _hideSizePerPage : useRemotePageLoader ? totalSize <= 10 : data.length <= 10;
  const showTotal = typeof _showTotal === "boolean" ? _showTotal : useRemotePageLoader ? totalSize > 10 : data.length > 10;

  return (
    <BootstrapTable hover bootstrap4 condensed bordered={false}
      expandRow            = {expandRow}
      selectRow            = {selectRow}
      keyField             = {keyField}
      data                 = {data}
      defaultSorted        = {[{dataField: currentSort?.field || defaultSortCol, order: currentSort?.order || defaultSortDir}]}
      defaultSortDirection = {defaultSortDir}
      noDataIndication     = { () => loading ? <div className="inline centered"><span><Text>App.loading-data</Text></span><Spinner size="medium"/></div> : <h4><Text>{emptyText}</Text></h4> }
      headerClasses        = {"table-header"}
      rowClasses           = {(...args) => [typeof rowClasses === "function" ? rowClasses(...args) : rowClasses || '', Boolean(expandRow || onRowClick) ? "link-row" : ""].join(" ")}
      rowStyle             = {rowStyle}
      rowEvents            = {{ onClick: (!selectRow?.clickToSelect && Boolean(onRowClick)) ? ((e, r, idx) => onRowClick(r, idx)) : () => {}, onMouseEnter: (_e, r, i) => onRowHover?.(r, i) }}
      columns              = { cols }
      sort                 = { { dataField: currentSort?.field, order: currentSort?.order } }
      remote               = { useRemotePageLoader ? { sort: true } : undefined }
      onTableChange        = { useRemotePageLoader ? (_type, { sortField, sortOrder }) => {_onSortChange(sortField, sortOrder); setCurrentPage(1); } : undefined }
      pagination           = { paginationFactory({
        paginationTotalRenderer: (from, to, size) => {
          totalPages.current = Math.ceil(size/sizePerPage.current);

          return (
            <span className="react-bootstrap-table-pagination-total">
              {useRemotePageLoader ? <><Text>Table.from</Text> { (pageNumber - 1) * sizePerPage.current + (totalSize ? 1 : 0) } <Text>Table.to</Text> { Math.min(pageNumber * sizePerPage.current, totalSize) } <Text>Table.of</Text> { typeof totalSize === "number" ? totalSize : size }</>
               : <><Text>Table.from</Text> { from } <Text>Table.to</Text> { to } <Text>Table.of</Text> { typeof totalSize === "number" ? totalSize : size }</>}
            </span>
          );
        },
        page                   : currentPage,
        pageListRenderer       : ({pages, onPageChange: goTo}) => { 
          const active = pages.filter(p => p.active).map(p => p.page).shift();
          if (!useRemotePageLoader && active !== currentPage) goTo(currentPage); // FIXME: somewhow table does not change "page" when "currentPage" changes, so we need to force it 

          return pages.length > 1 ? <PageList remotePageLoader={useRemotePageLoader} currentPage={pageNumber || currentPage} totalSize={totalSize} pageSize={sizePerPage.current} pages={pages} onPageChange={(pageBtn) => { 
            const newPage = calculateNextPage(totalPages.current, pageBtn);
            setCurrentPage(newPage);
            if (useRemotePageLoader) _onPageChange(pageBtn, newPage); 
            else goTo(newPage); 
          }}/> : <Fragment/>
        },
        alwaysShowAllBtns      : useRemotePageLoader && sizePerPage.current < totalSize,
        withFirstAndLast       : true,
        paginationSize         : useRemotePageLoader ? 1 : isSmallScreen() ? 3 : 5,
        showTotal,
        sizePerPage: sizePerPage.current,
        hideSizePerPage,
        sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '50', value: 50 }, { text: '100', value: 100 }],
        sizePerPageRenderer    : ({ options, currSizePerPage, onSizePerPageChange }) => {
          const sizeOptions = options.map(o => ({ label: o.text, value: o.page }));
          const onChange = e => {
            sizePerPage.current = parseInt(e.target.value); 
            onSizePerPageChange(e.target.value); 
            _onSizePerPageChange?.(parseInt(e.target.value));
            if (useRemotePageLoader) setCurrentPage(1);
          }
          return (
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <QuickFilters.Select useSpan={true} className="selected" value={currSizePerPage} onChange={onChange} options={sizeOptions} />
              <Text>Table.perPage</Text>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </span>
          );
        },
        hidePageListOnlyOnePage: true
      }) }
    />
  );
};

const HidableTable = ({ hidden, ...props }) => hidden ? <></> : <Table {...props} />

export default HidableTable;