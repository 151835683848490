// Short format: C04B0399
//  Long format: C04BXXXX00000399
const isShortId = id => /^[A-Z]\d{2}[A-Z]\d{4}$/.test(id?.split('/').pop());
const toLongId  = id => id.replace(/(.{4})(.{4})$/, (_, g1, g2) => `${g1}XXXX${g2.padStart(8, '0')}`);

// Old BatchId    : YY.MM
// New LOT/BatchId: YYYY-MM-DD
const isLotId = id => /^\d{4}-\d{2}-\d{2}$/.test(id)

export const patch = (model) => {
  const _newURN = model.newURN;
  model.newURN = id => _newURN(isShortId(id) ? toLongId(id) : id); // ensure sensor IDs are always in the long format

  return model;
}

export default {
  1627948800000: async (event) => { // 2021-08-03 0.0.0 - 1.5.4 (sensor tracking feature)
    const { devices: { Device, Batch }, diagnosis: { Study } } = require('../index').all();

    if (event.type === "BULKUPDATE_COMPLETED") {
      return []; // Deprecated event
    }
    
    if (event.aggregate.name === Device.name && isShortId(event.aggregate.id))  {
      return []; // ignore any Device event with an old ID. They will be removed permanently from firestore. TODO: Maybe remove this code once done?
    }
    
    if (event.aggregate.name === Batch.name && !isLotId(event.data.id.split('/').pop()) ) {
      return []; // ignore any Batch event with an old ID. They will be removed permanently from firestore. TODO: Maybe remove this code once done?
    }
    
    if (event.aggregate.name === Study.name && event.data.tests ) {
      // a bit of a hack maybe? transform study events in the device context may not seem orthodox?
      // but this transformation is related to a change in the device ID format, so it should be kept in the same place as all the above.
      // TODO: maybe event transformation should not be organised/broken down into transformations for each domain/context but one independent transformation per release/model version??
      Object
        .values (event.data.tests)
        .filter (t => isShortId(t.recording?.sensor))
        .forEach(t => t.recording.sensor = toLongId(t.recording.sensor) )
    
      if (event.type === "RECORDING_METADATA_UPDATED") { 
        const testId = Object.keys(event.data.tests).shift(); // there should be only one test in the event.
        const recording = event.data.tests[testId].recording;

        if (!recording.sensor || Device.id(recording.sensor) === "undefined") return event;

        const [test, study, healthcaresite, organisation] = await require('../../firebase/storage').Storage.bucket().file(recording.metadataFile).owners;

        const DEVICE_RECORDING_COMPLETED = Device.events.DEVICE_RECORDING_COMPLETED.new({
          id: recording.sensor,
          recordings: {
            [test]: { // kinda redundant information that is already in the study events. This should make the device model logic a bit simpler ? we should probably define a "Recording" entity (different from a "Test" entity. Test = Recording + Diagnosis) ??
              test, study, healthcaresite, organisation, 
              startTime: recording.startTime,
              endTime  : recording.endTime,
              length   : recording.length,
              receiver : recording.receiver,
            }
          } 
        }, { causationId: event.id, timestamp: event.timestamp });

        const DEVICE_ASSIGNED = Device.events.DEVICE_ASSIGNED.new({id: recording.sensor, owners: [healthcaresite, organisation ]}, { causationId: event.id, timestamp: event.timestamp });

        return [event, DEVICE_RECORDING_COMPLETED, DEVICE_ASSIGNED];
      }
    }
  

    return event;
  }
}