import { incrementBase32, monotonicFactory } from 'ulid';

const ulid = monotonicFactory();
// ASSUMPTION: concurrent processes (in different VMs or not) that generate event IDs 
// within the same millisecond for the same aggregate ID should have enough entropy 
// difference so their respective randoms increments will never clash. 
// ULID uses cryptographically-secure randoms https://github.com/ulid/javascript#pseudo-random-number-generators
ulid.next = id => id.slice(0, 10) + incrementBase32(id.slice(10));

const PREFIX      = 'urn:com:acurable:apsa'; // TODO: maybe we should not depend on web-app specific name? -> instead of apsa, use webapp?
const COMMAND     = 'command';
const QUERY       = 'query';
const EVENT       = 'event';
const AGGREGATE   = 'aggregate';
const PERMISSION  = 'role';

// examples:
//      <prefix>            <resource> |-------------------------------------------- <path> -----------------------------------------------|
//                                         <context>      <Aggregate>    <entity?>            <type>                      <id>
//  - urn:com:acurable:apsa:  command  : administration / Organisation /  A/B/C    / REGISTER_ORGANISATION    / 01E0JSE9AEZT6CN0Q3HV1KYXY1
//  - urn:com:acurable:apsa:   query   : administration / Organisation /  A/B/C    /         GET              / 01E0JSE9AEZT6CN0Q3HV1KYXY1
//  - urn:com:acurable:apsa:   event   : administration / Organisation /  A/B/C    / ORGANISATION_REGISTERED  / 01E0JSEAJYFKNB0DQKAN9C88DJ

//                                         <context>      <Aggregate>              <id>                                 <entity?>
//  - urn:com:acurable:apsa: aggregate : administration / Organisation / 24b7ddf5-e91e-568d-955a-b474436bb572 
//  - urn:com:acurable:apsa: aggregate : administration / Organisation / 24b7ddf5-e91e-568d-955a-b474436bb572 / Preferences/patientProfileForm

//                                         <context>/<Aggregate>/Entity?             <id> 
//  - urn:com:acurable:apsa:   role    : diagnosis/Study/Test  /             study_get_acuahi3_value
//  - urn:com:acurable:apsa:   role    :                                     admin
//  - urn:com:acurable:apsa:   role    :                                     anonymous

// TODO: convert "admin" and "anonymous" urns to Role aggregates
// TODO: rename "role" resource type to "permission"

const VERB_PATH_REGEXP = (p = []) => { // /^(?<context>[^/]+) \/ (?<aggregate>[^/]+) \/ (?:(?<entity>.*)\/)? (?<type>[^/]+) \/ (?<id>.*)$/;
  const [context, aggregate, ...rest] = p.split('/'), id = rest.pop(), type = rest.pop();
  return { context, aggregate, entity: rest.length ? rest.join('/') : undefined, type, id };
}
  
const NOUN_PATH_REGEXP = (p = []) => {
  // /^ (?<context>[^/]+) \/ (?<aggregate>[^/]+) (?:\/(?<id>[^/]+) (?:\/(?<entity>.*))?)? $/;
  const [context, aggregate, id, ...rest] = p.split('/');
  return { context, aggregate, id, entity: rest.length ? rest.join('/') : undefined };
}

const PERM_PATH_REGEXP = p => {
  // /(?:(?<context>[^/]+)\/(?<aggregate>[^/]+)\/)?(?<id>.+)$/;
  const members = p?.split('/'), id = members.pop(), [ context, aggregate, ...rest ] = members;
  return { context, aggregate, id, entity: rest.length ? rest.join('/') : undefined };
}

const PATH2REF = {
  command:    p => VERB_PATH_REGEXP(p),
  query:      p => VERB_PATH_REGEXP(p),
  event:      p => VERB_PATH_REGEXP(p),
  aggregate:  p => NOUN_PATH_REGEXP(p),
  entity:     p => NOUN_PATH_REGEXP(p),
  role:       p => PERM_PATH_REGEXP(p),
  permission: p => PERM_PATH_REGEXP(p)
}

const dataFrom = (urn) => {
  const [ resource, path ] = isURN(urn) ? urn.split(':').slice(-2) : []; // /^urn:com:acurable:apsa:(?<resource>command|query|event|aggregate|role|permission):(?<path>.*)$/
  const reference = PATH2REF[resource]?.(path);
  if (reference) {
    let aname = reference.aggregate;
    if (["aggregate", "entity", "query", "command", "event"].includes(resource)) {
      let apath = reference.entity || "";
      if (resource === "aggregate" || resource === "entity") {
        apath = apath.split("/").filter((_, idx) => (idx % 2) === 0).join("/");
      }      
      aname = `${reference.aggregate}${reference.entity?.length > 0 ? "/" : ""}${apath}`;
    }
    
    const model  = {
      context: reference.context, 
      name: aname,
      aggregate: reference.id ? ({ id: urn, name: aname }) : undefined
    }
    
    return { urn, resource, path, ids: path.split('/').filter((_, idx) => idx > 1 && idx % 2 === 0), model, ...reference };
  }

  return urn && typeof(urn) === "string" ? { id: urn } : {};
}

const roleURN      = (roleId, context, name)  => `${PREFIX}:${PERMISSION}:${context?`${context}/`:''}${name?`${name}/${name.replace(/\//g, '_').toLowerCase()}_${roleId}`:roleId}`;
const permissionURN      = (id, context, name)  => `urn:com:acurable:apsa:${PERMISSION}:${context?`${context}/`:''}${name?`${name}/`:''}${`${id}`}`;
const aggregateURN = (model, ...idArgs) => {
  const ids = idArgs.filter(id => id !== undefined);
  if (!model) throw new Error("Invalid model for new aggregate URN");
  const pathName = model.name.split('/');
  let urnIds = isURN(ids[0]) ? ids[0].split('/').reduce((all, i, idx) => idx > 0 && (idx % 2 === 0) ? all.concat(i) : all, []).concat(ids.slice(1))  
                             : ids;
  if (urnIds.length < pathName.length) urnIds = urnIds.concat(ulid())
  if (urnIds.length !== pathName.length) throw new Error(`Invalid id argument for new aggregate URN [urnIds: ${urnIds}, pathName: ${pathName}, model: ${model.name}]`);
  
  const path = [model.context].concat(pathName.reduce((p, a, idx) => p.concat([a, urnIds[idx]]), []));
  return `${PREFIX}:${AGGREGATE}:${path.join('/')}`;
}
const commandURN   = (model, type, id=ulid()) => `${PREFIX}:${COMMAND}:${model.context}/${model.name}/${type}/${dataFrom(id).id}`;
const eventURN     = (model, type, id, timestamp) => `${PREFIX}:${EVENT}:${model.context}/${model.name}/${type}/${dataFrom(id || ulid(timestamp)).id}`;
const nextEventURN = (targetURN, sourceURN)   => targetURN.replace(/[^/]+$/, ulid.next(dataFrom(sourceURN).id));
const queryURN     = (model, type, id=ulid()) => `${PREFIX}:${QUERY}:${model.context}/${model.name}/${type}/${dataFrom(id).id}`;

const isURN       = (id)  => id?.startsWith?.('urn:');
const isAggregate = (urn) => urn?.resource === AGGREGATE || urn?.includes(`:${AGGREGATE}:`);
const isReference = (urn, model) => {
  //const ref = dataFrom(urn);
  //return model ? (isURN(urn) && model.name === ref.model.name && aggregateURN(model, ...ref.ids) === urn) : isAggregate(ref); // More complex computationally when checking thousands/millions of ids because of dataFrom???
  return model ? isURN(urn) && (model.name.split('/').length * 2 /* entities&aggregates with ULID */ + 1 /* context */) === urn.split('/').length && model.name.split('/').every(aggr => urn.includes(`/${aggr}/`)) // ugly
               : isAggregate(urn);
}
const getModelName = (urn) => urn?.split('/').filter((_, idx) => idx % 2 !== 0).join('/');
const isCommand   = (urn) => (urn?.resource || dataFrom(urn).resource) === COMMAND;
const isEvent     = (urn) => (urn?.resource || dataFrom(urn).resource) === EVENT;
const isAudit     = (urn) => { if (!isURN(urn)) return false; const urnData = dataFrom(urn); return urnData?.resource === EVENT && urnData.context === 'audit'; };
const isQuery     = (urn) => (urn?.resource || dataFrom(urn).resource) === QUERY;

const parentURN   = (urn) => isURN(urn) && urn.split('/').length > 3 ? urn.split('/').slice(0, urn.split('/').length - 2).join('/') : undefined

const isPermission = (urn) => isURN(urn) && urn.match(`:${PERMISSION}:.*/`) !== null && !urn.endsWith('_viewer') && !urn.endsWith('_executor') && !urn.endsWith('_manager'); // FIXME: delegate to the model itself ?? do not hardcode ??
const isSystemRole = (urn) => isURN(urn) && urn.includes(`:${PERMISSION}:`) && !isPermission(urn); // FIXME: delegate to the model itself ?? do not hardcode ??
const isCustomRole = (urn) => isURN(urn) && urn.includes(`:${AGGREGATE}:authentication/Role/`); // FIXME: maybe aggregate should be "CustomRole" ??

export { isURN, isReference, getModelName, aggregateURN, commandURN, dataFrom, eventURN, nextEventURN, isAudit, isCommand, isEvent, isQuery, queryURN, roleURN, isPermission, isSystemRole, isCustomRole, parentURN, permissionURN };
