import './style.css';
import 'rc-time-picker/assets/index.css';

import * as Pages from 'modules/diagnosis';

import QuickFilters from 'ui/components/QuickFilters';

import Back from 'ui/components/Back';
import ClockIcon from '@atlaskit/icon/glyph/emoji/frequent';
import DetailsBlock from 'ui/components/DetailsBlock';
//import NoteIcon from '@atlaskit/icon/glyph/comment';
import { Redirect } from 'react-router-dom';
import ReportHeader from '../../components/TestReportHeader';
import { Study } from 'services/server/functions/model/diagnosis/model';
import Table from 'ui/components/Table';
import TimePicker from 'rc-time-picker';
import WithSnapshot from 'ui/components/WithSnapshot';
import moment from 'moment';
import useLocationParams from 'ui/hooks/useLocationParams';

const FindTime = (props) => (
  <div className="dateTimePickerInput"><TimePicker
    style={{ width: '100%' }}
    value={(props.value && moment(props.value)) || (props.defaultValue && moment(props.defaultValue))}
    minuteStep={5}
    secondStep={10}
    placeholder={props.placeholder}
    onChange={(momentTime) => props.handleChange(momentTime && momentTime.toDate())}
    inputReadOnly
    addon={(panel) => <div className="button fullWidth" onClick={() => panel.close()}>Done</div>}
  /><ClockIcon size='medium'/></div>
);

const ViewEvents = ({ events=[], fType, setType, fStartTime, setStartTime, fEndTime, setEndTime, header: Header }) => {
  const evsData = events.map(({idx, type, duration, time}, row) => ({
    idx,
    row          : row + 1,
    type         : type ? type : 'N/A',
    time         : time ? moment(time).format("HH:mm:ss") : 'N/A',
    typeComponent: <div className="eventType" key={type}><div className="icon"/><span>{type}</span></div>,
    duration     : `${duration}s` || 'N/A',
    // notes: notes && notes.length > 0 ? <NoteIcon/> : '' TODO: Emilio needs to check what to put as event comments 
  }));

  return (
    <div className="page" id="ViewEvents">
      <Back/>
      <div className="pageHeader">
        <h1 className="title">Sleep Study</h1>
      </div>
      <div className="pageContent">
        <div className="pageSection">
          <div className="title">Study details</div>
          <Header />
        </div>
        <div className="pageSection">
          <div className="title large">Apnoea Events List</div>
          <DetailsBlock className="reportCard">
            <div className="pageSection">
              <div className="title">Search by</div>
              <div className="grid-row">
                <div className="grid-col">
                  <FindTime
                    id="startTime"
                    placeholder='Start time (hh:mm:ss)'
                    value={fStartTime}
                    handleChange={(v) => { console.log("Selected start time: ", v); setStartTime(v); }}
                    defaultValue={fEndTime}
                  />
                  <FindTime
                    id="endTime"
                    placeholder='End time (hh:mm:ss)'
                    value={fEndTime}
                    handleChange={setEndTime}
                    defaultValue={fStartTime}
                  />
                </div>
                <QuickFilters>
                  <QuickFilters.Select label='Type' className="selected" onChange={(e) => setType(e.target.value)} options={evsData.map(e => ({label: e.type, value: e.type})).concat({label: 'All'})} value={fType}/>
                </QuickFilters>
              </div>
            </div>
            <Table
              keyField='idx'
              columns={{
                row          : { content: "#",        isSortable: true },
                time         : { content: "Time",     isSortable: true },
                duration     : { content: "Duration", isSortable: true },
                typeComponent: { content: "Type",                      },
                // notes   : { content: "Notes"                      } TODO: Emilio needs to check what to put as event comments
              }}
              // onRowClick={(event) => hist  ory.push(Pages.ViewSignals.PATH, { study: id, event: event.idx })}
              data={evsData}
              defaultSortCol={'idx'}
              defaultSortDir={'asc'}
              emptyText="No events found"
            />
          </DetailsBlock>
        </div>
      </div>
    </div>
  );
}

const ViewEventsPresenter = (props) => {
  const {type: fType, startTime: fStartTime, endTime: fEndTime} = props;
  const study = props.snapshot?.data || {id: undefined, report: { events: [] }, owners: []};
  
  const byTime         = ({time}) => moment(moment(time).format('HH:mm:ss'), 'HH:mm:ss').isBetween(fStartTime || moment(0), fEndTime || moment());
  const byType         = ({type}) => fType ? type === fType : true;
  const byCurrentState = (event) => [byTime, byType].reduce((result, filter) => result && filter(event), true);
  const toBasic        = ({id, report={events: []}, owners}) => ({id, owners, events: (report.events || []).map((e, idx) => {const newEvent = {...e}; newEvent.idx = idx; return newEvent;}).filter(byCurrentState)})
  
  return ViewEvents({
    ...toBasic(study),
    fType,
    setType: (type) => props.setLocationState({type}),
    fStartTime,
    setStartTime: (startTime) => props.setLocationState({startTime}),
    fEndTime,
    setEndTime: (endTime) => props.setLocationState({endTime}),
    header: () => <ReportHeader {...props}/>
  });
}

export default (props) => {
  const [{study: studyId, type, startTime, endTime}, setLocationState] = useLocationParams();
  
  return WithSnapshot(Study, studyId, {fallbackComponent: _ => <Redirect to={Pages.FindStudy.PATH}/>})(ViewEventsPresenter)({...props, studyId, type, startTime, endTime, setLocationState});
}