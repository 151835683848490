import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import ServerConfig  from 'services/server/functions/model/administration/config';

const modulePriority = 100;
const organisationBasePath = Organisation.name.toLowerCase();
const hcsBasePath = HealthcareSite.name.toLowerCase();
const trialsBasePath = Organisation.entities.Trial.name.split('/').pop().toLowerCase();

export default {
  context: ServerConfig.context,
  priority: modulePriority,
  routes: {
    // Organisations
    CreateOrganisation: { priority: modulePriority + 1, relpath: `/${organisationBasePath}/create`, roles: Organisation.commands.REGISTER_ORGANISATION.roles },
    FindOrganisation  : { priority: modulePriority + 2, relpath: `/${organisationBasePath}/find`  , roles: Organisation.queries.LIST.roles     },
    UpdateOrganisation: { priority: modulePriority + 3, relpath: `/${organisationBasePath}/edit`, roles: Organisation.commands.UPDATE_ORGANISATION.roles   },
    ViewOrganisation  : { priority: modulePriority + 4, relpath: `/${organisationBasePath}/view`  , roles: Organisation.queries.GET.roles       },
    ViewOrganisationConfiguration  : { priority: modulePriority + 10, relpath: `/${organisationBasePath}/configure`  , roles: Organisation.queries.GET.roles},
    ConfigureOrganisationProfile  : { priority: modulePriority + 11, relpath: `/${organisationBasePath}/profile/configure`  , roles: Organisation.commands.CONFIGURE_ORGANISATION_PROFILE.roles},
    MyOrganisation    : { priority: modulePriority + 9, relpath: `/${organisationBasePath}/mine`  , roles: Organisation.queries.GET.roles       },
    
    // Healthcare Sites
    CreateHCS: { priority: modulePriority + 5, relpath: `/${hcsBasePath}/create`, roles: Organisation.commands.REGISTER_HCS.roles  },
    FindHCS  : { priority: modulePriority + 6, relpath: `/${hcsBasePath}/find`  , roles: HealthcareSite.queries.LIST.roles   },
    UpdateHCS: { priority: modulePriority + 7, relpath: `/${hcsBasePath}/update`, roles: HealthcareSite.commands.UPDATE_HCS.roles  },
    ViewHCS  : { priority: modulePriority + 8, relpath: `/${hcsBasePath}/view`  , roles: HealthcareSite.queries.GET.roles      },
    ViewHealthcareSiteConfiguration  : { priority: modulePriority + 14, relpath: `/${hcsBasePath}/configure`, roles: HealthcareSite.queries.GET.roles},

    // Clinical trials
    CreateTrial: { priority: modulePriority + 10, relpath: `/${trialsBasePath}/create`, roles: Organisation.entities.Trial.commands.REGISTER_TRIAL.roles  },
    FindTrial  : { priority: modulePriority + 11, relpath: `/${trialsBasePath}/find`  , roles: Organisation.entities.Trial.queries.LIST.roles            },
    UpdateTrial: { priority: modulePriority + 12, relpath: `/${trialsBasePath}/update`, roles: Organisation.entities.Trial.commands.EDIT_TRIAL.roles      },
    ViewTrial  : { priority: modulePriority + 13, relpath: `/${trialsBasePath}/view`  , roles: Organisation.entities.Trial.queries.GET.roles             },
  }
};
