import { Children, useEffect } from "react";

import Loading from "../Loading";
import {Text} from "../Text";
import useSnapshot from "ui/hooks/useSnapshot";



const WithSnapshot = (...args) => (WrappedComponent) => {
  const _Component = (origProps) => {
    const [model, ids, options={} ] = args;
    
    const {loading: parentIsLoading, ...props} = origProps;
    const { showLoading = true, fallbackComponent: Fallback, loadingComponent: LoadingComponent = Loading.Default, ...snapshotOptions } = options;
    const [snapshots, loading, start, cancel] = useSnapshot(model, ids, {...snapshotOptions, 
      onError: reason => {
        props.notify?.('error', Text.resolve('App.loading-data-failed'), Text.resolve('App.loading-data-failed-resolution'), { timeout: 6000 });
        options.onError?.(reason);
      }
    });
    snapshotOptions.debug && console.log("WithSnapshot", model.name, ids, snapshots, loading)

    const missingID   = args.length >= 2 && ids === undefined && !parentIsLoading;
    const useFallback = Fallback && (missingID || (!loading && (snapshots === undefined || Object.keys(snapshots).length === 0)));

    if (useFallback)
      return <Fallback {...origProps}/> 

    return (
      <>
        {showLoading && <LoadingComponent loading={loading}/>}
        <WrappedComponent loading={loading || parentIsLoading} {...{ [(typeof ids === 'string') ? 'snapshot' : 'snapshots']: snapshots} } start={start} cancel={cancel} {...props}/>
      </>
    );
  }

  _Component.displayName = `WithSnapshot(${WrappedComponent?.displayName || WrappedComponent?.name})`
  return _Component;
};

export default WithSnapshot; // TODO: refactor deprecate. We would need simpler more domain oriented. See example below, which can be improved.

//TODO: @felipe we would need to improve the way we define HoCs as "normal" components which extends the properties of their children, instead of functions/classes applied to components
export const WithSnapshotV2 = ({children, model, id, propName=model.name.toLowerCase(), showLoading, LoadingComponent=Loading.Default, onNotFound=() => {}, onError=() => {}}) => {
  const Child = Children.only(children);
  const [snapshot, loading] = useSnapshot(model, id, { onError });
  if (snapshot) snapshot.model = model;

  useEffect(() => {
    if (!loading && (id === undefined || snapshot === undefined)) onNotFound();
  }, [loading, snapshot])
  
  return (
    <>
      { showLoading && <LoadingComponent loading={loading}/> }
      <Child.type {...{...Child.props, [propName]: snapshot}} loading={loading} /> {/* eslint-disable-line react/jsx-pascal-case */} 
    </>
  );
};
