import './style.css';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Fragment } from 'react';
import Modal from 'ui/components/Modal';
import { Text } from 'ui/components/Text';

import codegen from 'codegen.macro';
import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

let topics = [];
codegen.require('./topics/index.macro');

const TopicModal = ({ topic, topicNum }) => (
    <Modal
        id={`PrivacyTopic${topicNum}`}
        isLoading={false}
        showExpand={false}
        fullScreen={false}
        whenClosedRender={({ open }) => (
            <li className="link" onClick={() => open()}>
                {topic}
            </li>
        )}
        whenOpenedRender={(_) => (
            <Fragment>
                <h2 style={{ marginBottom: '10px' }}>
                    {topics[topicNum].topic}
                </h2>
                {topics[topicNum].component()}
            </Fragment>
        )}
    />
);

const Privacy = () => {
    const { assetsURl } = useAssetsURL();

    return (
        <div id="Privacy" className="page">
            <Back />
            <div className="pageHeader">
                <h1 className="title">
                    <Text>PrivacyPolicy.title</Text>
                </h1>
            </div>
            <div className="pageContent">
                <div className="pageSection">
                    <Text>PrivacyPolicy.intro-1</Text>
                </div>
                <div className="pageSection">
                    <Text>PrivacyPolicy.intro-2</Text>
                </div>
                <div className="pageSection">
                    <DetailsBlock inline>
                        <div>
                            <h2>
                                <Text>PrivacyPolicy.topics.title</Text>
                            </h2>
                            <ul>
                                {topics.map((t, topicNum) => (
                                    <TopicModal
                                        key={`topic${topicNum}`}
                                        topic={Text.resolve(t.topic)}
                                        topicNum={topicNum}
                                    />
                                ))}
                            </ul>
                        </div>
                        <div className="sideImage">
                            <img
                                alt=""
                                src={getImgUrl(assetsURl, 'gdpr.png')}
                            />
                        </div>
                    </DetailsBlock>
                </div>
            </div>
        </div>
    );
};

Privacy.displayName = 'Privacy';

export default Privacy;
