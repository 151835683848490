import './style.css';

import { Fragment, createContext, useCallback, useEffect, useState } from 'react';
import Styled from "styled-components";

import AppSwitcherIcon from '@atlaskit/icon/glyph/menu';
import BreakPoints from 'ui/acukit/Grid/breakPoints';
import SideNavBar from 'ui/components/SideNavBar';
import ContextSelector from 'features/components/ContextSelector';

const BreakNav    = BreakPoints.breakNav;
const topMenuSize = 32;

const ResponsiveNavTop = Styled.div.attrs(props => ({nav: props.width || 250}))` 
  transition      : transform 0.3s;
  transform       : translateY(-56px);
  margin-bottom   : -56px;
  height          : ${props=>props.menuSize}px;
  background-color: #F4F5F7;
  position        : fixed;
  top             : 0;
  width           : 100%;
  z-index         : 9999;
  @media (max-width: ${BreakNav}px){
      transform:translateY(0px);
      margin-bottom:0px;
  }
`;

const WrapperPage = Styled.div`
  position  : absolute;
  top       : 0;
  transition: all 0.3s;
  width     : 100%;
  height    : 100%;
  @media (max-width: ${BreakNav}px){
    top:${props => props.showTopMenu ? props.menuSize : 0}px;
    max-height: calc(100% - ${props => props.showTopMenu ? props.menuSize : 0}px);
  }
`;

const NavigationAndContent = Styled.div`
  display: flex;
  flex   : 1 1 auto;
  width  : 100%;
  height : 100%;
  @media (max-width: ${BreakNav}px){
    overflow: auto;
    max-height: calc(100vh - ${props => props.showTopMenu ? props.menuSize : 0}px);
  }
`;

const ClosableNavigation = ({isOpen, showSideNav, children}) => (
  <div id="Navigation" className={`${showSideNav ? 'visible' : 'hidden'} ${isOpen ? 'opened' : 'closed'}`}  >
    {children}
  </div>
);

export const PageNavContext = createContext();

const Page = props => {
  const [isFullScreen, setFullScreen]   = useState(props.fullscreen);
  const [isNavVisible, setIsNavVisible] = useState(), navVisible = () => isNavVisible && props.showNav;
  const [showSideNav, setShowSideNav]   = useState(window.innerWidth >= BreakNav);
  const [isOpen, setIsOpen]             = useState(window.innerWidth >= BreakNav);
  const open                      = useCallback(() => showSideNav && !isOpen && setIsOpen(true), [isOpen, showSideNav]);
  const close                     = useCallback(() => isOpen && setIsOpen(false), [isOpen]);
  const showSide                  = useCallback(() => { 
                                      if (navVisible() && !showSideNav) setShowSideNav(true); 
                                      if (window.innerWidth >= BreakNav) open();
                                      else                               close();
                                    }, [isNavVisible, showSideNav]);
  const hideSide                  = useCallback(() => { if (showSideNav)   setShowSideNav(false) }, [showSideNav]);
  const showNav                   = useCallback(() => { 
                                      if (!isNavVisible) setIsNavVisible(true); 
                                      if (window.innerWidth >= BreakNav) showSide();
                                      else                               hideSide();
                                    }, [isNavVisible]);
  const hideNav                   = useCallback(() => { if (navVisible())  setIsNavVisible(false); }, [isNavVisible]);
  const onItemSelected            = useCallback(() => (window.innerWidth < BreakNav) && hideSide());

  const enterFullScreen = useCallback(() => { setFullScreen(true); setIsOpen(false); });
  const exitFullScreen  = useCallback(() => { setFullScreen(false); setIsOpen(true); });

  const handleResize = useCallback(() => { 
    (window.innerWidth <  BreakNav) && hideSide();
    (window.innerWidth >= BreakNav) && showSide();
  }, [showSideNav, showSide, hideSide]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <div>
      {navVisible() ? (
        <ResponsiveNavTop id="ResponsiveNavTop" menuSize={topMenuSize}>
          <div className="button navButton" style={{ width: 2 * topMenuSize, height: topMenuSize, padding: 0, textAlign: 'center' }} onClick={showSideNav ? hideSide : showSide}>
            <AppSwitcherIcon size='large' label="navButton" />
          </div>
        </ResponsiveNavTop>
      ) : <Fragment />}
      <WrapperPage id="WrapperPage" showTopMenu={navVisible() && window.innerWidth < BreakNav} menuSize={topMenuSize} margin->
        <NavigationAndContent id="NavigationAndContent" showTopMenu={navVisible() && window.innerWidth < BreakNav} menuSize={topMenuSize}>
          {navVisible() ? <ClosableNavigation isOpen={isOpen} showSideNav={showSideNav}>
            <SideNavBar
              onClose={close}
              onOpen={open}
              isOpen={isOpen}
              onItemSelected={onItemSelected}
              headerContent={<ContextSelector />}
            />
          </ClosableNavigation> : <Fragment />}
          <PageNavContext.Provider value={{ open, isOpen: (() => isOpen), close, hide: hideNav, show: showNav, enterFullScreen, exitFullScreen }}><div id="PageContent" className={`pageContainer ${isFullScreen ? 'fullscreen' : ''}`}>{props.children}</div></PageNavContext.Provider>
        </NavigationAndContent>
      </WrapperPage>
    </div>
  );
}

export default Page;


