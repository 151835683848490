import history from 'history.js';
import { Text } from 'ui/components/Text';

import './style.css';

const WarningIcon = () => <svg id="WarningIcon" viewBox="0 0 24 24" focusable="false" role="presentation"><g fillRule="evenodd"><path d="M12.938 4.967c-.518-.978-1.36-.974-1.876 0L3.938 18.425c-.518.978-.045 1.771 1.057 1.771h14.01c1.102 0 1.573-.797 1.057-1.771L12.938 4.967z" fill="currentColor"/><path d="M12 15a1 1 0 0 1-1-1V9a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2" fill="inherit"/></g></svg>;

const goBack = _ => history.lengthSinceSignin > 0 ? history.goBack() : require('services/iam').signOut();

export default ({title, message, onBack=goBack}) => (  
  <div id="Error">
    <div className="grid-col">
      <div className="icon"><WarningIcon/></div>
      <div className="grid-row">
        <h1>{title}</h1>
        {message}
        {(history.lengthSinceSignin > 0 || require('services/iam').isAuthenticated()) && 
          <div className="button primary" onClick={onBack}>
            <Text>{history.lengthSinceSignin > 0 ? 'back' : 'exit'}</Text>
          </div>
        }
      </div>
    </div>
  </div>
)