import 'react-phone-input-2/lib/style.css';
import * as Pages from 'modules/administration';
import { Organisation } from 'services/server/functions/model/administration/model';
import { Select } from 'ui/components/Select';
import { WithValidation } from 'ui/components/WithValidation';
import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import {Text} from 'ui/components/Text';
import Modal from 'ui/acukit/ModalDialog';
import { useState } from 'react';
import { ValidatedPhone } from 'ui/components/PhoneInput';
import i18n from 'services/server/functions/i18n';
import WithSnapshot from 'ui/components/WithSnapshot';

import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import { EditOrganisationProducts } from './EditOrganisationProducts';

import './style.css';
import { DataBase } from 'services/firebase';
import useCurrentUser from 'ui/hooks/useCurrentUser';

const isTopCountry = (country) => country === "GB" || country === "US";
const countryNames = () => i18n.countries.getCodes().map(c => ({value: c, label: i18n.countries.getName(c), group: isTopCountry(c) ? '' : 'Others'}));

const ValidatedInput = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><input {...props} value={props.onEmpty ? props.onEmpty(props.value) : props.value || ''}/></WithValidation>;
const ValidatedSelect = (props) => <WithValidation field={props.field || props.name} {...props}><Select {...props}/></WithValidation>; 

const EditOrganisationUI = ({ formSaved, onValidate, onSave, isLoadingSave, onValueChange, isUpdate, askConfirmation, onConfirm, onRegret, organisation = {}, I18n }) => {
  const [edited, setEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const actions = [
    { text: 'leave', onClick: _ => history.goBack(), appearance: 'primary' },
    { text: 'stay', onClick: () => setShowModal(false) },
  ];
  const onChange = (...args) => {
    setEdited(true);
    return onValueChange(...args);
  };

  const ValidationSchema = isUpdate ? Organisation.commands.UPDATE_ORGANISATION.schema : Organisation.commands.REGISTER_ORGANISATION.schema;

  return (
    <div className="page" id="EditOrganisation">
      <Back />
      <div className="pageHeader">
        <h1 className="title">{isUpdate ? I18n('EditOrganisation.title') : I18n('CreateOrganisation.title')}</h1>
      </div>
      <div className="pageContent remove-m-bottom">
        <div className="pageSection remove-m-bottom">
          <div className="title">{I18n('details')}</div>
          <DetailsBlock>
            <div className="grid-col">
              <Details id="organisationName" label="global.name" mandatory><ValidatedInput schema={ValidationSchema} showError={formSaved} onValidate={onValidate} name="name" onChange={onChange} value={organisation.name} /></Details>
              <Details id="organisationCountry" label="country"><ValidatedSelect name='country' onValidate={onValidate} onChange={onChange} options={countryNames()} isClearable placeholder={Text.resolve("choose-country")} schema={ValidationSchema} showError={formSaved} value={organisation.country} /></Details>
            </div>
            <div className="grid-col">
              <Details id="organisationAddress" label="address"><ValidatedInput autoComplete="off" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} name="address" onChange={(e) => { if (e.target.value === "") onChange({ target: { name: e.target.name, value: null } }); else onChange(e); }} onEmpty={(val) => val === "" ? null : val} value={organisation.address} /></Details>
              { organisation.country ? <Details id="organisationPostCode" label="postal-code"><ValidatedInput name="postCode" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={(e) => { if (e.target.value === "") onChange({ target: { name: e.target.name, value: null } }); else onChange(e); }} onEmpty={(val) => val === "" ? null : val} value={organisation.postCode} form={{ country: organisation.country, postCode: organisation.postCode }} /></Details> : <></> }
            </div>
            <div className="grid-col">
              <Details id="organisationMail" label="email-address"><ValidatedInput schema={ValidationSchema} showError={formSaved} onValidate={onValidate} name="mail" onChange={(e) => { if (e.target.value === "") onChange({ target: { name: e.target.name, value: null } }); else onChange(e); }} onEmpty={(val) => val === "" ? null : val} value={organisation.mail} /></Details>
              <Details id="organisationPhone" label="telephone-number"><ValidatedPhone country={organisation.country || ''} schema={ValidationSchema} showError={formSaved} onValidate={onValidate} name="phone" onChange={onChange} value={organisation.phone} /></Details>
            </div>
            <Details id="organisationUID" label="unique-identifier" help={I18n("unique-id.help")}><ValidatedInput schema={ValidationSchema} showError={formSaved} onValidate={onValidate} name="uid" onChange={onChange} value={organisation.uid} /></Details>
            {
              !isUpdate && (
                <EditOrganisationProducts
                  organisationData={organisation}
                  onChange={ onChange }
                  isUpdate={isUpdate}
                  showError={formSaved} />
              )
            }
          </DetailsBlock>
          <span><sup className="mandatory">*</sup><span className="small">{I18n('compulsory-field')}</span></span>
        </div>
      </div>
      <div className="pageFooter">
        <div className='actions'>
          <Action className='button' id="back" handleAction={() => edited ? setShowModal(true) : history.replace(Pages.FindOrganisation.PATH)} label='cancel' />
          <Action className='button primary' id="report" handleAction={() => onSave()} label='global.button.save' loading={isLoadingSave} />
        </div >
      </div>
      {
        isUpdate && (
          <EditOrganisationProducts
            organisationData={organisation}
            isUpdate={isUpdate}
            onSubmit={() => history.replace(Pages.ViewOrganisation.PATH, { organisation: organisation.id })} />
        )
      }
      <Modal show={showModal} onClose={() => setShowModal(false)} heading="unsaved-changes" actions={actions}>
        <span>{I18n('sure-to-leave')}</span>
      </Modal>
      <Modal show={askConfirmation} onClose={onRegret} heading="please-confirm" actions={[
        { text: 'confirm', onClick: onConfirm, appearance: 'primary' },
        { text: 'cancel', onClick: onRegret },
      ]}>
        <span>{I18n('already-exists')}</span>
      </Modal>
    </div>
  );
};

const EditOrganisationPresenter = (props) => {
  const [originalUser] = useCurrentUser({ skipContext: true });

  const [saved, setSaved]   = useState(false);
  const [isLoadingSave, setIsLoadingSave]   = useState(false);
  const [errors, setErrors] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);

  const { org } = props;
  const id           = props.organisation;
  const isUpdate     = id !== undefined;
  const organisation = org || (isUpdate ? props.snapshot?.data : {});
  
  const hasErrors = () => errors && Object.values(errors).length > 0;
  
  const onValidate = (field, error) => setErrors(prevErrors => {
    if (error) return { ...prevErrors, [field]: error };
    delete prevErrors[field];
    return prevErrors;
  });
  
  const handleRegister = (data) => {
    const newOrg = Organisation.new(data);
    return props.registerOrganisation(newOrg.data)
      .then(() => history.replace(Pages.ViewOrganisation.PATH, { organisation: newOrg.aggregate.id }));
  };

  const handleUpdate = (data) => isUpdate && props.updateOrganisation({ id, ...data })
      .then(_ => history.replace(Pages.ViewOrganisation.PATH, { organisation: id }));

  const submit = () => {
    setAskConfirmation(false);
    return (isUpdate ? handleUpdate : handleRegister)(organisation).catch((e) => { setIsLoadingSave(false); console.error(e); });
  };

  const handleSave = async () => {
    setSaved(true);
    setIsLoadingSave(true);
    if (!hasErrors()) {
      const query = DataBase().snapshots(Organisation).with('name', organisation.name);
      if (organisation.id) {
        query.with('id', '!=', organisation.id);
        if (!originalUser.isSuperAdmin() || !originalUser.isFromRoot()) query.with('metadata.allOwners', 'array-contains-any', originalUser.data.owners);
      }
      const orgasWithSameName = await query.count().catch(error => { console.error(error); setIsLoadingSave(false); throw error;});
      console.log("here res", orgasWithSameName);
      if (orgasWithSameName !== 0) {
        setIsLoadingSave(false);
        return setAskConfirmation(true);
      }
      submit();
    };
    setIsLoadingSave(false);
  }

  const onValueChange = (event) => {
    const value = event.target.type === 'select-multiple' ? [] : event.target.value;
    if (event.target.type === 'select-multiple') for (let i = 0; i < (event.target.selectedOptions || []).length; i++) value.push(event.target.selectedOptions[i].value);
    const updates = { [event.target.name]: value };
    if (event.target.name === "country" && value === null) updates.postCode = null;
    props.setLocationState({org: { ...organisation, ...updates }});
  }

  return <EditOrganisationUI
    organisation={organisation}
    onValidate={onValidate}
    onSave={handleSave}
    isLoadingSave={isLoadingSave}
    onValueChange={onValueChange}
    formSaved={saved}
    isUpdate={isUpdate}
    askConfirmation={askConfirmation}
    onConfirm={submit}
    onRegret={() => setAskConfirmation(false)}
    I18n={props.I18n}
  />;
};

const EditOrganisation = (props) => {
  const [locState, setLocationState] = useLocationParams();
  // Note: loading all organisations because of popup warning saying there is another organisation with same name (re-using query to load current snapshot on Update UC)
  return WithSnapshot(Organisation, locState.organisation, {showLoading: locState.organisation !== undefined})(EditOrganisationPresenter)({...props, setLocationState, ...locState});
}

EditOrganisation.displayName = "EditOrganisation";
export default EditOrganisation;