import { Component } from 'react';
import Error500 from './Error500';

class ErrorBoundary extends Component {  
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  state = {
    hasError: false
  }
  
  componentDidCatch(error, _errorInfo) {
    // Catch errors in any components below and re-render with error message
    require('services/server/functions/errors').reportError(error);
  }
  
  goBack() {
    this.setState(_ => {
      window.location.replace('/');
      return { hasError: false }
    })
  }

  render() {
    return this.state.hasError ? <Error500 onBack={_ => this.goBack()}/> : this.props.children;
  }  
}

export default ErrorBoundary;