import { createRef } from "react";
import {Text} from 'ui/components/Text';

import "./style.css"

export default ({disabled, onFileAdded, accept}) => {
  const fileInputRef = createRef();

  const openFileDialog = () => {
    fileInputRef.current.value = null
    fileInputRef.current.click();
  }

  const onChange = (evt) => {
    onFileAdded(evt.target.files[0])
  }

  return (
    <div onClick={disabled ? _ => {} : openFileDialog}>
      <input onChange={onChange} name="file" type="file" ref={fileInputRef} accept={accept} className={['inputfile', 'dropButton', disabled ? 'disabled' : ''].join (' ')}/>
      <label htmlFor="file"><Text>FileInput.upload.select-file</Text></label>
    </div>
  );
}