import { Fragment }from "react";

import i18n from "services/server/functions/i18n";
import { Reports } from "features/reports/helpers/tableReportConfig";
import * as Pages from 'modules/diagnosis';

import { messageFrom } from "modules/executor";

import { Text } from "ui/components/Text";

import useCurrentUser, { IfHasAccess } from "ui/hooks/useCurrentUser";
import ExportReport from "ui/components/ExportReport";

import { filterOwners } from "features/helpers/studyHelper";
import { mapDateToRequest } from "features/helpers/dateHelper";
import { removeUnsetProps } from "features/helpers/objectHelper";
import { FiltersType } from "../../utils";

import './styles.css';
const getReport = (report, data) => require('services/server/functions/executor/executor.client').getReport(`diagnosis.Study.${report}`, data).then(encFile => `data:text/csv;charset=utf-8;base64,${encFile}`);

const ExportTemplate = (report, { resource, disabled, ...props }) => {
    if (!report) return <></>;
    return (
        <IfHasAccess resource={resource}>
            <ExportReport normal={true} disabled={disabled} type="csv" {...props} />
        </IfHasAccess>
    );
};

const CreateStudyAction = () => (
    <IfHasAccess resource={Pages.CreateStudy}>
        <Pages.CreateStudy.AsLinkButton text="create" className="primary" />
    </IfHasAccess>
);
const CreateStudyBatchAction = (_, { user }) => (
    user.isFromAcurable() && <IfHasAccess resource={Pages.CreateStudyBatch}>
        <Pages.CreateStudyBatch.AsLinkButton text="create-batch" linkClass="createStudyBatchLink" />
    </IfHasAccess>
);

const ReportActions = {
    [Reports.StudyReport.key]: [CreateStudyAction, CreateStudyBatchAction, ExportTemplate],
    [Reports.ActivityLogReport.key]: [ExportTemplate],
    [Reports.ActivitySummary.key]: [ExportTemplate]
};

export const StudyFilterActions = ({ reportType, statuses, productId, filters, organisation, healthcaresite, trial, device, patientID, patientBirth, clinician, showArchived, exclusiveArchived, disabled, notify, batchId }) => {
    const [user] = useCurrentUser();
    const reportCallback = (reportName = "") =>
        getReport(reportName, { ...removeUnsetProps({
            // Allows to have either organisations or healthcaresites
            owners: [...new Set(filterOwners([filters?.[FiltersType.Organisation], filters?.[FiltersType.HealthcareSite], organisation, healthcaresite, trial]))],
            createdDateStart: mapDateToRequest(filters?.[FiltersType.DateCreated]?.split("/")?.[0]),
            createdDateEnd: mapDateToRequest(filters?.[FiltersType.DateCreated]?.split("/")?.[1]),
            conductedDateStart: mapDateToRequest(filters?.[FiltersType.DateConducted]?.split("/")?.[0]),
            conductedDateEnd: mapDateToRequest(filters?.[FiltersType.DateConducted]?.split("/")?.[1]),
            lastUpdatedDateStart: mapDateToRequest(filters?.[FiltersType.LastUpdated]?.split("/")?.[0]),
            lastUpdatedDateEnd: mapDateToRequest(filters?.[FiltersType.LastUpdated]?.split("/")?.[1]),
            statuses,
            devices: [device].filter(Boolean),
            reference: patientID,
            patientBirthDate: patientBirth,
            clinicians: [clinician].filter(Boolean),
            testStatuses: [filters?.[FiltersType.Status]].filter(Boolean),
            requestedTests: filters?.[FiltersType.NumberOfTests],
            showArchived,
            exclusiveArchived,
            testSeverities: [filters?.[FiltersType.Severity]].filter(Boolean),
            productId,
            batchId: batchId?.length ? batchId : undefined,
            language: i18n.current,
        }), flatten: true });

    const report = Object.values(Reports).find(report => report.key === reportType);
    const currentActions = ReportActions[reportType];

    return (
        <div className='actions'>
            {currentActions?.map((c, index) => (
                <Fragment key={`${report.key}_${index}`}>
                    {c(report, {
                        user,
                        id: report.key,
                        resource: report.query,
                        disabled,
                        label: Text.resolve('global.export'),
                        urlCallback: () => reportCallback(report.key), onError: e => console.error(e) || notify('error', Text.resolve(`FindStudy.error.${report.key}`), messageFrom(e))
                    })}
                </Fragment>
            ))}
        </div>
    );
};
