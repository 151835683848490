import { useCallback } from 'react';

import { TitleField } from '../../components/TitleField';

import { getUISchemaFormLabel } from '../../utils/localizationUtils';
import { ClearButton } from '../../components/ClearButton';
import { DoesSchemaFormAcceptsUnndefined, getFormOpts } from '../../utils/formUtils';
import { ColorsType } from 'features/constants/colors';
import { Button } from 'features/components/Button';

import './style.css';

const getCurrentColor = (id, value) => {
    if (typeof value === 'undefined') {
        return ColorsType.Alternative;
    }
    const colorStatus = {
        [ColorsType.Success]: value ? ColorsType.Success : ColorsType.Alternative,
        [ColorsType.Danger]: !value ? ColorsType.Danger : ColorsType.Alternative
    };
    return colorStatus[id] ??  ColorsType.Alternative;
};
/*
  Available props from JSon schema lib
  schema,
  uiSchema,
  options,
  id,
  value,
  disabled,
  readonly,
  label,
  autofocus = false,
  onBlur,
  onFocus,
  onChange,
  registry,
 */

export const CheckboxWidget = (props) => {
    const {
        id,
        uiSchema,
        label,
        disabled,
        readonly,
        value,
        onChange,
        registry,
        required,
        schema,
    } = props;
    const formContext = registry?.formContext || {};
    const { title, help } = getUISchemaFormLabel({ uiSchema, formContext, label });
    const localization = formContext?.localization;
    const formOpts = getFormOpts(registry);
    const showClearButton = DoesSchemaFormAcceptsUnndefined(props) && formOpts?.showClearButton;
    const isDisabled = disabled || readonly || schema.isReadOnly;
    const disabledStyles = isDisabled ? 'switch-component-disabled' : '';
    const handleChange = useCallback((changedValue) => onChange(changedValue), [onChange]);
    const handleReset = () => handleChange(undefined);
    
    return (
        <div className="schema-form-toggle-widget-root">
            <div className="schema-form-toggle-widget-content schema-form-theme-check">
                <TitleField
                    id={id} required={required} help={help}
                >
                    {title}
                </TitleField>
                <div className="schema-form-toggle-widget-info">
                    <div className="schema-form-toggle-widget-buttons">
                        <Button
                            disabled={isDisabled} className="schema-form-toggle-width "
                            color={getCurrentColor(ColorsType.Success, value)}
                            onClick={() => handleChange(true)}
                        >
                            {localization?.status?.yes}
                        </Button>
                        <Button
                            disabled={isDisabled} className="schema-form-toggle-width   schema-form-toggle-margin"
                            color={getCurrentColor(ColorsType.Danger, value)}
                            onClick={() => handleChange(false)}
                        >
                            {localization?.status?.no}
                        </Button>
                    </div>
                </div>
                {<div className={disabledStyles}>{showClearButton && <ClearButton id={id} value={value} handleClick={handleReset} />}</div>}
            </div>
        </div>
    );
};
