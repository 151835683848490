import { INVALID_DATA_ERROR, NETWORK_ERROR, TIMEOUT_ERROR } from 'services/server/functions/errors/codes';
import { RECAPTCHA_VERIFICATION_REASON, getReason, getResolution } from 'services/server/functions/errors/messages';

import i18n from 'services/server/functions/i18n';

const recaptchaError = (code) => {
  const error      = {};
  error.code       = code;
  error.reason     = code === INVALID_DATA_ERROR ? RECAPTCHA_VERIFICATION_REASON : getReason(code)
  error.resolution = getResolution(code)
  return error;
}

const RecaptchaVerifier = require('services/firebase').firebase.auth.RecaptchaVerifier;

const RECAPTCHA = {};
const getDisabledCaptcha = (container) => {
  RECAPTCHA[container] = {};

  console.log("Captcha is now disabled_____________________");

  RECAPTCHA[container].doVerify = () =>
    new Promise((resolve) => resolve(true));

  return RECAPTCHA[container];
};
const getContainerId = (container) => container + '-element';
const appendRecaptchaElement = (container) => {
  const elem = document.createElement('div');
  elem.id = getContainerId(container);
  const parent = document.getElementById(container);
  parent.appendChild(elem);

};
const clearRecaptcha = (container) => {
  const docElement = document.getElementById(container);
  if (docElement) docElement.innerHTML = '';
  delete RECAPTCHA[container];
};

const recaptchaVerifier = (container) => { 
  const isDevelop = process?.env?.NODE_ENV === 'development';

  if (isDevelop) {
      return getDisabledCaptcha();
  }
  
  const recaptchaSettings = {
    size              : 'invisible',
    hl                : i18n.current,
    callback          : response => RECAPTCHA[container].onVerifySuccess(response),
    'expired-callback': (e) => { console.error("recaptcha expired", e); return RECAPTCHA[container]?.onVerifyFail(recaptchaError(TIMEOUT_ERROR)); },
    'error-callback'  : (e) => { console.error("recaptcha error", e); return RECAPTCHA[container]?.onVerifyFail(recaptchaError(NETWORK_ERROR), {clear: true}); },
  } 
  
  // Click outside recaptcha iframe closes recaptcha and no event is triggered to notify about this, so we need this ugly trick
  // FIXME: If user clicks anywhere while recaptcha is being verified (before opening recaptcha iframe), this event will get triggered producing a recaptchaError(INVALID_DATA_ERROR)
  const clickOutside = ev => !ev.target.closest('#rc-imageselect')?.offsetParent && RECAPTCHA[container]?.onVerifyFail(recaptchaError(INVALID_DATA_ERROR));
  const onVerifyStarted = () => setTimeout(_ => document.addEventListener('click', clickOutside), 150);
  const onVerifyEnds = () => document.removeEventListener('click', clickOutside);

  try {
    const recaptchaEmpty = _ => typeof container === "string" ? !document.getElementById(container).hasChildNodes() : !container.hasChildNodes();
    if (RECAPTCHA[container] && !recaptchaEmpty()) {
      clearRecaptcha(container);
    }
    if (!RECAPTCHA[container]) {
      appendRecaptchaElement(container);
      RECAPTCHA[container] = new RecaptchaVerifier(getContainerId(container), recaptchaSettings, require('services/firebase').app);
    }

    RECAPTCHA[container].doVerify = () => new Promise((resolve, reject) => {
                                          onVerifyStarted(); 
                                          RECAPTCHA[container].onVerifyFail = (error) => {onVerifyEnds(); clearRecaptcha(container); return reject(error);}; 
                                          RECAPTCHA[container].onVerifySuccess = result => {onVerifyEnds(); clearRecaptcha(container); return resolve(result);}; 
                                          RECAPTCHA[container].verify()
                                            .catch(e => {
                                              onVerifyEnds();
                                              console.error("Recaptcha error:", e);
                                              // Note: There is a bug in reCaptcha verifier when there is no internet connection -> it is reported as internal error -> using error message to detect this case
                                              return reject(recaptchaError(e.message.toLowerCase().match(/recaptcha dependencies/) ? NETWORK_ERROR : INVALID_DATA_ERROR));
                                            }); 
                                        });
  } catch (e) {
    console.error("Could not initialize reCaptcha verifier", e);
    throw e;
  }

  return RECAPTCHA[container];
}

export {
  recaptchaVerifier
}