import * as R from 'ramda';
import { roleURN, isCustomRole as isCustomRoleURN, isSystemRole as isSystemRoleURN, isPermission as isPermissionURN, isSystemRole, permissionURN } from '../executor/urn';

const isRoleURN      = (urn) => isPermissionURN(urn) || isSystemRole(urn);
const permissionId   = (acRight, {context, name} = {}) => roleURN(acRight.toLowerCase(), context, name);
// Cannot be lowerCase as it needs to be exactly like the field. We replace all spaces with a hashtag
const attributePermissionId   = (attributeRight, {context, name} = {}) => permissionURN(AttributeRights.to(attributeRight), context, name);
const newAccessRight = (role, {name, context, roleLabel, label, description}, includes=[]) => ({
  id         : isRoleURN(role) ? role : permissionId(role, {context, name}),
  label      : roleLabel || `${label} ${role}`,
  description: description || `${label} ${role}`,
  includes   : includes.filter((i, idx, a) => a.findIndex(r => r === i) === idx)
});
const newAttributeRight = (attributeRight, {context, name, attributeLabel, label, description}) => ({
  id: attributePermissionId(attributeRight, {context, name}),
  label: attributeLabel || `${label} ${attributeRight}`,
  description: description || `${label} ${attributeRight}`,
});

// Roles and Permissions: 
//   https://docs.google.com/spreadsheets/d/1FjNxhRt_UXpjHQ5hh39osL6bOtW6JJYD-spIpMceog4/edit#gid=0
// Super admin role is the role to be assigned to commands and queries that are not available to clients (only to Acurable administrators)
const superAdmin = newAccessRight('superadmin', { roleLabel: "Acurable administrator", description: "Acurable administrator with full system access rights"});
const admin      = newAccessRight('admin',      { roleLabel: "Administrator",          description: "User with all access rights"});
const anonymous  = newAccessRight('anonymous',  { roleLabel: "Anonymous user",         description: "Anonymous user without access rights"});
const patient    = newAccessRight('patient',    { roleLabel: "Patient impersonator",   description: "Patient impersonator"},  [
  "urn:com:acurable:apsa:role:authentication/User/user_get",
  "urn:com:acurable:apsa:role:diagnosis/Study/study_fill_patient_questionnaire",
  "urn:com:acurable:apsa:role:diagnosis/Study/study_get",
  "urn:com:acurable:apsa:role:administration/HealthcareSite/healthcaresite_get"
]);

const Roles = { 
  permission: {},
  custom: {},
  system: {
    [anonymous.id] : anonymous,
    [admin.id]     : admin,
    [superAdmin.id]: superAdmin,
    [patient.id]   : patient
  },
  urn: permissionId,
};

// Define some aliases for convenience
Roles.superAdmin = Roles.system[superAdmin.id];
Roles.admin      = Roles.system[admin.id];
Roles.anonymous  = Roles.system[anonymous.id];
Roles.patient    = Roles.system[patient.id];

const attributeRightsPermissions = {};
const AttributeRights = {
  // As some fields have spaces we will replace them with a hashtag in order to keep using urns
  to: (attributeRight) => attributeRight.replaceAll(" ", "#"),
  from: (attributeRight) => attributeRight.replaceAll("#", " "),
  urn: (attributeRight, { context, name }) => attributePermissionId(attributeRight, { context, name }),
  isAttributeRight: () => true, // TODO:
  exists: (attributeRight) => attributeRight in attributeRightsPermissions,
  // TODO: it should really be by context and name
  get: (attributeRight) => attributeRightsPermissions[attributeRight],
  all: () => Object.values(attributeRightsPermissions),
};

const withAccessRights = (depends) => (model) => {
  if (depends) {
    depends = Array.isArray(depends) ? depends : [depends];
    const roles = depends.map(d => d.roles).reduce((a, r) => ({ viewers: [...a.viewers, r.viewer.id], managers: [...a.managers, r.manager.id], executors: [...a.executors, r.executor.id] }), { viewers: [], managers: [], executors: [] })
    return withManager(roles.managers)(withExecutor(roles.executors)(withViewer(roles.viewers)(withActionRights(depends)(withAttributeRights(depends)(model)))));
  } else {
    return withManager()(withExecutor()(withViewer()(withActionRights()(withAttributeRights()(model)))));
  }
}

const VIEWER   = 'viewer';
const VIEWER_DESCRIPTION   = (model) => `${require('../i18n').default.resolve(model.label)} ${require('../i18n').default.resolve("read-only permission. User will be able to read any data or execute any query for", {}, { schema: "authentication.Role" })} ${require('../i18n').default.resolve(model.label)}.`;
const EXECUTOR = 'executor';
const EXECUTOR_DESCRIPTION = (model) => `${require('../i18n').default.resolve(model.label)} ${require('../i18n').default.resolve("write/execution-only permissions.", {}, { schema: "authentication.Role" })} ${require('../i18n').default.resolve(model.label)}.`;
const MANAGER  = 'manager';
const MANAGER_DESCRIPTION  = (model) => `${require('../i18n').default.resolve(model.label)} ${require('../i18n').default.resolve("full read and write/execution permissions. User will be able to perform any action for", {}, { schema: "authentication.Role" })} ${require('../i18n').default.resolve(model.label).toLowerCase()}.`;

const unique = (a) => a.filter((r, i) => i === a.indexOf(r))

const withViewer       = (includes=[]) => (model) => withAccessRight(VIEWER, includes, {description: VIEWER_DESCRIPTION(model)})(model);
const withManager      = (includes=[]) => (model) => withAccessRight(MANAGER, includes, {description: MANAGER_DESCRIPTION(model)})(model);
const withExecutor     = (includes=[]) => (model) => withAccessRight(EXECUTOR, includes, {description: EXECUTOR_DESCRIPTION(model)})(model);
const withActionRights = (_depends)    => (model) => {
  (Object.values(model.commands) || []).concat(Object.values(model.queries)  || []).forEach(action => { 
    model = withAccessRight(action.type, [], {label: action.label, description: `${require('../i18n').default.resolve('Permission to', {}, { schema: "authentication.Role" })} ${require('../i18n').default.resolve(action.label, {}, { schema: "authentication.Role" }).toLowerCase()}.`})(model);
  });
  return model;
}
const withAttributeRights = (_depends) => (model) => {
  Object.keys(model.fieldsWithPermissions || {}).forEach(field => {
    model = withAttributeRight(field)(model);
  });
  return model;
}
const withAttributeRight = (attribute) => (model) => {
  const RIGHTS = ["read", "write"];
  RIGHTS.forEach((right) => {
    const attributeRight = attribute + "/" + right;
    model.fieldPermissions = {
      ...model.fieldPermissions,
      [attributeRight]: newAttributeRight(attributeRight, model),
    };
    attributeRightsPermissions[attributeRight] = model.fieldPermissions[attributeRight];
  });
  return model;
}
const withAccessRight  = (acRight, includes=[], {label, description}={}) => (model) => {    
  const isPermission  = acRight !== MANAGER && acRight !== VIEWER && acRight !== EXECUTOR;
  const action        = isPermission ? Object.keys(model.commands || {}).includes(acRight) ? model.commands[acRight] : model?.queries[acRight] : undefined;
  const actions       = acRight === EXECUTOR ? model.commands : acRight === VIEWER ? model.queries : undefined;

  // Build system roles hierarchy
  if (!isPermission) {
    if (acRight === MANAGER) includes = includes.concat([permissionId(VIEWER, model), permissionId(EXECUTOR, model)]);
    if (actions)             includes = includes.concat(Object.values(actions).reduce((permissions, a) => permissions.concat(a.roles.filter(isPermissionURN)), []));
  }

  // Register new access right
  model.roles = {
    ...model.roles,
    [acRight]: (action?.roles?.[0] && Roles.get(action.roles[0])) || newAccessRight(action?.roles?.[0] || acRight, description ? {...model, roleLabel: label, description} : model, includes),  
  };
  
  // Inject default role for each action 
  if (isPermission && action) {
    if (action.roles === undefined) {
      action.roles = [model.roles[acRight].id];
    } else if (Roles.get(model.roles[acRight].id)?.id !== undefined // Action is reusing an existing access right
              && Roles.get(model.roles[acRight].id)?.id !== model.roles[acRight].id // Avoid cases where withDefaults is called more than once for the same model (in those cases Roles.get(model.roles[acRight].id)?.id is not undefined because the role was previously registered)
      ) {  
      delete model.roles[acRight]; // Then the this access right is not part of the model and we delete from this model
    }
  }

  // Update list of roles with new access right
  if (model.roles[acRight] !== undefined) {
    Roles[isPermission ? 'permission' : 'system'][model.roles[acRight].id] = model.roles[acRight];
    if (acRight === MANAGER) {
      Roles.superAdmin.includes = unique((Roles.superAdmin.includes || []).concat(model.roles[acRight].id));
      Roles.admin.includes = unique((Roles.admin.includes || []).concat(model.roles[acRight].id));
    }
  }

  return model;
};

// TODO: not well tested and probably with performance issues @felipe plz review
Roles.viewerFor     = ({context, name}) => Roles.system[roleURN(VIEWER, context, name)]
Roles.executorFor   = ({context, name}) => Roles.system[roleURN(EXECUTOR, context, name)]
Roles.managerFor    = ({context, name}) => Roles.system[roleURN(MANAGER, context, name)]
Roles.parentsOf     = (role) => Roles.all().filter(r => r.id !== role.id && r.includes.includes(role.id)); // TODO should be a Set
Roles.ancestorsOf   = (role) => Roles.parentsOf(role).reduce((ancestors, r) => ancestors.concat(r).concat(Roles.ancestorsOf(r)), []); // TODO should be a Set
Roles.childrenOf    = (role) => (role.includes || []).filter(r => Roles.get(r)).map(r => Roles.get(r)); // TODO should be a Set
Roles.descendantsOf = (role) => Roles.childrenOf(role).reduce((descendants, r) => [...descendants, r, ...Roles.descendantsOf(r)], []); // TODO should be a Set
Roles.isCustomRole  = (role) => (role?.id || role) in Roles.custom || isCustomRoleURN(role?.id || role);
Roles.isSystemRole  = (role) => (role?.id || role) in Roles.system || isSystemRoleURN(role?.id || role);
Roles.isPermission  = (role) => (role?.id || role) in Roles.permission;
Roles.isRole        = (role) => Roles.isSystemRole(role) || Roles.isCustomRole(role);
Roles.isAccessRight = (role) => Roles.isPermission(role) || Roles.isSystemRole(role) || Roles.isCustomRole(role);

const EXPANSIONS = {};
const expandRole = (id) => {
  if (!Roles.get(id)) return [];
  return EXPANSIONS[id] || (EXPANSIONS[id] = (Roles.get(id).includes || []).reduce((expansion, rId) => expansion.concat(expandRole(rId)), Roles.isPermission(id) ? [id] : []));
}
Roles.expand = (roles) => R.uniq((Array.isArray(roles) ? roles.filter(Roles.isAccessRight) : Roles.isAccessRight(roles) ? [roles] : []).reduce((expanded, r) => {
  return expanded.concat(expandRole(r.id || r));
}, []));

Roles.register = (role) => { // TODO: is this only for custom roles
  if (Roles.custom[role.id]) { Roles.ancestorsOf(role).forEach(Roles.register); } // invalidates ancestors' expansions
  Roles.custom[role.id] = role;
  delete EXPANSIONS[role.id]; // so next expandRole invocation don't uses cached values
}

Roles.unregister = (role) => {
  Roles.parentsOf(role).map(r=> ({...r, includes: r.includes.filter(id => id !== role.id)})).forEach(Roles.register);
  delete Roles.custom[role.id];
  delete EXPANSIONS[role.id];
}

let SYSTEM_ROLES  = undefined;
let PERMISSIONS   = undefined;
const loadSystemRolesAndPermissions = () => { 
  require('../model').all(); 
  
  SYSTEM_ROLES = Object.values(Roles.system); 
  PERMISSIONS = Object.values(Roles.permission);

  return Roles;
}
Roles.customRoles = () => Object.values(Roles.custom);
Roles.systemRoles = () => SYSTEM_ROLES || loadSystemRolesAndPermissions().systemRoles();
Roles.roles       = () => Roles.customRoles().concat(Roles.systemRoles());
Roles.permissions = () => PERMISSIONS  || loadSystemRolesAndPermissions().permissions();
Roles.all         = () => Roles.roles().concat(Roles.permissions());
Roles.get         = (id) => Roles.permission[id] || Roles.system[id] || Roles.custom[id];

const rolesListeners = [];
Roles.onUpdate = cb => {rolesListeners.push(cb); cb(); return _ => rolesListeners.splice(rolesListeners.findIndex(c => c === cb), 1);}
Roles.update = (role, remove) => {
  if (!role) return;
  // console.debug(`[Roles] ${remove ? 'Deleting' : 'Updating'} custom roles `, role);
  remove ? Roles.unregister(role) : Roles.register(role);
  rolesListeners.forEach(onUpdate => onUpdate());
}

Roles.setCustomRoles = (roles=[]) => {
  // console.debug("[Roles] Setting custom roles ... ", roles);
  Roles.customRoles().forEach(Roles.unregister);
  roles.forEach(Roles.register);
}

Roles.refresh = async () => { // TODO: ugly ... find a way to subscribe, does not apepear to work ? so all cloud functions update when roles change ??
  const { Role } = require('../model/authentication/model');

  require('../model').all();

  // make sure Roles are updates properly ... TODO: there must be a better way
  // only required for User commands ?
  return require('../snapshot').snapshotsFrom(Role)
    .then(snaps => snaps.map(s => s.data))
    .then(roles => Roles.setCustomRoles(roles));
}

// Can be improved with a cache, as some accessRights might be called multiple times
const removePermissionsFromAccessRight = (accessRight, permissionURNsToRemove, updatedAccessRights) => {
  const children = Roles.childrenOf(accessRight);
  if (children.length === 0) {
    if (permissionURNsToRemove.has(accessRight.id)) {
        updatedAccessRights.delete(accessRight.id);
        return {
          updatedAccessRightsURNs: [],
          edited: true
        };
    }
    return {
      updatedAccessRightsURNs: [accessRight.id],
      edited: false
    };
  }

  const updatedChildrenURNs = [];
  let editedChildren = false;
  for (const child of children) {
    const {
      updatedAccessRightsURNs,
      edited
    } = removePermissionsFromAccessRight(child, permissionURNsToRemove, updatedAccessRights);
    updatedAccessRightsURNs.forEach(urn => updatedChildrenURNs.push(urn));
    if (edited) {
      editedChildren = edited;
    }
  }

  if (editedChildren) {
    updatedAccessRights.delete(accessRight.id);
    updatedChildrenURNs.forEach(urn => updatedAccessRights.add(urn));
    return {
      updatedAccessRightsURNs: updatedChildrenURNs,
      edited: true
    };
  }
  return {
    updatedAccessRightsURNs: [accessRight.id],
    edited: false
  };
};

export const breakdownAccessRightsToSet = (accessRights, set) => {
  return accessRights.reduce((acc, accessRight) => {
    acc.add(accessRight.id);
    return breakdownAccessRightsToSet(Roles.childrenOf(accessRight), acc);
  }, set || new Set());
};

const mergeAccessRightsToSet = (accessRightsURNs, set) => {
  for (const accessRightURN of accessRightsURNs) {
    set.add(accessRightURN);
    const accessRightsURNsToRemove = breakdownAccessRightsToSet(Roles.childrenOf(Roles.get(accessRightURN)));
    [...accessRightsURNsToRemove].forEach(urn => set.delete(urn));
  }
};

// It performs insertions first so that if a permission from an insertion is meant to be removed it will be removed
Roles.modifyAccessRights = (accessRightsURNs = [], accessRoleURNsToAdd = [], accessRolesURNsToRemove = []) => {
    // Important to filter so that deleted permissions don't cause errors
    const updatedAccessRights = new Set(accessRightsURNs.filter(e => Roles.get(e)));

    // Optimized removal to allow breaking down and removing roles
    const accessRightsURNsToRemove = breakdownAccessRightsToSet(accessRolesURNsToRemove.map(ar => Roles.get(ar)));
    for (const accessRightURN of [...updatedAccessRights]) {
      removePermissionsFromAccessRight(Roles.get(accessRightURN), accessRightsURNsToRemove, updatedAccessRights);
    }

    // Optimized insertion to allow adding roles without having to add all its permissions (also removes junk/repeated permissions)
    mergeAccessRightsToSet(accessRoleURNsToAdd, updatedAccessRights);

    return [...updatedAccessRights];
};

export { 
  Roles,
  AttributeRights,
  withAccessRights, // TODO: deprecate
};

