import { useProducts } from "features/hooks/useProducts";
import Action from "ui/components/Action";
import Details from "ui/components/Details";
import DetailsBlock from "ui/components/DetailsBlock";
import ValidatedInput from "ui/components/Login/Components/ValidatedInput";
import { Text } from 'ui/components/Text';
import useForm from "ui/hooks/useForm";
import { FeatureToggleSettings, FeatureToggleTypes } from "features/preferences/FeatureToggleSettings";
import { StudySleepReportSettings } from "features/preferences/StudySleepReportSettings";

export const ProductForm = ({ defaultProduct, onClose }) => {
    const {
        form,
        onFormChange,
        onError,
        errors
    } = useForm(defaultProduct);
    const isEdit = !!defaultProduct;
    const {
        getValidationSchema
    } = useProducts();
    const validationSchema = getValidationSchema(isEdit);
    return (
        <>
            <div className="pageContent" id="productForm">
                <div className="pageSection">
                    <div className="title">
                        <Text>details</Text>
                    </div>
                    <DetailsBlock>
                        <div className="grid-col">
                            <Details id="key" label="module.administration.fe.manageProducts.key" mandatory>
                                {
                                    isEdit ? defaultProduct?.key : (
                                        <ValidatedInput
                                            disabled={isEdit}
                                            schema={validationSchema}
                                            showError={errors?.key && !isEdit}
                                            onValidate={(...args) => console.log("ProductForm onValidate", args) || onError(...args)}
                                            name="key"
                                            onChange={onFormChange}
                                            value={form?.key}
                                        />
                                    )
                                }
                            </Details>
                        </div>
                    </DetailsBlock>
                </div>
            </div>
            <div className="pageFooter">
                <div className='actions'>
                    <Action className='button'
                        id="back" handleAction={() => onClose()}
                        label='cancel' />
                </div>
            </div>
            {isEdit && (<>
                <FeatureToggleSettings ownerId={form?.id}
                    accessTo={FeatureToggleTypes.AllFeatures} />
                <StudySleepReportSettings ownerId={defaultProduct.id} />
            </>
            )}
        </>
    );
};