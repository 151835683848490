import DetailsBlock from 'ui/components/DetailsBlock';
import HelpIcon from '@atlaskit/icon/glyph/question-circle';
import InfoIcon from '@atlaskit/icon/glyph/info';
import ReactTooltip from 'react-tooltip';
import {Text} from 'ui/components/Text';
import useSnapshot from 'ui/hooks/useSnapshot';
import { Study } from 'services/server/functions/model/diagnosis/model';

import './style.css';

const ViewScoring = ({ test }) => {
  const NumberFormatter = new Intl.NumberFormat(undefined, { signDisplay: 'exceptZero' });
  const Marker = Study.entities.Test.entities.Marker;

  const [_markers ] = useSnapshot(Marker, {owner: test?.id, autoStart: Boolean(test?.id)})
  const markers = Object.values(_markers || {}).map(m => m.data);
  const scoring = Marker.toManualScore(markers, test?.recording?.diagnosableTime)
  
  if (scoring === undefined) {
    return <div className="pageContent" id="ViewScoring"><Text>This report is not available</Text></div>
  }

  return (
    <div className="pageContent" id="ViewScoring">
      <div className="pageSection">
        <div className="title"><Text>ViewTest.reports.scoring.title</Text></div>
        <DetailsBlock className="reportCard">
          <div className="grid-col diagnosisHeader">
            <div className="th centered inline"><span><Text>ViewTest.diagnosis.index</Text></span><div data-tip data-for="indexHelp" className="help"><HelpIcon size="small"/></div></div>
            <span className="th centered" style={{ flexShrink: 2 }}><Text>ViewTest.reports.scoring.number-hour</Text></span>
          </div>
          <div className="horizontal-line"/>
          <div className="odi3Rows">
            { Object.entries(scoring).map(([label, value]) => (
              <div key={label} className={`grid-col centered vcentered indexRow`} >
                <span className="label"><Text>{`ViewTest.reports.scoring.${label}.changes.label`}</Text></span>
                <div className="grid-col vcentered indexQuantity" style={{ flexShrink: 2 }}>
                  <span className="value">{NumberFormatter.format(value)}</span>
                </div>
              </div>
            ))}
          </div>
        </DetailsBlock>

        <ReactTooltip className="HelpTooltip" id="indexHelp" place='right' type="light" effect="solid" border multiline>
          <div id="IndexHelp" className="grid-row">
            <div className="inline">
              <span className="indexDescription"><Text>ViewTest.reports.scoring.help.index</Text></span>
            </div>
            <div className="inline"></div>
            <div className="grid-col footnote">
              <div className="icon"><InfoIcon /></div>
              <div>
                <div className="note">
                  <Text>ViewTest.reports.scoring.help.index.note</Text>
                </div>
              </div>
            </div>
          </div>
        </ReactTooltip>
      </div>
    </div>
  );
}

ViewScoring.displayName = "ViewScoring";
export default ViewScoring;