import { FORBIDDEN_POLICY, VALID_DATA_POLICY } from "../../policies";
import { Roles, AttributeRights } from "../../../iam/roles";
import { resolve } from "../..";
import ProductStudy from "./ProductStudy";

const Product = () => require(".").Product;
const HealthcareSite = () => require(".").HealthcareSite;
const Organisation = () => require(".").Organisation;

export const checkProducts = async (org, executor) => {
    const promises = (org.products || []).map(async (productId) => executor.execute(Product().queries.ID_EXISTS.newRequest({ id: productId })));

    const results = await Promise.all(promises);
    if (results.some(e => !e)) {
        throw VALID_DATA_POLICY("Can not assign products that do not exist");
    }
}

export const checkProductsSubset = async (data, orgaUnitSnap, executor) => {
    if (!HealthcareSite().isReference(orgaUnitSnap.aggregate.id)) return;
    const ownerId = Organisation().ownersFrom(orgaUnitSnap.data)[0];
    const orgaSnap = await executor.execute(Organisation().queries.GET.newRequest({ id: ownerId }));
    const availableProducts = orgaSnap.data.products || [];
    if (data.products.some(pId => !availableProducts.includes(pId))) {
        throw VALID_DATA_POLICY(`Can not assign product that is not available to the parent organisation`);
    }
};

export const checkProductsStudyConfigSettings = async (data, orgaUnitSnap, executor) => {
    const productsToCheck = (data.products || []).filter(pId => !(orgaUnitSnap.data.products || []).includes(pId));
    let promises;
    if (HealthcareSite().isReference(data.id)) {
        const { getOwnerProduct } = require("../../../executor/handlers/administration/ProductOwners");
        promises = productsToCheck.map(async (productId) => {
            const productPreferences = await executor.execute(Product().queries.GET_INHERITED_PREFERENCES.newRequest({ ownerId: HealthcareSite().entities.Product.newURN(HealthcareSite().id(data.id), Product().id(productId)), preferenceId: "StudyConfigSettings" }));
            const productOwnerSnap = await getOwnerProduct(data.id, productId);
            let hasAttributeRights = false;
            if (productOwnerSnap) {
                const productModel = resolve(productOwnerSnap.aggregate.id);
                const productSnap = await executor.execute(productModel.queries.GET.newRequest({ id: productOwnerSnap.aggregate.id }));
                const studyConfigSettingsAttributeRights = ProductStudy.schemas.StudyConfigSettings.attributeRights;
                hasAttributeRights = productSnap.data.attributeRights?.some(ar => studyConfigSettingsAttributeRights.includes(ar));
            }

            if (Object.keys(productPreferences || {}).length === 0 && !hasAttributeRights) throw VALID_DATA_POLICY(`Can not assign product ${Product().id(productId)} without Study Configuration Settings`);
        });
    } else {
        promises = productsToCheck.map(async (productId) => {
            const settingsSnap = await executor.execute(Product().entities.Preferences.queries.GET.newRequest({ id: Product().entities.Preferences.newURN(Product().id(productId), "StudyConfigSettings") }));
            const productSnap = await executor.execute(Product().queries.GET.newRequest({ id: productId }));
            const studyConfigSettingsAttributeRights = ProductStudy.schemas.StudyConfigSettings.attributeRights;
            const hasAttributeRights = productSnap.data.attributeRights?.some(ar => studyConfigSettingsAttributeRights.includes(ar));

            if (Object.keys(settingsSnap?.data?.defaults || {}).length === 0 && !hasAttributeRights) throw VALID_DATA_POLICY(`Can not assign product ${Product().id(productId)} without Study Configuration Settings`);
        });
    }

    await Promise.all(promises);
};

const isRootNode = (orgaUnitSnap) => {
    return orgaUnitSnap.metadata.allOwners.length < 3;
};

export const checkIncludesProduct = async (orgaUnitSnap, productId, executor) => {
    const products = orgaUnitSnap.data.products || [];
    if (products.length === 0 && !isRootNode(orgaUnitSnap)) {
        const ownerId = orgaUnitSnap.data.owners[0];
        const ownerModel = resolve(ownerId);
        const ownerSnap = await executor.execute(ownerModel.queries.GET.newRequest({ id: ownerId }));
        return await checkIncludesProduct(ownerSnap, productId, executor);
    }

    if (!products.includes(productId)) throw VALID_DATA_POLICY(`The product ${Product().id(productId)} is not assigned to the given resource ${orgaUnitSnap.aggregate.id}`);
    return undefined;
};

export const checkDuplicatedKey = async (key, executor) => {
    const productsWithKey = await executor.execute(Product().queries.LIST.newRequest({ where: { field: "data.key", operator: "==", value: key } }));
    if (productsWithKey.length > 0) {
        throw FORBIDDEN_POLICY("Can not create a product with the given key");
    }
}

export const checkAccessRights = (product) => {
    for (const accessRight of product.accessRights) {
        if (!Roles.isAccessRight(accessRight)) {
            throw FORBIDDEN_POLICY("The input includes invalid access rights");
        }
        if (!Roles.get(accessRight)) {
            throw VALID_DATA_POLICY("Can not assign invalid roles");
        }
    }
}

export const checkAttributeRights = (product) => {
    for (const attributeRight of product.attributeRights) {
        if (!AttributeRights.isAttributeRight(attributeRight)) {
            throw FORBIDDEN_POLICY("The input includes invalid attribute rights");
        }
        if (!AttributeRights.exists(attributeRight)) {
            throw VALID_DATA_POLICY("Can not assign invalid attribute right");
        }
    }
}

export const checkHCSReset = (data) => {
    const model = resolve(data.id);
    if (model.name === HealthcareSite.name && !data.reset) throw FORBIDDEN_POLICY("HealthcareSite data can not be updated, only reset");
};