import { useRef, useState, useEffect, useCallback } from 'react';
import { DataBase } from 'services/server/functions/firebase';
import useCurrentUser from './useCurrentUser';
import useEffectOnMount from './useEffectOnMount';

export default function useEvents(inputFilters={}, options={}) {
    const {onReady: clientOnReady=() => {}, autoStart=true, minDate: clientMinDate, maxDate: clientMaxDate} = options;
    const [events, setEvents] = useState([]);

    const [currentUser] = useCurrentUser();

    const [loading, setLoading] = useState(Boolean(autoStart));

    const subscriptions = useRef();
    const filters = useRef();
    const minDate = useRef();
    const maxDate = useRef();

    const cancelSubscriptions = useCallback(_ => {
        //console.debug(new Date(), "Cancelling subscription to events");
        if (subscriptions.current) subscriptions.current.forEach(s => s.unsubscribe());
        filters.current = {};
        subscriptions.current = undefined;
        setEvents([]);
        setLoading(Boolean(autoStart));
    }, [subscriptions.current, filters.current]);

    const startSubscriptions = _ => {
        if (subscriptions.current) return;
        filters.current = inputFilters;
        minDate.current = clientMinDate?.getTime();
        maxDate.current = clientMaxDate?.getTime();

        //console.debug(new Date(), "Starting subscriptions for events", filters.current)

        const onHookReady = _ => {
            //console.debug(new Date(), "useEvents:onHookReady", Boolean(subscriptions.current))
            if (!subscriptions.current) return false;
            setLoading(false);
            clientOnReady();
            return true;
        };

        const pushEvents = (evts) => {
            //console.debug(new Date(), "useEvents:pushEvents", Boolean(subscriptions.current), evts.length)
            if (!subscriptions.current) return false;
            
            setEvents(prevEvts => prevEvts.concat(evts));
            return true;
        }

        const hasAccess = currentUser?.isSuperAdmin;
        
        if (hasAccess) {
            let queries = Object.entries(filters.current).reduce((all, [collection, filters]) => {
                filters.forEach(filter => all.push(filter.reduce((q, [field, op, value]) => q.and(field, op, value), DataBase().events(undefined, collection).asQuery())));
                return all;
            }, []);
            if (minDate.current) queries = queries.map(q => q.and('timestamp', '>=', minDate.current));
            if (maxDate.current) queries = queries.map(q => q.and('timestamp', '<=', maxDate.current));
            
            subscriptions.current = queries.map((query, _idx) => /*console.debug(new Date(), `Start loading events in background`, query.toString()) || */query
                .onCreated(pushEvents)
                .onReady  (onHookReady)
                .onError  (reason => subscriptions.current && console.error("useEvents error:", reason))
                .subscribe());

            window.addEventListener("beforeunload", cancelSubscriptions); // Cancel subscriptions on tab closed
            require('services/iam').onSignout(cancelSubscriptions);
        }
    };

    const resetSubscriptions = useCallback(_ => {
        //console.debug(new Date(), "Reset subscription to events");
        cancelSubscriptions();
        startSubscriptions();
    }, [cancelSubscriptions, startSubscriptions, subscriptions.current]);

    useEffect(_ => {
        const filtersChanged = (minDate.current && clientMinDate?.getTime() !== minDate.current) 
                               || (maxDate.current && clientMaxDate?.getTime() !== maxDate.current)
                               || (filters.current && Object.entries(inputFilters).some(([col, colFilters]) => colFilters.toString() !== filters.current?.[col]?.toString()));
        
        //console.debug(new Date(), "[useEvents] Checking subscription status filters=", inputFilters, "vs", filters.current, "date range=", clientMinDate, "vs", minDate.current, "-", clientMaxDate, "vs", maxDate.current , "auto start=", autoStart, "changed=", filtersChanged)
        if (filtersChanged) resetSubscriptions();
        else if (!subscriptions.current && autoStart) startSubscriptions();
    }, [JSON.stringify(inputFilters), clientMinDate?.getTime(), clientMaxDate?.getTime()]);
    useEffectOnMount(_ => cancelSubscriptions);

    return [events.sort((e1, e2) => e1.seq > e2.seq ? 1 : e1.seq < e2.seq ? -1 : 0), loading, startSubscriptions, cancelSubscriptions, resetSubscriptions];
}