import { useContext, useEffect } from 'react';
import { UserContext } from 'features/providers/userContextProvider';
import i18n from 'services/server/functions/i18n';
import Spinner from '@atlaskit/spinner'
import pdfobject from "pdfobject";
import Back from 'ui/components/Back';
import {Text} from 'ui/components/Text';

import "./style.css";

const getPdfUrls = async (callback) => {
  const firebase = await import('services/firebase');
  firebase.Storage().getBucketAssets({
      path: i18n.resolve('module.diagnosis.fe.userManual.pdf_url', { lang: i18n.current }),
      defaultPath: i18n.resolve('module.diagnosis.fe.userManual.pdf_url', { lang: 'en' }),
      callback
  });
};

export default () => {
  const { i18nProductKey } = useContext(UserContext);
  useEffect(() => {
    getPdfUrls(pdfURL => 
      pdfobject.embed(pdfURL, "#pdf", {
        pdfOpenParams: {
          navpanes: 0,
          toolbar: 0,
          statusbar: 0,
          view: "FitV",
          page: 1,
          zoom: "page-fit"
        },
        forcePDFJS: true,
        PDFJS_URL: "/web/viewer.html"
      })
    )
  }, [i18n.current, i18n.product, i18nProductKey])

  return <div id="UserManual" className="page" style={{marginBottom: 0}}>
    <Back/>
    <div className="pageHeader">
      <h1 className="title"><Text>Menu.user-manual</Text></h1>
    </div>
    <div className="pageContent">
      <div id="pdf"><Spinner size="xlarge"/></div>
    </div>
  </div>;
}  