import Details from "ui/components/Details";
import FindCalendar from 'ui/components/Calendar';
import { calendarRangeProps, getCalendarContainer } from "ui/components/Calendar/CalendarContainer";
import { useCallback } from "react";
import { FiltersType, handleFieldDates } from "../../utils";

export const DateConductedField = ({ filters, onFilterChange, disabled, help }) => {
    const [dateConductedStart, dateConductedEnd] = filters[FiltersType.DateConducted] || [];

    const handleOption = useCallback((option) => {
        handleFieldDates(
            {
                filterType: FiltersType.DateConducted, option, onFilterChange, filters
            }
        );
    }, [onFilterChange]);
    return (
        <Details id="dateConductedDetails" label="global.date_conducted" help={help}>
            <FindCalendar
                id={FiltersType.DateConducted}
                startDate={dateConductedStart}
                endDate={dateConductedEnd}
                handleChange={handleOption}
                disabled={disabled}
                {...calendarRangeProps}
                calendarContainer={getCalendarContainer({
                    id: FiltersType.DateConducted,
                    onSelectRange: (_, option) => handleOption(option)
                })}
            />
        </Details>
    );
};