import { INVALID_LOGIN, LOGIN, LOGOUT, UPDATE_USER, LOGIN_ERROR, UPDATE_ACTIVITY } from '../../actions/Authentication';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import impersonation from './impersonation';

const initialState = {
  invalidLogin  : false,
  authenticating: false,
  lastActivity  : undefined,
  currentUser   : undefined
};

function login(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACTIVITY: {
      window.dispatchEvent(new CustomEvent('activity', { detail: action }));
      return (state.currentUser !== undefined && state.lastActivity !== undefined) ? {
        ...state,
        lastActivity: action.timestamp
      } : state;
    }
    case LOGIN: {
      return {
        ...state, 
        invalidLogin  : false,
        authenticating: true
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        authenticating: state.authenticating && !action.user,
        lastActivity  : (state.lastActivity === undefined && Boolean(action.user)) ? Date.now() : state.lastActivity,
        currentUser   : action.user
      };
    }
    case LOGOUT: {
      return (state.authenticating || state.lastActivity !== undefined || state.currentUser !== undefined) ? {
        ...state,
        authenticating: false,
        lastActivity  : undefined,
        currentUser   : undefined
      } : state;
    }
    case INVALID_LOGIN: {
      return {
        ...state,
        invalidLogin  : true,
        authenticating: false
      };
    }
    case LOGIN_ERROR: {
      return (state.authenticating || state.invalidLogin) ? {
        ...state,
        invalidLogin  : false,
        authenticating: false
      } : state;
    }
    default:
      return state;
  }
}

export default (storage) => combineReducers({
  login: persistReducer({ key: 'login', storage, blacklist: ['invalidLogin', 'authenticating'] }, login),
  impersonation
});
