import { useEffect, useState, useContext } from 'react';
import { Text } from 'ui/components/Text';
import { Study } from 'services/server/functions/model/diagnosis/model';
import asyncComponent from 'ui/components/AsyncComponent';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import Spinner from '@atlaskit/spinner';

const AsyncEdfBrowser = asyncComponent(
  () => import(/* webpackChunkName: "EdfBrowser" */  '../../components/EdfBrowser'), 
  () => <div className="centered grid-row"><div><Spinner size="large"/></div><span><Text>ViewTest.signals.loading-component</Text></span></div>
);

const EdfBrowser = ({ owner, fullScreenModeEnabled=true, showPreview=true, test, signals, addMarker, editMarker, removeMarker, onError, ...props }) => {
  const [currentUser]               = useCurrentUser(), canViewSignalsVisualisationTool = currentUser.hasAccess(Study.entities.Test.queries.VIEW_SIGNALS_VISUALISATION_TOOL);
  const [staticMode, setStaticMode] = useState(showPreview), toggleStaticMode = () => setStaticMode(!staticMode);
  
  const pageNav = useContext(require('ui/acukit/Page').PageNavContext);
  
  useEffect(_ => {
    if(fullScreenModeEnabled && !staticMode) {
      pageNav.enterFullScreen(); 
      return pageNav.exitFullScreen; 
    }
  }, [fullScreenModeEnabled, staticMode]);

  return (
    test?.report?.signalsFile ?
        <AsyncEdfBrowser 
          owner={owner}
          test                    = {test}
          signals                 = {signals}
          showMarkers             = {currentUser.hasAccess(Study.entities.Test.entities.Marker.queries.LIST)}
          onNewMarker             = {currentUser.hasAccess(Study.entities.Test.entities.Marker.ADD_MARKER) ? addMarker : undefined}
          onEditMarker            = {currentUser.hasAccess(Study.entities.Test.entities.Marker.EDIT_MARKER) ? editMarker : undefined}
          onRemoveMarker          = {currentUser.hasAccess(Study.entities.Test.entities.Marker.REMOVE_MARKER) ? removeMarker : undefined}
          navigationEnabled       = {!staticMode}
          onNavigationModeChanged = {canViewSignalsVisualisationTool ? toggleStaticMode : undefined}
          onError                 = {onError}
          {...props}
        /> : 
        <Text>This report is not available</Text>
  )
};

EdfBrowser.displayName= "EdfBrowser";
export default EdfBrowser;