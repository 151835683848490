import { Fragment, useState, useEffect } from 'react';

import { Contact } from 'modules/support';
import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Table from 'ui/components/Table';
import { Text } from 'ui/components/Text';
import useLocationParams from 'ui/hooks/useLocationParams';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

const faqUI = ({
    locState,
    fQuestions = [],
    clearSelected,
    openQuestion,
    onQuestionChangedHandler,
    ...otherProps
}) => {
    require('./style.css');
    const FAQ =
        locState.selected &&
        (fQuestions.find((faq) => faq.id === locState.selected) || {})
            .component;
    const { assetsURl } = useAssetsURL();

    return (
        <div id="FAQ" className="page">
            {FAQ && <Back handleHistory={false} onClick={clearSelected} />}
            <div className="pageHeader">
                <h1 className="title wraptext">
                    <Text>Help.title</Text>
                </h1>
            </div>
            <div className="pageContent">
                {FAQ ? (
                    <Fragment>
                        <h2>{FAQ.question}</h2>
                        <FAQ {...otherProps} />
                        <div className="actions">
                            <Action
                                label="back"
                                className="button"
                                handleAction={clearSelected}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="pageSection grid-col">
                            <div className="filter grid-row">
                                <div>
                                    <span>
                                        <Text>Help.text</Text>
                                    </span>
                                    <br />
                                    <span>
                                        <Text>Help.still-questions-1</Text>{' '}
                                        <Contact.AsLink
                                            id="contactLink"
                                            text="Help.key-admin"
                                        />{' '}
                                        <Text>Help.still-questions-2</Text>.
                                    </span>
                                </div>
                                <input
                                    name="fQuestion"
                                    placeholder={Text.resolve(
                                        'Help.enter-question'
                                    )}
                                    onChange={(e) =>
                                        onQuestionChangedHandler(e.target.value)
                                    }
                                    value={locState.typedQuestion || ''}
                                />
                            </div>
                            <div className="sideImage">
                                <img
                                    src={getImgUrl(
                                        assetsURl,
                                        'doctor-question.png'
                                    )}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="pageSection">
                            <Table
                                keyField="id"
                                columns={{
                                    question: { content: 'Help.question' },
                                }}
                                onRowClick={(q) => openQuestion(q.id)}
                                data={fQuestions}
                                defaultSortCol={'id'}
                                defaultSortDir={'asc'}
                                emptyText="No questions found"
                            />
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default (props) => {
    const similarity = require('string-similarity');

    const [currentUser] = useCurrentUser();
    const [{ selected, typedQuestion }, setLocationState] = useLocationParams({
            selected: undefined,
        }),
        openQuestion = (id) => setLocationState({ selected: id }),
        clearSelected = (_) => setLocationState({ selected: undefined });

    const faqs = Object.values(require('./Entries')).map((component, idx) => ({
        id: `Q${idx}`,
        component,
        question: component.question,
    }));
    const [typeTimeout, setTypeTimeout] = useState(undefined);
    const [similScores, setSimilScores] = useState(undefined);

    useEffect((_) => {
        if (typedQuestion)
            setSimilScores(
                similarity.findBestMatch(
                    typedQuestion.toLowerCase(),
                    faqs.map((f) => f.question.toLowerCase())
                )
            );
    }, []);

    const byRole = ({ component }) => currentUser.hasAccess(component);
    // TODO: Look for FAQs that match content and not only the question itself
    // Note: I think this metric is good enough for this UC, but can use word embeddings for example to check synonyms and have better similarity meassure in general
    const bySimilarity = (_, idx) =>
        !typedQuestion ||
        !similScores ||
        similScores.ratings[idx].rating >
            similScores.bestMatch.rating -
                (1 - similScores.bestMatch.rating) *
                    similScores.bestMatch.rating *
                    0.5;

    const onQuestionChangedHandler = (input) => {
        if (typeTimeout) clearTimeout(typeTimeout);
        setLocationState({ typedQuestion: input });
        setTypeTimeout(
            setTimeout(
                (_) =>
                    setSimilScores(
                        input &&
                            similarity.findBestMatch(
                                input.toLowerCase(),
                                faqs.map((f) => f.question.toLowerCase())
                            )
                    ),
                500
            )
        );
    };

    const fQuestions = faqs.filter((faq, idx) =>
        [byRole, bySimilarity].every((filter) => filter(faq, idx))
    );

    return faqUI({
        ...props,
        locState: { selected, typedQuestion },
        fQuestions,
        clearSelected,
        openQuestion,
        onQuestionChangedHandler,
    });
};
