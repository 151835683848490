import { useState, useEffect } from 'react';

import 'ui/theme/find.css';

import * as Pages from 'modules/administration';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Organisation, isFromRoot } from 'services/server/functions/model/administration/model';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import WithSnapshot from 'ui/components/WithSnapshot';
import history from 'history.js';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import { ManageProducts, ManageProductsAction } from 'features/administration/components/ManageProducts';

const FindOrganisationUI = ({ nameOrUID, organisations, onNameOrUIDChangedHandler, I18n, currentUser }) => {
  const [showFindProduct, setShowFindProduct] = useState(false);
  const [locState] = useLocationParams();
  const showOrganisationPage = () => setShowFindProduct(false);
  useEffect(() => {
    const hasEmptyState = Object.keys(locState).length === 0;
    if (hasEmptyState) {
      showOrganisationPage();
    }
  }, [locState]);
  const findOrganisationBlock = (
    <>
      <div className='pageHeader'>
        <h1 className='title'>{I18n('global.organisation.label-plural')}</h1>
        <div className='actions'>
          {currentUser.hasAccess(Pages.CreateOrganisation, {debug: true}) && <Pages.CreateOrganisation.AsLinkButton text="create" className="primary" />}
          {currentUser.isSuperAdmin() && <ManageProductsAction onAction={() => setShowFindProduct(true)} />}
        </div>
      </div>
      <div className="pageContent">
        <div className="pageSection">
          <div className='title'>{I18n('search-by')}</div>
          <DetailsBlock>
            <div className='filter'>
              <input id="Organisation.nameOrUID" placeholder={Text.resolve("search-by-tip")} onChange={(e) => onNameOrUIDChangedHandler(e.target.value)} value={nameOrUID || ''} />
            </div>
          </DetailsBlock>
        </div>
        <div className="pageSection">
          <Table
            keyField='id'
            columns={{
              name: { content: "global.name", isSortable: true },
              uid: { content: "unique-id", isSortable: true },
              address: { content: "address" }
            }}
            onRowClick={((org) => history.push(Pages.ViewOrganisation.PATH, { organisation: org.id }))}
            data={organisations}
            defaultSortCol={'name'}
            defaultSortDir={'asc'}
            emptyText={I18n("no-orgs-found")}
          />
        </div>
      </div>
    </>
  );

  return <div id='FindOrganisation' className='page'>
    {showFindProduct ?
      <ManageProducts header={<Back
        handleHistory={false}
        onClick={showOrganisationPage} />} />
      :
      <>
        <Back />
        {findOrganisationBlock}
      </>
    }
  </div>
};

const FindOrganisationPresenter = (props) => {
  const [currentUser] = useCurrentUser();
  const [{ nameOrUID, organisation }, setLocationState] = useLocationParams();
  
  const byNameOrUID       = ({name, uid})     => nameOrUID       ? `${name} ${uid}`.toLowerCase().includes(nameOrUID.toLowerCase())  : true;
  const byOrganisation    = ({id})            => organisation    ? id === organisation  : true;
  const toTable           = ({id, name, uid, address}) => ({id, name, uid, address});

  const organisations = Object.values(props.snapshots)
                              .map(o => o.data)
                              .filter(o => isFromRoot(currentUser) || currentUser.metadata.allOwners.includes(o.id))
                              .filter(byNameOrUID)
                              .filter(byOrganisation)
                              .map(toTable);

  const setNameOrUID = (nameOrUID) => setLocationState({ nameOrUID })
  return FindOrganisationUI({
    organisations,
    nameOrUID,
    onNameOrUIDChangedHandler: setNameOrUID,
    I18n                     : props.I18n,
    createProduct            : props.createProduct,
    currentUser,
  })
};

const FindOrganisation = WithSnapshot(Organisation)(FindOrganisationPresenter);

FindOrganisation.displayName = "FindOrganisation";
export default FindOrganisation;