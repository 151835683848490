import { UserContext } from "features/providers/userContextProvider";

import { useCallback, useContext, useEffect, useMemo } from "react";
import {  TreeSelect  } from "antd";

import { Text } from "ui/components/Text";
import {SelectorTitle} from "../ContextOptionsBlock";
import { equals } from "ramda";
import useCurrentUser from "ui/hooks/useCurrentUser";
import { HealthcareIcon, OrganisationIcon } from "ui/acukit/Icon";

const buildTreeData = (units) => {
    const rootIds = new Set(units.map(u => u.data.id));

    const tree = units.reduce((acc, curr) => {
        const isOrganisation = curr.data.id.includes('/Organisation/'); // ugly: once organisational units get implemented this info should come in a 'type' field (atm only possib le types are Organisation or HealthcareSite)
        acc[curr.data.id] = {
            id: curr.data.id,
            value: curr.data.id, 
            key: curr.data.id, 
            icon: isOrganisation ? <OrganisationIcon /> : <HealthcareIcon />,
            title: `${Text.resolve(isOrganisation ? "global.organisation.label": "hcs")}: ${curr.data.name}`,
            children: [],
        };
        return acc;   
    }, {});
    
    units.forEach((unit) => {
        if (unit.data.parent && unit.data.parent in tree) {
            tree[unit.data.parent].children.push(tree[unit.data.id]);
            rootIds.delete(unit.data.id);
        }
    });

    return Object.values(tree).filter((item) => rootIds.has(item.id));
}

export default ({
    value,
    selectedProduct,
    defaults: userDefaults,
    name='owners',
    placeholder=Text.resolve("global.placeholder.select"),
    label,
    onChange
}) => {
    const {
        getProductUnits, selectedOwners: contextSelectedOwners, loading
    } = useContext(UserContext);

    const [user] = useCurrentUser({skipContext: true});

    const units = getProductUnits(selectedProduct);

    const inputSelectedOwners = value !== undefined ? [...value].sort() : contextSelectedOwners;
    const selectedOwners = inputSelectedOwners.filter(o => units.find(unit => unit.data.id === o));
    const defaults = [...(userDefaults !== undefined ? userDefaults : (user.data?.owners || []))].sort();
    const onOwnersChange = useCallback(newOwners => onChange !== undefined && onChange({target: {name, type: 'select-multiple', selectedOptions: newOwners}}), [onChange]);
    
    const options = useMemo(_ => units.reduce((opts, o) => {
        const isOrganisation = o.data.id.includes('/Organisation/'); // ugly
        opts.push({
            group: Text.resolve(isOrganisation ? "global.organisation.label-plural": "hcss"), 
            itemGroupLabel: Text.resolve(isOrganisation ? "global.organisation.label": "hcs"), 
            label: o.data.name, value: o.data.id});
        return opts;
    }, []),
    [units?.map(o => o.data.id).join(';'), selectedOwners?.join(';')]);
    const treeData = useMemo(_ => loading ? [] : buildTreeData(units), [loading, units?.map(o => o.data.id).join(';')]);

    const getSelectedOptions = useCallback(_ => options.filter(o => selectedOwners.find(s => s === o.value)).map(o => ({...o, label: `${o.itemGroupLabel}: ${o.label}`})), [selectedOwners?.join(';'), options?.map(o => o.value).join(';')]);

    useEffect(_ => {
        if (selectedProduct?.id) {
            if (selectedOwners.length !== inputSelectedOwners.length) onOwnersChange(getSelectedOptions());
        }
    }, [inputSelectedOwners.join(';'), selectedOwners.join(';'), selectedProduct?.id])

    const onReset = _ => onOwnersChange(defaults.map(o => options.find(opt => opt.value === o)).filter(Boolean));
    const onSelectChange = useCallback(selections => onOwnersChange(!selections ? [] : selections), [onOwnersChange]);

    const selectedOptions = getSelectedOptions()?.map(o => o.value) || [];
    return <div className="grid-row">
        <ContextSelectorInfo onReset={!equals(selectedOwners, defaults) && onReset} label={label || "module.administration.fe.contextSelector.owner-selector-title"}/>
        <TreeSelect
            showSearch
            filterTreeNode={(search, item) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
            style={{
                width: '100%',
            }}
            value={selectedOptions}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
            }}
            placeholder={loading ? Text.resolve("App.loading-data") : placeholder}
            allowClear
            multiple
            treeCheckable
            treeCheckStrictly
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            treeIcon
            treeLine
            disabled={loading}
            onChange={selected => onSelectChange((selected || []).filter(o => !o.halfChecked).map(o => options.find(opt => opt.value === o.value)).filter(Boolean))}
            treeData={treeData}
            treeDefaultExpandedKeys={selectedOptions?.length > 0 ? selectedOptions : undefined}
            treeDefaultExpandAll={!selectedOptions?.length}
        />
    </div>
};


const ContextSelectorInfo = ({ label, onReset }) => {

  const onClick = (e) => { 
      onReset && onReset();
      e?.stopPropagation();
  };

  return (
      <div className="context-selector-orgs-info-root">
          <SelectorTitle label={label}>
              {onReset && (
                  <div className={'context-selector-orgs-info context-selector-cursor'}
                      onClick={onClick}>
                      <span className="context-selector-orgs-info-color">
                          {Text.resolve("global.button.reset")}
                      </span>
                  </div>
              )}
          </SelectorTitle>
      </div>
  );
};