import { useClickOutside } from "ui/hooks/useClickOutside";

const { cloneElement, Children, useRef } = require("react");

const MenuButton = ({enabled = true, title, primary, secondary, children}) => {
  require('./style.css');

  const dropdownRef = useRef(null);
  const [open, setOpen] = useClickOutside(dropdownRef, false, {ignoreInsideClick: false});
  const toggleMenu = () => setOpen(!open);
  
  return enabled ? Children.count(children) <= 1 ? children : <div className="menuButton">
    <div className={["button"].concat(primary ? 'primary' : secondary ? 'secondary' : '').join(' ')} onClick={toggleMenu}>{title || '···'}</div>
    
    <div ref={dropdownRef} className={`menu ${open ? 'showMenu' : 'hideMenu'}`}>{Children.map(children, c => c && cloneElement(c, { 
      asMenuItem: true,
    }))}</div>
  </div> : <></>;
}

export default MenuButton;