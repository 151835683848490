import { memo } from 'react';
import DropList, { Group, Item } from "../../../DropList";
import RegionIcon from '@atlaskit/icon/glyph/world';
import Tooltip from '@atlaskit/tooltip';
import {Text} from 'ui/components/Text';
import useRegion from 'ui/hooks/useRegion';

import './styles.css';

const Region = memo(
  ({ region }) =>
    <div id='regionItem' className="navSideLink bottomNavItem" onClick={e => { e.preventDefault(); }} >
      <Tooltip content={Text.resolve("region", {}, { component: 'RegionSelector' })} position="right">
        <div className="region-container">
          <RegionIcon secondaryColor={'#0747A6'} size={'medium'} />
          <span>{region}</span>
        </div>
      </Tooltip>
    </div>,
  () => true
);

export default (props) => {
  const [currentRegion, setRegion, regions] = useRegion();
  return <DropList id={'RegionMenu'} position='right bottom' trigger={<Region region={currentRegion} />} onClick={ e => {e.preventDefault(); e.stopPropagation();} }>
    <Group heading={Text.resolve("region", {}, {component: 'RegionSelector'})}>
      {(regions || []).map(region => <Item key={`menuRegion${region}`}>
        <span onClick={_ => {props.onItemSelected(); setRegion(region);}}>{region === currentRegion && "✔ "}<Text context={{component: 'RegionSelector'}}>{region}</Text></span>
      </Item>)}
    </Group>
  </DropList>;
}
