import { wrapUser } from 'services/server/functions/iam';
import { uniq } from 'ramda';

import AdministrationConfig from "services/server/functions/model/administration/config";
import { getProductUnits } from './product';
import { getSnapshot } from 'ui/hooks/useSnapshot/globalState';
import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';

const getAllOwnersFor = (user, selectedOwners, contextState) => contextState.loading ? user.metadata.allOwners : [user.data.id].concat((selectedOwners || []).reduce((allOwners, o) => {
    const newAllOwners = allOwners.includes(o) ? allOwners : allOwners.concat(o);
    const parentOwner = contextState.owners.find(own => own.data.id === o)?.data.owners[0];
    if (!newAllOwners.includes(parentOwner)) newAllOwners.concat([parentOwner]);
    return newAllOwners;
}, [contextState.selectedProduct.id, AdministrationConfig.organisation.root.id])); // selected product as allOwner used by useSnapshot to filter

const getOrgUnits = (snapshot) => {
    if (!snapshot) return undefined;
    let sites = HealthcareSite.ownersFrom(snapshot.data) || [];
    let orgs = Organisation.ownersFrom(snapshot.data) || [];
    if (sites.length || orgs.length) return [...sites, ...orgs];

    sites = HealthcareSite.ownersFrom({owners: snapshot.metadata?.allOwners}) || [];
    if (sites.length) return sites;
    orgs = Organisation.ownersFrom({owners: snapshot.metadata?.allOwners}) || [];
    if (orgs.length > 1) return orgs.filter(o => o !== AdministrationConfig.organisation.root.id);
    
    return undefined;
}

const getResourceParentOrgUnits = (resourceURN) => {
    const resourceSnap = getSnapshot(resourceURN); // This is assuming the snapshot is pre-loaded in cahce (so will fail for cases where the resource was not pre-loaded by a previous useSnapshot!)
    return resourceSnap && getOrgUnits(resourceSnap);
}

export const userFilteredByContext = (user, contextState, newOwners) => (options={}) => {
    if (!user?.data) return user;
    user.metadata.selectedProductKey = contextState.selectedProduct?.key;
    if (options?.skipContext) return user;

    const userContext = user?.metadata?.context;

    const selectedOwners = contextState.selectedOwners.length ? contextState.selectedOwners : user?.data?.owners;
    
    const isResourceOwner = options?.owner && [Organisation.name, HealthcareSite.name].every(m => !options.owner.split('/').includes(m));
    const ownerOrgUnits = isResourceOwner ? getResourceParentOrgUnits(options.owner) : options?.owner && [options.owner];
    const owners = ownerOrgUnits ? ownerOrgUnits : selectedOwners;

    const permissions = userContext?.rights ? uniq(owners.reduce((permissions, orgUnit) => { 
        permissions.push(...(userContext.rights[orgUnit]?.[contextState.selectedProduct?.key]?.accessRights || [])); 
        return permissions 
    }, [])) : (user?.metadata?.allRoles || []); //@meir get rid of this "accessRights" and generalise it to "permissions"
    const attributeRights = userContext?.rights ? uniq(owners.reduce((permissions, orgUnit) => { 
        permissions.push(...(userContext.rights[orgUnit]?.[contextState.selectedProduct?.key]?.attributeRights || [])); 
        return permissions 
    }, [])) : []; //@meir get rid of this "attributeRights" and generalise it to "permissions"
    
    const userWithContext = wrapUser({
        ...user,
        data: {
            ...user.data,
            owners,
            roles: permissions,
            attributeRights
        },
        metadata: {
            ...user.metadata,
            allOwners: getAllOwnersFor(user, owners, contextState),
            allRoles: permissions,
            isSuperAdmin: user.isSuperAdmin(),
            isFromAcurable: user.isFromAcurable(),
            loadingContext: contextState.loading,
            selectedProductKey: contextState.selectedProduct?.key,
        }
    }, getProductUnits(contextState, userContext).map(u => u.data.id), newOwners);

    return userWithContext;
};