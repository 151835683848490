import Config from './config';
import { publish } from 'modules/router';
import * as Pages from './ui';

const actions = {};

export const Help       = publish(Config.routes.Help,       actions)(Pages.Help);
export const UserManual = publish(Config.routes.UserManual, actions)(Pages.UserManual);
export const About      = publish(Config.routes.About,      actions)(Pages.About, { page: "About" });
export const Contact    = publish(Config.routes.Contact,    actions)(Pages.Contact);
export const Privacy    = publish(Config.routes.Privacy,    actions)(Pages.Privacy);
