import { Group, Item } from "../../../DropList";

import { AvatarIcon } from '../../../Icon';
import DropList from "../../../DropList";
import {Text} from 'ui/components/Text';
import Tooltip from '@atlaskit/tooltip';
import { ViewUser } from 'modules/authentication';
import { memo } from 'react';

const Avatar = memo(
  _ => 
    <div id='profile' className="navSideLink topNavItem" onClick={ e => { e.preventDefault(); }} >
      <Tooltip content={Text.resolve("SideBar.account")} position="right">
        <AvatarIcon secondaryColor={'#0747A6'} size={'medium'} />
      </Tooltip>
    </div>, 
    () => true
);

const AvatarMenu = (props) => {
  return ( 
  <DropList id={'AvatarMenu'} position='right top' trigger={<Avatar />} onClick={ e => {e.preventDefault(); e.stopPropagation();} }>
    <Group heading={Text.resolve("SideBar.account")}>
      <Item>
        <div onClick={props.onItemSelected}>
          <ViewUser.AsLink id='userProfile' text='my-profile' user={props.currentUser?.data?.id}><span><Text>my-profile</Text></span></ViewUser.AsLink>
        </div>
      </Item>
    </Group>
  </DropList> 
);
}

AvatarMenu.defaultProps = {
  items: []
};

export default AvatarMenu;
