import useSnapshot from 'ui/hooks/useSnapshot';
import AdministrationConfig from 'services/server/functions/model/administration/config';
import { HealthcareSite } from 'services/server/functions/model/administration/model';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { User } from 'services/server/functions/model/authentication/model';
import { hasRole } from 'services/server/functions/iam';
import I18n from 'services/server/functions/i18n';
import { useMemo } from 'react';

const getHcs = (currentUser) => {
    if (currentUser?.hasPermission(HealthcareSite.roles.LIST.id)) {
        return undefined;
    }

    if (currentUser?.hasPermission(HealthcareSite.roles.GET.id) && currentUser?.hasPermission(Study.roles.CREATE_STUDY.id)) {
        return HealthcareSite.ownersFrom(currentUser?.data);
    }

    return [];
};

const getSiteOptions = ({ sitesSnaps, study }) => {
    const siteOptions = Object.values(sitesSnaps || {})
        .filter(s => !s.data?.owners?.includes(AdministrationConfig.organisation.root.id))
        .map(
            (s) => ({ label: s.data.name, value: s.data.id, owners: s?.data?.owners })
        );
    if (siteOptions.length === 0 && study.site) {
        siteOptions.push({ label: I18n.resolve("my-hcs"), value: study.site });
    }
    return siteOptions;
};

export const useHcs = ({ currentUser, study, edited, isEditPage }) => {
    const hcsIds = getHcs(currentUser);
    const autoStartSites = !hcsIds || hcsIds.length > 0;
    const [_sitesSnaps = {}, loadingSites] = useSnapshot(HealthcareSite, hcsIds, { autoStart: autoStartSites });
    const sitesSnaps = useMemo(_ => _sitesSnaps?.aggregate ? { [_sitesSnaps.aggregate.id]: _sitesSnaps } : _sitesSnaps, [_sitesSnaps]);
    const siteOptions = getSiteOptions({ sitesSnaps, study });

    if (!edited && !study.site && siteOptions.length === 1) {
        study.site = siteOptions[0].value;
    }
    const site = (siteOptions.some(op => op?.value === study.site) && study.site) || (Array.isArray(siteOptions) && siteOptions[0]?.value);
    study.site = site;
    
    const canListSiteUsers = !isEditPage || currentUser?.hasPermission(User.roles.LIST.id);
    const cid = canListSiteUsers ? undefined : currentUser?.hasPermission(User.roles.GET.id) ? study.clinician : undefined;
    const [usersSnaps, loadingUsers] = useSnapshot(User, cid, {listenToChanges: false, autoStart: !loadingSites && site !== undefined && (canListSiteUsers || cid !== undefined)});
    const loadingClinicians = !autoStartSites || loadingSites || loadingUsers || !siteOptions?.length || site === undefined;

    const clinicianSnaps = usersSnaps?.aggregate ? {[usersSnaps?.aggregate.id]: usersSnaps} : (usersSnaps || {});
    const clinicians = Object.values(clinicianSnaps).length ? Object.values(clinicianSnaps)
                                                               .filter(c => hasRole(Study.roles.CREATE_STUDY.id)(c.data.roles) 
                                                                            // we need to show the original clinician even if it doesn't belong to that hcs anymore
                                                                            && (c.aggregate.id === study.clinician || (
                                                                                (!study.site || c.data.owners.includes(study.site)) 
                                                                                && HealthcareSite.ownersFrom(c.data).length > 0 
                                                                                && !(c.data.disabled && c.data.id !== study.clinician))
                                                                            ))
                                            : currentUser?.hasPermission(Study.roles.CREATE_STUDY.id) && (!isEditPage || currentUser?.aggregate.id === study.clinician) && currentUser ? [currentUser] 
                                            : [];
    const clinicianOptions = site ? clinicians.map(c => ({ label: [c.data.lastName, c.data.name].join(', '), value: c.data.id })) : [];
    if (study.clinician && clinicianOptions.length > 0 && clinicianOptions.every(o => o.value !== study.clinician)) study.clinician = undefined; // If has permission to list clinicians, then force study.clinician to be one of current possible clinician options 
    if ((!edited || !study.clinician) && clinicianOptions.length === 1) study.clinician = clinicianOptions[0]?.value;
    study.clinician ??= clinicianOptions[0]?.value;
    const clinician = study.clinician && clinicianSnaps?.[study.clinician]?.data;

    return {
        sitesSnaps,
        loadingSites,
        siteOptions,
        loadingClinicians,
        clinicianOptions,
        clinician,
    };
};