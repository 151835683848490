import { useEffect, Fragment } from 'react';
import * as d3 from 'd3';

import './style.css';

const percToDeg = (perc) => perc * 360;
const degToRad = (deg) => deg * Math.PI / 180;
const percToRad = (perc) => degToRad(percToDeg(perc));

const renderNeedle = (perc, len, radius) => {
  const thetaRad = percToRad(perc / 2) // half circle

  const centerX = 0
  const centerY = 0

  const topX = centerX - len * Math.cos(thetaRad)
  const topY = centerY - len * Math.sin(thetaRad)

  const leftX = centerX - radius * Math.cos(thetaRad - Math.PI / 2)
  const leftY = centerY - radius * Math.sin(thetaRad - Math.PI / 2)

  const rightX = centerX - radius * Math.cos(thetaRad + Math.PI / 2)
  const rightY = centerY - radius * Math.sin(thetaRad + Math.PI / 2)

  return `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`;
}

const GaucheChartText = ({ pathId, label, ...props }) =>
  <text {...props}>
    <textPath href={`#${pathId}`}>{label}</textPath>
  </text>;

const GaugeChart = (props) => {
  const width          = 150;
  const valueLabelSize = (props.label || props.valueAsLabel) ? 20 : 0;
  const height         = 150 + valueLabelSize;
  
  const sections    = props.sections || [{}];
  if (sections.every(s => s.min === undefined)) [...Array(sections.length).keys()].forEach(idx => { sections[idx].min = idx * (1 / sections.length) * 100; });
  if (sections.every(d => d.max === undefined)) sections.map(s => s.min).slice(1).concat(100).forEach((max, idx) => { sections[idx].max = max; });
  const value       = Number.isFinite(props.value) ? props.value : sections.filter(s => s.value?.toUpperCase() === props.value?.toUpperCase()).map(s => (s.min + s.max)/2).pop();
  const maxValue    = sections.slice(-1).pop().max || (sections.length === 1 ? 100 : sections.slice(-1).pop().min + sections.map(s => s.min).slice(1).reduce((r, v) => ({prev: v, sum: r.sum + (v - r.prev)}) ,{prev: sections[0].min, sum: 0}).sum / (sections.length-1));
  const valuePerc   = Math.min(value / maxValue, 1);

  const pad            = 2;
  const labelsSize     = sections.some(s => s.label !== undefined) ? 15 : 0;
  const labelsRadius   = Math.min(width, height) - labelsSize;
  const radius         = sections.some(s => s.label !== undefined) ? labelsRadius - labelsSize - pad : labelsRadius;
  const arcWidth       = Math.min(30, radius * 0.4);
  const needleLength   = radius - arcWidth / 2;
  const needleRadius = radius * 0.05;
  const levelsOfsset = 1;
  const highestLevel = [...sections].pop().max;
  const lowestLevel = [...sections].shift().min;
  const levelsQuantity = Math.round((highestLevel - lowestLevel  + levelsOfsset) / sections.length);
  const levelsPerSection = Array.from(Array(levelsQuantity).keys());
  const availableTextIndex = [0, highestLevel, props.value];

  useEffect(_ => {
    const gaugeChart = d3.select('.chart-gauge');
    gaugeChart.select('.chart').transition()
      .delay(500)
      .ease(d3.easeElastic)
      .duration(3000)
      .selectAll('.needle')
      .tween('progress', () => percentOfPercent => gaugeChart.select('.chart').select('.needle').attr('d', renderNeedle(percentOfPercent * valuePerc, needleLength, needleRadius)))
  }, [valuePerc, needleLength, needleRadius]);


  return (
    <svg id='chart-gauge-svg' width={props.width || 350} height={props.height || 196} viewBox={`0 0 ${width} ${height + 10}`}>
        <defs>
          <path 
            id="outer-arc" 
            className="arc" 
            d={d3.arc()
              .outerRadius(labelsRadius)
              .innerRadius(labelsRadius - labelsSize)
              .startAngle(percToRad(.75))
              .endAngle(percToRad(1.25))()}>
          </path>
        </defs>
        {!props.hideValue && (props.label || props.valueAsLabel) && 
          <text textAnchor="middle" fontSize="15" x={width/2} y={height}>{props.label ? props.valueAsLabel ? `${props.label}: ${value}`: props.label : value}</text>
        } 
        <g className="chart" transform={`translate(${(width) / 2}, ${height-valueLabelSize})`}>
          {!props.hideValue && !props.valueAsLabel &&
            <>
              <text textAnchor="middle" className="value" fontSize="7pt" transform={`translate(${Math.cos(percToRad(0.5 + valuePerc/2)) * (labelsRadius + 10)},${Math.sin(percToRad(0.5 + valuePerc/2)) * (labelsRadius + 10)})rotate(${percToRad(-.25 + valuePerc/2) * (360 / (2*Math.PI))},0,0)`}>{value}</text>
              <path className="value-indicator" 
                    transform={`translate(${Math.cos(percToRad(0.5 + valuePerc/2)) * (labelsRadius+5)},${Math.sin(percToRad(0.5 + valuePerc/2)) * (labelsRadius+5)})rotate(${percToRad(.25 + valuePerc/2) * (360 / (2*Math.PI))},0,0)`} 
                    d={d3.symbol().type(d3.symbolTriangle).size(30)()}></path>
            </>
          }
          {sections.map((sect, sectIdx) => {
            const sectSize      = (sect.max || maxValue) - sect.min;
            const sectSizePerc  = sectSize / maxValue;
            const arcStartRad   = percToRad((sect.min / maxValue) / 2); // half circle
            const arcEndRad     = percToRad((sect.min / maxValue + sectSizePerc) / 2); // half circle
            const arcSizeRad    = arcEndRad - arcStartRad;
            const selected      = value >= sect.min && (!sect.max || value < sect.max);
      
            const startPadRad = sectIdx === 0 ? 0 : (pad / radius) / 2;
            const endPadRad = sectIdx === sections.length ? 0 : (pad / radius) / 2;
            const startAngle = (percToRad(.75) + arcStartRad + startPadRad);
            const maxEndAngle = (percToRad(.75) + arcEndRad - endPadRad);
            const anglesRange = (maxEndAngle - startAngle) / levelsQuantity;
            let lastAngle = startAngle;

            return (
              <g key={`sect-${sectIdx + 1}`} id={`sect-${sectIdx + 1}`} className={['section'].concat(selected ? 'selected' : []).join(' ')}>
                {
                  levelsPerSection.map((level, levelIndex) => {
                    const endAngle = lastAngle + anglesRange;
                    const currentIndexLevel = (sectIdx * levelsPerSection.length) + levelIndex;
                    const isAvailableTextIndex = availableTextIndex.includes(currentIndexLevel);
                    const pathColor = currentIndexLevel === props.value ? sect.selectedColor : sect.color;

                    const arcElement = d3.arc()
                      .outerRadius(radius)
                      .innerRadius(radius - arcWidth)
                      .startAngle(lastAngle)
                      .endAngle(endAngle)();
                    const pathId = `arc-${sectIdx}-${levelIndex}`;
                    const pathTempalte = (
                      <Fragment key={`${sectIdx}_${level}`} >
                        <path id={pathId} className="arc"
                          d={arcElement}
                          stroke="white"
                          fill={pathColor} />
                        { isAvailableTextIndex && (
                          <GaucheChartText pathId={pathId} label={currentIndexLevel} id={`label-${sectIdx}_${level}`}
                            className="snoring-level-text" dx="9" dy={arcWidth/2} textAnchor="middle"/>
                        )}
                      </Fragment>
                    );
                    
                    lastAngle = endAngle; 
                    return pathTempalte;
                  })

                }
                <path id={`label-arc-${sectIdx+1}`} className={"label-arc arc"} 
                      fill={sect.color && selected ? sect.color : '#b2b2b2'}
                      d={d3.arc()
                          .outerRadius(labelsRadius)
                          .innerRadius(labelsRadius - labelsSize)
                          .startAngle(percToRad(.75) + arcStartRad + startPadRad)
                          .endAngle(percToRad(.75) + arcEndRad - endPadRad)()}></path>
                {sect.label && 
                  <text id={`label-${sectIdx+1}`} className="label" textAnchor="middle" dy="11" dx={(arcStartRad + startPadRad + arcSizeRad/2) * labelsRadius}>
                    <textPath href="#outer-arc">{sect.label}</textPath>
                  </text>
                }
                { sect.hideRange ? <></> :
                  <text id={`min-val-${sectIdx+1}`} className="min-val" dy="13" dx="3" fill="#ffffff">
                    <textPath href={`#arc-${sectIdx+1}`}>{sect.max !== undefined ? sect.min : `${sect.min}+`}</textPath>
                  </text>
                }
              </g>
            );
          })}
          <path className="needle" d={renderNeedle(0, needleLength, needleRadius)}></path>
          <circle className="needle-center" cx="0" cy="0" r={needleRadius}></circle>
        </g>
      </svg>
  );
};

export default (props) => (
  <div className={["chart-gauge"].concat(props.className || []).join(' ')} id={props.id || "Gauge"}>
    <GaugeChart {...props}/>
  </div>
);