
import history from 'history.js';
import { useCallback, useEffect, useState } from 'react';

const removeUnsetProps = obj => Object.entries(obj || {}).filter(([,v]) => v !== undefined).reduce((o,[k,v]) => ({...o, [k]: v}), {});

export const getLocationState   = (key, defaultValue={}) => key ? getLocationState()[key] || defaultValue : history.location.state || defaultValue;
export const getQueryParams     = ()                     => [...(new URLSearchParams(history.location.search))].reduce((params, [k, v]) => ({ ...params, [k]: v }), {});
export const getLocationParams  = () => ({ ...getQueryParams(), ...getLocationState() });

const updateLocationState = (type='replace') => (keyOrState, state) => {
  if (state !== undefined) {
    updateLocationState(type)({ [keyOrState]: state })

  } else if (typeof keyOrState === 'string' || keyOrState instanceof String) {
    return (state) => updateLocationState(type)({ [keyOrState]: state })
    
  } else {
    history[type](history.location.pathname, removeUnsetProps({...getLocationParams(), ...keyOrState}));
  }
}

const setLocationState   = updateLocationState('replace');
const _pushLocationState = updateLocationState('replace');


export default (init={}, options={}) => {
  const [_, set] = useState(), refresh = update => update && set(Math.random());

  const coerce = params => Object.entries(params).reduce((o, [k,v]) => ({...o, [k]: options.coerce?.[k] ? options.coerce?.[k](v) : v }), {})

  useEffect(() => {
    const current = getLocationParams();
    const patch   = Object.keys(init).filter(k => current[k] === undefined).reduce((o, k) => ({...o, [k]: init[k]}), {}); // override if not already defined in location state. Maybe provide an option to force overrride
    setLocationState(patch);
  }, []);

  const setLocState = useCallback((newState, setter={type: 'replace'}) => {
    const updated = Object.keys(newState).some(k => k in init && newState[k] !== getLocationState(k)); // Listen only to location.state changes in variables declared in init object
    updateLocationState(setter.type)(newState);
    refresh(updated);
  }, [refresh]);

  return [coerce(getLocationParams()), setLocState];
}