import { useEffect, useState, createContext, useMemo } from "react";

import Dexie from 'dexie';

export const BrowserDBContext = createContext();

export const BrowserDBName = Object.freeze({
    Images: 'imagesDB',
});

export const BrowserDbContextProvider = ({ children }) => {
    const [databases, setDataBases] = useState();
    const handleDBS = async () => { 
        const imagesDB = new Dexie(BrowserDBName.Images); // get or create Instance
        imagesDB.version(1).stores({
            content: 'url,dataUri',
        });

        setDataBases({
            ...databases,
            [BrowserDBName.Images]: imagesDB,
        });
    };

    useEffect(() => {
        handleDBS();
    }, []);
    
    const getDB = (name) => databases[name];

    const contextValue = useMemo(() => ({
        getDB
    }));

    return (
        <BrowserDBContext.Provider value={contextValue}>
            {children}
        </BrowserDBContext.Provider>
    );
};