import Select, { components } from '@atlaskit/select';

import './styles.css';

const AutoCompleteOption = ({ children, ...props }) => (
    <components.Option {...props} className="autocomplete-opt">
        {children}
    </components.Option>
);

const AutoCompleteSelectedOption = ({ children, ...props }) => (
    <components.SingleValue {...props} className="autocomplete-opt"
    >
        {children}
    </components.SingleValue>
);


const getDefaultValue = (items, isMulti) => isMulti ? [items[0]] : items[0];

export const AutoComplete = ({
    id, value, handleChange,
    className = "",
    optionsPlaceHolder = "",
    label,
    withDefault = true,
    placeholder = "", items = [], isMulti, selectProps = {}, forceDefault = true, forceOneItem = true, disableClear }) => {
    const styles = {
        control: (base) => ({
            ...base,
            width: "100%",
        }),
        input: (base) => ({ ...base }),
        option: (base, state) => ({ ...base, background: state?.isSelected ? "var(--acugrey)": base?.background }),
        clearIndicator: (provided) => ({
            ...provided,
            display: disableClear ? 'none' : provided.display,
        }),
    };
    const hasOnlyOneItem = withDefault && items?.length <= 1;
    const selectedItems = (forceDefault && hasOnlyOneItem) ? getDefaultValue(items, isMulti) : value === undefined ? null : value;
    const componentBlock = (
        <div className={`autocomplete-component ${className}`}>
            <Select
                {...selectProps}
                isMulti={isMulti}
                blurInputOnSelect={false}
                styles={styles}
                value={selectedItems}
                onChange={handleChange}
                inputId={id}
                placeholder={placeholder}
                options={items}
                isDisabled={(forceOneItem && hasOnlyOneItem) || selectProps.isDisabled}
                noOptionsMessage={() => optionsPlaceHolder}
                components={{
                    Option: AutoCompleteOption,
                    SingleValue: AutoCompleteSelectedOption
                }}
            />
        </div>
    );

    return label ? (
        <div className="autocomplete-component-opts-block">
            <span className="autocomplete-component-opts-block-label">{label}</span>
            {componentBlock}
        </div>
    ) : componentBlock;
};
