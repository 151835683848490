import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import Details from 'ui/components/Details';
import { useState } from 'react';
import Action from 'ui/components/Action';
import FileDrop from 'ui/components/FileDrop';
import {Text} from 'ui/components/Text';
import {ViewOrganisationConfiguration} from 'modules/administration';

import history from 'history.js'
import { getLocationParams } from 'ui/hooks/useLocationParams';

const MAX_IMAGE_WIDTH=300
const MAX_IMAGE_HEIGHT=150

const ViewOrganisationProfileUI = ({handleUpload, handleFileSelect, I18n, profileLogo}) => <div id='ConfigureOrganisationProfile' className='page'>
  <Back/>
  <div className='pageHeader'>
    <h1 className='title'>{I18n('subtitle')}</h1>
  </div>
  <div className="pageContent">
    <div className='pageSection'>
      <div className='sectionHeader'>
        <div className='title'>{I18n('details')}</div>
      </div>
      <DetailsBlock>
        <Details label="logo">
          <div className="grid-col">
            <FileDrop id="organisationLogoDrop" annotation={Text.resolve('DropFile.upload.image-size', {MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT})} onFileDrop={handleFileSelect} />
            { profileLogo && <div className="centered"><img src={profileLogo.src} alt={Text.resolve("logo")} /></div> }
          </div>
        </Details>
        { profileLogo && <Action handleAction={handleUpload} className="primary button" label="upload" /> }
      </DetailsBlock>
    </div>
  </div>
</div>;

const ViewOrganisationProfile = (props) => {
  const organisation = getLocationParams().organisation;
  const [profileLogo, setProfileLogo] = useState(undefined)

  const isImage     = (file) => file.type.match('image');
  const isValidSize = (img)  => img.width <= MAX_IMAGE_WIDTH && img.height <= MAX_IMAGE_HEIGHT;

  const handleFileSelect = (file) => {
    if (!isImage(file)) {
      props.notify('error', Text.resolve("Notification.wrong-file"), Text.resolve("Notification.wrong-file.not-image"));
      return;
    }

    const reader = new FileReader();
    reader.onload = (evt) => {
      const profileLogo = new Image()
      profileLogo.src = evt.target.result
      
      if (!isValidSize(profileLogo)) {
        props.notify('error', Text.resolve("Notification.wrong-file"), `${Text.resolve("Image file is of size %{width}x%{height}.", {width: profileLogo.width, height: profileLogo.height})} ${Text.resolve("Please select an image of a maximum size of %{width}x%{height}.", {width: MAX_IMAGE_WIDTH, height: MAX_IMAGE_HEIGHT})}`)
        return;
      }

      setProfileLogo(profileLogo)
    }

    reader.readAsDataURL(file);
  }

  const handleUpload = () => {   
    props.configureOrganisationProfile({id: organisation, profile: {logo: profileLogo.src}})
         .then(_ => history.push(ViewOrganisationConfiguration.PATH, {organisation}));
  }

  return ViewOrganisationProfileUI({handleUpload, handleFileSelect, I18n: props.I18n, profileLogo})
}

ViewOrganisationProfile.displayName = "ViewOrganisationProfile";
export default ViewOrganisationProfile;