import moment from 'moment';
import { WithValidation } from 'ui/components/WithValidation';
import CalendarInput from 'ui/components/Calendar';
import { Select } from 'ui/components/Select';

export const ValidatedInput = ({ onValidate, showError, field, form, schema, mandatory, ...props }) => <WithValidation field={field || props.name} form={form} onValidate={onValidate} showError={showError} schema={props.disabled ? undefined : schema} mandatory={mandatory} {...props}><input {...props} value={props.value || ''} /></WithValidation>;

export const ValidatedCalendar = ({ onValidate, showError, field, ...props }) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props} value={props.value ? moment(props.value).format("DD/MM/YYYY") : null}><CalendarInput name {...props} /></WithValidation>;

export const ValidatedSelect = ({onValidate, showError, field, form, schema, mandatory, ...props}) => <WithValidation field={field || props.name} form={form} onValidate={onValidate} showError={showError} schema={props.disabled ? undefined : schema} mandatory={mandatory} {...props}>  
    <Select {...props} />
  </WithValidation>;
