import './style.css';
import 'ui/theme/find.css';
import { Bar } from 'react-chartjs-2';
import * as Pages from 'modules/diagnosis';
import { Study } from 'services/server/functions/model/diagnosis/model';
import * as R from 'ramda';
import FindCalendar from 'ui/components/Calendar';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Back from 'ui/components/Back';
import {Text} from 'ui/components/Text';
import moment from 'moment';
import { PureComponent, useState } from 'react';
import QuickFilters from 'ui/components/QuickFilters';

import history from 'history.js';

// TODO: enums. Move them to the Study model
const Status = Object.values(Study.STATUS).reduce((initStatus, status) => { initStatus[status] = 0; return initStatus }, {});
const Severity = {
  NONE    : 0,
  NORMAL  : 0,
  MILD    : 0,
  MODERATE: 0,
  SEVERE  : 0,
};

const UsageReportUI = ({report, onStartDateChangedHandler, onEndDateChangedHandler, onDateRangeChangedHandler, onDateSelected}) => {
  const [period, setPeriod] = useState('lastWeek');
  
  const startdate       = Object.hasOwnProperty.call(report, 'startdate') ? (report.startdate && moment(report.startdate)) : moment().subtract(1,   'week');
  const enddate         = Object.hasOwnProperty.call(report, 'enddate') ? (report.enddate && moment(report.enddate)) : moment();
  const countByStatus   = { ...Status, ...report.countByStatus };                                                                            //TODO: @felipe why object assign vs spread ? I'd favour spread simplicity/less verbose
  const countBySeverity = { ...Severity, ...report.countBySeverity };
  const countByDate     = Object.keys(report.countByDay).sort((d1,d2) => d1-d2).map((d) => ({ t: moment(d, 'x'), y: report.countByDay[d] }));

  const handlePeriodSelect = (period) => {
    setPeriod(period);
    switch(period) {
      case 'today':
        return onDateRangeChangedHandler(moment().subtract(1, 'day'), moment());
      case 'lastWeek':
        return onDateRangeChangedHandler(moment().subtract(1, 'week'), moment());
      case 'lastMonth':
        return onDateRangeChangedHandler(moment().subtract(1, 'month'), moment());
      case 'lastQuarter':
        return onDateRangeChangedHandler(moment().subtract(1, 'quarter'), moment());
      case 'lastYear':
        return onDateRangeChangedHandler(moment().subtract(1, 'year'), moment());
      default:
        return onDateRangeChangedHandler(undefined, undefined);
    }
  }

  return (
    <div>
      <div id='UsageReport' className='page'>
        <Back/>
        <div className='pageHeader'>
          <h1 className='title'><Text>UsageReport.title</Text></h1>
        </div>
        <div className="pageContent">
          <div className="pageSection">
            <QuickFilters>
              <QuickFilters.Select label='period' className="selected" onChange={(e) => handlePeriodSelect(e.target.value)} value={period}
                options={[
                  {label: 'Today',        value: 'today'},
                  {label: 'Last week',    value: 'lastWeek'},
                  {label: 'Last month',   value: 'lastMonth'},
                  {label: 'Last quarter', value: 'lastQuarter'},
                  {label: 'Last year',    value: 'lastYear'},
                  {label: 'Custom',       value: 'custom'}
                ]}
              />
            </QuickFilters>
            <div className="grid-col">
                <Details label="from">
                  <FindCalendar   
                    readOnly={period !== 'custom'}
                    handleChange={e => onStartDateChangedHandler(e.target.value)}
                    id="startdate"
                    placeholder='Start date'
                    value={startdate !== undefined ? startdate.toDate() : undefined}
                  />
                </Details>
                <Details label="to">
                  <FindCalendar
                    readOnly={period !== 'custom'}
                    handleChange={e => onEndDateChangedHandler(e.target.value)}
                    id="enddate"
                    placeholder={Text("end-date")}
                    value={enddate !== undefined ? enddate.toDate() : undefined}
                  />
                </Details>
              </div>
          </div>
          <div className='pageSection'>
            <DetailsBlock inline>
              {Object.keys(countByStatus).map(status => 
                <Details key={status} label={status} centered size='medium' >
                  { countByStatus[status] && <Pages.FindStudy.AsLinkButton text={countByStatus[status]} status={status} startDate={report.startdate} endDate={report.enddate} /> }
                </Details>
              )}
            </DetailsBlock>
          </div>
          <div className='pageSection d-none d-sm-block'>
            <DetailsBlock>
              <Bar data={{
                datasets: [{
                  backgroundColor     : 'rgba(154, 154, 154, 0.9)',
                  borderColor         : 'rgba(154, 154, 154, 0.9)',
                  borderWidth         : 1,
                  data                : countByDate,
                  fill                : false,
                  hoverBackgroundColor: 'rgba(48, 115, 216,0.4)',
                  hoverBorderColor    : 'rgba(0, 82, 204,1)',
                  label               : 'Studies analysed',
                  type                : 'bar',
                }]
              }} 
              options={{
                title: { display: true, text: "NUMBER OF STUDIES ANALYSED / DAY", fontSize: 20 },
                maintainAspectRatio: true,
                responsive         : true,
                scales             : {
                  xAxes: [{ 
                    distribution: 'series',
                    gridLines   : { display: false },
                    ticks       : { source: 'data', autoSkip: true, autoSkipPadding: 5 },
                    time        : { unit: "day", round: true },
                    title       : "time",
                    type        : 'time',
                  }],
                  yAxes: [{ gridLines: { display: false }, ticks: { beginAtZero: true, min: 0, stepSize: 1 } }]
                },
                onClick: (e, [selected,]) => {
                  if (selected) {
                    const chart = selected._chart;
                    const date = chart.data.datasets[selected._datasetIndex].data[selected._index].t;
                    const startdate = date.valueOf(); 
                    const enddate = date.add(1, 'day').valueOf();
                    onDateSelected(startdate, enddate);
                  }
                }
              }}/>
            </DetailsBlock>
          </div>
          <div className='pageSection'>
            <div className="title">Test results of studies analysed</div>
            <DetailsBlock inline>
              {Object.keys(countBySeverity).map(severity => 
                  <Details key={severity} label={severity} centered size="medium">
                    { countBySeverity[severity] && <Pages.FindStudy.AsLinkButton text={countBySeverity[severity]} severity={severity} startDate={report.startdate} endDate={report.enddate} /> }
                  </Details>
              )}
            </DetailsBlock>
          </div>
        </div>
      </div>
    </div>
  );
};

    
export default class UsageReport extends PureComponent {
  
  static getDerivedStateFromProps(props, state) {
    const startdate = moment(state.startdate || 0);
    const enddate   = moment(state.enddate);
    const byDate    = ({date}) => moment(date).isBetween(startdate, enddate.add(1, 'day'));
    const toBasic   = ({date, report={severity: undefined}, status}) => ({ date: moment(date).startOf('day').valueOf(), severity: report.severity, status })
    
    const studies         = props.list(Study).filter(byDate).map(toBasic); // TODO: move to model query ?
    const countBySeverity = R.countBy(R.prop('severity'))(studies);
    const countByStatus   = R.countBy(R.prop('status'))(studies);
    const countByDay      = R.countBy(R.prop('date'))(studies);

    return { // PureComponent's shallow comparison should detect if state has changed
      countBySeverity,
      countByStatus,
      countByDay,
    };
  }

  state = {
    enddate        : undefined,
    startdate      : undefined,
    countBySeverity: undefined,
    countByStatus  : undefined,
    countByDay     : undefined
  }

  setStartDate = (date) => this.setDateRange(date, this.state.enddate)
  setEndDate   = (date) => this.setDateRange(this.state.startdate, date)

  setDateRange = (startdate, enddate) => this.setState({ 
    // moment() creates always a new instance (different memory address) so PureComponent's shallow comparison will detect it as a change even if the date hasn't changed...
    // that's why storing as state the epoch date rathen than the moment object 
    startdate: startdate ? moment(startdate).startOf('day').valueOf() : undefined,
    enddate  :   enddate ?   moment(enddate).startOf('day').valueOf() : undefined
  })

  clearDateRange = () => this.setDateRange(undefined, undefined);

  render = () => <UsageReportUI
    report={this.state}
    onDateClear={this.clearDateRange}
    onEndDateChangedHandler={this.setEndDate}
    onDateRangeChangedHandler={this.setDateRange}
    onStartDateChangedHandler={this.setStartDate}
    onDateSelected={(startDate, endDate) => history.push(Pages.FindStudy.PATH, {startDate, endDate})}
  />;     
}
