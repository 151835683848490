import * as Administration from 'modules/administration';
import * as Authentication from 'modules/authentication';
import * as Devices from 'modules/devices';
import * as Studies from 'modules/diagnosis';

import { Device } from 'services/server/functions/model/devices/model';
import { HealthcareSite, Organisation, isFromAcurable } from 'services/server/functions/model/administration/model';
import Config from 'services/server/functions/model/administration/config';
import { OrganisationIcon  } from 'ui/acukit/Icon';
import { Redirect } from 'react-router-dom';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { User } from 'services/server/functions/model/authentication/model';
import { hasRole } from 'services/server/functions/iam';
import Back from 'ui/components/Back';
import Counter from 'ui/components/Counter';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import DeleteEntity from 'ui/components/DeleteEntity';
import Table from 'ui/components/Table';
import i18n from 'services/server/functions/i18n';
import WithSnapshot from 'ui/components/WithSnapshot';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';
import AggregateAsText from 'ui/components/AggregateAsText';
import useCurrentUser, { IfHasAccess } from 'ui/hooks/useCurrentUser';

import history from 'history.js';
import { useProducts } from 'features/hooks/useProducts';
import { ViewProducts } from 'features/administration/components/FindProduct';
import { useCounter } from 'ui/hooks/useCounter';

const DeleteOrganisation = ({organisation, ...props}) => <DeleteEntity entity={organisation} linkVariable="organisation" {...props}/>;
DeleteOrganisation.roles = Organisation.commands.UNREGISTER_ORGANISATION.roles || [];

const sortByLastName = (_1, _2, order, _3, row1, row2) => (order === 'asc' ? 1 : -1) * (row1.lastName?.toLowerCase() > row2.lastName?.toLowerCase() ? 1 : -1);
const ViewOrganisationUI = ({ handleDelete, organisation, admins, summary: { healthcaresites, studies, devices, users, trials }, I18n, currentUser }) => {
  const isRoot         = organisation?.id === Config.organisation.root.id;
  const isSuperAdmin   = currentUser.isSuperAdmin();
  const { id, name, address, postCode = 'N/A', country = 'N/A', mail, phone, uid, products } = organisation || {};
  const { getProducts } = useProducts();
  const showSettingsButton = !isRoot && (currentUser.isSuperAdmin() || [Organisation.queries.UPDATE_STUDY_CONFIG_SETTINGS, Organisation.queries.UPDATE_STUDY_SLEEP_REPORT_SETTINGS, Organisation.queries.UPDATE_SIGNAL_SETTINGS, Organisation.queries.UPDATE_REPORT_SETTINGS].every(q => currentUser.hasAccess(q)));

  return (
    <div id='ViewOrganisation' className='page'>
      <Back />
      <div className='pageHeader'>
        <h1 className='title'>{I18n('global.organisation.label')}</h1>
        <div className='actions'>
          <IfHasAccess resource={Administration.UpdateOrganisation}>
            <Administration.UpdateOrganisation.AsLinkButton className='primary' text="edit" organisation={id} />
          </IfHasAccess>
          {currentUser.hasAccess(DeleteOrganisation) && !isRoot && <DeleteOrganisation
            organisation={{
              id, summary: {
                healthcaresites: { count: healthcaresites, page: Administration.FindHCS },
                studies: { count: studies, page: Studies.FindStudy },
                devices: { count: devices, page: Devices.Find },
                users: { count: users, page: Authentication.FindUser },
                trials: { count: trials, page: Administration.FindTrial }
              }
            }}
            onDelete={handleDelete} I18n={I18n}
          />}
          {showSettingsButton && <IfHasAccess resource={Administration.ViewOrganisationConfiguration}>
            <Administration.ViewOrganisationConfiguration.AsLinkButton disabled={!showSettingsButton} text="settings" organisation={id} />
          </IfHasAccess>}
        </div>
      </div>
      <div className="pageContent">
        <div className='pageSection'>
          <div className='title'>{I18n('details')}</div>
          <DetailsBlock>
            <div className="grid-col">
              <Details label='global.name' value={name} />
              <Details label='address' value={address} />
            </div>
            <div className="grid-col">
              <Details label='postal-code' value={postCode} />
              <Details label='country' value={i18n.countries.getName(country) || country} />
            </div>
            <div className="grid-col">
              <Details label='email-address' value={mail} />
              <Details label='telephone-number' value={phone} />
            </div>
            <Details label='unique-identifier' value={uid || 'N/A'} />
          </DetailsBlock>
        </div>
        {currentUser.hasAccess(User.queries.LIST) && <div className='pageSection'>
          <DetailsBlock>
            <Details label="ViewOrganisation.key-admins" help={<div className="maxTextWidth60">{I18n('global.error.problem_usign_the_system')}</div>}>
              <Table
                keyField='id'
                columns={{
                  fullName: { content: "global.name", isSortable: true, sortFunc: sortByLastName },
                  job: { content: "ViewOrganisation.job" }
                }}
                onRowClick={((u) => currentUser.hasAccess(Authentication.ViewUser) && history.push(Authentication.ViewUser.PATH, { user: u.id }))}
                loading={admins === undefined}
                data={(admins || []).filter(u => u.status !== User.STATUS.disabled).map(u => ({ ...u, fullName: [u.lastName, u.name].join(', ') }))}
                defaultSortCol={'fullName'}
                defaultSortDir={'asc'}
                emptyText="ViewOrganisation.no-admins-found"
              />
            </Details>
          </DetailsBlock>
        </div>}
        <div className='pageSection'>
          <DetailsBlock>
            <div className="grid-col">
              <Details label="ViewOrganisation.hcss"><Counter createPage={Administration.CreateHCS} count={healthcaresites} findPage={Administration.FindHCS} id='HCS' params={{ organisation: id }} /></Details>
              <Details label="devices"><Counter count={devices} findPage={Devices.Find} id='Device' params={{ organisation: id }} /></Details>
            </div>
            <div className="grid-col">
              <Details label="ViewOrganisation.sleep-studies"><Counter count={studies} findPage={Studies.FindStudy} id='Study' params={{ organisation: id }} /></Details>
              <Details label="users"><Counter createPage={Authentication.CreateUser} count={users} findPage={Authentication.FindUser} options={Authentication.FindUser.options} id='User' params={{ organisation: id }} /></Details>
            </div>
            {currentUser.hasAccess(Administration.FindTrial) && <div className="grid-col">
              <Details label="ViewOrganisation.trials"><Counter count={trials} findPage={Administration.FindTrial} id='Trial' params={{ organisation: id }} /></Details>
              <div></div>
            </div>}
          </DetailsBlock>
          {
            isSuperAdmin && (
              <DetailsBlock>
                <Details
                  label="module.administration.fe.manageProducts.products"
                  value="" />
                <ViewProducts
                  products={getProducts(products)}
                  withViewAction={false} />
              </DetailsBlock>
            )
          }
        </div>
      </div>
    </div>);
};

const ViewOrganisationPresenter = (props) => {
  const owner = props.snapshot?.aggregate.id;
  const useCounterAutoStart = Boolean(owner);
  const [sitesCount, loadingSites] = useCounter(HealthcareSite, { owner, autoStart: useCounterAutoStart });
  const [studiesCount, loadingStudies] = useCounter(Study, { owner, autoStart: useCounterAutoStart });
  const [devicesCount, loadingDevices] = useCounter(Device, { owner, autoStart: useCounterAutoStart });
  const [usersCount, loadingUsers] = useCounter(User, { owner, autoStart: useCounterAutoStart });
  const [trialsCount, loadingTrials] = useCounter(Organisation.entities.Trial, { owner, autoStart: useCounterAutoStart });

  const [usersSnap, loadingUserSnaps] = useSnapshot(User);
  
  const adminOf   = (expected) => ({data: {owners, roles}}) => owners.includes(expected) && hasRole(Organisation.roles.manager.id)(roles);

  const organisation = props.snapshot?.data;
  const admins       = (props.loading || loadingUserSnaps) ? undefined : Object.values(usersSnap).filter(adminOf(organisation.id)).map(u => u.data);
  const summary      = {
    healthcaresites: (props.loading || loadingSites) ? undefined : sitesCount,
    studies        : (props.loading || loadingStudies) ? undefined : studiesCount,
    devices        : (props.loading || loadingDevices) ? undefined : devicesCount,
    users          : (props.loading || loadingUsers) ? undefined : usersCount,
    trials         : (props.loading || loadingTrials) ? undefined : trialsCount,
  };

  const unregister = () => props.unregisterOrganisation({id: organisation.id}).then(() => history.replace(Administration.FindOrganisation.PATH));

  return ViewOrganisationUI({ organisation, admins, summary, isFromAcurable: props.isFromAcurable, hasAccess: props.hasAccess, handleDelete: unregister, I18n: props.I18n, currentUser: props?.currentUser });
}

const ViewOrganisation = (props) => {
  const [currentUser] = useCurrentUser();
  // TODO: need to check the organisation owner of the currentUser HCS in case the user belongs to a HCS and not to an org directly
  const [myOrganisation,] = Organisation.ownersFrom(currentUser?.data);
  const [{id, organisation}] = useLocationParams(); // TODO: deprecate id parameter
  const fallbackComponent = currentUser?.hasAccess(Administration.FindOrganisation) ? _ => <Redirect to={Administration.FindOrganisation.PATH}/> : undefined;
  return WithSnapshot(Organisation, id || organisation || myOrganisation, {fallbackComponent})(ViewOrganisationPresenter)({...props, hasAccess: currentUser.hasAccess, isFromAcurable: isFromAcurable(currentUser), loading: !currentUser, currentUser}); // ID priority 1) location state 2) query param 3) default org ... does it make sense?
}

ViewOrganisation.AsText = AggregateAsText(Organisation, data => data?.name, Organisation.queries.GET_NAME);

const AsIcon = ({ className, ...props }) => {
  const [currentUser] = useCurrentUser(props.options);
  const hasAccess = currentUser.hasAccess(require('../../config').default.routes.ViewOrganisation);
  const hasOwnership = currentUser.hasOwnership(props.organisation || props.id);
  return <div className={`${hasAccess && hasOwnership ? className : (className || "button").replace('button', 'nonselectable')} withIcon`}><OrganisationIcon /><ViewOrganisation.AsText {...props} /></div>;
}
ViewOrganisation.AsIcon = AsIcon;

export default ViewOrganisation;
