export const ColorsType = Object.freeze({
    Primary: 'primary',
    Secondary: 'secondary',
    Alternative: 'alternative',
    Success: 'success',
    Danger: 'danger',
});

export const Colors = Object.freeze({
    Primary: '#f3ba00'
});
