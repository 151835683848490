import { UserContext } from 'features/providers/userContextProvider';
import { useState, useEffect, useContext } from 'react';
import { resolve } from 'modules/model';

export const useSettingsValue = ({ id, owner }) => {
    const [settingValue, setSettingValue] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const contextState = useContext(UserContext);

    const ownerModel   = resolve(owner);
    const ProductModel = ownerModel?.entities.Product;
    const ownerId      = ProductModel?.newURN(ownerModel?.id(owner), ProductModel?.id(contextState.selectedProduct?.id));

    useEffect(async () => {
      if (ownerId && id) {
        setIsLoading(true);
        const newValue = await ProductModel.queries.GET_INHERITED_PREFERENCES.exec({ownerId, preferenceId: id}, { notifications: { successDisabled: true }}).catch(console.error);
        if (newValue) {
          setSettingValue(newValue);
        }
        setIsLoading(false);
      }
    }, [id, ownerId, Boolean(ProductModel)]);

    return { settingValue, isLoading };
};
