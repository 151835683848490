// export const CreateStudyWithPhone = require('./CreateStudyWithPhone').default; // TODO: requirements to be updated by @liz 
export const CreateStudyPatientPhone = require('./CreateStudyPatientPhone').default;
export const CleanSensor = require('./CleanSensor').default;
export const PatientInstructions = require('./PatientInstructions').default;
export const FindTestResults = require('./FindTestResults').default;
export const ReportSystemIssue = require('./ReportSystemIssue').default;
export const BatteryAutonomy = require('./BatteryAutonomy').default;
export const SensorBatteryIndicator = require('./SensorBatteryIndicator').default;
export const AdhesiveMaterial = require('./AdhesiveMaterial').default;
export const ReplaceAdhesive = require('./ReplaceAdhesive').default;
export const GDPR = require('./GDPR').default;