import './style.css'

import * as d3 from 'd3';

import { Fragment, useEffect } from 'react';

import HelpTooltip from '../HelpTooltip';
import { Text } from 'ui/components/Text';
import d3Tip from 'd3-tip';
import { increaseLightness } from 'services/server/functions/report/colors';
import { isSmallScreen } from 'ui/hooks/useWindowSize';

const BoxplotHelp = () => <div id="BoxplotHelpContent">
  <div><span className="boxplot-help-term"><Text>boxplot-max</Text>: </span><span className="boxplot-help-definition"><Text>boxplot-help-max-definition</Text></span></div>
  <div><span className="boxplot-help-term"><Text>boxplot-q3</Text>: </span><span className="boxplot-help-definition"><Text>boxplot-help-q3-definition</Text></span></div>
  <div><span className="boxplot-help-term"><Text>boxplot-median</Text>: </span><span className="boxplot-help-definition"><Text>boxplot-help-median-definition</Text></span></div>
  <div><span className="boxplot-help-term"><Text>boxplot-q1</Text>: </span><span className="boxplot-help-definition"><Text>boxplot-help-q1-definition</Text></span></div>
  <div><span className="boxplot-help-term"><Text>boxplot-min</Text>: </span><span className="boxplot-help-definition"><Text>boxplot-help-min-definition</Text></span></div>
  <div className="boxplot-help-footer"><span><Text>boxplot-help-footer</Text></span></div>
</div>

export default (props) => {
  const chartID = props.id || "BoxWhiskerChart" 
  // Size and color settings for the chart
  const totalWidth = isSmallScreen() ? 500 : 800;
  const totalheight = isSmallScreen() ? 600 : 380;
  const margin = {top: 0, right: 30, bottom: 0, left: 50},
        width = totalWidth - margin.left - margin.right,
        height = totalheight - margin.top - margin.bottom;
  const barWidth = 30;
  const boxPlotColor = "#898989";
  const medianLineColor = "#ffffff";

  const i18n = (text) => Text.resolve(text, {}, {chart: 'charts.Boxplot'});

  // Compute an ordinal xScale for the keys in plotData
  const xScale = d3.scalePoint()
    .domain(Object.entries(props.data).map(([key, data]) => data.xLabel || key))
    .rangeRound([10, width])
    .padding([0.5]);
  const xAxis = d3.axisBottom(xScale);

  // Set y scale
  let yMax   = props.yMax === undefined ? 0 : props.yMax;
  let yMin   = props.yMin === undefined ? 9999999999 : props.yMin;
  const yScale = d3.scaleLinear()
    .range([height-20, 20])
    .domain([yMin, yMax])
    .nice();
  const yAxis = d3.axisLeft(yScale).ticks(6)
  
  // Config for whiskers and median
  const horizontalLineConfigs = [
    {   // Bottom whisker
      x1: d => xScale(d.xLabel || d.key),
      y1: d => yScale(d.whiskers[0]),
      x2: d => xScale(d.xLabel || d.key) + barWidth,
      y2: d => yScale(d.whiskers[0]),
      color: boxPlotColor,
      class: 'boxQ1'
    },
    {   // Median
      x1: d => xScale(d.xLabel || d.key),
      y1: d => yScale(d.quartiles[1]),
      x2: d => xScale(d.xLabel || d.key) + barWidth,
      y2: d => yScale(d.quartiles[1]),
      color: medianLineColor,
      class: 'boxMedian'
    },
    {   // Top whisker
      x1: d => xScale(d.xLabel || d.key),
      y1: d => yScale(d.whiskers[1]),
      x2: d => xScale(d.xLabel || d.key) + barWidth,
      y2: d => yScale(d.whiskers[1]),
      color: boxPlotColor,
      class: 'boxQ3'
    }
  ];

  // Prepare the data for the box plots
  const plotData = [];
  const outliersData = [];
  for (const [key, boxData] of Object.entries(props.data)) { // eslint-disable-line no-unused-vars
    plotData.push({key, tipValue: boxData.quartiles?.[1], ...boxData});
    (boxData.outliers || []).forEach(o => outliersData.push({value: o.value, tipValue: o.value, key, color: o.color})); // eslint-disable-line no-loop-func

    yMax = Math.max(yMax, ...boxData.outliers?.map(o => o.value).concat(boxData.whiskers?.[1]));
    yMin = Math.min(yMin, ...boxData.outliers?.map(o => o.value).concat(boxData.whiskers?.[0]));
  }

  // Create Tooltips
  const createTooltip = (className, content) => d3Tip().attr('class', className)
    .html(content)
    .direction((_e, d) => {
      let y = '';
      let x = 'e';

      if (d.tipValue < (yMin + (yMax - yMin) * 0.25)) y = 'n';
      if (d.tipValue > (yMin + (yMax - yMin) * 0.75)) y = 's';
      
      const keys  = Object.keys(props.data);
      const index = keys.indexOf(d.key);
      if (index > (keys.length / 2)) x = 'w';

      return y + x;
    })
    .offset(d => {
      const keys  = Object.keys(props.data);
      const index = keys.indexOf(d.key);
      if (index > (keys.length / 2)) return [0, -5];
      return [0,5]
    });
  
  d3.selectAll('.d3-boxtip').remove(); //FIXME: executed many times and creates too many tip DIVs on the DOM., so removing them beforehand as a workaround
  const boxtip = createTooltip('d3-boxtip', (_event, d) => {
    let content = "<div style='display: flex'><div style='width: 10px; height: 10px; margin-right: 5px; background-color: " + d.color + "; border: solid 1px;'></div><span style='font-weight: 800;'>" + i18n("Time range") + ":</span><span style='margin-left: 5px;'>" + d.key + "</span></div><br>";
    content +=`</div><span style='margin-top: 2.5px; font-weight: 800;'>${i18n(props.yLabel)}:</span>${props.units ? `<br/><span>${i18n(props.units)}</span>` : ''}</div>`;
    content +=`
      <table>
        <tbody style="border: none;">
          <tr><td style='font-weight: 800;'>  &#8226; ${i18n('boxplot-max')}: </td><td style="text-align: right">` + d.whiskers[1] + `</td></tr>
          <tr><td style='font-weight: 800;'>  &#8226; ${i18n('boxplot-q3')}: </td><td style="text-align: right">` + d.quartiles[2] + `</td></tr>
          <tr><td style='font-weight: 800;'>  &#8226; ${i18n('boxplot-median')}: </td><td style="text-align: right">` + d.quartiles[1] + `</td></tr>
          <tr><td style='font-weight: 800;'>  &#8226; ${i18n('boxplot-q1')}: </td><td style="text-align: right">` + d.quartiles[0] + `</td></tr>
          <tr><td style='font-weight: 800;'>  &#8226; ${i18n('boxplot-min')}: </td><td style="text-align: right">` + d.whiskers[0] + `</td></tr>
        </tbody>
      </table>
      `;
    return content;
  });

  d3.selectAll('.d3-outliertip').remove(); //FIXME: executed many times and creates too many tip DIVs on the DOM., so removing them beforehand as a workaround
  const outliertip = createTooltip('d3-outliertip', (_event, outlier) => {
    let content = "<div style='display: flex'><div style='width: 10px; height: 10px; margin-right: 5px; background-color: " + outlier.color + "; border: solid 1px;'></div><span style='font-weight: 800;'>" + i18n("Time range") + ":</span><span style='margin-left: 5px;'>" + outlier.key + "</span></div><br>";
    content +=`<span style='font-weight: 800;'>${i18n('Outlier value')}:</span><span style="margin-left: 5px;">${outlier.value}${props.units ? ` ${i18n(props.units)}` : ''}</span>`;
    return content;
  });

  useEffect(() => {
    // add the Y gridlines
    d3.select("#GridLines").call(d3.axisLeft(yScale).tickSize(-(width-10)).tickFormat(""))

    // Bind tooltips with data
    d3.selectAll('#ChartContent > .box').call(boxtip).data(plotData).on('mouseover', boxtip.show).on('mouseout', boxtip.hide);
    d3.selectAll('.outliers > .outlier').call(outliertip).data(outliersData).on('mouseover', outliertip.show).on('mouseout', outliertip.hide);

    // Setup a scale on the left
    d3.select("#YAxis").call(yAxis);

    // Setup a series axis on the bottom
    d3.select("#XAxis").call(xAxis);
  });

  return (
    <div id={chartID} className="boxWhiskerChart">
      <div className="chartTitle"><Text>{props.title}</Text>
        <HelpTooltip 
          className="text-position"
          place="left" content={<BoxplotHelp />} id={'helpBoxplot'} />
      </div>
      <svg width={props.width} height={props.height} viewBox={`0, 0, ${totalWidth}, ${totalheight}`}>
        <g id="BoxPlot" transform={`translate(${margin.left - barWidth}, ${margin.top})`}>
          <g id="YAxisBox" transform="translate(40,0)">
            <g id="YAxis" className="y axis" transform={'translate(10, 0)'}></g>
          </g>
          <g id="XAxisBox" transform="translate(40,0)">
            <g id="XAxis" className="x axis" transform={`translate(0, ${height-20})`}></g>
          </g>
          <g id="GridLines" className="grid" transform="translate(50,0)">

          </g>
          <g id="ChartContent" transform="translate(20,0)">
            {plotData.map((d, idx) => <Fragment key={`boxFragment${idx}`}>
              {/* Outliers */}
              <g key={`boxOutliers${idx}`} id={`boxOutliers${idx}`} className="outliers">
                {d.outliers.map((outlier, outIdx) => <circle key={`outlier${idx}${outIdx}`} className="outlier"
                  cx={xScale(d.xLabel || d.key) + barWidth/2} 
                  cy={yScale(outlier.value)} 
                  r={barWidth/10}
                  fill={d.color} 
                  stroke={d.borderColor !== undefined ? d.borderColor : boxPlotColor} 
                  strokeWidth={1}
                  onMouseOver={ev => { ev.target.style.fill=increaseLightness(d.color, 0.5); ev.target.style.strokeWidth=3; }}
                  onMouseOut={ev => { ev.target.style.fill=d.color; ev.target.style.strokeWidth = 1; }}
                />)}
              </g>
              <g key={`box${idx}`} id={`box${idx}`} className="box">
                {/* Max/Min vertical line */}
                {d.whiskers ? <line x1={xScale(d.xLabel || d.key) + barWidth/2} 
                      y1={yScale(d.whiskers[0])}
                      x2={xScale(d.xLabel || d.key) + barWidth/2}
                      y2={yScale(d.whiskers[1])}
                      stroke={d.borderColor !== undefined ? d.borderColor : boxPlotColor}
                      strokeWidth={1}
                      strokeDasharray="5,5"
                      fill="none"
                /> : <Fragment/> }
                {/* Box */}
                {d.quartiles ? <rect width={barWidth} 
                      height={yScale(d.quartiles[0]) - yScale(d.quartiles[2])} 
                      x={xScale(d.xLabel || d.key)} 
                      y={yScale(d.quartiles[2])} 
                      fill={d.color} 
                      stroke={d.borderColor !== undefined ? d.borderColor : boxPlotColor} 
                      strokeWidth={1} 
                      onMouseOver={ev => { ev.target.style.fill=increaseLightness(d.color, 0.5); ev.target.style.strokeWidth=3; }}
                      onMouseOut={ev => { ev.target.style.fill=d.color; ev.target.style.strokeWidth = 1; }} /> : <Fragment/>}
                {/* Whiskers - Horizontal lines */}
                {d.quartiles && d.whiskers ? horizontalLineConfigs.map((lineConfig, i) =>
                  <Fragment key={i}>
                    {i === 1 && <text className="medianLabel" y={lineConfig.y1(d)} x={lineConfig.x2(d) + 5} alignmentBaseline="middle">{d.quartiles[1]}</text>}
                    <line className={lineConfig.class} x1={lineConfig.x1(d)}
                      y1={lineConfig.y1(d)}
                      x2={lineConfig.x2(d)}
                      y2={lineConfig.y2(d)}
                      stroke={d.borderColor !== undefined ? d.borderColor : boxPlotColor}
                      strokeWidth={1}
                      onMouseOver={ev => { ev.target.style.fill=increaseLightness(d.color, 0.5); ev.target.style.strokeWidth=3; }}
                      onMouseOut={ev => { ev.target.style.fill=d.color; ev.target.style.strokeWidth = 1; }}
                      fill="none"/>
                  </Fragment>
                ) : <Fragment/>}
              </g>
            </Fragment>)}
          </g>
          {props.yLabel && <text className="yLabel" transform="rotate(-90)" y={isSmallScreen() ? -25 : -5} x={-(height / 2)} dy="1em" textAnchor="middle">{i18n(props.yLabel) + (props.units ? ` (${i18n(props.units)})`: '')}</text>}
          {props.xLabel && <text className="xLabel" y={height+25} x={(totalWidth-margin.left)/2} textAnchor="middle">{props.xLabel}</text>}
        </g>
      </svg>
    </div>
  );
}