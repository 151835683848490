import { useEffect, useState, useContext } from 'react';

import { configurations, currentRegion } from 'services/server/functions/firebase/config/config.client';
import useRegion from './useRegion';

const getLocalisationConfig = (config, region) => {
    let localisation = [];

    try {
        localisation = config?.localisation[region];        
    } catch {
        localisation = [];
    }

    return localisation;
};

export const useLocalisation = () => {
    useContext(require('features/providers/userContextProvider').UserContext);
    const [localisation, setLocalisation] = useState(() => getLocalisationConfig(configurations, currentRegion));
    const [region] = useRegion();

    useEffect(() => {
        const isDifferentRegion = region !== currentRegion;
        if (isDifferentRegion) {
            setLocalisation(getLocalisationConfig(configurations, region));
        }
    }, [region]);

    return {
        localisation
    };
};