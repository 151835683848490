import * as Pages from '.';
import { User } from 'services/server/functions/model/authentication/model';
import {Text} from 'ui/components/Text';
import useCurrentUser from 'ui/hooks/useCurrentUser';

export const createItems = ({ currentUser }) => currentUser.hasAccess(Pages.CreateUser) ? [{
    icon: <Pages.ViewUser.Icon />,
    text: Text.resolve('user'),
    link: Pages.CreateUser.PATH,
    id: 'createUser'
  }] : [];

export default () => {
  const [originalUser] = useCurrentUser({ skipContext: true });
  const canList = originalUser.hasAccess(Pages.FindUser);
  
  const usrPage = canList ? Pages.FindUser : Pages.ViewUser;
  return originalUser.hasAccess(usrPage) ? 
    [{
      icon: <Pages.ViewUser.Icon />,
      text: canList ? Text.resolve("users") : Text.resolve("my-profile"),
      link: usrPage.PATH,
      id: `${canList ? 'find' : 'view'}User`,
      root: User.name.toLowerCase()
    }] 
    : 
    [];
};
