import './style.css';

import HelpTooltip from 'ui/components/HelpTooltip';
import { Text } from 'ui/components/Text';
import { CustomModal } from 'ui/acukit/ModalDialog';

const InfoModal = (props) => {
  return props.content ? (
    <CustomModal  id={props.id || 'info'}
                  asLink
                  message={''}
                  heading={Text.resolve(props.title || '')}
                  cancelLabel={Text.resolve("close")}
                  secondaryLabel={Text.resolve("save")}
                  label={Text.resolve(props.label || 'show')}
    >
      <div className="grid-row">
        {props.content?.split ? props.content.split('\n').map((l, i) => <p key={`info${i}`}>{l}</p>) : props.content}
      </div>
    </CustomModal>
  ) : props.label;
};

const Details = ({asModal, modalTitle, modalShowLabel="show", undefLabel="-", detailId, id, icon, iconSize, label, valueClass, value, size, centered, vcentered, children, mandatory, className, contentClass, help, helpPosition, helpType}) => {
  let valueContent = asModal ? <InfoModal title={modalTitle} content={(value !== undefined && value !== null) ? value : children} label={((value !== undefined && value !== null) || children) ? modalShowLabel : undefLabel}/>  : (value !== undefined ? value : (children || undefLabel));
  let detailsContentClass = "detailsContent";
  detailsContentClass = centered ? `${detailsContentClass} centered` : detailsContentClass;
  detailsContentClass = vcentered ? `${detailsContentClass} vcentered` : detailsContentClass;
  detailsContentClass = contentClass ? `${detailsContentClass} ${contentClass}` : detailsContentClass;
  return (
    <div id={detailId} className={['details'].concat(className || []).join(' ')}>
      {Array.isArray(valueContent) ? 
        (<div className={detailsContentClass}>
          {label && <div className="detailsHeader"><div className="name">{<Text>{label}</Text>}{mandatory && <sup className="mandatory">*</sup>}</div>{help && <HelpTooltip content={help} place={helpPosition} id={`help${id || detailId || (label || "").replace(/ /, '')}`} type={helpType} />}</div>}
          {valueContent.map((v, idx) => (
            <div className={(icon ? "withIcon" : "withoutIcon")} key={`val${idx}`}>
              {Array.isArray(icon) ? 
                icon.map((i,idx) => <div key={idx} className={iconSize === "dynamic" ? "small-icon large-icon" : "icon"}>{i}</div>) : 
                (icon ? <div className={iconSize === "dynamic" ? "small-icon large-icon" : "icon"}>{icon}</div> : null)
              }
              <div className={`value ${size ? size : ''} ${valueClass || ""}`} id={`${id}${idx}`}>{v}</div>
            </div>)
          )}
        </div>)
        :
        (<div className={icon ? "withIcon" : "withoutIcon"}>
          {Array.isArray(icon) ? 
                icon.map((i,idx) => <div key={idx} className={iconSize === "dynamic" ? "small-icon large-icon" : "icon"}>{i}</div>) : 
                (icon ? <div className={iconSize === "dynamic" ? "small-icon large-icon" : "icon"}>{icon}</div> : null)
              }
          <div className={detailsContentClass}>
            {label && <div className="detailsHeader"><div className="name">{label ? <Text>{label}</Text> : ""}{mandatory && <sup className="mandatory">*</sup>}</div>{help && <HelpTooltip content={help} place={helpPosition} id={`help${id || detailId || (label || "").replace(/ /, '')}`} type={helpType} />}</div>}
            <div className={`value ${size ? size : ''} ${valueClass || ""}`} id={id}>{valueContent}</div>
          </div>
        </div>)
      }
    </div>
  );
};

export default ({hidden, ...props}) => hidden ? <></> : <Details {...props} />;
