import moment from "moment";
import { HealthcareSite, Organisation } from "services/server/functions/model/administration/model";
import { Filters } from "services/server/functions/model/diagnosis/connector/study-report.connector";
import { Study } from "services/server/functions/model/diagnosis/model";

const DATE_GRANULARITY = 'day';
const DATE_COMPARISION_SCOPE = '[]';

export const byCreatedDatePeriod = ({ study, dateCreated = [] }) => {
    const [dateCreatedStart, dateCreatedEnd] = dateCreated;
    return dateCreatedStart && dateCreatedEnd ? study.data.date ? moment(study.data.date).isBetween(moment(dateCreatedStart), moment(dateCreatedEnd), DATE_GRANULARITY, DATE_COMPARISION_SCOPE) : false : true;
};

export const byConductedDatePeriod = ({ study, dateConducted = [] }) => {
    const [dateConductedStart, dateConductedEnd] = dateConducted;
    if (dateConductedStart && dateConductedEnd) {
      const conductedDate = Study.conductedDate(study.data.tests || {});
      return Boolean(conductedDate) && moment(conductedDate).isBetween(moment(dateConductedStart), moment(dateConductedEnd), DATE_GRANULARITY, DATE_COMPARISION_SCOPE)
    }
    return true;
};

export const byLastUpdatedDatePeriod = ({ study, dateLastUpdated = [] })  => {
  const [lastUpdatedDateStart, lastUpdatedDateEnd] = dateLastUpdated;
  if (lastUpdatedDateStart && lastUpdatedDateEnd) {
    const lastUpdatedDate = Study.lastEventUpdate(study.metadata.events);
    return Boolean(lastUpdatedDate) && moment(lastUpdatedDate).isBetween(moment(lastUpdatedDateStart), moment(lastUpdatedDateEnd), DATE_GRANULARITY, DATE_COMPARISION_SCOPE);
  }
  return  true;
}

export const filterOwners = (owners) => {
  const validOwners = owners.filter(Boolean);
  if (validOwners.some(urn => HealthcareSite.isReference(urn))) return validOwners.filter(urn => !Organisation.isReference(urn));
  return validOwners;
};

export const byTestSeverity = ({ study, severity }) => Filters({ testSeverities: [severity].filter(Boolean) }).byTestSeverities(study);





