import { getSubmitButtonOptions } from "@rjsf/utils";

import './style.css'

export const SubmitButtonTemplate = (props) => {
    const { uiSchema } = props;
    const {
        norender,
        props: submitButtonProps = {}
    } = getSubmitButtonOptions(uiSchema);
    
    if (norender) {
        return null;
    }
    const { generateActions } = submitButtonProps;

    return (
        <div className="schema-form-submit-button-template-root">
            {typeof generateActions === "function" && generateActions(props)}
        </div>
    )
};
