import Styled from 'styled-components';
import Icon from '@atlaskit/icon';
import OrganisationIcon from '@atlaskit/icon/glyph/office-building';
import HealthcareIcon from '@atlaskit/icon/glyph/department';
import DeviceIcon from '@atlaskit/icon/glyph/component';
import StudyIcon from '@atlaskit/icon/glyph/file';
import AvatarIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import SwitchIcon from '@atlaskit/icon/glyph/app-switcher';
import HelpIcon from '@atlaskit/icon/glyph/question-circle';

const CenterVertical = Styled.div`
  display: flex;
  align-items: center;
  span{
    padding:${ props=>props.padding };
  }
`;

export const IconWrapper = props => <CenterVertical padding={props.padding}> {props.icon} <span>{props.text}</span> </CenterVertical>;

IconWrapper.defaultProps = {
  padding: '7px',
};

export {
  OrganisationIcon,
  HealthcareIcon,
  DeviceIcon,
  AvatarIcon,
  SwitchIcon,
  HelpIcon,
  StudyIcon
};


export default Icon;
