export function RGBToHex(r,g,b) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length === 1)
    r = "0" + r;
  if (g.length === 1)
    g = "0" + g;
  if (b.length === 1)
    b = "0" + b;

  return "#" + r + g + b;
}

export function hexToRGB(h) {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  
  return [Number(r), Number(g), Number(b)];
}

export function RGBToHSV(r, g, b) {
  if (arguments.length === 1) {
      g = r.g; b = r.b; r = r.r;
  }
  r /= 255; g /= 255; b /= 255;
  
  var max = Math.max(r, g, b), min = Math.min(r, g, b),
      d = max - min,
      h,
      s = (max === 0 ? 0 : d / max),
      v = max;

  switch (max) {
      case min: h = 0; break;
      case r: h = (g - b) + d * (g < b ? 6: 0); h /= 6 * d; break;
      case g: h = (b - r) + d * 2; h /= 6 * d; break;
      default: h = (r - g) + d * 4; h /= 6 * d;
  }

  return [h, s, v];
}

export function HSVToRGB(h, s, v) {
  var r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
      s = h.s; v = h.v; h = h.h;
  }
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
      case 0: r = v; g = t; b = p; break;
      case 1: r = q; g = v; b = p; break;
      case 2: r = p; g = v; b = t; break;
      case 3: r = p; g = q; b = v; break;
      case 4: r = t; g = p; b = v; break;
      default: r = v; g = p; b = q;
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function isRGBStr(str) {
  return str.startsWith('rgb');
}

export function RGBStrToRGB(str) {
  return str.replace(/rgb(a)?\(/, '').replace(')', '').split(',').map(c => c.includes('.') ? parseFloat(c.trim()) : parseInt(c.trim()));
}

export function RGBToRGBStr(r,g,b,a) {
  return 'rgb' + (a !== undefined ? 'a' : '') + '(' + r + ',' + g + ',' + b + (a !== undefined ? ',' + a : '') + ')';
}

export function increaseLightness(color, increment) {
  const isRGB = Array.isArray(color);
  const rgb   = isRGB ? color : isRGBStr(color) ? RGBStrToRGB(color) : hexToRGB(color);
  
  let [h, s, v]  = RGBToHSV(rgb[0], rgb[1], rgb[2]);
  v = Math.min(1, v+increment);
  
  const newRGB = HSVToRGB(h, s ,v);
  if (rgb.length > 3) newRGB.push(rgb[3]);
  const output = isRGB ? newRGB : isRGBStr(color) ? RGBToRGBStr(...newRGB) : RGBToHex(...newRGB);
  return output;
}