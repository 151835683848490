import { useCallback } from "react";

import Details from "ui/components/Details";
import { AutoComplete } from "features/components/AutoComplete";
import { FiltersType } from "../../utils";

export const TestStatusField = ({ filters, onFilterChange, items, isDisabled, help, disableClear }) => {
    const handleChange = useCallback((option) => onFilterChange({ [FiltersType.Status]: option?.value }), [onFilterChange]);
    return (
        <Details id="statusDetails" label="test-status" help={help}>
            <AutoComplete
                id={FiltersType.Status}
                items={items}
                value={filters[FiltersType.Status]}
                handleChange={handleChange}
                selectProps={{
                    isMulti: false,
                    isClearable: true,
                    isDisabled,
                }}
                disableClear={disableClear}
            />
        </Details>
    );
};