import { Children, useContext, useEffect } from "react";
import { Study } from 'services/server/functions/model/diagnosis/model';
import { useSnapshots } from 'ui/hooks/useSnapshot';
import Loading from 'ui/components/Loading';
import { isEmpty } from "ramda";
import { getStudyTestIds, prepareTests } from "services/server/functions/model/diagnosis/connector/study-report.connector";
import { UserContext } from "features/providers/userContextProvider";

export const useTest = ({ study }) => {
  const testIds = getStudyTestIds(study);
  const [testSnaps, loadingTests] = useSnapshots(...testIds);
  const testEntities = testSnaps?.data ? { [testSnaps.aggregate.id]: testSnaps } : (testSnaps || {});
  const tests = prepareTests({ study, testIds, testEntities });
  return { tests, loadingTests };
};

export const useStudy = ({ id }, options) => {
  const [study, loadingStudy] = useSnapshots(id, options);
  const { tests, loadingTests } = useTest({ study });
  const studyWithTests = study ? { ...study, data: { ...study.data, tests } } : undefined;
  return [studyWithTests, loadingStudy || loadingTests];
};

// export const WithStudy = props => <WithSnapshotV2 model={Study} {...props} />
export const WithStudy = ({ children, id, showLoading = true, LoadingComponent = Loading.Default, onNotFound = () => { }, onError = () => { } }) => {
  const { selectedProduct } = useContext(UserContext);
  const Child = Children.only(children);
  const [snapshot, loading] = useStudy({ id }, { onError });
  if (snapshot) snapshot.model = Study;
  const hasStudy = snapshot?.data && !isEmpty(snapshot.data);
  useEffect(() => {
    // Checking here if the study is not from the product because on useSnapshot we bypass the context filters if we only provide an id
    if (!loading && ((id === undefined || snapshot === undefined) || (snapshot && !snapshot.data.owners.includes(selectedProduct.id)))) {
      onNotFound();
    }
  }, [id, loading, snapshot, selectedProduct?.id]);

  return (
    <>
      {(showLoading && loading && !hasStudy) && <LoadingComponent loading={loading} />}
      <Child.type {...{ ...Child.props, [Study.name.toLowerCase()]: snapshot }} loading={loading} /> {/* eslint-disable-line react/jsx-pascal-case */}
    </>
  );
};