import './style.css';
import { Batch, Device } from 'services/server/functions/model/devices/model';
import * as Devices from 'modules/devices';
import * as R from 'ramda';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Back from 'ui/components/Back';
import {Text} from 'ui/components/Text';
import Issues from '../components/Issues';
import moment from 'moment';
import { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import useLocationParams from 'ui/hooks/useLocationParams';
import WithSnapshot from 'ui/components/WithSnapshot';
import useSnapshot from 'ui/hooks/useSnapshot';

const DEVICE_STATUS = {[Device.STATUS.created]: 0, [Device.STATUS.inStock]: 0, [Device.STATUS.ready]: 0, [Device.STATUS.active]: 0, [Device.STATUS.disabled]: 0, [Device.STATUS.retired]: 0};

const ViewBatchUI = ({batch, status, I18n}) => {
  const {id, serialNumber='N/A', size=0, createdDate, issues=[]} = batch || {};
  return (
    <div id='ViewBatch' className='page'>
      <Back/>
      <div className='pageHeader'>
        <h1 className='title'>{I18n('title')}</h1>
        {/* Not used for now. Requires refactoring to ignore the devices field in the Batch aggregate (devices field might not be there and instead have a manifestFile field)
        <div className='actions'>
          <Devices.ChangeStatus.AsLinkButton text="change-status" batch={id} className="primary" />
          <Devices.LogBatchIssue.AsLinkButton text={I18n('log-issue')} batch={id} />
        </div> 
        */}
      </div>
      <div className='pageContent'>
        <div className='pageSection'>
          <div className='title'>{I18n('details')}</div>
          <DetailsBlock>
            <div className="grid-col">
              <Details label='batch-id' value={Batch.id(id) || 'N/A'} />
              <Details label='serial-no' value={serialNumber} />
            </div>
            <div className="grid-col">
              <Details label='quantity' value={size} />
              <Details label='date-created' value={createdDate ? moment(createdDate).format("DD-MM-YYYY") : 'N/A'} />
            </div>
          </DetailsBlock>
        </div>

        <div className='pageSection'>
          <div className='title'>{I18n('summary')}</div>
          <div id="BatchSummary" className="grid-col3" > 
            <div className='title'>{I18n('status')}</div>
            <div className='title'>{I18n('quantity')}</div>
            <div className='title'>{I18n('actions')}</div>
            {
              Object.entries(status).filter(s => s[1]).map((s, i) => (
                <Fragment key={i}>
                  <div id={s[0]} className={`status ${s[0].toLowerCase()}`}>{Text.resolve(s[0], {}, { schema: "devices.Device" })}</div>
                  <div className='status-value'>{s[1]}</div>
                  <div>{
                    /* Not used for now. Requires refactoring to ignore the devices field in the Batch aggregate (devices field might not be there and instead have a manifestFile field) 
                      (s[0] === Device.STATUS.created) ? <Devices.ChangeStatus.AsLinkButton text="receive" batch={id} status={s[0]} /> :
                      (s[0] === Device.STATUS.inStock) ? <Devices.ChangeStatus.AsLinkButton text="assign" batch={id} status={s[0]} />  :
                    */
                      <Devices.Find.AsLinkButton text="view" batch={id} status={s[0]} />
                  }</div>
                </Fragment>
              ))      
            } 
            <div id='total' className='status'>{I18n('total')}</div>
            <div className='status-value'>{size}</div>
            <div ><Devices.Find.AsLinkButton text="view" batch={id} /></div>
          </div>
        </div>
        <Issues issues={issues} I18n={(text, variables) => Text.resolve(text, variables, {page: 'ReportBatchIssue'})}/>
      </div>
      
    </div>
  );
};

const ViewBatchPresenter = props => {  
  const batch   = props.snapshot?.data;
  
  const [devSnaps] = useSnapshot(Device, { autoStart: Boolean(batch?.id), filters: [['batchId', '==', batch?.id]] });
  const devices = Object.values(devSnaps).map(s => s.data);
  const status  = { ...DEVICE_STATUS, ...R.countBy(R.prop('status'))(devices) };

  return ViewBatchUI({ batch, status, I18n: props.I18n})
}

const ViewBatch  = (props) => {
  const [{batch: batchId, id: oldId}] = useLocationParams();
  const id = Batch.newURN(batchId || oldId);

  return WithSnapshot(Batch, id, {fallbackComponent: _ => <Redirect to={Devices.Inventory.PATH} />})(ViewBatchPresenter)({...props, batch: id})
}
ViewBatch.AsText = ({batch, id=batch}) => `Batch ID: ${Batch.id(id)}`;

export default ViewBatch;