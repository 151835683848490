import './style.css';

import { useCallback, useContext, useState, useEffect } from 'react';

import Modal from 'ui/components/Modal';
import Spinner from '@atlaskit/spinner';
import { Study } from 'services/server/functions/model/diagnosis/model';
import {Text} from 'ui/components/Text';
import asyncComponent from 'ui/components/AsyncComponent';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { LoaderContext } from 'features/providers/LoaderProvider';
import ProductTest from 'services/server/functions/model/administration/model/ProductTest';
import useSnapshot from 'ui/hooks/useSnapshot';
import { currentRegion } from 'services/server/functions/firebase/config/config.client';

const EdfBrowser = asyncComponent(
  () => import(/* webpackChunkName: "EdfBrowser" */  '../../components/EdfBrowser'), 
  () => <div className="centered grid-row"><div><Spinner size="large"/></div><span><Text>ViewTest.signals.loading-component</Text></span></div>
);

const ViewSignals = ({ owner, test, getSignalsImage, addMarker: _addMarker, editMarker, removeMarker, onError }) => {
  const [currentUser]                   = useCurrentUser({ owner }), 
        canViewSignalsVisualisationTool = currentUser.hasAccess(Study.entities.Test.queries.VIEW_SIGNALS_VISUALISATION_TOOL),
                            showMarkers = currentUser.hasAccess(Study.entities.Test.entities.Marker.queries.LIST);
  
  const addMarker  = currentUser.hasAccess(Study.entities.Test.entities.Marker.commands.ADD_MARKER) ? m => _addMarker({...m, id: Study.entities.Test.entities.Marker.newURN(test.id, m.id)}) : undefined;
  editMarker       = currentUser.hasAccess(Study.entities.Test.entities.Marker.commands.EDIT_MARKER) ? editMarker : undefined;
  removeMarker     = currentUser.hasAccess(Study.entities.Test.entities.Marker.commands.REMOVE_MARKER) ? removeMarker : undefined;
  
  const [staticMode, setStaticMode] = useState(true), toggleStaticMode = () => setStaticMode(!staticMode);
  const [_markers, loadingMarkers] = useSnapshot(Study.entities.Test.entities.Marker, {autoStart: Boolean(showMarkers && !staticMode && test?.id !== undefined), owner: test?.id});
  const markers = Object.values(_markers || {}).map(m => m.data).filter(m => !m.userChange?.deleted).map(({userChange, ...m}) => ({signal: m.type.split('%-').pop(), ...m, ...userChange})).reduce((ms, m) => ({...ms, [m.signal]: [...(ms[m.signal] || []), m]}) ,{});
  const [showEdfBrowser, setShowEdfBrowser] = useState(true);
  let availableUserSignals = ProductTest.schemas.SignalSettings.getAllowedSignals(currentUser?.data?.attributeRights || []) || [];
  availableUserSignals = currentRegion === "us" ? availableUserSignals : availableUserSignals.filter(s => s !== "SpO2");
  if (showMarkers || addMarker || editMarker || removeMarker) {
    availableUserSignals.push('flow-event', 'desaturation-event'); // deprecate once signal settings supports these ones
  }

  const pageNav = useContext(require('ui/acukit/Page').PageNavContext);
  const { showLoader, setShowLoader } = useContext(LoaderContext);

  useEffect(_ => {
    if (showLoader) {
      setShowLoader(false);
    }

    if(!staticMode) {
      pageNav.enterFullScreen(); 
      return pageNav.exitFullScreen; 
    }
  }, [staticMode]);

  useEffect(() => {
    if (showEdfBrowser) {
       setShowEdfBrowser(false);
    }
  }, [test]);

  useEffect(() => {
    if (!showEdfBrowser) {
      setShowEdfBrowser(true);
    }
  }, [showEdfBrowser]);

  return (
    <div id="ViewSignals" className="pageContent">
      {test?.report?.signalsFile && showEdfBrowser ?
        <EdfBrowser 
          signals                 = {availableUserSignals}
          test                    = {test}
          markers                 = {showMarkers ? markers : undefined}
          loadingMarkers          = {showMarkers && (!test?.id || loadingMarkers)}
          getStaticImage          = {() => getSignalsImage({id: test?.id, report: {language: Text.i18n.current}}, { notifications: { successDisabled: true }, execute: { background: true } })}
          onNewMarker             = {addMarker}
          onEditMarker            = {editMarker}
          onRemoveMarker          = {removeMarker}
          navigationEnabled       = {!staticMode}
          onNavigationModeChanged = {canViewSignalsVisualisationTool ? toggleStaticMode : undefined}
          onError                 = {onError}
        /> : 
        <Text>This report is not available</Text>
      }
    </div> 
  )
};

// FIXME: When EDFBrowser modal is closed EDFBroser content is re-rendered
ViewSignals.AsModal = ({children, show, open, close, ...props}) => {
  const ViewSignalsPage = require('modules/diagnosis').ViewSignals; // This is the instrumented ViewSignals component (the one wrapped by router.publish)
  const [ _show, setShow ] = useState(show), _open = useCallback(() => setShow(true), []), _close = useCallback(() => setShow(false), []);
  return <Modal 
    id='EdfBrowserModal'
    show={show || _show} 
    onOpen={open || _open}
    onClose={close || _close}
    showExpand={false}
    whenClosedRender={children} >
      <ViewSignalsPage asModal={true} {...props} />
    </Modal>
};

ViewSignals.displayName= "ViewSignals";
ViewSignals.AsModal.displayName= "ViewSignalsModal";
export default ViewSignals;