import { Fragment } from "react";
import { Joi } from "services/server/functions/validation/rules";
import Details from "../Details";
import { Text } from "../Text";
import { WithValidation } from "../WithValidation";

const ValidatedInput = ({onValidate, showError, field, form, ...props}) => <WithValidation field={field || props.name} form={form} onValidate={onValidate} showError={showError} {...props}><input name={props.name || field} {...props} value={props.value || ''} /></WithValidation>
  
export const AsForm = ({name, label, question, value, required, placeholder, showErrors, onValidate, onChange}) => <div className="grid-row fullWidth">
  <Text values={question || label}/>{required && <sup className="mandatory">*</sup>}
  <ValidatedInput
      field={name || question}
      showError={showErrors} 
      schema={required ? Joi.string().required() : Joi.string().allow('')} 
      className="field" 
      placeholder={Text.resolveValue(placeholder)} 
      value={value} 
      onValidate={onValidate} 
      onChange={onChange}
    />
</div>;

export const AsView = (props) => <Details 
  id={props.id}
  label={Text.resolveValue(props.name || props.question)}
  value={props.value || '-'} 
/>;

export const AsConfig = Fragment; // TODO