import { Fragment } from 'react';
import {Text} from 'ui/components/Text';

export default {
  topic: Text.resolve("PrivacyPolicy.topics.how-non-personal.question"),
  component: () => <Fragment>
    <span><Text>PrivacyPolicy.topics.how-non-personal.answer-1</Text></span>
    <ul>
      <li><Text>PrivacyPolicy.topics.how-non-personal.answer-2</Text></li>
    </ul>
  </Fragment>
};