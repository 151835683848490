import 'modules/diagnosis/ui/CreateStudy/style.css';

import Instructions from 'modules/diagnosis/ui/CreateStudy/PrepareEquipment/Instructions';
import { PureComponent } from 'react';
import { Study } from 'services/server/functions/model/diagnosis/model';
import {Text} from 'ui/components/Text';

export default class Question extends PureComponent {
  static question = Text.resolve("Help.create-study-patient-mobile.question"); 
  static roles    = Study.commands.CREATE_STUDY.roles;

  render() {
    return <Instructions labels={{
      item2: 'module.diagnosis.fe.view.faq.instructions.adhesives',
      item3:  'module.diagnosis.fe.view.faq.instructions.micro_usb_cables'
    } } study={{data: {patient: {instructions: {providedPhone: false}}}}} {...this.props}/>;
  }
}