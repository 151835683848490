import { aggregateURN } from '../../executor/urn';
import { v5 as uuidv5 } from 'uuid';
import { config } from '../../firebase';

const context = "administration";
const orgAgrName = "Organisation";
const hcsAgrName = "HealthcareSite";
const prodAgrName = "Product";
const rootOrgId = aggregateURN({context, name: orgAgrName}, uuidv5('acurable.com', uuidv5.DNS));
const acuLabOrgId = aggregateURN({context, name: orgAgrName});
const sa100ProdId = aggregateURN({context, name: prodAgrName}, config.product);
const ox100ProdId = aggregateURN({context, name: prodAgrName}, 'ox100');

export default {
  organisation: {
    name: orgAgrName,
    root: {
      id: rootOrgId,
      name    : "Acurable Limited",
      mail    : "support@acurable.com",
      phone   : "+44 (0)208 191 7590",
      address : "5th Floor, 21 Knightsbridge, London",
      uid     : "GB270468691",
      country : "GB",
      postCode: "SW1X 7LY",
      owners  : [ rootOrgId ]
    },
    acurableLab : {
      id      : acuLabOrgId,
      name    : "Acurable Lab",
      mail    : "support@acurable.com",
      phone   : "+44 7721 877020", 
      address : "5th Floor, 21 Knightsbridge, London",
      country : "GB",
      postCode: "SW1X 7LY",
      owners  : [ rootOrgId ]
    }
  },
  healthcareSite: {
    name: hcsAgrName,
    acurableLab: {
      id      : aggregateURN({context, name: hcsAgrName}),
      name    : "Acurable Lab",
      mail    : "support@acurable.com",
      phone   : "+44 7721 877020", 
      address : "5th Floor, 21 Knightsbridge, London",
      country : "GB",
      postCode: "SW1X 7LY",
      owners  : [acuLabOrgId]
    }
  },
  products: {
    sa100: {
      id: sa100ProdId,
      actCodeId: 0,
      key: "sa100",
    },
    ox100: {
      id: ox100ProdId,
      actCodeId: 1,
      key: "ox100",
    }
  },
  context,
  aggregatesNames: {
    Organisation: orgAgrName,
    HealthcareSite: hcsAgrName,
    Product: prodAgrName,
  }
}
