import * as Pages from '.';
import DeviceIcon from '@atlaskit/icon/glyph/component';

import {Text} from 'ui/components/Text';

export const createItems = ({ currentUser }) => currentUser.hasAccess(Pages.RegisterBatch) ? [{
    icon: <DeviceIcon />,
    text: Text.resolve('CreateMenu.devices'),
    link: Pages.RegisterBatch.PATH,
    id: 'RegisterBatch'
  }] : [];

const devicesPage = Pages.Find;
export default ({ currentUser }) => currentUser.hasAccess(devicesPage) ? [{
  icon: <DeviceIcon />,
  text: Text.resolve('devices'),
  link: devicesPage.PATH,
  id: 'findDevices',
  root: 'devices'
}] : [];
