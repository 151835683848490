import { Fragment, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { WithValidation } from 'ui/components/WithValidation';
import i18n from 'services/server/functions/i18n';
import es from 'react-phone-input-2/lang/es.json';
import ClearIcon from '@atlaskit/icon/glyph/editor/close';

import './style.css';

const getLocale = () => {
  switch (i18n.current) {
    case "es": return es;
    default  : return undefined;
  }
}

const PhoneInput = ({autoFocus, ...props}) => <ReactPhoneInput
  {...props}
  placeholder         = {props.placeholder || ""}
  inputClass          = "phone"
  enableSearchField   = {true}
  countryCodeEditable = {false}
  preferredCountries  = {['gb','us']}
  country             = {(props.value && props.value.startsWith('+')) ? undefined : props.country ? props.country.toLowerCase() : undefined}
  defaultCountry      = {(props.value && props.value.startsWith('+')) ? undefined : props.country ? props.country.toLowerCase() : undefined}
  onChange            = {props.onChange && ((rawPhone, details, _3, value) => props.onChange({target: {name: props.name, value: rawPhone === details.dialCode ? undefined : value, type: 'phone'}}))}   // value follows E.164 standard
  enableSearch        = {true}
  inputProps          = {{autoFocus, name: props.name}}
  localization        = {getLocale()}
/>;

const ClearablePhoneInput = ({onValidate, showError, field, ...props}) => {
  // WithValidation does not call onValidate on each change, therefore I replicated the validation logic here
  const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
  const customOnValidate = (f, error, newValue = props.value) => {
    if (error) return onValidate(f, error);
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(newValue);
      const isValid = phoneUtil.isValidNumber(phoneNumber);
      if (!isValid) throw new Error('invalid');
      onValidate?.(f, null);
    } catch {
      const err = newValue || props.mandatory ? 'invalid phone number' : null;
      onValidate?.(f, err);
    }
  };
  const onChange = ({ target } = {}) => {
    if (!target?.value) target = { name: 'phone', value: null, type: 'phone' };
    customOnValidate(field, null, target.value);
    props?.onChange({ target });
  };
  return (
    <Fragment>
      <WithValidation style={{ display: "inline-flex", width: "100%" }} field={field || props.name} onValidate={customOnValidate} showError={showError} {...props}>
        <PhoneInput className="phoneInput" {...props} value={props.value === undefined ? null : props.value} onChange={onChange} />
        <div id="clearIcon" className={props.value ? "" : "hidden"} onClick={() => onChange()}><ClearIcon size='medium' /></div>
      </WithValidation>
    </Fragment>
  );
}

export const ValidatedPhone = ({onValidate, showError, field, ...props}) => {
  const [forceMessage, setForceMessage] = useState();

  const onErrorMessage = (field, message) => {
    //TODO: use joi extension instead?
    // Workaround for uncought google-libphonenumber validation error codes on joi-phone-number extension
    // Dependency not set on package.json file since it is a transitive dependency we want to track from joi-phone-number
    const PhoneErrors = Object.values(require('google-libphonenumber').Error);
    const errorMessage = PhoneErrors.find(e => e === message) || message;
    onValidate(field, errorMessage);
    setForceMessage(errorMessage);
  };

  return (
    <ClearablePhoneInput field={field || props.name} onValidate={onErrorMessage} forceMessage={forceMessage} showError={showError} {...props}/>
  );
};