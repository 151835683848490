import { execute as _exec } from "services/server/functions/executor";
import Progress from "react-progress-2";
import iamProvider from 'services/server/functions/iam';
import { getErrorData } from "services/server/functions/errors";
import { getReason, getResolution } from "services/server/functions/errors/messages";
import { Text } from "ui/components/Text";
import { dataFrom, isEvent } from "services/server/functions/executor/urn";
import { resolve } from "services/server/functions/model";
import { notify } from "services/redux/actions/Notifications";
import { allSettled } from "services/server/functions/promises";

const withProgress = (func) => {
  return async (...args) => {
    require('services/redux/actions/Authentication').keepSessionAlive();
    Progress.show();
    try {
      return await func(...args);
    } finally {
      Progress.hide();
    }
  };
};

const getLabel = (response) => { const model = resolve(response); return model && Text.resolve(model.events[response.type].label, {}, {schema: `${model.context}.${model.name.replace(/\//g, '.')}`}); };
const getActionLabel = (err) => { const info = dataFrom(err?.metadata?.causationId); const model = info && resolve(info); return model && Text.resolve(model.actions[info.type].label, {}, {schema: `${model.context}.${model.name.replace(/\//g, '.')}`}); };

const notifyError = (err, dispatch, options) => {
  const reason = Text.resolve(getErrorData(err, 'reason') || getReason(getErrorData(err, 'code')));
  const resolution = Text.resolve(getErrorData(err, 'resolution') || getResolution(getErrorData(err, 'code')));
  if (options?.disabled !== true && options?.errorDisabled !== true) notify('error', `${Text.resolve(getActionLabel(err)) || Text.resolve(options?.action) || Text.resolve("Notification.action")} ${Text.resolve("Notification.failed")}`, `${reason || ""}${reason ? '.' : ''}${resolution ? " " : ''}${resolution || ""}${resolution ? '.' : ''}`)(dispatch);
  return err;
}

const notifySuccess = (response, dispatch, options) => (options?.disabled !== true && options?.successDisabled !== true) && notify('success', options?.successText ? Text.resolve(options.successText) : (isEvent(response?.id) && getLabel(response)) || `${Text.resolve(options?.action) || Text.resolve("Notification.action")} ${Text.resolve("Notification.completed")}`, "", { timeout: 3000 })(dispatch);

export const withNotifications = (func, options) => (...args) => async dispatch => {
  try {
    const response = await func(...args);
    if ([].concat(response).some(r => r.status === 'rejected')) notifyError({}, dispatch, options);
    else                                                        notifySuccess(response, dispatch, options);

    return response;

  } catch (err) {
    return Promise.reject(notifyError(err, dispatch, options))
  };
}

const _execute = (options, dispatch) => (request) => {
  if (!navigator.onLine) {
    return Promise.reject(notifyError({ resolution: "global.notifications.with_out_connection", reason: "global.notifications.not_network" }, dispatch, options))
  }
  if (options?.background) require('services/redux/actions/modules/Executor').execBackground(request)(dispatch);
  return _exec(request).then(resp => {
    if (isEvent(resp?.id)) require('ui/hooks/useSnapshot/globalState').eventReducer(resp);
    return resp;
  });
}

const rxExecute = (request, options)  => (dispatch = require('services/redux').store.dispatch) => {
  const req = () => iamProvider.currentUser().then(user => withNotifications(_execute(options?.execute, dispatch), options?.notifications)({ ...request, user: user || require('services/iam').anonymousUser() })(dispatch));
  return options?.execute?.background ? req() : withProgress(req)();
}

// options.batch.results is used for retries -> avoid executing previously succeed request
const rxExecuteBatch = (requests, options) => (dispatch = require('services/redux').store.dispatch) => withProgress(() => iamProvider.currentUser().then(user => withNotifications(reqs => allSettled(reqs.map((req, idx) => options?.batch?.results?.[idx]?.status !== 'fulfilled' ? _execute(options?.execute, dispatch)(req) : Promise.resolve(options.batch.results[idx].value)), options?.batch?.onProgress), 
                                                                                                                                      options?.notifications)(requests.map(req => ({...req, user: user || require('services/iam').anonymousUser()})))(dispatch)))();

const messageFrom = (err) => { 
  const errorData = getErrorData(err);
  return `${Text.resolve(getReason(errorData?.code))}. ${Text.resolve(getResolution(errorData?.code))}.`;
};

const execute = (...args) => rxExecute(...args)();
const executeBatch = (...args) => rxExecuteBatch(...args)();

export {
  execute,
  executeBatch,
  messageFrom,
  rxExecute,
  rxExecuteBatch
};
