import DetailsBlock from 'ui/components/DetailsBlock';
import { Text } from 'ui/components/Text';
import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

const Question = () => {
    const { assetsURl } = useAssetsURL();

    return (
        <>
            {assetsURl && (
                <div className="pageSection">
                    <DetailsBlock inline>
                        <div className="grid-row">
                            <span>
                                <Text>Help.adhesive-material.answer-1</Text>
                            </span>
                            <span>
                                <Text>Help.adhesive-material.answer-2</Text>
                            </span>
                        </div>
                        <div className="instructionImg">
                            <img
                                style={{ maxWidth: '70%' }}
                                alt=""
                                src={getImgUrl(
                                    assetsURl,
                                    'new-adhesive-sensor.png'
                                )}
                            />
                        </div>
                    </DetailsBlock>
                </div>
            )}
        </>
    );
};

Question.question = Text.resolve('Help.adhesive-material.question');

export default Question;
