// TODO: create a "generic" Legend and Legend.Tooltip components ?

import './style.css'; // TODO: same as DeviceStatusLegend

import {Test} from 'services/server/functions/model/diagnosis/model/Test';
import {Text} from 'ui/components/Text';

//TODO: create generic "legend" compoenent?
export const StatusLegendTooltip = () => (
  <div id="TestStatusLegend" className='tooltipLegend'>
    <div className='statusItems grid-row'>
      { Test.STATUS_GROUP.values().map((s, i) =>  <div key={i} className="statusItem"><div className={`status ${s}`} >{Text.resolve(s, {}, { schema: "diagnosis.Study.Test" })}</div><span className="definition">{Text.resolve(`${s}-DESC`, {}, { schema: "diagnosis.Study.Test" })}</span></div>) }
    </div>
  </div>
);

export default StatusLegendTooltip;
