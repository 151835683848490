import { useState } from 'react';
import { CustomModal } from 'ui/acukit/ModalDialog';
import {Text} from 'ui/components/Text';
import {ValidatedInput} from 'ui/components/Input';
import { equalsTo } from 'services/server/functions/validation/rules';
import { User } from 'services/server/functions/model/authentication/model';

const ForbiddenDeleteUserModal = (props) => {
  const onReassign = () => require('history.js').default.push(require('modules/diagnosis').FindStudy.PATH, {clinician: props.user.id});
  
  return(
    <CustomModal  id={'forbidden-delete-user'}
                  btn
                  isOpen={props.isOpen}
                  onToggle={props.onToggle}
                  actions={[
                    {text: props.I18n("reassign-studies"), id: 'modal-reassign-resources', appearance: 'primary', onClick: onReassign},
                    props.user.status !== User.STATUS.disabled && props.onDisable && {text: props.I18n("disable-user"), id: 'modal-disable-user', onClick: props.onDisable},
                    {text: 'close', id: 'delete-user-cancel'}
                  ]}
                  message={''}
                  heading={props.I18n("global.user.delete")}
                  label={"delete"}
    >
      <p>{props.I18n('forbidden-user-delete-reason', {plural: props.user.studies})}</p>
      <ul>
        <li onClick={onReassign} className="link">{Text.resolve('assigned-studies', {plural: props.user.studies, counter: props.user.studies})}</li>
      </ul>
      {props.user.status !== User.STATUS.disabled && <p>{props.I18n('forbidden-user-delete-alternative')}</p>}
    </CustomModal>
  )
};

const DeleteUserModal = (props) => {
  const [confirmed, setConfirmed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState(undefined);
  
  return(
    <CustomModal  id={'delete'}
                  btn
                  isOpen={props.isOpen}
                  onToggle={props.onToggle}
                  secondaryAction={() => { setShowError(true); return confirmed && props.onDelete(); }}
                  message={''}
                  heading={props.I18n("global.user.delete")}
                  label={"delete"}
    >
      <p><Text variables={{deleteWord: (<span className='bold red'>'{Text.resolve('delete').toLowerCase()}'</span>)}}>sure-to-delete-type</Text></p>
      <ValidatedInput value={value} schema={equalsTo(Text.resolve('delete').toLowerCase())} autoFocus={true} onKeyDown={(e) => e.keyCode === 13 ? confirmed && props.onDelete() : null} showError={showError} onChange={e => { setValue(e.target.value); e.target.value && e.target.value.toLowerCase() === Text.resolve("delete").toLowerCase() ? setConfirmed(true) : setConfirmed(false); return true; }}/>
    </CustomModal>
  )
};

const DeleteUser = (props) => props.user.studies ? <ForbiddenDeleteUserModal {...props}/> : <DeleteUserModal {...props}/>;
DeleteUser.roles = (require('services/server/functions/model/authentication/model').User.commands.UNREGISTER_USER || {}).roles || [];

export default DeleteUser;