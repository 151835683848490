
import { useEffect, useState } from 'react';

import Table from 'ui/components/Table';
import DetailsBlock from "ui/components/DetailsBlock";
import I18n from 'services/server/functions/i18n';
import { Text } from 'ui/components/Text';
import { useProducts } from 'features/hooks/useProducts';

export const ViewProducts = ({ products, ...props }) => {
    const columns = {
        key: { content: "module.administration.fe.manageProducts.key", isSortable: true },
        id: { content: "unique-id", isSortable: false },
    };
    const tableOpts = {
        data: products || [],
        defaultSortCol: 'key',
        defaultSortDir: 'asc',
        emptyText: I18n.resolve('module.administration.fe.manageProducts.product_not_found')
    };

    return <Table
        id="view-products-table"
        keyField='id'
        columns={columns}
        {...tableOpts}
        {...props}
    />;
};

export const FindProduct = ({ onClickProductItem }) => {
    const { products: allProducts } = useProducts();
    const [products, setProducts] = useState(() => allProducts);

    const filterProducts = (e) => {
        const value = e?.target?.value;
        setProducts(allProducts?.filter(prod => prod?.key.includes(value)));
    };
    useEffect(() => setProducts(allProducts), [allProducts]);

    return (
        <div className="pageContent">
            <div className="pageSection">
                <div className='title'>{I18n.resolve('search-by')}</div>
                <DetailsBlock>
                    <div className='filter'>
                        <input
                            id="Organisation.productID"
                            placeholder={Text.resolve("search-by-tip")} onChange={filterProducts} />
                    </div>
                </DetailsBlock>
            </div>
            <div className="pageSection">
                <ViewProducts
                    products={products}
                    onRowClick={onClickProductItem}
                />
            </div>
        </div>
    );
};
 