import { Doughnut } from 'react-chartjs-2'; // This import must be before chartjs-plugin-labels import (otherwise labels are hidden -> Chart object not found error)

import 'chartjs-plugin-labels';
import './style.css'

import useWindowSize, { isSmallScreen } from 'ui/hooks/useWindowSize';

import DetailsBlock from 'ui/components/DetailsBlock';
import Details from 'ui/components/Details';
import HelpTooltip from 'ui/components/HelpTooltip';
import Table from 'ui/components/Table';
import { Text } from 'ui/components/Text';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Report } from 'services/server/functions/model/diagnosis/model/Report';
import { getStatsConfig } from './stats';
import { useHasAccess } from 'ui/hooks/useCurrentUser';
import Action from 'ui/components/Action';

const ViewStatsUI = ({ type, id, hideAverage, eventStats, onBack, ...params }) => {
  useWindowSize();
  
  const getv = (metric, defval=params?.[metric]) => {
    const value = params?.[metric]?.value === undefined ? params?.[metric] : params?.[metric]?.value;
    return (value === undefined || Object.isObject(value)) ?  defval : value; // probably use the Joi model to normalise the params into a unique format
  }
  const getu = metric => params?.[metric]?.unit === undefined ? params?.unit : params[metric].unit; // probably use the Joi model to normalise the params into a unique format
  const metrics = Object.entries({'events_count': 'events_count', 'max_duration': 'max_events_length', 'min_duration': 'min_events_length', 'avg_duration': 'average_events_length'}).map(([metric, key]) => { // eslint-disable-line camelcase
    const value = getv(key);
    const units = getu(key);

    return [metric, (value === undefined || value === null) ? "-" : `${value}${units ? ' ' + Text.resolve(`metrics.short_units.${units}`) : ''}`];
  });

  const toComponent = (label, apnoeaType) => <span key={label} className="type"><Text>{label}</Text> {apnoeaType === 'OBSTRUCTIVE-APNOEA-100%' ? <HelpTooltip content="ViewTest.diagnosis.events.100pc-cessation" id={`help${id}1`}/> 
                                                                                      : apnoeaType === 'OBSTRUCTIVE-APNOEA-90%' ? <HelpTooltip content="ViewTest.diagnosis.events.flow-reduction" id={`help${id}`}/> 
                                                                                      : apnoeaType === 'HYPOPNOEA-70%' ? <HelpTooltip content={`ViewTest.diagnosis.events.hypopnoea70pc.${type.slice(-1)}pc`} id={`help${id}2`}/> 
                                                                                      : ['UNCLASSIFIED-APNOEA', 'HYPOPNOEA-7090%'].includes(apnoeaType) ? <HelpTooltip content={`ViewTest.diagnosis.events.unclassified.${type.slice(-1)}pc`} id={`help${id}3`}/> 
                                                                                      : ['UNDEFINED-APNOEA', '"CENTRAL-OBSTRUCTIVE-APNOEA"'].includes(apnoeaType) ? <HelpTooltip content="ViewTest.diagnosis.events.central-obstructive-apnoea" id={`help${id}4`}/>
                                                                                      : ''}</span>
                                                                                      
  const evsData = eventStats ? Report.EVENT_TYPES.filter(type => eventStats[type] !== undefined).map(type => ({
    type,
    group     : eventStats[type].group,
    label     : (eventStats[type].group ? '— ' : '') + Text.resolve(type),
    percentage: `${eventStats[type].percentage}%`,
    value     : eventStats[type].percentage,
    average   : eventStats[type].average
  })) : [];
  const APNOEA_CLASSES = evsData && getStatsConfig(evsData.filter(e => !e.group).map(e => e.type));
  const pieChartData = evsData.filter(e => e.group === undefined);

  return (
    <div id="ViewStats" className="pageSection">
      <div className='sectionHeader grid-row'>
        <div className="title margin"><Text>{'module.diagnosis.fe.view.stats.title'}</Text></div>
        <div className="actions">
          <Action label="back" handleAction={onBack} className={'button secondary'} />
        </div>
      </div>
      <DetailsBlock className="reportCard">
        <div className={isSmallScreen() ? "grid-row" : "grid-col"}>
          <>
            {metrics.map(([metric, val]) => <Details key={`metric_${metric}`} id={metric} valueClass="medium" label={Text.resolve(`metrics.${metric}`)} value={val} />)}
          </> 
        </div>
      </DetailsBlock>
      <DetailsBlock id="ViewStats" className="reportCard" contentClass="statsBlock">
        {pieChartData.length > 0 && <DetailsBlock className="eventsPie">
          <div className="chart-title">
            <Text variables={{ type: Text.resolve(`ViewTest.diagnosis.acu-${type.toLowerCase()}`) }}>{'module.diagnosis.fe.view.stats.events_type'}</Text>
            <HelpTooltip content="module.diagnosis.fe.view.stats.events_type_tooltip" variables={{
              type,
              percentage: `${type?.slice(-1)}`
            }} id="event-per-type" />
          </div>
          <div className="pieChart">
            <Doughnut data={{
              labels: pieChartData.map(e => e.value >= 5 ? Text.resolve(e.label) : ''),
              datasets: [
                {
                  label: pieChartData.map(e => Text.resolve(e.label)), // This is tooltip label
                  data: pieChartData.map(e => e.value),
                  backgroundColor: pieChartData.map(e => APNOEA_CLASSES[e.type].style.fill),
                  hoverBackgroundColor: pieChartData.map(e => APNOEA_CLASSES[e.type].style.backgroundColor)
                }
              ]
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                labels: {
                  render  : 'label',
                  position: 'outside',
                  fontSize: isSmallScreen() ? '8' : '12'
                }
              },
              legend: {
                display: false
              },
              tooltips: {
                displayColors: false,
                callbacks: {
                  label: (item, data) => `${data.datasets[item.datasetIndex].label[item.index]}: ${data.datasets[item.datasetIndex].data[item.index]}%`
                }
              }
            }} />
          </div>
        </DetailsBlock>}
        <div className="eventTypesTable">
          <Table
            keyField='type'
            withViewAction={false}
            columns={{
              label     : { content: "ViewTest.diagnosis.events.type",     style: {paddingLeft: "10px"}, help: "ViewTest.diagnosis.events.type.description" },
              percentage: { content: "ViewTest.diagnosis.events.pc",       style: {textAlign: "center"}  },
              average   : { content: "ViewTest.diagnosis.events.avg-hour", style: {textAlign: "center"}, headerClasses: hideAverage ? 'hidden' : 'visible', classes: hideAverage ? 'hidden' : 'visible', help: "ViewTest.diagnosis.events.avg-hour.tooltip"},
            }}
            rowStyle={e => ({backgroundColor: APNOEA_CLASSES[e.type]?.style.fill || "#fef2c7"})}
            rowClasses={e => e.group === undefined ? 'main-group' : undefined}
            data={evsData.map(e => ({...e, label: toComponent(e.label, e.type)}))}
            defaultSortCol={'label'}
            defaultSortDir={'desc'}
            emptyText="ViewTest.diagnosis.events.no-found"
          />
        </div>
      </DetailsBlock>
    </div>
  );
}

const ViewStats = ({ type, test, onBack }) => {
  const resource      = type ? ViewStats[`AHI${type.slice(-1)}`] : ViewStats;
  const [ canAccess ] = useHasAccess(resource);
  
  if (!canAccess) 
    return <div id={resource.displayName} className="hidden forbidden" />;

  if (type) {
    const {events, ...params} = test?.report?.diagnosis?.[`ACU-${type}`] || {};
    return <ViewStatsUI type={type} id={`Test${test?.sequence}-${test?.retry}`} hideAverage={Boolean(test?.recording?.signals?.SpO2)} eventStats={events} onBack={onBack} {...params} />;
  }
      
  return (
    <>
      <ViewStats.AHI3 test={test} onBack={onBack} />
      <ViewStats.AHI4 test={test} onBack={onBack} />
    </>
  );
}

ViewStats.displayName = "ViewStats";
ViewStats.roles = Study.entities.Test.queries.VIEW_EVENTS_CLASSIFICATION_REPORT.roles;
ViewStats.AHI3 = (props) => <ViewStats type='ACU-AHI3' {...props}/>;;
ViewStats.AHI3.displayName = "ViewStats.ACU-AHI3";
ViewStats.AHI3.roles = [...Study.entities.Test.queries.VIEW_EVENTS_CLASSIFICATION_REPORT.roles, ...Study.entities.Test.queries.GET_ACUAHI3_VALUE.roles];
ViewStats.AHI4 = (props) => <ViewStats type='ACU-AHI4' {...props}/>;;
ViewStats.AHI4.displayName = "ViewStats.ACU-AHI4";
ViewStats.AHI4.roles = [...Study.entities.Test.queries.VIEW_EVENTS_CLASSIFICATION_REPORT.roles, ...Study.entities.Test.queries.GET_ACUAHI4_VALUE.roles];

export default ViewStats;