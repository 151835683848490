import 'ui/theme/find.css';

import * as Pages from 'modules/administration';
import * as Authentication from 'modules/authentication';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Organisation } from 'services/server/functions/model/administration/model';
import QuickFilters from 'ui/components/QuickFilters';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import WithSnapshot from 'ui/components/WithSnapshot';
import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';
import { User } from 'services/server/functions/model/authentication/model';
import useCurrentUser from 'ui/hooks/useCurrentUser';

const FindTrialUI = ({name, trials, organisation, healthcaresite, user, onNameChangedHandler, I18n, hasAccess }) => (
  <div id='FindTrial' className='page'>
    <Back/>
    <div className='pageHeader'>
      <h1 className='title'>{I18n('title')}</h1>
      <div className='actions'>
        {hasAccess(Pages.CreateTrial) && <Pages.CreateTrial.AsLinkButton text="create" className="primary"/>}
      </div>
    </div>
    <div className="pageContent">
      <div className="pageSection">
        <div className='title'>{I18n('search-by')}</div>
        <DetailsBlock>
          <div className='filter'>
            <input id="Trial.name" placeholder={Text.resolve("global.name")} onChange={(e) => onNameChangedHandler(e.target.value)} value={name || ''}/>
            <QuickFilters>
              { organisation && <Pages.ViewOrganisation.AsLinkIcon organisation={organisation} className="quickfilter selected" /> }
              { healthcaresite && <Pages.ViewHCS.AsLinkIcon healthcaresite={healthcaresite} className="quickfilter selected" /> }
              { user && <Authentication.ViewUser.AsLinkIcon user={user} className="quickfilter selected" /> }
            </QuickFilters>
          </div>
        </DetailsBlock>
      </div>
      <div className="pageSection">
        <Table
          keyField='id'
          columns={{
            name     : { content: "name",    isSortable: true },
            sponsor  : { content: "sponsor", isSortable: true },
            principal: { content: "principal-investigator"    }
          }}
          onRowClick={((trial) => history.push(Pages.ViewTrial.PATH, {trial: trial.id}))}
          data={trials.map(t => {
            t.principal = t.principal ? <Authentication.ViewUser.AsText user={t.principal} /> : "-";
            t.sponsor = <Pages.ViewOrganisation.AsText organisation={Organisation.ownersFrom(t)[0]} />; 
            
            return t;
          })}
          defaultSortCol={'name'}
          defaultSortDir={'asc'}
          emptyText={I18n("no-trials-found")}
        />
      </div>
    </div>
  </div>
);

const FindTrialPresenter = (props) => {
  const [currentUser] = useCurrentUser();
  const [{ name, organisation, healthcaresite, user }, setLocationState] = useLocationParams();
  const [usersSnaps={}]                                                  = useSnapshot(User);
  
  const byName          = ({name: trialName})     => name           ? trialName.toLowerCase().includes(name.toLowerCase())  : true;
  const bySponsor       = ({owners})              => organisation   ? (owners || []).includes(organisation) : true;
  const byUser          = ({id})                  => user           ? usersSnaps[user]?.data.owners.includes(id) : true;
  const bySite          = ({id})                  => healthcaresite ? Object.values(usersSnaps).some(u => u.data.owners.includes(id) && u.data.owners.includes(healthcaresite)) : true;
  const toTable         = ({id, name, owners, principal}) => ({id, name, owners, principal});

  const trials = Object.values(props.snapshots)
                       .map(o => o.data)
                       .filter(t => [byName, bySponsor, bySite, byUser].every(predicate => predicate(t)))
                       .map(toTable);

  const setName = (name) => setLocationState({ name })
  
  return FindTrialUI({
    trials,
    name,
    organisation,
    healthcaresite,
    user,
    onNameChangedHandler: setName,
    hasAccess           : currentUser.hasAccess,
    I18n                : props.I18n
  })
};

const FindTrial = WithSnapshot(Organisation.entities.Trial)(FindTrialPresenter);

FindTrial.displayName = "FindTrial";
export default FindTrial;