import {Text} from 'ui/components/Text';

export default {
  topic: Text.resolve("PrivacyPolicy.topics.why-need-data.question"),
  component: () => <div className="grid-row">
    <span><Text>PrivacyPolicy.topics.why-need-data.answer-1</Text></span>
    <ul>
      <li><Text>PrivacyPolicy.topics.why-need-data.answer-2</Text></li>
      <li><Text>PrivacyPolicy.topics.why-need-data.answer-3</Text></li>
      <li><Text>PrivacyPolicy.topics.why-need-data.answer-4</Text></li>
    </ul>
    <span><Text>PrivacyPolicy.topics.why-need-data.answer-5</Text></span>
  </div>
}