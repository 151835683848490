import DetailsBlock from 'ui/components/DetailsBlock';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import { Fragment } from 'react';
import moment from 'moment';

import useEvents from 'ui/hooks/useEvents';
import useSnapshot from 'ui/hooks/useSnapshot';
import { User } from 'services/server/functions/model/authentication/model';
import { dataFrom } from 'services/server/functions/executor/urn';

const BlockWrapper = ({children, ...props}) => <DetailsBlock {...props} className="admin">{children}</DetailsBlock>;

const Events = ({query, filter=_ => true, mapEvent=_=>_, mapRow=_=>_, onViewClick, hideTitle, noBox, head={}, minDate, maxDate, defaultSortOrder='asc'}) => {
    require('./style.css');
    
    const [userSnaps] = useSnapshot(User);
    
    const [events, loading] = useEvents(query, {minDate, maxDate});

    const {id: headId, date: headDate, type: headType, user: headUser, clientApp: headApp, causation: headCaus, ...otherCols} = head;
    const columns = {
        'date': {content: 'Date', align: 'center', isSortable: true, sortValue: (_cell, row) => row.raw.seq, ...headDate},
        'type': {content: 'Type', textFilter: true, style: {wrapSize: '12m'}, ...headType},
        'id'  : {content: 'URN', textFilter: true, ...headId},
        ...otherCols,
        'user': {content: 'User', textFilter: true, formatter: (_cell, row) => row.user.data?.mail || row.user, ...headUser},
        'clientApp': {content: 'Client app', textFilter: true, ...headApp},
        'causation': {content: 'Causation', ...headCaus}
    };

    const expandRow = {
        renderer: event => (
            <div key={event.id} style={{width: '100%'}}><textarea style={{width: '100%', minHeight: '200px'}} disabled={true} value={JSON.stringify(event.raw, null, 2) } /></div>
        )
    };

    const rows = events.map(mapEvent).map((evt, idx) => {
        const {timestamp: stamp, type, aggregate, data, metadata} = evt;

        const aggregateId = aggregate?.id || data.id;
        const user = metadata?.user || metadata?.request?.user;
        const timestamp = stamp && String(stamp).length >= 13 ? Math.floor(stamp / 1000) : stamp;
        const causation = metadata?.causationId && dataFrom(metadata.causationId);

        return {
            raw: evt,
            idx,
            id: aggregateId,
            date: timestamp !== undefined ? moment.unix(timestamp).format("DD/MM/yyyy") + '\n' + moment.unix(timestamp).format("HH:mm:ss") : 'N/A',
            type: Text.resolve(type),
            user: user ? (!userSnaps ? 'Loading' : (userSnaps[user] || 'N/A')) : 'N/A',
            clientApp: metadata?.request?.userAgent || 'N/A',
            causation: causation?.resource ? (causation.resource + (causation.type ? '\n' + causation.type : '')) : (causation?.id || 'UNKNOWN'),
        };
        
    }).filter((row, idx) => filter(row, events[idx], events)).map((row, idx, newEvents, ...args) => mapRow(row, events[idx], newEvents, ...args));
    
    const Wrapper = noBox ? Fragment : BlockWrapper;
    return (
    <div id="EventsLog" className={hideTitle ? undefined : 'pageSection'}>
        {!hideTitle && <div className='title'>{Text.resolve('Events log', {}, { page: "EventsLog" })}</div>}
        <Wrapper>
        <Table
            withViewAction={Boolean(onViewClick)}
            keyField='idx'
            columns={columns}
            data={rows}
            onViewClick={onViewClick}
            defaultSortCol={'date'}
            defaultSortDir={defaultSortOrder}
            emptyText={"EventsLog.no-events"}
            loading={loading}
            expandRow={ expandRow }
        />
        </Wrapper>
    </div>
    );
};

export default Events;