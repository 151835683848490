import { Roles } from '../../../iam/roles';
import { Joi } from '../../../validation/rules';

const buildFeatureSchema = (formId, actions, extensions) => {
    return {
        formId,
        get actions() {
            delete this.actions;
            this.actions = actions.reduce((acc, action) => {
                acc[action.newURN('form')] = action.parent.actions[action.type];
                return acc;
            }, {});
            return this.actions;
        },
        get schema() {
            delete this.schema;
            this.schema = Joi.object().keys(Object.keys(this.actions).reduce((acc, key) => {
                acc[key] = Joi.boolean().default(false).meta({ preferences: { id: this.formId } });
                return acc;
            }, {}));
            return this.schema;
        },
        getSupportedActions(product) {
            const productAccessRights = new Set([...(Roles.expand(product.accessRights) || [])]);
            const supportedActions = Object.entries(this.actions).reduce((acc, [actionURN, action]) => {
                if (action.roles.every(role => productAccessRights.has(role))) acc.push(actionURN);
                return acc;
            }, []);
            return supportedActions;
        },
        areActionsCompatibleWithParentProduct(actionsData, parentProduct) {
            const parentProductSupportedActions = this.getSupportedActions(parentProduct);
            const areCompatible = Object.entries(actionsData).filter(acEntry => acEntry[1]).every(acEntry => parentProductSupportedActions.includes(acEntry[0]));
            return areCompatible;
        },
        ...extensions,
    };
};

const ProductFeatures = {
    get Study() {
        delete this.Study;
        this.Study = require('../../diagnosis/model').Study;
        return this.Study;
    },
    get User() {
        delete this.User;
        this.User = require('../../authentication/model').User;
        return this.User;
    },
    schemas: {
        get ManualScoring() {
            delete this.ManualScoring;
            this.ManualScoring = buildFeatureSchema("ManualScoring", [
                ProductFeatures.Study.entities.Test.entities.Marker.commands.ADD_MARKER,
                ProductFeatures.Study.entities.Test.entities.Marker.commands.EDIT_MARKER,
                ProductFeatures.Study.entities.Test.entities.Marker.commands.REMOVE_MARKER,
            ], {
                requiredActionsFor: (_featureActionURN) => {
                    return [ProductFeatures.Study.entities.Test.entities.Marker.queries.LIST];
                },
            });
            return this.ManualScoring;
        },
        get DigitalSignature() {
            delete this.DigitalSignature;
            this.DigitalSignature = buildFeatureSchema("DigitalSignature", [
                ProductFeatures.User.commands.SET_SIGNATURE,
                ProductFeatures.User.commands.DELETE_SIGNATURE,
                ProductFeatures.Study.commands.APPLY_SIGNATURE,
                ProductFeatures.Study.commands.DELETE_SIGNATURE,
            ], {
                requiredActionsFor: (_featureActionURN) => {
                    return [ProductFeatures.User.entities.Preferences.queries.GET, ProductFeatures.Study.entities.Preferences.queries.GET];
                },
            });
            return this.DigitalSignature;
        },
        get ReviewedStudies() {
            delete this.ReviewedStudies;
            this.ReviewedStudies = buildFeatureSchema("ReviewedStudies", [
                ProductFeatures.Study.commands.MARK_AS_REVIEWED,
                ProductFeatures.Study.commands.MARK_AS_FINISHED,
            ], {
                requiredActionsFor: (_featureActionURN) => {
                    return [ProductFeatures.Study.entities.Preferences.queries.GET];
                },
            });
            return this.ReviewedStudies;
        },
    },
};

export default ProductFeatures;