import * as diagnosis from 'modules/diagnosis';

import { User } from 'services/server/functions/model/authentication/model';
import { HealthcareSite } from 'services/server/functions/model/administration/model';
import { Study } from 'services/server/functions/model/diagnosis/model';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import FindCalendar from 'ui/components/Calendar';
import StudyEvents from '../components/StudyEvents';
import { Select } from 'ui/components/Select';
import {Text} from 'ui/components/Text';

import history from 'history.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import useSnapshot from 'ui/hooks/useSnapshot';

import { calendarRangeProps, getCalendarContainer } from "ui/components/Calendar/CalendarContainer";

export default () => {
    const [userSnaps, userLoading] = useSnapshot(User);
    const [siteSnaps, sitesLoading] = useSnapshot(HealthcareSite);
    
    const [minDate, setMinDate] = useState(_ => {
        var date = new Date();
        date.setDate(date.getDate());
        date.setHours(0, 0, 0, 0)

        return date;
    });
    const [maxDate, setMaxDate] = useState(_ => {
        var date = new Date();
        date.setDate(date.getDate());
        date.setHours(23, 59, 0, 0)

        return date;
    });
    const dateRange = useRef([minDate, maxDate]);
    useEffect(_ => {
        if (minDate && maxDate) dateRange.current = [minDate, maxDate];
    }, [minDate, maxDate])

    const handleDateRangeChange = useCallback((option) => {
        let [startDate, endDate] = Array.isArray(option?.target?.value) ? option.target.value : [];
        const newMinDate = startDate && new Date(startDate);
        if (newMinDate) newMinDate.setHours(0, 0, 0, 0);
        setMinDate(newMinDate);
        const newMaxDate = endDate && new Date(endDate);
        if (newMaxDate) newMaxDate.setHours(23, 59, 0, 0);
        setMaxDate(newMaxDate);
    }, []);

    const [studyId, setStudyID] = useState(), studyIDFilter = (_row, evt) => studyId?.length && (evt.data.id.toLowerCase().includes(studyId.toLowerCase()) 
                                                                                                || Study.getReference(evt.data)?.toLowerCase().includes(studyId.toLowerCase())
                                                                                                || evt.data.patient?.id?.toLowerCase().includes(studyId.toLowerCase()));
    const [user, setUser] = useState(), userFilter = (_row, evt) => user?.length && evt.data.clinician && evt.data.clinician === user;
    const [hcs, setHCS] = useState(), hcsFilter = (_row, evt) => hcs?.length && evt.data.site && evt.data.site === hcs;
    const [events, setEvents] = useState(), eventFilter = (_row, evt) => events?.length && events.some(e => e === evt.type);
    const [clientApp, setClientApp] = useState(), clientFilter = (_row, evt) => clientApp?.length && evt.metadata.request?.userAgent && (console.log("Client app", clientApp, evt.metadata.request?.userAgent) || evt.metadata.request?.userAgent?.toLowerCase().includes(clientApp.toLowerCase()));
    const filters = useCallback((...args) => {
        const fs = [studyId?.length && studyIDFilter, user?.length && userFilter, hcs?.length && hcsFilter, events?.length && eventFilter, clientApp?.length && clientFilter].filter(Boolean);
        return fs.length === 0 || fs.every(f => f(...args));
    }, [studyId, user, hcs, clientApp, events?.join(',')]);

    require('./style.css');

    const today = new Date();
    today.setHours(23, 59, 0 ,0)

    console.log("Date range", minDate, maxDate)
    return <div className="page" id="StudyEventsLog">
        <Back/>
        <div className="pageHeader">
        <h1 className="title"><Text>Study events</Text></h1>
        </div>
        <div className="pageContent">
            <div className='title'>{Text.resolve('search-by')}</div>
            <DetailsBlock>
                <div className='filter vcentered grid-col'>
                    <div className="bold" style={{width: '400px'}}>Period:</div>
                    <FindCalendar
                        handleChange={handleDateRangeChange}
                        id="EventsDateRangeContainer"
                        startDate={minDate}
                        endDate={maxDate}
                        placeholder={Text.resolve("Date range")}
                        {...calendarRangeProps}
                        calendarContainer={getCalendarContainer({
                            id: 'EventsDateRangeContainer',
                            onSelectRange: (_, option) => handleDateRangeChange(option)
                        })}
                    />
                    <div className="bold" style={{width: '400px'}}>Study ID:</div>
                    <input id="Events.studyId" placeholder={"Study ID / Reference / Patient ID"} onChange={(e) => setStudyID(e.target.value)} />
                </div>
                <div className='filter vcentered grid-col'>
                    <div className="bold" style={{width: '400px'}}>Event type:</div>
                    <Select isMulti hideAllOption isClearable name="eventType" options={Object.values(Study.events).map(e => ({label: e.type, value: e.type}))} placeholder={Text.resolve("Event type")} onChange={e => setEvents((e.target.selectedOptions || []).map(o => o.value))} value={events} />
                    <div className="bold" style={{width: '400px'}}>Client app:</div>
                    <input id="Events.clientApp" placeholder={"Client app"} onChange={(e) => setClientApp(e.target.value)} />
                </div>
                <div className='filter vcentered grid-col'>
                    <div className="bold" style={{width: '400px'}}>Site:</div>
                    <Select isClearable name="hcs" options={Object.values(siteSnaps || {}).map(s => ({label: s.data.name, value: s.data.id}))} placeholder={sitesLoading ? 'Loading...' : Text.resolve("Healthcare Site")} onChange={e => setHCS(e.target.value)} value={hcs} />
                    <div className="bold" style={{width: '400px'}}>User:</div>
                    <Select isClearable name="clinician" options={Object.values(userSnaps || {}).map(u => ({label: `${u.data.name} (${u.data.mail})`, value: u.data.id}))} placeholder={userLoading ? 'Loading...' : Text.resolve("User")} onChange={e => setUser(e.target.value)} value={user}/>
                </div>
            </DetailsBlock>
            <StudyEvents noBox hideTitle
                minDate={dateRange.current[0] ? dateRange.current[0] : new Date(0)}
                maxDate={dateRange.current[1] ? dateRange.current[1] : today}
                onViewClick={r => history.push(r.raw.data.id.includes('/Test/') ? diagnosis.ViewTest.PATH : diagnosis.ViewStudy.PATH, {study: r.raw.data.id.split('/Test/')[0], test: Study.entities.Test.sequenceFromId(r.raw.data.id), retry: Study.entities.Test.retryFromId(r.raw.data.id)})}
                defaultSortOrder={'desc'}
                filter={filters}
            />
        </div>
    </div>
}