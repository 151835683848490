import { useCallback, useEffect, useState } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useClickOutside = (el, initialState, options={ignoreInsideClick: true}) => {
  const [isActive, setIsActive] = useState(initialState);
  const onClick = useCallback(e => {
    // If the active element exists and is clicked outside of
    const clickOutside = el.current !== null && !el.current.contains(e.target);
    if (!options.ignoreInsideClick || clickOutside) {
      if (isActive) window.removeEventListener("click", onClick);
      setIsActive(!isActive);
    }
  });

  useEffect(() => {
    // If the item is active (ie open) then listen for clicks outside
    if (isActive) setTimeout(_ => window.addEventListener("click", onClick), 250); // Need to use some delay in registration, otherwise it triggers the listener function on active under certain cases

    return () => { window.removeEventListener("click", onClick); };
  }, [isActive, el]);

  return [isActive, setIsActive];
};