import { useState, useEffect } from 'react';
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play';
import Modal from 'ui/acukit/ModalDialog';
import ReactPlayer from 'react-player/file'
import Spinner from '@atlaskit/spinner';
import {Text} from 'ui/components/Text';

import './style.css';

export const VideoViewer = ({ url, width, height, playing }) => {
  let [isloading, setLoading] = useState(true);

  useEffect(() => {
    //TODO: capture errors properly and validate URL: in case of failure current impl will show PDF viewer and not show spinner while loading PDF content (better than error??)
    if (url !== undefined) {
      if (url.match(/[^:]:\/\//) && !url.startsWith('file')) {
        fetch(url).then(_ => setLoading(false)).catch(_ => setLoading(false)); // catches PDF if not already cached.)
      } else setTimeout(() => setLoading(false), 1000);
    }
  }, [url]);

  return isloading ? <div id="VideoSpinner" className="grid-row centered"><div className="spinner"><Spinner size='xlarge'/></div><span><Text>Video.fetch</Text></span></div> 
  : <ReactPlayer controls={true} url={url} playing={playing === undefined ? true : playing} width={width} height={height}/>;
}

export default (props) => {
  const { handleAction, newWindow, onClose, id, url, className, ...other } = props;
  const [isOpen, setIsOpen] = useState(false), show = () => setIsOpen(true), hide = () => setIsOpen(false);

  const open = newWindow ? () => window.open(url) : () => { show(); if (handleAction) { handleAction(); } };
  const close = () => { hide(); if (onClose) { onClose(); } };

  return isOpen ? 
    <Modal className="videoPlayerModal" show={isOpen} onClose={close} scrollBehavior='inside' actions={[ { text: 'close',  onClick: close } ]}>
      <VideoViewer url={url} playing={isOpen} {...other} />
    </Modal>
    : <div id={id} className={["videoPlayerTrigger grid-row centered vcentered selectable"].concat(className || []).join(" ")} onClick={open}>
      <div className="playWrap"><VidPlayIcon size="medium"/></div>
      <span><Text>component.video_viewer.play_video_message</Text></span>
    </div>;
};
