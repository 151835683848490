import i18n from 'services/server/functions/i18n';
import { Test } from 'services/server/functions/model/diagnosis/model/Test';
import { toPascalCase } from 'features/helpers/objectHelper';
import { sortByPredicate } from 'features/helpers/sortHelper';
import { Report } from 'services/server/functions/model/diagnosis/model/Report';

export const FiltersType = Object.freeze({
    Organisation: "organisation",
    HealthcareSite: "healthcaresite",
    Status: "testStatus",
    NumberOfTests: "requestedTests",
    DateCreated: "dateCreated",
    DateConducted: "dateConducted",
    LastUpdated: "lastUpdated",
    Reference: "patientID",
    PatientBirth: "patientBirth",
    Clinician: "clinician",
    Severity: 'severity',
    BatchId: 'batchId',
});

export const handleFieldDates = ({
    filterType, option, onFilterChange, filters
}) => {
    let [startDate, endDate] = Array.isArray(option?.target?.value) ? option.target.value : [];
    if (startDate) startDate = new Date(startDate).toISOString();
    if (endDate) {
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);
        endDate = endDate.toISOString();
    }
    const dates = [startDate, endDate].filter(Boolean);
    if (dates.length !== 0 || (dates.length === 0 && filters[filterType])) {
        const value = dates.join("/");
        onFilterChange({ [filterType]: value });
    }
};
    
export const toAutoCompleteStudyOptions = (snapshotsData) => snapshotsData?.map(data => {
    return ({
        label: data.name,
        value: data.id,
        owners: data.owners
    });
});

export const getOwnersFilter = (filters = {}) => {
    const hcsFilter = filters[FiltersType.HealthcareSite];
    return hcsFilter?.value ? { [FiltersType.HealthcareSite]: hcsFilter } : {
        [FiltersType.Organisation]: filters[FiltersType.Organisation]
    };
};

// Note: Only Acurable users should have access to the REPEATED Test status
export const statusList = Object.keys(Test.STATUS_GROUP).reduce((acc, key) => {
    if (typeof Test.STATUS_GROUP[key] === "string") {
        acc.push({
            label: toPascalCase(i18n.resolve(key)),
            value: key
        });
    }
    return acc;
}, [{
    label: toPascalCase(i18n.resolve("module.diagnosis.fe.study.repeated_label")),
    value: "REPEATED",
}]).sort(sortByPredicate("label"));

const SEVERITY_EXCLUSIONS = ['UNKNOWN', 'INCONCLUSIVE'];
export const severityList = Report.SEVERITIES.filter(k => !k.includes('/') && !SEVERITY_EXCLUSIONS.includes(k)).map(key => ({
    label: toPascalCase(i18n.resolve(key)),
    value: key
})).sort(sortByPredicate("label"));

export const generateListBySize = (maxNumber) => [...Array(maxNumber).keys()].map(i => {
    const value = i + 1;
    return ({
        label: value,
        value
    });
});