export const fetchFirebaseConfigurations = async () => {
    return await fetchConfigApiData(`/v1/applications/web`)
}

export const fetchLocalisations = async () => {
    return await fetchConfigApiData(`/v1/locales/applications/web/gtins`)
}

const fetchConfigApiData = async (endpoint) => {
    const retries = 3;
    const delay = 1000;

    /* eslint-disable no-await-in-loop */
    let data;
    const baseUrl = process.env.REACT_APP_CONFIG_API_BASE_URL || 'http://127.0.0.1:5001/develop-local-7a78c/us-central1/configApi';

    for (let attempt = 0; attempt < retries; attempt++) {
        try {
            const response = await fetch(`${baseUrl}${endpoint}`)
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonResponse = await response.json();
            data = jsonResponse.data;

        } catch (error) {
            if (attempt < retries - 1) {
                console.log(`Attempt ${attempt + 1} failed. Retrying in ${delay}ms...`);
                await new Promise(resolve => setTimeout(resolve, delay));
                continue;
            }
            
            console.error('Could not fetch configuration')
            data = null
        }
    }
    return data;
}
