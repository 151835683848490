import "./style.css";

import * as Administration from 'modules/administration';
import * as Authentication from 'modules/authentication';
import * as Studies from 'modules/diagnosis';

import { HealthcareSite, Organisation, isFromRoot } from 'services/server/functions/model/administration/model';
import AdministrationConfig from 'services/server/functions/model/administration/config';
import { useEffect, useState } from 'react';

import AggregateAsText from 'ui/components/AggregateAsText';
import Back from 'ui/components/Back';
import Counter from 'ui/components/Counter';
import DeleteUser from './components/DeleteUser';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import DisableUser from './components/DisableUser';
import EditIcon from '@atlaskit/icon/glyph/edit';
import UserIcon from '@atlaskit/icon/glyph/people';
import Modal from 'ui/acukit/ModalDialog';
import { Redirect } from 'react-router-dom';
import ResetPasswordModal from './components/ResetUserPassword';
import { Roles } from 'services/server/functions/iam/roles';
import {Text} from 'ui/components/Text';
import { User } from 'services/server/functions/model/authentication/model';
import {ValidatedInput} from 'ui/components/Input';
import WithSnapshot from 'ui/components/WithSnapshot';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import { Tag } from 'ui/components/Tag';
import { useCounter } from "ui/hooks/useCounter";
import { Study } from "services/server/functions/model/diagnosis/model";
import SignatureManager from "./components/SignatureManager";

const PhoneChanged2FAModal = ({show, close, I18n}) => {
  return <Modal show={show} heading={I18n("settings.2-factor-phone-changed.modal.title")} actions={[{ text: 'Accept', id: 'modal-help', appearance: 'default', onClick: close }]} onClose={close} >
    {I18n("settings.2-factor-phone-changed.modal.content")}
  </Modal>
}

const ViewUserUI = ({ currentUser, user, handleEnable, handleDisable, handleDelete, handleEmailUpdate, I18n, ...props }) => {
  const itsme = user.id === currentUser.data?.id;
  
  // TODO: generalize editable details -> too much repeated code (backend actions can also be generalized but requires to create a FIELD-level access right implementation)
  const [editEmail,  setEditEmail]  = useState(false);
  const [editedMail, setEditedMail] = useState();
  const [mailValidation, setMailValidation] = useState(false);
  const [validMail, setValidMail] = useState(true);
  const [showPhoneChanged2FAModal, setShowPhoneChanged2FAModal] = useState(false);
  const [showDisableUser, setShowDisableUser] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);

  const userStatuses = {
    [User.STATUS.new]: {
      label: Text.resolve('global.status.new'),
      color: 'warning',
    },
    [User.STATUS.active]: {
      label: Text.resolve('global.status.active'),
      color: 'success',
    },
    [User.STATUS.disabled]: {
      label: Text.resolve('global.status.disabled'),
      color: 'error',
    },
  };

  const userStatus = userStatuses[user?.status];
  const skeleton = <span>-</span>

  const onMailValidate  = (_, error) => setValidMail(!error);

  const onMailUpdate = (mail) => {
    setMailValidation(true);
    if (validMail) {
      setEditEmail(false);
      return handleEmailUpdate(mail);
    }
    return false;
  }

  const mailEditable  = (currentUser.isSuperAdmin() || itsme) && currentUser.hasAccess(User.commands.UPDATE_USER_MAIL);

  const trials = Organisation.entities.Trial.ownersFrom(user).length;
  const hasOrgAccess = _ => Administration.ViewOrganisation.AsText.hasAccess() || currentUser.hasAccess(Administration.ViewOrganisation);
  const additionalInfo = [
    user.organisations.some(hasOrgAccess) ? () => <Details id="userOrganisation" label="global.organisation.label-plural">
      <div className="grid-row">
        {user.organisations.filter(hasOrgAccess).map(id => <Administration.ViewOrganisation.AsLinkIcon id={id} key={id} className="button" options={{ skipContext: true }} />)}
      </div>
    </Details> : undefined,
    Administration.ViewHCS.AsText.hasAccess() ? () => <Details id="userSite" label="hcss">
      <div className="grid-row">
        {user.healthcaresites.length ? user.healthcaresites.map(id => <Administration.ViewHCS.AsLinkIcon id={id} key={id} className="button" options={{ skipContext: true }} />) : 'N/A'}
      </div>
    </Details> : undefined,
    trials && currentUser.hasAccess(Administration.FindTrial) ? () => <Details label='trials'><Counter count={trials} findPage={Administration.FindTrial} id='Trial' params={{ user: user.id }} /></Details> : undefined,
    currentUser.hasAccess(Studies.FindStudy) ? () => <Details label='studies'><Counter count={user.studies} findPage={Studies.FindStudy} id='Study' params={{ clinician: user.id }} /></Details> : undefined
  ].filter(Boolean);

  return <div className="page" id="ViewUser">
    <Back/>
    <div className="pageHeader">
      <h1 className="title">{itsme ? I18n('my-profile.title') : I18n('title')}</h1>
      <div className="actions">
        { user.status !== User.STATUS.disabled && currentUser.hasAccess(Authentication.UpdateUser) && <Authentication.UpdateUser.AsLinkButton className='primary' text="edit" user={user.id} /> }
        { user.status !== User.STATUS.disabled && <ResetPasswordModal email={user.mail} I18n={I18n} />}
        { currentUser.hasAccess(User.commands[user.status === User.STATUS.disabled ? 'ENABLE_USER' : 'DISABLE_USER']) && <DisableUser isOpen={showDisableUser} onToggle={setShowDisableUser} disabledNotifications={!user.preferences?.notifications?.emailEnabled} disabled={user.status === User.STATUS.disabled} onConfirm={user.status === User.STATUS.disabled ? handleEnable : handleDisable} I18n={I18n}/> }
        { currentUser.hasAccess(DeleteUser) && <DeleteUser isOpen={showDeleteUser} onToggle={setShowDeleteUser} onDisable={currentUser.hasAccess(User.commands[user.status === User.STATUS.disabled ? 'ENABLE_USER' : 'DISABLE_USER']) && (() => {setShowDeleteUser(false); setShowDisableUser(true); return false;})} user={user} onDelete={handleDelete} I18n={I18n}/> }
      </div>
    </div>
    <div className="pageContent">
      <div className="pageSection">
        <div className="title">{I18n('details')}</div>
        <DetailsBlock>
          <div className="grid-col">
            <Details id="userName" label="first-name" value={user.name} />
            <Details id="userLastName" label="last-name" value={user.lastName} />
          </div>
          <div className="grid-col">
            <Details id="userMail" label="email-address">
              <div className="grid-col vcentered">{editEmail ? <div style={{maxWidth: "max-content"}}><ValidatedInput autoFocus={true} value={editedMail === undefined ? user.mail : editedMail} schema={User.schema.extract('mail')} showError={mailValidation} onValidate={onMailValidate} onChange={e => setEditedMail(e.target.value)} onKeyDown={(e) => e.keyCode === 13 ? onMailUpdate(editedMail) : null}/></div>
              : <span onDoubleClick={mailEditable ? _ => setEditEmail(true) : undefined} style={{maxWidth: "max-content"}}>{user.mail}</span>}{editEmail && <div style={{maxWidth: "max-content"}} className="button primary" onClick={_ => onMailUpdate(editedMail)}>{I18n('update')}</div>}{mailEditable && <div className={editEmail ? "button" : "editField selectable"} style={{maxWidth: "max-content"}} onClick={_ => setEditEmail(!editEmail)}>{editEmail ? Text.resolve("cancel") : <EditIcon/>}</div>}</div>
            </Details>
            <Details id="userJob" label="job-title" value={user.job} />
            
          </div>
            <div className="grid-col">
              <Details id="userStatus" label="global.User.status" >
                {
                  userStatus? <Tag color={userStatus.color}>{ userStatus.label }</Tag> : skeleton
                }
              </Details>
            </div>
          {user.phone && 
            <div className="grid-col">
              <Details id="userPhone" label="mobile-phone" value={user.phone}/>
              <Details />
            </div>
          }
        </DetailsBlock>
      </div>
      <div className="pageSection">
        <DetailsBlock>
          {additionalInfo.reduce((cs, Info, idx) => idx % 2 === 0 ? cs.concat([[Info]]) : cs.concat([cs.pop().concat(Info)]), [])
                         .map((cs, row) => <div key={`addInfo${row}`} className="grid-col">{cs.map((Info, col) => <Info key={`addInfo${row}${col}`}/>)}</div>)}
        </DetailsBlock>
      </div>
      <div className="pageSection">
        <DetailsBlock>
          <Details id="userRole" label="roles" help={<div className="maxTextWidth60">{I18n('roles-desc')}</div>} >
            {<ul>{ user.roles?.map((role, i) => <li key={i}><Authentication.ViewRole.AsLink id={role} disabled={Roles.isPermission(role)} /></li>) }</ul>}
          </Details>
        </DetailsBlock>
      </div>
      <div className="pageSection">
        {user.id && <SignatureManager userId={user.id} onUpload={props.setSignature} onDelete={props.deleteSignature} notify={props.notify} />}
      </div>
    </div>
    <PhoneChanged2FAModal show={showPhoneChanged2FAModal} close={() => setShowPhoneChanged2FAModal(false)} I18n={text => Text.resolve(text, undefined, {page: 'EditUser'})}/>
  </div>;
};

const ViewUserPresenter = (props) => {
  const user            = props.snapshot?.data || { id: undefined, disabled: false, name: undefined, lastName: undefined, mail: undefined, phone: undefined, job: undefined, owners: [], roles: [], preferences: {} };  // TODO: should use get(Role) since only registered high-level roles can be shown here
  
  const healthcaresites = HealthcareSite.ownersFrom(user);
  const organisations   = Organisation.ownersFrom({owners: props.snapshot?.metadata?.allOwners || []}).filter(o => isFromRoot(props.snapshot) || o !== AdministrationConfig.organisation.root.id);

  const [ studies ]     = useCounter(Study, { filters: [['clinician', '==', user.id]], ownedBy: props.snapshot, autoStart: Boolean(props.snapshot) });

  const enable           = (options) => props.enableUser({id: user.id, ...options});
  const disable          = (options) => props.disableUser({id: user.id, ...options});
  const editEmail        = (mail)    => props.editUserMail({id: user.id, mail});
  const unregister       = ()        => props.unregisterUser({id: user.id});

  const isDisabled = user?.status === User.STATUS.disabled;
  const [disabledWarnId, setDisabledWarnId] = useState();
  const {notify, dismissNotification, I18n} = props;

  useEffect(() => {
    if (isDisabled && !disabledWarnId) {
      const warnId = require('uuid').v4();
      notify('warning', I18n("user-account-disabled"), I18n("user-account-disabled-resolution"), { id: warnId, timeout: 0 });
      setDisabledWarnId(warnId);
    } else if (!isDisabled && disabledWarnId !== undefined) {
      dismissNotification({id: disabledWarnId});
    }

    return () => disabledWarnId && dismissNotification({id: disabledWarnId});
  }, [isDisabled, disabledWarnId, notify, dismissNotification, I18n]);

  return <ViewUserUI 
          currentUser       ={props.currentUser}
          user              ={user && { ...user, healthcaresites, organisations, studies }}
          handleEnable      ={enable}
          handleDisable     ={disable}
          handleDelete      ={unregister}
          handleEmailUpdate ={editEmail}
          I18n              ={props.I18n}
          {...props}
        />;
}

const ViewUser = props => {
  const [ currentUser ] = useCurrentUser();
  const [{ id: defaultId, user: userId = defaultId }] = useLocationParams(),
        id                                            = userId || currentUser.data?.id;

  return WithSnapshot(User, id, { skipContext: true, fallbackComponent: _ => <Redirect to={Authentication.FindUser.PATH} /> })(ViewUserPresenter)({...props, currentUser });
}
ViewUser.AsText = AggregateAsText(User, data => (data?.lastName && data?.name) ? `${data.lastName}, ${data.name}` : (data?.name || data?.lastName || data?.mail), User.queries.GET);
ViewUser.Icon = UserIcon;

const AsIcon = ({ className, ...props }) => {
  const [currentUser] = useCurrentUser();
  const hasAccess = currentUser.hasAccess(require('../../config').default.routes.ViewUser);
  return <div className={`${hasAccess ? className : className.replace('button', 'nonselectable')} withIcon`}><ViewUser.Icon /><ViewUser.AsText {...props} /></div>;
}
ViewUser.AsIcon = AsIcon;

export default ViewUser;