import DetailsBlock from 'ui/components/DetailsBlock';
import Table from 'ui/components/Table';
import {Text} from 'ui/components/Text';
import { Fragment } from 'react';

import history from 'history.js';
import { dataFrom } from 'services/server/functions/executor/urn';

const Activity = ({activity, hideAggregate, aggregate, hideTitle, noBox}) => {
  const head = {
    date:  { content: "ActivityLog.date", isSortable: true, type: "date", formatter: d => require('date-fns').format(d, "P p")  },
    event: { content: "description"   },
  }; 

  if (!hideAggregate) head["aggregate"] = {content: aggregate};

  const rows = activity.map(({timestamp, type, label, aggregate: {id: aggregateId}}, idx) => {
    let row = {
      idx,
      date: timestamp,
      // user: user ? user : "unknown", // TODO: make sure all events have user infromation
      event: Text.resolve(label || type),
    };

    if (!hideAggregate) row['aggregate'] = dataFrom(aggregateId).id

    return row;
  });

  const Wrapper = noBox ? Fragment : DetailsBlock;
  return (
    <div className='pageSection'>
      {!hideTitle && <div className='title'>{Text.resolve('title', {}, { page: "ActivityLog" })}</div>}
      <Wrapper>
        <Table
          withViewAction={!hideAggregate}
          keyField='idx'
          columns={head}
          onRowClick={!hideAggregate && ((row) => history.push(require('modules/devices')[`View${activity[row.idx].aggregate.name}`].PATH, {[activity[row.idx].aggregate.name.toLowerCase()]: activity[row.idx].aggregate.id}))}
          data={rows}
          defaultSortCol={'date'}
          defaultSortDir={'desc'}
          emptyText={"ActivityLog.no-activity"}
        />
      </Wrapper>
    </div>
  );
};

export default Activity;