import {Text} from 'ui/components/Text';

export default {
  topic: Text.resolve("PrivacyPolicy.topics.how-contact.question"),
  component: () => <div className="grid-row">
    <span><Text variables={{mail: (<a href='mailto:contact@acurable.com' target="_blank" rel="noopener noreferrer">contact@acurable.com</a>)}}>PrivacyPolicy.topics.how-contact.answer-1</Text></span>
    <span><Text variables={{mail: (<a href='mailto:diego@acurable.com' target="_blank" rel="noopener noreferrer">diego@acurable.com</a>)}}>PrivacyPolicy.topics.how-contact.answer-2</Text></span>
    <span><Text>PrivacyPolicy.topics.how-contact.answer-3</Text></span>
    <ul>
      <li><Text context={{component: 'RegionSelector'}}>eu</Text>: <a href='https://edpb.europa.eu/about-edpb/about-edpb/members_en' target="_blank" rel="noopener noreferrer">https://edpb.europa.eu/about-edpb/about-edpb/members_en</a></li>
      <li><Text context={{component: 'RegionSelector'}}>uk</Text>: <a href='https://ico.org.uk/global/contact-us' target="_blank" rel="noopener noreferrer">https://ico.org.uk/global/contact-us</a></li>
      <li><Text context={{component: 'RegionSelector'}}>us</Text>: <a href='https://www.hhs.gov/ocr/about-us/contact-us' target="_blank" rel="noopener noreferrer">https://www.hhs.gov/ocr/about-us/contact-us</a></li>
    </ul>
  </div>
}