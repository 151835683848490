import { useCallback, useRef } from 'react';
import {
  ariaDescribedByIds,
  examplesId,
  getInputProps,
} from '@rjsf/utils';

export const BaseInputTemplate = (props) => {
    const {
        id,
        value,
        readonly,
        disabled,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        onChangeOverride,
        options,
        schema,
        type,
        ...rest
    } = props;
    const inputRef = useRef(null);

    if (!id) {
        console.log('No id for', props);
        throw new Error(`no id for props ${JSON.stringify(props)}`);
    }
    const inputProps = {
        ...rest,
        ...getInputProps(schema, type, options),
    };

    let inputValue;
    if (inputProps.type === 'number' || inputProps.type === 'integer') {
        inputValue = value || value === 0 ? value : '';
    } else {
        // eslint-disable-next-line
        inputValue = value == null ? '' : value;
    }

    const _onChange = useCallback(
        (event) => {
            const { target: { value } } = event;
            onChange(value === '' ? options.emptyValue : value);
        },
        [onChange, options]
    );
    const _onBlur = useCallback(({ target: { value } }) => onBlur(id, value), [onBlur, id]);
    const _onFocus = useCallback(
        ({ target: { value } }) => onFocus(id, value),
        [onFocus, id]
    );


    return (
        <>
            <input
                ref={inputRef}
                id={id}
                name={id}
                className='form-control'
                readOnly={readonly}
                disabled={disabled}
                autoFocus={autofocus}
                value={inputValue}
                {...inputProps}
                list={schema.examples ? examplesId(id) : undefined}
                onChange={onChangeOverride || _onChange}
                onBlur={_onBlur}
                onFocus={_onFocus}
                aria-describedby={ariaDescribedByIds(id, !!schema.examples)}
            />
            {Array.isArray(schema.examples) && (
                <datalist key={`datalist_${id}`} id={examplesId(id)}>
                    {(schema.examples)
                        .concat(schema.default && !schema.examples.includes(schema.default) ? ([schema.default]) : [])
                        .map((example) => {
                            return <option key={example} value={example} />;
                        })}
                </datalist>
            )}
        </>
    );
};
