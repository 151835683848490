import Config from './config';
import { publish } from 'modules/router';
import * as Pages from './ui';

import MODELS from 'modules/model'

const actions = {
  ...MODELS.devices.Batch.UIActions,
  ...MODELS.devices.Device.UIActions
};

export const ChangeStatus  = publish(Config.routes.ChangeStatus, actions)(Pages.ChangeStatus, { page: "ChangeDeviceStatus" });
export const RegisterBatch = publish(Config.routes.RegisterBatch, actions)(Pages.RegisterBatch, { page: "RegisterBatch" });
export const Inventory     = publish(Config.routes.Inventory, actions)(Pages.Inventory, { page: "Inventory" });
export const Activity      = publish(Config.routes.Activity, actions)(Pages.Activity, { page: "ActivityLog" });
export const LogBatchIssue = publish(Config.routes.LogBatchIssue, actions)(Pages.LogBatchIssue, { page: "ReportBatchIssue" });
export const LogIssue      = publish(Config.routes.LogIssue, actions)(Pages.LogIssue, { page: "ReportDeviceIssue" });
export const Find          = publish(Config.routes.Find, actions)(Pages.Find, { page: "FindDevices" });
export const ViewBatch     = publish(Config.routes.ViewBatch, actions)(Pages.ViewBatch, { page: "ViewBatch" });
export const ViewDevice    = publish(Config.routes.ViewDevice, actions)(Pages.ViewDevice, { page: "ViewDevice" });
