import Error403 from 'ui/components/ErrorBoundary/Error403';
import useCurrentUser from "ui/hooks/useCurrentUser";
import hoistNonReactStatics from 'hoist-non-react-statics';

/**
 * Grants access to the `WrappedComponent` if and only if current user has required roles.
 * @param {*} roles required page roles to get access.
 */
export const withPermission = (resource) => (WrappedComponent) => {
  const WithPermission = (props) => {
    const [currentUser] = useCurrentUser(resource.options);
    const isPublic = !resource.onlyAcurableUsers && !resource.onlySuperAdmin && resource.roles === undefined;
    return (isPublic || currentUser.hasAccess(resource)) ? <WrappedComponent {...props} /> : <Error403 />;
  }

  WithPermission.roles = resource.roles;
  WithPermission.options = resource.options;
  WithPermission.onlyAcurableUsers = resource.onlyAcurableUsers;
  WithPermission.onlySuperAdmin = resource.onlySuperAdmin;
  WithPermission.displayName = `WithPermission(${WrappedComponent?.displayName}))`;

  hoistNonReactStatics(WithPermission, WrappedComponent);

  return WithPermission;
};
