import 'react-phone-input-2/lib/style.css';
import * as Pages from 'modules/administration';
import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import { Select } from 'ui/components/Select';
import { WithValidation } from 'ui/components/WithValidation';
import { prop, sortBy } from 'ramda';
import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import Details from 'ui/components/Details';
import {Text} from 'ui/components/Text';
import DetailsBlock from 'ui/components/DetailsBlock';
import Modal from 'ui/acukit/ModalDialog';
import { useState } from 'react';
import { ValidatedPhone } from 'ui/components/PhoneInput';
import { Device } from 'services/server/functions/model/devices/model';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { User } from 'services/server/functions/model/authentication/model';
import i18n from 'services/server/functions/i18n';
import Spinner from '@atlaskit/spinner';

import history from 'history.js';
import useLocationParams from 'ui/hooks/useLocationParams';
import WithSnapshot from 'ui/components/WithSnapshot';
import useSnapshot from 'ui/hooks/useSnapshot';
import { useCounter } from 'ui/hooks/useCounter';
import { DataBase } from 'services/firebase';
import useCurrentUser from 'ui/hooks/useCurrentUser';

const isTopCountry = (country) => country === "GB" || country === "US";
const countryNames = () => i18n.countries.getCodes().map(c => ({value: c, label: i18n.countries.getName(c), group: isTopCountry(c) ? '' : 'Others'}));

const ValidatedInput  = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name}  onValidate={onValidate} showError={showError} {...props}><input {...props} value={props.onEmpty ? props.onEmpty(props.value) : props.value || ''} /></WithValidation>
const ValidatedSelect = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><Select {...props} /></WithValidation>; 

const EditHCSUI = ({formSaved, organisations, onValidate, onSave, isLoadingSave, onValueChange, isUpdate, healthcaresite, askConfirmation, onConfirm, onRegret, hasResourcesAssigned, I18n}) => {
  const [edited, setEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const actions = [
    { text: 'leave',  onClick: () => history.goBack(), appearance: 'primary' },
    { text: 'stay', onClick: () => setShowModal(false) },
  ];
  const onChange = (...args) => {
    setEdited(true);
    return onValueChange(...args);
  };

  const ValidationSchema = isUpdate ? HealthcareSite.commands.UPDATE_HCS.schema : Organisation.commands.REGISTER_HCS.schema;
  return (
    <div className="page" id="EditHealthcareSite">
      <Back/>
      <div className="pageHeader">
        <h1 className="title">{isUpdate ? I18n('EditHCS.title') : I18n('CreateHCS.title')}</h1>
      </div>
      <div className="pageContent">
        <div className="pageSection">
          <div className="title">{I18n('details')}</div>
          <DetailsBlock>
            <div className="grid-col">
              <Details id="healthcaresiteName" label="global.name" mandatory><ValidatedInput name="name" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={healthcaresite.name} /></Details>
              <Details id="healthcaresiteDepartment" label="department"><ValidatedInput name="department" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={healthcaresite.department} /></Details>
            </div>
            <div className="grid-col">
              <Details id="healthcaresiteCountry" label="country"><ValidatedSelect name='country' onValidate={onValidate} onChange={onChange} options={countryNames()} isClearable placeholder={Text.resolve("choose-country")} schema={ValidationSchema} showError={formSaved} value={healthcaresite.country} /></Details>
              {healthcaresite.country ? <Details id="healthcaresitePostCode" label="postal-code"><ValidatedInput name="postCode" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={(e) => { if (e.target.value === "") onChange({ target: { name: e.target.name, value: null } }); else onChange(e); }} onEmpty={(val) => val === "" ? null : val} value={healthcaresite.postCode} form={{country: healthcaresite.country, postCode: healthcaresite.postCode}} /></Details> : <></> }
            </div>
            <Details id="healthcaresiteAddress" label="address"><ValidatedInput autoComplete="off" name="address" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={(e) => { if (e.target.value === "") onChange({ target: { name: e.target.name, value: null } }); else onChange(e); }} onEmpty={(val) => val === "" ? null : val} value={healthcaresite.address} /></Details>
            <div className="grid-col">
              <Details id="healthcaresiteMail" label="email-address"><ValidatedInput name="mail" schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={(e) => { if (e.target.value === "") onChange({ target: { name: e.target.name, value: null } }); else onChange(e); }} onEmpty={(val) => val === "" ? null : val} value={healthcaresite.mail} /></Details>
              <Details id="healthcaresitePhone" label="telephone-number"><ValidatedPhone name="phone" country={healthcaresite.country || ''} schema={ValidationSchema} showError={formSaved} onValidate={onValidate} onChange={onChange} value={healthcaresite.phone} /></Details>
            </div>
          </DetailsBlock>
        </div>
        <div className="pageSection">
          <DetailsBlock>
            <Details id="healthcaresiteOrganisation" label="global.organisation.label" help={hasResourcesAssigned ? I18n('resources-assigned') : undefined} mandatory={!isUpdate || organisations?.length === 1}>
              {!organisations.length ? <Spinner size="small"/>
                : organisations.length === 1 ? <Pages.ViewOrganisation.AsIcon organisation={organisations[0].value} text={organisations[0].label} />
                : <ValidatedSelect name='organisation' onValidate={onValidate} onChange={onChange} options={sortBy(prop('label'))(organisations)} placeholder={I18n('choose-org')} schema={Organisation.schema.extract('id').required()} showError={formSaved} value={healthcaresite.organisation} isDisabled={hasResourcesAssigned} />}
            </Details>
          </DetailsBlock>
          <span><sup className="mandatory">*</sup><span className="small">{I18n('compulsory-field')}</span></span>
        </div>
      </div>
      <div className="pageFooter">
        <div className='actions'>
          <Action className='button' id="back" handleAction={() => edited ? setShowModal(true) : history.goBack()} label='cancel' />
          <Action className='button primary' id="report" handleAction={() => onSave()} label='global.button.save' loading={isLoadingSave} />
        </div >
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} heading="unsaved-changes" actions={actions}>
        <span>{I18n('sure-to-leave')}</span>
      </Modal>
      <Modal show={askConfirmation} onClose={onRegret} heading="please-confirm" actions={[
        { text: 'confirm', onClick: onConfirm, appearance: 'primary' },
        { text: 'cancel', onClick: onRegret },
      ]}>
        <span>{I18n('EditHCS.already-exists')}</span>
      </Modal>
    </div>
  );
};

const EditSitePresenter = (props) => {
  const { site, organisation } = props;
  const id            = props.healthcaresite;
  const isUpdate      = id !== undefined;

  const [originalUser] = useCurrentUser({ skipContext: true });
  
  const useCounterAutoStart = Boolean(id);
  const [studiesCounter, loadingStudies] = useCounter(Study, { owner: id, autoStart: useCounterAutoStart });
  const [devicesCounter, loadingDevices] = useCounter(Device, { owner: id, autoStart: useCounterAutoStart });
  const [usersCounter,   loadingUsers]   = useCounter(User, { owner: id, autoStart: useCounterAutoStart });
  const loadingCounters = loadingStudies || loadingDevices || loadingUsers;
  const hasResourcesAssigned = loadingCounters ? undefined : (studiesCounter || devicesCounter || usersCounter || 0);

  const healthcaresite = site || (isUpdate && props.snapshot?.data) || {};
  
  const orgaId = (hasResourcesAssigned === undefined || hasResourcesAssigned > 0) ? healthcaresite.organisation : undefined;
  const orgasAutoStart = orgaId ? true : !loadingCounters;
  const [orgSnaps, loadingOrgs] = useSnapshot(Organisation, orgaId, { autoStart: orgasAutoStart });
  const orgSnapshots = orgSnaps?.aggregate ? [orgSnaps] : Object.values(orgSnaps || {});
  let organisations = orgSnapshots.map(({data: { id, name }}) => ({ label: name, value: id }));
  healthcaresite.organisation = healthcaresite.organisation || healthcaresite.owners?.[0] || organisation || (organisations.length === 1 ? organisations[0].value : undefined);
  
  const [saved, setSaved]   = useState(false);
  const [isLoadingSave, setIsLoadingSave]   = useState(false);
  const [errors, setErrors] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);

  if (orgasAutoStart && !loadingOrgs && isUpdate && organisations?.length === 0) organisations = [{label: props.I18n("Current organisation"), value: healthcaresite.organisation}];

  const hasErrors = () => errors && Object.values(errors).length > 0;
  
  const onValidate = (field, error) => setErrors(prevErrors => {
    if (error) return { ...prevErrors, [field]: error };
    delete prevErrors[field];
    return {...prevErrors};
  });

  const handleRegister = (data) => {
    const newHCS = HealthcareSite.new(data);
    return props.registerHCS(newHCS.data)
      .then(() => history.replace(`${Pages.ViewHCS.PATH}`, { healthcaresite: newHCS.aggregate.id }));
  };

  const handleUpdate = (data) => isUpdate && props.updateHCS({ id, ...data }).then(_ => history.replace(`${Pages.ViewHCS.PATH}`, { healthcaresite: id }));

  const withOwners = ({ organisation, ...healthcaresite }) => ({ ...healthcaresite, owners: [organisation] });

  const submit = () => {
    setAskConfirmation(false);
    return (isUpdate ? handleUpdate : handleRegister)(withOwners(healthcaresite)).catch((e) => { setIsLoadingSave(false); console.error(e); });
  };
  
  const handleSave = async () => {
    setSaved(true);
    setIsLoadingSave(true);
    if (!hasErrors()) {
      const query = DataBase().snapshots(HealthcareSite).with('name', healthcaresite.name);
      if (healthcaresite.id) {
        query.with('id', '!=', healthcaresite.id);
      }
      if (!originalUser.isSuperAdmin() || !originalUser.isFromRoot()) query.with('metadata.allOwners', 'array-contains-any', originalUser.data.owners);
      const orgasWithSameName = await query.count().catch(error => { console.error(error); setIsLoadingSave(false); throw error; });
      if (orgasWithSameName !== 0) {
        setIsLoadingSave(false);
        return setAskConfirmation(true);
      }
      submit();
    };
    setIsLoadingSave(false);
  }

  const onValueChange = (event) => {
    const value = event.target.type === 'select-multiple' ? [] : event.target.value;
    if (event.target.type === 'select-multiple') for (let i = 0; i < (event.target.selectedOptions || []).length; i++) value.push(event.target.selectedOptions[i].value);
    const updates = { [event.target.name]: value };
    if (event.target.name === "country" && value === null) updates.postCode = null;
    props.setLocationState({site: {...healthcaresite, ...updates }});
  }

  return <EditHCSUI
    healthcaresite={healthcaresite}
    organisations={organisations}
    onValidate={onValidate}
    onSave={handleSave}
    isLoadingSave={isLoadingSave}
    onValueChange={onValueChange}
    formSaved={Boolean(saved)}
    isUpdate={isUpdate}
    askConfirmation={askConfirmation}
    onConfirm={submit}
    onRegret={() => setAskConfirmation(false)}
    hasResourcesAssigned={hasResourcesAssigned}
    I18n={props.I18n}
  />;
};

const EditSite = (props) => {
  const [locState, setLocationState] = useLocationParams();
  // Note: loading all organisations because of popup warning saying there is another organisation with same name (re-using query to load current snapshot on Update UC)
  return WithSnapshot(HealthcareSite, locState.healthcaresite, {showLoading: locState.healthcaresite !== undefined})(EditSitePresenter)({...props, setLocationState, ...locState});
}

EditSite.displayName = "EditHealthcareSite";
export default EditSite;
