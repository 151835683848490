import { Joi, list, min, required, unique } from '../validation/rules';
import { aggregateURN, commandURN, dataFrom, eventURN, isCommand, isReference, isURN, nextEventURN, queryURN } from '../executor/urn';
import { config } from '../firebase/config';
import { getReason, getResolution } from '../errors/messages';

import { INVALID_DATA_ERROR } from '../errors/codes';
import { SYSTEM_ACTION } from '../executor/actions';
import { withAccessRights } from '../iam/roles';
import { withCustomError } from '../validation/errors';
import { when, FORBIDDEN_POLICY } from './policies';
import { SchemaExtractor } from '../validation/schemaExtractor';
import { FormBuilderFactory } from '../forms';
import { resetMerge } from '../executor/handlers/strategies';

const joi2Policy           = (data, error) => ({ code: INVALID_DATA_ERROR, reason: getReason(INVALID_DATA_ERROR), resolution: getResolution(INVALID_DATA_ERROR), details: `${JSON.stringify({data, error})}` });
const defaultCheckPolicies = (_model, _action) => (after,  _before, _executor ) => after; // shold throw otherwise return always after.
// {
//   const { when } = require('./policies');
//   const result = require('joi-browser').validate(newSnap, (action ? action.schema : model.schema));
//   const error  = result.error && JSON.stringify(result.error.details);
//   return when(error).rejectWith({ code: `${model.name.toUpperCase()}/${action ? action.type.toUpperCase() : 'SCHEMA'}/E001`, reason: `Data does not conform schema. ${error}`, resolution: 'Enter valid data.' });
// }

const defaultEvents = {
  OWNERS_ADDED: _model => ({}),
  OWNERS_UPDATED: _model => ({}),
  CLONED: _model => ({ snapshot: resetMerge }),
}

const defaultCommands = {
  ADD_OWNERS: (model) => ({
    label: `Add owners to ${(model.label || model.name).toLowerCase()}`,
    checkPolicies: (req) => when(!req.owners.every(o => isReference(o))).rejectWith(FORBIDDEN_POLICY(`Some of the following selected owners ${req.owners} is not a valid reference URN.`)), // TODO: we should check if the owner instance actually exists (same for CREATE/UPDATE commands in all agregates)
    schema: Joi.object().keys({
      id    : Joi.string().uri().required(),
      owners: Joi.array().min(1).items(Joi.string().uri()).required()
    }),
    event: async (action, prevSnap) => {
      const event = model.events.OWNERS_ADDED.new(action);
      event.data.owners = event.data.owners.concat(prevSnap?.data.owners).filter((o, idx, owners) => owners.indexOf(o) === idx);

      return event;
    }
  }),
  UPDATE_OWNERS: (model) => ({
    label: `Update owners of ${(model.label || model.name).toLowerCase()}`,
    checkPolicies: (req) => when(!req.owners.every(o => isReference(o))).rejectWith(FORBIDDEN_POLICY(`Some of the following selected owners ${req.owners} is not a valid reference URN.`)), // TODO: we should check if the owner instance actually exists (same for CREATE/UPDATE commands in all agregates)
    schema: Joi.object().keys({
      id    : Joi.string().uri().required(),
      owners: Joi.array().min(1).items(Joi.string().uri()).required()
    }),
    get event() { return model.events.OWNERS_UPDATED; }
  }),
  CLONE: (model) => ({
    get schema() { return model.schema },
    get event() { return model.events.CLONED; },
  }),
};

const extensions = {
  events: {
    DELETED: _model => ({ snapshot: () => ({ metadata: { deleted: true } }) }),
  },
  commands: {
    DELETE: (model) => ({
      get schema() {
        if (typeof model === "function") model = model();
        return Joi.object().keys({ id: model.schema.extract("id").required() })
      },
      get event() {
        if (typeof model === "function") model = model();
        return model.events.DELETED;
      },
    }),
  },
  getEvents: (model, events) => Object.entries(events).reduce((acc, [type, event]) => ({
    ...acc,
    [type]: event(model)
  }), {}),
  getCommands: (model, commands) => Object.entries(commands).filter(([type]) => !(type in (model.commands || {}))).reduce((acc, [type, command]) => ({
    ...acc,
    [type]: command(model)
  }), {}),
};

const primitveValueFilterSchema = Joi.alternatives([Joi.string(), Joi.number(), Joi.boolean()]);
const queryFilterSchema = Joi.object().keys({
  field: Joi.string().required(), 
  operator: Joi.string().required(), 
  value: Joi.any().when('operator', {switch: [
    {is: 'array-contains', then: primitveValueFilterSchema.required()},
    {is: 'array-contains-any', then: Joi.array().items(primitveValueFilterSchema).required()},
    {is: 'in', then: Joi.array().items(primitveValueFilterSchema).required()},
  ], otherwise: primitveValueFilterSchema.required()})
});
const queryAllSchema    = Joi.array().items(queryFilterSchema);

// utils for manipulating objects in the model (ideally they should be reusable across all models, maybe find a decent library that does this?)
const get = (obj, [first, ...rest], value) => {
  if (first.startsWith('[')) { first = Number(first.slice(1,-1)); }
  if (obj?.[first] === undefined) return value;
  return rest.length ? get(obj[first], rest, value) : obj[first];
}
const set = (obj, [first, ...rest], value) => {
  if (value === undefined) return obj;

  if (first.startsWith('[')) {
    first = Number(first.slice(1,-1));
    obj = [...obj];  
  } else {
    obj = { ...obj};
  }
  
  obj[first] = rest.length ? set(obj[first] || (rest[0].startsWith('[') ? [] : {}), rest, value) : value;
  return obj;
}

// --------------

const defaultQueries = {
  ID_EXISTS: (model) => ({
    label: `Check ${(model.label || model.name).toLowerCase()} ID`,
    schema: Joi.object().keys({ id: required(Joi.string()) }),
    newRequest: (args, metadata) => ({
      action   : model.queries.ID_EXISTS.new(args, metadata),
      depends  : model.queries.GET.newRequest({ id: args.id, fields: ['aggregate.id']}, metadata),
      transform: ([instance]) => instance?.aggregate.id === args.id
    })
  }),  
  GET: (model) => ({
    label : `Get full ${(model.label || model.name).toLowerCase()} record`,
    schema: Joi.object().keys({ 
      id    : required(Joi.string()),
      fields: Joi.array().items(Joi.string()),
      expands: Joi.array().items(Joi.string())
    })
  }),
  LIST: (model) => ({
    label: `List full ${(model.label || model.name).toLowerCase()} records`,
    // TODO: Implement transform function to filter those fields this user do not have access to
    schema  : Joi.object().keys({ 
      where: queryFilterSchema,
      any: Joi.object().pattern(Joi.number().positive().allow(0), queryAllSchema),
      all: queryAllSchema,
      fields: Joi.array().items(Joi.string()),
      expands: Joi.array().items(Joi.string())
      // TODO: add pagination and order filters
    })
  })
};

const withOptionals = (model) => {
  model.qualifiedName = aggregateURN(model).replace(/\/[^/]+$/, '');
  model.label         = model.label || model.name;
  model.schema        = model.schema.keys({ id: Joi.string().uri(), owners: unique(min(list(Joi.string().uri()), 1)) });
  model.describe      = (schema=model.schema) => SchemaExtractor.JoiSchemaDescriptor.describe(schema);
  const originalEvents = model.events;
  // Necessary because getters cannot be overwritten
  delete model.events;
  model.events        = {...originalEvents, ...Object.entries(defaultEvents).reduce((events, [type, event]) => ({...events, [type]: event(model)}), {})};
  const originalCommands = model.commands;
  // Necessary because getters cannot be overwritten
  delete model.commands;
  model.commands      = {...originalCommands, ...Object.entries(defaultCommands).filter(([type]) => !(type in (model.commands || {}))).reduce((commands, [type, command]) => ({...commands, [type]: command(model)}), {})};
  const originalQueries = model.queries;
  // Necessary because getters cannot be overwritten
  delete model.queries;
  model.queries       = {...originalQueries, ...Object.entries(defaultQueries).filter(([type]) => !(type in (model.queries || {}))).reduce((queries, [type, query]) => ({...queries, [type]: query(model)}), {})};
  model.checkPolicies = model.checkPolicies || defaultCheckPolicies(model);
  model.newURN        = (...id) => aggregateURN(model, ...id);
  model.new           = (data={}, metadata={}, id=model.newURN(data.id || model.parent?.ownersFrom(data)[0])) => ({context: { name: model.context }, aggregate: { name: model.name, id }, data: {...data, id}, metadata});
  model.isInstance    = (instance={aggregate: {}, context: {}}) => ((instance.aggregate.name || instance.model?.aggregate.name) === model.name) && ((instance.context.name || instance.model?.context) === model.context);
  model.isReference   = (urn) => isReference(urn, model);
  model.id            = (instance) => instance?.data ? dataFrom(instance.data.id).id : instance && dataFrom(instance).id;
  model.ownersFrom    = (instance) => (instance?.owners || []).map(dataFrom).filter(model.isInstance).map(ref => ref.urn);
  model.get           = get;
  model.set           = set;

  ['events', 'commands', 'queries'].forEach(e => Object.keys(model[e]).forEach(k => {
    model[e][k].parent        = model;
    model[e][k].type          = model[e][k].type          || k;
    model[e][k].handler       = model[e][k].handler       || `${model.context}_${model.name.split('/').pop()}_${k}`;
    model[e][k].label         = model[e][k].label         || k.split('_').map((w, idx) => idx === 0 ? `${w.charAt(0).toUpperCase()}${w.slice(1).toLowerCase()}` : w.toLowerCase()).join(' ');
    if (!('schema' in model[e][k]))  { model[e][k].schema = model.schema; }
    model[e][k].describe      = (schema=model[e][k].schema) => SchemaExtractor.JoiSchemaDescriptor.describe(schema);
    model[e][k].checkPolicies = model[e][k].checkPolicies || defaultCheckPolicies(model, model[e][k]);
    if (e === 'commands' || e === 'queries') {
      model[e][k].transform = model[e][k].transform || (result => result);
      model[e][k].getForm = (values, formId, options) => {
        const formBuilder = FormBuilderFactory.get(model[e][k], formId, options);
        const form = formBuilder.generate(values);
        return form;
      }
    }
    if (e === 'events')   {
      model[e][k].isInstance = (urnOrEvent) => eventURN(model, k, isURN(urnOrEvent) ? urnOrEvent : urnOrEvent?.id) === (isURN(urnOrEvent) ? urnOrEvent : urnOrEvent?.id);
      model[e][k].new = model[e][k].new || ( (data, metadata = {}) => require('../executor').event(model[e][k].type, model, isCommand(data.id) ? data : require('../executor').command(SYSTEM_ACTION, model, data), {...data.metadata, ...metadata}) );
      model[e][k].nextId = fromURN => nextEventURN(eventURN(model, k), fromURN);
    }
    if (e === 'commands') { 
      // Request schema is converted into current app version schema by "validate"
      model[e][k].new = model[e][k].new || ( (data, metadata = {}) => require('../executor').command(model[e][k].type, model, validate(data, model[e][k].schema, metadata), {...metadata, schemaVersion: config.appVersion}) );
      model[e][k].newRequest = model[e][k].newRequest || ( (data = {}, metadata = {}) => ({ action: model[e][k].new(data, metadata)}) );
      model[e][k].newURN = (id) => commandURN(model, model[e][k].type, id);
    }
    if (e === 'queries')  { 
      // Request schema is converted into current app version schema by "validate"
      model[e][k].new = model[e][k].new || ( (data, metadata = {}) => require('../executor').query(model[e][k].type, model, validate(data, model[e][k].schema, metadata), {...metadata, schemaVersion: config.appVersion})   );
      model[e][k].newRequest = model[e][k].newRequest || ( (data = {}, metadata = {}) => ({ action: model[e][k].new(data, metadata)}) );
      model[e][k].newURN = (id) => queryURN(model, model[e][k].type, id);
    }
  }));

  return model;
}

const withI18N = model => {
  model.i18n = (text, vars, ctx) => require('../i18n').default.resolve(text, vars, { schema: `${model.context}.${model.name}`, ...ctx });
  return model;
}

const withParent = (parent) => model => {
  if (!parent) return model;

  model.context = parent.context;
  model.name    = `${parent.name}/${model.name}`;
  model.label   = model.label || `${parent.name} ${model.name.toLowerCase()}`;
  model.parent  = parent;
  model.parentId = (id, ascendant=parent.name.split('/').pop()) => {
    const path = id.split(`/${ascendant}/`);
    return path[0] + `/${ascendant}/` + path[1].split('/')[0];
  }

  return model;
}

const buildEntities = model => {
  return Object.keys(model.entities || {}).reduce((acc, entityKey) => {
    let entity = model.entities[entityKey];
    if (typeof model.entities[entityKey] === "function") entity = entity(model);
    acc[entityKey] = entity;
    if (entity.entities) entity.entities = buildEntities(entity);
    return acc;
  }, {});
};

const withDefaults = (depends) => (rawModel, parent) => {
  const model = withParent(parent)(withOptionals(rawModel)); // TODO: shouldn't this be the other way around?  withOptionals(withParent(parent)(rawModel))
  
  const populatedModel = [
    withAccessRights(depends),
    withI18N,
    (m) => { m.actions = { ...m.commands, ...m.queries }; return m; }, // TODO: legacy, to be deprecated. Remove all references to '.actions'. ??
  ].reduce((m, fn) => fn(m), model);
  
  if (populatedModel.entities) {
    populatedModel.entities = buildEntities(populatedModel);
    if (depends) {
      if (Array.isArray(depends)) {
        depends = [...depends, ...Object.values(populatedModel.entities)];
      } else {
        depends = [depends, ...Object.values(populatedModel.entities)];
      }
    } else {
      depends = Object.values(populatedModel.entities);
    }
  }
  return populatedModel;
}

const all = () => ({
  'administration': require('./administration/model'),
  'authentication': require('./authentication/model'),
  'devices'       : require('./devices/model'),
  'diagnosis'     : require('./diagnosis/model'),
  'notifications' : require('./notifications/model'),
  'system'        : require('./system/model'),
});
const validate = (data, schema, context) => {
  const result = withCustomError(schema).validate(data, { abortEarly: false, allowUnknown: true, stripUnknown: true, context: {schemaVersion: context.apiVersion || config.appVersion, ...context} }); // By default use last schema version of none provided by context
  if (result.error) throw(joi2Policy(data, result.error));
  return result.value;
}
const resolve  = (reference) => {
  const {context, aggregate, model, name} = isURN(reference) ? dataFrom(reference) : (reference || {});
  
  if (context) {
    const ctx = model?.context || context?.name || context;
    const [aggr, ...entities] = (model?.aggregate.name || aggregate?.name || aggregate || name).split('/');
    return entities.reduce((m, e) => m.entities[e], all()[ctx][aggr]);
  }
  
  return undefined;
}

export { resolve, validate, withDefaults, all, defaultQueries, extensions };
