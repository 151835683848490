import { Fragment, useEffect, useState } from "react";
import { Joi } from "services/server/functions/validation/rules";
import Details from "../Details";
import { Select } from "../Select";
import { Text } from "../Text";
import { WithValidation } from "../WithValidation";
import InfoModal from "./components/InfoModal";

const ValidatedText  = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} value={props.value}><textarea name={props.name || field} {...props} value={props.value || ''} /></WithValidation>
const ValidatedSelect = ({onValidate, showError, field, ...props}) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} value={props.value}>  
    <Select name={props.name || field} {...props} />
  </WithValidation>;
const optionsSchema = (options) => Joi.number().valid(...Object.values(options).map((_v, idx) => idx));
const Question = ({name, label, showErrors, question, options, required, ...props}) => <div className="question" style={{ gap: "10px", alignItems: "center" }}>
  <Text values={question || label}/>{required && <sup className="mandatory">*</sup>}
  <ValidatedSelect 
    className="answer" 
    options={options.map((o, idx) => ({label: Text.resolveValue(o.label), value: o.value || idx}))} 
    schema={required ? optionsSchema(options).required() : optionsSchema(options)}
    field={name}
    showError={showErrors}
    {...props} />
</div>;
export const AsForm = (props) => {
  const optionComments = (value) => value !== undefined ? props.options[value]?.comments : undefined;

  // ugly hack necessary because the form is removed and re-rendered entirely on every update
  const [selectedCustomItem, setSelectedCustomItem] = useState(typeof props.value === "string" ? props.value : undefined);
  useEffect(() => setSelectedCustomItem(typeof props.value === "string" ? props.value : undefined), [props.value]);
  const [newItem, setNewItem] = useState();
  const allOptions = [...props.options];
  if (selectedCustomItem) allOptions.push({ value: selectedCustomItem, label: selectedCustomItem });
  if (newItem) allOptions.push({ value: newItem, label: newItem });

  return <div>
    <Question {...props} options={allOptions} onInputChange={(value) => {
      if (props.allowNewOption) {
        if (value.length && !props.options.some(i => i.value === value)) setNewItem(value);
        else setNewItem(undefined);
      }
    }} onChange={e => props.onChange({...e, target: {...e.target, comments: optionComments(e.target.value) !== undefined ? props.comments : undefined}})}/>
    <ValidatedText
      style={{display: optionComments(props.value) !== undefined ? "block" : "none"}} 
      field={`${props.name}.comments`} 
      showError={props.showErrors} 
      schema={optionComments(props.value)?.required ? Joi.string().required() : Joi.string().allow('')} 
      className="field" 
      placeholder={Text.resolveValue(optionComments(props.value)?.placeholder)} 
      value={props.comments} 
      onValidate={props.onValidate} 
      onChange={e => props.onChange({target: {name: props.name, comments: (optionComments(props.value) !== undefined && e.target.value?.length > 0) ? e.target.value : undefined, value: props.value}})}
    />
  </div>;
}

export const AsView = ({value, default: defaultValue, name, question, options, id, description, comments}) => {
  const valueToUse = typeof value === "string" ? value : options[value]?.label;
return <Details 
  id={id} 
  label={Text.resolveValue(name || question)} 
  help={Text.resolveValue(description)} 
  value={comments ? <InfoModal label={value !== undefined ? Text.resolveValue(valueToUse) : "-"} info={<Details label={Text.resolveValue(name)} value={comments} valueClass='preserve-line-breaks' />} /> : value !== undefined ? Text.resolveValue(valueToUse) : defaultValue !== undefined ? Text.resolveValue(valueToUse) : "-"} 
/>
};

export const AsConfig = Fragment; // TODO