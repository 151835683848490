import { compose, prop, sortBy, toLower } from 'ramda';

import AdministrationConfig from "services/server/functions/model/administration/config";

import { FiltersType, generateListBySize, toAutoCompleteStudyOptions } from '../utils';
import { generateStudyFiltersValue, getUiFilters } from "../utils/uiFields";

export const useStudyFiltersManagement = ({
    currentUser, snapshots, baseFilters, maxNumberOfTests, reportType
}) => {
    const {
        organisations: organisationsSnap,
        healthcaresites: hcsSnap,
        clinicianOptions
    } = snapshots;
    const listOfClinicians = sortBy(compose(toLower, prop('label')))(clinicianOptions.map(u => ({ label: [u.lastName, u.name].join(", "), value: u.id })));
    const uiFilters = getUiFilters(currentUser, reportType);
    const listOfTests = generateListBySize(maxNumberOfTests);
    const clinician = baseFilters[FiltersType.Clinician] ? clinicianOptions.find(c => c.id === baseFilters[FiltersType.Clinician]) : undefined;
    let organisations = toAutoCompleteStudyOptions(organisationsSnap)?.filter(o => o.value !== AdministrationConfig.organisation.root.id && (clinician ? clinician.allOwners.includes(o.value) : true));

    const selectedOrg = baseFilters[FiltersType.Organisation] ? organisations?.find(o => o.value === baseFilters[FiltersType.Organisation]) : undefined;
    const hcs = toAutoCompleteStudyOptions(hcsSnap)?.filter(hc => (selectedOrg?.value ? hc.owners?.includes(selectedOrg?.value) : true)  && (clinician ? clinician.allOwners.includes(hc.value) : true));
    const selectedClinician = listOfClinicians.find(c => c.value === baseFilters[FiltersType.Clinician]);

    // Needed so that if a hcs is selected the orga is forced to the only value available (UI Requirement)
    if (baseFilters[FiltersType.HealthcareSite]) {
        const currentHCS = hcs.find(h => h.value === baseFilters[FiltersType.HealthcareSite]);
        if (currentHCS) {
            organisations = organisations.filter(o => o.value === currentHCS.owners[0]);
        }
    }
    
    const filters = generateStudyFiltersValue(uiFilters, { selectedOrg, baseFilters, hcs, listOfTests, selectedClinician });

    return {
        listOfTests,
        uiFilters,
        filters,
        hcs,
        organisations,
        listOfClinicians
    };
};