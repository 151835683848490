import { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import { Study } from 'services/server/functions/model/diagnosis/model';

import './style.css';
import { Text } from 'ui/components/Text';
import { BlockComment, BlockCommentTypes } from 'features/components/BlockComment';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import { TemplateSettingValue } from 'features/preferences/TemplateSettings/TemplateSettingValue';
import { getExportStudyAction } from './getExportStudyAction';
import { ApplySignatureButton } from 'features/diagnosis/Study/preferences/ApplySignatureButton';
import { ClinicianAnalysisSignature } from 'features/diagnosis/Study/actions/ClinicianAnalysisSignature';
import { SaveClinicianAnalysisStudyActionButton } from './SaveClinicianAnalysisStudyActionButton';

export const StudyActionsNames = {
    ExportPDF: 'ExportPdf',
    Analysis: 'Analysis'
};

export const StudyActions = ({ layout = false, updateActions, children, site, ...props }) => {
    const [currentUser] = useCurrentUser({ owner: site });
    const hasAccess = currentUser.hasAccess;
    const defaultActions = {
        [StudyActionsNames.ExportPDF]: getExportStudyAction(hasAccess),
        [StudyActionsNames.Analysis]:
            ({ id, study, clinicianAnalysis, onSaveClinicianAnalysis, applySignature, deleteSignature, markAsReviewed, signatureInfo, hideDeleteButton, onToggle, ...overrides }) => {
                const [clinicianAnalysisValue, setClinicianAnalysisValue] = useState(clinicianAnalysis);
                const [userSignature, setUserSignature] = useState({ data: null, action: null }); // action: ["upload", "delete"]
                const hasAccessToClinicianAnalysis = hasAccess(Study.commands.EDIT_CLINICIAN_ANALYSIS);
                const onSave = useCallback(async (newValue, updateToReviewed) => {
                    setClinicianAnalysisValue(newValue);
                    if (newValue !== undefined) await onSaveClinicianAnalysis(newValue);
                    if (userSignature.action === "upload") await applySignature();
                    if (userSignature.action === "delete") await deleteSignature();
                    if (updateToReviewed) await markAsReviewed();
                    setUserSignature({ data: null, action: null });
                }, [userSignature, onSaveClinicianAnalysis, applySignature, deleteSignature, markAsReviewed]);
                const header = (
                    <>
                        {
                            study?.healthcaresite && (
                                <div className="clinician-analysis-header">
                                    {Text.resolve('module.diagnosis.fe.view.study.comments_title')}
                                    <div className="flex">
                                        {Study.STATUS.compare(study.status, Study.STATUS.finished) >= 0 && userSignature.action !== "upload" && <ApplySignatureButton
                                            onResolve={(data) => setUserSignature((prev) => ({...prev, data, action: "upload"}))} owner={study.healthcaresite} />}
                                        {hasAccessToClinicianAnalysis && <TemplateSettingValue
                                            name="clinicianTemplate"
                                            owner={study.healthcaresite}
                                            className="clinician-analysis-import"
                                            data={{ study, user: currentUser?.data }}
                                            onResolve={setClinicianAnalysisValue} />}
                                    </div>
                                </div>
                            )
                        }
                    </>
                );

                const customActions = useMemo(() => {
                    const actions = [{ id: 'close-action-comment' }, { id: 'save-clinician-analysis-study-action-button', component: SaveClinicianAnalysisStudyActionButton({ currentUser, signatureInfo, userSignature, onSave, }) }];
                    if (Study.STATUS.compare(study.status, Study.STATUS.finished) === 0 && hasAccess(Study.commands.MARK_AS_REVIEWED)) actions.push({ id: 'save-clinician-analysis-and-mark-reviewed-study-action-button', component: SaveClinicianAnalysisStudyActionButton({ currentUser, signatureInfo, userSignature, onSave: (newValue) => onSave(newValue, true), submitText: "module.diagnosis.fe.component.save_clinician_analysis_and_mark_reviewed" }) });
                    return actions;
                }, [study.status, currentUser, signatureInfo, userSignature, onSave]);

                const signatureDetails = useMemo(() => {
                    if (userSignature.action === "upload" && userSignature.data) return { fileContent: userSignature.data };
                    if (signatureInfo) return signatureInfo;
                    return undefined;
                }, [userSignature, signatureInfo]);

                return (
                    <div className="margins">
                        <BlockComment
                            id={id}
                            defaultValue={clinicianAnalysis}
                            value={clinicianAnalysisValue}
                            header={header}
                            label={Text.resolve('global.text.analysis')}
                            hasAcces={hasAccessToClinicianAnalysis}
                            addLabel={Text.resolve('module.diagnosis.fe.view.study.add_analysis')}
                            customActions={customActions}
                            onSave={onSave}
                            onOverrideValue={() => setClinicianAnalysisValue()}
                            type={BlockCommentTypes.ButtonModal}
                            onToggle={onToggle}
                            {...overrides}
                        >
                            {userSignature.action !== "delete" && <ClinicianAnalysisSignature hideButton={hideDeleteButton} signatureInfo={signatureDetails} deleteSignature={() => setUserSignature((prev) => ({...prev, data: null, action: userSignature.action === "upload" ? null : "delete"}))} owner={study.healthcaresite} />}
                        </ BlockComment>
                    </div>
                )
            }
    };

    const [actions, setActions] = useState(defaultActions);

    useEffect(() => {
        if (updateActions) {
            const newActions = updateActions(actions)
            setActions(newActions);
        }
    }, []);

    const actionsBlock = Object.values(actions).map((Component, index) => <Fragment key={`study-actions-${index}`}>{<Component {...props} />}</Fragment>);

    return (
        <>
            {
                layout ? <div id="study-actions" className="actions">
                    {actionsBlock}
                    {children}
                </div> : actionsBlock
            }
        </>
    )
};