import { Role, User } from 'services/server/functions/model/authentication/model';
import ServerConfig from 'services/server/functions/model/authentication/config';
import { Roles } from 'services/server/functions/iam/roles';

const priority = 300;

export default {
  context : ServerConfig.context,
  priority,
  routes  : {
    ChangePassword: { relpath: '/changePassword', public: true, hideNav: true},
    Login         : { hideOnAuth: true, public: true, hideNav: true },
    
    FindUser  : { priority: priority + 11, relpath: `/${User.name.toLowerCase()}/find`, roles: [User.roles.manager.id], options: { skipContext: true } },
    CreateUser: { priority: priority + 12, relpath: `/${User.name.toLowerCase()}/create`, roles: User.commands.REGISTER_USER.roles },
    UpdateUser: { priority: priority + 13, relpath: `/${User.name.toLowerCase()}/edit`,   roles: User.commands.UPDATE_USER.roles },
    ViewUser  : { priority: priority + 14, relpath: `/${User.name.toLowerCase()}/view` }, // Note: Any user can view its own profile

    FindRole   : { priority: priority + 15, relpath: `/${Role.name.toLowerCase()}/find`,   roles: [Roles.superAdmin.id] },
    CreateRole : { priority: priority + 16, relpath: `/${Role.name.toLowerCase()}/create`, roles: Role.commands.CREATE_ROLE.roles },
    UpdateRole : { priority: priority + 17, relpath: `/${Role.name.toLowerCase()}/edit`,   roles: Role.commands.UPDATE_ROLE.roles },
    ViewRole   : { priority: priority + 18, relpath: `/${Role.name.toLowerCase()}/view`,   roles: Role.queries.GET.roles },
  }
};

