import './style.css';

import * as Pages from 'modules/diagnosis';

import { createRef, Fragment, useEffect, useState } from 'react';

import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import PrepareEquipment from './PrepareEquipment';
import PatientDetails from './PatientDetails';
import { Redirect } from 'react-router-dom';
import { Study } from 'services/server/functions/model/diagnosis/model';
import {Text} from 'ui/components/Text';
import { WithStudy } from '../components';
import history from 'history.js';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';

const STEPS = {
  FORM        : "form", 
  VALIDATE    : "validate", 
  PREPARE     : "instructions"
};
const STEPS_PAGE = {
  [STEPS.FORM]        : PatientDetails,
  [STEPS.VALIDATE]    : PatientDetails,
  [STEPS.PREPARE]     : PrepareEquipment,
};
const STEPS_TRANSITION = {
  [STEPS.FORM]        : ()        => ({ next: STEPS.VALIDATE }),
  [STEPS.VALIDATE]    : ()        => ({ back: STEPS.FORM, next: STEPS.PREPARE }),
  [STEPS.PREPARE]     : ()        => ({ }),
}

const StepProgress = (props) => {
  return (
    <div className="stepProgress">
      <span className="label upperCase"><Text>CreateStudy.steps</Text></span>
      <div className={['step'].concat(props.step === STEPS.FORM ? "active" : []).join(' ')}>
        <span className='stepNum centered'>1<span className="total">/3</span></span>
        <span className="stepLabel"><Text>CreateStudy.enter-details</Text></span>
      </div>
      <div className={['step'].concat(props.step === STEPS.VALIDATE ? "active" : []).join(' ')}>
        <span className='stepNum centered'>2<span className="total">/3</span></span>
        <span className="stepLabel"><Text>CreateStudy.confirm-details</Text></span>
      </div>
      <div className={['step'].concat(props.step === STEPS.PREPARE ? "active" : []).join(' ')}>
        <span className='stepNum centered'>3<span className="total">/3</span></span>
        <span className="stepLabel"><Text>CreateStudy.prepare-equipment</Text></span>
      </div>
    </div>
  )
}

const CreateStudyPresenter = (props) => {
  const [currentUser] = useCurrentUser();
  const formRef = createRef();
  const {id: currentStudyId, step=Object.values(STEPS)[0]} = props;
  const cloneData = props.study?.data;

  const [currentSnap, loadingCurrent, subscribe] = useSnapshot(Study, currentStudyId, {autoStart: false});
  if (!currentSnap && !loadingCurrent && currentStudyId) subscribe();

  useEffect(_ => {
    if (Object.values(STEPS).indexOf(step) > Object.values(STEPS).indexOf(STEPS.VALIDATE) && !currentStudyId) history.replace(Pages.CreateStudy.PATH, {});
  }, [step, currentSnap]);
  
  const isFirstStep = Object.values(STEPS)[0] === step;
  let onNext = undefined;
  let onBack = undefined;
  const setOnNext = (callback) => onNext = callback;
  const setOnBack = (callback) => onBack = callback;
  const Content = STEPS_PAGE[step];

  
  const stepOptions = {};

  const goBack = (isFirstStep || STEPS_TRANSITION[step](stepOptions).back) ? _ => Promise.resolve(onBack ? onBack() : true).then(validated => { validated && setOnBack(undefined); return validated && (isFirstStep ? history.goBack() : props.setStep(STEPS_TRANSITION[step](stepOptions).back)) }) : undefined;
  const goNext = STEPS_TRANSITION[step](stepOptions).next ? _ => {
    let validStudySettings = true;
    if (formRef?.current) {
      validStudySettings = formRef.current?.validateForm();
    }
    Promise.resolve(onNext ? onNext() : true)
      .then((validated) => {
        const canContinue = validated && validStudySettings;
        if (canContinue) {
          setOnNext(undefined);
          const nextStep = STEPS_TRANSITION[step](stepOptions).next;
          return props.pushStep(nextStep);
        }
        return canContinue;
      });
  }
                                             : _ => history.push(currentUser.hasAccess(Pages.ViewStudy) ? Pages.ViewStudy.PATH : Pages.StudyLanding.PATH, {study: currentStudyId});

  return (
    <div className='page'>
      { (isFirstStep || goBack) && <Back handleHistory={isFirstStep} onClick={isFirstStep ? (() => {}) : goBack}/> }
      <div className='pageHeader'>
        <div className='title wraptext'><Text>CreateStudy.title</Text></div>
      </div>
      <div id='CreateStudy' className="pageContent grid-row">
        <div className="pageSection"><StepProgress step={step} {...stepOptions}/></div>
        <Content formRef={formRef} goBack={goBack} goNext={goNext} setOnBack={setOnBack} setOnNext={setOnNext} isCreate={true} study={currentSnap || (step === STEPS.SHOW_CODE ? {data: {patient: {instructions: {providedPhone: props.providedPhone}}}} : undefined)} onSubmit={props.createStudy} loading={props.loading} cloneData={cloneData} notify={props.notify} getPatientForm={props.getPatientForm} />
      </div>
      <div className='pageFooter'>
        <div className='actions'>
          { goBack ? <Action className='button' handleAction={goBack} label={isFirstStep ? 'cancel' : 'back'} /> : <Fragment/>}
          <Action className='button primary' handleAction={goNext} label={STEPS_TRANSITION[step](stepOptions).next ? 'next' : 'finish'} />
        </div>
      </div>
    </div>
  );
};

const CreateStudy = (props) => {
  const [locState, setLocationState] = useLocationParams({step: undefined, id: undefined, clone: undefined }),
                                       setStep  = step => setLocationState({ step }), 
                                       pushStep = step => setLocationState({ step }, {type: 'push'});
  const additionalProps = {pushStep, setStep, step: locState.step};

  const [notfound, setNotFound] = useState(undefined);

  return notfound ? <Redirect to={Pages.FindStudy.PATH}/>
  : locState.clone !== undefined ? <WithStudy id={locState.clone} onNotFound={() => setNotFound(true)}>
    <CreateStudyPresenter {...{...additionalProps, ...props, ...locState}} />
  </WithStudy> 
  : <CreateStudyPresenter {...{...additionalProps, ...props, ...locState}} />;
}

CreateStudy.displayName = "CreateStudy";
export default CreateStudy;