import { Children } from 'react';
import HelpTooltip from 'ui/components/HelpTooltip';
import './style.css';

const DetailsBlock = ({id, hidden, children, title, titleClassName='', inline, className, contentClass, help, helpPosition, helpType, ...other}) => {
  const blockClass = inline ? 'grid-col' : 'grid-row';
  return Children.count(Children.toArray(children).filter(Boolean)) ? (
    <div id={id} {...other} className={['detailsBlock', hidden ? 'hidden' : ''].concat(className || []).join(' ')}>
      { title ? 
        <div className={['title'].concat(titleClassName).join(' ')}>
          {title}
          {help && <HelpTooltip content={help} place={helpPosition} id={`help${id || (title || "").replace(/ /, '')}`} type={helpType} />}
        </div> : 
        <></> 
      }
      <div className={[`detailsBlockContent ${blockClass}`].concat(contentClass || []).join(' ')}>
        {hidden ? <></> : children}
      </div>
    </div>
  ) : <></>;
};

export default DetailsBlock;
