import { Fragment } from "react";
import { Joi } from "services/server/functions/validation/rules";
import Details from "../Details";
import { Text } from "../Text";
import { WithValidation } from "../WithValidation";
import InfoModal from "./components/InfoModal";

const ValidatedText  = ({onValidate, showError, schema, field, form, value, ...props}) => <WithValidation field={field || props.name} form={form} schema={schema} onValidate={onValidate} showError={showError} value={value}><textarea name={props.name || field} {...props} value={value || ''} /></WithValidation>
  
export const AsForm = ({name, label, question, required, placeholder, showErrors, ...props}) => <div className="grid-row fullWidth">
  <Text values={question || label}/>{required && <sup className="mandatory">*</sup>}
  <ValidatedText
      field={name || question}
      showError={showErrors} 
      schema={required ? Joi.string().required() : Joi.string().allow('')} 
      className="field" 
      placeholder={Text.resolveValue(placeholder)} 
      {...props}
    />
</div>;

export const AsView = (props) => <Details 
  id={props.id}
  label={Text.resolveValue(props.name || props.question)}
  value={props.value !== undefined && props.value !== null && String(props.value)?.length > 0 ? <InfoModal info={<Details label={Text.resolveValue(props.name || props.question)} value={props.value} valueClass='preserve-line-breaks' />} /> : '-'} 
/>;

export const AsConfig = Fragment; // TODO